export const WORKFLOW_REGULAR = "REGULAR";
export const WORKFLOW_AUTOMATION = "AUTOMATION";

export const AUTOMATION_LABEL = "Automation";

export const WORKFLOW_STATUS_PENDING = "PENDING";
export const WORKFLOW_STATUS_ACTIVE = "ACTIVE";
export const WORKFLOW_STATUS_COMPLETED = "COMPLETED";
export const WORKFLOW_STATUS_ABORTED = "ABORTED";
export const WORKFLOW_STATUS_SUSPENDED = "SUSPENDED";
export const WORKFLOW_STATUS_UNKNOWN = "UNKNOWN";

export const WORKFLOW_TERMINAL_STATUSES = [WORKFLOW_STATUS_COMPLETED, WORKFLOW_STATUS_ABORTED, WORKFLOW_STATUS_UNKNOWN];
