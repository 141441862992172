import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tokens from "components/Tokens";
import {createToken, revokeToken} from "actions/Tokens";

class TokensPanel extends Component {
    render() {
        const { tokens, loading, createToken, creating, error, token, revokeToken } = this.props;

        return (
            <Tokens
                tokens={tokens}
                withUserInput
                loading={loading} creating={creating} error={error} tokenCreated={token}
                onCreateToken={createToken} revokeToken={revokeToken} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        creating: state.tokens.creating,
        loading: state.tokens.loading,
        error: state.tokens.error_creating,
        tokens: state.tokens.list,
        token: state.tokens.token_created
    };
};
export default connect(
    mapStateToProps, { createToken, revokeToken }
)(TokensPanel);
