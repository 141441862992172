import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { useApiRequestV2 } from 'actions';
import { createRole } from 'actions/Roles';
import { ROLE_SYSTEM_ADMINISTRATION } from 'constants/Authorities';
import GeneralDialog from 'components/Admin/Users/Common/GeneralDialog';
import ButtonLoading from 'components/Buttons/ButtonLoading';
import RoleDialogForm from 'components/Admin/Users/Roles/Dialog/RoleDialogForm';

const CreateRoleContainer = ({ onSubmit }) => {

  const [isOpen, setIsOpen] = useState(false);
  const { isLoading, errorObject, reset, run } = useApiRequestV2(
    createRole,
    (response) => {
      onSubmit(response);
      handleDialogClose();
    });

  const handleDialogClose = () => {
    setIsOpen(false);
    reset();
  };

  return (
    <>
      <ButtonLoading
        loading={isLoading}
        loadingTitle="Creating role"
        authoritiesRequired={[ROLE_SYSTEM_ADMINISTRATION]}
        onClick={() => setIsOpen(true)}
        variant="outlined"
        color="primary"
      >
        {`Create role`}
      </ButtonLoading>
      <GeneralDialog
        title="Create role"
        isOpen={isOpen}
        onClose={handleDialogClose}
      >
        <RoleDialogForm
          saving={isLoading}
          errorSaving={errorObject}
          onSubmit={run}
        />
      </GeneralDialog>
    </>
  );
};

CreateRoleContainer.propTypes = ({
  onSubmit: PropTypes.func.isRequired,
});

export default CreateRoleContainer;
