const APPCONFIG = {
  brand: "Model Governance Suite",
  AutoCloseMobileNav: true, // true, false. Automatically close sidenav on route change (Mobile only)
  color: {
    primary: "#607d8b",
    success: "#4ab405",
    info: "#66BB6A",
    infoAlt: "#7E57C2",
    warning: "#f49620",
    danger: "#b40721",
    text: "#3D4051",
    gray: "#dadada",
  },
  settings: {
    layoutBoxed: false, // true, false
    navCollapsed: false, // true, false
    navBehind: false, // true, false
    fixedHeader: true, // true, false
    sidebarWidth: "middle", // small, middle, large
    colorOption: "15", // String: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    theme: "light", // light, gray, dark
  },
  dateFormat: "DD/MM/YYYY",
  dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
  timeFormat: "HH:mm:SS",
  TIMEOUT_LONG_REQUEST: 2000,
  REFRESH_TIMEOUT: 30000,
  REFRESH_NOTIFICATION_TIMEOUT: 60000,
  LIST_SIZE: 15,
  TOKEN_MIN_EXPIRATION_VALIDITY: 2,
  WAITING_TIME_IN_MS_BEFORE_RETRY_AFTER_401: 500,
};

export default APPCONFIG;
