import React, { Component } from "react";
import { connect } from "react-redux";
import ListHosts from "../../Containers/ListHosts";

class DockerHostsPanel extends Component {
  render() {
    const { hosts } = this.props;
    return <ListHosts hosts={hosts} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    hosts: state.containers.list_hosts
  };
};
export default connect(
  mapStateToProps,
  {}
)(DockerHostsPanel);
