import React, {useEffect, useState} from 'react';

import { useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import InfoRow from 'components/Account/InfoRow';
import RedirectToAccountManagement from "./RedirectToAccountManagement";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

const AccountInfo = () => {

  const classes = useStyles();
  const me = useSelector(state => state.auth.me);

  const getChips = (items) => {
    if (!items || items.length === 0) {
      return null;
    } else {
      return items.map(item =>
        <Chip
          color="primary"
          variant="outlined"
          key={item.id}
          label={item.name}
          className={classes.chip}/>);
    }
  };

  return (
    <div className={'scrollable-content'}>
      <InfoRow label='Display name:' children={me.displayName}/>
      <InfoRow label='Email:' children={me.email}/>
      <InfoRow label='Timezone:' children={me.timezone}/>
      <InfoRow label='Role names:' children={getChips(me.roles)}/>
      <InfoRow label='Security levels:' children={getChips(me.securityLevels)}/>
      <InfoRow label=''>
        <RedirectToAccountManagement />
      </InfoRow>
    </div>
  );
};

export default AccountInfo;
