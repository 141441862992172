import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Drawer } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DatahubSearchForm from "components/Attachments/Search/form";
import { getAttachmentsSearch } from "actions/Attachments";
import { matchPath, withRouter } from "react-router";
import { getCurrentURLParams } from "../../../api/GUI";
import {
  getEntitiesPath,
  getEntityLabel,
  getEntityOverviewPath,
  getEntityTypeFromPath,
  getFolderPath,
  getStorageTypeTab,
  MODEL_REVISION_TYPE,
  MODEL_TYPE,
  PERSONAL_FOLDER_TYPE,
  PUBLIC_FOLDER_TYPE,
  SHARED_FOLDER_TYPE
} from "../../../api/Entity";
import APPCONFIG from "../../../constants/Config";
import ListItem from "components/Buttons/ListItem";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import FileIcon from "components/Attachments/File/Icon";
import moment from "moment";
import { getDownloadLink } from "../../../api/Attachments";
import {createDownloadLink} from "../../../actions/Uploads";
import { downloadFile } from '../../../api/File';
import {
  FOLDER_PATH,
  ID_REGEX,
  TAB_ATTACHMENTS,
  TAB_DOCUMENTS
} from "../../../constants/Routes";
import TabCentered from "components/TabContainer/TabCentered";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { NavigateNext } from "@material-ui/icons";
import { Link } from "react-router-dom";
import qs from "querystring";
import { isFeaturesAuthorized } from "../../../api/Authorities";
import { FULLTEXT_SEARCH } from "../../../constants/Features";

export const STORAGE_TYPE_DATA_HUB = "DATA_HUB";
export const STORAGE_TYPE_DOCUMENTS = "DOCUMENT_MANAGEMENT";

const DatahubSearch = ({
  getAttachmentsSearch,
  location,
  attachments,
  count,
  features
}) => {
  if (!isFeaturesAuthorized(features, [FULLTEXT_SEARCH])) {
    return null;
  }
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [form, setForm] = useState(null);
  const [context, setContext] = useState(null);

  useEffect(() => {
    search(form);
  }, [page, form, open]);

  useEffect(() => {
    let subFolder, entityId, entityType, storageTypePlatform;
    const query = getCurrentURLParams(location.search);
    subFolder = query["folder"];
    storageTypePlatform =
      query["tab"] === TAB_ATTACHMENTS
        ? STORAGE_TYPE_DATA_HUB
        : query["tab"] === TAB_DOCUMENTS
        ? STORAGE_TYPE_DOCUMENTS
        : null;
    entityType = getEntityTypeFromPath(location.pathname);
    if (entityType) {
      const entityPathMath = matchPath(location.pathname, {
        path: getEntityOverviewPath(entityType)
      });
      if (entityType === MODEL_REVISION_TYPE) {
        entityType = MODEL_TYPE;
      }
      if (entityPathMath && entityPathMath.params) {
        entityId = entityPathMath.params.entityId;
      }
    } else {
      const matchFolder = matchPath(location.pathname, { path: FOLDER_PATH });
      if (matchFolder) {
        entityId = matchFolder.params.folderId;
        switch (entityId) {
          case "personal":
            entityType = PERSONAL_FOLDER_TYPE;
            break;
          case "public":
            entityType = PUBLIC_FOLDER_TYPE;
            break;
          default:
            entityType = SHARED_FOLDER_TYPE;
            break;
        }
      }
    }
    setContext({
      subFolder,
      entityId,
      entityType,
      storageTypePlatform
    });
  }, [location]);

  const search = () => {
    if (!form || !open) {
      return null;
    }
    const {
      text,
      author,
      attachmentType,
      inCurrentFolder,
      inDocuments,
      from,
      till
    } = form;
    const dateFrom = from
      ? moment(from).format("YYYY-MM-DDT00:00:00.000Z")
      : null;
    const dateTill = till
      ? moment(till).format("YYYY-MM-DDT23:59:59.000Z")
      : null;
    if (inCurrentFolder) {
      const { subFolder, entityId, entityType, storageTypePlatform } = context;
      getAttachmentsSearch(
        page,
        APPCONFIG.LIST_SIZE,
        text,
        subFolder,
        entityId,
        entityType,
        storageTypePlatform,
        attachmentType,
        author,
        dateFrom,
        dateTill
      );
    } else {
      getAttachmentsSearch(
        page,
        APPCONFIG.LIST_SIZE,
        text,
        null,
        null,
        null,
        inDocuments ? STORAGE_TYPE_DOCUMENTS : null,
        attachmentType,
        author,
        dateFrom,
        dateTill
      );
    }
  };
  const handleScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
    if (bottom) {
      const _page = attachments
        ? Math.floor(attachments.length / APPCONFIG.LIST_SIZE)
        : 0;
      setPage(_page);
    }
  };
  const handleSubmit = _form => {
    if (_form === form) {
      search();
    } else {
      setForm(_form);
    }
  };

  const handleDownload = async (event, fileName, entityEnum, entityId, folder, storageTypePlatform) => {
    const href = getDownloadLink({
      fileName,
      entity: entityEnum,
      ownerId: entityId,
      prefix: folder,
      isFolder: false
    }, 
    storageTypePlatform === STORAGE_TYPE_DOCUMENTS, 
    true);

    const response = await createDownloadLink(href);
    const data = await response.data;
    downloadFile(data);
  };

  const renderResult = () => {
    if (!attachments || attachments.length === 0) {
      return (
        <TabCentered style={{ height: 200 }}>
          <div>No result found</div>
        </TabCentered>
      );
    }
    return (
      <>
        <Typography variant="body2" style={{ marginTop: 20 }}>
          {count} results
        </Typography>
        <List style={{ width: 600 }}>
          {attachments.map(
            ({
              documentId,
              details: {
                attachmentDetails: { contentType },
                uploaderUserName,
                entityEnum,
                typeId,
                typeLabel,
                entityLabel,
                fileName,
                uploadedDate,
                entityId,
                folder,
                highlightedFields: { content },
                storageTypePlatform
              }
            }) => {
              const entitiesPath = getEntitiesPath(entityEnum);
              const entitiesLabel = getEntityLabel(entityEnum);
              let overviewPath = getEntityOverviewPath(entityEnum);
              if (entityEnum === SHARED_FOLDER_TYPE) {
                overviewPath = getFolderPath(entityEnum, entityId);
              } else if (
                entityEnum !== PUBLIC_FOLDER_TYPE &&
                entityEnum !== PERSONAL_FOLDER_TYPE
              ) {
                overviewPath =
                  overviewPath
                    .replace(":entityType" + ID_REGEX, typeId)
                    .replace(":entityId" + ID_REGEX, entityId) +
                  "?" +
                  qs.stringify({
                    tab: getStorageTypeTab(storageTypePlatform),
                    highlightedDocument: documentId
                  });
              }
              const subfolderPaths =
                folder && folder !== "" && folder.split("/");

              return (
                <ListItem
                  key={documentId}
                  alignItems="flex-start"
                  button
                  target="_blank"
                  component="a"
                  onClick={() =>handleDownload(event, fileName, entityEnum, entityId, folder, storageTypePlatform)}
                >
                  <ListItemAvatar>
                    <FileIcon
                      mimetype={contentType}
                      extension={fileName.split(".").pop()}
                      size={40}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={fileName}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {`${uploaderUserName === null ? "(User removed)" : uploaderUserName} - ${moment(
                            uploadedDate
                          ).format(APPCONFIG.dateTimeFormat)}`}
                        </Typography>
                        <Breadcrumbs
                          separator={<NavigateNext fontSize="small" />}
                        >
                          <Link to={entitiesPath} onClick={(event) => {event.stopPropagation()}}>
                            <Typography variant="body2">
                              {entitiesLabel}
                            </Typography>
                          </Link>
                          {overviewPath ? (
                            <Link to={overviewPath} onClick={(event) => {event.stopPropagation()}}>
                              <Typography variant="body2">
                                {entityLabel}
                              </Typography>
                            </Link>
                          ) : null}
                          {subfolderPaths && subfolderPaths.length > 0
                            ? subfolderPaths.map((subfolderPath, idx) => {
                                if (subfolderPath === "") {
                                  return null;
                                }
                                const subfolderFullPath =
                                  "/" +
                                  subfolderPaths.slice(0, idx + 1).join("/") +
                                  "/";
                                return (
                                  <Link
                                    onClick={(event) => {event.stopPropagation()}}
                                    to={
                                      (entityEnum === PUBLIC_FOLDER_TYPE ||
                                      entityEnum === PERSONAL_FOLDER_TYPE
                                        ? entitiesPath
                                        : overviewPath) +
                                      (entityEnum === PUBLIC_FOLDER_TYPE ||
                                      entityEnum === PERSONAL_FOLDER_TYPE ||
                                      entityEnum === SHARED_FOLDER_TYPE
                                        ? "?"
                                        : "&") +
                                      qs.stringify({
                                        folder: subfolderFullPath,
                                        highlightedDocument: documentId
                                      })
                                    }
                                  >
                                    <Typography variant="body2">
                                      {subfolderPath}
                                    </Typography>
                                  </Link>
                                );
                              })
                            : null}
                        </Breadcrumbs>
                        {content ? (
                          <Typography style={{ marginTop: 5 }}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: content
                                  .slice(0, 5)
                                  .join(" ... ")
                                  .replace(/(<? *script)/gi, "illegalscript")
                              }}
                            />
                          </Typography>
                        ) : null}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              );
            }
          )}
        </List>
      </>
    );
  };

  return (
    <>
      <TextField
        testid="datahubsearchinput"
        label="Search in Data hub"
        value={form ? form.text : undefined}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setOpen(true);
          }
        }}
        onChange={e => setForm({ text: e.target.value })}
        type="search"
        variant="outlined"
        margin="dense"
        style={{ marginTop: 4 }}
      />
      <Drawer
        open={open}
        anchor="right"
        onClose={() => setOpen(false)}
        style={{ borderLeft: "solid 1px #f2f2f2" }}
      >
        <div style={{ padding: 20 }} onScroll={handleScroll}>
          <DatahubSearchForm
            initialValues={form}
            onSubmit={handleSubmit}
            context={context}
          />
          {renderResult()}
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    attachments: state.attachments.attachments_search,
    page: state.attachments.attachments_search_page,
    count: state.attachments.attachments_search_count,
    islast: state.attachments.attachments_search_islast,
    features: state.features.list
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      getAttachmentsSearch
    }
  )(DatahubSearch)
);
