import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Close, Info } from "@material-ui/icons";
import { Paper, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { LICENSE_OVERVIEW_PATH } from "../../constants/Routes";
import {
  getLicense,
  showLicenseBanner,
  hideLicenseBanner
} from "actions/License";

const LICENSE_MAINTENANCE_SUPPORT_EXPIRED =
  "LICENSE_MAINTENANCE_SUPPORT_EXPIRED";
const LICENSE_FAILED_VERIFY_LICENSE = "LICENSE_FAILED_VERIFY_LICENSE";
const LICENSE_USING_LICENSE_TYPE = "LICENSE_USING_LICENSE_TYPE";

const WarningBanner = ({
  getLicense,
  showLicenseBanner,
  hideLicenseBanner,
  license,
  classes,
  showBanner
}) => {
  useEffect(() => {
    getLicense();
    showLicenseBanner();
  }, []);

  const handleWarningClose = () => {
    hideLicenseBanner();
  };

  let paperClassName;

  if (!showBanner || !license) {
    return null;
  }

  const { licenseTypeName, environmentTypeName, messages } = license;
  let content;

  if (messages && messages.length > 0) {
    const messageKey = messages.sort((a, b) => {
      if (a.severity === "ERROR") {
        return -1;
      }
      if (b.severity === "ERROR") {
        return 1;
      }
      return 0;
    })[0].messageKey;
    switch (messageKey) {
      case LICENSE_MAINTENANCE_SUPPORT_EXPIRED:
        paperClassName = classes.paperWarning;
        content = (
          <span>
            {
              " Your maintenance support has expired and requires attention. Show "
            }
            <Link
              style={{ color: "inherit", textDecoration: "underline" }}
              to={LICENSE_OVERVIEW_PATH + "?tab=license_info"}
            >
              license
            </Link>
            {"."}
          </span>
        );
        break;
      case LICENSE_FAILED_VERIFY_LICENSE:
        paperClassName = classes.paperWarning;
        content = (
          <span>
            {
              " Failed to verify license validity. Please, contact your administrator."
            }
          </span>
        );
        break;
      case LICENSE_USING_LICENSE_TYPE:
        paperClassName = classes.paperInfo;
        content = (
          <span>
            {" You are using "}
            {environmentTypeName ? environmentTypeName : "unknown"}{" "}
            <Link
              style={{ color: "inherit", textDecoration: "underline" }}
              to={LICENSE_OVERVIEW_PATH + "?tab=license_info"}
            >
              license
            </Link>
            {". "}
          </span>
        );
        break;
      default:
        return null;
    }
  }
  return (
    <Paper className={paperClassName}>
      <Info style={{ fontSize: "16px", verticalAlign: "text-bottom" }} />
      {content}
      <IconButton
        className={classes.closeButton}
        onClick={handleWarningClose}
        aria-label="Close"
      >
        <Close style={{ margin: 0 }} />
      </IconButton>
    </Paper>
  );
};

const styles = theme => ({
  paperWarning: {
    height: "40px",
    backgroundColor: "#d04437",
    color: "white",
    textAlign: "center",
    padding: "8px",
    borderRadius: "0px"
  },
  paperInfo: {
    height: "40px",
    backgroundColor: theme.palette.secondary.main,
    color: "#594300",
    textAlign: "center",
    padding: "8px",
    borderRadius: "0px"
  },
  closeButton: {
    padding: "2px",
    color: "inherit",
    float: "right"
  }
});

const mapStateToProps = state => ({
  license: state.license.license,
  showBanner: state.license.showBanner
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      getLicense,
      showLicenseBanner,
      hideLicenseBanner
    }
  )(WarningBanner)
);
