import React from "react";
import {useDispatch} from "react-redux";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import {Link} from "@material-ui/icons";
import FileCopy from '@material-ui/icons/FileCopyOutlined';
import {Box, Grid} from "@material-ui/core";
import {pushNotification} from "actions/notifications";

const InfoDialogItem = ({rowToShow}) => {
    const dispatch = useDispatch();

    const handleCopyID = id => {
        navigator.clipboard.writeText(id).then(
            function () {
                dispatch(pushNotification("check", "success", "ID copied to clipboard."));
            }
        );
    };
    const handleCopyURL = (href) => {
        navigator.clipboard.writeText(location.origin + href + location.search).then(
            function () {
                dispatch(pushNotification("check", "success", "Link copied to clipboard."));
            }
        );
    };
    
        return <Grid container alignItems="center">
                <Grid item xs={3}>
                    <Box fontWeight="fontWeightBold" style={{display: 'inline-flex', padding: "0 10px"}}>
                        {rowToShow.title}
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    {rowToShow.id}
                </Grid>
                <Grid item xs={3}>
                    <IconButtonLoading
                        style={{display: "inline-block", height: 44}}
                        color="primary"
                        tooltipTitle="Copy ID"
                        aria-label="Copy ID"
                        onClick={() => handleCopyID(rowToShow.id)}
                    >
                        <FileCopy/>
                    </IconButtonLoading>
                    <IconButtonLoading
                        style={{display: "inline-block", height: 44}}
                        color="primary"
                        tooltipTitle="Copy link"
                        aria-label="Copy link"
                        onClick={() => handleCopyURL(rowToShow.href)}
                    >
                        <Link/>
                    </IconButtonLoading>
                </Grid>
            </Grid>;
};

export default InfoDialogItem;
