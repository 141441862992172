import React from 'react';

import { useSelector } from 'react-redux';

import AnonymousNavRightList from 'components/Header/AnonymousNavRightList';
import DatahubSearch from 'components/Attachments/Search';
import UserMenu from 'components/Header/UserMenu';
import ContainersMenu from '../Containers/MenuHeader';
import NotificationMenu from '../Notification/MenuHeader';
import TasksMenu from '../Tasks/MenuHeader';

export const HeaderIconButtonStyle = {
  width: 40,
  minWidth: 40,
  padding: 0,
  height: 60,
  color: '#607d8b',
};

const NavRightList = () => {

  const me = useSelector((state) => state.auth.me);

  if (!me || !me.id) {
    return (
      <AnonymousNavRightList />
    );
  }

  return (
    <ul className="list-unstyled float-right">
      <li className="list-inline-item">
        <DatahubSearch/>
      </li>
      <TasksMenu/>
      <ContainersMenu/>
      <NotificationMenu/>
      <UserMenu user={me}/>
    </ul>
  );
};

export default NavRightList;
