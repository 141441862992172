import React from "react";
import Button from "components/Buttons/Button";
import { Field, reduxForm } from "redux-form";
import { RequiredValidator } from "components/Form/Fields/FieldValidators";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import { DialogContent, Dialog, DialogActions } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "api/Error";

const ExecuteDelegateDialog = reduxForm({
  form: "executedelegatedialog",
  onSubmitFail: scrollToFirstError
})(({ handleSubmit, handleClose, open }) => {
    return (
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleSubmit}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleClose}
          >
            Delegate the task
          </CloseableDialogTitle>
          <DialogContent>
            <div>
              <Field
                label={"Delegate the task to"}
                name="targetId"
                component={AutoSuggestSelectField}
                validate={[RequiredValidator]}
                apiUrl="/api/users/lookup"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {"Delegate the task"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
});
export default ExecuteDelegateDialog;
