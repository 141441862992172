import React, { Component } from "react";
import TabCentered from "../TabContainer/TabCentered";
import moment from "moment";

import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TablePagination
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import APPCONFIG from "constants/Config";

class AuditTrails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10
    };
  }
  refreshTimeout = null;

  componentWillReceiveProps(nextProps) {
    const { entity } = nextProps;
    if (this.props.entity !== entity) {
      if (entity && this.props.entity && this.props.entity.id !== entity.id) {
        this.refreshTab(nextProps);
      }
    }
  }
  componentDidMount() {
    this.refreshTab(this.props);
  }
  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { rowsPerPage, page } = this.state;
    if (rowsPerPage !== nextState.rowsPerPage) {
      return this.refresh(nextProps, nextState);
    }
    if (page !== nextState.page) {
      return this.refresh(nextProps, nextState);
    }
  }

  refreshTabTimer = () => {
    this.refreshTab(this.props);
  };

  refreshTab = props => {
    const { entity } = props;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTabTimer();
    }, 30000);

    if (!entity) {
      return;
    }
    this.refresh(props, this.state);
  };

  refresh = (props, state) => {
    const { onRefresh } = props;
    const { rowsPerPage, page } = state;
    onRefresh(page * rowsPerPage, rowsPerPage);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  renderAuditTrail = (auditTrail, idx) => {
    const {
      message,
      timestamp,
    } = auditTrail;
    const { classes } = this.props;

    return (
      <ListItem key={idx} alignItems="flex-start" testid="historyitem">
        <ListItemText
          primary={
            <React.Fragment>
              <Typography variant="body1" className={classes.inline}>
                {message}
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>{moment(timestamp).format(APPCONFIG.dateTimeFormat)}</React.Fragment>
          }
        />
      </ListItem>
    );
  };

  render() {
    const { entity, classes } = this.props;
    const { page, rowsPerPage } = this.state;
    const hasAuditTrails =
      entity &&
      entity.auditTrails &&
      entity.auditTrails.result &&
      entity.auditTrails.result.length > 0;
    let list;

    if (hasAuditTrails) {
      const pagination = (
        <TablePagination
          style={{ margin: "auto" }}
          component="div"
          count={entity.auditTrails.totalResultCount}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      );
      list = (
        <div className={"scrollable-container"}>
          <div className={"scrollable-content"}>
          <Grid container justify="center" className={classes.root}>
            <Grid item xs={11}>
              <List className={classes.root}>
                {entity.auditTrails.result.map(this.renderAuditTrail)}
              </List>
            </Grid>
          </Grid>
          </div>
          <div className={"scrollable-footer"} style={{margin: "auto"}}>
          {pagination}
          </div>
        </div>
      );
    } else if (entity.loading_audit_trails) {
      list = (
        <TabCentered>
          <div style={{ padding: "20px", textAlign: "center" }}>
            <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
              Loading the history
            </span>
          </div>
        </TabCentered>
      );
    } else {
      list = (
        <TabCentered>
          <div style={{ padding: "20px" }} className="emptyMessage">
            No history available
          </div>
        </TabCentered>
      );
    }

    return <React.Fragment>{list}</React.Fragment>;
  }
}

const styles = theme => ({
  root: {
    width: "100%"
  },
  inline: {
    display: "inline"
  }
});

export default withStyles(styles)(AuditTrails);
