import {
  LOADING_CONTAINERS,
  END_LOADING_CONTAINERS,
  CONTAINERS_ERROR,
  GET_CONTAINERS,
  LOADING_DOKER_HOSTS,
  END_LOADING_DOKER_HOSTS,
  DOKER_HOSTS_ERROR,
  GET_DOKER_HOSTS,
  GET_CONTAINERS_USER,
  LOADING_CONTAINERS_USER,
  END_LOADING_CONTAINERS_USER,
  CONTAINERS_USER_ERROR,
  KILLING_CONTAINER,
  END_KILLING_CONTAINER,
  ERROR_KILLING_CONTAINER,
  KILL_CONTAINER,
  SYNCUPING_CONTAINER,
  END_SYNCUPING_CONTAINER,
  ERROR_SYNCUPING_CONTAINER,
  SYNCDOWNING_CONTAINER,
  END_SYNCDOWNING_CONTAINER,
  ERROR_SYNCDOWNING_CONTAINER,
  ADD_CONTAINER,
  PASSWORD_TO_COPY,
  GET_CONTAINER,
  OPENING_ANALYTICAL_TOOL,
  END_OPENING_ANALYTICAL_TOOL,
  ERROR_OPENING_ANALYTICAL_TOOL
} from "../../constants/ActionTypes";
import { goThroughList } from "../../api/List";

export default function(state = {}, action) {
  let result, resultUser;
  const list = state.list ? state.list : [];
  const listUser = state.list_user ? state.list_user : [];
  const containerId = action.containerId;
  const container = action.container;

  switch (action.type) {
    case GET_CONTAINERS:
      return {
        ...state,
        list: action.containers,
        error: undefined
      };
    case LOADING_CONTAINERS:
      return { ...state, loading: true, error: undefined };
    case END_LOADING_CONTAINERS:
      return { ...state, loading: false };
    case CONTAINERS_ERROR:
      return { ...state, error: action.error };

    case GET_DOKER_HOSTS:
      return {
        ...state,
        list_hosts: action.doker_hosts,
        error_hosts: undefined
      };
    case LOADING_DOKER_HOSTS:
      return { ...state, loading_hosts: true, error_hosts: undefined };
    case END_LOADING_DOKER_HOSTS:
      return { ...state, loading_hosts: false };
    case DOKER_HOSTS_ERROR:
      return { ...state, error_hosts: action.error };

    case OPENING_ANALYTICAL_TOOL:
      return {
        ...state,
        opening_analytical_tool: true,
        error_opening_analytical_tool: undefined
      };
    case END_OPENING_ANALYTICAL_TOOL:
      return { ...state, opening_analytical_tool: false };
    case ERROR_OPENING_ANALYTICAL_TOOL:
      return { ...state, error_opening_analytical_tool: action.error };
    case ADD_CONTAINER:
      console.log(container);
      return {
        ...state,
        last_container_created: container,
        list: [...list, container],
        list_user: [...listUser, container]
      };
    case PASSWORD_TO_COPY:
      return {
        ...state,
        password: action.password
      };

    case GET_CONTAINERS_USER:
      return {
        ...state,
        list_user: action.containers,
        error_user: undefined
      };
    case LOADING_CONTAINERS_USER:
      return { ...state, loading_user: true, error_user: undefined };
    case END_LOADING_CONTAINERS_USER:
      return { ...state, loading_user: false };
    case CONTAINERS_USER_ERROR:
      return { ...state, error_user: action.error };

    case GET_CONTAINER:
      result = goThroughList(
        list,
        null,
        container.containerId,
        container2 => {
          return container;
        },
        "containerId"
      );
      resultUser = goThroughList(
        listUser,
        null,
        containerId,
        container => {
          return container;
        },
        "containerId"
      );
      return {
        ...state,
        list: result.list,
        list_user: resultUser.list
      };

    case KILL_CONTAINER:
      result = goThroughList(
        list,
        null,
        containerId,
        container => {
          return null;
        },
        "containerId"
      );
      resultUser = goThroughList(
        listUser,
        null,
        containerId,
        container => {
          return null;
        },
        "containerId"
      );
      return {
        ...state,
        list: result.list,
        list_user: resultUser.list
      };
    case KILLING_CONTAINER:
      result = goThroughList(
        list,
        null,
        containerId,
        container => {
          return {
            ...container,
            killing: true,
            errorKill: undefined
          };
        },
        "containerId"
      );
      resultUser = goThroughList(
        listUser,
        null,
        containerId,
        container => {
          return {
            ...container,
            killing: true,
            errorKill: undefined
          };
        },
        "containerId"
      );
      return {
        ...state,
        list: result.list,
        list_user: resultUser.list
      };
    case END_KILLING_CONTAINER:
      result = goThroughList(
        list,
        null,
        containerId,
        container => {
          return {
            ...container,
            killing: false
          };
        },
        "containerId"
      );
      resultUser = goThroughList(
        listUser,
        null,
        containerId,
        container => {
          return {
            ...container,
            killing: false
          };
        },
        "containerId"
      );
      return {
        ...state,
        list: result.list,
        list_user: resultUser.list
      };
    case ERROR_KILLING_CONTAINER:
      result = goThroughList(
        list,
        null,
        containerId,
        container => {
          return {
            ...container,
            errorKill: action.error
          };
        },
        "containerId"
      );
      resultUser = goThroughList(
        listUser,
        null,
        containerId,
        container => {
          return {
            ...container,
            errorKill: action.error
          };
        },
        "containerId"
      );
      return {
        ...state,
        list: result.list,
        list_user: resultUser.list
      };

    case SYNCUPING_CONTAINER:
      result = goThroughList(
        list,
        null,
        containerId,
        container => {
          return {
            ...container,
            errorSyncUp: undefined,
            syncuping: true
          };
        },
        "containerId"
      );
      resultUser = goThroughList(
        listUser,
        null,
        containerId,
        container => {
          return {
            ...container,
            errorSyncUp: undefined,
            syncuping: true
          };
        },
        "containerId"
      );
      return {
        ...state,
        list: result.list,
        list_user: resultUser.list
      };
    case END_SYNCUPING_CONTAINER:
      result = goThroughList(
        list,
        null,
        containerId,
        container => {
          return {
            ...container,
            syncuping: false
          };
        },
        "containerId"
      );
      resultUser = goThroughList(
        listUser,
        null,
        containerId,
        container => {
          return {
            ...container,
            syncuping: false
          };
        },
        "containerId"
      );
      return {
        ...state,
        list: result.list,
        list_user: resultUser.list
      };
    case ERROR_SYNCUPING_CONTAINER:
      result = goThroughList(
        list,
        null,
        containerId,
        container => {
          return {
            ...container,
            errorSyncUp: action.error
          };
        },
        "containerId"
      );
      resultUser = goThroughList(
        listUser,
        null,
        containerId,
        container => {
          return {
            ...container,
            errorSyncUp: action.error
          };
        },
        "containerId"
      );
      return {
        ...state,
        list: result.list,
        list_user: resultUser.list
      };

    case SYNCDOWNING_CONTAINER:
      result = goThroughList(
        list,
        null,
        containerId,
        container => {
          return {
            ...container,
            errorSyncDown: undefined,
            syncdowning: true
          };
        },
        "containerId"
      );
      resultUser = goThroughList(
        listUser,
        null,
        containerId,
        container => {
          return {
            ...container,
            errorSyncDown: undefined,
            syncdowning: true
          };
        },
        "containerId"
      );
      return {
        ...state,
        list: result.list,
        list_user: resultUser.list
      };
    case END_SYNCDOWNING_CONTAINER:
      result = goThroughList(
        list,
        null,
        containerId,
        container => {
          return {
            ...container,
            syncdowning: false
          };
        },
        "containerId"
      );
      resultUser = goThroughList(
        listUser,
        null,
        containerId,
        container => {
          return {
            ...container,
            syncdowning: false
          };
        },
        "containerId"
      );
      return {
        ...state,
        list: result.list,
        list_user: resultUser.list
      };
    case ERROR_SYNCDOWNING_CONTAINER:
      result = goThroughList(
        list,
        null,
        containerId,
        container => {
          return {
            ...container,
            errorSyncDown: action.error
          };
        },
        "containerId"
      );
      resultUser = goThroughList(
        listUser,
        null,
        containerId,
        container => {
          return {
            ...container,
            errorSyncDown: action.error
          };
        },
        "containerId"
      );
      return {
        ...state,
        list: result.list,
        list_user: resultUser.list
      };

    default:
      return state;
  }
}
