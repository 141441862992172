import {TASK_STATUS_COMPLETED} from "constants/Task";
import {getSortingTasks} from "api/Sort";
import TasksList from "components/Tasks/TasksPanel/TasksList";
import React, {useEffect, useState} from "react";

const ActiveTasksList = ({tasks, showEntity, withWorkflowContext}) => {
  const [notCompletedTasks, setNotCompletedTasks] = useState([]);

  useEffect(()=>{
    setNotCompletedTasks(tasks.filter(
      task => task && task.status !== TASK_STATUS_COMPLETED
    ).sort(getSortingTasks));
  },[tasks]);

  return <TasksList tasks={notCompletedTasks} showEntity={showEntity} withWorkflowContext={withWorkflowContext} />
};

export default ActiveTasksList;