import React, { Component } from 'react';
import { withRouter } from 'react-router';
import classnames from 'classnames';

import { Avatar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LinkEntity from "components/Entity/LinkEntity";

const styles = theme => ({
    avatar: {
        width: 20,
        height: 20,
        fontSize: 10,
        display: 'inline-flex',
        marginLeft: "-10px",
        border: "solid 1px white",
        backgroundColor: "#607d8b"
    },
    avatarFirst: {
        marginLeft: 0,
    },
    avatarContainer: {
        display: 'inline-flex',
        marginRight: 5,
        verticalAlign: "middle"
    },
    committee: {
        display: 'inline-flex',
        verticalAlign: "middle"
    },
});

class CommitteeThumbnail extends Component {
    render() {
        const { classes, committee, typoStyle } = this.props;
        if (!committee) {
            return (
                <Typography className={classes.committee} variant="body2" testid="linkedCommittee">
                    Without committee
                </Typography>
            )
        }
        let participants;
        if (committee.participations && committee.participations.length > 0) {
            participants = (
                <div className={classes.avatarContainer}>
                    {
                        committee.participations.map((participation, idx) => {
                            let className = classes.avatar;
                            if (idx === 0) {
                                className = classnames(classes.avatar, classes.avatarFirst)
                            }
                            return (
                                <Tooltip key={idx} title={participation.appUser.label}>
                                    <Avatar color="primary" className={className}>
                                        { participation.appUser.label.substring(0,1) }
                                    </Avatar>
                                </Tooltip>
                            );
                        })
                    }
                </div>
            );
        }
        return (
            <LinkEntity entity={committee} style={{display:"inherit"}} testid="linkedCommittee">
                { participants }
                <Typography className={classnames(classes.committee, typoStyle)} variant="body2">
                    { committee.label || "No committee name" }
                </Typography>
            </LinkEntity>
        )
    };
}

export default withRouter((withStyles(styles)(CommitteeThumbnail)));
