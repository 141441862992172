import React from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getEntityLink, getEntityLinkViewFromPath, isAuthorizedToViewEntity } from 'api/Entity';

const LinkEntity = (props) => {
  const { entity, me, ...otherProps } = props;

  if (!isAuthorizedToViewEntity(me, entity)) {
    return <div {...otherProps} />
  }

  if (entity.path) {
    return <Link {...getEntityLinkViewFromPath(entity.path)} {...otherProps} />;
  }

  return <Link {...getEntityLink(entity)} {...otherProps} />;
}

const mapStateToProps = (state) => {
  return {
    me: state.auth.me
  };
};

export default connect(
  mapStateToProps,
  {}
)(LinkEntity);
