import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import classnames from "classnames";
import IconButton from "../../Buttons/IconButton";
import Button from "../../Buttons/Button";
import ValidationModels from "./Models/List";
import CommitteeThumbnail from "../Committees/thumbnail";
import { unassignModel } from "../../../actions/Validations";
import { getEntity } from "../../../actions/Entity";
import { VALIDATION_TYPE } from "../../../api/Entity";
import EntityTag from "components/Entity/Tag";
import { Close, ExpandMore } from "@material-ui/icons";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Tooltip,
  Typography,
  Grid
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  card: {},
  actions: {
    display: "flex",
    padding: "8px 9px"
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8
    }
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5
  },
  modelLengthContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  statusCaption: {
    display: "flex",
    marginRight: 5
  },
  statusValue: {
    display: "flex"
  }
});

class ValidationCard extends Component {
  state = {
    expanded: false
  };

  handleExpandClick = event => {
    const { getEntity, validation } = this.props;
    getEntity(validation.id);
    this.setState(state => ({ expanded: !state.expanded }));
  };
  unassign = modelRevision => {
    const { unassignModel, validation } = this.props;
    unassignModel(validation.id, modelRevision.id);
  };

  render() {
    const {
      classes,
      validation,
      onClose,
      onAction,
      actionName,
      actionAuthoritiesRequired
    } = this.props;
    if (!validation) {
      return null;
    }
    let committee,
      title = validation.label || validation.id,
      action;

    committee = (
      <CommitteeThumbnail committee={validation.validationCommittee} />
    );

    if (onClose) {
      action = (
        <IconButton onClick={onClose} testid="closeValidationCard">
          <Close />
        </IconButton>
      );
    }

    return (
      <Card className={classes.card} testid="linkedValidation">
        <CardHeader
          avatar={<EntityTag entityType={VALIDATION_TYPE} />}
          action={action}
          title={title}
          subheader={committee}
          titleTypographyProps={{
            variant: "subtitle1",
            style: { fontWeight: "bold" }
          }}
        />
        <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <div className={classes.modelLengthContainer}>
            <Typography variant="caption" className={classes.statusCaption}>
              Models linked:
            </Typography>
            <Typography variant="body1" className={classes.statusValue}>
              {validation.modelRevisions ? validation.modelRevisions.length : 0}
            </Typography>
          </div>
        </CardContent>
        <CardActions className={classes.actions} disableSpacing>
          <Button
            authoritiesRequired={actionAuthoritiesRequired}
            color="primary"
            onClick={onAction}
          >
            {actionName}
          </Button>
          {validation.modelRevisions && validation.modelRevisions.length > 0 && (
            <Tooltip title="Show the models">
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: this.state.expanded
                })}
                onClick={this.handleExpandClick}
                aria-expanded={this.state.expanded}
                aria-label="Show more"
              >
                <ExpandMore />
              </IconButton>
            </Tooltip>
          )}
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <ValidationModels validation={validation} />
        </Collapse>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      {
        unassignModel,
        getEntity: getEntity(VALIDATION_TYPE)
      }
    )(ValidationCard)
  )
);
