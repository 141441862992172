import {
  GET_AUDIT_TRAILS,
  LOADING_AUDIT_TRAILS,
  END_LOADING_AUDIT_TRAILS,
  ERROR_LOADING_AUDIT_TRAILS
} from "../../constants/ActionTypes";
import axios from "axios";
import qs from "querystring";

export function getAuditTrails(
  entity,
  attributeName,
  changedByUser,
  firstResult,
  maxResultCount
) {
  return function(dispatch) {
    dispatch({
      type: LOADING_AUDIT_TRAILS,
      ownerId: entity.id
    });

    const queryParams = qs.stringify({
      entityId: entity.id,
      attributeName,
      changedByUser,
      firstResult: firstResult,
      maxResultCount: maxResultCount
    });

    axios
      .get("/api/attribute-history-values/find?" + queryParams, {
        withCredentials: true
      })
      .then(response => {
        let auditTrails = response.data;
        dispatch({
          type: GET_AUDIT_TRAILS,
          auditTrails: auditTrails && auditTrails.length > 0 && auditTrails[0],
          ownerId: entity.id
        });
        dispatch({
          type: END_LOADING_AUDIT_TRAILS,
          ownerId: entity.id
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_LOADING_AUDIT_TRAILS,
          error: message,
          ownerId: entity.id
        });
        dispatch({
          type: END_LOADING_AUDIT_TRAILS,
          ownerId: entity.id
        });
      });
  };
}
