import {
  GET_DATE_ASSISTANT_OPTIONS,
  LOADING_DATE_ASSISTANT_OPTIONS,
  END_GETING_DATE_ASSISTANT_OPTIONS,
  ERROR_GETING_DATE_ASSISTANT_OPTIONS,
  SET_EXPORT_DATES,
  ADD_EXPORT_DATE,
  REMOVE_EXPORT_DATE,
  SET_EXPORT_FORMAT,
} from "../../constants/ActionTypes";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_DATE_ASSISTANT_OPTIONS:
      return {
        ...state,
        options: action.options,
        error: undefined,
      };
    case LOADING_DATE_ASSISTANT_OPTIONS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case END_GETING_DATE_ASSISTANT_OPTIONS:
      return { ...state, loading: false };
    case ERROR_GETING_DATE_ASSISTANT_OPTIONS:
      return { ...state, error: action.error };

    case SET_EXPORT_DATES:
      return { ...state, [action.entityType]: action.exportDates || [] };
    case ADD_EXPORT_DATE:
      const dates = state[action.entityType] || [];
      return {
        ...state,
        [action.entityType]: [...dates, action.inputDate],
      };
    case REMOVE_EXPORT_DATE:
      const filteredDates = state[action.entityType].filter(
        date => date !== action.inputDate
      );
      return { ...state, [action.entityType]: filteredDates };

    case SET_EXPORT_FORMAT:
      return { ...state, selectedFormat: action.selectedFormat };

    default:
      return state;
  }
}
