import React, {Component} from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {ViewList, ViewModule} from "@material-ui/icons";
import {connect} from "react-redux";
import {changeAttachmentView} from "actions/index";
import { withStyles, Grid } from "@material-ui/core";
import DirectoryPath from "components/Attachments/DirectoryPath";
import { LIST_MODE } from 'constants/Attachments';

class AttachmentToolbar extends Component {

    handleChangeMode = (event, mode) => {
        const { changeAttachmentView } = this.props;
        changeAttachmentView(mode);
    };

    render() {
        const { view, classes, withSubfolder, currentSubfolder, onSubfolderChange, draggedFiles, onDrop, rootPathName } = this.props;

        return (
            <Grid container justify="space-between" alignItems="center" className={classes.root}>
                <Grid item>
                    {
                        withSubfolder && (
                            <DirectoryPath
                                rootPathName={rootPathName}
                                onDrop={onDrop}
                                currentSubfolder={currentSubfolder}
                                onSubfolderChange={onSubfolderChange}
                                isDragging={Boolean(draggedFiles)}/>
                        )
                    }
                </Grid>
                <Grid item>
                    <ToggleButtonGroup value={view} exclusive onChange={this.handleChangeMode}>
                        <ToggleButton icon="icon" value="list">
                            <ViewList />
                        </ToggleButton>
                        <ToggleButton icon="icon" value="grid">
                            <ViewModule />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        );
    }
}


const styles = theme => ({
    root: {
        paddingRight: 10,
        margin: 0
    }
});


const mapStateToProps = (state, ownProps) => {
    return {
        types: state.attachments.types,
        view: state.settings.attachment_view || LIST_MODE
    };
};

export default withStyles(styles)(connect(
    mapStateToProps, { changeAttachmentView }
)(AttachmentToolbar));