import React, { PureComponent } from 'react';
import { FormControl as FormControlMaterial } from '@material-ui/core';

class FormControl extends PureComponent {
    render() {
        const { disabled, input: { name }, meta: { touched, error, warning, submitFailed }, children, withoutError } = this.props;
        let className = "form-control input-row";
        if (submitFailed && error && !disabled) {
            className += " input-error";
        }
        const warningProps = this.props.warning;
        return (
            <FormControlMaterial color="primary" className={className} testid={name}>
                { children }
                {
                    !withoutError && warning && !disabled && (
                        <span className="warning">{warning}</span>
                    )
                }
                {
                    !withoutError && warningProps && !disabled && (
                        <span className="warning">{warningProps}</span>
                    )
                }
                {
                    !withoutError && submitFailed && error && !disabled && (
                        <span className="error">{error}</span>
                    )
                }
            </FormControlMaterial>
        );
    }
}

export default FormControl;
