import React, {useEffect} from "react";
import {Redirect} from "react-router";
import {ID_REGEX, MODEL_REVISION_OVERVIEW_PATH} from "constants/Routes";
import {useDispatch, useSelector} from "react-redux";
import {getEntityTypeId, MODEL_REVISION_TYPE} from "api/Entity";
import {getEntity} from "actions/Entity";

const ModelRevisionShortcut = ({match}) => {
    const revision = useSelector(state => state.modelRevisions.current);
    const error = useSelector(state => state.entity[MODEL_REVISION_TYPE] && state.entity[MODEL_REVISION_TYPE].error);
    const loading = useSelector(state => state.entity[MODEL_REVISION_TYPE] && state.entity[MODEL_REVISION_TYPE].loading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEntity(MODEL_REVISION_TYPE)(match.params.revisionId));
    }, []);

    if (error) {
        return <Redirect to={{pathname: "/404"}}/>
    }
    if (loading || !revision) {
        return <div id="loader-container"/>;
    }
    const pathname =
        MODEL_REVISION_OVERVIEW_PATH
            .replace(":entityType" + ID_REGEX, getEntityTypeId(revision.model))
            .replace(":entityId" + ID_REGEX, revision.model.id)
            .replace(":revisionId" + ID_REGEX, revision.id);
    return <Redirect to={{pathname, search: location.search}}/>
};

export default ModelRevisionShortcut;