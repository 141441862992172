import {
  GET_FOLDERS,
  LOADING_FOLDERS,
  END_LOADING_FOLDERS,
  ERROR_FOLDERS,
  GET_FOLDER,
  LOADING_FOLDER,
  END_LOADING_FOLDER,
  ERROR_FOLDER,
  CREATE_FOLDER,
  CREATING_FOLDER,
  END_CREATING_FOLDER,
  ERROR_CREATING_FOLDER,
  DELETE_FOLDER,
  DELETING_FOLDER,
  END_DELETING_FOLDER,
  ERROR_DELETING_FOLDER,
  PUSH_NOTIFICATION,
  UPDATING_FOLDER,
  END_UPDATING_FOLDER,
  ERROR_UPDATING_FOLDER
} from "../../constants/ActionTypes";
import axios from "axios";
import { isFeaturesAuthorized } from "../../api/Authorities";
import {
  ROLE_PERSONAL_STORAGE,
  ROLE_PUBLIC_FOLDER_VIEW,
  ROLE_SHARED_FOLDER_MANAGE,
  ROLE_SHARED_FOLDER_VIEW
} from "../../constants/Authorities";
import {
  DATAHUB_SHARED_FOLDER,
  DATAHUB_PUBLIC,
  DATAHUB_PERSONAL
} from "../../constants/Features";

const PUBLIC_FOLDER = {
  id: "public",
  featuresRequired: [DATAHUB_PUBLIC],
  authoritiesRequired: [ROLE_PUBLIC_FOLDER_VIEW],
  isRootSharedFolderEntity: true,
  label: "Public folder",
  name: "Public folder",
  description: "This folder is public, everybody can access it",
  securityLevels: [
    {
      name: "Public"
    }
  ]
};
const PERSONAL_FOLDER = {
  id: "personal",
  featuresRequired: [DATAHUB_PERSONAL],
  authoritiesRequired: [ROLE_PERSONAL_STORAGE],
  isPersonalFolderEntity: true,
  label: "Personal folder",
  name: "Personal folder",
  description: "This folder is private, you are the only one who can access it",
  securityLevels: [
    {
      name: "Private"
    }
  ]
};

//get enabled features here and restrict as with authorities
export function getFolders(features) {
  return function(dispatch) {
    if (!isFeaturesAuthorized(features, [DATAHUB_SHARED_FOLDER])) {
      dispatch({
        type: GET_FOLDERS,
        folders: [PERSONAL_FOLDER, PUBLIC_FOLDER]
      });
      return;
    }

    dispatch({
      type: LOADING_FOLDERS
    });

    axios
      .get("/api/shared-folders", {
        withCredentials: true
      })
      .then(response => {
        let folders = response.data;
        if (!folders) {
          folders = [];
        }

        folders = [
          PERSONAL_FOLDER,
          PUBLIC_FOLDER,
          ...folders.map(folder => {
            folder.authoritiesRequired = [ROLE_SHARED_FOLDER_VIEW];
            folder.featuresRequired = [DATAHUB_SHARED_FOLDER];
            return folder;
          })
        ];

        dispatch({
          type: GET_FOLDERS,
          folders: folders
        });
        dispatch({
          type: END_LOADING_FOLDERS
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_FOLDERS,
          error: message
        });
        dispatch({
          type: END_LOADING_FOLDERS
        });
      });
  };
}

export function getFolder(folderId) {
  return function(dispatch) {
    switch (folderId) {
      case "public":
        dispatch({
          type: GET_FOLDER,
          folder: PUBLIC_FOLDER
        });
        return;
      case "personal":
        dispatch({
          type: GET_FOLDER,
          folder: PERSONAL_FOLDER
        });
        return;
    }

    dispatch({
      type: LOADING_FOLDER
    });

    axios
      .get("/api/shared-folders/" + folderId, {
        withCredentials: true
      })
      .then(response => {
        let folder = response.data;
        folder.isSharedFolderEntity = true;
        folder.authoritiesRequired = [ROLE_SHARED_FOLDER_VIEW];
        folder.featuresRequired = [DATAHUB_SHARED_FOLDER];

        dispatch({
          type: GET_FOLDER,
          folder: folder
        });
        dispatch({
          type: END_LOADING_FOLDER
        });
      })
      .catch(err => {
        if (
          err.response.status === 404 ||
          err.response.data.status === "NOT_FOUND"
        ) {
          dispatch({
            type: ERROR_FOLDER,
            error: "NOT_FOUND"
          });
        } else {
          let message = err.response.data.message;
          if (!message) {
            message = err.response.data.status;
          }
          console.log(message);
          dispatch({
            type: ERROR_FOLDER,
            error: message
          });
        }
        dispatch({
          type: END_LOADING_FOLDER
        });
      });
  };
}
export function createFolder(folder) {
  return function(dispatch) {
    dispatch({
      type: CREATING_FOLDER
    });

    axios
      .post("/api/shared-folders", folder, {
        withCredentials: true
      })
      .then(response => {
        let folder = response.data;
        folder.isSharedFolderEntity = true;
        folder.authoritiesRequired = [ROLE_SHARED_FOLDER_MANAGE];
        folder.featuresRequired = [DATAHUB_SHARED_FOLDER];

        dispatch({
          type: CREATE_FOLDER,
          folder: folder
        });
        dispatch({
          type: END_CREATING_FOLDER
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_CREATING_FOLDER,
          error: message
        });
        dispatch({
          type: END_CREATING_FOLDER
        });
      });
  };
}
export function deleteFolder(folderId) {
  return function(dispatch) {
    dispatch({
      type: DELETING_FOLDER,
      folderId: folderId
    });

    axios
      .delete("/api/shared-folders/" + folderId, {
        withCredentials: true
      })
      .then(response => {
        dispatch({
          type: DELETE_FOLDER,
          folderId: folderId
        });
        dispatch({
          type: END_DELETING_FOLDER,
          folderId: folderId
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_DELETING_FOLDER,
          folderId: folderId,
          error: message
        });
        dispatch({
          type: END_DELETING_FOLDER,
          folderId: folderId
        });
      });
  };
}
export function updateFolder(folder) {
  return function(dispatch) {
    if (!folder) {
      return;
    }
    let folderId = folder.id;

    let url = `/api/shared-folders`;

    dispatch({
      type: UPDATING_FOLDER,
      folderId: folderId
    });

    axios
      .put(url, folder, {
        withCredentials: true
      })
      .then(response => {
        const newFolder = response.data;
        newFolder.isSharedFolderEntity = true;
        newFolder.authoritiesRequired = [ROLE_SHARED_FOLDER_MANAGE];
        newFolder.featuresRequired = [DATAHUB_SHARED_FOLDER];
        newFolder.attachments = folder.attachments;
        dispatch({
          type: GET_FOLDER,
          folder: newFolder,
          folderId: folderId
        });
        dispatch({
          type: END_UPDATING_FOLDER,
          folderId: folderId
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "edit",
          variant: "success",
          message: "Shared folder updated"
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_UPDATING_FOLDER,
          error: message,
          folderId: folderId
        });
        dispatch({
          type: END_UPDATING_FOLDER,
          folderId: folderId
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Updating of the shared folder failed" + message
        });
      });
  };
}
