import React, { Component } from "react";

import classnames from "classnames";
import IconButton from "../Buttons/IconButton";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import Grid from "components/Grid";

import { Drawer as DrawerMaterial } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

class Drawer extends Component {
  state = {
    open: false
  };

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, children } = this.props;
    const { open } = this.state;

    return (
      <DrawerMaterial
        variant="permanent"
        open={open}
        anchor="right"
        classes={{
          paper: classnames(classes.drawerPaper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={this.handleDrawerToggle} testid="openDrawer">
            {open ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <Grid
          container
          direction="column"
          justify="space-between"
          className={classes.content}
        >
          {children}
        </Grid>
      </DrawerMaterial>
    );
  }
}

const drawerWidth = 240;

const styles = theme => ({
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    overflowX: 'hidden',
    zIndex: 0
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(6) + 2,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(6) + 2
    }
  },
  content: {
    height: "100%"
  }
});

export default withStyles(styles, { withTheme: true })(Drawer);
