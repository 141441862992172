import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormControl from './FormControl';
import classnames from 'classnames';
import {changeField} from "actions/index";

import { Chip, InputLabel, withStyles } from '@material-ui/core';

class ChipsField extends Component {
    onSelect = (option) => {
        const { disabled, multiple, input: { name, value }, meta, changeField } = this.props;
        let newValue = Object.assign([], value);
        if (disabled) {
            return;
        }
        if (this.isSelected(option)) {
            if (multiple) {
                newValue.splice(newValue.indexOf(option.value), 1);
            } else {
                newValue = null;
            }
        } else {
            if (multiple) {
                if (!newValue) {
                    newValue = [];
                }
                newValue.push(option.value);
            } else {
                newValue = option.value;
            }
        }
        changeField(meta.form, name, newValue, true);
        this.forceUpdate();
    };
    isSelected = (option) => {
        const { multiple, input: { value } } = this.props;
        if (multiple) {
            for (let i = 0; value && i < value.length; i++) {
                if (value[i] === option.value) {
                    return true;
                }
            }
        } else {
            return value === option.value;
        }
        return false;
    };
    render() {
        const { input: { name }, options, label, classes, disabled } = this.props;
        return (
            <FormControl { ...this.props }>
                { (label)? <InputLabel htmlFor={name}>{ label }</InputLabel>: "" }
                <div className={classes.root} testid="chipsfield">
                {
                    options && options.map((option, idx) => {
                        const isSelected = this.isSelected(option);
                        return (
                            <Chip
                                key={idx}
                                disabled={disabled}
                                clickable
                                className={classnames(classes.chip, (isSelected)? classes.chipSelected: null)}
                                color="primary"
                                onClick={() => { this.onSelect(option) }}
                                variant={(isSelected? 'default': 'outlined')}
                                testid={option.value}
                                icon={option.icon}
                                label={option.label} />
                        );
                    })
                }
                </div>
            </FormControl>
        );
    }
}

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    chipSelected: {
        color: 'white',
        border: 'solid 1px'
    }
});

function mapStateToProps(state, ownProps) {
    return {}
}
export default withStyles(styles)(connect(mapStateToProps, { changeField })(ChipsField));
