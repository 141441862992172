import React from 'react';

import { Grid, Paper, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  name: {
    textAlign: 'right',
    display: 'inline-flex',
    paddingRight: '10px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100px',
  },
  value: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    flexWrap: 'wrap',
  },
  typography: {
    width: '100%',
  },
  container: {
    padding: '10px',
    margin: '10px',
  },
}));

const InfoRow = ({ label, children }) => {

  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Grid container>
        <Grid item className={classes.name}>
          <Typography variant="caption">
            {label}
          </Typography>
        </Grid>
        <Grid item className={classes.value}>
          <Typography variant="body1" className={classes.typography}>
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

InfoRow.propTypes = ({
  label: PropTypes.string.isRequired,
  children: PropTypes.element,
});

export default InfoRow;
