import React from 'react';

import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config'

const ModelMonitoring = ({route}) => {
    return (renderRoutes(route.routes, { }));
};

const mapStateToProps = (state, ownProps) => {
    return {
        route: ownProps.route
    };
};

export default connect(
    mapStateToProps, { }
)(ModelMonitoring);
