import {
  LOADING_ATTRIBUTES,
  GET_ATTRIBUTES,
  END_LOADING_ATTRIBUTES,
  ATTRIBUTES_ERROR,
  SHOW_ATTRIBUTE,
  HIDE_ATTRIBUTE,
  GET_CONSTRAINT_ENTRIES
} from "../../constants/ActionTypes";

export default function(state = {}, action) {
  const attribute = action.attribute;
  const attributesToShow = state.attributesToShow
    ? state.attributesToShow
    : state.list;
  let newattributesToShow = [];

  switch (action.type) {
    case GET_ATTRIBUTES:
      const list = action.attributes;
      return {
        ...state,
        list,
        attributesToShow: list
          ? list.filter(attribute => attribute.selectedByUser)
          : undefined,
        error: undefined
      };
    case LOADING_ATTRIBUTES:
      return { ...state, loading: true, error: undefined };
    case END_LOADING_ATTRIBUTES:
      return { ...state, loading: false };
    case ATTRIBUTES_ERROR:
      return { ...state, error: action.error };

    case GET_CONSTRAINT_ENTRIES:
      return { ...state, constraintEntries: action.constraintEntries };

    case SHOW_ATTRIBUTE:
      return {
        ...state,
        attributesToShow: [...attributesToShow, attribute]
      };
    case HIDE_ATTRIBUTE:
      for (let i = 0; attributesToShow && i < attributesToShow.length; i++) {
        const attributeToShow = attributesToShow[i];
        if (attributeToShow.id !== attribute.id) {
          newattributesToShow.push(attributeToShow);
        }
      }
      return { ...state, attributesToShow: newattributesToShow };

    default:
      return state;
  }
}
