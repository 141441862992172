import React, { Component } from 'react';
import FormControl from './FormControl';
import InputField from "components/Form/Fields/InputField";

class URLField extends Component {
    render() {
        const { id } = this.props;
        const field = this.props[id];
        return (
            <FormControl { ...field }>
                <InputField
                    label="Name"
                    meta={{}}
                    input={{
                        name: id+".visibleName",
                        onChange: (ev) => {
                            const newValue = ev.nativeEvent.target.value;
                            field.input.onChange({
                                visibleName: newValue,
                                url: field.input.value.url
                            })
                        },
                        value: field.input.value.visibleName
                    }} />
                <InputField
                    label="URL"
                    meta={{}}
                    input={{
                        name: id+".url",
                        onChange: (ev) => {
                            const newValue = ev.nativeEvent.target.value;
                            field.input.onChange({
                                url: newValue,
                                visibleName: field.input.value.visibleName
                            })
                        },
                        value: field.input.value.url
                    }} />
            </FormControl>
        );
    }
}

export default URLField;
