import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { renderRoutes } from "react-router-config";
import { getEntity } from "actions/Entity";
import { createMode } from "actions/Entity";
import {
  MODEL_REVISION_OVERVIEW_PATH,
  TAB_ATTACHMENTS,
  TAB_ATTRIBUTES,
  TAB_VALIDATIONS,
  TAB_WORKFLOWS,
  TAB_WORKFLOWS_AUTOMATION,
  TAB_LINKS,
  TAB_AUDIT_TRAILS,
  ID_REGEX,
  TAB_PARTICIPANTS,
  TAB_MODELS,
  TAB_DOCUMENTS,
  MODEL_USAGE_OVERVIEW_PATH
} from 'constants/Routes';

import DrawerView from "components/Drawer";
import DrawerTabs from "components/Drawer/DrawerTabs";
import DrawerActions from "components/Drawer/DrawerActions";
import CommitteeThumbnail from "components/Validations/Committees/thumbnail";
import Tabs from "components/TabContainer/Tabs";
import Dropzone from "components/Attachments/Dropzone";
import {
  ROLE_ENTITYLINK_VIEW,
  ROLE_PARTICIPATION_VIEW,
  ROLE_MODEL_MONITORING_VIEW,
} from 'constants/Authorities';
import {
  AUTOMATION_WORKFLOWS,
  DOCUMENT_MANAGEMENT,
  ENTITY_LINKS,
  MODEL_MONITORING,
  VALUE_HISTORY_RETRIEVE
} from 'constants/Features';
import {
  MODEL_TYPE,
  getEntityTypeId,
  getEntityTypeFromPath,
  getEntityReducerAttribute,
  getEntityCreatePath,
  VALIDATION_TYPE,
  MODEL_REVISION_TYPE,
  VALIDATION_COMMITTEE_TYPE,
  MODEL_USAGE_TYPE,
  getEntitiesPath,
  getEntityHref,
  getEntityWorkflowRoleView,
  getEntityRoleAttachmentUpload
} from 'api/Entity';
import { bindActionCreators } from "redux";
import RevisionsList from "components/Model/RevisionsList";
import UsagesList from "components/Model/UsagesList";

import { withStyles, Divider, Typography } from "@material-ui/core";
import { getCurrentURLParams } from 'api/GUI';
import { isAuthorized } from 'api/Authorities';
import qs from "querystring";
import RightSideBar from 'containers/pages/App/Entity/RightSideBar';

class Entity extends Component {
  constructor(props) {
    super(props);
    const { location, entityType } = props;
    const query = getCurrentURLParams(location.search);
    this.state = {
      value: query && query["tab"] ? query["tab"] : TAB_ATTRIBUTES,
      waitingForModelLoading: false,
      tabs: TABS.map(tab =>
        tab.value === TAB_WORKFLOWS || tab.value === TAB_WORKFLOWS_AUTOMATION
          ? {
              ...tab,
              authoritiesRequired: [getEntityWorkflowRoleView(entityType)]
            }
          : tab
      )
    };
    this.manageProps(props);
  }

  componentWillReceiveProps(nextProps) {
    const {
      match,
      creation_mode,
      entity,
      saving,
      error_saving,
      error,
      loading,
      model,
      entityType,
      history
    } = nextProps;
    const { waitingForModelLoading } = this.state;
    if (entityType === MODEL_USAGE_TYPE) {
      if (match.params.usageId !== this.props.match.params.usageId) {
        this.manageProps(nextProps);
      }
    } else if (entityType === MODEL_REVISION_TYPE) {
      if (match.params.revisionId !== this.props.match.params.revisionId) {
        this.manageProps(nextProps);
      }
    } else {
      if (match.params.entityId !== this.props.match.params.entityId) {
        this.manageProps(nextProps);
      }
    }
    if (entityType === MODEL_REVISION_TYPE && waitingForModelLoading && model) {
      this.manageProps(nextProps);
      this.state.waitingForModelLoading = false;
    }
    if (
      (!this.props.creation_mode && creation_mode) ||
      (this.props.creation_mode &&
        creation_mode &&
        this.props.entity !== entity)
    ) {
      window.scrollTo(0, 0);
    }
    if (
      this.props.creation_mode &&
      !creation_mode &&
      this.props.saving &&
      !saving &&
      !error_saving &&
      entity
    ) {
      let new_location;
      if (entityType === MODEL_REVISION_TYPE) {
        new_location = getEntityHref(entity);
      } else if (entityType === MODEL_USAGE_TYPE) {
        new_location =
          MODEL_USAGE_OVERVIEW_PATH.replace(":usageId" + ID_REGEX, entity.id)
            .replace(":usageType" + ID_REGEX, match.params.usageType)
            .replace(":revisionId" + ID_REGEX, match.params.revisionId)
            .replace(":entityId" + ID_REGEX, match.params.entityId)
            .replace(":entityType" + ID_REGEX, match.params.entityType) +
          "?tab=attributes";
      } else {
        new_location = getEntityHref(entity);
      }
      history.push(new_location);
    }
    if (this.props.entity && !entity) {
      if (entityType === MODEL_REVISION_TYPE && model) {
        let new_location;
        if (model.revisions && model.revisions.length > 0) {
          new_location =
            MODEL_REVISION_OVERVIEW_PATH.replace(
              ":revisionId" + ID_REGEX,
              model.revisions[0].id
            )
              .replace(":entityId" + ID_REGEX, model.id)
              .replace(":entityType" + ID_REGEX, model.modelType.id) +
            "?tab=attributes";
        } else {
          new_location = getEntityHref(model);
        }
        history.push(new_location);
      } else {
        history.push(getEntitiesPath(entityType));
      }
    }
    if (!loading && error === "NOT_FOUND") {
      history.push("/404");
    }
  }

  manageProps = props => {
    const {
      match,
      getEntity,
      getEntityModel,
      getEntityRevision,
      createMode,
      entityType,
      model,
      location
    } = props;
    const id = match.params.entityId;
    const revisionId = match.params.revisionId;
    const usageId = match.params.usageId;
    if (
      id &&
      (entityType !== MODEL_REVISION_TYPE || revisionId) &&
      (entityType !== MODEL_USAGE_TYPE || usageId)
    ) {
      if (entityType === MODEL_REVISION_TYPE) {
        getEntity(revisionId, id);
        getEntityModel(id);
      } else if (entityType === MODEL_USAGE_TYPE) {
        getEntity(usageId);
        getEntityRevision(revisionId, id);
        getEntityModel(id);
      } else {
        getEntity(id);
      }
    } else {
      let modelRevisions, validationCommittee, attributes;
      if (location && location.state) {
        if (location.state.modelRevisions) {
          modelRevisions = location.state.modelRevisions;
        }
        if (location.state.validationCommittee) {
          validationCommittee = location.state.validationCommittee;
        }
        if (location.state.attributes) {
          attributes = location.state.attributes;
        }
      }
      if (entityType === MODEL_REVISION_TYPE) {
        if (model) {
          if (!attributes) {
            if (model.revisions && model.revisions.length > 0) {
              attributes = model.revisions[0].attributes;
            }
          }
        } else {
          getEntityModel(id);
          if (!attributes) {
            this.state.waitingForModelLoading = true;
            return;
          }
        }
      }
      if (entityType === MODEL_USAGE_TYPE) {
        getEntityRevision(revisionId, id);
        getEntityModel(id);
      }
      createMode(
        entityType === MODEL_USAGE_TYPE
          ? match.params.usageType
          : match.params.entityType,
        {
          modelRevisions: modelRevisions,
          validationCommittee: validationCommittee
        },
        attributes
      );
    }
  };

  handleChange = value => {
    this.setState({ value, droppedFiles: null });
  };

  create = () => {
    const { history, entity, match } = this.props;
    const new_location = getEntityCreatePath(entity).replace(
      ":entityType" + ID_REGEX,
      getEntityTypeId(entity)
    );
    history.push({
      pathname: new_location,
      search: "?" + qs.stringify({ tab: TAB_ATTRIBUTES })
    });
  };

  onDrop = files => {
    const { value } = this.state;
    if (value === TAB_DOCUMENTS) {
      this.setState({
        droppedFiles: files,
        value: TAB_DOCUMENTS
      });
    } else {
      this.setState({
        droppedFiles: files,
        value: TAB_ATTACHMENTS
      });
    }
  };

  render() {
    const {
      route,
      classes,
      entity,
      entityType,
      model,
      creation_mode,
      edition_mode,
      loading,
      error,
      revision,
      me
    } = this.props;
    const { value, droppedFiles, waitingForModelLoading, tabs } = this.state;

    const authorityRequiredUpload = getEntityRoleAttachmentUpload(entity);
    const disabled =
      !entity ||
      (entityType === MODEL_REVISION_TYPE
        ? !entity.model || entity.model.active === false
        : entity.active === false);
    const isAuthorizedToUpload = isAuthorized(
      me,
      authorityRequiredUpload ? [authorityRequiredUpload] : null
    );

    let committee;
    if (entity) {
      if (entity.validationCommittee) {
        committee = (
          <React.Fragment>
            <div className={classes.attributesContainer}>
              <div className={classes.attributeContainer}>
                <Typography variant="caption">Validation committee</Typography>
                <CommitteeThumbnail
                  typoStyle={classes.attributeValue}
                  committee={entity.validationCommittee}
                />
              </div>
            </div>
            <Divider />
          </React.Fragment>
        );
      }
    }

    return (
      <Dropzone
        onDrop={this.onDrop}
        testid="entityview"
        style={{ height: "100%", minHeight: 0 }}
        disabled={!isAuthorizedToUpload || disabled}
      >
        <DrawerView>
          <DrawerTabs testid="entitytabs">
            {committee}
            <Tabs
              tabs={tabs}
              creation_mode={creation_mode}
              edition_mode={edition_mode}
              entityType={entityType}
              value={value}
              onChange={this.handleChange}
            />
            <RevisionsList
              entity={entityType === MODEL_USAGE_TYPE ? model : entity}
              entityType={entityType}
              model={model}
              waitingForModelLoading={waitingForModelLoading}
              loading={loading}
              error={error}
            />
            <UsagesList
              entity={entity}
              entityType={entityType}
              model={model}
              revision={revision}
              loading={loading}
              error={error}
            />
          </DrawerTabs>
          {renderRoutes(route.routes, {
            tab: value,
            droppedFiles: droppedFiles
          })}
          <DrawerActions>
            <RightSideBar
              entity={entity}
              entityType={entityType}
              model={model}
            />
          </DrawerActions>
        </DrawerView>
      </Dropzone>
    );
  }
}

const TABS = [
  {
    value: TAB_ATTRIBUTES,
    label: "Attributes",
    allowedEntityTypes: [
      MODEL_TYPE,
      MODEL_REVISION_TYPE,
      MODEL_USAGE_TYPE,
      VALIDATION_TYPE,
      VALIDATION_COMMITTEE_TYPE
    ]
  },
  {
    value: TAB_ATTACHMENTS,
    label: "Data hub",
    disabledOnEdit: true,
    allowedEntityTypes: [
      MODEL_TYPE,
      MODEL_REVISION_TYPE,
      VALIDATION_TYPE,
      VALIDATION_COMMITTEE_TYPE
    ]
  },
  {
    value: TAB_DOCUMENTS,
    label: "Documents",
    disabledOnEdit: true,
    featuresRequired: [DOCUMENT_MANAGEMENT],
    allowedEntityTypes: [
      MODEL_TYPE,
      MODEL_REVISION_TYPE,
      VALIDATION_TYPE,
      VALIDATION_COMMITTEE_TYPE
    ]
  },
  {
    value: TAB_PARTICIPANTS,
    label: "Participants",
    disabledOnEdit: true,
    authoritiesRequired: [ROLE_PARTICIPATION_VIEW],
    allowedEntityTypes: [VALIDATION_COMMITTEE_TYPE]
  },
  {
    value: TAB_VALIDATIONS,
    label: "Validations",
    disabledOnEdit: true,
    allowedEntityTypes: [
      MODEL_TYPE,
      MODEL_REVISION_TYPE,
      VALIDATION_COMMITTEE_TYPE
    ]
  },
  {
    value: TAB_WORKFLOWS,
    label: "Workflows",
    disabledOnEdit: true,
    allowedEntityTypes: [
      MODEL_TYPE,
      MODEL_REVISION_TYPE,
      MODEL_USAGE_TYPE,
      VALIDATION_TYPE,
      VALIDATION_COMMITTEE_TYPE
    ]
  },
  {
    value: TAB_WORKFLOWS_AUTOMATION,
    label: "Automation",
    disabledOnEdit: true,
    featuresRequired: [AUTOMATION_WORKFLOWS],
    allowedEntityTypes: [
      MODEL_TYPE,
      MODEL_REVISION_TYPE,
      VALIDATION_TYPE,
      VALIDATION_COMMITTEE_TYPE
    ]
  },
  {
    value: TAB_MODELS,
    label: "Models",
    disabledOnEdit: true,
    allowedEntityTypes: [VALIDATION_TYPE]
  },
  {
    value: TAB_LINKS,
    label: "Links",
    featuresRequired: [ENTITY_LINKS],
    authoritiesRequired: [ROLE_ENTITYLINK_VIEW],
    disabledOnEdit: true,
    allowedEntityTypes: [MODEL_REVISION_TYPE]
  },
  {
    value: TAB_LINKS,
    label: "Links",
    featuresRequired: [MODEL_MONITORING],
    authoritiesRequired: [ROLE_MODEL_MONITORING_VIEW],
    disabledOnEdit: true,
    allowedEntityTypes: [VALIDATION_TYPE]
  },
  {
    value: TAB_AUDIT_TRAILS,
    label: "History",
    featuresRequired: [VALUE_HISTORY_RETRIEVE],
    authoritiesRequired: [],
    disabledOnEdit: true,
    allowedEntityTypes: [
      MODEL_TYPE,
      MODEL_REVISION_TYPE,
      VALIDATION_TYPE,
      VALIDATION_COMMITTEE_TYPE
    ]
  }
];

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const entityType = getEntityTypeFromPath(location.pathname);
  const entityReducerAttribute = getEntityReducerAttribute(entityType);
  let entity = {};
  if (state.entity[entityType]) {
    entity = {
      creation_mode: state.entity[entityType].creation_mode,
      edition_mode: state.entity[entityType].edition_mode,
      saving: state.entity[entityType].saving,
      error_saving: state.entity[entityType].error_saving,
      loading: state.entity[entityType].loading,
      error: state.entity[entityType].error
    };
  }
  return {
    ...entity,
    entityType,
    model: state.models.current,
    revision: state.modelRevisions.current,
    entity: state[entityReducerAttribute].current,
    route: ownProps.route,
    securityLevels: state.securityLevels.list,
    attachmentTypes: state.attachments.types,
    me: state.auth.me
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  const { location } = ownProps;
  let entityType = getEntityTypeFromPath(location.pathname);

  return bindActionCreators(
    {
      getEntity: getEntity(entityType),
      getEntityModel: getEntity(MODEL_TYPE),
      getEntityRevision: getEntity(MODEL_REVISION_TYPE),
      createMode: createMode(entityType)
    },
    dispatch
  );
};

const styles = theme => ({
  attributesContainer: {
    paddingTop: "10px"
  },
  attributeContainer: {
    padding: "0 24px 10px 24px"
  },
  attributeLabel: {},
  attributeValue: {
    fontWeight: "bold"
  },

  list: {
    paddingRight: "10px"
  },
  item: {
    paddingTop: "5px",
    paddingBottom: "5px",
    borderBottomRightRadius: "23px",
    borderTopRightRadius: "23px"
  },
  revisionItem: {
    paddingLeft: "40px",
    flexDirection: "column",
    alignItems: "flex-start",
    lineHeight: "18px"
  },
  revisionItemSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: "#594300"
  },
});

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Entity)
  )
);
