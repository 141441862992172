import React, { useState } from 'react';
import Button from "components/Buttons/Button";
import { CircularProgress, Menu, Checkbox } from "@material-ui/core";
import { FolderOutlined, MoreVert } from "@material-ui/icons";
import IconButton from "components/Buttons/IconButton";
import MenuItem from "components/Buttons/MenuItem";
import DataLabLinkGenerator from "components/Attachments/File/DataLabLinkGenerator";
import { getDownloadLink } from "../../api/Attachments";
import {useDispatch} from "react-redux";
import {copyToClipboard} from "../../api/Links";

const ITEM_HEIGHT = 48;

const SubDirectory = ({
  onDrop,
  droppable,
  subDirectory,
  onRemove,
  onRename,
  onSelect,
  onOpenDirectory,
  isDragging,
  isSelected,
  isSelecting,
  onDragStart,
  onDragEnd,
  draggable
}) => {

  const { label, updating, renaming, deleting } = subDirectory;
  const href = getDownloadLink && getDownloadLink(subDirectory);
  const isSelectedAndNotDroppable = isSelected && !(isDragging && droppable);

  const [anchorEl, setAnchorEl] = useState(null);
  const [dropping, setDropping] = useState(false);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleOnDrop = (event) => {
    setDropping(false);
    if (onDrop && droppable) {
      onDrop();
    }
  };

  const handleCopyLink = (event, href) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(copyToClipboard(href))
  };

  const onDragEnter = (event) => {
    setDropping(true);
  };

  const onDragLeave = (event) => {
    setDropping(false);
  };

  const onDragOver = (event) => {
    if (!dropping) {
      setDropping(true);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        onDragEnter={!isSelectedAndNotDroppable ? onDragEnter : undefined}
        onDragLeave={!isSelectedAndNotDroppable ? onDragLeave : undefined}
        onDragOver={!isSelectedAndNotDroppable ? onDragOver : undefined}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        draggable={draggable}
        dropping={(dropping && !isSelectedAndNotDroppable) ? "true" : undefined}
        onDrop={handleOnDrop}
        onClick={onSelect}
        onDoubleClick={onOpenDirectory}
        variant={(isSelectedAndNotDroppable) ? "text" : "outlined"}
        style={{
          opacity: (isDragging && !droppable) ? 0.3 : 1,
          padding: 0,
          paddingLeft: 16,
          textTransform: 'none',
          backgroundColor: (isSelectedAndNotDroppable) ? "rgba(0, 0, 0, 0.04)" : undefined
        }}
        color={(dropping && !isSelectedAndNotDroppable) ? "secondary" : "primary"}>
        {
          (isSelecting) ? (
            <Checkbox checked={isSelected} />
          ) : (
            <FolderOutlined style={{ marginRight: 10 }} />
          )
        }
        {label}
        <IconButton
          aria-label="More"
          color={(dropping) ? "inherit" : "primary"}
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClick}>
          {
            (updating || renaming || deleting) ? (
              <CircularProgress size={20} />
            ) : (
                <MoreVert />
              )
          }
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 5.5,
              width: 200,
            },
          }}>
          {
            onOpenDirectory && (
              <MenuItem onClick={(event) => {
                handleClose(event);
                onOpenDirectory()
              }}>
                Open
              </MenuItem>
            )
          }
          {
            onSelect && (
              <MenuItem onClick={(event) => {
                handleClose(event);
                onSelect()
              }}>
                {(isSelected) ? 'Unselect' : 'Select'}
              </MenuItem>
            )
          }
          {
            onRename && (
              <MenuItem onClick={(event) => {
                handleClose(event);
                onRename()
              }}>
                Rename
              </MenuItem>
            )
          }
          <MenuItem onClick={(event) => {
            handleClose(event);
            onRemove()
          }}>
            Delete
          </MenuItem>
          <MenuItem onClick={(event) => {
            handleClose(event)
            handleCopyLink(event, href)
          }}>
            Copy link
          </MenuItem>
          { subDirectory && subDirectory.entity !== "VALIDATION_COMMITTEE" && <DataLabLinkGenerator file={subDirectory} /> }
        </Menu>
      </Button>
    </>
  );
};

export default SubDirectory;

