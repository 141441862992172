import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import { pushNotification } from "actions/notifications";
import { getApiError } from "api/Error";
import { getUsersRowPerPageForEntity, MODEL_MONITORING_TYPE } from "api/Entity";
import { updateUserPreferences } from "actions/Configuration";
import { useApiRequest } from "actions/index";
import {
  getSearchMonitoringMetadata,
  searchMonitoring,
  getMonitoring,
} from "actions/Monitoring";
import getDefaultSearchFilters from "api/Search";
import { OBJECT_LIST, TABLE_VIEW } from "constants/ResultFormats";

import MonitoringRow from "components/ModelMonitoring/MonitoringRow";
import TabCentered from "components/TabContainer/TabCentered";
import Pagination from "components/Search/Pagination";
import TableTopMenu from "components/Search/TableTopMenu";
import DrawerFilter from "components/Search/DrawerFilter";
import { RUNNING_SEARCH, RUNNING_SEARCH_END } from "constants/ActionTypes";

const useStyles = makeStyles({
  title: {
    alignItems: "center",
    height: "68px",
    position: "relative",
    paddingLeft: "24px",
    display: "flex",
  },
  empty: {
    width: "100%",
    textAlign: "center",
    marginTop: 5,
  },
});

const MonitoringTable = () => {
  const entityType = MODEL_MONITORING_TYPE;
  const classes = useStyles();
  const { entityId } = useParams();

  // REDUX
  const dispatch = useDispatch();
  const preferences = useSelector((state) =>
    state.auth.preferences
      ? state.auth.preferences[getUsersRowPerPageForEntity(entityType)]
      : undefined
  );
  const configLoading = useSelector((state) => state.loading_config);
  const me = useSelector((state) => state.auth.me);
  const last_filters_runned = useSelector((state) =>
    state.filters.last_filters_runned
      ? state.filters.last_filters_runned[entityType]
      : undefined
  );
  const last_current_filters_runned = useSelector((state) =>
    state.filters.last_current_filters_runned
      ? state.filters.last_current_filters_runned[entityType]
      : undefined
  );

  // State
  const [drawerFilterOpened, setDrawerFilterOpened] = useState(false);
  const [monitoringList, setMonitoringList] = useState([]); // Table content
  const [monitoringListLength, setMonitoringListLength] = useState(0); // Number of all records matching the current filter (not only the visible page)
  const [page, setPage] = useState(0);

  const [pageSize, setRowsPerPage] = useState(preferences ? preferences : 10);
  const [lastExecutedWorkingFilter, setLastExecutedWorkingFilter] = useState(
    last_filters_runned || getDefaultSearchFilters()
  );
  const [lastExecutedCurrentFilter, setLastExecutedCurrentFilter] = useState(
    last_current_filters_runned || getDefaultSearchFilters()
  ); // The last executed filter from the DrawerFilter tab Current

  const [loading, error, runSearch] = useApiRequest(
    (searchDefinition) => {
      if (entityId) {
        return getMonitoring(entityId);
      } else {
        const searchFilter = {
          ...searchDefinition,
          resultFormat: OBJECT_LIST,
          firstResult: page,
          maxResultCount: pageSize,
        };
        dispatch({
          type: RUNNING_SEARCH,
          entityType,
          filters: {
            filters: { ...searchDefinition },
            page: page,
            rowsPerPage: pageSize,
            resultFormat: OBJECT_LIST,
          },
        });
        return searchMonitoring(searchFilter);
      }
    },
    (response) => {
      setMonitoringList(entityId ? [response] : response.result);
      setMonitoringListLength(entityId ? 1 : response.totalResultCount);
      !entityId &&
        dispatch({
          type: RUNNING_SEARCH_END,
          entityType,
        });
    },
    (e, message) => {
      dispatch(
        pushNotification(
          "error",
          "error",
          `Loading Model monitoring failed:${getApiError(e)}`
        )
      );
    }
  );

  const refreshTableData = () => {
    runSearch(lastExecutedWorkingFilter);
  };

  const removeAppliedFilter = () => {
    runSearch(getDefaultSearchFilters());
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    updateUserPreferences({
      ...preferences,
      modelMonitoringRowsPerTable: event.target.value,
      userId: me.id,
    })(dispatch);
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    refreshTableData();
  }, [page, pageSize]);

  useEffect(() => {
    getSearchMonitoringMetadata()(dispatch);
  }, [drawerFilterOpened]);

  useEffect(() => {
    preferences && setRowsPerPage(preferences);
  }, [preferences]);

  const renderMonitoringList = () => {
    if (loading) {
      return (
        <TabCentered>
          <div style={{ lineHeight: "36px", textAlign: "center" }}>
            <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
              Loading model monitoring list
            </span>
          </div>
        </TabCentered>
      );
    }

    if (error) {
      return (
        <TabCentered>
          <div className="error">{error}</div>
        </TabCentered>
      );
    }

    if (!monitoringList || monitoringList.length === 0) {
      return (
        <TabCentered>
          <div className={classes.empty}>No Model Monitoring found</div>
        </TabCentered>
      );
    }

    return (
      <>
        <TableContainer component={Paper} className={"scrollable-content"}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {/* The first cell is collapsible arrow */}
                </TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Executor label</TableCell>
                <TableCell>Additional information</TableCell>
                <TableCell>Data source</TableCell>
                <TableCell>Creation time</TableCell>
                <TableCell>Update time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: "auto", maxHeight: "100%" }}>
              {monitoringList &&
                monitoringList.map((monitoring) => (
                  <MonitoringRow key={monitoring.id} monitoring={monitoring} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <div className={"scrollable-container"} style={{ width: "100%" }}>
      <div className={"scrollable-header"}>
        <TableTopMenu
          entityType={entityType}
          handleOpenDrawerFilter={() => setDrawerFilterOpened(true)}
          removeAppliedFilter={removeAppliedFilter}
        />
        <Typography variant="h6" id="tableTitle" className={classes.title}>
          Model monitoring
        </Typography>
      </div>

      {renderMonitoringList()}

      {monitoringList && monitoringList.length > 0 ? (
        <div className={"scrollable-footer"} style={{ margin: "auto" }}>
          <Pagination
            page={page}
            rowsPerPage={pageSize}
            totalResultCount={monitoringListLength}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      ) : null}

      <DrawerFilter
        entityType={entityType}
        loading={configLoading}
        drawerFilterOpened={drawerFilterOpened}
        workingFilter={lastExecutedCurrentFilter}
        handleChangeLastExecutedCurrentFilter={setLastExecutedCurrentFilter}
        handleCloseDrawerFilter={() => setDrawerFilterOpened(false)}
        handleSearch={runSearch}
      />
    </div>
  );
};

export default MonitoringTable;
