import React, { Component } from "react";
import TabCentered from "../TabContainer/TabCentered";

import {
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

class ListHosts extends Component {
  render() {
    const { hosts, classes } = this.props;
    if (!hosts || !hosts.map) {
      return (
        <TabCentered>
          <div>No Datalab host found</div>
        </TabCentered>
      );
    }
    return (
      <List className={classes.root}>
        {hosts.map((host, idx) => {
          const capacity = (host.actualSize / host.capacity) * 100;
          return (
            <ListItem key={idx} className={classes.listItem}>
              <ListItemText
                primary={
                  <React.Fragment>
                    {host.label}
                    <Typography
                      component="span"
                      className={classes.inline}
                      variant="caption"
                    >
                      {" - " +
                        host.sshUsername +
                        "@" +
                        host.sshHostname +
                        ":" +
                        host.sshPort}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <LinearProgress
                      variant="determinate"
                      color="primary"
                      value={capacity}
                    />
                    <Typography
                      component="span"
                      className={classes.inline}
                      variant="caption"
                    >
                      {Math.round(capacity) + "% of the capacity used"}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        })}
      </List>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  status: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "#dadada",
    marginRight: 10
  },
  listItem: {}
});

export default withStyles(styles)(ListHosts);
