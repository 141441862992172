import React, { Component } from "react";
import Button from "../../Buttons/Button";
import { DialogTitle, DialogActions, Dialog } from "@material-ui/core";

class UnassignValidationConfirmation extends Component {
  render() {
    const { open, handleClose, onUnassignValidation } = this.props;

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="confirmation-dialog-title">
          Are you sure to unassign this validation ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onUnassignValidation}
            color="primary"
            testid="confirmUnassignValidation"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default UnassignValidationConfirmation;
