
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setBusinessOwners} from "actions/Tasks";
import BusinessOwnerForm from "components/Tasks/TaskDialog/BusinessOwnerForm";
import {Grid, Typography} from "@material-ui/core";
import IconButton from "components/Buttons/IconButton";
import EditIcon from '@material-ui/icons/Edit';

const TaskInfoBusinessOwner = ({ task, classes }) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [adminNames, setAdminNames] = useState(null);

  useEffect(()=>{
    setEditing(false);
    if(task.businessAdmins && task.businessAdmins.length > 0){
      setAdminNames(
        task.businessAdmins
          .map(adimn => adimn.displayName)
          .reduce((result, adminName) => [result, ", ", adminName]));
    } else {
      setAdminNames("");
    }
  },[task.businessAdmins]);

  const setBusinessOwnersForm = form => {
    setBusinessOwners(task, form.administrators)(dispatch);
  };

  if(editing){
    return <Grid item xs={12} style={{padding: "5px 0"}}>
      <BusinessOwnerForm
        initialValues={{
          administrators: task.businessAdmins
        }}
        onSubmit={setBusinessOwnersForm}
        handleClose={()=>setEditing(false)}
      />
    </Grid>;
  }

  return <>
    <Grid item xs={3} lg={2} className={classes.captionRow}>
      <Typography variant="caption" className={classes.caption}>
        Business owners:
      </Typography>
    </Grid>
    <Grid item xs={9} lg={10} className={classes.row}>
      <div style={{display:"flex", alignItems:"center"}}>
        <Typography variant="body1" onClick={() => setEditing(true)}>
          {adminNames}
        </Typography>
        <IconButton size="small" onClick={() => setEditing(true)}>
          <EditIcon />
        </IconButton>
      </div>
    </Grid>
    </>

};
export default TaskInfoBusinessOwner;