import {
  DELETE_ENTITY,
  DELETING_ENTITY,
  END_DELETING_ENTITY,
  ERROR_DELETING_ENTITY,
  LOADING_ENTITY,
  END_LOADING_ENTITY,
  GET_ENTITY,
  ERROR_ENTITY,
  LOADING_ENTITY_TYPES,
  GET_ENTITY_TYPES,
  END_LOADING_ENTITY_TYPES,
  ERROR_ENTITY_TYPES,
  LOADING_ENTITY_TYPE,
  GET_ENTITY_TYPE,
  END_LOADING_ENTITY_TYPE,
  ERROR_ENTITY_TYPE,
  CREATE_ENTITY,
  END_CREATE_ENTITY,
  EDIT_ENTITY,
  END_EDIT_ENTITY,
  SAVING_ENTITY,
  END_SAVING_ENTITY,
  SAVE_ENTITY,
  ERROR_SAVING_ENTITY,
  PUSH_NOTIFICATION
} from "../../constants/ActionTypes";
import axios from "axios";
import {
  getEntityGetApiParam,
  getEntityGetType,
  getEntityGetTypeAttribute,
  MODEL_REVISION_TYPE,
  MODEL_TYPE,
  getEntityGetApiParamSimple
} from "../../api/Entity";
import {
  clearCreateAttributes,
  clearAttributes,
  clearUpdateAttributes
} from "../../api/Attributes";
import { manageAttributesErrors } from "../Attributes/index";

export function deleteEntity(entity) {
  return function(dispatch) {
    dispatch({
      type: DELETING_ENTITY,
      ownerId: entity.id
    });

    axios
      .delete("/api/" + getEntityGetApiParam(entity) + "/" + entity.id, {
        withCredentials: true
      })
      .then(response => {
        dispatch({
          type: DELETE_ENTITY,
          ownerId: entity.id
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: "Entity removed"
        });
        dispatch({
          type: END_DELETING_ENTITY,
          ownerId: entity.id
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_DELETING_ENTITY,
          error: message,
          ownerId: entity.id
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: message
        });
        dispatch({
          type: END_DELETING_ENTITY,
          ownerId: entity.id
        });
      });
  };
}

export function createMode(entityType) {
  return function(
    entityTypeId,
    baseEntityAttributes,
    attributesFromPreviousRevision
  ) {
    return function(dispatch) {
      dispatch({
        type: LOADING_ENTITY_TYPE,
        entityType
      });
      window.scrollTo(0, 0);
      axios
        .get("/api/" + getEntityGetType(entityType) + "/" + entityTypeId, {
          withCredentials: true
        })
        .then(response => {
          let type = response.data;
          let attributes = {},
            attributesRevision = {};
          for (let i = 0; i < type.attributeSpecifications.length; i++) {
            const attributeSpecification = type.attributeSpecifications[i];
            const attribute = attributeSpecification.attribute;
            const attributePanel = attributeSpecification.attributePanel
              ? attributeSpecification.attributePanel
              : { position: 99 };
            const attributePanelId = attributePanel.id
              ? attributePanel.id
              : "none";
            const groupName = attribute.groupName;
            attribute.specificationId = attributeSpecification.id;
            if (attributeSpecification.applicableForEntity === entityType) {
              if (!attributes[attributePanelId]) {
                attributes[attributePanelId] = attributePanel;
                attributes[attributePanelId].groupNames = {};
              }
              if (!attributes[attributePanelId].groupNames[groupName]) {
                attributes[attributePanelId].groupNames[groupName] = [];
              }
              if (attributesFromPreviousRevision) {
                for (
                  let l = 0;
                  l < attributesFromPreviousRevision.length;
                  l++
                ) {
                  const panel = attributesFromPreviousRevision[l].groupNames;
                  const keys = Object.keys(panel);
                  for (let k = 0; k < keys.length; k++) {
                    const key = keys[k];
                    const attributesSections = panel[key];
                    for (
                      let j = 0;
                      attributesSections && j < attributesSections.length;
                      j++
                    ) {
                      const attributesSection = attributesSections[j];
                      const value = attributesSection.value;
                      if (
                        attributeSpecification.id ===
                        attributesSection.specificationId
                      ) {
                        attribute.value = value;
                      }
                    }
                  }
                }
              }
              attributes[attributePanelId].groupNames[groupName].push(
                attribute
              );
            } else if (
              entityType === MODEL_TYPE &&
              attributeSpecification.applicableForEntity === MODEL_REVISION_TYPE
            ) {
              if (!attributesRevision[attributePanelId]) {
                attributesRevision[attributePanelId] = attributePanel;
                attributesRevision[attributePanelId].groupNames = {};
              }
              if (!attributesRevision[attributePanelId].groupNames[groupName]) {
                attributesRevision[attributePanelId].groupNames[groupName] = [];
              }
              attributesRevision[attributePanelId].groupNames[groupName].push(
                attribute
              );
            }
          }
          if (entityType === MODEL_TYPE) {
            if (!baseEntityAttributes) {
              baseEntityAttributes = {};
            }
            baseEntityAttributes.creationModeRevision = {
              attributes: Object.values(attributesRevision)
            };
          }
          dispatch({
            type: CREATE_ENTITY,
            entityType,
            entity: {
              ...baseEntityAttributes,
              attributes: Object.values(attributes)
            }
          });
          dispatch({
            type: GET_ENTITY_TYPE,
            entityType:
              entityType === MODEL_REVISION_TYPE ? MODEL_TYPE : entityType,
            entity_type: type
          });
          dispatch({
            type: END_LOADING_ENTITY_TYPE,
            entityType
          });
        })
        .catch(err => {
          console.log(err);
          const message = err.response.data.message;
          dispatch({
            type: ERROR_ENTITY_TYPE,
            entityType,
            error: message
          });
          dispatch({
            type: END_LOADING_ENTITY_TYPE,
            entityType
          });
        });
    };
  };
}
export function endCreationMode(entityType) {
  return function() {
    return function(dispatch) {
      dispatch({
        type: END_CREATE_ENTITY,
        entityType
      });
    };
  };
}
export function editMode(entityType) {
  return function() {
    return function(dispatch) {
      dispatch({
        type: EDIT_ENTITY,
        entityType
      });
    };
  };
}
export function endEditMode(entityType) {
  return function() {
    return function(dispatch) {
      dispatch({
        type: END_EDIT_ENTITY,
        entityType
      });
    };
  };
}
export function create(entityType) {
  return function(entity, entityTypeId, securityLevel, history) {
    return function(dispatch) {
      entityTypeId = entityTypeId.id ? entityTypeId.id : entityTypeId;
      dispatch({
        type: SAVING_ENTITY,
        entityType
      });

      const entityTypeAttribute = getEntityGetTypeAttribute(entityType);

      if (!entity[entityTypeAttribute] || !entity[entityTypeAttribute].id) {
        entity[entityTypeAttribute] = {
          id: entityTypeId
        };
      }

      if (!entity.securityLevels) {
        entity.securityLevels = [];
      }
      if (securityLevel && securityLevel !== "undefined") {
        entity.securityLevels.push({
          id: securityLevel
        });
      }
      if (entityType === MODEL_TYPE) {
        entity.revisions = [
          {
            ...clearCreateAttributes(entity.creationModeRevision)
          }
        ];
      }
      if (entityType === MODEL_REVISION_TYPE) {
        if (!entity.model) {
          entity.model = {};
        }
        if (!entity.model.id) {
          entity.model.id = entityTypeId;
        }
      }

      axios
        .post(
          "/api/" + getEntityGetApiParam(entity),
          clearCreateAttributes(entity),
          {
            withCredentials: true
          }
        )
        .then(response => {
          let entity = response.data;
          dispatch({
            type: SAVE_ENTITY,
            entityType,
            entity: clearAttributes(entity)
          });
          dispatch({
            type: END_SAVING_ENTITY,
            entityType
          });
          dispatch({
            type: PUSH_NOTIFICATION,
            icon: "check",
            variant: "success",
            message: "Entity created"
          });

          // The following code performs a conditional redirection for freshly created models (redirection to revision 1 overview)
          if (entityType === MODEL_TYPE) {
            const modelId = entity && entity.id ? entity.id : null;
            const modelRevisionId = entity.revisions && entity.revisions.length === 1 && entity.revisions[0].id ? entity.revisions[0].id : null;

            if (modelId != null && modelRevisionId != null && entityTypeId != null) {
              const firstModelRevisionRoute = `/models/${entityTypeId}/${modelId}/revision/${modelRevisionId}/overview`;
              history.push(firstModelRevisionRoute);
            }
          }

        })
        .catch(err => {
          let message = err.response.data.message;
          let attributesError = err.response.data.attributesError;
          manageAttributesErrors(attributesError)(dispatch);
          if (!message) {
            message = err.response.statusText;
          }
          console.log(message);
          dispatch({
            type: ERROR_SAVING_ENTITY,
            entityType,
            error: message
          });
          dispatch({
            type: END_SAVING_ENTITY,
            entityType
          });
          if (message) {
            message = ": " + message;
          }
          dispatch({
            type: PUSH_NOTIFICATION,
            icon: "error",
            variant: "error",
            message: "Save entity failed" + message
          });
        });
    };
  };
}
export function save(entityType) {
  return function(entity, entityTypeId, securityLevel, history) {
    return function(dispatch) {
      dispatch({
        type: SAVING_ENTITY,
        entityType
      });
      if (!entity.id) {
        return create(entityType)(entity, entityTypeId, securityLevel, history)(
          dispatch
        );
      }

      axios
        .patch(
          `/api/${getEntityGetApiParam(entity)}/${
            entity.id
          }/attribute-values?version=${entity.version}`,
          clearUpdateAttributes(entity),
          {
            withCredentials: true
          }
        )
        .then(response => {
          let entity = response.data;
          dispatch({
            type: SAVE_ENTITY,
            entityType,
            entity: clearAttributes(entity)
          });
          dispatch({
            type: END_SAVING_ENTITY,
            entityType
          });
          dispatch({
            type: PUSH_NOTIFICATION,
            icon: "check",
            variant: "success",
            message: "Entity saved"
          });
        })
        .catch(err => {
          let message = err.response.data.message;
          let attributesError = err.response.data.attributesError;
          manageAttributesErrors(attributesError)(dispatch);
          if (!message) {
            message = err.response.statusText;
          }
          console.log(message);
          dispatch({
            type: ERROR_SAVING_ENTITY,
            entityType,
            error: message
          });
          dispatch({
            type: END_SAVING_ENTITY,
            entityType
          });
          if (message) {
            message = ": " + message;
          }
          dispatch({
            type: PUSH_NOTIFICATION,
            icon: "error",
            variant: "error",
            message: "Save entity failed" + message
          });
        });
    };
  };
}
export function getEntity(entityType) {
  return function(entityId, modelId) {
    return function(dispatch) {
      if (!entityId) {
        const error = "No entity id provided";
        console.error(error);
        dispatch({
          type: ERROR_ENTITY,
          entityType,
          error
        });
        return;
      }
      dispatch({
        type: LOADING_ENTITY,
        entityType
      });
      axios
        .get(
          `/api/${getEntityGetApiParamSimple(entityType, modelId)}/${entityId}`,
          {
            withCredentials: true
          }
        )
        .then(response => {
          let entity = response.data;
          dispatch({
            type: GET_ENTITY,
            entityType,
            entity: clearAttributes(entity)
          });
          dispatch({
            type: END_LOADING_ENTITY,
            entityType
          });
        })
        .catch(err => {
          if (
            err.response.status === 404 ||
            err.response.data.status === "NOT_FOUND"
          ) {
            dispatch({
              type: ERROR_ENTITY,
              entityType,
              error: "NOT_FOUND"
            });
          } else {
            let message = err.response.data.message;
            if (!message) {
              message = err.response.status;
            }
            dispatch({
              type: ERROR_ENTITY,
              entityType,
              error: message
            });
          }
          dispatch({
            type: END_LOADING_ENTITY,
            entityType
          });
        });
    };
  };
}
export function getEntityType(entityType) {
  return function(entityTypeId) {
    return function(dispatch) {
      dispatch({
        type: LOADING_ENTITY_TYPE,
        entityType
      });
      axios
        .get(`/api/${getEntityGetType(entityType)}/${entityTypeId}`, {
          withCredentials: true
        })
        .then(response => {
          let entity_type = response.data;
          dispatch({
            type: GET_ENTITY_TYPE,
            entity_type: entity_type,
            entityType:
              entityType === MODEL_REVISION_TYPE ? MODEL_TYPE : entityType
          });
          dispatch({
            type: END_LOADING_ENTITY_TYPE,
            entityType
          });
        })
        .catch(err => {
          if (
            err.response.status === 404 ||
            err.response.data.status === "NOT_FOUND"
          ) {
            dispatch({
              type: ERROR_ENTITY_TYPE,
              entityType,
              error: "NOT_FOUND"
            });
          } else {
            let message = err.response.data.message;
            if (!message) {
              message = err.response.data.status;
            }
            dispatch({
              type: ERROR_ENTITY_TYPE,
              entityType,
              error: message
            });
          }
          dispatch({
            type: END_LOADING_ENTITY_TYPE,
            entityType
          });
        });
    };
  };
}
export function getEntityTypes(entityType) {
  return function(dispatch) {
    dispatch({
      type: LOADING_ENTITY_TYPES,
      entityType
    });
    axios
      .get(`/api/${getEntityGetType(entityType)}`, {
        withCredentials: true
      })
      .then(response => {
        let types = response.data;
        dispatch({
          type: GET_ENTITY_TYPES,
          entityTypes: types,
          entityType:
            entityType === MODEL_REVISION_TYPE ? MODEL_TYPE : entityType
        });
        dispatch({
          type: END_LOADING_ENTITY_TYPES,
          entityType
        });
      })
      .catch(err => {
        const message = err.response.data.message;
        dispatch({
          type: ERROR_ENTITY_TYPES,
          entityType,
          error: message
        });
        dispatch({
          type: END_LOADING_ENTITY_TYPES,
          entityType
        });
      });
  };
}
