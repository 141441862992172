import {
  GET_TASKS,
  ADD_TASKS,
  LOADING_TASKS,
  END_LOADING_TASKS,
  TASKS_ERROR,
  LOADING_CLAIMABLE_TASKS,
  GET_CLAIMABLE_TASKS,
  ADD_CLAIMABLE_TASKS,
  END_LOADING_CLAIMABLE_TASKS,
  CLAIMABLE_TASKS_ERROR,
  GET_TASK,
  LOADING_TASK,
  END_LOADING_TASK,
  ERROR_LOADING_TASK,
  EXECUTING_ACTION,
  END_EXECUTING_ACTION,
  EXECUTING_ACTION_ERROR,
  GET_WORKFLOW_DIAGRAM,
  LOADING_WORKFLOW_DIAGRAM,
  END_LOADING_WORKFLOW_DIAGRAM,
  WORKFLOW_DIAGRAM_ERROR,
  SET_DIAGRAM_VALUES,
  SETTING_BUSINESS_OWNERS_TASK,
  END_SETTING_BUSINESS_OWNERS_TASK,
  ERROR_SETTING_BUSINESS_OWNERS_TASK,
  LOADING_TASK_AUDIT_TRAILS,
  END_LOADING_TASK_AUDIT_TRAILS,
  ERROR_LOADING_TASK_AUDIT_TRAILS,
  GET_TASK_AUDIT_TRAILS,
  OPEN_TASK,
  CLOSE_TASK,
  UNAUTH_USER
} from "constants/ActionTypes";
import { goThroughList, getIdValue } from "api/List";
import { TASK_ACTION_CLAIM } from "constants/Task";

export default function(state = {}, action) {
  let found, result;
  const taskId = action.taskId;
  let current = state.current;
  let mine = state.mine;
  let claimable = state.claimable;
  const workflowId = action.workflowId;

  switch (action.type) {
    case GET_TASKS:
      return {
        ...state,
        mine: action.tasks,
        error: undefined
      };
    case ADD_TASKS:
      return {
        ...state,
        mine: [...state.mine, ...action.tasks],
        claimable_error: undefined
      };
    case LOADING_TASKS:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case END_LOADING_TASKS:
      return { ...state, loading: false };
    case TASKS_ERROR:
      return { ...state, error: action.error };

    case GET_CLAIMABLE_TASKS:
      return {
        ...state,
        claimable: action.tasks,
        claimable_error: undefined
      };
    case ADD_CLAIMABLE_TASKS:
      return {
        ...state,
        claimable: [...state.claimable, ...action.tasks],
        claimable_error: undefined
      };
    case LOADING_CLAIMABLE_TASKS:
      return {
        ...state,
        claimable_loading: true,
        claimable_error: undefined
      };
    case END_LOADING_CLAIMABLE_TASKS:
      return { ...state, claimable_loading: false };
    case CLAIMABLE_TASKS_ERROR:
      return { ...state, claimable_error: action.error };

    case GET_TASK:
      result = goThroughTasksLists(
        mine,
        claimable,
        current,
        taskId,
        (task, claimable, current) => {
          if (claimable && !isClaimable(action.task)) {
            return null;
          }
          if (
            !claimable &&
            !current &&
            action.me &&
            action.task.owner !== action.me
          ) {
            return null;
          }
          if (!current) {
            found = true;
          }
          if (action.task) {
            if (!action.task.diagram) {
              action.task.diagram = task.diagram;
              action.task.diagramValues = task.diagramValues;
            }
            if (!action.task.auditTrails) {
              action.task.auditTrails = task.auditTrails;
            }
          }
          return action.task;
        }
      );
      if (!found) {
        if (isClaimable(action.task)) {
          result.claimable.push(action.task);
        } else if (
          action.me &&
          action.task &&
          action.task.owner &&
          action.task.owner === action.me
        ) {
          result.mine.push(action.task);
        }
      }
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };

    case LOADING_TASK:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        task.loading = true;
        task.error_loading = undefined;
        return task;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case END_LOADING_TASK:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        task.loading = false;
        return task;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case ERROR_LOADING_TASK:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        task.error_loading = action.error;
        return task;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };

    case EXECUTING_ACTION:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        return {
          ...task,
          executing_action: action.action,
          error: undefined
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case END_EXECUTING_ACTION:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        return {
          ...task,
          executing_action: undefined
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case EXECUTING_ACTION_ERROR:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        return {
          ...task,
          error: action.error
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };

    case SETTING_BUSINESS_OWNERS_TASK:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        task.setting_business_owners = true;
        task.error_business_owners = undefined;
        return task;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case END_SETTING_BUSINESS_OWNERS_TASK:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        task.setting_business_owners = false;
        return task;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case ERROR_SETTING_BUSINESS_OWNERS_TASK:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        task.error_business_owners = action.error;
        return task;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };

    case LOADING_TASK_AUDIT_TRAILS:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        return {
          ...task,
          loading_audit_trails: true,
          error_audit_trails: undefined
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case END_LOADING_TASK_AUDIT_TRAILS:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        return {
          ...task,
          loading_audit_trails: false
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case ERROR_LOADING_TASK_AUDIT_TRAILS:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        task.error_audit_trails = action.error;
        return {
          ...task,
          error_audit_trails: action.error
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case GET_TASK_AUDIT_TRAILS:
      result = goThroughTasksLists(mine, claimable, current, taskId, task => {
        return {
          ...task,
          auditTrails: action.auditTrails
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };

    case OPEN_TASK:
      return {
        ...state,
        open: true,
        current: action.task
      };
    case CLOSE_TASK:
      return {
        ...state,
        open: false,
        current: null
      };
    case UNAUTH_USER:
      return {
        ...state,
        open: false,
        current: null
      };

    case GET_WORKFLOW_DIAGRAM:
      result = goThroughTasksLists(
        mine,
        claimable,
        current,
        workflowId,
        task => {
          return {
            ...task,
            diagram: action.diagram
          };
        },
        "workflowId"
      );
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case LOADING_WORKFLOW_DIAGRAM:
      result = goThroughTasksLists(
        mine,
        claimable,
        current,
        workflowId,
        task => {
          return {
            ...task,
            loading_diagram: true,
            error_diagram: undefined
          };
        },
        "workflowId"
      );
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case END_LOADING_WORKFLOW_DIAGRAM:
      result = goThroughTasksLists(
        mine,
        claimable,
        current,
        workflowId,
        task => {
          return {
            ...task,
            loading_diagram: false
          };
        },
        "workflowId"
      );
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    case WORKFLOW_DIAGRAM_ERROR:
      result = goThroughTasksLists(
        mine,
        claimable,
        current,
        workflowId,
        task => {
          return {
            ...task,
            error_diagram: action.error
          };
        },
        "workflowId"
      );
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };

    case SET_DIAGRAM_VALUES:
      result = goThroughTasksLists(
        mine,
        claimable,
        current,
        workflowId,
        task => {
          return {
            ...task,
            diagramValues: action.values
          };
        },
        "workflowId"
      );
      return {
        ...state,
        current: Object.assign({}, result.current),
        mine: result.mine,
        claimable: result.claimable
      };
    default:
      return state;
  }
}

function goThroughTasksLists(
  mine,
  claimable,
  current,
  taskId,
  editTask,
  idKey = "taskId"
) {
  let idValue = getIdValue(idKey, current);
  if (idValue && taskId && idValue.toString() === taskId.toString()) {
    current = editTask(current, false, true);
  }
  let result = goThroughList(
    mine,
    null,
    taskId,
    task => {
      return editTask(task, false, false);
    },
    idKey
  );
  mine = result.list;
  let result2 = goThroughList(
    claimable,
    null,
    taskId,
    task => {
      return editTask(task, true, false);
    },
    idKey
  );
  claimable = result2.list;
  return {
    mine: mine,
    claimable: claimable,
    current: current
  };
}

function isClaimable(task) {
  return task && task.possibleActions && task.possibleActions.includes(TASK_ACTION_CLAIM);
}
