import React, { Component } from 'react';

class CompilationErrors extends Component {

    constructor(props) {
        super(props);
        this.state = {currentErrors: null};
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors != null) {
            this.setState({currentErrors: nextProps.errors});
        }

        if (!this.props.editing) {
            this.setState({currentErrors: null});
        }
    }

    render() {
        const { currentErrors } = this.state;
        if (!currentErrors) {
            return null;
        }
        return currentErrors && currentErrors.map((error, idx) => {
            return (
                <div key={idx} className="error">
                    { error.type + ": " + error.message }
                </div>
            )
        });
    };
}

export default CompilationErrors;
