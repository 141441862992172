import React, {useState} from "react";
import { withRouter } from "react-router";
import ButtonLoading from "../Buttons/ButtonLoading";
import { Field, reduxForm } from "redux-form";
import {
  RequiredValidator,
  NonEmptyStringValidator
} from "../Form/Fields/FieldValidators";
import InputField from "../Form/Fields/InputField";
import SelectField from "../Form/Fields/SelectField";
import CheckboxField from "../Form/Fields/CheckboxField";
import SecurityLevels from "../SecurityLevels/input";
import {
  MenuItem,
  DialogContent,
  Dialog,
  DialogActions
} from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";
import {makeStyles} from "@material-ui/core/styles";

const QUERY_FORMATS = [
  {
    label: "SQL query",
    value: "SQL_QUERY"
  }
];
const RESULT_FORMATS = [
  {
    label: "Table",
    value: "TABLE"
  },
  {
    label: "Time serie",
    value: "TIMESERIES"
  },
  {
    label: "Pie chart",
    value: "PIE_CHART"
  }
];

const CreateWidgetFormDialog = reduxForm({
  form: "createwidgetdialog",
  shouldWarn: () => {
    return true;
  },
  onSubmitFail: scrollToFirstError
})(({
  handleSubmit,
  handleClose,
  open,
  creating,
  error
}) => {
  const classes = useStyles();
  const [resultFormatSelected, setResultFormatSelected] = useState(null);

  const onResultFormatChange = event => {
    setResultFormatSelected(event.target.value);
  };

  let otherInputs;

  switch (resultFormatSelected) {
    case "TABLE":
      otherInputs = (
        <div className={classes.field}>
          <Field
            label={"Include column names"}
            name="includeColumnNames"
            component={CheckboxField}
            validate={[]}
          />
        </div>
      );
      break;
    case "TIMESERIES":
      otherInputs = (
        <React.Fragment>
          <div className={classes.field}>
            <Field
              label={"Column on X axis"}
              name="parameters.axisX"
              component={InputField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
            />
          </div>
          <div className={classes.field}>
            <Field
              label={"Column on Y axis"}
              name="parameters.axisY"
              component={InputField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
            />
          </div>
          <div className={classes.field}>
            <Field
              label={"Label column"}
              name="parameters.dataLabel"
              component={InputField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
            />
          </div>
        </React.Fragment>
      );
      break;
  }

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
      <form onSubmit={handleSubmit}>
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={handleClose}
        >
          Create a widget
        </CloseableDialogTitle>
        <DialogContent>
          <div className={classes.field}>
            <Field
              label={"Name"}
              name="name"
              component={InputField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
            />
          </div>
          <div className={classes.field}>
            <Field
              label={"Query format"}
              name="queryFormat"
              component={SelectField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
            >
              {QUERY_FORMATS.map(function(queryFormat, idx) {
                return (
                  <MenuItem key={idx} value={queryFormat.value}>
                    {queryFormat.label}
                  </MenuItem>
                );
              })}
            </Field>
          </div>
          <div className={classes.field}>
            <Field
              label={"Result format"}
              name="resultFormat"
              onChange={onResultFormatChange}
              component={SelectField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
            >
              {RESULT_FORMATS.map(function(resultFormat, idx) {
                return (
                  <MenuItem key={idx} value={resultFormat.value}>
                    {resultFormat.label}
                  </MenuItem>
                );
              })}
            </Field>
          </div>
          <div className={classes.field}>
            <Field
              label={"Query"}
              name="query"
              multiline
              component={InputField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
            />
          </div>
          {otherInputs}
          <div className={classes.field}>
            <SecurityLevels />
          </div>
          <div className={classes.field}>
            <Field
              label={"Show on dashboard"}
              name="shownOnDashboard"
              component={CheckboxField}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonLoading
            loading={creating}
            loadingTitle="Creating the widget"
            className="color-primary"
            type="submit"
            variant="outlined"
            color="primary"
          >
            {"Create a widget"}
          </ButtonLoading>
          <div className="error">{error}</div>
        </DialogActions>
      </form>
    </Dialog>
  );
});

const useStyles = makeStyles(theme => ({
  field: {
    margin: 20
  }
}));

export default withRouter(CreateWidgetFormDialog);
