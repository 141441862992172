import React, { useState } from "react";
import { Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Delete";
import IconButton from "components/Buttons/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";
import AddDateForm from "./AddDateForm";
import {
  addExportDate,
  removeExportDate,
} from "../../../actions/ExportHistory";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  container: {
    margin: "16px 0",
    padding: "0 16px 8px 16px",
    overflowY: "hidden",
    flexDirection: "column",
    height: "100%",
  },
  formStyle: {
    overflowY: "auto",
    height: "calc(100% - 92px)",
  },
  dates: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "10px",
  },
  error: {
    color: "red",
  },
}));

const datesCompare = (a, b) => {
  if (!a) return 1;
  if (!b) return -1;
  const timestampA = new Date(a).getTime();
  const timestampB = new Date(b).getTime();
  return timestampA - timestampB;
};

const endOfDay = date => {
  return moment(date, ZONE_DATETIME_FORMAT)
    .hour(23)
    .minute(59)
    .second(59)
    .format(ZONE_DATETIME_FORMAT);
};

const ZONE_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";
const DISPLAY_DATETIME_FORMAT = "DD/MM/YYYY";

export default ({ entityType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dates = useSelector(state => state.exportHistory[entityType] || []);

  const [error, setError] = useState("");

  const handleAddDate = ({ dateInput }) => {
    if (!dateInput || dateInput === "Invalid date") {
      setError("Invalid date");
      return;
    }
    if (moment(dateInput, ZONE_DATETIME_FORMAT).isAfter(new Date())) {
      setError("Date should not be after maximal date");
      return;
    }
    const eod = endOfDay(dateInput);
    if (dates.includes(eod)) {
      setError("Already added");
      return;
    }
    addExportDate(entityType, eod)(dispatch);
  };

  const handleRemoveField = inputDate => {
    removeExportDate(entityType, inputDate)(dispatch);
  };

  const renderFields = () => {
    return dates.sort(datesCompare).map(date => {
      return (
        <TextField
          variant="standard"
          disabled
          id={date}
          value={moment(date, ZONE_DATETIME_FORMAT).format(
            DISPLAY_DATETIME_FORMAT
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => handleRemoveField(date)}
                >
                  <RemoveIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      );
    });
  };

  return (
    <Paper variant="outlined" className={classes.container}>
      <Typography variant="h6" style={{ margin: "10px 0" }}>
        Export Dates
      </Typography>
      <div className={classes.formStyle}>
        <div className={classes.dates}>{renderFields()}</div>
      </div>
      <AddDateForm onSubmit={handleAddDate} clearError={() => setError("")} />
      <div className="error">{error}</div>
    </Paper>
  );
};
