import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { ROLE_CONTAINER_CREATE } from "../../constants/Authorities";
import CreateContainersDialogForm from "components/Containers/CreateDialogForm";
import { createAnalyticalToolContainer } from "actions/Containers";

class ContainersEntitiesButton extends Component {
  state = {
    openCreateAnalyticalToolDialog: false
  };

  onCreateAnalyticalToolContainer = () => {
    this.setState({
      openCreateAnalyticalToolDialog: true
    });
  };
  handleCloseCreateAnalyticalTool = () => {
    this.setState({
      openCreateAnalyticalToolDialog: false
    });
  };
  onSubmitAnalyticalTool = form => {
    const { selectedEntities } = this.props;
    const { createAnalyticalToolContainer } = this.props;
    createAnalyticalToolContainer(
      form.username,
      form.password,
      form.imageAlias,
      selectedEntities
    );
    this.setState({
      selected: []
    });
  };

  render() {
    const { openCreateAnalyticalToolDialog } = this.state;
    const { opening_analytical_tool } = this.props;

    return (
      <React.Fragment>
        <ButtonLoading
          testid="createdatalab"
          variant="outlined"
          color="primary"
          tooltipTitle="Create a Datalab"
          loading={opening_analytical_tool}
          loadingTitle="Creating the Datalab"
          authoritiesRequired={[ROLE_CONTAINER_CREATE]}
          aria-label="Create a Datalab"
          onClick={this.onCreateAnalyticalToolContainer}
        >
          Create a Datalab
        </ButtonLoading>
        <CreateContainersDialogForm
          open={openCreateAnalyticalToolDialog}
          handleClose={this.handleCloseCreateAnalyticalTool}
          onSubmit={this.onSubmitAnalyticalTool}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    opening_analytical_tool: state.containers.opening_analytical_tool
  };
};
export default connect(
  mapStateToProps,
  { createAnalyticalToolContainer }
)(ContainersEntitiesButton);
