import { grey, amber } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

/**
 *  Light Theme is the default theme used in material-ui. It is guaranteed to
 *  have all theme variables needed for every component. Variables not defined
 *  in a custom theme will default to these values.
 */

const FAB_STYLE = {
  position: "fixed",
  bottom: 4 * 6,
  right: 4 * 6,
  zIndex: 1
};
export default createMuiTheme({
  typography: {
    useNextVariants: true
  },
  fontFamily: "Roboto, sans-serif",
  borderRadius: 2,
  palette: {
    primary: {
      light: "#8eacbb",
      main: "#607d8b",
      dark: "#34515e",
      contrastText: "#000000"
    },
    secondary: {
      light: "#fff350",
      main: "#ffc107",
      dark: "#c79100",
      contrastText: "#000000"
    },
    textColor: "#000",
    secondaryTextColor: "rgba(0,0,0,0.54)",
    alternateTextColor: "#fff",
    canvasColor: "#fff",
    borderColor: grey[300],
    disabledColor: "rgba(0,0,0,0.3)",
    pickerHeaderColor: amber[500],
    clockCircleColor: "rgba(0,0,0,0.07)",
    shadowColor: "#000"
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        color: "white",
        boxShadow: "none"
      },
      colorDefault: {
        boxShadow: "none"
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: 20,
        "button:not([icon]) &": {
          marginRight: 10,
          fontSize: "18px !important"
        },
        "button:not([icon]) button &": {
          marginRight: 0,
          fontSize: 20
        }
      }
    },
    MuiCircularProgress: {
      root: {}
    },
    MuiButton: {
      containedPrimary: {
        color: "white"
      },
      root: {
        "&:not([icon]) .material-icons": {
          marginRight: 10,
          fontSize: "18px !important"
        },
        "&[icon]": {
          borderRadius: "50%",
          padding: 12,
          minWidth: 0,
          minHeight: 0
        }
      },
      text: {
        padding: "8px 16px"
      }
    },
    MuiIconButton: {
      root: {
        "& svg": {
          marginRight: "0 !important"
        }
      }
    },
    MuiToggleButton: {
      "&$selected": {
        backgroundColor: "#607d8b",
        color: "white",
        "&:after": {
          opacity: 0
        }
      },
      root: {
        "&:hover": {
          color: "#9f9f9f"
        },
        height: 36
      }
    },
    MuiCard: {
      root: {
        border: "solid 1px #eee",
        boxShadow: "none"
      }
    },
    MuiToggleButtonGroup: {
      "&$selected": {
        border: "solid 1px #eee",
        boxShadow: "none"
      }
    },
    MuiPaper: {
      elevation1: {
        border: "solid 1px #eee",
        boxShadow: "none"
      },
      elevation2: {
        border: "solid 1px #eee",
        boxShadow: "none"
      }
    },
    MuiExpansionPanel: {
      root: {
        marginTop: -1,
        "&:before": {
          content: null,
          opacity: 0
        }
      }
    },
    MuiExpansionPanelSummary: {
      content: {
        "&$expanded": {
          margin: "10px 0"
        }
      },
      root: {
        "&$expanded": {
          minHeight: 48
        }
      }
    },
    MuiTableCell: {
      body: {
        whiteSpace: "nowrap",
        paddingRight: "10px",
        "tr[dropping] &": {
          color: "#ffc107"
        }
      },
      root: {},
      paddingNone: {
        paddingRight: "10px"
      }
    },
    MuiTableRow: {
      head: {
        height: 56
      }
    },
    MuiTabs: {
      root: {
        background: "#fafafa",
        marginBottom: 10
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        overflow: "auto",
        paddingTop: 0,
        paddingBottom: 8
      }
    },
    MuiSnackbarContent: {
      root: {
        maxWidth: "100% !important"
      }
    },
    MuiTablePagination: {
      root: {
        ".widget &": {
          position: "absolute",
          bottom: 0,
          width: "100%",
          background: "white"
        },
        "&:before": {
          content: '""',
          position: "relative",
          width: "100%",
          height: 56
        },
        "& svg": {
          marginRight: "0 !important"
        }
      }
    },
    MuiCheckbox: {
      root: {
        padding: "0",
        margin: "0 12px",
        "tr[dropping] &": {
          color: "#ffc107"
        },
        "button &": {
          margin: 0
        },
        "& svg": {
          fontSize: "20px !important",
          marginRight: 0
        }
      }
    },
    MuiListItem: {
      container: {
        listStyleType: "none"
      }
    },
    MuiFab: {
      primary: {
        color: "white"
      },
      root: FAB_STYLE
    },
    MuiSpeedDial: {
      root: FAB_STYLE,
      fab: {
        position: "relative",
        bottom: 0,
        right: 0
      }
    },
    MuiSpeedDialAction: {
      button: {
        position: "relative",
        bottom: 0,
        right: 0
      }
    },
    MuiSwitch: {
      switchBase: {
        color: "#c1c1c1",
        height: "inherit"
      },
      track: {
        backgroundColor: "#afafaf"
      },
      "&$disabled": {
        "&$checked": {
          color: "#607d8b"
        }
      }
    },
    MuiChip: {
      colorPrimary: {
        color: "white"
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0,
        marginRight: 16
      }
    },
    MuiListItemText: {
      root: {
        lineHeight: 0
      }
    },
    MuiExpansionPanelActions: {
      root: {
        padding: "0 10px 10px",
        justifyContent: "flex-start"
      }
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: "white"
      },
      toolbarTxt: {
        color: "white"
      }
    },
    MuiPickersDay: {
      daySelected: {
        color: "white"
      }
    },
    PrivateBreadcrumbSeparator: {
      root: {
        marginLeft: 0,
        marginRight: 0
      }
    },
    MuiDialogActions: {
      root: {
        "form &": {
          marginBottom: 20,
          padding: "8px 24px",
          justifyContent: "center",
          flexWrap: "wrap-reverse"
        },
        "form & .error": {
          width: "100%",
          marginLeft: 0
        },
        "form & button": {
          width: 300
        }
      }
    }
  }
});
