import React, {useEffect, useState} from "react";
import RoleItem from "components/Admin/Users/Roles/RoleItem";
import {makeStyles} from "@material-ui/core/styles";
import {useApiRequest} from "actions/index";
import {updateGrantedAuthoritiesForRole} from "actions/Roles";
import {pushNotification} from "actions/notifications";
import {useDispatch} from "react-redux";
import RoleDialogForm from 'components/Admin/Users/Roles/Dialog/RoleDialogForm';
import { updateRole } from 'actions/Roles';
import { useApiRequestV2 } from 'actions';
import GeneralDialog from 'components/Admin/Users/Common/GeneralDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "auto",
    padding: "1px 0",
    backgroundColor: theme.palette.background.paper
  }
}));

  const RolesList = ({roles, onRoleUpdate}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);

  const [updatingAuthority, errorUpdatingAuthority, addAuthorityToRole] = useApiRequest(
    updateGrantedAuthoritiesForRole,
    (role) => {
      dispatch(pushNotification("check", "success", "Granted authorities saved sucessfully."));
    },
    (e, message) => {
      dispatch(pushNotification(
        "error",
        "error",
        `Updating granted authorities to role failed${message ? `: ${message}` : ""}`
      ));
    }
  );

  const { isLoading: isRoleUpdateLoading, errorObject: errorObjectRoleUpdate, reset: resetRoleUpdate, run: runRoleUpdate } = useApiRequestV2(
    updateRole,
    (response) => {
      onRoleUpdate(response);
      handleUpdateDialogClose();
  });

  const handleOpenUpdateDialog = (role) => {
    setIsUpdateDialogOpen(true);
    setCurrentRole(role);
  };

  const handleUpdateDialogClose = () => {
    setIsUpdateDialogOpen(false);
    setCurrentRole(null);
    resetRoleUpdate();
  };

  const handleSubmit = async (role, form) => {
    addAuthorityToRole(role.id, form.assignedAuthorities);
  };

  const renderRole = (role, idx) => {
    const formName = "roleitemform" + role.id;
    return (
      <RoleItem
        idx={idx}
        role={role}
        updatingAuthority={updatingAuthority}
        errorUpdatingAuthority={errorUpdatingAuthority}
        form={formName}
        onSubmit={form => handleSubmit(role, form)}
        onUpdateClick={handleOpenUpdateDialog}
      />
    );
  };

  return (
    <>
      <GeneralDialog
        title="Update role"
        isOpen={isUpdateDialogOpen}
        onClose={handleUpdateDialogClose}
      >
        <RoleDialogForm
          initialValues={currentRole}
          saving={isRoleUpdateLoading}
          errorSaving={errorObjectRoleUpdate}
          onSubmit={runRoleUpdate}
        />
      </GeneralDialog>
      <div className={classes.root}>
        {roles.map(renderRole)}
      </div>
    </>
  );

}

export default RolesList;