import React, { Component } from "react";

import { connect } from "react-redux";
import { FilterList } from "@material-ui/icons";
import {
  Checkbox,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { updateUserPreferences } from "actions/Configuration";
import { getAttributes } from "actions/Attributes";
import { hideAttribute, showAttribute } from "../../actions/Attributes";
import { isAttributeToShow } from "api/Attributes";
import BatchSelect from "components/Attributes/BatchSelect";
import IconButton from "../Buttons/IconButton";

class AttributesFilter extends Component {
  state = {
    anchorEl: null,
    isSelectAll: true
  };

  componentDidMount() {
    const { getAttributes, attributesFromResult } = this.props;
    getAttributes();
    if (attributesFromResult) {
      for (let attributesFromResultElement of attributesFromResult) {
        if (!this.isAttributeShown({ id: attributesFromResultElement })) {
          this.setState({ ...this.state, isSelectAll: false });
          break;
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { attributesToShow, preferences, updateUserPreferences } = nextProps;
    if (attributesToShow !== this.props.attributesToShow) {
      updateUserPreferences({
        ...preferences,
        selectedAttributes: attributesToShow
      });
    }
  }

  isAttributeShown = attribute => {
    const { attributesToShow } = this.props;
    return isAttributeToShow(attributesToShow, attribute);
  };

  handleToggle = attribute => () => {
    const { showAttribute, hideAttribute } = this.props;
    if (!this.isAttributeShown(attribute)) {
      showAttribute(attribute);
    } else {
      hideAttribute(attribute);
    }
    this.setState({ ...this.state, isSelectAll: false });
  };
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleBatchSelect = () => {
    const {
      attributes,
      attributesFromResult,
      showAttribute,
      hideAttribute
    } = this.props;
    const { isSelectAll } = this.state;

    if (!attributes) {
      return;
    }

    for (let attribute of attributes) {
      if (attributesFromResult && attributesFromResult.includes(attribute.id)) {
        if (isSelectAll) {
          hideAttribute(attribute);
        } else {
          showAttribute(attribute);
        }
      }
    }

    this.setState({ ...this.state, isSelectAll: !isSelectAll });
  };

  /*
    Compare attributes in the filter list alphabetically based on the label if available.
  */
  compareAttributes = (a1, a2) => {
    if (a1.label && a2.label) {
      return a1.label.localeCompare(a2.label);
    }
    return 1;
  };

  getMenu = (
    attributes,
    attributesFromResult,
    anchorEl,
    isSelectAll,
    classes
  ) => {
    if (!attributes) {
      return null;
    }
    const sortedAttributes = attributes.sort(this.compareAttributes);

    return (
      <Menu
        testid="filterAttributesList"
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.handleClose}
      >
        <MenuList>
          <BatchSelect
            isSelectAll={isSelectAll}
            onClick={this.handleBatchSelect}
          />
          {sortedAttributes.map((attribute, idx) => {
            if (
              attributesFromResult &&
              !attributesFromResult.includes(attribute.id)
            ) {
              return null;
            }
            return (
              <MenuItem
                key={attribute.id}
                role={undefined}
                dense
                button
                testid={attribute.id}
                onClick={this.handleToggle(attribute)}
                className={classes.listItem}
              >
                <Checkbox
                  checked={this.isAttributeShown(attribute)}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={attribute.label} />
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  };

  render() {
    const { attributes, classes, attributesFromResult } = this.props;
    const { anchorEl, isSelectAll } = this.state;
    const menu = this.getMenu(
      attributes,
      attributesFromResult,
      anchorEl,
      isSelectAll,
      classes
    );

    return (
      <div>
        <Tooltip title="Filter list attributes">
          <IconButton
            testid="filterAttributes"
            aria-label="Filter list attributes"
            onClick={this.handleClick}
          >
            <FilterList />
          </IconButton>
        </Tooltip>
        {menu}
      </div>
    );
  }
}

const styles = theme => ({});

const mapStateToProps = (state, ownProps) => {
  const { entityType, attributesFromResult } = ownProps;

  return {
    attributesFromResult: attributesFromResult
      ? attributesFromResult
      : state.filters.attributes
      ? state.filters.attributes[entityType]
      : undefined,
    attributes: state.attributes.list,
    attributesToShow: state.attributes.attributesToShow,
    preferences: state.auth.preferences
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getAttributes,
    showAttribute,
    hideAttribute,
    updateUserPreferences
  })(AttributesFilter)
);
