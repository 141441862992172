import React, { useState } from 'react';

import {
  CheckCircleOutlined,
  HelpOutlineRounded,
  HighlightOffOutlined,
  InfoOutlined,
  WatchLaterOutlined,
} from '@material-ui/icons';
import { Dialog, DialogContent, Tooltip, Typography } from '@material-ui/core';

import Config from 'constants/Config';
import IconButton from 'components/Buttons/IconButton';
import CloseableDialogTitle from 'components/Text/CloseableDialogTitle';

const FileValidation = ({ validationMessage, validationStatus }) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDialogOpen(false);
  };

  let backgroundColorStatus, Icon;
  switch (validationStatus) {
    case 'INITIALIZED':
      Icon = InfoOutlined;
      backgroundColorStatus = Config.color.primary;
      break;
    case 'IN_PROGRESS':
      Icon = WatchLaterOutlined;
      backgroundColorStatus = Config.color.warning;
      break;
    case 'PASSED':
      Icon = CheckCircleOutlined;
      backgroundColorStatus = Config.color.success;
      break;
    case 'FAILED':
      Icon = HighlightOffOutlined;
      backgroundColorStatus = Config.color.danger;
      break;
    default:
      Icon = HelpOutlineRounded;
      backgroundColorStatus = Config.color.gray;
      break;
  }

  const statusLabel = validationStatus ? validationStatus : 'No status';
  const isDisabled = !(Boolean(validationMessage));

  return (
    <>
      <Tooltip placement="bottom-start" title={statusLabel}>
          <span>
            <IconButton
              disabled={isDisabled}
              onClick={handleOpenDialog}
            >
              <Icon
                fontSize="inherit"
                color="inherit"
                style={{
                  color: backgroundColorStatus,
                  verticalAlign: 'middle',
                  fontSize: 20,
                }}
              />
            </IconButton>
          </span>
      </Tooltip>
      <Dialog
        onClose={handleCloseDialog}
        open={isDialogOpen}
      >
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={handleCloseDialog}
        >
          Validation message
        </CloseableDialogTitle>
        <DialogContent>
          <Typography>{validationMessage}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FileValidation;
