import {
  CHANGE_RESULT_FORMAT,
  LOCK_UI,
  UNLOCK_UI,
  SET_CHOOSE_ENTITIES,
  CLOSE_SNACKBAR,
  LONG_REQUEST_PENDING,
  END_LONG_REQUEST_PENDING,
  OPEN_TOKEN_DIALOG,
  CLOSE_TOKEN_DIALOG,
  CHANGE_ATTACHMENT_VIEW,
} from "../constants/ActionTypes";
import GlobalState from "../constants/GlobalState";
const globalState = GlobalState.state;

export default function (state = globalState, action) {
  switch (action.type) {
    case LONG_REQUEST_PENDING: {
      return {
        ...state,
        pending: true,
      };
    }
    case END_LONG_REQUEST_PENDING: {
      return {
        ...state,
        pending: false,
      };
    }
    case LOCK_UI: {
      return {
        ...state,
        locked: true,
      };
    }
    case UNLOCK_UI: {
      return {
        ...state,
        locked: false,
      };
    }
    case OPEN_TOKEN_DIALOG: {
      return {
        ...state,
        open_token_dialog: true,
      };
    }
    case CLOSE_TOKEN_DIALOG: {
      return {
        ...state,
        open_token_dialog: false,
      };
    }
    case SET_CHOOSE_ENTITIES: {
      return {
        ...state,
        choose_entity_settings: action.settings,
      };
    }
    case CLOSE_SNACKBAR: {
      const snackbar = {};
      snackbar.open = false;
      return {
        ...state,
        snackbar,
      };
    }
    case CHANGE_RESULT_FORMAT: {
      return {
        ...state,
        result_format: action.result_format,
      };
    }
    case CHANGE_ATTACHMENT_VIEW: {
      return {
        ...state,
        attachment_view: action.view,
      };
    }
    default:
      return state;
  }
}
