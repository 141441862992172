import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { withRouter } from "react-router";
import Grid from "components/Grid";
import {createWidget, getWidgets} from "actions/Widgets";
import CreateWidgetFormDialog from "components/Widget/CreateWidgetFormDialog";
import Widget from "components/Widget/Widget";
import { ROLE_WIDGET_CREATE } from "constants/Authorities";
import {
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryAxis,
  VictoryLegend
} from "victory";
import { MOCK_HOMEPAGE } from "constants/Features";
import { getColorsScale } from "constants/Colors";
import {CircularProgress, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useApiRequest} from "actions";
import TabCentered from "components/TabContainer/TabCentered";
import {pushNotification} from "actions/notifications";

const COLOR_SCALE = getColorsScale(2);

const AppOverview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const features = useSelector((state) => state.features.list);
  const [widgets, setWidgets] = useState([]);
  const [openDialogCreateWidget, setOpenDialogCreateWidget] = useState(false);

  const [loading, error, runGetWidgets] = useApiRequest(getWidgets,
    returnedWidgets => {
      setWidgets(returnedWidgets);
    }
  );

  const [creatingWidget, errorCreatingWidget, runCreateWidget] = useApiRequest(
    form => createWidget(form),
    createdWidget => {
      setWidgets([...widgets, createdWidget]);
      setOpenDialogCreateWidget(false);
      dispatch(pushNotification("check", "success", "Widget created"));
    }
  );

  useEffect(() => {
    runGetWidgets();
  },[]);

  const addWidget = () => {
    setOpenDialogCreateWidget(true);
  };
  const onCloseCreateWidgetDialog = () => {
    setOpenDialogCreateWidget(false);
  };

  const handleSubmitCreateWidget = form => {
    runCreateWidget(form);
  };

  const renderShowcase = () => {
    const isFeatureEnabled = features && features.includes(MOCK_HOMEPAGE);
    if (!isFeatureEnabled) {
      return null;
    }
    return (
      <React.Fragment>
        <Grid testid="showcase" container className={classes.showcaseContainer}>
          <Grid item xs={4} className={classes.showcaseItem}>
            <div className={classes.showcaseNumber}>124</div>
            <div className={classes.showcaseLabel}>models</div>
          </Grid>
          <Grid item xs={4} className={classes.showcaseItem}>
            <div className={classes.showcaseNumber}>12</div>
            <div className={classes.showcaseLabel}>validations</div>
          </Grid>
          <Grid item xs={4} className={classes.showcaseItem}>
            <div className={classes.showcaseNumber}>24</div>
            <div className={classes.showcaseLabel}>connected technologies</div>
          </Grid>
        </Grid>
        <div className={classes.showcaseGraph}>
          <VictoryChart
            height={300}
            width={window.innerWidth}
            theme={VictoryTheme.grayscale}
            style={{
              labels: { fontFamily: "roboto" }
            }}
          >
            <VictoryLegend
              x={100}
              y={25}
              title={null}
              colorScale={COLOR_SCALE}
              orientation="vertical"
              rowGutter={0}
              padding={{ top: 5, bottom: 5, left: 5, right: 5 }}
              style={{
                border: { stroke: "black", strokeWidth: 1 },
                title: { fontSize: 15, fontFamily: "roboto" },
                labels: { fontSize: 10, fontFamily: "roboto" }
              }}
              data={[
                {
                  name: "Average risk score"
                },
                {
                  name: "Active model count"
                }
              ]}
            />
            <VictoryLine
              animate
              domain={{ x: [1, 20], y: [0, 150] }}
              style={{
                data: { stroke: COLOR_SCALE[0] },
                tickLabels: { fontFamily: "roboto", fontSize: "10px" }
              }}
              data={[
                { x: 1, y: 124 },
                { x: 2, y: 110 },
                { x: 3, y: 23 },
                { x: 4, y: 105 },
                { x: 5, y: 85 },
                { x: 6, y: 93 },
                { x: 7, y: 94 },
                { x: 8, y: 47 },
                { x: 9, y: 56 },
                { x: 10, y: 24 },
                { x: 11, y: 32 },
                { x: 12, y: 28 },
                { x: 13, y: 26 },
                { x: 14, y: 35 },
                { x: 15, y: 41 },
                { x: 16, y: 52 },
                { x: 17, y: 23 },
                { x: 18, y: 20 },
                { x: 19, y: 25 },
                { x: 20, y: 18 }
              ]}
            />
            <VictoryLine
              animate
              domain={{ x: [1, 20], y: [0, 150] }}
              style={{
                data: { stroke: COLOR_SCALE[1] },
                tickLabels: { fontFamily: "roboto", fontSize: "10px" }
              }}
              data={[
                { x: 1, y: 18 },
                { x: 2, y: 20 },
                { x: 3, y: 25 },
                { x: 4, y: 23 },
                { x: 5, y: 22 },
                { x: 6, y: 27 },
                { x: 7, y: 31 },
                { x: 8, y: 40 },
                { x: 9, y: 42 },
                { x: 10, y: 47 },
                { x: 11, y: 52 },
                { x: 12, y: 67 },
                { x: 13, y: 72 },
                { x: 14, y: 85 },
                { x: 15, y: 100 },
                { x: 16, y: 111 },
                { x: 17, y: 105 },
                { x: 18, y: 110 },
                { x: 19, y: 120 },
                { x: 20, y: 124 }
              ]}
            />
            <VictoryAxis tickCount={10} />
            <VictoryAxis dependentAxis />
          </VictoryChart>
        </div>
      </React.Fragment>
    );
  };

  if(loading){
    return <TabCentered>
      <div
        style={{
          width: "400px",
          lineHeight: "36px",
          textAlign: "center",
          margin: "20px 10px 0"
        }}
      >
        <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
        <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
          Loading the widgets
        </span>
      </div>
    </TabCentered>
  }
  if(error){
    return <TabCentered>
      <div
        style={{
          width: "400px",
          lineHeight: "36px",
          textAlign: "center",
          margin: "20px 10px 0"
        }}
      >
        <span className="error">{error}</span>
      </div>
    </TabCentered>;
  }

  return (
    <div style={{overflow:"auto", height:"100%"}}>
      {renderShowcase()}
      <Grid container className={classes.container} spacing={5}>
        {widgets &&
          widgets
            .sort(sortByRowThenColumns)
            .map((widget, idx) => {
            return (
              <Grid
                authoritiesRequired={widget.authorities}
                item
                xs={12}
                key={idx}
                className={classes.item}
              >
                <Widget widget={widget} />
              </Grid>
            );
          })}
        <Grid
          authoritiesRequired={[ROLE_WIDGET_CREATE]}
          item
          xs={6}
          className={classes.item}
          onClick={addWidget}
        >
          <Paper className={classes.itemAdd}>
            <Typography variant="h2">+</Typography>
          </Paper>
        </Grid>
      </Grid>
      <CreateWidgetFormDialog
        open={openDialogCreateWidget}
        handleClose={onCloseCreateWidgetDialog}
        onSubmit={handleSubmitCreateWidget}
        creating={creatingWidget}
        error={errorCreatingWidget}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  showcaseContainer: {
    backgroundColor: "#607d8b"
  },
  showcaseItem: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "50px 0"
  },
  showcaseNumber: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "white",
    marginBottom: "15px"
  },
  showcaseLabel: {
    fontSize: "15px",
    color: "white"
  },
  showcaseGraph: {
    height: "300px"
  },
  container: {
    padding: "50px 105px 50px 85px",
    width: "100%",
    margin: 0
  },
  item: {
    display: "flex",
    flexDirection: "column"
  },
  itemAdd: {
    cursor: "pointer",
    flex: 1,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 200
  },
  image: {
    width: "300px"
  },
  features: {
    marginBottom: 50
  },
  feature: {
    padding: 40,
    marginBottom: 50
  },
  loadingImage: {
    width: "50px"
  },
  featureTitle: {
    margin: "30px 0",
    textAlign: "center"
  },
  featureDescription: {
    textAlign: "center"
  }
}));

const sortByRowThenColumns = (a, b) => {
  const aRow = a.rowPosition;
  const bRow = b.rowPosition;
  const aColumn = a.columnPosition;
  const bColumn = b.columnPosition;

  if(aRow === bRow && aColumn === bColumn) {
    return sort(a.id, b.id);
  }
  if(aRow === bRow) {
    return sort(aColumn, bColumn);
  }
  return sort(aRow, bRow);
};

const sort = (a, b) => {
  if (a === null) {
    return 1;
  } else if (b === null) {
    return -1;
  } else if (a === b) {
    return 0;
  } else {
    return (a < b) ? -1 : 1;
  }
};

export default withRouter(AppOverview);
