import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import FlipMove from "react-flip-move";
import AttributeFilterRow from "components/Search/AttributeFilterRow";
import ButtonLoading from "components/Buttons/ButtonLoading";

let counter = 0;
const sortSectionNameFunction = (a, b) => {
  if (b.sectionName > a.sectionName) {
    return a.sectionName > b.sectionName ? 1 : -1;
  } else {
    return a.sectionName > b.sectionName ? 1 : 0;
  }
};

const AttributesFilter = ({
  fields,
  meta: { error, submitFailed },
  initialValues,
  availableAttributes,
  formName,
  disabled,
  label,
  inputName,
}) => {
  const addField = () => {
    fields.push({
      id: counter,
    });
    counter++;
  };

  const getSections = () => {
    return fields
      .map((key, index) => {
        const field = fields.get(index);
        const { attributeName } = field;
        let sectionName = "none";
        if (attributeName) {
          const [first, second] = attributeName.split(":");
          if (second) {
            sectionName = first;
          }
        }
        return {
          key,
          index,
          field,
          sectionName,
        };
      })
      .sort(sortSectionNameFunction);
  };

  let prevSectionName;
  return (
    <React.Fragment>
      <Typography variant="subtitle2">Filters</Typography>
      <FlipMove style={{ margin: "0 0 10px 0" }}>
        {getSections().map(({ field, index, key, sectionName }) => {
          let sectionTitle;
          if (sectionName !== prevSectionName) {
            if (sectionName !== "none") {
              sectionTitle = (
                <Typography variant="subtitle2" style={{ marginTop: 30 }}>
                  {sectionName}
                </Typography>
              );
            } else if (prevSectionName) {
              sectionTitle = <div style={{ marginTop: 30 }} />;
            }
          }
          prevSectionName = sectionName;
          return (
            <div key={sectionName + key}>
              {sectionTitle}
              <AttributeFilterRow
                labelAttribute={label}
                disabled={disabled}
                formName={formName}
                availableAttributes={availableAttributes}
                initialValues={initialValues[index]}
                attributeFilter={key}
                values={field}
                key={key}
                onRemove={() => {
                  fields.remove(index);
                }}
              />
            </div>
          );
        })}
      </FlipMove>
      <ButtonLoading
        disabled={disabled}
        variant="outlined"
        color="primary"
        onClick={addField}
        testid={"add" + inputName}>
        Add {label} filter
      </ButtonLoading>
      {submitFailed && error && <span>{error}</span>}
    </React.Fragment>
  );
};

export default AttributesFilter;
