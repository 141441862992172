import React, { Component } from "react";
import {
    getEntityLabel,
    getEntityRoleAttachmentDownload,
    getEntityRoleAttachmentUpload,
    getEntityType,
    MODEL_REVISION_TYPE,
    MODEL_TYPE,
    SHARED_FOLDER_TYPE
} from 'api/Entity';
import { connect } from "react-redux";
import {
  deleteAttachments,
  getAttachmentsLogs,
  moveAttachment,
  updateAttachment,
} from "actions/Attachments";
import { withStyles } from "@material-ui/core";
import {
  createFolderAttachment
} from "actions/Attachments/folders";
import Directory from "components/Attachments/Directory";
import { isAuthorized } from 'api/Authorities';
import { withRouter } from "react-router";
import qs from "querystring";
import { getCurrentURLParams } from 'api/GUI';
import { getDownloadLink } from 'api/Attachments';
import { ATTACHMENT_PLATFORM } from 'constants/Attachments';
import { addFileGroup } from 'actions/Uploads';
import {getEntityRoleAttachmentDelete} from "../../api/Entity";

class EntityDirectory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typesAttachment: this.getTypesAttachment(props.entity)
    };
  }
  componentDidMount() {
    const { location, currentSubfolder, onSubfolderChange } = this.props;
    const query = getCurrentURLParams(location.search);
    if (query && query["folder"] && query["folder"] !== currentSubfolder) {
      onSubfolderChange(query["folder"]);
    }
  }
  componentWillReceiveProps(nextProps) {
    const {
      entity,
      types,
      location,
      currentSubfolder,
      onSubfolderChange
    } = nextProps;
    if (this.props.types !== types || entity !== this.props.entity) {
      this.setState({
        typesAttachment: this.getTypesAttachment(entity)
      });
    }
    const query = getCurrentURLParams(location.search);
    const folderUrlParam = (query && query["folder"]) || "/";
    if (query && folderUrlParam !== currentSubfolder) {
      if (currentSubfolder !== this.props.currentSubfolder) {
        this.onSubfolderChangeURL(currentSubfolder);
      } else {
        onSubfolderChange(folderUrlParam);
      }
    }
  }

  onSubfolderChangeURL = subfolder => {
    const { history, location } = this.props;
    let query = getCurrentURLParams(location.search);
    query["folder"] = subfolder || "/";
    const searchString = qs.stringify(query);
    history.push({
      ...location,
      search: searchString
    });
  };

  getTypesAttachment = entity => {
    const { types } = this.props;
    let entityType = getEntityType(entity);
    entityType = entityType === MODEL_REVISION_TYPE ? MODEL_TYPE : entityType;
    if (types && types[entityType]) {
      return types[entityType].list;
    }
    return [];
  };

  handleSubmitFolder = form => {
    const { createFolderAttachment, entity, currentSubfolder } = this.props;
    const { name } = form;
    createFolderAttachment(entity, currentSubfolder, name);
  };

  handleSubmitFiles = form => {
    const { addFileGroup, entity, currentSubfolder} = this.props;
    addFileGroup(form.files, entity, form.type, currentSubfolder, ATTACHMENT_PLATFORM.DATAHUB);
  };

  handleRemoveSubfolder = subdirectoryToDelete => {
    this.handleDeleteAttachments([subdirectoryToDelete]);
  };

  handleRenameSubfolder = (subdirectoryToRename, form) => {
    const updatedAttachment = Object.assign({}, subdirectoryToRename);
    updatedAttachment.fileName = form.name;

    const { updateAttachment, entity, currentSubfolder } = this.props;
    updateAttachment(
      entity,
      subdirectoryToRename,
      updatedAttachment,
      currentSubfolder,
      true
    );
  };

  onMoveAttachment = (draggedFiles, to) => {
    const { moveAttachment } = this.props;
    for (let i = 0; i < draggedFiles.length; i++) {
      const draggedFile = draggedFiles[i];
      moveAttachment(draggedFile, to);
    }
  };

  handleUpdateAttachment = (attachmentToUpdate, form) => {
    const updatedAttachment = Object.assign({}, attachmentToUpdate);
    updatedAttachment.category = form.category;
    updatedAttachment.fileName = form.fileName;

    const { updateAttachment, entity, currentSubfolder } = this.props;
    updateAttachment(
      entity,
      attachmentToUpdate,
      updatedAttachment,
      currentSubfolder
    );
  };

  handleDeleteAttachment = attachment => {
    this.handleDeleteAttachments([attachment]);
  };

  handleDeleteAttachments = attachments => {
    const {
      deleteAttachments,
      currentSubfolder,
      entity
    } = this.props;

    if (attachments.length != 0) {
      deleteAttachments(entity, attachments, currentSubfolder);
    }
  };

  onRefreshLogs = (firstResult, maxResultCount) => {
    const { getAttachmentsLogs, entity, currentSubfolder } = this.props;
    getAttachmentsLogs(
      entity.id,
      getEntityType(entity),
      firstResult,
      maxResultCount,
      currentSubfolder
    );
  };

  render() {
    const {
      entity,
      currentSubfolder,
      droppedFiles,
      onSubfolderChange,
      me
    } = this.props;

    if (!entity) {
      return null;
    }

    const { typesAttachment } = this.state;
    const entityType = getEntityType(entity);
    const authorityRequiredUpload = getEntityRoleAttachmentUpload(entity);
    const authorityRequiredDelete = getEntityRoleAttachmentDelete(entity);
    const authorityRequiredDownload = getEntityRoleAttachmentDownload(entity);
    const disabled =
      entityType === MODEL_REVISION_TYPE
        ? !entity.model || entity.model.active === false
        : entity.active === false;
    const isAuthorizedToUpload = isAuthorized(
      me,
      authorityRequiredUpload ? [authorityRequiredUpload] : null
    );
    const isAuthorizedToDelete = isAuthorized(
      me,
      authorityRequiredDelete ? [authorityRequiredDelete] : null
    );
    const isAuthorizedToDownload = isAuthorized(
      me,
      authorityRequiredDownload ? [authorityRequiredDownload] : null
    );

    return (
      <Directory
        withSubfolder
        disabled={disabled}
        rootPathName={entityType === SHARED_FOLDER_TYPE? entity.name : getEntityLabel(entityType)}
        entityType={entityType}
        attachmentsLabel="attachments"
        attachmentLabel="attachment"
        onCreateFolder={isAuthorizedToUpload ? this.handleSubmitFolder : null}
        onUpload={isAuthorizedToUpload ? this.handleSubmitFiles : null}
        onRenameSubfolder={
          isAuthorizedToUpload ? this.handleRenameSubfolder : null
        }
        onSubfolderChange={isAuthorizedToUpload ? onSubfolderChange : null}
        onMoveAttachment={isAuthorizedToUpload ? this.onMoveAttachment : null}
        onRemoveSubfolder={
          isAuthorizedToDelete ? this.handleRemoveSubfolder : null
        }
        onUpdateAttachment={
          isAuthorizedToUpload ? this.handleUpdateAttachment : null
        }
        onDeleteAttachment={
          isAuthorizedToDelete ? this.handleDeleteAttachment : null
        }
        onDeleteAttachments={
          isAuthorizedToDelete ? this.handleDeleteAttachments : null
        }
        getDownloadLink={isAuthorizedToDownload ? getDownloadLink : null}
        currentSubfolder={currentSubfolder}
        typesAttachment={typesAttachment}
        droppedFiles={droppedFiles}
        authoritiesRequired={
          authorityRequiredUpload ? [authorityRequiredUpload] : null
        }
        loading={entity && entity.loading_attachments}
        attachments={entity && entity.attachments}
        onRefreshLogs={this.onRefreshLogs}
        loadingLogs={entity && entity.loading_attachments_logs}
        errorLogs={entity && entity.error_attachments_logs}
        logs={entity && entity.attachments_logs}
        attachmentPlatform={ATTACHMENT_PLATFORM.DATAHUB}
      />
    );
  }
}

const styles = theme => ({
  root: {
    padding: "24px 0"
  },
  uploadingContainer: {
    flex: "1 1 auto",
    padding: "0 16px",
    minWidth: 0
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    types: state.attachments.types,
    me: state.auth.me
  };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      {
        createFolderAttachment,
        moveAttachment,
        updateAttachment,
        deleteAttachments,
        getAttachmentsLogs,
        addFileGroup
      }
    )(EntityDirectory)
  )
);
