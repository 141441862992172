import React, { Component } from "react";
import Button from "../Buttons/Button";
import SortedTable from "../Table/SortedTable";
import { ExpandMore } from "@material-ui/icons";

import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

class TreeListSearchResult extends Component {
  render() {
    const {
      tree,
      classes,
      selected,
      onSelectionChange,
      handleClickResult,
      columnDataTree,
      handleClickModel,
      componentRow,
      propsRow
    } = this.props;
    if (!tree) {
      return null;
    }
    const models = Object.keys(tree);
    return (
      <div style={{ marginTop: "10px" }}>
        {models.map((key, idx) => {
          const result = tree[key];
          return (
            <ExpansionPanel key={idx}>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>
                  {result.model.label}
                  {/*<span onClick={() => { handleClickModel(result.model) }}>
                                         </span>*/}
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  {result.model.modelType.name}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <SortedTable
                  id={"modelsresults"}
                  selectable
                  selected={selected}
                  onSelectionChange={onSelectionChange}
                  columnDatas={columnDataTree}
                  datas={result.revisions || []}
                  componentRow={componentRow}
                  propsRow={propsRow}
                  style={{ maxWidth: "none", margin: 0 }}
                  withPagination
                  orderBy="id"
                  order="asc"
                />
              </ExpansionPanelDetails>
              <ExpansionPanelActions style={{ justifyContent: "flex-start" }}>
                <Button
                  onClick={() => {
                    handleClickModel(result.model);
                  }}
                  color="primary"
                >
                  Go to model
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>
          );
        })}
      </div>
    );
  }
}

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "50%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

export default withStyles(styles)(TreeListSearchResult);
