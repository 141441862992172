import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { getApiError } from 'api/Error';
import { generateAttachmentDatalabLinkRequestURL } from 'api/Attachments';
import { getAttachmentDataLabLink } from 'actions/Attachments';
import { PUSH_NOTIFICATION } from 'constants/ActionTypes';
import MenuItemLoading from 'components/Buttons/MenuItemLoading';

const DataLabLinkGenerator = ({ file, onClose }) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleCopyDatalabLink = async (event) => {
    event.persist();
    event.stopPropagation();
    event.preventDefault();
    const requestURL = generateAttachmentDatalabLinkRequestURL(file);
    if (requestURL) {
      setIsLoading(true);
      await fetchDataLabLink(requestURL);
      setIsLoading(false);
    }

    onClose(event);
  };

  const fetchDataLabLink = async (requestURL) => {
    try {
      const dataLabLink = await getAttachmentDataLabLink(requestURL);
      navigator.clipboard.writeText(dataLabLink).then(
        function () {
          dispatch({
            type: PUSH_NOTIFICATION,
            icon: 'check',
            variant: 'success',
            message: 'DataLab attachment link copied to clipboard.',
          });
        },

        function (err) {
          let message = getApiError(err);
          dispatch({
            type: PUSH_NOTIFICATION,
            icon: 'error',
            variant: 'error',
            message: 'Error: could not copy Datalab link: ' + message,
          });
        },
      );
    } catch (error) {
      let message = getApiError(err);
      dispatch({
        type: PUSH_NOTIFICATION,
        icon: 'error',
        variant: 'error',
        message: 'Error: could not copy Datalab link: ' + message,
      });
    }
  };

  return (
    <MenuItemLoading
      loading={isLoading}
      loadingTitle={'Copying DataLab link'}
      onClick={handleCopyDatalabLink}
    >
      Copy DataLab link
    </MenuItemLoading>
  );
};

export default DataLabLinkGenerator;
