import {
  GET_REST,
  EXECUTING_REST,
  END_EXECUTING_REST,
  ERROR_EXECUTING_REST,
  GET_SQL,
  EXECUTING_SQL,
  END_EXECUTING_SQL,
  ERROR_EXECUTING_SQL
} from "../../constants/ActionTypes";
import axios from "axios";

export function executeREST(method, endpoint, headers, data) {
  return function(dispatch) {
    dispatch({
      type: EXECUTING_REST
    });

    let dataToSend;
    if (headers["content-type"] === "multipart/form-data") {
      if (data) {
        dataToSend = new FormData();
        let parsedData;
        try {
          parsedData = JSON.parse(data);
        } catch (e) {
          console.log(e);
        }
        parsedData &&
          Object.keys(parsedData).forEach(key =>
            dataToSend.append(key, parsedData[key])
          );
      }
    } else {
      dataToSend = data;
    }

    axios(
      {
        method: method,
        url: "/api/" + endpoint,
        data: dataToSend,
        headers: headers
      },
      {
        withCredentials: true
      }
    )
      .then(response => {
        let data = response.data;
        let responseContentType = response.headers["content-type"];

        dispatch({
          type: GET_REST,
          data: data,
          responseContentType: responseContentType
        });
        dispatch({
          type: END_EXECUTING_REST
        });
      })
      .catch(err => {
        let data = err.response.data;
        dispatch({
          type: ERROR_EXECUTING_REST,
          data: data
        });
        dispatch({
          type: END_EXECUTING_REST
        });
      });
  };
}
export function executeSQL(query, includeColumnNames) {
  return function(dispatch) {
    dispatch({
      type: EXECUTING_SQL
    });

    let url = "/api/database-query";
    if (includeColumnNames) {
      url += "?includeColumnNames=true";
    }

    axios
      .post(url, query, {
        withCredentials: true,
        headers: {
          "Content-Type": "text/plain",
          Accept: "application/json",
          "Accept-Charset": "utf-8"
        }
      })
      .then(response => {
        let result = response.data;
        let columns = [];
        if (includeColumnNames && result && result.length > 0) {
          columns = result[0];
          result = result.slice(1);
        }

        dispatch({
          type: GET_SQL,
          columns: columns,
          result: result
        });
        dispatch({
          type: END_EXECUTING_SQL
        });
      })
      .catch(err => {
        let message = err.response.data.errorMessage;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_EXECUTING_SQL,
          error: message
        });
        dispatch({
          type: END_EXECUTING_SQL
        });
      });
  };
}
