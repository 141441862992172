import React, { useCallback } from 'react';

import { Checkbox, CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { FolderOutlined } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { date2Str } from 'api/File';
import FileIcon from 'components/Attachments/File/Icon';
import FolderSize from 'components/Attachments/FolderSize';
import FileValidation from 'components/Attachments/File/FileValidation';

const useStyles = makeStyles(() => ({
  fileIcon: {
    verticalAlign: 'middle',
    marginLeft: 10,
  },
  td: {
    whiteSpace: 'inherit',
    maxWidth: 350,
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
  },
  emptyTd: {
    padding: 0,
  },
}));

const RowFile = ({
  withoutTypes,
  file,
  viewStatus,
  fileMenuEl,
  actions,
}) => {

  const classes = useStyles();

  const {
    fileName,
    fileSizeStr,
    mimeType,
    label,
    uploadDate,
    uploaderLabel,
    updateOnLabel,
    categoryLabel,
    fileCanBeValidated,
    validationMessage,
    validationStatus,
    moving,
    isFolder,
    version,
  } = file;

  const {
    onSelect,
    onDragStart,
    onDragEnd,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
    onOpenDirectory,
    onDownload,
  } = actions;

  const {
    isDragging,
    isSelected,
    isHighlighted,
    droppable,
    draggable,
    dropping
  } = viewStatus;

  const handleRowDoubleClick = useCallback((event) => {
    isFolder ? onOpenDirectory(event) : onDownload()
  }, [isFolder, onOpenDirectory, onDownload]);

  return (
    <TableRow
      hover
      style={{
        opacity: isDragging && !droppable ? 0.3 : 1,
        cursor: 'pointer',
        border: isHighlighted ? 'solid 2px #ffc107' : null,
      }}
      className={isHighlighted ? 'hightligted-animation' : null}
      onClick={onSelect}
      onDoubleClick={handleRowDoubleClick}
      dropping={dropping && droppable ? 'true' : undefined}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      selected={isSelected}
      target="_blank"
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      draggable={draggable}
    >
      {onSelect ? (
        <TableCell
          onClick={onSelect}
          padding="checkbox"
        >
          <Checkbox onClick={onSelect} checked={isSelected}/>
        </TableCell>
      ) : (
        <TableCell className={classes.emptyTd}/>
      )}
      <TableCell className={classes.td} style={{ padding: 5 }}>
        {moving ? (
          <CircularProgress className={classes.fileIcon} size={20}/>
        ) : isFolder ? (
          <FolderOutlined className={classes.fileIcon}/>
        ) : (
          <FileIcon
            mimetype={mimeType}
            extension={fileName.split('.').pop()}
            size={40}
          />
        )}
      </TableCell>
      <TableCell className={classes.td}>
        {label ? label : fileName}
      </TableCell>
      {version && <TableCell className={classes.td}>{version}</TableCell>}
      {!withoutTypes && (
        <TableCell className={classes.td}>
          {categoryLabel}
        </TableCell>
      )}
      <TableCell className={classes.td}>
        {isFolder ? '-' : date2Str(uploadDate, true)}
      </TableCell>
      <TableCell className={classes.td}>
        {uploaderLabel}
      </TableCell>
      <TableCell className={classes.td}>
        {updateOnLabel ? updateOnLabel : ' - '}
      </TableCell>
      <TableCell className={classes.emptyTd}>
        {isFolder ? <FolderSize folder={file}/> : fileSizeStr}
      </TableCell>
      <TableCell
        align="center"
        className={fileCanBeValidated ? classes.td : classes.emptyTd}
      >
        {fileCanBeValidated
        && <FileValidation
          validationMessage={validationMessage}
          validationStatus={validationStatus}/>
        }
      </TableCell>
      <TableCell className={classes.td} style={{ padding: 0 }}>
        {fileMenuEl}
      </TableCell>
    </TableRow>
  );
};

export default RowFile;
