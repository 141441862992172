import {
  GET_ENTITY,
  CREATE_ENTITY,
  END_CREATE_ENTITY,
  END_EDIT_ENTITY,
  SAVE_ENTITY
} from "../constants/ActionTypes";
import { MODEL_TYPE, MODEL_REVISION_TYPE, updateEntity } from "./Entity";

export default function(entity, action, entityType) {
  if (action.entityType !== entityType) {
    if (entityType === MODEL_TYPE) {
      if (action.entityType === MODEL_REVISION_TYPE) {
        switch (action.type) {
          case SAVE_ENTITY:
            if (
              !entity ||
              !action.entity ||
              !action.entity.model ||
              action.entity.model.id !== entity.id
            ) {
              return entity;
            }
            let foundRevision = false;
            for (
              let i = 0;
              entity.revisions && i < entity.revisions.length;
              i++
            ) {
              if (entity.revisions[i].id === action.entity.id) {
                entity.revisions[i] = action.entity;
                foundRevision = true;
                break;
              }
            }
            if (!foundRevision) {
              if (!entity.revisions) {
                entity.revisions = [];
              }
              entity.revisions.unshift(action.entity);
            }
            return Object.assign({}, entity);
        }
        return entity;
      }
    }

    return entity;
  }
  switch (action.type) {
    case GET_ENTITY:
      return updateEntity(entity, action.entity);

    case CREATE_ENTITY:
      return action.entity;
    case END_CREATE_ENTITY:
      return { ...entity };
    case END_EDIT_ENTITY:
      return { ...entity };
    case SAVE_ENTITY:
      return updateEntity(entity, action.entity);
  }
  return entity;
}
