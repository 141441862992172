import React, { Component } from "react";
import ButtonLoading from "../Buttons/ButtonLoading";
import { DialogContent, DialogActions, Dialog } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";

class DeleteButton extends Component {
  state = {
    open: false
  };

  openDialog = () => {
    this.setState({
      open: true
    });
  };
  closeDialog = () => {
    this.setState({
      open: false
    });
  };
  confirmRemove = () => {
    const { onDeleteAttachments, selectedEntities } = this.props;
    onDeleteAttachments(selectedEntities);
    this.closeDialog();
  };

  render() {
    const {
      onDeleteAttachments,
      selectedEntities,
      attachmentsLabel,
      attachmentLabel
    } = this.props;
    const { open } = this.state;
    if (!onDeleteAttachments) {
      return null;
    }
    let label = attachmentsLabel;
    if (selectedEntities.length === 1) {
      if (selectedEntities[0].isFolder) {
        label = "folder";
      } else {
        label = attachmentLabel;
      }
    } else {
      let areFolders = true;
      for (let i = 0; i < selectedEntities.length; i++) {
        if (!selectedEntities[i].isFolder) {
          areFolders = false;
          break;
        }
      }
      if (areFolders) {
        label = "folders";
      }
    }

    return (
      <React.Fragment>
        <ButtonLoading
          variant="outlined"
          onClick={this.openDialog}
          color="primary"
        >
          Delete
        </ButtonLoading>
        <Dialog onClose={this.closeDialog} open={open}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={this.closeDialog}
          >
            {selectedEntities.length} {label} will be deleted, do you want to
            continue?
          </CloseableDialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <React.Fragment>
              <ButtonLoading
                autoFocus
                onClick={this.closeDialog}
                color="primary"
              >
                Cancel
              </ButtonLoading>
              <ButtonLoading onClick={this.confirmRemove} color="primary">
                Continue
              </ButtonLoading>
            </React.Fragment>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default DeleteButton;
