import React, { Component } from "react";
import {
  getEntityLabel,
  MODEL_REVISION_TYPE,
  VALIDATION_COMMITTEE_TYPE
} from "../../api/Entity";
import { Avatar, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const ENTITY_TAG_SIZE = "36px";

class EntityTag extends Component {
  render() {
    const { entityType, classes } = this.props;
    if (!entityType) {
      return null;
    }
    const entityLabel = getEntityLabel(entityType);
    let shortcut = entityLabel ? entityLabel.substring(0, 1) : "";
    if (entityType === MODEL_REVISION_TYPE) {
      shortcut = "MR";
    } else if (entityType === VALIDATION_COMMITTEE_TYPE) {
      shortcut = "VC";
    }

    return (
      <Tooltip title={entityLabel}>
        <Avatar className={classes.avatar}>{shortcut}</Avatar>
      </Tooltip>
    );
  }
}

const styles = theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight: "5px",
    width: ENTITY_TAG_SIZE,
    height: ENTITY_TAG_SIZE,
    fontSize: 12
  }
});

export default withStyles(styles)(EntityTag);
