import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import {loadState, saveState} from './localStorage';
import reducers from '../reducers';
import throttle from "lodash/throttle";

export default function configureStore(initialState) {
    const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
    const persistedDatas = loadState();
    const store = createStoreWithMiddleware(reducers, persistedDatas, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    store.subscribe(throttle(() => {
        const state = store.getState();
        saveState({
            filters: {
                config: state.filters.config,
                last_filters_runned: state.filters.last_filters_runned
            },
            notifications: state.notifications,
            settings: {
                result_format: state.settings.result_format,
                snackbar: state.settings.snackbar,
                attachment_view: state.settings.attachment_view
            },
            auth: {
                me: state.auth.me
            },
            attributes: {
                attributesToShow: state.attributes.attributesToShow
            }
        })
    }, 1000));

    return store;
}
