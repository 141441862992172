import React, { Component } from "react";
import FormControl from "./FormControl";

import { Input, InputLabel, TextField, withStyles } from "@material-ui/core";
import classnames from "classnames";

class InputField extends Component {
  render() {
    const {
      classes,
      disabled,
      multiline,
      input,
      label,
      autoFocus,
      onKeyDown,
      type,
      variant,
      margin,
      placeholder,
      startAdornment,
      endAdornment,
      submitOnEnter,
      style,
      className,
      InputProps,
      rowsMax,
      required
    } = this.props;
    const renderInput = variant ? (
      <TextField
        style={style}
        className={classnames("input", className)}
        color="primary"
        type={type}
        variant={variant}
        margin={margin}
        label={variant === "outlined" ? label : null}
        autoFocus={autoFocus}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onDragStart={input.onDragStart}
        onDrop={input.onDrop}
        onFocus={input.onFocus}
        disabled={disabled}
        id={input.name}
        value={input.value}
        placeholder={placeholder}
        onKeyPress={e => {
          if (!submitOnEnter && !multiline && e.key === "Enter") {
            e.preventDefault();
          }
        }}
        onKeyDown={onKeyDown}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        InputProps={InputProps}
        multiline={multiline}
      />
    ) : (
      <Input
        style={style}
        className={classnames("input", className)}
        color="primary"
        type={type}
        autoFocus={autoFocus}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onDragStart={input.onDragStart}
        onDrop={input.onDrop}
        onFocus={input.onFocus}
        disabled={disabled}
        id={input.name}
        value={input.value}
        placeholder={placeholder}
        onKeyPress={e => {
          if (!submitOnEnter && !multiline && e.key === "Enter") {
            e.preventDefault();
          }
        }}
        onKeyDown={onKeyDown}
        multiline={multiline}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        rowsMax={rowsMax}
      />
    );
    return (
      <FormControl {...this.props}>
        {label && variant !== "outlined" ? (
          <InputLabel
            formcontrolclasses={{
              focused: "label-focused"
            }}
            htmlFor={input.name}
          >
            {label} {required && ( <span className={classes.required}>*</span> )}
          </InputLabel>
        ) : (
          ""
        )}
        {renderInput}
      </FormControl>
    );
  }
}

const styles = theme => ({
  required: {
    color: "red",
    marginLeft: 2,
    alignSelf: "flex-start"
  }
});

export default withStyles(styles)(InputField);
