import React, { useState } from "react";
import {
  MODEL_TYPE,
  VALIDATION_TYPE,
  VALIDATION_COMMITTEE_TYPE,
  getEntityCreatePath,
} from "api/Entity";
import SearchFilters from "components/Search";
import ListView from "components/Entity/ListView";
import CreateEntityDialogForm from "components/Entity/CreateEntityDialogForm";
import { ID_REGEX, TAB_ATTRIBUTES } from "constants/Routes";
import { withRouter } from "react-router";
import qs from "querystring";

const Entities = ({
  entityType,
  history,
  handleClickRow,
  enableCreate,
  enableExportHistory,
  onSelectionChange,
  selectable,
  withoutToolbar,
  filters,
  selectableOnRowClick,
  url,
  excludeIds,
}) => {
  const [openDialogCreateEntity, setOpenDialogCreateEntity] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleSubmitCreateEntityDialogForm = entity => {
    const new_location = getEntityCreatePath(entityType)
      .replace(":entityType" + ID_REGEX, entity.entityTypeId)
      .replace(
        ":securityLevel" + ID_REGEX,
        entity.securityLevel || "undefined"
      );
    history.push({
      pathname: new_location,
      search: "?" + qs.stringify({ tab: TAB_ATTRIBUTES }),
    });
    setOpenDialogCreateEntity(false);
  };

  const handleRequestSort = (order, orderBy) => {
    setOrder(order);
    setOrderBy(orderBy);
  };

  const handleCreateEntityDialogForm = () => {
    setOpenDialogCreateEntity(true);
  };

  let createLabel, title;
  switch (entityType) {
    case MODEL_TYPE:
      createLabel = "Create a model";
      title = "Model revisions";
      break;
    case VALIDATION_TYPE:
      createLabel = "Create a validation";
      title = "Validations";
      break;
    case VALIDATION_COMMITTEE_TYPE:
      createLabel = "Create a validation committee";
      title = "Validation committees";
      break;
  }
  return (
    <React.Fragment>
      <SearchFilters
        url={url}
        createLabel={enableCreate && createLabel}
        entityType={entityType}
        filters={filters}
        onCreate={enableCreate && handleCreateEntityDialogForm}
        enableExportHistory={enableExportHistory}
        order={order}
        orderBy={orderBy}
        excludeIds={excludeIds}
      >
        <ListView
          title={title}
          excludeIds={excludeIds}
          withoutToolbar={withoutToolbar}
          handleClickRow={handleClickRow}
          selectableOnRowClick={selectableOnRowClick}
          selectable={selectable}
          orderBy={orderBy}
          order={order}
          onSelectionChange={onSelectionChange}
          onRequestSort={handleRequestSort}
          entityType={entityType}
        />
      </SearchFilters>
      {enableCreate && (
        <CreateEntityDialogForm
          entityType={entityType}
          open={openDialogCreateEntity}
          handleClose={() => setOpenDialogCreateEntity(false)}
          onSubmit={handleSubmitCreateEntityDialogForm}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(Entities);
