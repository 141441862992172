import React, { Component } from 'react';
import FileIcon, { defaultStyles } from 'react-file-icon';
import Mime2Ext from '../../../api/Mime2Ext';
import { withStyles } from '@material-ui/core/styles';

class Icon extends Component {
    render() {
        const { mimetype, style, size, extension } = this.props;
        let extensionFinal = extension || Mime2Ext.data[mimetype];
        return (
            <FileIcon style={style} size={size} extension={extensionFinal} {...defaultStyles[extensionFinal]} />
        );
    }
}

const styles = theme => ({
});

export default withStyles(styles)(Icon);
