import React from "react";
import {useSelector} from "react-redux";
import {Field, reduxForm} from "redux-form";
import { RequiredValidator } from "components/Form/Fields/FieldValidators";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import { scrollToFirstError } from "api/Error";
import RestrictedComponent from "components/RestrictedComponent";
import {ROLE_WORKFLOW_MANAGE} from "constants/Authorities";
import IconButton from "components/Buttons/IconButton";
import {Close, Done} from "@material-ui/icons";
import IconButtonLoading from "components/Buttons/IconButtonLoading";

const BusinessOwnerForm = reduxForm({
  form: "businessownerform",
  enableReinitialize: true,
  onSubmitFail: scrollToFirstError
})(({ handleSubmit, handleClose }) => {
  const task = useSelector(state => state.tasks.current);

  return (
    <form onSubmit={handleSubmit}>
      <div style={{display:"flex", alignItems: "flex-end"}}>
        <div style={{flex:"1 1 auto"}}>
          <Field
            label={"Business owners:"}
            name="administrators"
            component={AutoSuggestSelectField}
            validate={[RequiredValidator]}
            multiple
            apiUrl="/api/users/lookup"
          />
        </div>
        <div style={{flex:"0 0 auto", padding:"5px 10px"}}>
          <IconButtonLoading
            loading={task &&  task.setting_business_owners}
            style={{ padding: "4px", marginRight: "8px" }}
            size="small"
            type="submit"
          >
            <Done style={{ margin: 0 }} size="small" />
          </IconButtonLoading>
          <IconButton
            style={{ padding: "4px" }}
            size="small"
            onClick={handleClose}
          >
            <Close style={{ margin: 0 }} size="small" />
          </IconButton>
        </div>
      </div>
      <div className="error">{task && task.error_business_owners}</div>
    </form>
  );
});

export default (props) => {
  return (
    <RestrictedComponent
      componentButton={BusinessOwnerForm}
      authoritiesRequired={[ROLE_WORKFLOW_MANAGE]}
      {...props}
    />
  );
};

