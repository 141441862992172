import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Button from "../Buttons/Button";
import { Field, reduxForm } from "redux-form";
import { RequiredValidator } from "../Form/Fields/FieldValidators";
import SelectField from "../Form/Fields/SelectField";
import SecurityLevels from "../SecurityLevels/input";
import { getEntityLabel, MODEL_TYPE } from "api/Entity";
import {
  MenuItem,
  DialogContent,
  Dialog,
  Typography,
} from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { Warning } from "@material-ui/icons";
import { scrollToFirstError } from "api/Error";
import DialogActions from "@material-ui/core/DialogActions";
import {getSortingByLabel} from "api/Sort";

class CreateEntityDialogForm extends Component {
  render() {
    const {
      handleSubmit,
      handleClose,
      open,
      types,
      error_creating,
      children,
      entityType,
      withoutSecurityLevel,
      license
    } = this.props;

    if (!types) {
      return null;
    }

    let warning;
    if (entityType === MODEL_TYPE && license) {
      const capacity =
        license.currentModelCount / (license.maxModelCount + 0.0);
      if (capacity > 0.9) {
        warning = (
          <Typography className="warning">
            <Warning
              color="inherit"
              style={{ marginRight: 5, verticalAlign: "middle" }}
            />
            Your capacity for model creation is almost reached (
            {Math.floor(capacity * 100)}%)
          </Typography>
        );
      }
    }

    return (
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleSubmit}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleClose}
          >
            Create {getEntityLabel(entityType)}
          </CloseableDialogTitle>
          <DialogContent>
            {warning}
            {children}
            <div>
              <Field
                label={getEntityLabel(entityType) + " type"}
                name="entityTypeId"
                component={SelectField}
                validate={[RequiredValidator]}
                ref="entityTypeId"
              >
                {types.sort(getSortingByLabel).map(function(type, idx) {
                  return (
                    <MenuItem key={idx} value={type.id}>
                      {type.label}
                    </MenuItem>
                  );
                })}
              </Field>
            </div>
            {!withoutSecurityLevel && <SecurityLevels />}
          </DialogContent>
          <DialogActions>
            <Button
              testid="submitEntity"
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {"Create new " + getEntityLabel(entityType)}
            </Button>
            <div className="error">{error_creating}</div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { entityType } = ownProps;
  return {
    types: state.entity[entityType] ? state.entity[entityType].types : null,
    error_creating: state.entity[entityType]
      ? state.entity[entityType].error
      : null,
    license: state.license.license
  };
};

CreateEntityDialogForm = reduxForm({
  form: "createentitydialog",
  shouldWarn: () => {
    return true;
  },
  onSubmitFail: scrollToFirstError
})(CreateEntityDialogForm);

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(CreateEntityDialogForm)
);
