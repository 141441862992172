import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { getColorsScale } from "../../constants/Colors";

const sortFunc = function (a, b) {
  return a.value - b.value;
};

const PieChartWidget = ({widget}) => {
  if(!widget || !widget.results || !widget.results[0]){
    return null;
  }
  const [data, setData] = useState(null);

  useEffect(()=>{
    const { results } = widget;
    const resultsParsed = results[0];
    setData(resultsParsed.data.sort(sortFunc));
  },[widget]);

  return (
    <ReactEcharts
      className="react_for_echarts"
      option={{
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d} %)"
        },
        color: getColorsScale(data && data.length),
        legend: {
          orient: "vertical",
          right: "20",
          bottom: "20"
        },
        series: [
          {
            name: widget.name,
            type: "pie",
            radius: "55%",
            center: ["50%", "40%"],
            data: data,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            },
            animationType: "scale",
            animationEasing: "elasticOut"
          }
        ]
      }}
    />
  );
};

export default PieChartWidget;
