import {
  GET_SECURITY_LEVELS,
  LOADING_SECURITY_LEVELS,
  END_LOADING_SECURITY_LEVELS,
  SECURITY_LEVELS_ERROR
} from "../../constants/ActionTypes";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_SECURITY_LEVELS:
      return {
        ...state,
        list: action.securityLevels,
        error_security_levels: undefined
      };
    case LOADING_SECURITY_LEVELS:
      return {
        ...state,
        loading_security_levels: true,
        error_security_levels: undefined
      };
    case END_LOADING_SECURITY_LEVELS:
      return { ...state, loading_security_levels: false };
    case SECURITY_LEVELS_ERROR:
      return { ...state, error_security_levels: action.error };

    default:
      return state;
  }
}
