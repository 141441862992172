import axios from 'axios';

import { getApiError } from 'api/Error';
import { getSortingAttachments } from 'api/Sort';
import {
  ATTACHMENT_IMPORTED,
  ATTACHMENT_UPDATED,
  ATTACHMENT_UPDATED_ERROR,
  ATTACHMENT_UPDATING,
  ATTACHMENT_DELETED_ERROR,
  ATTACHMENT_BULK_DELETING,
  LOADING_ATTACHMENTS,
  END_LOADING_ATTACHMENTS,
  GET_ATTACHMENTS,
  ATTACHMENTS_ERROR,
  LOADING_ATTACHMENTS_SEARCH,
  END_LOADING_ATTACHMENTS_SEARCH,
  GET_ATTACHMENTS_SEARCH,
  ERROR_ATTACHMENTS_SEARCH,
  GET_ATTACHMENTS_TYPES,
  LOADING_ATTACHMENTS_TYPES,
  END_LOADING_ATTACHMENTS_TYPES,
  ATTACHMENTS_TYPES_ERROR,
  MOVING_ATTACHMENT,
  MOVE_ATTACHMENT,
  END_MOVING_ATTACHMENT,
  ERROR_MOVING_ATTACHMENT,
  GET_ATTACHMENTS_LOGS,
  LOADING_ATTACHMENTS_LOGS,
  END_LOADING_ATTACHMENTS_LOGS,
  ATTACHMENTS_LOGS_ERROR,
  PUSH_NOTIFICATION,
  ATTACHMENT_CONFLICT_ERROR,
  ATTACHMENT_CONFLICT_MANAGED,
  END_ATTACHMENT_UPDATING,
} from 'constants/ActionTypes';
import {
  getEntityGetAttachmentsParam,
  PERSONAL_FOLDER_TYPE,
  PUBLIC_FOLDER_TYPE,
} from 'api/Entity';
import {
  buildAttachment,
} from 'api/Attachments';
import {DRAG_ATTACHMENTS, END_DRAGING_ATTACHMENTS} from "../../constants/ActionTypes";

export async function getAttachmentDataLabLink(requestURL) {
  return (await axios
    .get(requestURL, {
      withCredentials: true,
    })).data
}

export function getAttachmentsTypes(entityType) {
  return function (dispatch) {
    dispatch({
      type: LOADING_ATTACHMENTS_TYPES,
      entityType: entityType,
    });
    let entityTypeParam = getEntityGetAttachmentsParam(entityType);

    let url = `/api/${entityTypeParam}/attachment-categories`;
    if (entityTypeParam === PERSONAL_FOLDER_TYPE) {
      url = '/api/personal/storage/attachment-categories';
    } else if (entityTypeParam === PUBLIC_FOLDER_TYPE) {
      url = '/api/public/storage/attachment-categories';
    }

    axios
      .get(url, {
        withCredentials: true,
      })
      .then(response => {
        let types = response.data;
        dispatch({
          type: GET_ATTACHMENTS_TYPES,
          entityType: entityType,
          types: types,
        });
        dispatch({
          type: END_LOADING_ATTACHMENTS_TYPES,
          entityType: entityType,
        });
      })
      .catch(err => {
        let message = getApiError(err);
        dispatch({
          type: ATTACHMENTS_TYPES_ERROR,
          error: message,
          entityType: entityType,
        });
        dispatch({
          type: END_LOADING_ATTACHMENTS_TYPES,
          entityType: entityType,
        });
      });
  };
}

export function checkConflictError(err, dispatch, id, onAccept) {
  if (
    err &&
    err.response &&
    err.response.data &&
    err.response.data.status === 'CONFLICT'
  ) {
    let message = getApiError(err);
    dispatch({
      type: ATTACHMENT_CONFLICT_ERROR,
      conflict: {
        id,
        message,
        onAccept,
      },
    });
    return true;
  }
  return false;
}

export function manageFileConflict(id) {
  return function (dispatch) {
    dispatch({
      type: ATTACHMENT_CONFLICT_MANAGED,
      id,
    });
  };
}

export function getAttachments(ownerId, entityType, subfolder) {
  const entityParam = getEntityGetAttachmentsParam(entityType);
  let url = '/api/' + entityParam + '/' + ownerId + '/attachments';
  if (entityType === PERSONAL_FOLDER_TYPE) {
    url = '/api/personal/storage/attachments';
  } else if (entityType === PUBLIC_FOLDER_TYPE) {
    url = '/api/public/storage/attachments';
  }
  if (subfolder === '/') {
    subfolder = '';
  }
  return axios.get(url + '?prefix=' + subfolder, {
      withCredentials: true,
    });
}

export function getAttachmentsRedux(ownerId, entityType, subfolder) {
  return function (dispatch) {
    const entityParam = getEntityGetAttachmentsParam(entityType);
    let url = '/api/' + entityParam + '/' + ownerId + '/attachments';
    if (entityType === PERSONAL_FOLDER_TYPE) {
      url = '/api/personal/storage/attachments';
    } else if (entityType === PUBLIC_FOLDER_TYPE) {
      url = '/api/public/storage/attachments';
    }
    if (subfolder === '/') {
      subfolder = '';
    }
    dispatch({
      type: LOADING_ATTACHMENTS,
      ownerId: ownerId,
    });
    axios
      .get(url + '?prefix=' + subfolder, {
        withCredentials: true,
      })
      .then(response => {
        let attachments = response.data;
        dispatch({
          type: GET_ATTACHMENTS,
          attachments: attachments && attachments.sort(getSortingAttachments),
          ownerId: ownerId,
        });
        dispatch({
          type: END_LOADING_ATTACHMENTS,
          ownerId: ownerId,
        });
      })
      .catch(err => {
        let message = getApiError(err);
        dispatch({
          type: ATTACHMENTS_ERROR,
          error: message,
          ownerId: ownerId,
        });
        dispatch({
          type: END_LOADING_ATTACHMENTS,
          ownerId: ownerId,
        });
      });
  };
}

export function updateAttachment(
  entity,
  oldFile,
  newFile,
  prefix,
  isFolder = false,
  rewriteDuplicate = false,
) {
  return async function (dispatch) {
    if (!oldFile) {
      return;
    }
    let ownerId = entity.id;
    const typeOfOwner = getEntityGetAttachmentsParam(oldFile.entity);
    const id = oldFile.id;
    const metadata = oldFile.metadata;
    const oldFileName = oldFile.fileName;

    const newFileName = newFile.fileName;
    const newCategory = newFile.category;

    let url = `/api/${typeOfOwner}/${ownerId}/attachments`;
    if (oldFile.entity === PERSONAL_FOLDER_TYPE) {
      ownerId = 'personal';
      url = '/api/personal/storage/attachments';
    } else if (oldFile.entity === PUBLIC_FOLDER_TYPE) {
      ownerId = 'public';
      url = '/api/public/storage/attachments';
    }

    dispatch({
      type: ATTACHMENT_UPDATING,
      ownerId,
      oldAttachment: oldFile,
    });
    try {
      const newAttachment = (await axios.patch(
        url,
        {
          isFolder: isFolder,
          sourcePrefix: prefix,
          sourceName: oldFileName,
          targetPrefix: prefix,
          targetName: newFileName,
          metadata: metadata,
          newCategoryId: newCategory,
          rewriteDuplicate,
        },
        {
          withCredentials: true,
        },
      )).data;
      dispatch({
        type: ATTACHMENT_UPDATED,
        oldAttachment: oldFile,
        newAttachment,
        ownerId,
        id,
      });
      dispatch({
        type: PUSH_NOTIFICATION,
        icon: 'edit',
        variant: 'success',
        message: oldFileName + ' updated',
      });
    } catch (err) {
      if (
        !checkConflictError(err, dispatch, id, () => {
          updateAttachment(entity, oldFile, newFile, prefix, isFolder, true)(
            dispatch,
          );
        })
      ) {
        let message = getApiError(err);
        dispatch({
          type: ATTACHMENT_UPDATED_ERROR,
          error: message,
          ownerId: ownerId,
          oldAttachment: oldFile,
        });
        if (message) {
          message = ': ' + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: 'error',
          variant: 'error',
          message:
            'Updating of attachment ' + oldFile.fileName + ' failed' + message,
        });
      } else {
        dispatch({
          type: END_ATTACHMENT_UPDATING,
          ownerId: ownerId,
          oldAttachment: oldFile,
        });
      }
    }
  };
}

export function deleteAttachments(entity, attachments, prefix) {
  return function (dispatch) {
    if (!attachments) {
      return;
    }

    const representativeEntity = attachments[0].entity;
    const ownerId = entity.id;
    const typeOfOwner = getEntityGetAttachmentsParam(representativeEntity);

    dispatch({
      type: ATTACHMENT_BULK_DELETING,
      ownerId: ownerId,
      attachments: attachments,
    });

    let url = `/api/${typeOfOwner}/${ownerId}/attachments/all`;
    if (representativeEntity === PERSONAL_FOLDER_TYPE) {
      url = '/api/personal/storage/attachments/all';
    } else if (representativeEntity === PUBLIC_FOLDER_TYPE) {
      url = '/api/public/storage/attachments/all';
    }

    axios
      .delete(url, {
        withCredentials: true,
        data: attachments.map(attachment => ({
          fileName: attachment.fileName,
          isFolder: attachment.isFolder,
          prefix,
        })),
      })
      .catch(err => {
        let message = getApiError(err);
        dispatch({
          type: ATTACHMENT_DELETED_ERROR,
          error: message,
          ownerId: ownerId,
        });
        if (message) {
          message = ': ' + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: 'error',
          variant: 'error',
          message:
            'Deleting of attachments failed' + message,
        });
      }).finally(response => {
      getAttachmentsRedux(ownerId, representativeEntity, prefix)(dispatch);
    });
  };
}

export function moveAttachment(
  file,
  to,
  newOwnerId = null,
  rewriteDuplicate = false
) {
  return async function (dispatch) {
    if (!file) {
      return;
    }
    const ownerId = file.ownerId;
    const fileEntity = file.entity;
    const prefix = file.prefix;
    const typeOfOwner = getEntityGetAttachmentsParam(fileEntity);
    const fileName = file.fileName;
    dispatch({
      type: MOVING_ATTACHMENT,
      ownerId: ownerId,
      attachment: file,
    });

    let url = `/api/${typeOfOwner}/${ownerId}/attachments/clone`;
    if (fileEntity === PERSONAL_FOLDER_TYPE) {
      url = '/api/personal/storage/attachments/clone';
    } else if (fileEntity === PUBLIC_FOLDER_TYPE) {
      url = '/api/public/storage/attachments/clone';
    }

    dispatch({
      type: MOVE_ATTACHMENT,
      attachment: file,
      ownerId: ownerId,
      newOwnerId: newOwnerId,
      to: to,
    });

    axios.post(
      url,
      {
        isFolder: file.isFolder,
        shouldDeleteSource: true,
        sourcePrefix: prefix,
        sourceName: fileName,
        targetPrefix: to,
        targetName: fileName,
        newOwnerId,
        rewriteDuplicate
      },
      {
        withCredentials: true,
      },
    ).then(response => {
      dispatch({
        type: END_MOVING_ATTACHMENT,
        ownerId: ownerId,
        newOwnerId: newOwnerId,
        attachment: file,
      });
      let rootFolder = response.data.rootFolder !== null ? (" [" + response.data.rootFolder + "] ") : " ";
      dispatch({
        type: PUSH_NOTIFICATION,
        icon: 'check',
        variant: 'success',
        message: fileName + ' moved to' + rootFolder + to,
      });
    }).catch(error => {
      if (
        !checkConflictError(error, dispatch, file.id, () =>
          moveAttachment(file, to, newOwnerId, true)(dispatch),
        )
      ) {
        let message = getApiError(error);
        dispatch({
          type: ERROR_MOVING_ATTACHMENT,
          error: message,
          ownerId: ownerId,
          attachment: file,
        });
        if (message) {
          message = ': ' + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: 'error',
          variant: 'error',
          message:
            'Deleting of attachment ' + file.fileName + ' failed' + message,
        });
      }
      dispatch({
        type: END_MOVING_ATTACHMENT,
        ownerId: ownerId,
        attachment: file,
      });
    });
  };
}

export function getAttachmentsLogs(
  ownerId,
  entityType,
  firstResult,
  maxResultCount,
  subfolder,
) {
  return function (dispatch) {
    dispatch({
      type: LOADING_ATTACHMENTS_LOGS,
      ownerId: ownerId,
    });
    const entityParam = getEntityGetAttachmentsParam(entityType);
    let url = '/api/' + entityParam + '/' + ownerId + '/attachments/logs';
    if (entityType === PERSONAL_FOLDER_TYPE) {
      url = '/api/personal/storage/attachments/logs';
    } else if (entityType === PUBLIC_FOLDER_TYPE) {
      url = '/api/public/storage/attachments/logs';
    } else if (!entityType) {
      url = '/api/storage/logs';
    }

    if (subfolder && subfolder.startsWith('/')) {
      subfolder = subfolder.substr(1);
    }
    url = `${url}?firstResult=${firstResult}&maxResultCount=${maxResultCount}`;
    if (subfolder) {
      url += `&prefix=${subfolder}`;
    }
    axios
      .get(url, {
        withCredentials: true,
      })
      .then(response => {
        let logs = response.data;
        dispatch({
          type: GET_ATTACHMENTS_LOGS,
          attachments_logs: logs ? logs[0] : null,
          ownerId: ownerId,
        });
        dispatch({
          type: END_LOADING_ATTACHMENTS_LOGS,
          ownerId: ownerId,
        });
      })
      .catch(err => {
        let message = getApiError(err);
        dispatch({
          type: ATTACHMENTS_LOGS_ERROR,
          error: message,
          ownerId: ownerId,
        });
        dispatch({
          type: END_LOADING_ATTACHMENTS_LOGS,
          ownerId: ownerId,
        });
      });
  };
}

export function getAttachmentsSearch(
  page,
  size,
  text,
  subfolder,
  entityId,
  entityType,
  storageTypePlatform,
  category,
  author,
  uploadFrom,
  uploadTill,
) {
  return function (dispatch) {
    dispatch({
      type: LOADING_ATTACHMENTS_SEARCH,
    });
    axios
      .post(
        `/api/full-text-search/search?page=${page}&size=${size}&sort=score,asc&sort=date,desc`,
        {
          content: text,
          folder: subfolder,
          entityId: entityId,
          entityEnum: entityType,
          attachmentCategory: category,
          storageTypePlatform: storageTypePlatform,
          uploaderId: author,
          uploadedDateFrom: uploadFrom,
          uploadedDateTo: uploadTill,
        },
        {
          withCredentials: true,
        },
      )
      .then(response => {
        let { content, numberOfElements, last, pageable } = response.data;
        dispatch({
          type: GET_ATTACHMENTS_SEARCH,
          attachments: content,
          page: pageable.pageNumber,
          numberOfElements,
          last,
        });
        dispatch({
          type: END_LOADING_ATTACHMENTS_SEARCH,
        });
      })
      .catch(err => {
        let message = getApiError(err);
        dispatch({
          type: ERROR_ATTACHMENTS_SEARCH,
          error: message,
        });
        dispatch({
          type: END_LOADING_ATTACHMENTS_SEARCH,
        });
      });
  };
}

export function importAttachment(file, uploadDetail, user) {
  return function (dispatch) {
    const attachment = buildAttachment(file, uploadDetail, user);
    dispatch({
      type: ATTACHMENT_IMPORTED,
      attachment: attachment,
      ownerId: attachment.ownerId,
      id: attachment.objectName,
    });
  }
}

export function dragAttachments(draggedFiles) {
  return function (dispatch) {
    dispatch({
      type: DRAG_ATTACHMENTS,
      draggedAttachments: draggedFiles
    });
  }
}

export function dragAttachmentsEnd() {
  return function (dispatch) {
    dispatch({
      type: END_DRAGING_ATTACHMENTS
    });
  }
}
