import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  isAuthorized,
  isDynamicPropertyAuthorized,
  isFeaturesAuthorized
} from "api/Authorities";

const RestrictedComponent = ({
  onAuthorized,
  onUnauthorized,
  me,
  features,
  featuresRequired,
  atLeastOneFeature,
  atLeastOneAuthorization,
  authoritiesRequired,
  dynamicProperties,
  dynamicPropertiesRequired,
  atLeastOneDynamicProperty,
  componentButton,
  dispatch,
  ...rest
}) => {
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    const shouldDisplay = isAuthorized(me, authoritiesRequired, atLeastOneAuthorization) &&
      isFeaturesAuthorized(features, featuresRequired, atLeastOneFeature) &&
      isDynamicPropertyAuthorized(
        dynamicProperties,
        dynamicPropertiesRequired,
        atLeastOneDynamicProperty
      )
    setDisabled(!shouldDisplay);
    if(!shouldDisplay){
      onUnauthorized && onUnauthorized();
    } else {
      onAuthorized && onAuthorized();
    }
  }, [
    me,
    authoritiesRequired,
    atLeastOneAuthorization,
    features,
    featuresRequired,
    atLeastOneFeature,
    dynamicProperties,
    dynamicPropertiesRequired,
    atLeastOneDynamicProperty
  ]);

  const Component = componentButton;
  if (disabled) {
    return <span />;
  }
  if (!Component) {
    return rest.children;
  }
  return <Component {...rest} />;
};
function mapStateToProps(state, ownProps) {
  return {
    me: state.auth.me,
    features: state.features.list,
    dynamicProperties: state.configuration.dynamic_properties
  };
}
export default connect(mapStateToProps)(RestrictedComponent);
