import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { Field, reduxForm } from "redux-form";
import { createWorkflow } from "actions/Workflow/index";
import WorkflowFields from "./JBPMForm/fields";
import Input from "components/Form/Fields/InputField";
import { DialogContent, Dialog, DialogActions } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { initializeForm } from "actions/index";
import { getApiError, scrollToFirstError } from "api/Error";

const CreateWorkflowFullDialogForm = reduxForm({
  form: "createworkflowfulldialog",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmitFail: scrollToFirstError
})(
    ({
      handleSubmit,
      handleClose,
      open,
      owners,
      creating,
      errorCreating,
      workflowLabel,
      currentWorkflowType
    }) => {

      const workflowDialogTitle = owners ?
          (
              <div>
                Create {workflowLabel} for{" "}
                { owners.map(name => name ).join(", ") }
              </div>
          )
          :
          (
              <div>Create {workflowLabel}</div>
          );

      return (
        <Dialog
          onClose={handleClose}
          open={open}
          testid="createworkflowdialog"
          fullWidth
          maxWidth="sm"
        >
          <form onSubmit={handleSubmit}>
            <CloseableDialogTitle id="simple-dialog-title" handleClose={handleClose}>
              {workflowDialogTitle}
            </CloseableDialogTitle>
            <DialogContent>
              <div testid="createWorkflowForm">
                <Field label={workflowLabel + " name"} name="name" component={Input}/>
              </div>
              <div testid="createWorkflowVariablesForm">
                {
                  currentWorkflowType && <WorkflowFields form={currentWorkflowType.form}/>
                }
              </div>
            </DialogContent>
            <DialogActions>
              <ButtonLoading
                testid="submitworkflow"
                loading={creating}
                loadingTitle={"Creating the " + workflowLabel}
                className="color-primary"
                type="submit"
                variant="outlined"
                color="primary"
              >
                {"Create new " + workflowLabel}
              </ButtonLoading>
              <div className="error">{errorCreating}</div>
            </DialogActions>
          </form>
        </Dialog>
      );
    }
);

const CreateWorkflowFullDialog = ({
  owners,
  open,
  handleClose,
  showOwnerInput,
  workflowLabel,
  labelOwnerInput,
  classification,
  workflowTypes,
  workflowTypeId,
  initialValues,
  entity,
  onCreate
}) => {
  const dispatch = useDispatch();
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);
  const [currentWorkflowType, setCurrentWorkflowType] = useState(null);

  useEffect(() => {
    // The currentWorkflowType is set and matches the demanded workflowTypeId
    if (currentWorkflowType && currentWorkflowType.id === workflowTypeId) {
      return;
    }

    for (let i = 0; workflowTypes && i < workflowTypes.length; i++) {
      const workflow_type = workflowTypes[i];
      if (workflow_type.id === workflowTypeId) {
        setCurrentWorkflowType(workflow_type);
        break;
      }
    }
  },[workflowTypes]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (!initialValues) {
      initialValues = {};
    }

    if (owners && owners.length === 1) {
      initialValues.ownerId = owners[0].id;
    }

    if (currentWorkflowType && currentWorkflowType.form && currentWorkflowType.form.fields) {
      currentWorkflowType.form.fields.filter(field => field.defaultValue).forEach((field) => {
        initialValues[field.name] = field.defaultValue;
      });
    }

    initializeForm("createworkflowfulldialog", initialValues)(dispatch);

  },[open, currentWorkflowType]);

  const handleSubmit = async workflow => {
    setCreating(true);
    try {
      let { ownerId, name, ...variables } = workflow;
      let owner;
      if (!ownerId && entity) {
        owner = entity;
        ownerId = owner.id;
      } else {
        for (let i = 0; owners && i < owners.length; i++) {
          if (owners[i].id === ownerId) {
            owner = owners[i];
          }
        }
      }
      const _workflow = (await createWorkflow(
        {
          workflowTypeId,
          ownerId,
          name,
          variables
        },
        owner
      )).data;
      onCreate(_workflow);
      handleClose();
    } catch (e) {
      setError(getApiError(e));
    }
    setCreating(false);
  };

  return (
    <CreateWorkflowFullDialogForm
      creating={creating}
      errorCreating={error}
      workflowLabel={workflowLabel}
      currentWorkflowType={currentWorkflowType}
      classification={classification}
      owners={owners}
      open={open}
      showOwnerInput={showOwnerInput}
      labelOwnerInput={labelOwnerInput}
      initialValues={initialValues}
      handleClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
};
export default CreateWorkflowFullDialog;
