import React  from 'react';

import { Card, Checkbox, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { date2Str } from 'api/File';
import Grid from 'components/Grid';
import FileIcon from 'components/Attachments/File/Icon';
import FileValidation from 'components/Attachments/File/FileValidation';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    position: 'relative',
    padding: 20,
  },
  checkboxGrid: {
    width: 46,
    height: 46,
    '& svg': {
      fontSize: '30px !important',
    },
  },
  cover: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  validationContainerGrid: {
    position: 'absolute',
    top: 10,
    left: 0,
  },
}));

const CardFile = ({
  withoutTypes,
  file,
  viewStatus,
  fileMenuEl,
  actions,
}) => {

  const classes = useStyles();

  const {
    fileName,
    fileSizeStr,
    mimeType,
    label,
    uploadDate,
    uploaderLabel,
    updateOnLabel,
    categoryLabel,
    fileCanBeValidated,
    validationMessage,
    validationStatus,
  } = file;

  const {
    onDragStart,
    onDragEnd,
    onSelect,
    onDownload,
  } = actions;

  const {
    isDragging,
    isSelected,
    isSelecting,
    isHighlighted,
    droppable,
    draggable,
  } = viewStatus;

  return (
    <Grid
      style={{
        opacity: isDragging && !droppable ? 0.3 : 1,
      }}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      draggable={draggable}
      item
      onClick={onSelect}
      onDoubleClick={onDownload}
      xs={12}
      sm={12}
      md={12}
      lg={6}
      xl={3}
    >
      <Card
        style={{
          border: isHighlighted ? 'solid 2px #ffc107' : null,
          backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.04)' : 'white',
        }}
        className={[
          classes.card,
          isHighlighted ? 'hightligted-animation' : null,
        ]}
      >
        <Grid container spacing={2} wrap="nowrap">
          <Grid item className={classes.cover}>
            {isSelecting ? (
              <Checkbox
                checked={isSelected}
                className={classes.checkboxGrid}
              />
            ) : (
              <FileIcon
                mimetype={mimeType}
                extension={fileName.split('.').pop()}
                size={70}
              />
            )}
          </Grid>
          <Grid item xs={12} sm container zeroMinWidth>
            <Grid item xs>
              <Grid
                container
                wrap="nowrap"
                justify="space-between"
                alignItems="center"
                style={{ padding: 0 }}
              >
                <Grid item zeroMinWidth>
                  <Typography
                    variant="subtitle2"
                    noWrap
                    style={{ textOverflow: 'ellipsis' }}
                  >
                    {label}
                  </Typography>
                </Grid>
                <Grid item>{fileMenuEl}</Grid>
              </Grid>
              <Typography gutterBottom>
                {fileSizeStr +
                (!withoutTypes
                  ? ' - ' + categoryLabel
                  : '')
                }
              </Typography>
              <Typography color="textSecondary">
                {uploadDate
                  ? (`Uploaded ${date2Str(uploadDate)} ${uploaderLabel ? ' by ' + uploaderLabel : ''}`)
                  : (<br/>)
                }
              </Typography>
              <Typography color="textSecondary">
                {updateOnLabel ? updateOnLabel : <br/>}
              </Typography>
              <div className={classes.validationContainerGrid}>
                {fileCanBeValidated
                && <FileValidation
                  validationMessage={validationMessage}
                  validationStatus={validationStatus}/>
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default CardFile;
