import React, { useState } from "react";
import ButtonLoading from "../../Buttons/ButtonLoading";
import DialogLogs from "components/Attachments/Logs/DialogLogs";

const LogsButton = ({ onRefreshLogs, loading, error, logs, withContext }) => {
  const [dialogOpened, setOpenDialog] = useState(false);

  const openDialog = () => {
    setOpenDialog(true);
  };
  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <ButtonLoading
        style={{ marginRight: 10 }}
        variant="outlined"
        color="primary"
        onClick={openDialog}
        testid="openLogs"
      >
        History
      </ButtonLoading>
      <DialogLogs
        withContext={withContext}
        onRefreshLogs={onRefreshLogs}
        loading={loading}
        error={error}
        logs={logs}
        onClose={closeDialog}
        open={dialogOpened}
      />
    </React.Fragment>
  );
};
export default LogsButton;
