import {
  GET_WORKFLOW_TYPES,
  LOADING_WORKFLOW_TYPES,
  END_LOADING_WORKFLOW_TYPES,
  WORKFLOW_TYPES_ERROR,
  GET_WORKFLOW_CLASSIFICATIONS,
  LOADING_WORKFLOW_CLASSIFICATIONS,
  END_LOADING_WORKFLOW_CLASSIFICATIONS,
  WORKFLOW_CLASSIFICATIONS_ERROR,
  GET_WORKFLOW_PROCESSES,
  LOADING_WORKFLOW_PROCESSES,
  END_LOADING_WORKFLOW_PROCESSES,
  WORKFLOW_PROCESSES_ERROR,
  GET_WORKFLOW_TYPES_BY_ENTITY,
  LOADING_WORKFLOW_TYPES_BY_ENTITY,
  END_LOADING_WORKFLOW_TYPES_BY_ENTITY,
  WORKFLOW_TYPES_BY_ENTITY_ERROR,
  DELETE_WORKFLOW_TYPE,
  DELETING_WORKFLOW_TYPE,
  END_DELETING_WORKFLOW_TYPE,
  DELETE_WORKFLOW_TYPE_ERROR,
  CREATE_WORKFLOW_TYPE,
  CREATING_WORKFLOW_TYPE,
  END_CREATING_WORKFLOW_TYPE,
  CREATE_WORKFLOW_TYPE_ERROR,
  UPDATE_WORKFLOW_TYPE,
  UPDATING_WORKFLOW_TYPE,
  END_UPDATING_WORKFLOW_TYPE,
  UPDATE_WORKFLOW_TYPE_ERROR,
  SET_CURRENT_WORKFLOW_TYPE,
  PUSH_NOTIFICATION
} from "../../constants/ActionTypes";
import { manageForm } from "../../api/Workflow";
import axios from "axios";
import qs from "querystring";

export function getWorkflowProcesses() {
  return function(dispatch) {
    dispatch({
      type: LOADING_WORKFLOW_PROCESSES
    });

    axios
      .get("/api/workflows/all-process-definitions", {
        withCredentials: true
      })
      .then(response => {
        let processes = response.data;
        let processesMapped = {};
        for (let i = 0; i < processes.length; i++) {
          const process = processes[i];
          if (!processesMapped[process.containerId]) {
            processesMapped[process.containerId] = [];
          }
          processesMapped[process.containerId].push(process.processId);
        }
        dispatch({
          type: GET_WORKFLOW_PROCESSES,
          processes: processesMapped
        });
        dispatch({
          type: END_LOADING_WORKFLOW_PROCESSES
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: WORKFLOW_PROCESSES_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_WORKFLOW_PROCESSES
        });
      });
  };
}
export function getWorkflowTypes() {
  return function(dispatch) {
    dispatch({
      type: LOADING_WORKFLOW_TYPES
    });

    axios
      .get("/api/workflows/types", {
        withCredentials: true
      })
      .then(response => {
        let types = response.data;
        dispatch({
          type: GET_WORKFLOW_TYPES,
          types:
            types &&
            types.map(type => {
              return manageForm(type);
            })
        });
        dispatch({
          type: END_LOADING_WORKFLOW_TYPES
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: WORKFLOW_TYPES_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_WORKFLOW_TYPES
        });
      });
  };
}
export function getWorkflowClassifications() {
  return function(dispatch) {
    dispatch({
      type: LOADING_WORKFLOW_CLASSIFICATIONS
    });

    axios
      .get("/api/workflows/types/classifications", {
        withCredentials: true
      })
      .then(response => {
        let classifications = response.data;
        dispatch({
          type: GET_WORKFLOW_CLASSIFICATIONS,
          classifications: classifications
        });
        dispatch({
          type: END_LOADING_WORKFLOW_CLASSIFICATIONS
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: WORKFLOW_CLASSIFICATIONS_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_WORKFLOW_CLASSIFICATIONS
        });
      });
  };
}
export function getWorkflowTypesByEntity(entityId, entityType, classification) {
  const queryParams = qs.stringify({
    entityType,
    classification
  });
  const url =
    entityId && entityType
      ? "/api/workflows/types/for-owner/" + entityId
      : "/api/workflows/types";
  return axios.get(url + "?" + queryParams, {
    withCredentials: true
  });
}
export function getWorkflowTypesByEntityRedux(
  entityId,
  entityType,
  classification
) {
  return function(dispatch) {
    dispatch({
      type: LOADING_WORKFLOW_TYPES_BY_ENTITY
    });
    getWorkflowTypesByEntity(entityId, entityType, classification)
      .then(response => {
        let types = response.data;
        dispatch({
          type: GET_WORKFLOW_TYPES_BY_ENTITY,
          types:
            types &&
            types.map(type => {
              return manageForm(type);
            })
        });
        dispatch({
          type: END_LOADING_WORKFLOW_TYPES_BY_ENTITY
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: WORKFLOW_TYPES_BY_ENTITY_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_WORKFLOW_TYPES_BY_ENTITY
        });
      });
  };
}
export function deleteWorkflowType(typesId) {
  return function(dispatch) {
    dispatch({
      type: DELETING_WORKFLOW_TYPE,
      typesId: typesId
    });

    axios
      .delete("/api/workflows/types/" + typesId, {
        withCredentials: true
      })
      .then(response => {
        dispatch({
          type: DELETE_WORKFLOW_TYPE,
          typesId: typesId
        });
        dispatch({
          type: END_DELETING_WORKFLOW_TYPE,
          typesId: typesId
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Delete workflow type failed" + message
        });
        dispatch({
          type: DELETE_WORKFLOW_TYPE_ERROR,
          typesId: typesId,
          error: message
        });
        dispatch({
          type: END_DELETING_WORKFLOW_TYPE,
          typesId: typesId
        });
      });
  };
}
export function createWorkflowType(form) {
  return function(dispatch) {
    dispatch({
      type: CREATING_WORKFLOW_TYPE
    });

    axios
      .post("/api/workflows/types", form, {
        withCredentials: true
      })
      .then(response => {
        dispatch({
          type: CREATE_WORKFLOW_TYPE,
          workflowType: manageForm(response.data)
        });
        dispatch({
          type: END_CREATING_WORKFLOW_TYPE
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Create workflow type failed" + message
        });
        dispatch({
          type: CREATE_WORKFLOW_TYPE_ERROR,
          error: message
        });
        dispatch({
          type: END_CREATING_WORKFLOW_TYPE
        });
      });
  };
}
export function setCurrentWorkflowType(workflowType) {
  return function(dispatch) {
    dispatch({
      type: SET_CURRENT_WORKFLOW_TYPE,
      workflowType
    });
  };
}
export function updateWorkflowType(workflowType) {
  return function(dispatch) {
    const typesId = workflowType.id;
    dispatch({
      type: UPDATING_WORKFLOW_TYPE,
      typesId
    });

    axios
      .put(
        "/api/workflows/types/" + typesId,
        {
          name: workflowType.name,
          description: workflowType.description,
          containerId: workflowType.containerId,
          processId: workflowType.processId,
          classification: workflowType.classification
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        dispatch({
          type: UPDATE_WORKFLOW_TYPE,
          workflowType: manageForm(response.data),
          typesId
        });
        dispatch({
          type: END_UPDATING_WORKFLOW_TYPE,
          typesId
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Create workflow type failed" + message
        });
        dispatch({
          type: UPDATE_WORKFLOW_TYPE_ERROR,
          error: message,
          typesId
        });
        dispatch({
          type: END_UPDATING_WORKFLOW_TYPE,
          typesId
        });
      });
  };
}
