import React, { Component } from "react";
import {
  getEntityTypeId,
  MODEL_REVISION_TYPE,
  MODEL_USAGE_TYPE
} from "../../api/Entity";
import { matchPath, withRouter } from "react-router";
import {
  ID_REGEX,
  MODEL_REVISION_CREATE_OVERVIEW_PATH,
  MODEL_USAGE_CREATE_OVERVIEW_PATH,
  MODEL_USAGE_OVERVIEW_PATH,
  TAB_ATTRIBUTES
} from "../../constants/Routes";
import { Link } from "react-router-dom";
import classnames from "classnames";
import ListItem from "components/Buttons/ListItem";
import {
  ROLE_MODEL_CREATE,
  ROLE_MODEL_VIEW,
  ROLE_MODELREVISION_CREATE
} from "../../constants/Authorities";
import CreateEntityDialogForm from "components/Entity/CreateEntityDialogForm";
import Grid from "components/Grid";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import { Add } from "@material-ui/icons";

import {
  CircularProgress,
  Typography,
  ListItemSecondaryAction,
  List,
  Divider
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { isAuthorized } from "../../api/Authorities";
import { connect } from "react-redux";
import qs from "querystring";

class UsagesList extends Component {
  state = {
    creating: false
  };

  onCreateUsage = () => {
    this.setState({
      creating: true
    });
  };
  onCloseCreateUsage = () => {
    this.setState({
      creating: false
    });
  };
  createNewUsage = form => {
    const { history, model, revision } = this.props;
    const new_location = MODEL_USAGE_CREATE_OVERVIEW_PATH.replace(
      ":revisionId" + ID_REGEX,
      revision.id
    )
      .replace(":usageType" + ID_REGEX, form.entityTypeId)
      .replace(":entityId" + ID_REGEX, model.id)
      .replace(":entityType" + ID_REGEX, getEntityTypeId(model));
    history.push({
      pathname: new_location,
      search: "?" + qs.stringify({ tab: TAB_ATTRIBUTES })
    });
    this.onCloseCreateUsage();
  };

  renderList = usageSelected => {
    const { loading, classes, model, revision } = this.props;
    if (revision && revision.modelUsages && revision.modelUsages.length > 0) {
      return revision.modelUsages.map((usage, idx) => {
        const path =
          MODEL_USAGE_OVERVIEW_PATH.replace(":usageId" + ID_REGEX, usage.id)
            .replace(":usageType" + ID_REGEX, usage.modelUsageType.id)
            .replace(":revisionId" + ID_REGEX, revision.id)
            .replace(":entityId" + ID_REGEX, model.id)
            .replace(":entityType" + ID_REGEX, getEntityTypeId(model)) +
          "?tab=attributes";
        const isSelected = usage.id && usageSelected === usage.id.toString();
        let className;
        if (isSelected) {
          className = classes.usageItemSelected;
        }
        return (
          <ListItem
            button
            key={idx}
            component={Link}
            to={path}
            className={classnames(className, classes.item)}
          >
            <Typography
              color={isSelected ? "inherit" : "primary"}
              variant="subtitle2"
            >
              {usage.label}
            </Typography>
            <Typography
              color={isSelected ? "inherit" : "primary"}
              variant="caption"
            >
              {usage.subLabel}
            </Typography>
            {isSelected && loading && (
              <ListItemSecondaryAction>
                <CircularProgress size={15} />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      });
    }
    return (
      <Typography variant="subtitle2" align="left" style={{ marginLeft: 16 }}>
        No usages found
      </Typography>
    );
  };

  render() {
    const {
      entityType,
      loading,
      error,
      location,
      classes,
      model,
      revision,
      me,
      modelUsageTypes
    } = this.props;
    const { creating } = this.state;

    const hasModelUsageTypes = modelUsageTypes && modelUsageTypes.length > 0;
    const hasUsages =
      revision && revision.modelUsages && revision.modelUsages.length > 0;
    const isAbleToCreate =
      model &&
      model.active &&
      hasModelUsageTypes &&
      isAuthorized(me, [ROLE_MODELREVISION_CREATE]);

    if (
      (entityType !== MODEL_USAGE_TYPE && entityType !== MODEL_REVISION_TYPE) ||
      (!isAbleToCreate && (!isAuthorized(me, [ROLE_MODEL_VIEW]) || !hasUsages))
    ) {
      //and if the user can't see any usage
      return null;
    }
    let list;
    if (!revision && loading) {
      list = (
        <div>
          <div style={{ textAlign: "center", lineHeight: "30px" }}>
            <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
              Loading the usages
            </span>
          </div>
        </div>
      );
    } else if (revision && model) {
      let creatingModelUsage, usageSelected;
      const currentPath = location.pathname;
      const matchPathUsage = matchPath(currentPath, {
        path: MODEL_USAGE_OVERVIEW_PATH
      });
      const matchPathCreate = matchPath(currentPath, {
        path: MODEL_USAGE_CREATE_OVERVIEW_PATH
      });
      const matchPathCreateModelRevision = matchPath(currentPath, {
        path: MODEL_REVISION_CREATE_OVERVIEW_PATH
      });
      if (matchPathUsage) {
        usageSelected = matchPathUsage.params.usageId;
      }

      if (matchPathCreateModelRevision) {
        return null;
      } else if (model.modelType) {
        if (matchPathCreate) {
          const modelCreateUsagePath = MODEL_USAGE_CREATE_OVERVIEW_PATH.replace(
            ":usageType" + ID_REGEX,
            matchPathCreate.params.usageType
          )
            .replace(":revisionId" + ID_REGEX, revision.id)
            .replace(":entityId" + ID_REGEX, model.id)
            .replace(":entityType" + ID_REGEX, getEntityTypeId(model));
          creatingModelUsage = (
            <ListItem
              button
              component={Link}
              to={modelCreateUsagePath}
              className={classnames(classes.usageItemSelected, classes.item)}
            >
              <Typography
                color={"inherit"}
                style={{ fontStyle: "italic" }}
                variant="subtitle2"
              >
                Usage in creation
              </Typography>
            </ListItem>
          );
        }
      }

      list = (
        <List className={classes.list}>
          {creatingModelUsage}
          {this.renderList(usageSelected)}
        </List>
      );
    } else if (error) {
      list = (
        <Typography variant="subtitle2" align="left" style={{ marginLeft: 16 }}>
          No usage found: {error}
        </Typography>
      );
    }
    return (
      <div className={classes.container}>
        <Divider />
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ height: 32 }}
        >
          <Grid item>
            <Typography variant="subtitle2" className={classes.title}>
              Usages
            </Typography>
          </Grid>
          <Grid item>
            {isAbleToCreate ? (
              <IconButtonLoading
                tooltipTitle="Create a new usage"
                style={{ padding: 6, marginRight: 12 }}
                authoritiesRequired={[ROLE_MODELREVISION_CREATE]}
                onClick={this.onCreateUsage}
              >
                <Add color="primary" style={{ fontSize: 20 }} />
              </IconButtonLoading>
            ) : null}
          </Grid>
        </Grid>
        {list}
        <CreateEntityDialogForm
          withoutSecurityLevel
          onSubmit={this.createNewUsage}
          handleClose={this.onCloseCreateUsage}
          open={creating}
          entityType={MODEL_USAGE_TYPE}
        />
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    marginTop: 10
  },
  list: {
    paddingRight: "10px"
  },
  title: {
    padding: "0 16px 0"
  },
  item: {
    paddingTop: "5px",
    paddingBottom: "5px",
    borderBottomRightRadius: "23px",
    borderTopRightRadius: "23px",
    flexDirection: "column",
    alignItems: "flex-start",
    lineHeight: "18px"
  },
  usageItemSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: "#594300"
  }
});

UsagesList = withStyles(styles, { withTheme: true })(withRouter(UsagesList));

const mapStateToProps = (state, ownProps) => {
  return {
    modelUsageTypes: state.entity[MODEL_USAGE_TYPE]
      ? state.entity[MODEL_USAGE_TYPE].types
      : null,
    me: state.auth.me
  };
};

export default connect(
  mapStateToProps,
  {}
)(UsagesList);
