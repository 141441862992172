import axios from "axios";
import React from "react";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import {connect} from "react-redux";
import {pushNotification} from "actions/notifications";

/*
    Workaround for button-triggered download of backend-generated files. Implemented as a wrapper around IconButtonLoading.
    The main aim of this workaround is to avoid the GET&open-new-tab approach that caused authorization issues after switching
    to Keycloak (MGS-3718).
    Note that this approach is usable for relatively small files only (e.g. table view exports), large files (tens of MB
    and more) could be problematic, since the downloaded file is temporarily stored in memory.
*/
const DownloadGeneratedFileIconButton = ({fileGeneratorUrl, postRequest, postBody, pushNotification, ...rest}) => {

    const extractFilename = (contentDispositionHeader) => {
        if (!contentDispositionHeader) {
            console.log("Generated file did not specify the content-disposition header at all");
            return "export.txt";
        }

        const fields = contentDispositionHeader.split(";");
        const trimmedFields = fields.map(s => s.trim());

        for (let i = 0; i<trimmedFields.length; i++) {
            if (trimmedFields[i].startsWith("filename=")) {
                return trimmedFields[i].replace("filename=", "");
            }
        }
        console.log("Generated file did not provide the filename in content-disposition header");
        return "export.txt";
    };

    const triggerGeneratedFileDownload = (response) => {
        const fileURL = URL.createObjectURL(new Blob([response.data], {}));

        const linkElement = window.document.createElement("a");
        linkElement.href = fileURL;
        linkElement.download = extractFilename(response.headers["content-disposition"]);
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
    };

    const handleFileFetchFail = (err) => {
        pushNotification("error", "error", "Download failed: " + err.response.data.message);
    };

    const handleDownloadButtonClick = (e) => {
        const requestPromise = postRequest ?
            axios.post(fileGeneratorUrl, postBody, {withCredentials: true}) :
            axios.get(fileGeneratorUrl, {withCredentials: true});

        requestPromise.then(triggerGeneratedFileDownload).catch(handleFileFetchFail);
    };

    return <IconButtonLoading {...rest} onClick={handleDownloadButtonClick} />;
};



const mapStateToProps = (state, ownProps) => { return {};};

export default connect(
    mapStateToProps,
    { pushNotification }
)(DownloadGeneratedFileIconButton);

