import React from "react";
import { List } from "@material-ui/core/index";
import TaskListItem from "components/Tasks/TasksPanel/TaskListItem";

const TasksList = ({tasks, showEntity, withWorkflowContext}) => {

  return (
    <List component="nav" style={{ width: "100%" }}>
      {tasks.map(task =>
        <TaskListItem task={task} showEntity={showEntity} withWorkflowContext={withWorkflowContext} />
      )}
    </List>
  );

};
export default TasksList;