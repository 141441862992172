import React, {useState, useEffect} from "react";
import AttributesGrid from "./Grid";

import {
  Paper,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails
} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";

const GRIDS = [
  {
    id: "A1",
    size: 4
  },
  {
    id: "B1",
    size: 4
  },
  {
    id: "C1",
    size: 4
  },
  {
    id: "A2",
    size: 4
  },
  {
    id: "B2",
    size: 4
  },
  {
    id: "C2",
    size: 4
  }
];

const AttributesPanel = ({style, editable, editMode, expanded, panel: {id, groupNames, name}}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  const grids = (
    <Grid container>
      {GRIDS.map(grid => {
        return (
          <AttributesGrid
            key={grid.id}
            grid={grid}
            attributes={groupNames[grid.id]}
            editable={editable}
            editMode={editMode}
          />
        );
      })}
    </Grid>
  );
  if (!id) {
    return (
      <Paper className={classes.root} style={style}>
        {grids}
      </Paper>
    );
  }

  return (
    <Paper className={classes.panel} style={style}>
      <ExpansionPanel expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
        <ExpansionPanelSummary expandIcon={<ExpandMore/>}>
          <Typography variant="subtitle1">{name}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{grids}</ExpansionPanelDetails>
      </ExpansionPanel>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    boxShadow: "none",
    border: "none",
    width: "100%"
  },
  panel: {
    margin: "10px auto",
    boxShadow: "none",
    border: "none",
    width: "100%"
  }
}));

export default AttributesPanel;
