import React from 'react';
import { TablePagination } from '@material-ui/core';

const TablePaginationWidget = (props) => {
  return (
    <TablePagination { ...props } />
  );
};

export default TablePaginationWidget;
