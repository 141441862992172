import React, {useEffect} from "react";
import ButtonLoading from "../../../components/Buttons/ButtonLoading";
import QueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router";
import { login } from "actions/authentification";
import InputField from "../../../components/Form/Fields/InputField";

import PasswordField from "../../../components/Form/Fields/PasswordField";
import { RequiredValidator } from "components/Form/Fields/FieldValidators";
import Image from "components/Image";
import { setTitle } from "../../../api/GUI";
import { Grid } from "@material-ui/core";
import { scrollToFirstError } from "../../../api/Error";
import { handleRedirect } from "../../../api/Route";
import VersionInfoFooter from "components/Footer/VersionInfoFooter";

const LoginForm = reduxForm({
  form: "login",
  onSubmitFail: scrollToFirstError
})(({ handleSubmit, logining, locked, errorServer }) => {
  setTitle("Login");

  return (
    <div className="card bg-white">
      <div className="card-content">
        <section className="logo text-center">
          <h1>
            <a href="#/">
              <img
                style={{ height: "170px" }}
                src={"/assets/images/mgs_logo/logo_big.png"}
              />
            </a>
          </h1>
        </section>
        <form testid="loginform" onSubmit={handleSubmit}>
          <Field
            label={"Username"}
            name="username"
            component={InputField}
            validate={[RequiredValidator]}
            submitOnEnter={true}
          />
          <Field
            label={"Password"}
            name="password"
            component={PasswordField}
            validate={[RequiredValidator]}
            submitOnEnter={true}
          />
          <div className="error">{!logining && errorServer}</div>
          {locked ? (
            <div className="error">
              Server unreachable, trying to reconnect...
            </div>
          ) : null}
          <div className="card-action no-border">
            <div>
              <Image
                style={{
                  minHeight: 0,
                  minWidth: 50,
                  height: "auto",
                  maxHeight: 64,
                  width: "auto"
                }}
                src={"/api/resources/customer-logo.png"}
              />
            </div>
            <ButtonLoading
              loading={logining}
              loadingTitle="Login"
              className="color-primary"
              style={{ height: 32 }}
              type="submit"
              disabled={locked}
              variant="outlined"
              color="primary"
            >
              {"Login"}
            </ButtonLoading>
          </div>
        </form>
      </div>
    </div>
  );
});

const Login = withRouter(
  connect(
    mapStateToProps,
    { login }
  )(
    ({
      me,
      login,
      locked,
      logining,
      error,
      location: { state },
      history,
      lastLocation
    }) => {

      useEffect(() => {
        handleRedirect({ me, location: { state }, history, lastLocation });
      }, []);
      useEffect(() => {
        handleRedirect({ me, location: { state }, history, lastLocation });
      }, [me]);
      const handleSubmit = form => {
        login(form.username, form.password);
      };

      return (
          <>
              <Grid container alignItems="center" className="page-login">
                  <Grid className="main-body">
                      <QueueAnim type="bottom" className="ui-animate">
                          <div key="1">
                              <div className="body-inner">
                                  <LoginForm
                                      logining={logining}
                                      onSubmit={handleSubmit}
                                      errorServer={error}
                                      locked={locked}
                                  />
                              </div>
                          </div>
                      </QueueAnim>
                  </Grid>
              </Grid>
              <VersionInfoFooter/>
          </>
      );
    }
  )
);

function mapStateToProps(state) {
  return {
    me: state.auth.me,
    error: state.auth.error,
    logining: state.auth.logining,
    locked: state.settings.locked
  };
}

export default Login;
