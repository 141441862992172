import React, { useState } from "react";
import ButtonLoading from "components/Buttons/ButtonLoading";
import DialogActions from "@material-ui/core/DialogActions/index";
import Dialog from "@material-ui/core/Dialog/index";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import { Delete } from "@material-ui/icons/index";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { getApiError } from "../../api/Error";

const DeleteCredentialsButton = ({ onDelete, credential }) => {
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    setError(null);
    try {
      await onDelete(credential.id);
      setOpen(false);
    } catch (e) {
      setError(getApiError(e));
    }
    setDeleting(false);
  };

  if (!credential) {
    return null;
  }

  return (
    <div style={{ display: "inline-block" }}>
      <div className="error" style={{ display: "inline-block" }}>
        {error}
      </div>
      <IconButtonLoading
        loading={deleting}
        loadingTitle="Removing SSH keys"
        tooltipTitle="Remove SSH keys"
        onClick={() => setOpen(true)}
        aria-label="Remove SSH keys"
        color="primary"
      >
        <Delete />
      </IconButtonLoading>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={() => setOpen(false)}
        >
          These SSH keys will be removed, do you want to continue?
        </CloseableDialogTitle>
        <DialogActions>
          {!deleting && (
            <ButtonLoading onClick={() => setOpen(false)} color="primary">
              Cancel
            </ButtonLoading>
          )}
          <ButtonLoading
            loading={deleting}
            loadingTitle="Deleting the credential"
            onClick={handleDelete}
            color="primary"
          >
            Continue
          </ButtonLoading>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteCredentialsButton;
