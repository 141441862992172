import React, { Component } from "react";
import { connect } from "react-redux";
import Diagram from "./Diagram";
import IconButton from "../Buttons/IconButton";
import { setDiagramValues } from "../../actions/Workflow";
import { Close } from "@material-ui/icons";
import { Dialog } from "@material-ui/core";

class DiagramDialog extends Component {
  onClose = e => {
    const { onClose } = this.props;
    onClose(e);
  };

  render() {
    const { workflow, open } = this.props;
    if (!workflow) {
      return null;
    }

    return (
      <div
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Dialog
          fullWidth
          maxWidth="lg"
          onClose={this.onClose}
          PaperProps={{
            style: { height: "100%" }
          }}
          testid="diagram"
          open={open}
        >
          <IconButton
            className="closeDialog"
            onClick={this.onClose}
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              zIndex: 9
            }}
          >
            <Close />
          </IconButton>
          <Diagram workflow={workflow} open={open} />
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  { setDiagramValues }
)(DiagramDialog);
