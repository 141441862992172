import React, { Component } from 'react';
import SimpleTable from "components/Table/SimpleTable";

class SQLTable extends Component {

    render() {
        const { data, columns } = this.props;

        if (!data) {
            return null;
        }
        if (!Array.isArray(data)) {
            return data;
        }
        if (data.length === 0) {
            return "Empty response was retrieved";
        }
        return (
            <React.Fragment>
                <SimpleTable
                    columns={columns}
                    data={ data } />
            </React.Fragment>
        );
    };
}

export default SQLTable;
