import {
  GET_DATE_ASSISTANT_OPTIONS,
  LOADING_DATE_ASSISTANT_OPTIONS,
  END_GETING_DATE_ASSISTANT_OPTIONS,
  ERROR_GETING_DATE_ASSISTANT_OPTIONS,
  SET_EXPORT_DATES,
  ADD_EXPORT_DATE,
  REMOVE_EXPORT_DATE,
  SET_EXPORT_FORMAT,
} from "../../constants/ActionTypes";
import axios from "axios";
import { getEntityApiExport } from "../../api/Entity";

export function getDateAssistantOptions() {
  return function(dispatch) {
    dispatch({
      type: LOADING_DATE_ASSISTANT_OPTIONS,
    });

    axios
      .post(
        "/api/dynamic-function-call",
        {
          functionName: "DateSelectionAssistantOptions",
          arguments: {},
        },
        {
          withCredentials: true,
        }
      )
      .then(response => {
        dispatch({
          type: GET_DATE_ASSISTANT_OPTIONS,
          options: response.data,
        });
        dispatch({
          type: END_GETING_DATE_ASSISTANT_OPTIONS,
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_GETING_DATE_ASSISTANT_OPTIONS,
          error: message,
        });
        dispatch({
          type: END_GETING_DATE_ASSISTANT_OPTIONS,
        });
      });
  };
}

export function setExportDates(entityType, exportDates) {
  return function(dispatch) {
    dispatch({
      type: SET_EXPORT_DATES,
      entityType,
      exportDates,
    });
  };
}

export function addExportDate(entityType, inputDate) {
  return function(dispatch) {
    dispatch({
      type: ADD_EXPORT_DATE,
      entityType,
      inputDate,
    });
  };
}

export function removeExportDate(entityType, inputDate) {
  return function(dispatch) {
    dispatch({
      type: REMOVE_EXPORT_DATE,
      entityType,
      inputDate,
    });
  };
}

export function setFormatSelected(selectedFormat) {
  return function(dispatch) {
    dispatch({
      type: SET_EXPORT_FORMAT,
      selectedFormat,
    });
  };
}

export function startHistoryExport(entityType, exportDates, exportFileType) {
  const url = getEntityApiExport(entityType);
  return axios.post(
    url,
    { exportFileType, exportDates },
    {
      withCredentials: true,
    }
  );
}
