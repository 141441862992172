export const ROLE_ACCESSTOKEN_MANAGEOWN = "ROLE_ACCESSTOKEN_MANAGEOWN";
export const ROLE_CONTAINER_CREATE = "ROLE_CONTAINER_CREATE";
export const ROLE_CONTAINER_KILL = "ROLE_CONTAINER_KILL";
export const ROLE_CONTAINER_SYNC = "ROLE_CONTAINER_SYNC";
export const ROLE_CONTAINER_VIEW = "ROLE_CONTAINER_VIEW";
export const ROLE_DATALAB_IMAGE_VIEW = "ROLE_DATALAB_IMAGE_VIEW";
export const ROLE_DYNAMICPROPERTY_EDIT = "ROLE_DYNAMICPROPERTY_EDIT";
export const ROLE_ENTITYLINK_CREATE = "ROLE_ENTITYLINK_CREATE";
export const ROLE_ENTITYLINK_DEFINITION_VIEW = "ROLE_ENTITYLINK_DEFINITION_VIEW";
export const ROLE_ENTITYLINK_DELETE = "ROLE_ENTITYLINK_DELETE";
export const ROLE_ENTITYLINK_EDIT = "ROLE_ENTITYLINK_EDIT";
export const ROLE_ENTITYLINK_VIEW = "ROLE_ENTITYLINK_VIEW";
export const ROLE_EVENT_DISPATCH = "ROLE_EVENT_DISPATCH";
export const ROLE_GIT_REPO_CREATE = "ROLE_GIT_REPO_CREATE";
export const ROLE_GIT_REPO_VIEW = "ROLE_GIT_REPO_VIEW";
export const ROLE_MODEL_ATTACHMENTDELETE = "ROLE_MODEL_ATTACHMENTDELETE";
export const ROLE_MODEL_ATTACHMENTDOWNLOAD = "ROLE_MODEL_ATTACHMENTDOWNLOAD";
export const ROLE_MODEL_ATTACHMENTUPLOAD = "ROLE_MODEL_ATTACHMENTUPLOAD";
export const ROLE_MODEL_CREATE = "ROLE_MODEL_CREATE";
export const ROLE_MODEL_DELETE = "ROLE_MODEL_DELETE";
export const ROLE_MODEL_EDIT = "ROLE_MODEL_EDIT";
export const ROLE_MODEL_VIEW = "ROLE_MODEL_VIEW";
export const ROLE_MODEL_MONITORING_CREATE = "ROLE_MODEL_MONITORING_CREATE";
export const ROLE_MODEL_MONITORING_DELETE = "ROLE_MODEL_MONITORING_DELETE";
export const ROLE_MODEL_MONITORING_EDIT = "ROLE_MODEL_MONITORING_EDIT";
export const ROLE_MODEL_MONITORING_VIEW = "ROLE_MODEL_MONITORING_VIEW";
export const ROLE_MODEL_WORKFLOW_CREATE = "ROLE_MODELWORKFLOW_CREATE";
export const ROLE_MODEL_WORKFLOW_DELETE = "ROLE_MODELWORKFLOW_DELETE";
export const ROLE_MODEL_WORKFLOW_EDIT = "ROLE_MODELWORKFLOW_EDIT";
export const ROLE_MODEL_WORKFLOW_VIEW = "ROLE_MODELWORKFLOW_VIEW";
export const ROLE_MODELREVISION_CREATE = "ROLE_MODELREVISION_CREATE";
export const ROLE_MODELREVISION_DELETE = "ROLE_MODELREVISION_DELETE";
export const ROLE_MODELTYPE_VIEW = "ROLE_MODELTYPE_VIEW";
export const ROLE_MODELUSAGE_CREATE = "ROLE_MODELUSAGE_CREATE";
export const ROLE_ODATAACCESSCODE_CREATE = "ROLE_ODATAACCESSCODE_CREATE";
export const ROLE_ODATAACCESSCODE_DELETE = "ROLE_ODATAACCESSCODE_DELETE";
export const ROLE_ODATAACCESSCODE_VIEW = "ROLE_ODATAACCESSCODE_VIEW";
export const ROLE_PARTICIPATION_CREATE = "ROLE_PARTICIPATION_CREATE";
export const ROLE_PARTICIPATION_DELETE = "ROLE_PARTICIPATION_DELETE";
export const ROLE_PARTICIPATION_MANAGE_ASSIGNMENT = "ROLE_PARTICIPATION_MANAGEASSIGNMENT";
export const ROLE_PARTICIPATION_ROLES_VIEW = "ROLE_PARTICIPATIONROLES_VIEW";
export const ROLE_PARTICIPATION_VIEW = "ROLE_PARTICIPATION_VIEW";
export const ROLE_PERSONAL_STORAGE = "ROLE_PERSONAL_STORAGE";
export const ROLE_PUBLIC_FOLDER_MANAGE = "ROLE_PUBLIC_FOLDER_MANAGE";
export const ROLE_PUBLIC_FOLDER_VIEW = "ROLE_PUBLIC_FOLDER_VIEW";
export const ROLE_SAVEDSEARCH_CREATE = "ROLE_SAVED_SEARCH_CREATE";
export const ROLE_SAVEDSEARCH_DELETE = "ROLE_SAVED_SEARCH_DELETE";
export const ROLE_SAVEDSEARCH_EDIT = "ROLE_SAVED_SEARCH_EDIT";
export const ROLE_SAVEDSEARCH_VIEW = "ROLE_SAVED_SEARCH_VIEW";
export const ROLE_SECURITYLEVEL_CREATE = "ROLE_SECURITYLEVEL_CREATE";
export const ROLE_SECURITYLEVEL_DELETE = "ROLE_SECURITYLEVEL_DELETE";
export const ROLE_SECURITYLEVEL_EDIT = "ROLE_SECURITYLEVEL_EDIT";
export const ROLE_SECURITYLEVEL_VIEW = "ROLE_SECURITYLEVEL_VIEW";
export const ROLE_SHARED_FOLDER_VIEW = "ROLE_SHARED_FOLDER_VIEW";
export const ROLE_SHARED_FOLDER_ATTACHMENTUPLOAD = "ROLE_SHARED_FOLDER_ATTACHMENTUPLOAD";
export const ROLE_SHARED_FOLDER_ATTACHMENTDOWNLOAD = "ROLE_SHARED_FOLDER_ATTACHMENTDOWNLOAD";
export const ROLE_SHARED_FOLDER_ATTACHMENTDELETE = "ROLE_SHARED_FOLDER_ATTACHMENTDELETE";
export const ROLE_SHARED_FOLDER_MANAGE = "ROLE_SHARED_FOLDER_MANAGE";
export const ROLE_STANDALONE_WORKFLOW_CREATE = "ROLE_WORKFLOW_CREATE";
export const ROLE_STANDALONE_WORKFLOW_DELETE = "ROLE_WORKFLOW_DELETE";
export const ROLE_STANDALONE_WORKFLOW_EDIT = "ROLE_WORKFLOW_EDIT";
export const ROLE_STANDALONE_WORKFLOW_VIEW = "ROLE_WORKFLOW_VIEW";
export const ROLE_SYSTEM_ADMINISTRATION = "ROLE_SYSTEM_ADMINISTRATION";
export const ROLE_SYSTEM_PERFORM = "ROLE_SYSTEM_PERFORM";
export const ROLE_VALIDATION_ATTACHMENTDELETE = "ROLE_VALIDATION_ATTACHMENTDELETE";
export const ROLE_VALIDATION_ATTACHMENTDOWNLOAD = "ROLE_VALIDATION_ATTACHMENTDOWNLOAD";
export const ROLE_VALIDATION_ATTACHMENTUPLOAD = "ROLE_VALIDATION_ATTACHMENTUPLOAD";
export const ROLE_VALIDATION_CREATE = "ROLE_VALIDATION_CREATE";
export const ROLE_VALIDATION_DELETE = "ROLE_VALIDATION_DELETE";
export const ROLE_VALIDATION_EDIT = "ROLE_VALIDATION_EDIT";
export const ROLE_VALIDATION_MANAGE_ASSIGNMENT = "ROLE_VALIDATION_MANAGEASSIGNMENT";
export const ROLE_VALIDATION_VIEW = "ROLE_VALIDATION_VIEW";
export const ROLE_VALIDATION_WORKFLOW_CREATE = "ROLE_VALIDATIONWORKFLOW_CREATE";
export const ROLE_VALIDATION_WORKFLOW_DELETE = "ROLE_VALIDATIONWORKFLOW_DELETE";
export const ROLE_VALIDATION_WORKFLOW_EDIT = "ROLE_VALIDATIONWORKFLOW_EDIT";
export const ROLE_VALIDATION_WORKFLOW_VIEW = "ROLE_VALIDATIONWORKFLOW_VIEW";
export const ROLE_VALIDATIONCOMMITTEE_ATTACHMENTDELETE = "ROLE_VALIDATIONCOMMITTEE_ATTACHMENTDELETE";
export const ROLE_VALIDATIONCOMMITTEE_ATTACHMENTDOWNLOAD = "ROLE_VALIDATIONCOMMITTEE_ATTACHMENTDOWNLOAD";
export const ROLE_VALIDATIONCOMMITTEE_ATTACHMENTUPLOAD = "ROLE_VALIDATIONCOMMITTEE_ATTACHMENTUPLOAD";
export const ROLE_VALIDATIONCOMMITTEE_CREATE = "ROLE_VALIDATIONCOMMITTEE_CREATE";
export const ROLE_VALIDATIONCOMMITTEE_DELETE = "ROLE_VALIDATIONCOMMITTEE_DELETE";
export const ROLE_VALIDATIONCOMMITTEE_EDIT = "ROLE_VALIDATIONCOMMITTEE_EDIT";
export const ROLE_VALIDATIONCOMMITTEE_MANAGE_ASSIGNMENT = "ROLE_VALIDATIONCOMMITTEE_MANAGEASSIGNMENT";
export const ROLE_VALIDATIONCOMMITTEE_VIEW = "ROLE_VALIDATIONCOMMITTEE_VIEW";
export const ROLE_VALIDATIONCOMMITTEE_WORKFLOW_CREATE = "ROLE_VALIDATIONCOMMITTEEWORKFLOW_CREATE";
export const ROLE_VALIDATIONCOMMITTEE_WORKFLOW_DELETE = "ROLE_VALIDATIONCOMMITTEEWORKFLOW_DELETE";
export const ROLE_VALIDATIONCOMMITTEE_WORKFLOW_EDIT = "ROLE_VALIDATIONCOMMITTEEWORKFLOW_EDIT";
export const ROLE_VALIDATIONCOMMITTEE_WORKFLOW_VIEW = "ROLE_VALIDATIONCOMMITTEEWORKFLOW_VIEW";
export const ROLE_VALIDATIONCOMMITTEETYPE_VIEW = "ROLE_VALIDATIONCOMMITTEETYPE_VIEW";
export const ROLE_VALIDATIONTYPE_VIEW = "ROLE_VALIDATIONTYPE_VIEW";
export const ROLE_WIDGET_CREATE = "ROLE_WIDGET_CREATE";
export const ROLE_WORKFLOW_CREATE = "ROLE_WORKFLOW_CREATE";
export const ROLE_WORKFLOW_MANAGE = "ROLE_WORKFLOW_MANAGE";
export const ROLE_WORKFLOWTYPE_CREATE = "ROLE_WORKFLOWTYPE_CREATE";
export const ROLE_WORKFLOWTYPE_DELETE = "ROLE_WORKFLOWTYPE_DELETE";
export const ROLE_WORKFLOWTYPE_VIEW = "ROLE_WORKFLOWTYPE_VIEW";
