import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { UNAUTH_USER } from "constants/ActionTypes";
import admin from "./Admin";
import attachments from "./Attachments";
import attributes from "./Attributes";
import authentification from "./authentification";
import committees from "./Validations/Committees";
import configuration from "./Configuration";
import containers from "./Containers";
import dockerImages from "./Containers/dockerImages";
import drools from "./Drools";
import entity from "./Entity";
import enumEntries from "./Attributes/enumEntries";
import exportHistory from "./ExportHistory";
import features from "./Features";
import filters from "./Search";
import folders from "./Folders";
import models from "./Models";
import modelRevisions from "./Models/revisions";
import modelUsages from "./Models/usages";
import notifications from "./notifications";
import securityLevels from "./SecurityLevels";
import settings from "./settings";
import tasks from "./Tasks";
import tokens from "./Tokens";
import users from "./Users";
import uploads from "./Upload";
import validations from "./Validations";
import workflow from "./Workflow";
import workflowTypes from "./Workflow/types";
import license from "./License";

import { initialValue as UPLOAD_INITIAL_VALUE } from "./Upload";

const reducers = {
  admin,
  attributes,
  attachments,
  auth: authentification,
  committees,
  configuration,
  containers,
  dockerImages,
  drools,
  entity,
  enumEntries,
  exportHistory,
  features,
  filters,
  folders,
  form,
  models,
  modelRevisions,
  modelUsages,
  notifications,
  securityLevels,
  settings,
  tasks,
  tokens,
  users,
  uploads,
  validations,
  workflow,
  workflowTypes,
  license,
};

const combinedReducer = combineReducers(reducers);

function crossSliceReducer(state, action) {
  switch (action.type) {
    case UNAUTH_USER: {
      return {
        attachments: {},
        attributes: {},
        auth: {
          lastUserIdConnected: state.auth.lastUserIdConnected,
        },
        committees: {},
        configuration: {},
        enumEntries: {},
        exportHistory: {},
        features: {},
        filters: {},
        form: {},
        folders: {},
        models: {},
        modelUsages: {},
        modelRevisions: {},
        notifications: state.notifications,
        settings: {
          result_format: state.settings.result_format,
          snackbar: state.settings.snackbar,
        },
        tasks: {},
        users: {},
        uploads: UPLOAD_INITIAL_VALUE,
        workflow: {},
        workflowTypes: {},
        validations: {},
        license: {},
      };
    }
    default:
      return state;
  }
}

function rootReducer(state, action) {
  const intermediateState = combinedReducer(state, action);
  return crossSliceReducer(intermediateState, action);
}

export default rootReducer;
