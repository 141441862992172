import {
  GET_WORKFLOW,
  LOADING_WORKFLOW,
  END_LOADING_WORKFLOW,
  WORKFLOW_ERROR,
  GET_WORKFLOW_DIAGRAM,
  LOADING_WORKFLOW_DIAGRAM,
  END_LOADING_WORKFLOW_DIAGRAM,
  WORKFLOW_DIAGRAM_ERROR,
  SET_DIAGRAM_VALUES,
  LOADING_WORKFLOW_AUDIT_TRAILS,
  GET_WORKFLOW_AUDIT_TRAILS,
  END_LOADING_WORKFLOW_AUDIT_TRAILS,
  WORKFLOW_AUDIT_TRAILS_ERROR
} from "../../constants/ActionTypes";
import axios from "axios";
import {
  getEntityApiParam,
  getEntityType,
  getEntityGetApiParamSimple
} from "../../api/Entity";
import { manageForm } from "../../api/Workflow";
import APPCONFIG from "../../constants/Config";
import qs from "querystring";
import { WORKFLOW_REGULAR } from "../../constants/Workflows";

export async function getWorkflows(
  ownerId,
  entityType,
  page = 0,
  pageSize = APPCONFIG.LIST_SIZE,
  classification = WORKFLOW_REGULAR,
  workflowName,
  workflowTypeId
) {
  const queryParams = qs.stringify({
    loadFull: false,
    page,
    pageSize,
    classification,
    workflowName,
    workflowTypeId
  });
  const url =
    ownerId && entityType
      ? "/api/" + getEntityGetApiParamSimple(entityType) + "/" + ownerId
      : "/api";

  return await axios.get(url + "/workflows?" + queryParams, {
    withCredentials: true
  });
}

export function createWorkflow(workflow, entity) {
  const ownerType = entity && entity.id ? getEntityType(entity) : null;
  const url =
    entity && entity.id
      ? "/api/" + getEntityApiParam(ownerType) + "/" + entity.id + "/workflows"
      : "/api/workflows";
  return axios.post(url, workflow, {
    withCredentials: true
  });
}

export function getWorkflow(workflowId, owner) {
  const ownerType = owner ? owner.category : null;
  const url = ownerType ? "/api/" + getEntityApiParam(ownerType) : "/api";
  return axios.get(url + "/workflows/" + workflowId, {
    withCredentials: true
  });
}
export function getWorkflowRedux(workflowId, owner) {
  const ownerId = owner ? owner.id : null;
  return function(dispatch) {
    dispatch({
      type: LOADING_WORKFLOW,
      ownerId: ownerId,
      workflowId: workflowId
    });

    getWorkflow(workflowId, owner)
      .then(response => {
        let workflow = response.data;
        dispatch({
          type: GET_WORKFLOW,
          workflow: {
            ...manageForm(workflow),
            tasks:
              workflow.tasks &&
              workflow.tasks.map(task => {
                return manageForm(task);
              })
          }
        });
        dispatch({
          type: END_LOADING_WORKFLOW,
          ownerId: ownerId,
          workflowId: workflowId
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: WORKFLOW_ERROR,
          ownerId: ownerId,
          workflowId: workflowId,
          error: message
        });
        dispatch({
          type: END_LOADING_WORKFLOW,
          ownerId: ownerId,
          workflowId: workflowId
        });
      });
  };
}
export function getWorkflowDiagram(workflowId, owner) {
  const ownerType = owner ? owner.category : null;

  const url = ownerType ? "/api/" + getEntityApiParam(ownerType) : "/api";
  return axios.get(url + "/workflows/" + workflowId + "/diagrams", {
    withCredentials: true
  });
}
export function getWorkflowDiagramRedux(workflowId, owner) {
  const ownerId = owner ? owner.id : null;
  return function(dispatch) {
    dispatch({
      type: LOADING_WORKFLOW_DIAGRAM,
      ownerId: ownerId,
      workflowId: workflowId
    });

    getWorkflowDiagram(workflowId, owner)
      .then(response => {
        let diagram = response.data;
        dispatch({
          type: GET_WORKFLOW_DIAGRAM,
          ownerId: ownerId,
          workflowId: workflowId,
          diagram: diagram
        });
        dispatch({
          type: END_LOADING_WORKFLOW_DIAGRAM,
          ownerId: ownerId,
          workflowId: workflowId
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: WORKFLOW_DIAGRAM_ERROR,
          ownerId: ownerId,
          workflowId: workflowId,
          error: message
        });
        dispatch({
          type: END_LOADING_WORKFLOW_DIAGRAM,
          ownerId: ownerId,
          workflowId: workflowId
        });
      });
  };
}
export function getWorkflowAuditTrail(
  workflowId,
  owner,
  firstResult,
  maxResultCount
) {
  const ownerType = owner ? owner.category : null;
  const url = ownerType ? "/api/" + getEntityApiParam(ownerType) : "/api";
  return axios.get(
    url +
      "/workflows/" +
      workflowId +
      "/logs?firstResult=" +
      firstResult +
      "&maxResultCount=" +
      maxResultCount,
    {
      withCredentials: true
    }
  );
}
export function getWorkflowAuditTrailRedux(
  workflowId,
  owner,
  firstResult,
  maxResultCount
) {
  const ownerId = owner ? owner.id : null;
  return function(dispatch) {
    dispatch({
      type: LOADING_WORKFLOW_AUDIT_TRAILS,
      ownerId: ownerId,
      workflowId: workflowId
    });
    getWorkflowAuditTrail(workflowId, owner, firstResult, maxResultCount)
      .then(response => {
        let auditTrails = response.data;
        dispatch({
          type: GET_WORKFLOW_AUDIT_TRAILS,
          ownerId: ownerId,
          workflowId: workflowId,
          auditTrails: auditTrails
        });
        dispatch({
          type: END_LOADING_WORKFLOW_AUDIT_TRAILS,
          ownerId: ownerId,
          workflowId: workflowId
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: WORKFLOW_AUDIT_TRAILS_ERROR,
          ownerId: ownerId,
          workflowId: workflowId,
          error: message
        });
        dispatch({
          type: END_LOADING_WORKFLOW_AUDIT_TRAILS,
          ownerId: ownerId,
          workflowId: workflowId
        });
      });
  };
}
export function updateWorkflow(workflowId, owner, values) {
  const ownerType = owner ? owner.category : null;
  const url = ownerType ? "/api/" + getEntityApiParam(ownerType) : "/api";
  return axios.patch(url + "/workflows/" + workflowId + "/variables", values, {
    withCredentials: true
  });
}
export function updateWorkflowName(workflowId, owner, name) {
  const ownerCategory = owner ? owner.category : null;
  const url = ownerCategory
    ? "/api/" + getEntityGetApiParamSimple(ownerCategory)
    : "/api";
  return axios.patch(
    url + "/workflows/" + workflowId,
    { name },
    {
      withCredentials: true
    }
  );
}
export function deleteWorkflow(workflow) {
  const ownerType = workflow.owningEntity
    ? workflow.owningEntity.category
    : null;
  const workflowId = workflow.id;
  const url = ownerType ? "/api/" + getEntityApiParam(ownerType) : "/api";
  return axios.delete(url + "/workflows/" + workflowId, {
    withCredentials: true
  });
}
export function abortWorkflow(workflow) {
  const ownerType = workflow.owningEntity
    ? workflow.owningEntity.category
    : null;
  const workflowId = workflow.id;
  const url = ownerType ? "/api/" + getEntityApiParam(ownerType) : "/api";
  return axios.post(
    `${url}/workflows/${workflowId}/abort`,
    {},
    {
      withCredentials: true
    }
  );
}

export function setDiagramValues(workflow, values) {
  const workflowId = workflow.id;
  const ownerId = workflow.owningEntity ? workflow.owningEntity.id : null;
  return {
    type: SET_DIAGRAM_VALUES,
    ownerId: ownerId,
    workflowId: workflowId,
    values: values
  };
}
