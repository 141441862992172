import React, { Component } from "react";
import Entities from "components/Entity/Entities";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getEntityTypeFromPath, MODEL_TYPE, VALIDATION_TYPE } from "api/Entity";

class EntitiesOverview extends Component {
  render() {
    const { entityType } = this.props;
    return (
      <div style={{ margin: "0 32px", padding: "10px 0", height: "100%" }}>
        <Entities
          enableCreate
          enableExportHistory
          entityType={entityType}
          selectable={[MODEL_TYPE, VALIDATION_TYPE].includes(entityType)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  return {
    entityType: getEntityTypeFromPath(location.pathname),
    me: state.auth.me,
  };
};

export default withRouter(connect(mapStateToProps, {})(EntitiesOverview));
