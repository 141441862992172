import React, { useState } from "react";
import Button from "components/Buttons/Button";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import { Delete } from "@material-ui/icons";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { getApiError } from "../../api/Error";
import { connect } from "react-redux";
import { pushNotification } from "actions/notifications";
import { ROLE_ODATAACCESSCODE_DELETE } from "../../constants/Authorities";

const DeleteODataAccessCodeButton = ({
  onDelete,
  access,
  pushNotification,
  isAdmin
}) => {
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState(null);

  const confirm = async () => {
    setDeleting(true);
    setErrorDeleting(null);
    try {
      await onDelete();
      setOpen(false);
    } catch (e) {
      let message = getApiError(e);
      setErrorDeleting(message);
      if (message) {
        message = ": " + message;
      }
      pushNotification(
        "error",
        "error",
        "Deleting access link failed" + message
      );
    }
    setDeleting(false);
  };

  if (!access) {
    return null;
  }

  return (
    <>
      <IconButtonLoading
        onClick={() => setOpen(true)}
        authoritiesRequired={isAdmin ? [ROLE_ODATAACCESSCODE_DELETE] : []}
        tooltipTitle="Remove OData access link"
        loading={deleting}
        loadingTitle="Removing the OData access link"
        edge="end"
        aria-label="delete"
        color="primary"
      >
        <Delete />
      </IconButtonLoading>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={() => setOpen(false)}
        >
          This access link will be removed, do you want to continue?
        </CloseableDialogTitle>
        {errorDeleting && (
          <DialogContent>
            <div className="error">{errorDeleting}</div>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => confirm()} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(
  () => ({}),
  { pushNotification }
)(DeleteODataAccessCodeButton);
