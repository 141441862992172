import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <section className="app-footer">
        <div className="container-fluid">
          <span className="float-left">
          </span>
          <span>
            <span>
              Built by
            </span>
            <span style={{marginLeft: '10px'}}>
              <a href="http://www.closeit.co/" target="blank">
                <img height="27px" src="/assets/images/logo.png" />
              </a>
            </span>
          </span>
        </div>
      </section>
    );
  }
}

export default Footer;
