// Copy-pasted from:
// https://stackoverflow.com/questions/12635652/generate-a-secure-password-in-javascript
// and modified for Auth0.
//
// Auth0 requirements:
// https://auth0.com/docs/connections/database/password-strength
//
// "at least 10 characters including at least 3 of the following 4 types of characters:
// a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*).
// Not more than 2 identical characters in a row (such as 111 is not allowed)".

const specials = '!@#$%^&*()_+{}:"<>?\|[];\',./`~';
const lowercase = 'abcdefghijklmnopqrstuvwxyz';
const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const numbers = '0123456789';

const all = /*specials + */lowercase + uppercase + numbers;

export default function generatePassword() {
    let password = '';

    //password += pick(password, specials, 1, 3);
    password += pick(password, lowercase, 1, 3);
    password += pick(password, uppercase, 1, 3);
    password += pick(password, all, 10);

    return shuffle(password);
}

function pick(exclusions, string, min, max) {
    let n, chars = '';

    if (max === undefined) {
        n = min;
    } else {
        n = min + Math.floor(Math.random() * (max - min + 1));
    }

    let i = 0;
    while (i < n) {
        const character = string.charAt(Math.floor(Math.random() * string.length));
        if (exclusions.indexOf(character) < 0 && chars.indexOf(character) < 0) {
            chars += character;
            i++;
        }
    }

    return chars;
}

// Credit to @Christoph: http://stackoverflow.com/a/962890/464744
function shuffle(string) {
    let array = string.split('');
    let tmp, current, top = array.length;

    if (top) while (--top) {
        current = Math.floor(Math.random() * (top + 1));
        tmp = array[current];
        array[current] = array[top];
        array[top] = tmp;
    }

    return array.join('');
}