import React, { Component } from "react";
import FormControl from "./FormControl";
import { Select, InputLabel, MenuItem } from "@material-ui/core";

class SelectField extends Component {
  render() {
    const {
      disabled,
      multiple,
      input,
      renderValue,
      children,
      label,
      displayEmpty,
      placeholder,
    } = this.props;

    return (
      <FormControl {...this.props}>
        {label ? <InputLabel htmlFor={input.name}>{label}</InputLabel> : ""}
        <Select
          {...input}
          className="select"
          color="primary"
          displayEmpty={displayEmpty || placeholder}
          disabled={disabled}
          multiple={multiple}
          renderValue={renderValue}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          id={input.name}>
          {placeholder ? (
            <MenuItem value={""} disabled>
              <span style={{ color: "rgba(0, 0, 0, 0.38)" }}>
                {placeholder}
              </span>
            </MenuItem>
          ) : null}
          {children}
        </Select>
      </FormControl>
    );
  }
}

export default SelectField;
