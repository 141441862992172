import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import React from "react";
import ButtonLoading from "components/Buttons/ButtonLoading";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import {killContainer, syncUpContainer} from "actions/Containers";


class RemoveContainerDialog extends React.Component {

    state = {
        transfer: true
    }

    // Datalabs can be synced on removal only under certain conditions (the Datalab must be interactive and fully recognized by backend)
    confirmRemove = () => {
        const { closeRemoveDialog, killContainer, syncUpContainer, containerToRemove, datalabSyncable, isAdmin } = this.props;
        const { transfer } = this.state;

        // If the AWS sync mode is enabled and a sync up is requested...otherwise remove directly
        if (datalabSyncable && transfer) {
            syncUpContainer(containerToRemove, true, isAdmin);
        } else {
            killContainer(containerToRemove, isAdmin);
        }
        closeRemoveDialog();
    };

    onChangeCheckbox = event => {
        this.setState({
            transfer: event.target.checked
        });
    };

    renderS3fsRemoveDialog() {
        const { removeDialogOpen, containerToRemove, closeRemoveDialog } = this.props;
        return (
            <Dialog maxWidth="md" fullWidth onClose={closeRemoveDialog} open={removeDialogOpen}>
                <CloseableDialogTitle
                    id="simple-dialog-title"
                    handleClose={closeRemoveDialog}
                >
                    This Datalab will be deleted
                </CloseableDialogTitle>
                <DialogContent>
                    All the unfinished processes running in the Datalab will be permanently lost. Do you want to continue?
                    <div className="error">
                        {containerToRemove && containerToRemove.errorKill}
                    </div>
                </DialogContent>
                <DialogActions>
                    {containerToRemove && (
                        <React.Fragment>
                            <ButtonLoading
                                onClick={closeRemoveDialog}
                                color="primary"
                                autoFocus
                            >
                                Cancel
                            </ButtonLoading>
                            <ButtonLoading
                                loading={containerToRemove.killing}
                                onClick={ this.confirmRemove}
                                color="primary"
                            >
                                Continue
                            </ButtonLoading>
                        </React.Fragment>
                    )}
                </DialogActions>
            </Dialog>

        );
    }

    renderAwsSyncRemoveDialog() {
        const { removeDialogOpen, containerToRemove, datalabSyncable, closeRemoveDialog } = this.props;
        const { transfer } = this.state;
        return (
            <Dialog maxWidth="md" fullWidth onClose={closeRemoveDialog} open={removeDialogOpen}>
                <CloseableDialogTitle
                    id="simple-dialog-title"
                    handleClose={closeRemoveDialog}
                >
                    This Datalab will be deleted, do you want to continue?
                </CloseableDialogTitle>
                {
                    datalabSyncable ?
                        (
                            <DialogContent>
                                The Datalab environment may have performed changes on files and these changes may have not
                                been propagated back to Data hub. Do you wish to update the Data hub with changes from the
                                Datalab environment being deleted?
                                <div style={{margin: "10px 0"}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={transfer}
                                                onChange={this.onChangeCheckbox}
                                                value="checkedB"
                                                color="secondary"
                                            />
                                        }
                                        label="Update the Data hub files before removing"
                                    />
                                </div>
                                <div className="error">
                                    {containerToRemove && containerToRemove.errorKill}
                                </div>
                            </DialogContent>
                        )
                        :
                        (
                            <DialogContent>
                                Are you sure you want to delete this Datalab environment?
                                <div className="error">
                                    {containerToRemove && containerToRemove.errorKill}
                                </div>
                            </DialogContent>
                        )
                }
                <DialogActions>
                    {containerToRemove && (
                        <React.Fragment>
                            <ButtonLoading
                                onClick={closeRemoveDialog}
                                color="primary"
                                autoFocus
                            >
                                Cancel
                            </ButtonLoading>
                            <ButtonLoading
                                loadingTitle={
                                    containerToRemove.syncuping
                                        ? "Updating the Data hub files"
                                        : "Removing the Datalab"
                                }
                                loading={containerToRemove.killing}
                                onClick={this.confirmRemove}
                                color="primary"
                            >
                                Continue
                            </ButtonLoading>
                        </React.Fragment>
                    )}
                </DialogActions>
            </Dialog>
        );
    }


    render () {
        const { s3fsEnabled } = this.props;

        return s3fsEnabled ?
            this.renderS3fsRemoveDialog():
            this.renderAwsSyncRemoveDialog();

    }

} export default connect(
    (state, ownProps) => { return {} }, { killContainer, syncUpContainer }
)(RemoveContainerDialog);




