export const getColorsScale = count => {
  switch (count) {
    case 1:
      return ["#607d8b"];
    case 2:
      return ["#607d8b", "#ffa600"];
    case 3:
      return ["#607d8b", "#41ab74", "#ffa600"];
    case 4:
      return ["#607d8b", "#209e90", "#79b34a", "#ffa600"];
    case 5:
      return ["#607d8b", "#2c9797", "#41ab74", "#98b432", "#ffa600"];
    case 6:
      return ["#607d8b", "#379298", "#26a487", "#61b05c", "#abb323", "#ffa600"];
    case 7:
      return [
        "#607d8b",
        "#3f8e98",
        "#209e90",
        "#41ab74",
        "#79b34a",
        "#b9b218",
        "#ffa600"
      ];
    default:
      return [
        "#607d8b",
        "#458c97",
        "#259a95",
        "#2ca682",
        "#58af63",
        "#8ab43d",
        "#c2b10e",
        "#ffa600"
      ];
  }
};

export const COLORS2 = [
  "#ebc844",
  "#105b77",
  "#c02e1c",
  "#107899",
  "#d94d1e",
  "#0f95b9",
  "#f16b20",
  "#5ca793",
  "#ef8b2b",
  "#a2b86c",
  "#ecaa38",
  "#0c3c55"
];

export const COLORS = [
  "#ffa600",
  "#003f5c",
  "#a05195",
  "#d45087",
  "#665191",
  "#f95d6a",
  "#2f4b7c",
  "#ff7c43"
];

export function getAColor(index) {
  return COLORS[index % COLORS.length];
}
