import React, { Component } from "react";
import Button from "../Buttons/Button";
import { DialogActions, Dialog } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";

class ConfirmDeleteWorkflowDialogForm extends Component {
  render() {
    const { handleCancel, handleOk, open, workflowLabel } = this.props;
    return (
      <Dialog onClose={handleCancel} open={open} testid="deleteworkflowdialog">
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={handleCancel}
        >
          This {workflowLabel} will be deleted, do you want to continue?
        </CloseableDialogTitle>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary" testid="confirm">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default ConfirmDeleteWorkflowDialogForm;
