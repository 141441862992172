import React, { Component } from "react";

import { connect } from "react-redux";
import {
  DialogContent,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  withStyles
} from "@material-ui/core";

import { areAttachmentsEqual } from 'api/Attachments';
import { getHistory } from "actions/Documents";
import { LIST_MODE } from 'constants/Attachments';
import File from "components/Attachments/File";
import TabCentered from "components/TabContainer/TabCentered";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";

class HistoryDialog extends Component {
  componentWillReceiveProps(nextProps) {
    const { document, getHistory } = nextProps;
    if (
      document &&
      (!this.props.document || document.id !== this.props.document.id)
    ) {
      getHistory(document);
    }
  }

  render() {
    const {
      document,
      onClose,
      typesAttachment,
      loading,
      error,
      history,
      getDownloadLink,
      classes
    } = this.props;
    if (!document) {
      return null;
    }
    const { fileName } = document;
    let content;
    if (!history && loading) {
      content = (
        <TabCentered>
          <div>
            <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle", marginLeft: "5px" }}>
              Loading the history
            </span>
          </div>
        </TabCentered>
      );
    } else if (!history && error) {
      content = (
        <TabCentered>
          <span style={{ verticalAlign: "middle", color: "red" }}>
            {"Error during the loading of the history: " + error}
          </span>
        </TabCentered>
      );
    } else {
      content = (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.emptyTd}>{/*Checkboxes*/}</TableCell>
              <TableCell>Icon</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Uploaded on</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Last update</TableCell>
              <TableCell>Size</TableCell>
              <TableCell className={classes.emptyTd}>{/*File Validation*/}</TableCell>
              <TableCell className={classes.emptyTd}>{/*Actions*/}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history &&
              history.map((version, idx) => {
                return (
                  <File
                    key={idx}
                    actions={{ onSelect: null }}
                    configuration={{ mode: LIST_MODE, isHistory: true, withoutTypes: true }}
                    file={{ ...version, getDownloadLink }}
                    typesAttachment={typesAttachment}
                  />
                );
              })}
          </TableBody>
        </Table>
      );
    }

    return (
      <Dialog
        onClose={onClose}
        open={Boolean(document)}
        fullWidth
        maxWidth="lg"
      >
        <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
          History of {fileName}
        </CloseableDialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = theme => ({
  emptyTd: {
    padding: 0
  }
});

const mapStateToProps = (state, ownProps) => {
  let { document } = ownProps;
  const {
    history,
    error_history,
    loading_history,
    current
  } = state.attachments;
  if (areAttachmentsEqual(document, current)) {
    document = current;
  }

  return {
    document: document,
    history: history,
    loading: loading_history,
    error: error_history,
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { getHistory }
  )(HistoryDialog)
);
