import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Grid from "components/Grid";
import Button from "components/Buttons/Button";
import ButtonLoading from "components/Buttons/ButtonLoading";
import TabCentered from "components/TabContainer/TabCentered";
import { Link } from "react-router-dom";
import { FOLDER_OVERVIEW_PATH } from "../../../../constants/Routes";
import { ROLE_SHARED_FOLDER_MANAGE } from "../../../../constants/Authorities";
import { DATAHUB_SHARED_FOLDER } from "../../../../constants/Features";
import { isAuthorized } from "../../../../api/Authorities";

import { Card, CardActions, CardContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LogsButton from "components/Attachments/Logs/ButtonLogs";
import { getAttachmentsLogs } from "actions/Attachments";

class FoldersOverview extends Component {
  renderSecurityLevels = folder => {
    if (folder && folder.securityLevels && folder.securityLevels.length > 0) {
      let found = false;
      return folder.securityLevels.map(securityLevel => {
        if (!securityLevel) {
          return null;
        }
        if (found) {
          return ", " + securityLevel.name;
        }
        found = true;
        return securityLevel.name;
      });
    }
    return "";
  };

  onRefreshLogs = (firstResult, maxResultCount) => {
    const { getAttachmentsLogs } = this.props;
    getAttachmentsLogs(null, null, firstResult, maxResultCount);
  };

  render() {
    const {
      classes,
      folders,
      creating,
      createNewFolder,
      me,
      logs,
      errorLogs,
      loadingLogs
    } = this.props;
    const hasFolders = folders && folders.length > 0;
    let list;

    const button = (
      <React.Fragment>
        <LogsButton
          onRefreshLogs={this.onRefreshLogs}
          loading={loadingLogs}
          error={errorLogs}
          logs={logs}
          withContext
        />
        <ButtonLoading
          loading={creating}
          testid="createsharedfolder"
          loadingTitle="Creating the new shared folder"
          onClick={createNewFolder}
          authoritiesRequired={[ROLE_SHARED_FOLDER_MANAGE]}
          featuresRequired={[DATAHUB_SHARED_FOLDER]}
          variant="outlined"
          color="primary"
        >
          Create a new shared folder
        </ButtonLoading>
      </React.Fragment>
    );

    if (hasFolders) {
      list = (
        <div style={{ padding: "10px", overflowX: "hidden" }}>
          <Grid container spacing={2} testid="folders">
            {folders &&
              folders.map((folder, idx) => {
                const isAuthorizedBool = isAuthorized(
                  me,
                  folder.authoritiesRequired
                );
                return (
                  <Grid
                    testid={folder.id}
                    featuresRequired={folder.featuresRequired}
                    item
                    key={idx}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={2}
                  >
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          {this.renderSecurityLevels(folder)}
                        </Typography>
                        <Typography variant="h5" component="h2">
                          {folder.name}
                        </Typography>
                        <Typography component="p">
                          {folder.description}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          disabled={!isAuthorizedBool}
                          component={Link}
                          to={FOLDER_OVERVIEW_PATH.replace(
                            ":folderId",
                            folder.id
                          )}
                          size="small"
                        >
                          {isAuthorizedBool
                            ? "Show More"
                            : "You don't have access to this folder"}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      );
    } else {
      list = (
        <TabCentered>
          <div className="emptyMessage">No folder found</div>
          {button}
        </TabCentered>
      );
    }

    return (
      <React.Fragment>
        <Grid container justify="flex-end" style={{ padding: "10px" }}>
          <Grid item>{hasFolders && button}</Grid>
        </Grid>
        {list}
      </React.Fragment>
    );
  }
}

const styles = {
  card: {
    flex: 1
  },
  title: {
    fontSize: 14,
    marginBottom: 12
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    folders: state.folders.list,
    creating: state.folders.creating,
    features: state.features.list,
    me: state.auth.me,
    loadingLogs: state.attachments.loading_attachments_logs,
    errorLogs: state.attachments.error_attachments_logs,
    logs: state.attachments.attachments_logs
  };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      { getAttachmentsLogs }
    )(FoldersOverview)
  )
);
