import React, { useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemIcon, ListItemText, Menu } from '@material-ui/core';
import { Computer, Copyright, Forward, LockOpen, PersonOutline, Security } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { ROLE_SYSTEM_ADMINISTRATION, ROLE_SYSTEM_PERFORM } from 'constants/Authorities';
import {
  ADMIN_OVERVIEW_PATH,
  DEVELOPER_OVERVIEW_PATH,
  LICENSE_OVERVIEW_PATH,
  PERSONAL_SECTION_OVERVIEW_PATH,
} from 'constants/Routes';
import MenuItem from 'components/Buttons/MenuItem';
import UserAvatar from 'components/Header/UserAvatar';

const useStyles = makeStyles(() => ({
  menuItem: {
    fontSize: '14px',
    lineHeight: '48px',
  },
}));

const UserMenu = ({ user }) => {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const { keycloak } = useKeycloak();

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleCloseMenu();
    keycloak.logout('/');
  };

  return (
    <li className="list-inline-item" style={{ marginRight: '10px' }}>

      <UserAvatar displayName={user.label} onClick={handleClickMenu}/>
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
      >
        <MenuItem
          authoritiesRequired={[ROLE_SYSTEM_PERFORM]}
          onClick={handleCloseMenu}
          component={Link}
          to={DEVELOPER_OVERVIEW_PATH + '?tab=admin_rest'}
          className={classes.menuItem}
        >
          <ListItemIcon>
            <Computer/>
          </ListItemIcon>
          <ListItemText primary="Root"/>
        </MenuItem>
        <MenuItem
          testid="adminPanel"
          authoritiesRequired={[ROLE_SYSTEM_ADMINISTRATION]}
          onClick={handleCloseMenu}
          component={Link}
          to={ADMIN_OVERVIEW_PATH}
          className={classes.menuItem}
        >
          <ListItemIcon>
            <Security/>
          </ListItemIcon>
          <ListItemText primary="System administration"/>
        </MenuItem>
        <MenuItem
          testid="personalSection"
          authoritiesRequired={[]}
          onClick={handleCloseMenu}
          component={Link}
          to={PERSONAL_SECTION_OVERVIEW_PATH + '?tab=account_info'}
          className={classes.menuItem}
        >
          <ListItemIcon>
            <PersonOutline/>
          </ListItemIcon>
          <ListItemText primary="My account"/>
        </MenuItem>
        <MenuItem
          testid="licenseOverview"
          authoritiesRequired={[]}
          onClick={handleCloseMenu}
          component={Link}
          to={LICENSE_OVERVIEW_PATH + '?tab=license_info'}
          className={classes.menuItem}
        >
          <ListItemIcon>
            <LockOpen/>
          </ListItemIcon>
          <ListItemText primary="MGS License"/>
        </MenuItem>
        <MenuItem component="a" target="_blank" href="/licenses.txt">
          <ListItemIcon>
            <Copyright/>
          </ListItemIcon>
          <ListItemText primary="3rd party licenses"/>
        </MenuItem>
        <MenuItem
          testid="logout"
          onClick={handleLogout}
          className={classes.menuItem}
        >
          <ListItemIcon>
            <Forward/>
          </ListItemIcon>
          <ListItemText primary="Log Out"/>
        </MenuItem>
      </Menu>
    </li>
  );
};

UserMenu.propTypes = {
  user: PropTypes.object
}

export default UserMenu;
