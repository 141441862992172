import React, { useEffect, useState } from 'react';

import { Close } from '@material-ui/icons';
import { Dialog, withStyles } from '@material-ui/core';
import Graph from 'react-graph-vis';

import { getEntityHrefFromPath } from 'api/Entity';
import { isResolvedEntitiesSet } from 'api/Links';
import { getAColor } from 'constants/Colors';
import IconButton from 'components/Buttons/IconButton';
import LinkGraphFilter from 'components/Links/Graph/LinkGraphFilter';
import TabCentered from 'components/TabContainer/TabCentered';

const styles = () => ({
  name: {
    textAlign: "right",
    display: "inline-flex",
    paddingRight: "10px",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100px"
  },
  value: {
    display: "inline-flex",
    overflowWrap: "break-word",
    overflowX: "auto",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1
  },
  container: {
    padding: "10px",
    margin: "10px"
  }
});

const LinkGraphDialog = ({ isOpen, links, entityId, onClose }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [entityTypesColors, setEntityTypesColors] = useState([]);
  const [linkTypesColors, setLinkTypesColors] = useState([]);
  const [filters, setFilters] = useState([]);
  const [network, setNetwork] = useState(null);

  useEffect(() => {
    if (network) {
      network.redraw();
      network.fit();
    }
  }, [nodes, edges, linkTypesColors, entityTypesColors]);

  useEffect(() => {
    const _nodes = {
      init: {
        isInit: true,
        x: 0,
        y: 0,
        color: {
          border: "transparent",
          highlight: { border: "transparent" },
          hover: { border: "transparent" }
        }
      }
    };

    const _edges = {};
    const _nodesColors = {};
    const _edgesColors = {};
    let nodesColorsIndex = 0,
      edgesColorsIndex = 0;

    const setEdgeNode = node => {
      setEdgeColor(node);
      const nodeId = node.id;
      const nodeTypeId = node.typeId;
      const isMainEntity = entityId === node.id;

      if (!_nodes[nodeId] && !filters.includes(nodeTypeId)) {
        _nodesColors[nodeTypeId].count++;
        _nodes[nodeId] = {
          ...node,
          label: node.label,
          font: {
            color: isMainEntity ? "#594300" : "black"
          },
          color: {
            border: _nodesColors[nodeTypeId].color,
            background: isMainEntity
              ? _nodesColors[nodeTypeId].color
              : "white",
            highlight: {
              border: _nodesColors[nodeTypeId].color
            },
            hover: {
              border: _nodesColors[nodeTypeId].color
            }
          },
          x: _nodesColors[nodeTypeId].index * 500,
          y: _nodesColors[nodeTypeId].count * 200
        };
      }
    };

    const setEdgeColor = node => {
      const nodeTypeId = node.typeId;
      const nodeType = { id: nodeTypeId, label: node.typeName };
      if (!_nodesColors[nodeTypeId]) {
        _nodesColors[nodeTypeId] = {
          type: nodeType,
          color: getAColor(nodesColorsIndex),
          index: nodesColorsIndex,
          count: 0
        };
        nodesColorsIndex++;
      }
    };

    const manageLink = ({ id, sourceEntityResolved, targetEntityResolved, definition }) => {
      setEdgeNode(sourceEntityResolved);
      setEdgeNode(targetEntityResolved);

      if (!_edgesColors[definition.id]) {
        _edgesColors[definition.id] = {
          color: getAColor(edgesColorsIndex),
          type: definition
        };
        edgesColorsIndex++;
      }
      if (!filters.includes(definition.id)) {
        const edgeKey = `${sourceEntityResolved.id}/${targetEntityResolved.id}`;
        if (_edges[edgeKey]) {
          if (!_edges[edgeKey].label.includes(definition.sourceName)) {
            _edges[edgeKey].label += ` / ${definition.sourceName}`;
            _edges[edgeKey].count++;
            _edges[id] = {
              id: id,
              from: sourceEntityResolved.id,
              to: targetEntityResolved.id,
              smooth: { type: "curvedCCW" },
              width: 4 * (_edges[edgeKey].count + 1),
              color: {
                color: _edgesColors[definition.id].color,
                hover: _edgesColors[definition.id].color,
                highlight: _edgesColors[definition.id].color
              }
            };
          }
        } else {
          _edges[edgeKey] = {
            id: edgeKey,
            count: 0,
            width: 4,
            from: sourceEntityResolved.id,
            to: targetEntityResolved.id,
            label: definition.sourceName,
            smooth: { type: "curvedCCW" },
            color: {
              color: _edgesColors[definition.id].color,
              hover: _edgesColors[definition.id].color,
              highlight: _edgesColors[definition.id].color
            }
          };
        }
      }
    };

    if (links) {
      if (links.sourceLinks) {
        links.sourceLinks
          .filter(isResolvedEntitiesSet)
          .map(manageLink);
      }
      if (links.targetLinks) {
        links.targetLinks
          .filter(isResolvedEntitiesSet)
          .map(manageLink);
      }
      if (links.siblingLinks) {
        links.siblingLinks
          .filter(isResolvedEntitiesSet)
          .map(manageLink);
      }
    }

    setNodes(Object.values(_nodes));
    setEdges(Object.values(_edges).sort((a, b) => b.width - a.width));
    setEntityTypesColors(Object.values(_nodesColors));
    setLinkTypesColors(Object.values(_edgesColors));
  }, [links, filters]);

  const handleClose = () => {
    onClose();
  }

  const isEmpty = nodes.length <= 1 && edges.length <= 0;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{ style: { height: "100%" } }}
      maxWidth="lg"
      fullWidth
    >
      <IconButton
        className="closeDialog"
        onClick={handleClose}
        style={{
          position: "absolute",
          right: "10px",
          top: "10px",
          zIndex: 9
        }}
      >
        <Close />
      </IconButton>

      <LinkGraphFilter
        entityTypesColors={entityTypesColors}
        linkTypesColors={linkTypesColors}
        filters={filters}
        handleChangeFilters={setFilters}
      />
      {!isEmpty
        ? <Graph
            graph={{ nodes, edges }}
            getNetwork={network => {
              setNetwork(network);
            }}
            events={{
              click: ev => {
                if (ev.nodes) {
                  nodes.map(entity => {
                    if (ev.nodes.includes(entity.id) && !entity.isInit && entity.path) {
                      window.open(getEntityHrefFromPath(entity.path), "_blank");
                    }
                  });
                }
              }
            }}
            options={{
              interaction: {
                hover: true,
                tooltipDelay: 300
              },
              layout: {
                randomSeed: 1,
                improvedLayout: true,
                hierarchical: {
                  enabled: false, //change to true to see the other graph
                  direction: "LR",
                  nodeSpacing: 200,
                  levelSeparation: 400,
                  sortMethod: "directed"
                }
              },
              height: "100%",
              nodes: {
                margin: 10,
                borderWidth: 2,
                color: {
                  background: "#FFFFFF",
                  border: "#ddd",
                  highlight: {
                    background: "#FFFFFF"
                  },
                  hover: {
                    background: "#FFFFFF"
                  }
                },
                font: {
                  color: "#000000",
                  bold: {
                    color: "#ffffff"
                  }
                },
                shape: "box",
                shapeProperties: {}
              },
              edges: {
                smooth: true,
                arrowStrikethrough: false
              },
              physics: {
                enabled: false,
                minVelocity: 0.75
              }
            }}
          />
        : <TabCentered>No model link found</TabCentered>
      }
    </Dialog>
  )
};

export default withStyles(styles)(LinkGraphDialog);