import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import AutoSuggestSelectField from "../Form/Fields/AutoSuggestSelectField";
import { getAllRolesRedux } from "actions/Roles";

class Roles extends Component {
  componentDidMount() {
    const { getAllRoles } = this.props;
    getAllRoles();
  }
  render() {
    const { roles, multiple } = this.props;
    let style;
    const name = multiple ? "allowedRoles" : "allowedRole";
    const label = multiple ? "Roles" : "Role";

    // TODO this is quick fix => endpoint was changed and returns page
    const roleResponseContent = roles ? roles.content : [];
    return (
      <div style={style}>
        <Field
          label={label}
          name={name}
          component={AutoSuggestSelectField}
          validate={[]}
          multiple={multiple}
          withEmptyOpt={true}
          ref={name}
          options={
            roleResponseContent &&
            roleResponseContent.map(role => ({
              id: role.id,
              label: role.name
            }))
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    roles: state.users.roles
  };
};
export default connect(
  mapStateToProps,
  { getAllRoles: getAllRolesRedux }
)(Roles);
