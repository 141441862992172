import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../Buttons/ButtonLoading";
import { Field, reduxForm } from "redux-form";
import { createDroolsRule } from "actions/Drools";
import CodeInputField from "components/Form/Fields/CodeInputField";
import {
  NonEmptyStringValidator,
  RequiredValidator
} from "components/Form/Fields/FieldValidators";
import InputField from "components/Form/Fields/InputField";
import {
  withStyles,
  DialogContent,
  Dialog,
  DialogActions
} from "@material-ui/core";
import classnames from "classnames";
import CompilationErrors from "components/Drools/CompilationErrors";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";

class CreateDroolsRuleDialogForm extends Component {
  componentWillReceiveProps(nextProps) {
    const { handleClose, creating, error_creating } = nextProps;
    if (this.props.creating && !creating && !error_creating) {
      handleClose();
    }
  }

  render() {
    const {
      handleSubmit,
      handleClose,
      open,
      creating,
      error_creating,
      compilationErrors,
      classes
    } = this.props;

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        open={open}
        testid="createdroolsruledialog"
      >
        <form onSubmit={handleSubmit}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleClose}
          >
            Create business rule
          </CloseableDialogTitle>
          <DialogContent>
            <div className={classnames(classes.input, classes.inputName)}>
              <Field
                label="Name"
                name="name"
                component={InputField}
                validate={[RequiredValidator, NonEmptyStringValidator]}
                ref="name"
              />
            </div>
            <div className={classes.input}>
              <Field
                label="Code"
                name="code"
                component={CodeInputField}
                validate={[RequiredValidator, NonEmptyStringValidator]}
                ref="code"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonLoading
              testid="submitdroolsrule"
              loading={creating}
              loadingTitle={"Creating the business rule"}
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {"Create new business rule"}
            </ButtonLoading>
            <div className="error">{error_creating}</div>
            <CompilationErrors errors={compilationErrors} />
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const styles = theme => ({
  input: {
    marginBottom: 24
  },
  inputName: {
    width: 300
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    creating: state.drools.creating,
    error_creating: state.drools.error_creating,
    compilationErrors: state.drools.compilation_errors
  };
};
CreateDroolsRuleDialogForm = withStyles(styles)(
  reduxForm({
    form: "createdroolsruledialog",
    onSubmitFail: scrollToFirstError
  })(connect(mapStateToProps)(CreateDroolsRuleDialogForm))
);

class CreateDroolsRuleDialog extends Component {
  handleSubmit = rule => {
    const { createDroolsRule } = this.props;
    let { name, code } = rule;
    createDroolsRule(name, code);
  };

  render() {
    const { open, handleClose } = this.props;
    return (
      <CreateDroolsRuleDialogForm
        open={open}
        handleClose={handleClose}
        onSubmit={this.handleSubmit}
      />
    );
  }
}
export default connect(
  mapStateToProps,
  { createDroolsRule }
)(CreateDroolsRuleDialog);
