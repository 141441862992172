import React from "react";
import Button from "../../Buttons/Button";
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Link } from "react-router-dom";

const ExportLinkToDatahubDialog = ({ handleClose, open, linkToDatabub }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <CloseableDialogTitle
        id="confirmation-dialog-title"
        handleClose={handleClose}
      >
        Export has started.
      </CloseableDialogTitle>
      <DialogContent>
        <DialogContentText>
          Exported file will be saved to Data Hub's personal folder <strong>My Exports</strong>.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Link to={linkToDatabub}>
          <Button color="primary">Follow to Datahub</Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default ExportLinkToDatahubDialog;
