import React, { useEffect, useState } from "react";
import { Drawer } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getDateAssistantOptions } from "actions/ExportHistory";
import Title from "components/ExportHistory/DrawerContent/Title";
import AccompanyingText from "components/ExportHistory/DrawerContent/AccompanyingText";
import DatesSelector from "components/ExportHistory/DrawerContent/DatesSelector";
import DateSelectionAssistant from "components/ExportHistory/DrawerContent/DateSelectionAssistant";
import FormatSelector from "components/ExportHistory/DrawerContent/FormatSelector";
import ExportAction from "components/ExportHistory/DrawerContent/ExportAction";
import { makeStyles } from "@material-ui/core/styles";
import {
  setExportDates,
  startHistoryExport,
} from "../../actions/ExportHistory";
import PresetChangeConfirmDialog from "./Dialog/PresetChangeConfirmDialog";
import ExportLinkToDatahubDialog from "./Dialog/ExportLinkToDatahubDialog";
import {
  APP_OVERVIEW_PATH,
  FOLDER_PERSONAL_PATH,
} from "../../constants/Routes";
import { useApiRequest, useApiRequestV2 } from "../../actions";
import { resolve } from "path";

const useStyles = makeStyles(() => ({
  drawerContent: {
    maxWidth: "700px",
    minWidth: "500px",
    borderLeft: "solid 1px #f2f2f2",
    padding: "24px",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gap: "10px",
  },
}));

const DrawerExport = ({ isOpened, hideDrawer, entityType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dates = useSelector(state => state.exportHistory[entityType] || []);
  const formatSelected = useSelector(
    state => state.exportHistory.selectedFormat
  );

  const [presetSelected, setPresetSelected] = useState(null);
  const [linkToDatabub, setLinkToDatabub] = useState(false);

  const [loading, error, startExport] = useApiRequest(
    startHistoryExport,
    response => {
      setLinkToDatabub(response);
    }
  );

  useEffect(() => {
    getDateAssistantOptions()(dispatch);
  }, []);

  const handlePrefill = ({ presetSelected }) => {
    setPresetSelected(presetSelected);
  };

  const handleExport = () => {
    startExport(entityType, dates, formatSelected);
  };

  return (
    <>
      <Drawer open={isOpened} onClose={hideDrawer} anchor="right">
        <div className={classes.drawerContent}>
          <Title content={"Export Historical Data"} />
          <AccompanyingText />
          <DatesSelector entityType={entityType} />
          <DateSelectionAssistant onSubmit={handlePrefill} />
          <div className={classes.footer}>
            <FormatSelector />
            <ExportAction onClick={handleExport} />
          </div>
        </div>
      </Drawer>
      <PresetChangeConfirmDialog
        open={Boolean(presetSelected)}
        handleClose={() => setPresetSelected(null)}
        preset={presetSelected}
        handleConfirmChanging={() => {
          setExportDates(entityType, presetSelected.dates)(dispatch);
          setPresetSelected(null);
        }}
      />
      <ExportLinkToDatahubDialog
        open={Boolean(linkToDatabub)}
        handleClose={() => setLinkToDatabub(null)}
        linkToDatabub={linkToDatabub}
      />
    </>
  );
};

export default DrawerExport;
