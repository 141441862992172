import React from 'react';

import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';

import CloseableDialogTitle from 'components/Text/CloseableDialogTitle';

const GeneralDialog = ({
  title,
  isOpen,
  onClose,
  children,
}) => {

  /*const [createLoading, createError, runCreate] = useApiRequest(
    createSecurityLevel,
    user => {
      onClose();
      onCreate(user);
      dispatch(pushNotification("check", "success", "User created sucessfully."));
    },
    (e, message) => {
      dispatch(pushNotification(
        "error",
        "error",
        `Creating user failed${message ? `: ${message}` : ""}`
      ));
    }
  );

  const [updateLoading, updateError, runUpdate] = useApiRequest(
    updateSecurityLevel1,
    user => {
      onClose();
      onUpdate(user);
      dispatch(pushNotification("check", "success", "User updated sucessfully."));
    },
    (e, message) => {
      dispatch(pushNotification(
        "error",
        "error",
        `Updating user failed${message ? `: ${message}` : ""}`
      ));
    }
  );*/

  return (
    <Dialog
      onClose={onClose}
      maxWidth="md"
      fullWidth
      open={isOpen}
    >
      <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
        {title}
      </CloseableDialogTitle>
      {children}
    </Dialog>
  );
};

GeneralDialog.propTypes = ({
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
});

export default GeneralDialog;
