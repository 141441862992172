import {
  initialize,
  startAsyncValidation,
  stopAsyncValidation,
  change,
  clearFields,
} from "redux-form";
import {
  SET_CHOOSE_ENTITIES,
  CLOSE_SNACKBAR,
  CHANGE_ATTACHMENT_VIEW,
  OPEN_TOKEN_DIALOG,
  CLOSE_TOKEN_DIALOG,
} from "constants/ActionTypes";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import APPCONFIG from "../constants/Config";
import axios from "axios";
import { getApiError } from "api/Error";

export function closeSnackbar() {
  return {
    type: CLOSE_SNACKBAR,
  };
}

export function initializeForm(formName, datas, keepDirty = false) {
  return function (dispatch) {
    dispatch(
      initialize(formName, datas, keepDirty, {
        updateUnregisteredFields: true,
        keepDirty: keepDirty,
      })
    );
  };
}

export function changeField(formName, fieldName, value, touch) {
  return function (dispatch) {
    dispatch(change(formName, fieldName, value, touch));
  };
}

export function unregisterFormField(formName, fieldName) {
  return function (dispatch) {
    dispatch(clearFields(formName, false, false, [fieldName]));
  };
}

export function errorField(form, name, error) {
  return function (dispatch) {
    dispatch(startAsyncValidation(form));
    let errors = {};
    errors[name] = error;
    dispatch(stopAsyncValidation("attributesform", errors));
  };
}

export function openTokenDialog() {
  return {
    type: OPEN_TOKEN_DIALOG,
  };
}

export function closeTokenDialog() {
  return {
    type: CLOSE_TOKEN_DIALOG,
  };
}

export function openChooseEntities(settings) {
  return {
    type: SET_CHOOSE_ENTITIES,
    settings,
  };
}

export function closeChooseEntities() {
  return {
    type: SET_CHOOSE_ENTITIES,
    settings: {
      open: false,
    },
  };
}

export function changeAttachmentView(view) {
  return {
    type: CHANGE_ATTACHMENT_VIEW,
    view,
  };
}

export function useApiRequest(request, onResponse, onError) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const run = async (...params) => {
    setLoading(true);
    setError(null);
    try {
      const response = await request(...params);
      onResponse(response && response.data);
    } catch (e) {
      let message = getApiError(e);
      setError(message);
      onError && onError(e, message);
    }
    setLoading(false);
  };

  return [loading, error, run];
}

export function useApiFetchDatas(url, setDatas) {
  const refreshTimeout = useRef(null);
  const [loading, error, run] = useApiRequest(axios.get, setDatas);

  useEffect(() => {
    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout.current);
      }
    };
  }, []);
  useEffect(() => {
    refreshDatas();
  }, [url]);

  const refreshDatasTimer = () => {
    refreshDatas();
  };

  const refreshDatas = async () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout.current);
    }
    refreshTimeout.current = setTimeout(() => {
      refreshDatasTimer();
    }, APPCONFIG.REFRESH_TIMEOUT);
    await fetchDatas();
  };

  const fetchDatas = async () => {
    await run(url, {
      withCredentials: true,
    });
  };

  return [loading, error];
}

export const LOADING = "LOADING";
export const FETCHED = "FETCHED";
export const ERROR = "ERROR";
export const IDLE = "IDLE";
export const RESET = "RESET";

const initialState = {
  isLoading: false,
  isError: false,
  errorObject: null,
};

const init = (initialState) => {
  return { ...initialState };
};

const reducer = (state, action) => {
  switch (action.type) {
    case LOADING:
      return { ...initialState, isLoading: true };
    case FETCHED:
      return { ...initialState, isLoading: false };
    case ERROR:
      return {
        ...initialState,
        isLoading: false,
        isError: true,
        errorObject: action.errorObject,
      };
    case RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export const useApiRequestV2 = (request, onResponse) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);

  const fetchData = useCallback(async (payload) => {
    dispatch({ type: LOADING });
    try {
      const response = await request(payload);
      const data = await response.data;
      onResponse(data);
      dispatch({ type: FETCHED });
    } catch (error) {
      let message = getApiError(error);
      dispatch({ type: ERROR, errorObject: message });
    }
  }, []);

  const reset = () => {
    dispatch({ type: RESET });
  };

  return { ...state, reset, run: fetchData };
};

export const useTimeout = (callback, delay) => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
};

export const useDebounce = (callback, delay, dependencies) => {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, []);
};
