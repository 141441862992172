import {
  ADD_PARTICIPATION,
  ADDING_PARTICIPANT,
  END_ADDING_PARTICIPANT,
  ADDING_PARTICIPANT_ERROR,
  REMOVING_PARTICIPANT,
  REMOVE_PARTICIPATION,
  END_REMOVING_PARTICIPANT,
  REMOVING_PARTICIPANT_ERROR,
  GET_PARTICIPATION_ROLES,
  LOADING_PARTICIPATION_ROLES,
  END_LOADING_PARTICIPATION_ROLES,
  PARTICIPATION_ROLES_ERROR,
  PUSH_NOTIFICATION
} from "../../../constants/ActionTypes";
import axios from "axios";

export function getParticipationRoles() {
  return function(dispatch) {
    dispatch({
      type: LOADING_PARTICIPATION_ROLES
    });

    axios
      .get("/api/validation-committees/participation-roles", {
        withCredentials: true
      })
      .then(response => {
        let roles = response.data;
        dispatch({
          type: GET_PARTICIPATION_ROLES,
          roles: roles
        });
        dispatch({
          type: END_LOADING_PARTICIPATION_ROLES
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: PARTICIPATION_ROLES_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_PARTICIPATION_ROLES
        });
      });
  };
}
export function addParticipant(userId, committeeId, role) {
  return function(dispatch) {
    dispatch({
      type: ADDING_PARTICIPANT
    });
    axios
      .post(
        `/api/participations`,
        {
          appUser: {
            id: userId
          },
          validationCommittee: {
            id: committeeId
          },
          participationRole: role
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        let participation = response.data;
        dispatch({
          type: ADD_PARTICIPATION,
          participation: participation
        });
        dispatch({
          type: END_ADDING_PARTICIPANT
        });
      })
      .catch(err => {
        console.log(err);
        const message = err.response.data.message;
        dispatch({
          type: ADDING_PARTICIPANT_ERROR,
          error: message
        });
        dispatch({
          type: END_ADDING_PARTICIPANT
        });
      });
  };
}
export function removeParticipant(committeeId, participation) {
  return function(dispatch) {
    const participationId = participation.id;
    dispatch({
      type: REMOVING_PARTICIPANT,
      participationId: participationId,
      committeeId: committeeId
    });
    axios
      .delete("/api/participations/" + participationId, {
        withCredentials: true
      })
      .then(response => {
        dispatch({
          type: REMOVE_PARTICIPATION,
          participationId: participationId,
          participation: participation,
          committeeId: committeeId
        });
        dispatch({
          type: END_REMOVING_PARTICIPANT,
          participationId: participationId,
          committeeId: committeeId
        });
      })
      .catch(err => {
        console.log(err);
        const message = err.response.data.message;
        dispatch({
          type: REMOVING_PARTICIPANT_ERROR,
          participationId: participationId,
          error: message,
          committeeId: committeeId
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message:
            "Removing participation of " +
            participation.appUser.label +
            " failed" +
            message
        });
        dispatch({
          type: END_REMOVING_PARTICIPANT,
          participationId: participationId,
          committeeId: committeeId
        });
      });
  };
}
