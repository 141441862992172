import React, { useCallback, useState } from 'react';

import { withRouter } from 'react-router';

import { getCurrentURLParams } from 'api/GUI';
import { createUpdateOnLabel, downloadFile, nFormatter } from 'api/File';
import { LIST_MODE } from 'constants/Attachments';
import FileMenu from 'components/Attachments/File/FileMenu';
import RowFile from 'components/Attachments/File/RowFile';
import CardFile from 'components/Attachments/File/CardFile';
import { pushNotification } from 'actions/notifications';
import { getApiError } from 'api/Error';
import { useDispatch } from 'react-redux';
import { createDownloadLink } from 'actions/Uploads';

const File = ({
  actions,
  configuration,
  file,
  location,
  viewStatus,
  typesAttachment,
}) => {

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropping, setDropping] = useState(false);

  const {
    withoutTypes,
    mode,
    isHistory,
  } = configuration;

  const {
    fileSize,
    fileName,
    updatedOn,
    updaterLabel,
    category,
    updating,
    deleting,
    renaming,
    moving,
    isFolder,
    objectName,
    currentSubfolder,
    getDownloadLink,
    getLatestDownloadLink,
    id,
  } = file;

  const {
    onSelect,
    onSubfolderChange,
    onShowHistory,
    onDrop,
    onRename,
    onEdit,
    onDelete,
  } = actions;

  const query = getCurrentURLParams(location.search);
  const isHighlighted =
    query['highlightedDocument'] &&
    (isHistory
      ? objectName === query['highlightedDocument']
      : query['highlightedDocument'].includes(id));

  viewStatus = {
    ...viewStatus,
    isHighlighted,
    dropping,
  };

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const getTypeAttachmentLabel = (attachmentTypeId) => {
    for (let i = 0; typesAttachment && i < typesAttachment.length; i++) {
      const type = typesAttachment[i];
      if (type.id === attachmentTypeId) {
        return type.label;
      }
    }
    return '';
  };

  const handleDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setDropping(false);
    if (onDrop && !viewStatus.isDragged) {
      onDrop();
    }
  };

  const onDragEnter = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setDropping(true);
  };

  const onDragLeave = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setDropping(false);
  };

  const onDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!dropping) {
      setDropping(true);
    }
  };

  const handleSelect = (event, file) => {
    event.stopPropagation();
    event.preventDefault();
    if (onSelect) {
      onSelect(file);
    }
  };

  const handleDownload = useCallback(async () => {
    try {
      if (Boolean(getDownloadLink)) {
        const href = getDownloadLink && getDownloadLink(file);
        const response = await createDownloadLink(href);
        const data = await response.data;
        downloadFile(data);
      }
    } catch (error) {
      dispatch(pushNotification("error", "error", `File downloaded failure:${getApiError(error)}`));
    }
  }, [getDownloadLink, file]);

  const openDirectory = (event) => {
    event.stopPropagation();
    event.preventDefault();
    return event.target.type !== 'checkbox' && isFolder && onSubfolderChange
      ? onSubfolderChange(currentSubfolder + fileName)
      : null;
  };

  const wrappedActions = {
    onDragEnter,
    onDragLeave,
    onDragOver,
    onSelect: onSelect ? handleSelect : null,
    onOpenDirectory: openDirectory,
    onClose: handleClose,
    onClick: handleClick,
    onDrop: handleDrop,
    onDownload: handleDownload,
  };

  const href = getDownloadLink && getDownloadLink(file);
  const latestLink = getLatestDownloadLink && getLatestDownloadLink(file);
  const fileSizeStr = nFormatter(fileSize, 1);

  const updateOnLabel = createUpdateOnLabel(updatedOn, updaterLabel);
  const categoryLabel = getTypeAttachmentLabel(category);

  const allActions = { ...actions, ...wrappedActions };

  let fileMenuEl;
  if (
    isFolder ||
    onSelect ||
    onShowHistory ||
    onRename ||
    onEdit ||
    onDelete ||
    href ||
    latestLink
  ) {
    let isModifying = updating ||
      deleting ||
      renaming ||
      moving;

    fileMenuEl = <FileMenu
      isModifying={isModifying}
      isSelected={viewStatus.isSelected}
      isHistory={isHistory}
      anchorEl={anchorEl}
      dropping={dropping}
      href={href}
      file={file}
      latestLink={latestLink}
      actions={allActions}/>;
  }

  if (mode === LIST_MODE) {
    return (
      <RowFile
        actions={allActions}
        fileMenuEl={fileMenuEl}
        viewStatus={viewStatus}
        withoutTypes={withoutTypes}
        file={{ ...file, href, fileSizeStr, categoryLabel, updateOnLabel }}
      />
    );
  }

  return (
    <CardFile
      actions={allActions}
      fileMenuEl={fileMenuEl}
      viewStatus={viewStatus}
      withoutTypes={withoutTypes}
      file={{ ...file, href, fileSizeStr, categoryLabel, updateOnLabel }}
    />
  );
};

export default withRouter(File);
