import React, { useEffect, useRef, useState } from 'react';

import APPCONFIG from 'constants/Config';
import { getApiError } from 'api/Error';
import { getLinks } from 'actions/Links';
import LinksGraph from 'components/Links/Graph/LinksGraph';

const AllLinksGraph = () => {
  const refreshTimeout = useRef(null);
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    return () => {
      stopRefresh();
    };
  }, []);

  const stopRefresh = () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout.current);
    }
    setLinks([]);
  }

  const refreshTabTimer = () => {
    refreshTab();
  };

  const refreshTab = async () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout.current);
    }

    refreshTimeout.current = setTimeout(() => {
      refreshTabTimer();
    }, APPCONFIG.REFRESH_TIMEOUT);

    refreshLinks();
  };

  const refreshLinks = async () => {
    setLoading(true);
    setError(null);
    try {
      const _links = (await getLinks()).data;
      setLinks(_links);
    } catch (e) {
      setError(getApiError(e));
    }
    setLoading(false);
  };

  return (
    <LinksGraph
      links={{ sourceLinks: links }}
      onOpen={refreshTabTimer}
      onClose={stopRefresh}
    />
  );
};

export default AllLinksGraph;
