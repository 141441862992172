import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../../Buttons/ButtonLoading";
import { reduxForm } from "redux-form";
import WorkflowFields from "./fields";

import { Grid, CircularProgress } from "@material-ui/core";
import { scrollToFirstError } from "api/Error";
import {WORKFLOW_STATUS_COMPLETED} from "constants/Workflows";

class WorkflowJBPMForm extends Component {
  render() {
    const { handleSubmit, onDelete, workflow, workflowLabel } = this.props;

    if (!workflow) {
      return null;
    }

    let errorContainer, content;
    if ((!workflow.form || !workflow.form.fields) && workflow.loading) {
      return (
        <div
          style={{ lineHeight: "36px", textAlign: "center", margin: "0 auto" }}
        >
          <CircularProgress size={15} style={{ verticalAlign: "middle" }} />
          <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
            Loading the {workflowLabel}
          </span>
        </div>
      );
    } else if (workflow.error) {
      errorContainer = <div className="error">{workflow.error}</div>;
    } else if (workflow.update_error) {
      errorContainer = <div className="error">{workflow.update_error}</div>;
    } else if (!workflow.form || !workflow.form.fields) {
      content = (
        <div
          style={{ lineHeight: "36px", textAlign: "center", margin: "0 auto" }}
        >
          <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
            No form provided
          </span>
        </div>
      );
    }

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        {content}
        <WorkflowFields form={workflow.form} />
        <Grid container justify="flex-end">
          {workflow.form &&
            workflow.form.fields &&
            workflow.status !== WORKFLOW_STATUS_COMPLETED && (
              <Grid item>
                <ButtonLoading
                  disabled={workflow.deleting}
                  loading={workflow.updating}
                  loadingTitle={"Updating the " + workflowLabel}
                  style={{ minWidth: "200px", margin: "20px 10px 0" }}
                  className="color-primary"
                  type="submit"
                  variant="outlined"
                  color="primary"
                >
                  {"Submit"}
                </ButtonLoading>
              </Grid>
            )}
        </Grid>
        {errorContainer}
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { workflow } = ownProps;
  if (!workflow) {
    return null;
  }
  return {
    initialValues: workflow.variables
  };
};

WorkflowJBPMForm = reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmitFail: scrollToFirstError
})(WorkflowJBPMForm);

WorkflowJBPMForm = connect(
  mapStateToProps,
  {}
)(WorkflowJBPMForm);

class FormContainer extends Component {
  render() {
    return <WorkflowJBPMForm {...this.props} />;
  }
}

// it's important in this case to note that mapStateToProps can have a second parameter, which is the props passed down
// from the calling (parent) component
function mapStateToProps2(state, ownProps) {
  const { workflow } = ownProps;
  return {
    form: "workflowjdpmform" + workflow.id
  };
}

export default connect(mapStateToProps2)(FormContainer);
