import React, { Component } from 'react';
import FormControl from './FormControl';

import { FormControlLabel, Checkbox } from '@material-ui/core';

class CheckboxField extends Component {
    render() {
        const { disabled, input, label } = this.props;
        const checked = input.value === true || input.value === 'true';
        return (
            <FormControl { ...this.props }>
                <FormControlLabel
                    control={
                        <Checkbox
                            {...input}
                            className="switch"
                            color="primary"
                            disabled={disabled}
                            checked={checked}
                            id={input.name}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    }
                    label={ label }
                />
            </FormControl>
        );
    }
}

export default CheckboxField;
