import React, { Component } from 'react';
import { withStyles, Step, StepButton } from "@material-ui/core";

class SubDirectoryStepper extends Component {
    state = {
        dropping: false
    };

    onDrop = (event) => {
        const { onDrop, droppable } = this.props;
        this.setState({
            dropping: false
        });
        if (onDrop && droppable) {
            onDrop();
        }
    };

    onDragEnter = (event) => {
        this.setState({
            dropping: true
        });
    };
    onDragLeave = (event) => {
        this.setState({
            dropping: false
        });
    };
    onDragOver = (event) => {
        const { dropping } = this.state;
        if (!dropping) {
            this.setState({
                dropping: true
            });
        }
    };

    render() {
        const { subfolderPath, onClick, isDragging, droppable, classes } = this.props;
        const { dropping } = this.state;

        return (
            <Step
                orientation="horizontal"
                onDragEnter={droppable && this.onDragEnter}
                onDragLeave={droppable && this.onDragLeave}
                onDragOver={droppable && this.onDragOver}
                onDrop={this.onDrop}
                dropping={(dropping && droppable)? "true": undefined}
                completed>
                <StepButton
                    onClick={onClick}
                    style={{
                        opacity: (isDragging && !droppable)? 0.3: 1,
                        margin: '-12px -16px',
                        padding: '12px 16px'
                    }}
                    icon={null}>
                    <span className={classes.text} style={{color: (dropping && isDragging && droppable)? "#ffc107": "inherit"}}>
                        { subfolderPath }
                    </span>
                </StepButton>
            </Step>
        )
    };
}

const styles = theme => ({
    text: {
        fontSize: 16
    }
});

export default withStyles(styles)(SubDirectoryStepper);