import React from "react";
import { SaveAlt } from "@material-ui/icons";
import ButtonLoading from "components/Buttons/ButtonLoading";

const OpenExportDrawerButton = ({ entityType, onOpenDrawer }) => {
  return (
    <ButtonLoading
      style={{ margin: "5px", alignItems: "flex-start" }}
      variant="outlined"
      color="primary"
      testid="exportEntity"
      onClick={onOpenDrawer}
      entityType={entityType}
    >
      <SaveAlt />
      Export
    </ButtonLoading>
  );
};
export default OpenExportDrawerButton;
