import React from 'react';

import {
  makeStyles,
} from '@material-ui/core';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';
import Tooltip from '@material-ui/core/Tooltip';

import { CANCELLED, DO_NOT_REWRITE, ERROR } from 'constants/FileUploadStatus';
import { createStatusLabel } from 'api/Uploads';
import Status from 'components/Uploads/Statuses/Status';

const useStyles = makeStyles(theme => ({
  warning: {
    fontSize: 48,
    color: theme.palette.warning.main,
  },
  error: {
    fontSize: 48,
    color: theme.palette.error.main,
  },
}));

const StaticStatus = ({ status, message }) => {

  const classes = useStyles();

  const renderStatus = () => {
    switch (status) {
      case DO_NOT_REWRITE:
        return ({
          title: 'Do not rewrite',
          primaryEl: <CancelSharpIcon className={classes.warning}/>,
        });

      case CANCELLED:
        return ({
          title: 'Cancelled',
          primaryEl: <CancelSharpIcon className={classes.warning}/>,
        });

      case ERROR:
        return ({
          title: message,
          primaryEl: <ErrorSharpIcon className={classes.error}/>,
        });
      default:
        return ({
          title: '',
          primaryEl: null,
        });
    }
  };

  const statusIcon = renderStatus();
  let label = createStatusLabel(null, statusIcon.title);

  if (!statusIcon.primaryEl) {
    return null;
  }

  return (
    <Tooltip title={statusIcon.title}>
      <span>
        <Status
          progress={null}
          variant={label.variant}
          primaryEl={statusIcon.primaryEl}
          secondaryEl={statusIcon.primaryEl}
        />
      </span>
    </Tooltip>
  );
};

export default StaticStatus;
