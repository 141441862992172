import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import AutoSuggestSelectField from "../Form/Fields/AutoSuggestSelectField";
import { SecurityLevelValidator } from "components/Form/Fields/FieldValidators";
import {getSortingByLabel} from "api/Sort";

class SecurityLevels extends Component {
  render() {
    const { me, multiple, withoutEmptyLevelWarning } = this.props;
    let style;
    const name = multiple ? "securityLevels" : "securityLevel";
    const label = multiple ? "Security levels" : "Security level";
    if (me.securityLevels.length < 1) {
      style = { display: "none" };
    }

    return (
      <div style={style}>
        <Field
          label={label}
          name={name}
          component={AutoSuggestSelectField}
          validate={[]}
          warn={withoutEmptyLevelWarning ? null : [SecurityLevelValidator]}
          multiple={multiple}
          withEmptyOpt={true}
          ref={name}
          options={me.securityLevels
            .sort(getSortingByLabel)
            .map(securityLevel => ({
              id: securityLevel.id,
              label: securityLevel.label
          }))}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me
  };
};
export default connect(
  mapStateToProps,
  {}
)(SecurityLevels);
