import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Collapse,
  Paper,
} from '@material-ui/core';

import { deleteFileGroupBy } from 'actions/Uploads';
import { CANCELLED, DO_NOT_REWRITE, END, ERROR } from 'constants/FileUploadStatus';
import OptimizedUploadGroupList from 'components/Uploads/OptimizedUploadGroupList';
import UploadHeader from 'components/Uploads/UploadHeader';
import ApproveDialog from 'components/Dialogs/ApproveDialog';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: '1em',
    right: '5em',
    maxHeight: 340,
    width: 350,
    zIndex: 100,
  },
  content: {
    width: '100%',
  },
}));

const initialUploadStatus = {
  allFiles: 0,
  cancelled: 0,
  errors: 0,
  uploaded: 0,
  skipped: 0,
};

const useUploadStatusHandler = () => {
  const [uploadStatus, setUploadStatus] = useState(initialUploadStatus);

  const handleUploadProcessed = (status) => {
    switch (status) {
      case END:
        setUploadStatus((uploadStatus) => {
          return { ...uploadStatus, uploaded: uploadStatus.uploaded + 1 };
        });
        break;
      case ERROR:
        setUploadStatus((uploadStatus) => {
          return { ...uploadStatus, errors: uploadStatus.errors + 1 };
        });
        break;
      case CANCELLED:
        setUploadStatus((uploadStatus) => {
          return { ...uploadStatus, cancelled: uploadStatus.cancelled + 1 };
        });
        break;
      case DO_NOT_REWRITE:
        setUploadStatus((uploadStatus) => {
          return { ...uploadStatus, skipped: uploadStatus.skipped + 1 };
        });
        break;
    }
  };

  const handleGroupsAdded = (groups) => {
    const initialValue = 0;
    const count = groups.reduce(
      (accumulator, currentValue) => accumulator + currentValue.taggedFiles.length,
      initialValue,
    );
    setUploadStatus((uploadStatus) => {
      return { ...uploadStatus, allFiles: count };
    });
  };

  const handleClear = () => {
    setUploadStatus(initialUploadStatus);
  };

  return {
    uploadStatus,
    handleClear,
    handleUploadProcessed,
    handleGroupsAdded,
  };
};

const UploadPanel = () => {

  const classes = useStyles();

  const dispatch = useDispatch();
  const loadedGroups = useSelector((state) => state.uploads.groups);

  const [isOpen, setIsOpen] = useState(true);
  const [isAllProcessed, setIsAllProcessed] = useState(false);
  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);
  const [groups, setGroups] = useState([]);

  const { uploadStatus, handleClear, handleUploadProcessed, handleGroupsAdded } = useUploadStatusHandler();

  useEffect(() => {
    if (loadedGroups && loadedGroups.length > 0) {
      setIsAllProcessed(false);
      setGroups((prevState) => [...prevState, ...loadedGroups]);
      dispatch(deleteFileGroupBy(loadedGroups.map(loadedGroup => loadedGroup.groupId)));
    }
  });

  useEffect(() => {
    handleGroupsAdded(groups);
  }, [groups]);

  const handleToggle = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    if (isAllProcessed) {
      clearPanel();
    } else {
      setIsCloseDialogOpen(true);
    }
  };

  const handleAllProcessed = () => {
    setIsAllProcessed(true);
  };

  const handleForceClose = () => {
    hideCloseDialog();
    clearPanel();
  };

  const hideCloseDialog = () => {
    setIsCloseDialogOpen(false);
  };

  const clearPanel = () => {
    setGroups([]);
    handleClear();
  };

  if (!groups || !groups.length) {
    return null;
  }

  return (
    <>
      <Paper
        className={classes.root}
        elevation={3}
      >
        <UploadHeader
          isUploading={!isAllProcessed}
          isOpen={isOpen}
          uploadStatus={uploadStatus}
          onToggle={handleToggle}
          onClose={handleClose}
        />
        <Collapse className={classes.content} in={isOpen}>
          <OptimizedUploadGroupList
            groups={groups}
            onUploadProcessed={handleUploadProcessed}
            onAllProcessed={handleAllProcessed}
          />
        </Collapse>
      </Paper>
      <ApproveDialog
        isOpen={isCloseDialogOpen}
        onApprove={handleForceClose}
        onCancel={hideCloseDialog}
        title={'The upload is being processed. Do you want to stop it?'}
      />
    </>
  );
};

export default UploadPanel;
