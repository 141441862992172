import React, {useEffect, useState} from "react";
import {evaluateWidget} from "actions/Widgets";
import TableWidget from "./TableWidget";
import PaginatedTableWidget from "./PaginatedTableWidget";
import ChartWidget from "./ChartWidget";
import PieChartWidget from "./PieChartWidget";
import MultiPieChartWidget from "./MultiPieChartWidget";
import HeatMapWidget from "./HeatMapWidget";
import FillWidgetVariablesFormDialog from "./FillWidgetVariablesFormDialog";
import AttributesFilter from "components/Attributes/filter";
import {SaveAlt} from "@material-ui/icons";
import PortfolioStatusVisualization from "components/Widget/PortfolioStatusVisualization";
import {useApiRequest} from "actions";
import {CircularProgress, Paper, Typography} from "@material-ui/core";
import TabCentered from "components/TabContainer/TabCentered";
import {makeStyles} from "@material-ui/core/styles";
import DownloadGeneratedFileIconButton from "components/Buttons/DownloadGeneratedFileIconButton";

const Widget = ({widget, hideVariablesButton}) => {
  if(!widget || !widget.id){
    return null;
  }
  const classes = useStyles();
  const [variables, setVariables] = useState( {});
  const [parameters, setParameters] = useState(widget.parameters? widget.parameters : {});
  const [evaluatedWidget, setEvaluatedWidget] = useState(null);

  const [evaluating, errorEvaluating, runEvaluation] = useApiRequest(
    (widgetId, variables, parameters) => evaluateWidget(widgetId, variables, parameters),
    returnedWidget => {
      setEvaluatedWidget(returnedWidget);
    }
  );

  useEffect(() => {
    runEvaluation(widget.id, variables, parameters);
  }, [parameters]);

  useEffect(() => {
    setVariables(getVariables());
    setParameters(widget.parameters);
  }, [widget.id]);

  useEffect(() => {
    if ((evaluatedWidget && !evaluatedWidget.results) &&
      !evaluating &&
      !errorEvaluating) {
      runEvaluation(widget.id, variables, parameters);
    }
  }, [widget, variables, parameters]);

  const getVariables = () => {
    let variables = {};
    for (
      let i = 0;
      widget.variables && i < widget.variables.length;
      i++
    ) {
      const variable = widget.variables[i];
      variables[variable.name] = variable.defaultValue;
    }
    return variables;
  };

  if(evaluating && evaluatedWidget && !evaluatedWidget.results){
    return <TabCentered>
      <div
        style={{
          width: "400px",
          lineHeight: "36px",
          textAlign: "center",
          margin: "20px 10px 0"
        }}
      >
        <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
        <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
                Loading the widget
              </span>
      </div>
    </TabCentered>
  }
  if(!evaluatedWidget || !evaluatedWidget.results){
    return null;
  }

  const {resultFormat, error, queryFormat} = evaluatedWidget;
  const widgetClass =
    !["PIE_CHART", "HEAT_MAP", "PORTFOLIO_STATUS_VISUALIZATION"].includes(resultFormat) ?
    "widget" : "";

  let component;
  switch (resultFormat) {
    case "TABLE":
      component = <TableWidget widget={evaluatedWidget}/>;
      break;
    case "PAGINATED_TABLE":
      component = (
        <PaginatedTableWidget
          widget={evaluatedWidget}
          onSetParameters={(newParameters) => setParameters(newParameters)}
        />
      );
      break;
    case "TIMESERIES":
      component = <ChartWidget widget={evaluatedWidget} maxPoints={1000}/>;
      break;
    case "PIE_CHART":
      component = <PieChartWidget widget={evaluatedWidget}/>;
      break;
    case "MULTI_PIE_CHART":
      component = <MultiPieChartWidget widget={evaluatedWidget}/>;
      break;
    case "HEAT_MAP":
      component = <HeatMapWidget widget={evaluatedWidget}/>;
      break;
    case "PORTFOLIO_STATUS_VISUALIZATION":
      component = <PortfolioStatusVisualization widget={evaluatedWidget}/>;
      break;
    default:
      console.log(evaluatedWidget);
      component = null;
  }

  const widgetExportGeneratorUrl = encodeURI(
      "/api/widgets/export?key=" +
      JSON.stringify({
        widgetId: widget.id,
        variables: variables,
        parameters: widget.parameters
      })
  );

  const content = (
    <div className={widgetClass}>
        {
          !hideVariablesButton && (
              <div style={{position: "absolute", top: 10, right: 24}}>
                <FillWidgetVariablesFormDialog
                    initialValues={variables}
                    widget={evaluatedWidget}
                    onSubmit={(form) => setVariables(form)}
                />

                {
                  queryFormat === "SEARCH" && ["TABLE", "PAGINATED_TABLE"].includes(resultFormat) ?
                      <AttributesFilter/> :
                      null
                }

                {
                  widget.exportable && (
                      <DownloadGeneratedFileIconButton
                          fileGeneratorUrl={widgetExportGeneratorUrl}
                          className="color-primary"

                      >
                        <SaveAlt/>
                      </DownloadGeneratedFileIconButton>
                  )
                }

              </div>
          )
        }
        <div style={{ margin: "10px 24px" }} className="error">
          {error || errorEvaluating}
        </div>
        {component}
      </div>
  );

  if (hideVariablesButton) {
    return content;
  } else {
    return (<Paper className={classes.paper}>
      <div style={{margin: "10px 24px 0 24px"}}>
        <Typography variant="h6">{widget.name}</Typography>
      </div>
      {content}
    </Paper>);
  }
};

const useStyles = makeStyles(theme => ({
  paper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
}));

export default Widget;