import React from "react";

import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";

const BatchSelect = ({ isSelectAll, onClick }) => {
  return (
    <MenuItem key="batchSelect" role={undefined} dense button onClick={onClick}>
      <Checkbox checked={isSelectAll} tabIndex={-1} disableRipple />
      <ListItemText primary="All" />
    </MenuItem>
  );
};

export default BatchSelect;
