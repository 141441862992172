import React from 'react';

import PropTypes from 'prop-types';
import { Avatar, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from 'components/Buttons/IconButton';

const ImgIconButtonStyle = {
  width: '60px',
  height: '60px',
};

const useStyles = makeStyles(() => ({
  avatar: {
    width: '36px',
    height: '36px',
  },
}));

const UserAvatar = ({ displayName, onClick }) => {

  const classes = useStyles();

  const initials = displayName ? displayName.substring(0, 1) : '';

  return (
    <Tooltip title={displayName}>
      <IconButton
        testid="menuheader"
        onClick={onClick}
        className="md-button header-btn"
        style={ImgIconButtonStyle}
      >
        <Avatar
          color="primary"
          className={classes.avatar}
        >
          {initials}
        </Avatar>
      </IconButton>
    </Tooltip>
  );
};

UserAvatar.propTypes = {
  displayName: PropTypes.string,
  onClick: PropTypes.func,
};

export default UserAvatar;
