import {
  GET_FEATURES,
  LOADING_FEATURES,
  END_LOADING_FEATURES,
  FEATURES_ERROR
} from "../../constants/ActionTypes";
import axios from "axios";

export function getFeatures() {
  return function(dispatch) {
    dispatch({
      type: LOADING_FEATURES
    });

    axios
      .get("/api/features", {
        withCredentials: true
      })
      .then(response => {
        let features = response.data;

        dispatch({
          type: GET_FEATURES,
          features: features
        });
        dispatch({
          type: END_LOADING_FEATURES
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: FEATURES_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_FEATURES
        });
      });
  };
}
