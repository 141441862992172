import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Link } from "react-router-dom";
import NavLeftList from "./NavLeftList";
import NavRightList from "./NavRightList";
import { APP_OVERVIEW_PATH } from "../../constants/Routes";
import { isAuthorized } from "../../api/Authorities";

class Header extends React.Component {
  componentDidMount() {
    const sidebarToggler = this.sidebarBtn;
    const $sidebarToggler = $(sidebarToggler);
    const $body = $("#body");

    $sidebarToggler.on("click", e => {
      // _sidebar.scss, _page-container.scss
      $body.toggleClass("sidebar-mobile-open");
    });
  }

  render() {
    const { isFixedHeader, colorOption, me } = this.props;
    if (!me || !me.id || !isAuthorized(me, [])) {
      return null;
    }
    const ifrs9HeaderStyle = {
      display: "inline-block",
      position: "relative",
      marginTop: "-5px"
    };
    return (
      <section className="app-header" style={{overflow: "hidden"}}>
        <div
          className={classnames("app-header-inner", {
            "bg-color-light":
              ["11", "12", "13", "14", "15", "16", "21"].indexOf(colorOption) >=
              0,
            "bg-color-dark": colorOption === "31",
            "bg-color-primary": ["22", "32"].indexOf(colorOption) >= 0,
            "bg-color-success": ["23", "33"].indexOf(colorOption) >= 0,
            "bg-color-info": ["24", "34"].indexOf(colorOption) >= 0,
            "bg-color-warning": ["25", "35"].indexOf(colorOption) >= 0,
            "bg-color-danger": ["26", "36"].indexOf(colorOption) >= 0
          })}
        >
          <div
            className="top-nav-left"
            style={{
              lineHeight: "55px",
              paddingRight: "15px",
              paddingLeft: "15px"
            }}
          >
            <ul className="list-unstyled list-inline">
              <Link to={APP_OVERVIEW_PATH} className="list-inline-item">
                <div style={ifrs9HeaderStyle}>
                  <div className="">
                    <img
                      style={{ width: "120px" }}
                      src={"/assets/images/mgs_logo/logo.png"}
                    />
                  </div>
                </div>
              </Link>
            </ul>
          </div>
          <div className="top-nav-left">
            <NavLeftList />
          </div>
          <div className="top-nav-right">
            <NavRightList />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  me: state.auth.me,
  colorOption: state.settings.colorOption,
  isFixedHeader: state.settings.isFixedHeader
});

export default connect(mapStateToProps)(Header);
