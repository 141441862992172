import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ButtonLoading from "../Buttons/ButtonLoading";
import UpdateSecurityLevelDialogForm from "./UpdateSecurityLevelDialogForm";
import { ROLE_SECURITYLEVEL_EDIT } from "../../constants/Authorities";
import { updateSecurityLevel } from "../../actions/SecurityLevel";
import { Security } from "@material-ui/icons";
import { isEntityActive } from 'api/Entity';

class SecurityLevels extends Component {
  state = {
    openDialog: false
  };
  updateSecurityLevel = () => {
    this.setState({
      openDialog: true
    });
  };
  handleSubmit = form => {
    const { entity, me, updateSecurityLevel } = this.props;
    if (!form.securityLevel) {
      updateSecurityLevel(entity, null);
    } else {
      for (let i = 0; me.securityLevels && i < me.securityLevels.length; i++) {
        const securityLevel = me.securityLevels[i];
        if (securityLevel.id === form.securityLevel) {
          updateSecurityLevel(entity, securityLevel);
          break;
        }
      }
    }
    this.closeDialog();
  };
  closeDialog = () => {
    this.setState({
      openDialog: false
    });
  };

  render() {
    const { entity, me } = this.props;
    const { openDialog } = this.state;
    if (!entity || !entity.id || me.securityLevels.length < 1) {
      return null;
    }

    let securityLevel;
    if (entity.securityLevels && entity.securityLevels.length > 0) {
      securityLevel = entity.securityLevels[0];
    }
    const isModifiable = isEntityActive(entity);

    return (
      <React.Fragment>
        <ButtonLoading
          tooltipPlacement="left"
          tooltipTitle="Edit the security level"
          loading={entity.updating_security_level}
          loadingTitle="Updating the security level"
          loadingSize={22}
          style={{
            width: "100%",
            justifyContent: "flex-start",
            textAlign: "left"
          }}
          authoritiesRequired={[ROLE_SECURITYLEVEL_EDIT]}
          onClick={this.updateSecurityLevel}
          color="primary"
        >
          <Security style={{ marginRight: 15 }} />
          Edit the security level
        </ButtonLoading>
        <div className="error">{entity.error_security_level}</div>
        <UpdateSecurityLevelDialogForm
          open={openDialog}
          initialValues={{
            securityLevel: securityLevel && securityLevel.id
          }}
          isModifiable={isModifiable}
          handleClose={this.closeDialog}
          onSubmit={this.handleSubmit}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me,
    securityLevels: state.securityLevels.list
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    { updateSecurityLevel }
  )(SecurityLevels)
);
