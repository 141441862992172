import React, { Component } from "react";
import FormControl from "./FormControl";
import IconButton from "../../Buttons/IconButton";
import { VisibilityOff, Visibility } from "@material-ui/icons";

import { Input, InputAdornment, InputLabel } from "@material-ui/core";

class PasswordField extends Component {
  state = {
    showPassword: false
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPasssword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  render() {
    const {
      disabled,
      input,
      label,
      placeholder,
      submitOnEnter,
      autoComplete,
      style
    } = this.props;
    const { showPassword } = this.state;
    return (
      <FormControl {...this.props}>
        {label ? (
          <InputLabel
            formcontrolclasses={{
              focused: "label-focused"
            }}
            htmlFor={input.name}
          >
            {label}
          </InputLabel>
        ) : (
          ""
        )}
        <Input
          style={style}
          className="input"
          color="primary"
          onChange={input.onChange}
          onBlur={input.onBlur}
          onDragStart={input.onDragStart}
          onDrop={input.onDrop}
          onFocus={input.onFocus}
          disabled={disabled}
          id={input.name}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onKeyPress={e => {
            if (!submitOnEnter && e.key === "Enter") {
              e.preventDefault();
            }
          }}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={this.handleClickShowPasssword}
                onMouseDown={this.handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    );
  }
}
export default PasswordField;
