import React, { Component } from "react";
import ButtonAbstract from "../RestrictedComponent";
import { Button as ButtonMaterial } from "@material-ui/core";

class Button extends Component {
  render() {
    return (
      <ButtonAbstract
        componentButton={ButtonMaterial}
        {...this.props}
        focusRipple={false}
      />
    );
  }
}

export default Button;
