import {
  GET_REST,
  EXECUTING_REST,
  END_EXECUTING_REST,
  ERROR_EXECUTING_REST,
  GET_SQL,
  EXECUTING_SQL,
  END_EXECUTING_SQL,
  ERROR_EXECUTING_SQL
} from "../../constants/ActionTypes";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_REST:
      return {
        ...state,
        data_rest: action.data,
        responseContentType: action.responseContentType,
        error_rest: false
      };
    case EXECUTING_REST:
      return {
        ...state,
        executing_rest: true,
        error_rest: undefined
      };
    case ERROR_EXECUTING_REST:
      return {
        ...state,
        data_rest: action.data,
        executing_rest: false,
        error_rest: true
      };
    case END_EXECUTING_REST:
      return { ...state, executing_rest: false };

    case GET_SQL:
      return {
        ...state,
        result_sql: action.result,
        columns_sql: action.columns,
        error_sql: undefined
      };
    case EXECUTING_SQL:
      return {
        ...state,
        executing_sql: true,
        error_sql: undefined
      };
    case END_EXECUTING_SQL:
      return { ...state, executing_sql: false };
    case ERROR_EXECUTING_SQL:
      return { ...state, error_sql: action.error };

    default:
      return state;
  }
}
