import React, { Component } from "react";
import PropTypes from "prop-types";

function TabCentered({ style, children, className }) {
  return (
    <div
      className={className}
      style={{
        width: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        ...style
      }}
    >
      {children}
    </div>
  );
}

TabCentered.propTypes = {
  children: PropTypes.node.isRequired
};

export default TabCentered;
