import {
  TASK_GROUP_ACTIVE,
  TASK_GROUP_FAILED,
  TASK_GROUP_INITIAL,
  TASK_GROUP_TERMINAL
} from "constants/Task";
import React from "react";
import Chip from "@material-ui/core/Chip";

const TaskStatus = ({taskStatus}) => {
  if(!taskStatus){
    return null;
  }

  let statusBgColor;
  let statusColor;
  if(TASK_GROUP_INITIAL.includes(taskStatus)){
    statusColor = "white";
    statusBgColor = "#607d8b";
  }
  if(TASK_GROUP_TERMINAL.includes(taskStatus)){
    statusColor = "black";
    statusBgColor = "#e0e0e0";
  }
  if(TASK_GROUP_ACTIVE.includes(taskStatus)){
    statusColor = "black";
    statusBgColor = "#ffc107";
  }
  if(TASK_GROUP_FAILED.includes(taskStatus)){
    statusColor = "white";
    statusBgColor = "#b40721";
  }

  return <Chip
    size="small"
    label={taskStatus}
    style={{width: "100px", marginRight:"10px", backgroundColor:statusBgColor, color:statusColor}}
  />
};
export default TaskStatus;