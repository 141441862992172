import React, { Component } from "react";
import Button from "../Buttons/Button";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { RequiredValidator } from "components/Form/Fields/FieldValidators";
import InputField from "components/Form/Fields/InputField";
import { isAuthorized } from "../../api/Authorities";
import { ROLE_SYSTEM_ADMINISTRATION } from "../../constants/Authorities";
import { DialogContent, Dialog, DialogActions } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";

class UploadLicenseForm extends Component {
  render() {
    const {
      handleCancel,
      handleSubmit,
      open,
      me,
      error_setting_license
    } = this.props;

    if (!isAuthorized(me, [ROLE_SYSTEM_ADMINISTRATION])) {
      return null;
    }

    return (
      <Dialog maxWidth={false} onClose={handleCancel} open={Boolean(open)}>
        <form onSubmit={handleSubmit} style={{ padding: "0 20px" }}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleCancel}
          >
            Update license
          </CloseableDialogTitle>
          <DialogContent>
            <div style={{ marginBottom: 10, width: "600px" }}>
              <Field
                label={"New license"}
                name="newLicense"
                component={InputField}
                multiline
                validate={[RequiredValidator]}
                ref="newLicense"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {"Update license"}
            </Button>
            {error_setting_license && (
              <div className="error">{error_setting_license}</div>
            )}
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

UploadLicenseForm = reduxForm({
  form: "uploadlicenseform",
  onSubmitFail: scrollToFirstError
})(UploadLicenseForm);

const mapStateToProps = (state, ownProps) => {
  return {
    error_setting_license: state.license.error_setting_license,
    me: state.auth.me
  };
};
export default connect(
  mapStateToProps,
  {}
)(UploadLicenseForm);
