import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  AUTH_CLEAR_ERROR,
  LOGINING,
  END_LOGINING,
  GETTING_USER,
  END_GETTING_USER,
  LOADING_USER_TOKENS,
  END_LOADING_USER_TOKENS,
  ERROR_USER_TOKENS,
  GET_USER_TOKENS,
  CREATING_USER_TOKEN,
  END_CREATING_USER_TOKEN,
  ERROR_CREATING_USER_TOKEN,
  CREATE_USER_TOKEN,
  REVOKING_USER_TOKEN,
  END_REVOKING_USER_TOKEN,
  ERROR_REVOKING_USER_TOKEN,
  REVOKE_USER_TOKEN,
  GET_USER_PREFERENCES,
  UPDATING_USER_PREFERENCES,
  END_UPDATING_USER_PREFERENCES,
  ERROR_UPDATING_USER_PREFERENCES
} from "../constants/ActionTypes";
import { goThroughList } from "../api/List";

export default function(state = {}, action) {
  let result;

  switch (action.type) {
    case AUTH_USER:
      let me;
      if (state.me) {
        me = Object.assign(state.me, action.me);
      } else {
        me = action.me;
      }
      me = Object.assign({}, me);
      return { ...state, me: me };
    case UNAUTH_USER:
      return {
        ...state,
        me: undefined,
        lastUserIdConnected: state.me && state.me.id
      };
    case GETTING_USER:
      return { ...state, loading: true };
    case END_GETTING_USER:
      return { ...state, loading: false };

    case LOGINING:
      return { ...state, logining: true, error: undefined };
    case END_LOGINING:
      return { ...state, logining: false };
    case AUTH_ERROR:
      return { ...state, error: action.payload };
    case AUTH_CLEAR_ERROR:
      return { ...state, error: undefined };

    case LOADING_USER_TOKENS:
      return { ...state, loading_tokens: true, error_tokens: undefined };
    case END_LOADING_USER_TOKENS:
      return { ...state, loading_tokens: false };
    case ERROR_USER_TOKENS:
      return { ...state, error_tokens: action.error };
    case GET_USER_TOKENS:
      return { ...state, tokens: action.tokens };

    case CREATING_USER_TOKEN:
      return { ...state, creating_token: true, error_create_token: undefined };
    case END_CREATING_USER_TOKEN:
      return { ...state, creating_token: false };
    case ERROR_CREATING_USER_TOKEN:
      return { ...state, error_create_token: action.error };
    case CREATE_USER_TOKEN:
      if (!state.tokens) {
        state.tokens = [];
      }
      return {
        ...state,
        token_created: action.token,
        tokens: [...state.tokens, action.token]
      };

    case REVOKING_USER_TOKEN:
      result = goThroughList(state.tokens, null, action.token, token => {
        return {
          ...token,
          revoking: true,
          error: undefined
        };
      });
      return {
        ...state,
        tokens: result.list
      };
    case END_REVOKING_USER_TOKEN:
      result = goThroughList(state.tokens, null, action.token, token => {
        return {
          ...token,
          revoking: false
        };
      });
      return {
        ...state,
        tokens: result.list
      };
    case ERROR_REVOKING_USER_TOKEN:
      result = goThroughList(state.tokens, null, action.token, token => {
        return {
          ...token,
          error: action.error
        };
      });
      return {
        ...state,
        tokens: result.list
      };
    case REVOKE_USER_TOKEN:
      result = goThroughList(state.tokens, null, action.token, token => {
        return null;
      });
      return {
        ...state,
        tokens: result.list
      };

    case GET_USER_PREFERENCES:
      return { ...state, preferences: action.preferences };
    case UPDATING_USER_PREFERENCES:
      return {
        ...state,
        updating_preferences: true,
        error_updating_preferences: undefined
      };
    case END_UPDATING_USER_PREFERENCES:
      return { ...state, updating_preferences: false };
    case ERROR_UPDATING_USER_PREFERENCES:
      return { ...state, error_updating_preferences: action.error };
    default:
      return state;
  }
}
