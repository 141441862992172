import React, { useEffect } from 'react';

import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { DialogContent, Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";

import { initializeForm } from "../../actions";
import { NonEmptyStringValidator, RequiredValidator } from '../Form/Fields/FieldValidators';
import { scrollToFirstError } from 'api/Error';
import Button from "../Buttons/Button";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import InputField from "components/Form/Fields/InputField";
import Roles from "components/Roles/input";
import SecurityLevels from "components/SecurityLevels/input";

const FORM_NAME = "updatefolderform";

let UpdateFolderDialogForm = ({ handleSubmit, folder, onClose, initializeForm, open, classes }) => {

  useEffect(() => {
    if (open) {
      initializeForm(FORM_NAME, folder, true);
    }
  }, [open]);

  return (
    <Dialog onClose={onClose} open={Boolean(open)}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
          Edit folder
        </CloseableDialogTitle>
        <DialogContent>
          <div className={classes.field}>
            <Field
              label={"Name"}
              name="name"
              component={InputField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
            />
          </div>
          <div className={classes.field}>
            <Field
              label={"Description"}
              name="description"
              multiline
              component={InputField}
              validate={[RequiredValidator]}
            />
          </div>
          <div className={classes.field}>
            <SecurityLevels multiple />
          </div>
          <div className={classes.field}>
            <Roles multiple />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="color-primary"
            type="submit"
            variant="outlined"
            color="primary"
          >
            {"Update the folder"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const styles = () => ({
  field: {
    marginBottom: 10,
    width: "300px"
  },
  form: {
    padding: "0 20px"
  }
});

const mapStateToProps = (state) => {
  return {};
};

UpdateFolderDialogForm = reduxForm({
    form: FORM_NAME,
    shouldWarn: () => { return true; },
    onSubmitFail: scrollToFirstError
  }
)(UpdateFolderDialogForm);

UpdateFolderDialogForm = connect(
  mapStateToProps,
  { initializeForm }
)(UpdateFolderDialogForm);

export default withStyles(styles)(UpdateFolderDialogForm);
