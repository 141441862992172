import {Dialog, DialogActions, DialogContent, Grid, Typography} from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import React, {Component} from "react";
import ButtonLoading from "components/Buttons/ButtonLoading";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {InsertChartOutlined } from "@material-ui/icons";
import StorageIcon from "@material-ui/icons/Storage";
import ForwardIcon from "@material-ui/icons/Forward";
import {connect} from "react-redux";
import { syncDownContainer, syncUpContainer} from "actions/Containers";

class AwsSyncDialog extends Component {

    state = {
        syncAction: "UP"
    };

    updateSyncAction = (event, action) => {
        if (action !== null) {
            this.setState({syncAction: action});
        }
    }

    confirmSynchronization = (containerToSync, syncAction) => {
        const {closeSynchronizeDialog, syncUpContainer, syncDownContainer, isAdmin } = this.props;
        if (syncAction === "UP") {
            syncUpContainer(containerToSync, false, isAdmin);
        }

        if (syncAction === "DOWN") {
            syncDownContainer(containerToSync, isAdmin);
        }

        closeSynchronizeDialog();
    };

    render() {
        const { syncAction } = this.state;
        const { closeSynchronizeDialog, containerToSync, syncDialogOpen } = this.props;

        return (
            <Dialog maxWidth="sm" fullWidth onClose={closeSynchronizeDialog} open={syncDialogOpen}>
                <CloseableDialogTitle id="simple-dialog-title" handleClose={closeSynchronizeDialog}>
                    Synchronize files between Datalab and Data hub
                </CloseableDialogTitle>
                <DialogContent>
                    <Grid>
                        <Grid container direction="row">
                            <Typography component="p" align="center">
                                Choose, whether you want to propagate the changes made in your Datalab environment into Data hub, or vice versa.
                            </Typography>
                        </Grid>
                        <br/>
                        <Grid container direction="row" alignItems="center" justify="center">
                            <ToggleButtonGroup
                                value={syncAction}
                                exclusive
                                onChange={(event, value) => this.updateSyncAction(event, value)}
                                aria-label="Synchronization action"
                                style={{margin: "10px"}}
                                size="large"
                            >
                                <ToggleButton value="UP">
                                    Update Data hub
                                </ToggleButton>
                                <ToggleButton value="DOWN">
                                    Update Datalab
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid container direction="row">
                            {syncAction === "UP" &&
                            <React.Fragment>
                                <Grid container alignItems="center" justify="center" style={{margin: "4%"}}>
                                    <InsertChartOutlined fontSize="large"/>
                                    <ForwardIcon fontSize="small"/>
                                    <StorageIcon fontSize="large"/>
                                </Grid>
                                <Typography component="p" color="textSecondary" align="center">
                                    Updating Data hub will update Data hub files based on changes made in this Datalab
                                    environment.
                                </Typography>
                            </React.Fragment>
                            }
                        </Grid>
                        <Grid container direction="row">
                            {syncAction === "DOWN" &&
                            <React.Fragment>
                                <Grid container alignItems="center" justify="center" style={{margin: "4%"}}>
                                    <StorageIcon fontSize="large"/>
                                    <ForwardIcon fontSize="small"/>
                                    <InsertChartOutlined fontSize="large"/>
                                </Grid>
                                <Typography component="p" color="textSecondary" align="center">
                                    Updating the Datalab will make the files in the Datalab environment up-to-date with
                                    Data hub.
                                </Typography>
                            </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {containerToSync && (
                        <React.Fragment>
                            <ButtonLoading
                                onClick={closeSynchronizeDialog}
                                color="primary"
                                autoFocus
                            >
                                Cancel
                            </ButtonLoading>
                            <ButtonLoading
                                loadingTitle="Synchronizing"
                                onClick={ () => this.confirmSynchronization(containerToSync, syncAction) }
                                color="primary"
                            >
                                Synchronize files
                            </ButtonLoading>
                        </React.Fragment>
                    )}
                </DialogActions>
            </Dialog>
        );
    }


}

export default connect( (state, ownProps) => {return {}}, { syncUpContainer, syncDownContainer } )(AwsSyncDialog);