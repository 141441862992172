import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Tab from "../TabContainer/Tab";
import { Delete, Edit, ExpandMore, MoreVert } from "@material-ui/icons";
import {
  updateDroolsRule,
  getDroolsRuleLogs,
  updateDroolsRuleName,
  deleteDroolsRule
} from "actions/Drools";
import LogsList from "components/Drools/LogsList";
import CodeEditor from "components/Drools/CodeEditor";
import RuleExecution from "components/Drools/RuleExecution";
import ConfirmDeleteDrulesDialogForm from "components/Drools/ConfirmDeleteDrulesDialogForm";
import moment from "moment";
import EditableDroolsHeading from "components/Drools/EditableDroolsHeading";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import {
  CircularProgress,
  withStyles,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  AppBar,
  Tabs,
  Grid,
  Menu
} from "@material-ui/core";
import MenuItem from "components/Buttons/MenuItem";
import APPCONFIG from "constants/Config";

class DroolsRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      expanded: false,
      openDiagram: false,
      openConfirmDeleteDroolsDialog: false,
      anchorEl: null,
      openRenamingDialog: false
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  onChange = (event, expanded) => {
    this.setState({ expanded });
    if (expanded) {
      const { rule, getDroolsRuleLogs } = this.props;
      getDroolsRuleLogs(rule);
    }
  };
  onSaveCode = code => {
    const { rule, updateDroolsRule } = this.props;
    updateDroolsRule(rule, code);
  };

  handleRenaming = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      openRenamingDialog: true,
      anchorEl: null
    });
  };

  handleRenameUpdate = changedName => {
    const { rule, updateDroolsRuleName } = this.props;
    this.setState({
      openRenamingDialog: false
    });
    updateDroolsRuleName(rule.id, changedName);
  };

  handleCloseRenaming = () => {
    this.setState({
      openRenamingDialog: false
    });
  };

  handleOpenConfirmDeleteDroolsDialog = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      openConfirmDeleteDroolsDialog: true,
      anchorEl: null
    });
  };
  handleCloseConfirmDeleteDroolsDialog = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      openConfirmDeleteDroolsDialog: false
    });
  };
  handleConfirmDeleteDroolsDialog = event => {
    event.stopPropagation();
    event.preventDefault();
    const { deleteDroolsRule, rule } = this.props;
    deleteDroolsRule(rule);
    this.setState({
      openConfirmDeleteDroolsDialog: false
    });
  };

  handleOpenMenu = e => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };
  handleCloseMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { classes, rule, getDroolsRuleLogs } = this.props;
    const {
      value,
      expanded,
      anchorEl,
      openRenamingDialog,
      openConfirmDeleteDroolsDialog
    } = this.state;
    const {
      logs,
      loading_logs,
      error_logs,
      lastModifiedOn,
      lastModifiedBy,
      updating,
      renaming,
      deleting
    } = rule;
    let optionsMenu = (
      <Grid container alignItems="center" justify="flex-end" style={{}}>
        <Grid item>
          <IconButtonLoading
            size="small"
            loading={renaming}
            onClick={this.handleOpenMenu}
          >
            {updating || renaming || deleting ? (
              <CircularProgress size={20} />
            ) : (
              <MoreVert />
            )}
          </IconButtonLoading>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem onClick={this.handleRenaming}>Rename</MenuItem>
            <MenuItem onClick={this.handleOpenConfirmDeleteDroolsDialog}>
              Delete
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    );

    return (
      <ExpansionPanel key={rule.id} onChange={this.onChange} testid="drool">
        <ExpansionPanelSummary testid="droolpanel" expandIcon={<ExpandMore />}>
          <div className={classes.container}>
            <div className={classes.headingContainer}>
              <Typography className={classes.heading}>
                <EditableDroolsHeading
                  rule={rule}
                  openRenamingDialog={openRenamingDialog}
                  handleRenameUpdate={this.handleRenameUpdate}
                  handleCloseRenaming={this.handleCloseRenaming}
                />
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {lastModifiedOn
                  ? "Last modification at " + moment(lastModifiedOn).format(APPCONFIG.dateTimeFormat)
                  : ""}
                {lastModifiedBy ? " by " + lastModifiedBy.displayName : ""}
              </Typography>
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <div style={{ width: "100%", flex: 1 }}>
            <AppBar color="default" position="static">
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Logs" testid="droollogs" />
                <Tab label="Code" testid="droolcode" />
                <Tab label="Execution" testid="droolexecution" />
                {optionsMenu}
              </Tabs>
            </AppBar>
            {value === 0 && (
              <LogsList
                onRefresh={(firstResult, maxResultCount) => {
                  if (expanded && value === 0) {
                    getDroolsRuleLogs(rule, firstResult, maxResultCount);
                  }
                }}
                logs={logs}
                loading={loading_logs}
                error={error_logs}
              />
            )}
            {value === 1 && <CodeEditor rule={rule} onSave={this.onSaveCode} />}
            {value === 2 && <RuleExecution rule={rule} />}
          </div>
        </ExpansionPanelDetails>
        <ConfirmDeleteDrulesDialogForm
          open={openConfirmDeleteDroolsDialog}
          handleCancel={this.handleCloseConfirmDeleteDroolsDialog}
          handleOk={this.handleConfirmDeleteDroolsDialog}
        />
      </ExpansionPanel>
    );
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1
  },
  headingContainer: {
    flex: 0.5
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    paddingRight: 50
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row"
  },
  statusLabel: {
    lineHeight: 2,
    marginRight: 5
  },
  statusValue: {}
});

DroolsRule.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      getDroolsRuleLogs,
      updateDroolsRule,
      updateDroolsRuleName,
      deleteDroolsRule
    }
  )(DroolsRule)
);
