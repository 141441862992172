import React, { useState } from "react";
import {
  DialogContent,
  Typography,
  Dialog,
  DialogActions
} from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import MenuItem from "components/Buttons/MenuItem";

const MenuInfo = ({ workflow, onClick }) => {
  const [dialogOpened, setOpenDialog] = useState(false);

  const openDialog = () => {
    setOpenDialog(true);
    if (onClick) {
      onClick();
    }
  };
  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <MenuItem onClick={openDialog} testid="openWorkflowInfo">
        More info
      </MenuItem>
      <Dialog onClose={closeDialog} open={dialogOpened} fullWidth maxWidth="sm">
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={closeDialog}
        >
          {workflow.name}
        </CloseableDialogTitle>
        <DialogContent>
          <Typography>ID: {workflow.id}</Typography>
          <Typography>
            Process instance ID: {workflow.processInstanceId}
          </Typography>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </React.Fragment>
  );
};
export default MenuInfo;
