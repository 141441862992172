import React, { Component } from "react";
import { connect } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions/index";
import Dialog from "@material-ui/core/Dialog/index";
import { Warning } from "@material-ui/icons";
import { Field, reduxForm } from "redux-form";
import ButtonLoading from "components/Buttons/ButtonLoading";
import {
  Typography,
  withStyles,
  DialogContent,
  TextField,
  InputAdornment,
  Grid
} from "@material-ui/core";
import IconButton from "components/Buttons/IconButton";
import { FileCopy } from "@material-ui/icons";
import { openTokenDialog, closeTokenDialog } from "actions/index";
import { pushNotification } from "actions/notifications";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import InputField from "components/Form/Fields/InputField";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import {
  NonEmptyStringValidator,
  RequiredValidator,
  OnlyIntegerValidator
} from "components/Form/Fields/FieldValidators";
import { scrollToFirstError } from "../../api/Error";

class TokenInfoDialog extends Component {
  handleCopy = () => {
    const { pushNotification, token } = this.props;
    navigator.clipboard.writeText(token.token).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
        pushNotification("check", "success", "Token copy to clipboard.");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  render() {
    const { onClose, token } = this.props;

    return (
      <React.Fragment>
        <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
          Token created
        </CloseableDialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item>
              <Warning
                color="inherit"
                style={{ marginRight: 5, color: "#c79100" }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle2"
                color="inherit"
                style={{ color: "#c79100" }}
              >
                Be sure to copy the token now. This is the only time you'll be
                able to see it unencrypted.
              </Typography>
            </Grid>
          </Grid>
          <TextField
            style={{ width: "100%", marginTop: 20, minWidth: 400 }}
            variant="outlined"
            type={"text"}
            label="Token"
            value={token.token}
            onChange={null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Copy GIT repository link"
                    onClick={this.handleCopy}
                  >
                    <FileCopy />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <ButtonLoading
            testid="submittoken"
            className="color-primary"
            variant="outlined"
            onClick={this.handleCopy}
            color="primary"
          >
            {"Copy the token"}
          </ButtonLoading>
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

TokenInfoDialog = connect(
  mapStateToProps,
  { pushNotification }
)(TokenInfoDialog);

class CreateTokenForm extends Component {
  render() {
    const {
      handleSubmit,
      error_creating,
      creating,
      classes,
      onClose,
      withUserInput
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
          Create a token
        </CloseableDialogTitle>
        <DialogContent>
          <div className={classes.field}>
            <Field
              label={"Name"}
              name="name"
              component={InputField}
              validate={[]}
              withTimeFormatting
              ref="name"
            />
          </div>
          <div className={classes.field}>
            <Typography variant="subtitle2">Expires in</Typography>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Field
                  className={classes.expirationField}
                  name="expirationDays"
                  component={InputField}
                  validate={[OnlyIntegerValidator]}
                  endAdornment={
                    <InputAdornment position="end">days</InputAdornment>
                  }
                  ref="expirationDays"
                />
              </Grid>
              <Grid item>
                <Field
                  name="expirationHours"
                  component={InputField}
                  className={classes.expirationField}
                  validate={[OnlyIntegerValidator]}
                  endAdornment={
                    <InputAdornment position="end">hours</InputAdornment>
                  }
                  ref="expirationHours"
                />
              </Grid>
              <Grid item>
                <Field
                  name="expirationMinutes"
                  component={InputField}
                  className={classes.expirationField}
                  validate={[OnlyIntegerValidator]}
                  endAdornment={
                    <InputAdornment position="end">minutes</InputAdornment>
                  }
                  ref="expirationMinutes"
                />
              </Grid>
            </Grid>
          </div>
          {withUserInput ? (
            <div className={classes.field}>
              <Field
                name="userId"
                component={AutoSuggestSelectField}
                validate={[RequiredValidator, NonEmptyStringValidator]}
                apiUrl="/api/users/lookup"
                maxResultNumber={10}
                ref="userId"
                withEmptyOpt={true}
              />
            </div>
          ) : null}
          <div className="error">{error_creating}</div>
        </DialogContent>
        <DialogActions>
          <ButtonLoading
            testid="submittoken"
            loading={creating}
            loadingTitle="Creating the token"
            className="color-primary"
            type="submit"
            variant="outlined"
            color="primary"
          >
            {"Create new token"}
          </ButtonLoading>
        </DialogActions>
      </form>
    );
  }
}

const styles = theme => ({
  field: {
    marginTop: theme.spacing(2)
  },
  expirationField: {
    width: 100
  }
});

CreateTokenForm = withStyles(styles)(
  reduxForm({
    form: "createtokenform",
    validate: values => {
      const errors = {};
      if (
        !values.expirationDays &&
        !values.expirationHours &&
        !values.expirationMinutes
      ) {
        errors.expirationDays = "An expiration time is required";
      }
      return errors;
    },
    onSubmitFail: scrollToFirstError
  })(CreateTokenForm)
);

class CreateTokenDialog extends Component {
  state = {
    created: false
  };

  componentWillReceiveProps(nextProps) {
    const { creating, error } = nextProps;
    if (this.props.creating && !creating && !error) {
      this.setState({
        created: true
      });
    }
  }

  open = () => {
    const { openTokenDialog } = this.props;
    openTokenDialog();
  };
  onClose = () => {
    const { closeTokenDialog } = this.props;
    closeTokenDialog();
    this.setState({
      created: false
    });
  };

  render() {
    const {
      handleCreateToken,
      creating,
      error,
      tokenCreated,
      open,
      withUserInput
    } = this.props;
    const { created } = this.state;

    return (
      <React.Fragment>
        <Dialog onClose={this.onClose} open={open} fullWidth maxWidth="sm">
          {created ? (
            <TokenInfoDialog token={tokenCreated} onClose={this.onClose} />
          ) : (
            <CreateTokenForm
              withUserInput={withUserInput}
              onSubmit={handleCreateToken}
              onClose={this.onClose}
              error_creating={error}
              creating={creating}
            />
          )}
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps2 = (state, ownProps) => {
  return {
    open: state.settings.open_token_dialog
  };
};

export default connect(
  mapStateToProps2,
  { openTokenDialog, closeTokenDialog }
)(CreateTokenDialog);
