import React, { Component } from "react";
import Button from "../Buttons/Button";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { AttachmentNameValidator, NonEmptyStringValidator, RequiredValidator } from "../Form/Fields/FieldValidators";
import SelectField from "../Form/Fields/SelectField";
import { areAttachmentsEqual } from "../../api/Attachments";
import { initializeForm } from "../../actions";
import InputField from "components/Form/Fields/InputField";
import {
  DialogContent,
  Dialog,
  MenuItem,
  DialogActions
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";

class EditForm extends Component {
  componentWillReceiveProps(nextProps) {
    const { attachmentToUpdate } = nextProps;
    if (
      (!this.props.attachmentToUpdate && attachmentToUpdate) ||
      (this.props.attachmentToUpdate &&
        attachmentToUpdate &&
        !areAttachmentsEqual(attachmentToUpdate, this.props.attachmentToUpdate))
    ) {
      const initialValues = {
        category: attachmentToUpdate.category,
        fileName: attachmentToUpdate.fileName,
        securityLevel: attachmentToUpdate.securityLevel
      };
      nextProps.initializeForm("updateattachmentform", initialValues);
    }
  }
  render() {
    const {
      handleSubmit,
      handleClose,
      open,
      typesAttachment,
      classes,
      attachmentToUpdate
    } = this.props;
    const actionTitle =
      attachmentToUpdate && attachmentToUpdate.isFolder
        ? "Rename folder"
        : "Edit attachment";

    return (
      <Dialog onClose={handleClose} open={Boolean(open)}>
        <form onSubmit={handleSubmit} style={{ padding: "0 20px" }}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleClose}
          >
            {actionTitle}
          </CloseableDialogTitle>
          <DialogContent>
            <div className={classes.field}>
              <Field
                label={"File name"}
                name="fileName"
                component={InputField}
                validate={[RequiredValidator, NonEmptyStringValidator, AttachmentNameValidator]}
                ref="fileName"
              />
            </div>
            <div className={classes.field}>
              <Field
                label={"Type"}
                name="category"
                component={SelectField}
                validate={[RequiredValidator]}
                ref="category"
              >
                {typesAttachment &&
                  typesAttachment.map(function(typeSelectedAttachment, idx) {
                    return (
                      <MenuItem key={idx} value={typeSelectedAttachment.id}>
                        {typeSelectedAttachment.label}
                      </MenuItem>
                    );
                  })}
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {actionTitle}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const styles = theme => ({
  field: {
    marginBottom: 10,
    width: "300px"
  }
});

EditForm = reduxForm({
  form: "updateattachmentform",
  onSubmitFail: scrollToFirstError
})(EditForm);

const mapStateToProps = state => {
  return {};
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { initializeForm }
  )(EditForm)
);
