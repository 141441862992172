import React, { Component } from 'react';
import FormControl from './FormControl';
import { InputLabel, Checkbox, FormControlLabel } from '@material-ui/core';

class SwitchField extends Component {
    render() {
        const { label, input, disabled } = this.props;
        const checked = input.value === '1' || input.value === true;
        return (
            <FormControl { ...this.props }>
                { (label)? <InputLabel htmlFor={input.name}>{ label }</InputLabel>: "" }
                <FormControlLabel
                    control={
                        <Checkbox
                            {...input}
                            className="switch"
                            color="primary"
                            value={(checked)? '1': '0'}
                            checked={checked}
                            id={input.name}
                            disabled={disabled}
                        />
                    }
                    label={(checked)? "ON": "OFF"}
                />
            </FormControl>
        );
    }
}

export default SwitchField;
