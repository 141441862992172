import React from "react";
import Button from "../../../Buttons/Button";
import FormControl from "../FormControl";
import { Typography } from "@material-ui/core/index";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import { Close, Edit } from "@material-ui/icons/index";
import Grid from "@material-ui/core/Grid/index";
import {connect} from "react-redux";
import {closeChooseEntities, openChooseEntities} from "actions/index";
import { MODEL_REVISION_TYPE } from 'api/Entity';

const EntityInputField = (props) => {
  const {
    authoritiesRequired,
    closeChooseEntities,
    dialogTitle,
    entityType,
    getButtonSentence,
    input,
    label,
    openChooseEntities,
    selectable,
    url,
  } = props;
  const { value } = input;

  const chooseEntity = e => {
    e.stopPropagation();
    openChooseEntities({
      authoritiesRequired: authoritiesRequired,
      entityType: entityType,
      getButtonSentence: getButtonSentence,
      handleAssign: handleAssign,
      handleClose: closeAssign,
      open: true,
      selectable: selectable,
      title: dialogTitle,
      url: url
    });
  };
  const closeAssign = () => {
    closeChooseEntities();
  };
  const handleAssign = selected => {
    closeAssign();
    return input.onChange(selected);
  };

  let buttonLabel;
  if (value.length === 1) {
    buttonLabel = `${value[0].entityEnum === MODEL_REVISION_TYPE ? value[0].extendedLabel : value[0].label} selected`
  } else {
    buttonLabel = `${value.length} ${label} selected`;
  }

  return (
      <FormControl {...props}>
        {label ? <Typography variant="caption">{label}</Typography> : ""}
        <div>
          {!value || !value.length ? (
              <Button
                  testid="chooseEntity"
                  authoritiesRequired={authoritiesRequired}
                  onClick={chooseEntity}
                  style={{ width: "300px", margin: "5px auto 0" }}
                  className="color-primary"
                  variant="outlined"
                  color="primary"
              >
                Choose one or more {label}
              </Button>
          ) : (
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="body1">
                    {buttonLabel}
                  </Typography>
                </Grid>
                <Grid
                    item
                    container
                    xs
                    justify="flex-end"
                    wrap="nowrap"
                    style={{ flexGrow: 0 }}
                >
                  <Grid item xs>
                    <IconButtonLoading
                        testid="chooseEntity"
                        onClick={chooseEntity}
                    >
                      <Edit />
                    </IconButtonLoading>
                  </Grid>
                  <Grid item xs>
                    <IconButtonLoading onClick={() => input.onChange(null)}>
                      <Close />
                    </IconButtonLoading>
                  </Grid>
                </Grid>
              </Grid>
          )}
        </div>
      </FormControl>
  );
};

function mapStateToProps() {
  return {};
}
export default connect(
    mapStateToProps,
    { openChooseEntities, closeChooseEntities }
)(EntityInputField);
