import { MODEL_REVISION_OVERVIEW_PATH, ID_REGEX } from "../constants/Routes";
import { getEntityHref } from "./Entity";

export function is_revision_matching(model, modelRevisionId) {
  for (
    let i = 0;
    modelRevisionId && model && model.revisions && i < model.revisions.length;
    i++
  ) {
    const revision = model.revisions[i];
    if (
      modelRevisionId &&
      revision &&
      revision.id &&
      revision.id.toString() === modelRevisionId.toString()
    ) {
      return true;
    }
  }
  return false;
}

export function is_revisions_matching(model, modelRevisionIds) {
  for (
    let i = 0;
    modelRevisionIds && model && model.revisions && i < model.revisions.length;
    i++
  ) {
    const revision = model.revisions[i];
    if (modelRevisionIds.includes(revision.id)) {
      return true;
    }
  }
  return false;
}
