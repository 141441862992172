import React, { useEffect, useRef, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import List from '@material-ui/core/List';

import UploadGroup from 'components/Uploads/UploadGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '280px',
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
  },
}));

export const UploadGroupList = ({ groups, onUploadProcessed, onAllProcessed }) => {

  const classes = useStyles();

  const [uploadingGroup, setUploadingGroup] = useState(null);

  const pendingGroups = useRef([]);
  const processedGroups = useRef([]);

  useEffect(() => {
    if (groups && groups.length) {
      const newGroups = groups
        .filter(group => !(group.groupId === uploadingGroup
          || pendingGroups.current.some(pendingGroup => pendingGroup === group.groupId)
          || processedGroups.current.some(processedGroup => processedGroup === group.groupId)))
        .map(group => group.groupId);

      pendingGroups.current = [...pendingGroups.current, ...newGroups];
      activateNextGroup();
    }
  }, [groups]);

  useEffect(() => {
    activateNextGroup();
  }, [uploadingGroup]);

  const activateNextGroup = () => {
    if (!uploadingGroup && pendingGroups.current.length > 0) {
      const next = pendingGroups.current[0];
      setUploadingGroup(next);
      pendingGroups.current = pendingGroups.current.slice(1);
    }
  };

  const handleGroupProcessed = (groupId) => {
    processedGroups.current = [...processedGroups.current, groupId];
    setUploadingGroup(null);
    if (groups.length === processedGroups.current.length) {
      onAllProcessed();
    }
  };

  return (
    <List className={classes.root}>
      {groups.map(group => {
        const { taggedFiles, ...groupDetail } = group;
        return <UploadGroup
          key={group.groupId}
          group={groupDetail}
          files={taggedFiles}
          isUploading={group.groupId === uploadingGroup}
          onUploadProcessed={onUploadProcessed}
          onGroupProcessed={handleGroupProcessed}
        />;
      })
      }
    </List>
  );
};

/**
 * Equals if the previous group ids are the same as next. The rest of the
 * group object won't change because it is immutable.
 *
 * @param prevProps Previous props.
 * @param nextProps Next props.
 *
 * @returns {boolean} true if the group ids is the same as previous.
 */
function groupsPropsAreEqual(prevProps, nextProps) {
  if (!prevProps || !nextProps) {
    return false;
  }

  const prevGroups = prevProps.groups;
  const nextGroups = nextProps.groups;

  if (prevGroups.length !== nextGroups.length) {
    return false;
  }

  const prevIds = prevGroups.map((group) => group.groupId).sort();
  const nextIds = nextGroups.map((group) => group.groupId).sort();

  return (prevIds.join(',') === nextIds.join(','));
}

const OptimizedUploadGroupList = React.memo(UploadGroupList, groupsPropsAreEqual);

export default OptimizedUploadGroupList;
