import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "../Buttons/Button";
import TasksPanel from "../Tasks/TasksPanel/TasksPanel";
import JBPMForm from "./JBPMForm";
import Diagram from "./DiagramDialog";
import ConfirmDeleteWorkflowDialogForm from "./ConfirmDeleteWorkflowDialogForm";
import AuditTrail from "../AuditTrails/ListJBPM";
import Tab from "../TabContainer/Tab";
import moment from "moment";
import {
  getWorkflow,
  getWorkflowDiagram,
  updateWorkflow,
  getWorkflowAuditTrail,
  updateWorkflowName,
  abortWorkflow
} from "actions/Workflow";
import { WORKFLOW_AUDIT_TRAIL } from 'constants/Features';
import { Error, ExpandMore, MoreVert } from "@material-ui/icons";
import APPCONFIG from "constants/Config";
import EditableWorkflowName from "components/Workflow/EditableWorkflowName";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import MenuItem from "components/Buttons/MenuItem";
import {
  CircularProgress,
  withStyles,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tabs,
  Grid,
  Menu
} from "@material-ui/core";
import {
  getEntityWorkflowRoleDelete,
  getEntityWorkflowRoleEdit
} from 'api/Entity';
import { isAuthorized } from 'api/Authorities';
import MenuInfo from "components/Workflow/MenuInfo";
import { getApiError } from 'api/Error';
import { manageForm } from 'api/Workflow';
import { WORKFLOW_TERMINAL_STATUSES } from 'constants/Workflows';
import ErrorsList from "components/Workflow/ErrorsList";
import { pushNotification } from "actions/notifications";
import ScriptExecutionLogList from "components/Workflow/ScriptExecutionLogList";

const Workflow = ({
  classes,
  workflow,
  workflowLabel,
  workflowsLabel,
  readOnly,
  entityType,
  me,
  onDelete,
  pushNotification,
  task
}) => {
  const [refreshTimeout, setRefreshTimeout] = useState(null);
  const [currentWorkflow, setWorkflow] = useState(null);
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [
    openConfirmDeleteWorkflowDialog,
    setOpenConfirmDeleteWorkflowDialog
  ] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const [openRenamingDialog, setOpenRenamingDialog] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const [openDiagram, setOpenDiagram] = useState(false);
  const [loadingDiagram, setLoadingDiagram] = useState(false);
  const [diagram, setDiagram] = useState(null);
  const [errorDiagram, setErrorDiagram] = useState(null);

  const [loadingAuditTrail, setLoadingAuditTrail] = useState(false);
  const [auditTrails, setAuditTrails] = useState(null);
  const [errorAuditTrail, setErrorAuditTrail] = useState(null);

  const [aborting, setAborting] = useState(false);
  const [abortError, setAbortError] = useState(null);

  useEffect(() => {
    setWorkflow(workflow);
    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
        setRefreshTimeout(null);
      }
    };
  }, []);
  useEffect(() => {
    refreshTab();
  }, [expanded, task]);
  useEffect(() => {
    setWorkflow(workflow);
    refreshTab();
  }, [workflow.id]);

  const handleRenaming = event => {
    event.stopPropagation();
    event.preventDefault();
    setOpenRenamingDialog(true);
    setAnchorEl(null);
  };

  const refreshTabTimer = () => {
    refreshTab();
  };

  const refreshTab = async () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }
    if (!expanded) {
      return;
    }
    setRefreshTimeout(setTimeout(() => {
      refreshTabTimer();
    }, APPCONFIG.REFRESH_TIMEOUT));

    if (!currentWorkflow) {
      return;
    }
    setLoading(true);
    try {
      const _workflow = (await getWorkflow(
        currentWorkflow.id,
        currentWorkflow.owningEntity
      )).data;
      setWorkflow({
        ...manageForm(_workflow),
        tasks:
          _workflow.tasks &&
          _workflow.tasks.map(task => {
            return manageForm(task);
          })
      });
    } catch (e) {
      setError(getApiError(e));
    }
    setLoading(false);
  };

  if (!currentWorkflow) {
    return null;
  }
  const handleChange = (event, value) => {
    setValue(value);
  };

  const onChange = (event, expanded) => {
    setExpanded(expanded);
  };
  const handleSubmitForm = async form => {
    setUpdating(true);
    try {
      const _workflow = (await updateWorkflow(
        currentWorkflow.id,
        currentWorkflow.owningEntity,
        form
      )).data;
      setWorkflow({
        ...manageForm(_workflow),
        owningEntity: currentWorkflow.owningEntity,
        tasks:
          _workflow.tasks &&
          _workflow.tasks.map(task => {
            return manageForm(task);
          })
      });
    } catch (e) {
      setUpdateError(getApiError(e));
    }
    setUpdating(false);
  };
  const handleOpenConfirmDeleteWorkflowDialog = event => {
    event.stopPropagation();
    event.preventDefault();
    setOpenConfirmDeleteWorkflowDialog(true);
    setAnchorEl(null);
  };
  const handleCloseConfirmDeleteWorkflowDialog = event => {
    event.stopPropagation();
    event.preventDefault();
    setOpenConfirmDeleteWorkflowDialog(false);
  };
  const handleConfirmDeleteWorkflowDialog = async event => {
    event.stopPropagation();
    event.preventDefault();
    setDeleting(true);
    try {
      await onDelete();
      setOpenConfirmDeleteWorkflowDialog(false);
    } catch (e) {
      setDeleteError(getApiError(e));
    }
    setDeleting(false);
  };
  const handleAbort = async event => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
    setAborting(true);
    try {
      const _workflow = (await abortWorkflow(currentWorkflow)).data;
      setWorkflow({
        ...manageForm(_workflow),
        owningEntity: currentWorkflow.owningEntity,
        tasks:
          _workflow.tasks &&
          _workflow.tasks.map(task => {
            return manageForm(task);
          })
      });
    } catch (e) {
      const error = getApiError(e);
      setAbortError(error);
      pushNotification("error", "error", "Aborting workflow failed: " + error);
    }
    setAborting(false);
  };

  const getDiagram = async () => {
    try {
      const diagram = (await getWorkflowDiagram(
        currentWorkflow.id,
        currentWorkflow.owningEntity
      )).data;
      setDiagram(diagram);
    } catch (e) {
      setErrorDiagram(getApiError(e));
    }
  };
  const handleOpenDiagram = async event => {
    event.stopPropagation();
    event.preventDefault();
    setOpenDiagram(true);
    setLoadingDiagram(true);
    await getDiagram();
    setLoadingDiagram(false);
  };
  const handleCloseDiagram = event => {
    event.stopPropagation();
    event.preventDefault();
    setOpenDiagram(false);
  };
  const onRefreshAuditTrails = async (firstResult, maxResultCount) => {
    setLoadingAuditTrail(true);
    try {
      const _auditTrails = (await getWorkflowAuditTrail(
        currentWorkflow.id,
        currentWorkflow.owningEntity,
        firstResult,
        maxResultCount
      )).data;
      setAuditTrails(_auditTrails);
    } catch (e) {
      setErrorAuditTrail(getApiError(e));
    }
    setLoadingAuditTrail(false);
  };

  const handleRenameUpdate = async changedName => {
    setOpenRenamingDialog(false);
    setUpdating(true);
    try {
      const _workflow = (await updateWorkflowName(
        currentWorkflow.id,
        currentWorkflow.owningEntity,
        changedName
      )).data;
      setWorkflow({
        ...manageForm(_workflow),
        owningEntity: currentWorkflow.owningEntity,
        owningEntityType: currentWorkflow.owningEntityType,
        tasks:
          _workflow.tasks &&
          _workflow.tasks.map(task => {
            return manageForm(task);
          })
      });
    } catch (e) {
      setUpdateError(getApiError(e));
    }
    setUpdating(false);
  };

  const handleCloseRenaming = () => {
    setOpenRenamingDialog(false);
  };

  const handleOpenMenu = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const {
    renaming,
    id,
    executionErrorCount,
    scriptExecutionErrorCount,
    status,
    revision,
    startDate,
    tasks
  } = currentWorkflow;
  const roleWorkflowEdit = getEntityWorkflowRoleEdit(entityType);
  const roleWorkflowDelete = getEntityWorkflowRoleDelete(entityType);
  let optionsMenu;

  if (
    !readOnly &&
    isAuthorized(me, [roleWorkflowEdit, roleWorkflowDelete], true)
  ) {
    optionsMenu = (
      <Grid container alignItems="center" justify="flex-end" style={{}}>
        <Grid item>
          <IconButtonLoading
            size="small"
            loading={renaming}
            onClick={handleOpenMenu}
            testid="workflowActionMenu"
          >
            {updating || renaming || deleting || aborting ? (
              <CircularProgress size={20} />
            ) : (
              <MoreVert />
            )}
          </IconButtonLoading>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuInfo workflow={currentWorkflow} onClick={handleCloseMenu} />
            <MenuItem
              authoritiesRequired={[roleWorkflowEdit]}
              onClick={handleRenaming}
              testid="renameWorkflowAction"
            >
              Rename
            </MenuItem>
            {!WORKFLOW_TERMINAL_STATUSES.includes(workflow.status) &&
              <MenuItem
                authoritiesRequired={[roleWorkflowEdit]}
                onClick={handleAbort}
                testid="abortWorkflowOpen"
              >
                Abort
              </MenuItem>
            }
            <MenuItem
              authoritiesRequired={[roleWorkflowDelete]}
              onClick={handleOpenConfirmDeleteWorkflowDialog}
              testid="deleteWorkflowOpen"
            >
              Delete
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    );
  }

  return (
    <ExpansionPanel key={id} onChange={onChange} testid="workflow">
      <ExpansionPanelSummary testid="workflowpanel" expandIcon={<ExpandMore />}>
        <div className={classes.container}>
          <div className={classes.headingContainer}>
            <Typography className={classes.heading}>
              <EditableWorkflowName
                workflow={currentWorkflow}
                openRenamingDialog={openRenamingDialog}
                handleRenameUpdate={handleRenameUpdate}
                handleCloseRenaming={handleCloseRenaming}
              />
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {(revision ? revision + " - " : "") +
                "Created at " +
                moment(startDate).format(APPCONFIG.dateTimeFormat)}
            </Typography>
          </div>
          <div>
            <div className={classes.statusContainer}>
              <Typography variant="caption" className={classes.statusLabel}>
                Status:
              </Typography>
              <Typography variant="body1" className={classes.statusValue}>
                {status}
              </Typography>
            </div>
            {executionErrorCount && executionErrorCount > 0 ? (
              <div
                onClick={() => {
                  setExpanded(true);
                  setValue(3);
                }}
                className={classes.statusContainer}
              >
                <Typography
                  color="error"
                  variant="caption"
                  className={classes.statusLabel}
                >
                  <Error />
                </Typography>
                <Typography
                  color="error"
                  variant="body1"
                  className={classes.statusValue}
                >
                  {executionErrorCount} {executionErrorCount === 1 ? "process error" : "process errors"}
                </Typography>
              </div>
            ) : null}
          <div>
            {scriptExecutionErrorCount > 0 ? (
            <div
                onClick={() => {
                  setExpanded(true);
                  setValue(2);
                }}
                className={classes.statusContainer}
            >
              <Typography
                  color="error"
                  variant="caption"
                  className={classes.statusLabel}
              >
                <Error />
              </Typography>
              <Typography
                  color="error"
                  variant="body1"
                  className={classes.statusValue}
              >
                {scriptExecutionErrorCount} {scriptExecutionErrorCount === 1 ? "script execution error" : "script execution errors"}
              </Typography>
            </div>) : null}
          </div>

          </div>
          <Button
            testid="diagrambutton"
            variant="outlined"
            color="primary"
            onClick={handleOpenDiagram}
          >
            View {workflowLabel}
          </Button>
        </div>
        <Diagram
          workflow={{
            ...currentWorkflow,
            diagram,
            loading_diagram: loadingDiagram
          }}
          open={openDiagram}
          onClose={handleCloseDiagram}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ padding: 0 }}>
        <div style={{ width: "100%", flex: 1 }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label={workflowLabel + " detail"} testid="workflowdetailtab" />
            <Tab label="Tasks" testid="tasklisttab" />
            <Tab label={<Typography
                variant="button"
                color={scriptExecutionErrorCount ? "error" : ""}
            >{`Script execution logs`}</Typography>}/>
            <Tab
              label="Event log"
              testid="eventlog"
              featuresRequired={[WORKFLOW_AUDIT_TRAIL]}
            />
            {executionErrorCount && executionErrorCount > 0 ? (
              <Tab
                label={
                  <Typography
                    variant="button"
                    color="error"
                  >{`Errors (${executionErrorCount})`}</Typography>
                }
                testid="errors"
              />
            ) : null}
            {optionsMenu}
          </Tabs>
          {value === 0 && (
            <div style={{ padding: "20px" }}>
              <JBPMForm
                workflowLabel={workflowLabel}
                workflowsLabel={workflowsLabel}
                workflow={{
                  ...currentWorkflow,
                  updating,
                  deleting,
                  aborting,
                  update_error: updateError,
                  error
                }}
                onSubmit={handleSubmitForm}
                onDelete={handleOpenConfirmDeleteWorkflowDialog}
              />
            </div>
          )}
          {value === 1 && (
            <div style={{ width: "100%" }}>
              <TasksPanel tasks={tasks} />
            </div>
          )}
          {value === 2 && (
              <div style={{width: "100%"}}>
                <ScriptExecutionLogList workflow={currentWorkflow}/>
              </div>
          )}
          {value === 3 && (
            <div style={{ width: "100%" }}>
              <AuditTrail
                entity={{
                  ...currentWorkflow,
                  auditTrails,
                  loading_audit_trails: loadingAuditTrail
                }}
                onRefresh={onRefreshAuditTrails}
              />
            </div>
          )}
          {value === 4 && (
            <div style={{ width: "100%" }}>
              <ErrorsList workflow={currentWorkflow} />
            </div>
          )}
        </div>
      </ExpansionPanelDetails>
      <ConfirmDeleteWorkflowDialogForm
        workflowLabel={workflowLabel}
        open={openConfirmDeleteWorkflowDialog}
        handleCancel={handleCloseConfirmDeleteWorkflowDialog}
        handleOk={handleConfirmDeleteWorkflowDialog}
      />
    </ExpansionPanel>
  );
};

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1
  },
  headingContainer: {
    flex: 0.5
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    paddingRight: 50
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row"
  },
  statusLabel: {
    lineHeight: 2,
    marginRight: 5
  },
  statusValue: {}
});

Workflow.propTypes = {
  classes: PropTypes.object.isRequired
};
Workflow.defaultProps = {
  workflowLabel: "Workflow",
  workflowsLabel: "Workflows"
};

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me,
    task: state.tasks.current
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { pushNotification }
  )(Workflow)
);
