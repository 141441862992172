import React, { Component } from "react";
import { connect } from "react-redux";
import TabCentered from "../TabContainer/TabCentered";
import {List} from "@material-ui/core";
import DatalabListItem from "components/Containers/DatalabListItem";
import withStyles from "@material-ui/core/styles/withStyles";
import AwsSyncDialog from "components/Containers/AwsSyncDialog";
import RemoveContainerDialog from "components/Containers/RemoveContainerDialog";

class ContainersList extends Component {

  state = {
    removeDialogOpen: false,
    syncDialogOpen: false,
    containerToRemove: null,
    containerToSync: null
  };

  openSynchronizeDialog = container => {
    this.setState({
      syncDialogOpen: true,
      containerToSync: container
    });
  };

  closeSynchronizeDialog = () => {
    this.setState({
      syncDialogOpen: false
    });
  };

  openRemoveDialog = container => {
    this.setState({
      removeDialogOpen: true,
      containerToRemove: container
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false
    });
  };


  render() {
    const { containers, withoutUser, isAdmin, dynamicProperties, classes } = this.props;
    if (!containers || !containers.map || containers.length === 0) {
      return (
        <TabCentered>
          <div>No Datalab found</div>
        </TabCentered>
      );
    }
    const { removeDialogOpen, syncDialogOpen, containerToSync, containerToRemove } = this.state;
    const s3fsModeEnabled = dynamicProperties.DATALAB_ENABLE_S3FS_FILE_SYNC === "true";
    const datalabSyncable = !s3fsModeEnabled && containerToRemove && containerToRemove.datalabImage && containerToRemove.datalabImage.imageType.interactive;


    return (
        <React.Fragment>
          <List className={classes.root}>
            {
              containers.map((container, idx) => {
                    return (
                        <DatalabListItem
                            container={container}
                            idx={idx}
                            s3fsModeEnabled={s3fsModeEnabled}
                            withoutUser={withoutUser}
                            isAdmin={isAdmin}
                            openSynchronizeDialog={this.openSynchronizeDialog}
                            openRemoveDialog={this.openRemoveDialog}
                        />
                    );
                  }
              )
            }
          </List>
          <RemoveContainerDialog
              s3fsEnabled={s3fsModeEnabled}
              datalabSyncable={datalabSyncable}
              removeDialogOpen={removeDialogOpen}
              containerToRemove={containerToRemove}
              closeRemoveDialog={this.closeRemoveDialog}
              isAdmin={isAdmin}
          />
          { !s3fsModeEnabled ?
              <AwsSyncDialog
                  closeSynchronizeDialog={this.closeSynchronizeDialog}
                  containerToSync={containerToSync}
                  syncDialogOpen={syncDialogOpen}
                  isAdmin={isAdmin}
              />
              :
              null
          }
        </React.Fragment>
    );
  }
}


const styles = theme => ({
  root: {
    width: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper
  }
});


const mapStateToProps = (state, ownProps) => {
  return {
      dynamicProperties: state.configuration.dynamic_properties
  };
};
export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(ContainersList)
);
