import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../Buttons/ButtonLoading";
import DroolsRule from "./DroolsRule";
import TabCentered from "../TabContainer/TabCentered";
import { initializeForm } from "actions/index";
import CreateDroolsRuleDialog from "components/Drools/CreateDroolsRuleDialog";
import { withStyles, Grid, CircularProgress } from "@material-ui/core";

class Drools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialogCreateRule: false,
      createWorkflowCurrentForm: undefined
    };
  }

  handleCreateDroolRuleDialogForm = () => {
    this.setState({
      openDialogCreateRule: true
    });
  };
  handleCloseCreateDroolRuleDialogForm = () => {
    const { initializeForm } = this.props;
    this.setState({
      openDialogCreateRule: false
    });
    initializeForm("createdroolsruledialog", {});
  };

  render() {
    const { classes, rules, loading } = this.props;
    const { openDialogCreateRule } = this.state;

    let list,
      has_rules = rules && rules.length > 0;
    const button = (
      <ButtonLoading
        testid="createdoolsrule"
        authoritiesRequired={[]}
        onClick={this.handleCreateDroolRuleDialogForm}
        variant="outlined"
        color="primary"
      >
        Create new business rule
      </ButtonLoading>
    );

    if (has_rules) {
      list = rules.map((rule, idx) => {
        if (!rule) {
          return null;
        }
        return <DroolsRule key={rule.id} rule={rule} />;
      });
    } else {
      if (loading) {
        list = (
          <TabCentered>
            <div
              style={{
                width: "400px",
                lineHeight: "36px",
                textAlign: "center",
                margin: "20px 10px 0"
              }}
            >
              <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
              <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
                Loading the business rules
              </span>
            </div>
          </TabCentered>
        );
      } else {
        list = (
          <TabCentered>
            <div className="emptyMessage">No business rule added yet</div>
            {button}
          </TabCentered>
        );
      }
    }

    return (
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12} className={"scrollable-container"}>
          <Grid container justify="flex-end" className={"scrollable-header"} style={{ padding: "10px" }}>
              {has_rules && button}
          </Grid>
          <div className="scrollable-content">
            {list}
          </div>
        </Grid>
        <CreateDroolsRuleDialog
          open={openDialogCreateRule}
          handleClose={this.handleCloseCreateDroolRuleDialogForm}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.drools.loading,
    error: state.drools.error,
    rules: state.drools.list
  };
};
const styles = theme => ({
  root: {
    height:"100%"
  }
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { initializeForm }
  )(Drools)
);
