import React, { Component } from 'react';
import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/ext/language_tools'

import 'brace/mode/drools';
import 'brace/theme/github';
import ButtonLoading from "components/Buttons/ButtonLoading";
import CompilationErrors from "components/Drools/CompilationErrors";
import { withStyles } from '@material-ui/core';

class CodeEditor extends Component {
    state = {
        code: '',
        editing: false
    };

    componentDidMount() {
        const { rule } = this.props;
        if (rule) {
            this.setState({
                code: rule.body
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { rule } = nextProps;
        const { updating, error_updating } = rule;
        if (rule && (!this.props.rule || rule.id !== this.props.rule.id)) {
            this.setState({
                code: rule.body,
                editing: false
            });
        }
        if (!updating && this.props.rule && this.props.rule.updating && !error_updating) {
            this.setState({
                editing: false
            });
        }
    }
    onChange = (value) => {
        const { rule } = this.props;
        const { body } = rule;
        this.setState({
            code: value,
            editing: body !== value
        });
    };

    render() {
        const { classes, rule, onSave } = this.props;
        const { updating, error_updating, compilation_errors, body } = rule;
        const { code, editing } = this.state;
        return (
            <div className={classes.root}>
                <AceEditor
                    placeholder="Your business rule code"
                    mode="drools"
                    focus
                    theme="github"
                    name="droolscode"
                    width="100%"
                    height="400px"
                    style={{
                        fontFamily: 'monospace'
                    }}
                    onLoad={this.onLoad}
                    onChange={this.onChange}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={code}
                    showLineNumbers
                    enableSnippets
                    enableBasicAutocompletion
                    enableLiveAutocompletion
                    tabSize={4} />
                <CompilationErrors errors={compilation_errors} editing={editing}/>
                <div>
                    <ButtonLoading
                        testid="savedroolcode"
                        className={classes.button}
                        loading={updating}
                        loadingTitle="Saving the code"
                        authoritiesRequired={[]}
                        onClick={() => {
                            onSave && onSave(code);
                        }}
                        variant="outlined"
                        color="primary">
                        Save the code
                    </ButtonLoading>
                    {
                        editing && (
                            <ButtonLoading
                                className={classes.button}
                                testid="canceldroolcode"
                                onClick={() => {
                                    this.onChange(body)
                                }}
                                color="primary">
                                Cancel
                            </ButtonLoading>
                        )
                    }
                </div>
            </div>
        );
    };
}

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: '10px 0 10px 20px',
    }
});

export default withStyles(styles)(CodeEditor);
