import React, {useEffect, useRef} from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import {HelpOutline} from '@material-ui/icons';
import IconButton from "components/Buttons/IconButton";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: "30vw",
  },
  popoverIcon:{
    padding: 0
  }
}));

const Hint = ({hintText}) => {
  if(!hintText){
    return null;
  }

  const hintElementRef = useRef(null);
  const classes = useStyles();
  const [anchorElClick, setAnchorElClick] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (hintElementRef.current && !hintElementRef.current.contains(event.target)) {
        setAnchorElClick(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hintElementRef]);

  const handlePopoverClick = (event) => {
    if(anchorElClick === null){
      setAnchorElClick(event.currentTarget);
    } else {
      setAnchorElClick(null);
    }
    event.stopPropagation();
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl || anchorElClick);

  return (
    <div ref={hintElementRef}>
      <IconButton
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverClick}
        className={classes.popoverIcon}
      >
          <HelpOutline />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl || anchorElClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableEnforceFocus
        disableAutoFocus
      >
        <Typography>{hintText}</Typography>
      </Popover>
    </div>
  );
}
export default Hint;