import React, { useEffect, useRef, useState } from "react";
import FormControl from "./FormControl";
import Autosuggest from "./Autosuggest";
import deburr from "lodash/deburr";
import parse from "autosuggest-highlight/parse";
import PropTypes from "prop-types";
import { change } from "redux-form";
import { useDispatch } from "react-redux";
import { errorField, useDebounce } from "actions/index";
import axios from "axios";
import { KeyboardArrowDown } from "@material-ui/icons";

import {
  Paper,
  Popper,
  TextField,
  InputAdornment,
  Chip,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import GenerateUUID from "../../../api/GenerateUUID";

function customMatch(text, query) {
  const results = [];
  const trimmedQuery = query.trim().toLowerCase();
  const textLower = text.toLowerCase();
  const queryLength = trimmedQuery.length;
  let indexOf = textLower.indexOf(trimmedQuery);
  while (indexOf > -1) {
    results.push([indexOf, indexOf + queryLength]);
    indexOf = textLower.indexOf(query, indexOf + queryLength);
  }
  return results;
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  let matches;
  if (query === "") {
    matches = [];
  } else {
    matches = customMatch(suggestion.label, query);
  }
  const parts = parse(suggestion.label, matches);
  return (
    <MenuItem selected={isHighlighted} component="div" testid={suggestion.id}>
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span
              key={String(index)}
              style={{ fontWeight: 800 }}
              testid="suggestionHighlighted">
              {part.text}
            </span>
          ) : (
            <span key={String(index)}>{part.text}</span>
          );
        })}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const styles = (theme) => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: "relative",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  inputBase: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  input: {
    flexGrow: 1,
    width: "auto",
    flex: 1,
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
});

const AutoSuggestSelectField = ({
  valueLabel,
  input,
  input: { value, name },
  apiUrl,
  apiValueFromIdUrl,
  meta,
  meta: { form },
  label,
  placeholder,
  classes,
  disabled,
  maxResultNumber,
  multiSection,
  multiple,
  style,
  suggestionFieldName,
  options,
  onChangeValue,
  styleInput,
  ...rest
}) => {
  const popperNode = useRef(null);
  const lastApiCall = useRef(null);
  const dispatch = useDispatch();
  const [isExternalChange, setIsExternalChange] = useState(true);
  const [popper, setPopper] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [reason, setReason] = useState("");

  useDebounce(
    () => {
      handleInputOrReasonChange(inputValue, reason);
    },
    500,
    [inputValue, reason]
  );

  useEffect(() => {
    let selectedOption = getSelectedOption();
    if (valueLabel) {
      setPopper(selectedOption ? selectedOption.label : valueLabel);
    } else {
      setPopper(selectedOption ? selectedOption.label : "");
    }
    initializePopper();
  }, []);

  useEffect(() => {
    if (isExternalChange) {
      reinitializePopper();
    }
    setIsExternalChange(true);
  }, [input]);

  const initializePopper = async () => {
    if (!multiple && apiValueFromIdUrl && value && !valueLabel) {
      setPopper(await getValueFromID(value, apiValueFromIdUrl));
    }
  };

  const reinitializePopper = () => {
    let selectedOption;
    if (!multiple) {
      if (options) {
        selectedOption = getSelectedOption();
        if (valueLabel) {
          setPopper(selectedOption ? selectedOption.label : valueLabel);
        } else {
          setPopper(selectedOption ? selectedOption.label : "");
        }
      }
    }
    initializePopper();
  };

  const getSelectedOption = () => {
    if (multiple || !options) {
      return null;
    }
    if (multiSection) {
      for (const opt of options) {
        for (const suggestion of opt.suggestions) {
          if (suggestion.id === value) {
            return suggestion;
          }
        }
      }
    } else {
      return options.find((opt) => opt.id === input.value);
    }
  };

  const getValueFromID = (id, url) => {
    return new Promise((resolve) => {
      axios
        .get(`${url}/${id}`, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.displayName !== undefined) {
            // Option for AppUser which has displayName but not label
            resolve(response.data.displayName);
          } else {
            resolve(response.data.label);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve("");
        });
    });
  };

  const getSuggestionsAPI = (valueToRequest) => {
    const apiCallId = GenerateUUID();
    lastApiCall.current = apiCallId;

    return new Promise((resolve) => {
      const pattern = valueToRequest === null ? "" : valueToRequest;
      const maxResults = maxResultNumber || 10;
      axios
        .get(`${apiUrl}?pattern=${pattern}&max-results=${maxResults}`, {
          withCredentials: true,
        })
        .then((response) => {
          if (!lastApiCall || lastApiCall.current !== apiCallId) {
            return;
          }
          resolve(
            response.data.filter((suggestion) => {
              if (multiple) {
                if (value) {
                  for (const val of value) {
                    if (val.id === suggestion.id) {
                      return false;
                    }
                  }
                }
              } else if (value && value.id === suggestion.id) {
                return false;
              }
              return true;
            })
          );
        })
        .catch((err) => {
          console.log(err);
          resolve([]);
        });
    });
  };

  const getSuggestionsLocalFilter = (inputValue) => (suggestion) => {
    const inputLength = inputValue && inputValue.length;
    if (multiple) {
      let keep =
        !inputValue ||
        inputLength === 0 ||
        suggestion.label.toLowerCase().includes(inputValue);
      if (keep && value) {
        for (const val of value) {
          if (val.id === suggestion.id) {
            return false;
          }
        }
      }

      return keep;
    }
    return suggestion.label.toLowerCase().includes(inputValue);
  };

  const getSuggestionsLocal = (suggestionList, valueInput) => {
    if (!suggestionList) {
      return [];
    }
    let inputValue, inputLength;
    if (!valueInput || valueInput === "") {
      if (!multiple) {
        return [...suggestionList];
      }
    } else {
      inputValue = deburr(valueInput.trim()).toLowerCase();
      inputLength = inputValue.length;
    }
    if (multiSection) {
      return suggestionList
        .map((section) => {
          return {
            title: section.title,
            suggestions:
              !multiple && inputLength === 0
                ? []
                : section.suggestions.filter(
                    getSuggestionsLocalFilter(inputValue)
                  ),
          };
        })
        .filter((section) => section.suggestions.length > 0);
    }
    return !multiple && inputLength === 0
      ? []
      : suggestionList.filter(getSuggestionsLocalFilter(inputValue));
  };

  const getSuggestions = async (suggestionList, valueInput) => {
    if (apiUrl) {
      setInputValue(valueInput);
      return getSuggestionsAPI(valueInput);
    }
    return Promise.resolve(getSuggestionsLocal(suggestionList, valueInput));
  };

  const handleInputOrReasonChange = async (changedValue, changedReason) => {
    let newSuggestions = [];
    if (changedReason === "input-focused") {
      newSuggestions = await getSuggestions(options, changedValue || "");
    } else {
      newSuggestions = await getSuggestions(options, changedValue);
    }
    if (maxResultNumber) {
      newSuggestions = newSuggestions.splice(0, maxResultNumber);
    }
    setSuggestions(newSuggestions);
  };

  const handleSuggestionsFetchRequested = ({
    value: fetchValue,
    reason: fetchReason,
  }) => {
    setInputValue(fetchValue);
    setReason(fetchReason);
  };

  const handleSuggestionsClearRequested = async () => {
    let newSuggestions = await getSuggestions(options, null);
    if (maxResultNumber) {
      newSuggestions = newSuggestions.splice(0, maxResultNumber);
    }
    setSuggestions(newSuggestions);
  };

  /**
   * Returns default (id) value or value of field defined
   * by fieldName from suggestion.
   */
  const getValueOrDefaultFromSuggestion = (suggestion, fieldName) => {
    return fieldName ? suggestion[fieldName] : suggestion.id;
  };

  const handleSuggestionSelected = (event, { suggestion }) => {
    if (suggestion) {
      setPopper(multiple ? "" : suggestion.label);
    } else {
      setPopper("");
    }
    let newValue;
    if (multiple) {
      addValue(suggestion || null);
    } else {
      newValue = suggestion
        ? getValueOrDefaultFromSuggestion(suggestion, suggestionFieldName)
        : null;
      onChange(newValue);
    }
  };

  const handleBlur = async (event, { highlightedSuggestion }) => {
    //get first valid suggestion
    if (popper && popper !== "" && !highlightedSuggestion) {
      let suggestionList = await getSuggestions(options, popper);
      if (suggestionList.length > 0) {
        if (multiSection) {
          highlightedSuggestion = suggestionList[0].suggestions[0];
        } else {
          highlightedSuggestion = suggestionList[0];
        }
      }
    }

    if (multiple) {
      if (highlightedSuggestion) {
        addValue(highlightedSuggestion);
      }
    } else if (highlightedSuggestion) {
      onChange(
        getValueOrDefaultFromSuggestion(
          highlightedSuggestion,
          suggestionFieldName
        )
      );
    } else {
      onChange(null);
      if (popper && popper !== "") {
        errorField(form, name, "Invalid value")(dispatch);
      }
    }
    if (!multiple) {
      setPopper(highlightedSuggestion ? highlightedSuggestion.label : popper);
    } else {
      setPopper(highlightedSuggestion ? "" : popper);
    }
  };

  const addValue = (newValue) => {
    if (!Array.isArray(value)) {
      value = [];
    }
    let found = false;
    if (value) {
      for (const val of value) {
        if (newValue.id === val.id) {
          found = true;
          break;
        }
      }
    }
    if (!found) {
      value.push(newValue);
    }
    onChange(value);
  };
  const removeValue = (valueToRemove) => {
    if (value) {
      for (let i = 0; i < value.length; i++) {
        const val = value[i];
        if (valueToRemove.id === val.id) {
          value.splice(i, 1);
          break;
        }
      }
    }
    onChange(value);
    setSuggestions([]);
  };

  const onChange = (changedValue) => {
    setIsExternalChange(false);
    dispatch(change(form, name, changedValue, true, false));
    onChangeValue && onChangeValue(changedValue);
  };

  const handleChange = (event, { newValue }) => {
    setPopper(newValue);
  };

  const onArrowClick = () => {
    if (popperNode) {
      popperNode.current.focus();
    }
  };

  const renderSectionTitle = (section) => {
    return (
      <Typography style={{ padding: 10 }} variant="h6">
        {section.title}
      </Typography>
    );
  };

  const getSectionSuggestions = (section) => {
    return section.suggestions;
  };

  const renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;
    let startAdornment;

    if (multiple) {
      startAdornment =
        value &&
        value.map &&
        value.map((val, idx) => {
          return (
            <Chip
              key={idx}
              tabIndex={-1}
              disabled={disabled}
              className={classes.chip}
              onDelete={() => {
                removeValue(val);
              }}
              label={val.label}
            />
          );
        });
    }

    return (
      <TextField
        fullWidth
        InputProps={{
          startAdornment: startAdornment,
          style: styleInput,
          endAdornment: (
            <InputAdornment position="end" onClick={onArrowClick}>
              <KeyboardArrowDown />
            </InputAdornment>
          ),
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          classes: {
            fullWidth: classes.inputBase,
            input: classes.input,
          },
        }}
        {...other}
      />
    );
  };

  const autosuggestionProps = {
    multiSection,
    renderInputComponent: renderInputComponent,
    suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionSelected: handleSuggestionSelected,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
    shouldRenderSuggestions: () => true,
    focusInputOnSuggestionClick: false,
    renderSectionTitle: renderSectionTitle,
    getSectionSuggestions: getSectionSuggestions,
  };

  return (
    <FormControl disabled={disabled} input={input} meta={meta} {...rest}>
      <Autosuggest
        {...autosuggestionProps}
        inputProps={{
          classes: classes,
          disabled: disabled,
          label: label,
          placeholder: placeholder,
          inputRef: (node) => {
            popperNode.current = node;
          },
          id: input.name,
          value: popper,
          onChange: handleChange,
          onBlur: handleBlur,
          onKeyPress: (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          },
          InputLabelProps: {},
        }}
        theme={{
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={(opt) => {
          return (
            <Popper
              testid="suggestionsContainer"
              placement="bottom-start"
              style={{
                zIndex: 9999999,
                minWidth: popperNode.clientWidth + 32,
              }}
              anchorEl={popperNode.current}
              open={Boolean(opt.children)}>
              <Paper elevation={3} {...opt.containerProps}>
                <div
                  itemscontainer="container"
                  style={{ maxHeight: "210px", overflow: "auto" }}>
                  {opt.children}
                </div>
              </Paper>
            </Popper>
          );
        }}
      />
    </FormControl>
  );
};

AutoSuggestSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AutoSuggestSelectField);
