import {
  GET_DROOLS_RULES,
  LOADING_DROOLS_RULES,
  END_LOADING_DROOLS_RULES,
  ERROR_LOADING_DROOLS_RULES,
  GET_DROOLS_RULE_LOGS,
  LOADING_DROOLS_RULE_LOGS,
  END_LOADING_DROOLS_RULE_LOGS,
  ERROR_LOADING_DROOLS_RULE_LOGS,
  GET_DROOLS_RULE,
  RENAMING_DROOLS_RULE,
  END_RENAMING_DROOLS_RULE,
  UPDATING_DROOLS_RULE,
  END_UPDATING_DROOLS_RULE,
  ERROR_UPDATING_DROOLS_RULE,
  CREATE_DROOLS_RULE,
  CREATING_DROOLS_RULE,
  END_CREATING_DROOLS_RULE,
  ERROR_CREATING_DROOLS_RULE,
  DELETE_DROOLS_RULE,
  DELETING_DROOLS_RULE,
  END_DELETING_DROOLS_RULE,
  ERROR_DELETING_DROOLS_RULE,
  PUSH_NOTIFICATION
} from "../../constants/ActionTypes";
import axios from "axios";
import APPCONFIG from "../../constants/Config";

export function getDroolsRules() {
  return function(dispatch) {
    dispatch({
      type: LOADING_DROOLS_RULES
    });

    axios
      .get("/api/rules?excludeBody=false", {
        withCredentials: true
      })
      .then(response => {
        let rules = response.data;
        dispatch({
          type: GET_DROOLS_RULES,
          rules: rules
        });
        dispatch({
          type: END_LOADING_DROOLS_RULES
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_LOADING_DROOLS_RULES,
          error: message
        });
        dispatch({
          type: END_LOADING_DROOLS_RULES
        });
      });
  };
}

export function createDroolsRule(name, code) {
  return function(dispatch) {
    dispatch({
      type: CREATING_DROOLS_RULE
    });
    axios
      .post(
        "/api/rules",
        {
          name: name,
          body: code
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        let rule = response.data;
        dispatch({
          type: CREATE_DROOLS_RULE,
          rule: rule
        });
        dispatch({
          type: END_CREATING_DROOLS_RULE
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "add",
          variant: "success",
          message: "New business rule created"
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        const supplementaryResult = err.response.data.supplementaryResult;
        const compilationErrors =
          supplementaryResult &&
          supplementaryResult.resultingObjects &&
          supplementaryResult.resultingObjects[0] &&
          supplementaryResult.resultingObjects[0].errors;
        dispatch({
          type: ERROR_CREATING_DROOLS_RULE,
          error: message,
          compilationErrors: compilationErrors
        });
        dispatch({
          type: END_CREATING_DROOLS_RULE
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Business rule creation failed" + message
        });
      });
  };
}

export function getDroolsRuleLogs(
  rule,
  firstResult = 0,
  maxResultCount = APPCONFIG.LIST_SIZE
) {
  return function(dispatch) {
    const id = rule.id;
    dispatch({
      type: LOADING_DROOLS_RULE_LOGS,
      id
    });

    axios
      .get(
        `/api/rules/logs/find-by-drools-rule-name?droolsRuleName=${rule.name}&firstResult=${firstResult}&maxResultCount=${maxResultCount}`,
        {
          withCredentials: true
        }
      )
      .then(response => {
        let logs = response.data && response.data[0];
        dispatch({
          type: GET_DROOLS_RULE_LOGS,
          id,
          logs: logs
        });
        dispatch({
          type: END_LOADING_DROOLS_RULE_LOGS,
          id
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_LOADING_DROOLS_RULE_LOGS,
          id,
          error: message
        });
        dispatch({
          type: END_LOADING_DROOLS_RULE_LOGS,
          id
        });
      });
  };
}
export function updateDroolsRule(rule, code) {
  return function(dispatch) {
    const id = rule.id;
    dispatch({
      type: UPDATING_DROOLS_RULE,
      id
    });

    axios
      .patch(
        "/api/rules/" + id,
        {
          name: rule.name,
          body: code
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        let rule = response.data;
        dispatch({
          type: GET_DROOLS_RULE,
          id,
          rule: rule
        });
        dispatch({
          type: END_UPDATING_DROOLS_RULE,
          id
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: "Business rule code saved"
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        const supplementaryResult = err.response.data.supplementaryResult;
        const compilationErrors =
          supplementaryResult &&
          supplementaryResult.resultingObjects &&
          supplementaryResult.resultingObjects[0] &&
          supplementaryResult.resultingObjects[0].errors;
        console.log(message);
        dispatch({
          type: ERROR_UPDATING_DROOLS_RULE,
          id,
          error: message,
          compilationErrors: compilationErrors
        });
        dispatch({
          type: END_UPDATING_DROOLS_RULE,
          id
        });
      });
  };
}

export function updateDroolsRuleName(id, newName) {
  return function(dispatch) {
    dispatch({
      type: RENAMING_DROOLS_RULE,
      id
    });
    axios
      .patch(
        "/api/rules/" + id,
        {
          name: newName
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        let rule = response.data;
        dispatch({
          type: GET_DROOLS_RULE,
          id,
          rule
        });
        dispatch({
          type: END_RENAMING_DROOLS_RULE,
          id
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: "Business rule renamed"
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        const supplementaryResult = err.response.data.supplementaryResult;
        const compilationErrors =
          supplementaryResult &&
          supplementaryResult.resultingObjects &&
          supplementaryResult.resultingObjects[0] &&
          supplementaryResult.resultingObjects[0].errors;
        console.log(message);
        dispatch({
          type: ERROR_UPDATING_DROOLS_RULE,
          id,
          error: message,
          compilationErrors: compilationErrors
        });
        dispatch({
          type: END_RENAMING_DROOLS_RULE,
          id
        });
      });
  };
}
export function deleteDroolsRule(rule) {
  return function(dispatch) {
    const id = rule.id;
    dispatch({
      type: DELETING_DROOLS_RULE,
      id
    });

    axios
      .delete("/api/rules/" + id, {
        withCredentials: true
      })
      .then(response => {
        dispatch({
          type: DELETE_DROOLS_RULE,
          id
        });
        dispatch({
          type: END_DELETING_DROOLS_RULE,
          id
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: "Business rule removed"
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Delete business rule failed" + message
        });
        dispatch({
          type: ERROR_DELETING_DROOLS_RULE,
          id,
          error: message
        });
        dispatch({
          type: END_DELETING_DROOLS_RULE,
          id
        });
      });
  };
}

export function runRuleForAllEntities(ruleName) {
  return axios.get(
    "/api/rules/trigger-for-all-entities?droolsRuleName=" + ruleName,
    {
      withCredentials: true
    }
  );
}