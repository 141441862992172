import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../../Buttons/ButtonLoading";
import AssignValidationCommitteeDialog from "./AssignValidationCommitteeDialog";
import { setCommittee } from "../../../actions/Validations/index";
import {
  ROLE_VALIDATIONCOMMITTEE_MANAGE_ASSIGNMENT,
  ROLE_VALIDATION_MANAGE_ASSIGNMENT
} from "../../../constants/Authorities";
import { Group, GroupAdd } from "@material-ui/icons";

class EntityButton extends Component {
  state = {
    open: false
  };

  unassignCommittee = () => {
    const { setCommittee, entity } = this.props;
    setCommittee(entity, "none");
  };
  assignCommittee = () => {
    this.setState({
      open: true
    });
  };
  handleCloseAssignValidationCommitteeDialog = () => {
    this.setState({
      open: false
    });
  };
  onSubmitAssignValidation = committee => {
    const { setCommittee, entity } = this.props;
    setCommittee(entity, committee.id);
    this.handleCloseAssignValidationCommitteeDialog();
  };

  render() {
    const { entity } = this.props;
    const { open } = this.state;
    let buttonTitle, onClick, loadingTitle, icon;
    if (!entity || !entity.id) {
      return null;
    }

    if (entity.validationCommittee) {
      buttonTitle = "Unassign from the committee";
      onClick = this.unassignCommittee;
      loadingTitle = "Unassigning the committee";
      icon = <Group style={{ marginRight: 15 }} />;
    } else {
      buttonTitle = "Assign to committee";
      onClick = this.assignCommittee;
      loadingTitle = "Assigning the committee";
      icon = <GroupAdd style={{ marginRight: 15 }} />;
    }

    return (
      <React.Fragment>
        <ButtonLoading
          testid="assignCommittee"
          tooltipPlacement="left"
          tooltipTitle={buttonTitle}
          loading={entity.setting_committee}
          loadingTitle={loadingTitle}
          loadingSize={22}
          style={{
            width: "100%",
            justifyContent: "flex-start",
            textAlign: "left"
          }}
          authoritiesRequired={[
            ROLE_VALIDATIONCOMMITTEE_MANAGE_ASSIGNMENT,
            ROLE_VALIDATION_MANAGE_ASSIGNMENT
          ]}
          onClick={onClick}
          color="primary"
        >
          {icon}
          {buttonTitle}
        </ButtonLoading>

        <AssignValidationCommitteeDialog
          open={open}
          onAssign={this.onSubmitAssignValidation}
          handleClose={this.handleCloseAssignValidationCommitteeDialog}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  {
    setCommittee
  }
)(EntityButton);
