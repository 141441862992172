import React, {useState} from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryAxis,
  VictoryArea,
  VictoryScatter,
  Point,
  VictoryLegend,
  Curve,
  VictoryTooltip
} from "victory";
import { getColorsScale } from "../../constants/Colors";
import { Typography, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ID_REGEX, MODEL_REVISION_OVERVIEW_PATH } from "../../constants/Routes";
import { makeStyles } from "@material-ui/core/styles";

const data = models => {
  let modelData = [];
  models.map(oneModel =>
    modelData.push({
      x: oneModel.position.x,
      y: oneModel.position.y,
      label: oneModel.label,
      size: oneModel.size + 5,
      id: oneModel.id,
      trendX: oneModel.trend && oneModel.trend.x,
      trendY: oneModel.trend && oneModel.trend.y
    })
  );
  return modelData;
};

const Arrow = ({ isForward, x, y, color, ...rest }) => {
  const path = isForward ? (
    <path markerEnd="url(#arrow)" />
  ) : (
    <path markerStart="url(#arrowBack)" />
  );

  return (
    <g x={x} y={y}>
      <defs>
        <marker
          id="markerSquare"
          markerWidth="7"
          markerHeight="7"
          refX="4"
          refY="4"
          orient="auto"
        >
          <rect x="1" y="1" width="5" height="5" fill={color} />
        </marker>
        <marker
          id="arrow"
          markerWidth="10"
          markerHeight="10"
          refX="0"
          refY="3"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d={`M0,1 L0,5 L4,3 z`} fill={color} />
        </marker>
        <marker
          id="arrowBack"
          markerWidth="10"
          markerHeight="10"
          refX="0"
          refY="3"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d={`M4.5,0.5 L4.5,5 L0,3 z`} fill={color} />
        </marker>
      </defs>
      <Curve {...rest} pathComponent={path} />
    </g>
  );
};

const Trend = ({ datum, color, chartWidth, chartHeight, ...rest }) => {
  if (!datum.trendX || !datum.trendY) {
    return <Point {...rest} />;
  }
  return (
    <g>
      <VictoryLine
        padding={{ top: 50, bottom: 50, left: 40, right: 20 }}
        standalone={false}
        width={chartWidth}
        height={chartHeight}
        domain={{ x: [0, 100], y: [0, 100] }}
        style={{
          data: { stroke: color }
        }}
        data={[
          {
            x: datum.x,
            y: datum.y
          },
          {
            x: datum.trendX,
            y: datum.trendY
          }
        ]}
        dataComponent={
          <Arrow color={color} isForward={datum.trendX >= datum.x} />
        }
      />
      <Point {...rest} />
    </g>
  );
};

const PortfolioStatusVisualization = ({ widget, ...rest }) => {
  if(!widget){
    return null;
  }

  const classes = useStyles();
  const [selectedModel, setSelectedModel] = useState(undefined);

  const handleOnClick = data => {
    setSelectedModel(data.datum.id);
  };

  const { results } = widget;
  let legendData = [];
  let COLOR_SCALE;
  if (results && results.models) {
    COLOR_SCALE = getColorsScale(results.ranges.length + 2);
  }
  const chartWidth = 600;
  const chartHeight = 450;

  return (
    <Grid container>
      <Grid xs={12} md={9} xl={7} item style={{ padding: "0 0 20px 20px" }}>
        {results && (
          <React.Fragment>
            <VictoryChart
              width={chartWidth}
              height={chartHeight}
              padding={{ top: 50, bottom: 50, left: 40, right: 20 }}
              theme={VictoryTheme.material}
              style={{
                labels: { fontFamily: "roboto" }
              }}
              events={[
                {
                  target: "data",
                  childName: "ModelTooltip",
                  eventHandlers: {
                    onClick: (e, data) => {
                      handleOnClick(data);
                    },
                    onMouseOver: () => {
                      return [
                        {
                          target: "labels",
                          mutation: () => ({ active: true })
                        }
                      ];
                    },
                    onMouseOut: () => {
                      return [
                        {
                          target: "labels",
                          mutation: () => ({ active: false })
                        }
                      ];
                    }
                  }
                }
              ]}
            >
              {results.ranges &&
                results.ranges.map((range, index) => {
                  legendData.push({
                    name: range.label,
                    labels: { fill: COLOR_SCALE[index] }
                  });
                  return (
                    <VictoryArea
                      key={"BlockArea-" + index}
                      name={"BlockArea-" + index}
                      style={{ data: { fill: COLOR_SCALE[index] } }}
                      data={range.area}
                    />
                  );
                })}
              <VictoryAxis
                crossAxis={false}
                tickCount={10}
                label={results.axisX}
                style={{ axisLabel: { fontSize: 15, padding: 30 } }}
              />
              <VictoryAxis
                crossAxis={false}
                dependentAxis
                tickCount={10}
                label={results.axisY}
                style={{ axisLabel: { fontSize: 15, padding: 30 } }}
              />
              <VictoryScatter
                name="ModelTooltip"
                style={{
                  data: { fill: COLOR_SCALE[COLOR_SCALE.length - 1] }
                }}
                symbol={"square"}
                data={data(results.models)}
                dataComponent={
                  <Trend
                    chartWidth={chartWidth}
                    chartHeight={chartHeight}
                    color={COLOR_SCALE[COLOR_SCALE.length - 1]}
                  />
                }
                labelComponent={
                  <VictoryTooltip
                    {...rest}
                    constrainToVisibleArea
                    orientation="top"
                    pointerLength={5}
                    cornerRadius={2}
                    flyoutStyle={{
                      fill: "rgba(255,255,255,0.75)",
                      stroke: COLOR_SCALE[0],
                      strokeWidth: 2
                    }}
                  />
                }
              />
              <VictoryLegend
                x={50}
                y={20}
                title={null}
                colorScale={COLOR_SCALE}
                orientation="horizontal"
                padding={{ top: 5, bottom: 5, left: 5, right: 5 }}
                style={{
                  border: { stroke: "black", strokeWidth: 1 },
                  title: { fontSize: 15, fontFamily: "roboto" },
                  labels: { fontSize: 10, fontFamily: "roboto" }
                }}
                data={legendData}
              />
            </VictoryChart>
          </React.Fragment>
        )}
      </Grid>
      <Grid item xs={12} md={3} xl={5} style={{ padding: "20px" }}>
        {!selectedModel && (
          <Typography style={{ minWidth: "200px" }}>
            Click on any square of the model to show details
          </Typography>
        )}
        {results &&
          results.models &&
          results.models
            .filter(model => model.id === selectedModel)
            .map(model => {
              return (
                <div>
                  <Typography variant="h6">{model.label}</Typography>
                  <div style={{ padding: "10px 0" }}>
                    {model.attributes &&
                      Object.keys(model.attributes).map(attr => {
                        return (
                          <Grid container>
                            <Grid item className={classes.name}>
                              <Typography
                                variant="body1"
                                style={{ color: "grey" }}
                                gutterBottom
                              >
                                {attr}:{" "}
                              </Typography>
                            </Grid>
                            <Grid item className={classes.value}>
                              <Typography variant="body1" gutterBottom>
                                {model.attributes[attr]}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </div>
                  <Button
                    style={{ width: "100%", margin: "auto" }}
                    variant="outlined"
                    color="primary"
                    component={Link}
                    to={
                      MODEL_REVISION_OVERVIEW_PATH.replace(
                        ":entityId" + ID_REGEX,
                        model.modelId
                      )
                        .replace(":revisionId" + ID_REGEX, model.id)
                        .replace(
                          ":entityType" + ID_REGEX,
                          model.modelTypeId
                        ) + "?tab=attributes"
                    }
                    size="small"
                  >
                    Go to Model Revision
                  </Button>
                </div>
              );
            })}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  name: {
    display: "block",
    paddingRight: "10px",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100px"
  },
  value: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
    minWidth: "150px"
  }
}));

export default PortfolioStatusVisualization;
