import { getEntityType, MODEL_REVISION_TYPE } from './Entity';
import {WORKFLOW_STATUS_COMPLETED} from "../constants/Workflows";
import {TASK_STATUS_COMPLETED} from "../constants/Task";

export function combineFieldsAndVariables(task) {
  if(!task){
    return null;
  }
  let fieldsAndVariables = {...task.variables};
  if (task.form) {
    const form = task.form;
    for (let i = 0; form && form.fields && i < form.fields.length; i++) {
      const field = form.fields[i];
      fieldsAndVariables[field.name] = field.value;
    }
  }
  return fieldsAndVariables;
}

function extractFieldsAndVariables(form, formMap, variables, completed) {
  if (!form) {
    return {};
  }

  let fieldsExtracted = [];
  const fields = form.fields;
  for(let i = 0; i < fields.length; i++) {
    const field = fields[i];
    if (field.code === "SubForm") {
      const fieldsAndVariables = extractFieldsAndVariables(formMap[field.nestedForm], formMap, variables, completed);
      fieldsExtracted = fieldsExtracted.concat(fieldsAndVariables.fields);
      variables = fieldsAndVariables.variables;
    } else {
      if (variables) {
        const variablesKeys = Object.keys(variables);
        for(let j = 0; variablesKeys && j < variablesKeys.length; j++) {
          const variableName = variablesKeys[j];
          if (variableName && variableName.toString() === field.name.toString()) {
            field.value = variables[variableName];
            break;
          }
        }
      }
      if (!field.value && field.defaultValue) {
        if (!variables) {
          variables = {};
        }
        variables[field.name] = field.defaultValue;
        field.value = field.defaultValue;
      }
      if(!field.value){
        field.value = field.code === "CheckBox"? false: null;
      }
      if (completed) {
        field.readOnly = true;
      }
      fieldsExtracted.push(field);
    }
  }
  return {
    fields: fieldsExtracted,
    variables: variables
  }
}
export function manageForm(workflow) {
  if (!workflow || !workflow.form) {
    return workflow;
  }
  let forms;
  try {
    forms = JSON.parse(workflow.form);
  } catch (e) {
    return undefined;
  }
  let formMap = {};
  let initForm;
  for(let i = 0; i < forms.length; i++) {
    const form = forms[i];
    if (form.model && form.model.source && form.model.source === "INTERNAL") {
      formMap[form.id] = form;
    } else {
      initForm = form;
    }
  }
  const fieldsAndVariables = extractFieldsAndVariables(
    initForm,
    formMap,
    workflow.variables,
    workflow.status === WORKFLOW_STATUS_COMPLETED || workflow.status === TASK_STATUS_COMPLETED
  );
  workflow.form = {
    ...initForm,
    fields: fieldsAndVariables.fields
  };
  workflow.variables = fieldsAndVariables.variables;
  return workflow;
}

export function getEntityOverviewForWorkflow(entity) {
  const entityType = getEntityType(entity);
  const disabled = entityType === MODEL_REVISION_TYPE
    ? !entity.model || entity.model.active === false
    : entity && entity.active === false;

  return {
    id: entity.id,
    entityType,
    disabled,
  }
}
