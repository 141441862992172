import React from 'react';

import { Paper } from '@material-ui/core';

const LinkGraphFilter = ({ entityTypesColors, linkTypesColors, filters, handleChangeFilters }) => {

  const toggleFilter = id => {
      handleChangeFilters(filters.includes(id) ? filters.filter(filter => filter !== id) : [...filters, id]);
  };

  return (
    <Paper
      style={{
        position: "absolute",
        left: "10px",
        top: "10px",
        padding: 10,
        zIndex: 9
      }}
    >
      <div style={{ marginBottom: 10 }}>
        {entityTypesColors.map(({ color, type: { label, id } }) => (
          <div key={id}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => toggleFilter(id)}
          >
            <div
              style={{
                width: 30,
                height: 15,
                border: filters.includes(id)
                  ? "none"
                  : `solid 2px ${color}`,
                marginRight: 5
              }}
            />
            <div>{label}</div>
          </div>
        ))}
      </div>
      <div>
        {linkTypesColors.map(({ color, type: { sourceName, id } }) => (
          <div key={id}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => toggleFilter(id)}
          >
            <div
              style={{
                width: 30,
                height: 5,
                backgroundColor: filters.includes(id)
                  ? "transparent"
                  : color,
                marginRight: 5
              }}
            />
            <div>{sourceName}</div>
          </div>
        ))}
      </div>
    </Paper>
  )
}

export default LinkGraphFilter;
