import {
  SETTING_COMMITTEE,
  END_SETTING_COMMITTEE,
  SETTING_COMMITTEE_ERROR,
  ASSIGNING_VALIDATION_MODEL,
  UNASSIGNING_VALIDATION_MODEL,
  END_ASSIGNING_VALIDATION_MODEL,
  END_UNASSIGNING_VALIDATION_MODEL,
  ASSIGNING_VALIDATION_MODEL_ERROR,
  UNASSIGNING_VALIDATION_MODEL_ERROR,
  ASSIGNING_VALIDATION_MODEL_REVISIONS,
  ASSIGNING_VALIDATION_MODEL_REVISIONS_END,
  ASSIGNING_VALIDATION_MODEL_REVISIONS_ERROR,
  OPEN_ASSIGN_MODELS,
  CLOSE_ASSIGN_MODELS
} from "../../constants/ActionTypes";
import reduceEntity from "../../api/ReduceEntity";
import { VALIDATION_TYPE } from "../../api/Entity";
import { goThroughList } from "../../api/List";

export default function(state = {}, action) {
  const validation = action.validation;
  const validationId = action.validationId;
  const modelRevisionId = action.modelRevisionId;
  let result;
  const current = state.current;
  const list = state.list;

  switch (action.type) {
    case SETTING_COMMITTEE:
      result = goThroughList(list, current, validation.id, validation => {
        validation.setting_committee = true;
        validation.setting_committee_error = undefined;
        return validation;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };
    case END_SETTING_COMMITTEE:
      result = goThroughList(list, current, validation.id, validation => {
        validation.setting_committee = false;
        return validation;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };
    case SETTING_COMMITTEE_ERROR:
      result = goThroughList(list, current, validation.id, validation => {
        validation.setting_committee_error = action.error;
        return validation;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };

    case ASSIGNING_VALIDATION_MODEL_REVISIONS:
      return {
        ...state,
        assigning_validation: true,
        error_assign_validation: undefined
      };
    case ASSIGNING_VALIDATION_MODEL_REVISIONS_END:
      return { ...state, assigning_validation: false };
    case ASSIGNING_VALIDATION_MODEL_REVISIONS_ERROR:
      return { ...state, error_assign_validation: action.error };

    case ASSIGNING_VALIDATION_MODEL:
      result = goThroughList(list, current, validationId, validation => {
        validation.assigning_model = true;
        validation.assigning_model_error = undefined;
        return validation;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };
    case UNASSIGNING_VALIDATION_MODEL:
      result = goThroughList(list, current, validationId, validation => {
        let newModelRevisions = [];
        for (
          let i = 0;
          state.current &&
          state.current.modelRevisions &&
          i < state.current.modelRevisions.length;
          i++
        ) {
          let modelRevision = state.current.modelRevisions[i];
          if (modelRevision.id === modelRevisionId) {
            modelRevision.setting = true;
            modelRevision.error = undefined;
            modelRevision = Object.assign({}, modelRevision);
            newModelRevisions.push(modelRevision);
          } else {
            newModelRevisions.push(modelRevision);
          }
        }
        validation.modelRevisions = newModelRevisions;
        return validation;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };
    case END_ASSIGNING_VALIDATION_MODEL:
      result = goThroughList(list, current, validationId, validation => {
        validation.assigning_model = false;
        return validation;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };
    case ASSIGNING_VALIDATION_MODEL_ERROR:
      result = goThroughList(list, current, validationId, validation => {
        validation.assigning_model_error = action.error;
        return validation;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };
    case END_UNASSIGNING_VALIDATION_MODEL:
      result = goThroughList(list, current, validationId, validation => {
        let newModelRevisions = [];
        for (
          let i = 0;
          state.current &&
          state.current.modelRevisions &&
          i < state.current.modelRevisions.length;
          i++
        ) {
          let modelRevision = state.current.modelRevisions[i];
          if (modelRevision.id === modelRevisionId) {
            modelRevision.setting = false;
            modelRevision = Object.assign({}, modelRevision);
            newModelRevisions.push(modelRevision);
          } else {
            newModelRevisions.push(modelRevision);
          }
        }
        validation.modelRevisions = newModelRevisions;
        return validation;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };
    case UNASSIGNING_VALIDATION_MODEL_ERROR:
      result = goThroughList(list, current, validationId, validation => {
        let newModelRevisions = [];
        for (
          let i = 0;
          validation && i < validation.modelRevisions.length;
          i++
        ) {
          let modelRevision = validation.modelRevisions[i];
          if (modelRevision.id === modelRevisionId) {
            modelRevision.error = action.error;
            modelRevision = Object.assign({}, modelRevision);
            newModelRevisions.push(modelRevision);
          } else {
            newModelRevisions.push(modelRevision);
          }
        }
        validation.modelRevisions = newModelRevisions;
        return validation;
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };

    case OPEN_ASSIGN_MODELS:
      return {
        ...state,
        open_assign_models: true
      };
    case CLOSE_ASSIGN_MODELS:
      return {
        ...state,
        open_assign_models: false
      };
  }
  return {
    ...state,
    current: reduceEntity(state.current, action, VALIDATION_TYPE)
  };
}
