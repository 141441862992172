import React, { Component } from 'react';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config'
import { withRouter } from "react-router";
import { getFolder } from "actions/Folders";

class Folder extends Component {
    componentDidMount() {
        this.manageProps(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const { match, error, history } = nextProps;

        if (match.params.folderId !== this.props.match.params.folderId) {
            this.manageProps(nextProps);
        }
        if (error === "NOT_FOUND") {
            history.push("/404");
        }
    }

    manageProps = (props) => {
        const { match, getFolder } = props;
        const id = match.params.folderId;
        getFolder(id);
    };

    render() {
        const { route, droppedFiles } = this.props;
        return (
            renderRoutes(route.routes, { droppedFiles })
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        route: ownProps.route,
        error: state.folders.error_folder
    };
};

export default withRouter(connect(
    mapStateToProps, { getFolder }
)(Folder));
