import moment from "moment";

export const RequiredValidator = value =>
  value === null || value === undefined ? "This field is required" : undefined;
export const NonEmptyStringValidator = value =>
  value == null || value === "" ? "This field can't be empty" : undefined;
export const OnlyIntegerValidator = value =>
  value && !/^-?\d+$/i.test(value) ? "Invalid integer" : undefined;
export const OnlyNumberValidator = value =>
  value && !/^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/i.test(value)
    ? /^[\+\-]?\d*\,?\d+(?:[Ee][\+\-]?\d+)?$/i.test(value)
      ? "Please, use a point instead of a comma"
      : "Invalid number"
    : undefined;
export const RequiredArrayValidator = value =>
  value == null || value.length === 0 ? "This field is required" : undefined;
export const DateValidator = value =>
  value && !moment(value, "YYYY-MM-DD", true).isValid()
    ? "This date is invalid"
    : undefined;
export const DateTimeValidator = value =>
  value && !moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid()
    ? "This date is invalid"
    : undefined;
export const AutoSuggestValidator = options => value => {
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (option && option.value === value) {
      return undefined;
    }
  }
  return "Invalid value";
};
export const EmailValidator = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email"
    : undefined;
export const SecurityLevelValidator = value =>
  value === null || value === undefined || value === "" || value.length === 0
    ? "If no security level is set, all MGS users can have the access."
    : undefined;

export const UsernameValidator = value =>
  value && !/^[a-z0-9]*$/g.test(value)
    ? "Only numbers and lowercase letters can be used"
    : undefined;

export const AttachmentNameValidator = value =>
  value && /\//gi.test(value)
    ? "The file name cannot contain symbols '/'"
    : undefined;
