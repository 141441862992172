export const TASK_ACTION_ACTIVATE = "ACTIVATE";
export const TASK_ACTION_CLAIM = "CLAIM";
export const TASK_ACTION_COMPLETE = "COMPLETE";
export const TASK_ACTION_COMPLETE_AUTO = "COMPLETE_AUTO";
export const TASK_ACTION_DELEGATE = "DELEGATE";
export const TASK_ACTION_EXIT = "EXIT";
export const TASK_ACTION_FAIL = "FAIL";
export const TASK_ACTION_FORWARD = "FORWARD";
export const TASK_ACTION_RELEASE = "RELEASE";
export const TASK_ACTION_RESUME = "RESUME";
export const TASK_ACTION_SAVE = "SAVE";
export const TASK_ACTION_SKIP = "SKIP";
export const TASK_ACTION_START = "START";
export const TASK_ACTION_STOP = "STOP";
export const TASK_ACTION_SUSPEND = "SUSPEND";

export const TASK_STATUS_COMPLETED = "COMPLETED";
export const TASK_STATUS_ERROR = "ERROR";
export const TASK_STATUS_EXITED = "EXITED";
export const TASK_STATUS_FAILED = "FAILED";
export const TASK_STATUS_IN_PROGRESS = "IN_PROGRESS";
export const TASK_STATUS_OBSOLETE = "OBSOLETE";
export const TASK_STATUS_READY = "READY";
export const TASK_STATUS_RESERVED = "RESERVED";
export const TASK_STATUS_SUSPENDED = "SUSPENDED";
export const TASK_STATUS_UNKNOWN = "UNKNOWN";

export const TASK_GROUP_INITIAL = [TASK_STATUS_READY, TASK_STATUS_RESERVED, TASK_STATUS_SUSPENDED, TASK_STATUS_UNKNOWN];
export const TASK_GROUP_ACTIVE = [TASK_STATUS_IN_PROGRESS];
export const TASK_GROUP_TERMINAL = [TASK_STATUS_OBSOLETE, TASK_STATUS_EXITED, TASK_STATUS_COMPLETED];
export const TASK_GROUP_FAILED = [TASK_STATUS_ERROR, TASK_STATUS_FAILED];

export const TASK_STATUS_OPTIONS = [
  {
    id: TASK_STATUS_COMPLETED,
    label: 'Completed',
    order: 1,
  },
  {
    id: TASK_STATUS_ERROR,
    label: 'Error',
    order: 2,
  },
  {
    id: TASK_STATUS_EXITED,
    label: 'Exited',
    order: 3,
  },
  {
    id: TASK_STATUS_FAILED,
    label: 'Failed',
    order: 4,
  },
  {
    id: TASK_STATUS_IN_PROGRESS,
    label: 'In progress',
    order: 5,
  },
  {
    id: TASK_STATUS_OBSOLETE,
    label: 'Obsolete',
    order: 6,
  },
  {
    id: TASK_STATUS_READY,
    label: 'Ready',
    order: 7,
  },
  {
    id: TASK_STATUS_RESERVED,
    label: 'Reserved',
    order: 8,
  },
  {
    id: TASK_STATUS_SUSPENDED,
    label: 'Suspended',
    order: 9,
  },
];
