import React, { Component } from "react";
import { connect } from "react-redux";
import Attributes from "components/Attributes/EntityContainer";
import Validations from "components/Validations";
import ValidationModels from "components/Validations/Validation/Models";
import Participants from "components/Validations/Committees/Committee/Participants";
import Links from "components/Links";
import TabCentered from "components/TabContainer/TabCentered";
import { withRouter } from "react-router";
import {
  TAB_ATTACHMENTS,
  TAB_ATTRIBUTES,
  TAB_VALIDATIONS,
  TAB_WORKFLOWS,
  TAB_WORKFLOWS_AUTOMATION,
  TAB_LINKS,
  TAB_PARTICIPANTS,
  TAB_MODELS,
  TAB_AUDIT_TRAILS,
  TAB_DOCUMENTS
} from "constants/Routes";
import { getEntity } from "actions/Entity";
import {
  getValidationsForModel,
  closeAssignModels,
  assignModelRevisions,
  getValidationsForModelRevision
} from "actions/Validations";
import {getAttachmentsRedux} from "actions/Attachments";
import { getAuditTrails } from "actions/AuditTrail";
import { withErrorBoundary } from "routes";
import {
  getEntityReducerAttribute,
  getEntityTypeFromPath,
  getEntityRoleEdit,
  MODEL_REVISION_TYPE,
  MODEL_USAGE_TYPE,
  MODEL_TYPE,
  VALIDATION_TYPE,
} from "api/Entity";
import { bindActionCreators } from "redux";
import { ROLE_VALIDATION_MANAGE_ASSIGNMENT } from "constants/Authorities";
import APPCONFIG from "constants/Config";
import AuditTrails from "components/AuditTrails";
import DrawerContent from "components/Drawer/DrawerContent";
import { WORKFLOW_AUTOMATION } from "constants/Workflows";
import { getDocuments } from "actions/Documents";
import EntityDirectory from "components/Attachments/EntityDirectory";
import EntityDocuments from "components/Attachments/EntityDocuments";
import { CircularProgress } from "@material-ui/core";
import { getCurrentURLParams } from "api/GUI";
import ListWorkflows from "components/Workflow/ListWorklows";
import { getEntityOverviewForWorkflow } from "api/Workflow";
import AssignEntityDialog from "components/Entity/AssignEntityDialog";

class EntityOverview extends Component {
  refreshTimeout = null;

  constructor(props) {
    super(props);
    const { location, match } = props;
    const query = getCurrentURLParams(location.search);
    this.state = {
      currentSubfolder: query && query["folder"] ? query["folder"] : "/",
      pages: {}
    };
    this.state.pages[TAB_WORKFLOWS] = 0;
    this.state.pages[TAB_WORKFLOWS_AUTOMATION] = 0;
    const id = match.params.entityId;
    if (id) {
      this.refreshTab(props, this.state);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      tab,
      creation_mode,
      entity,
      entityType,
      model,
      workflowSearchNameInput,
      workflowSearchType
    } = this.props;
    if (prevProps.tab !== tab) {
      this.refreshTab(this.props, this.state);
    } else if (!creation_mode && prevProps.entity !== entity) {
      if (entity && (!prevProps.entity || prevProps.entity.id !== entity.id)) {
        this.refreshTab(this.props, this.state);
      }
    } else if (
      !creation_mode &&
      entityType === MODEL_REVISION_TYPE &&
      prevProps.model !== model
    ) {
      if (model && (!prevProps.model || prevProps.model.id !== model.id)) {
        this.refreshTab(this.props, this.state);
      }
    } else if (
      prevProps.workflowSearchNameInput !== workflowSearchNameInput ||
      prevProps.workflowSearchType !== workflowSearchType
    ) {
      this.refreshTab(this.props, this.state);
    }
  }
  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  closeAssignModels = () => {
    const { closeAssignModels } = this.props;
    closeAssignModels();
  };
  getSelectedIdsForAssign = selected => {
    let ids = [];
    for (let i = 0; selected && i < selected.length; i++) {
      ids.push(selected[i].id);
    }
    return ids;
  };
  handleAssign = selected => {
    const { assignModelRevisions, entity } = this.props;
    assignModelRevisions(entity.id, this.getSelectedIdsForAssign(selected));
  };
  getButtonAssignSentence = selected => {
    if (!selected || selected.length === 0) {
      return null;
    }
    if (selected.length === 1) {
      return "Assign this model";
    }
    return "Assign these " + selected.length + " models";
  };

  onSubfolderChange = subfolder => {
    this.setState(
      {
        currentSubfolder: subfolder
      },
      () => {
        this.refreshTab(this.props, this.state);
      }
    );
  };

  refreshTabTimer = () => {
    this.refreshTab(this.props, this.state);
  };

  refreshTab = (props, state) => {
    const {
      tab,
      entityType,
      creation_mode,
      edition_mode,
      getEntity,
      getAttachments,
      getDocuments,
      getValidationsForModel,
      getValidationsForModelRevision,
      match,
      entity
    } = props;
    const { pages, currentSubfolder } = state;
    const isModelRevision = entityType === MODEL_REVISION_TYPE;
    const isModelUsage = entityType === MODEL_USAGE_TYPE;
    const entityTypeParent = isModelRevision ? MODEL_TYPE : entityType;
    const revisionId = match.params.revisionId;
    const id = isModelUsage ? match.params.usageId : match.params.entityId;
    const currentPage = pages[tab];

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTabTimer();
    }, APPCONFIG.REFRESH_TIMEOUT);

    if (creation_mode || edition_mode || !entity || entity.deleting) {
      return;
    }

    switch (tab) {
      case TAB_ATTRIBUTES:
        if (id) {
          if (isModelRevision) {
            getEntity(match.params.revisionId, id);
          } else {
            getEntity(id);
          }
        }
        break;
      case TAB_ATTACHMENTS:
        getAttachments(id, entityType, currentSubfolder);
        break;
      case TAB_DOCUMENTS:
        getDocuments(id, entityType, currentSubfolder);
        break;
      case TAB_VALIDATIONS:
        if (isModelRevision) {
          getValidationsForModelRevision(id, revisionId);
        } else {
          getValidationsForModel(id);
        }
        break;
      case TAB_MODELS:
        getEntity(id);
        break;
      case TAB_PARTICIPANTS:
        getEntity(id);
        break;
    }
  };

  render() {
    const {
      entity,
      entityType,
      model,
      revision,
      entity_type,
      tab,
      droppedFiles,
      loading,
      error,
      openAssignModels,
      errorAssignValidation,
      assigning
    } = this.props;
    const { currentSubfolder } = this.state;
    let currentTab;
    const isModelUsage = entityType === MODEL_USAGE_TYPE;
    const isModelRevision = entityType === MODEL_REVISION_TYPE;

    if (!entity && loading) {
      currentTab = (
        <TabCentered>
          <div>
            <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle", marginLeft: "5px" }}>
              Loading the entity
            </span>
          </div>
        </TabCentered>
      );
    } else if (!entity && error) {
      currentTab = (
        <TabCentered>
          <span style={{ verticalAlign: "middle", color: "red" }}>
            {"Error during the loading of the entity: " + error}
          </span>
        </TabCentered>
      );
    } else {
      if (entity) {
        if (isModelRevision && model) {
          entity.attachments = model.attachments;
        }
        switch (tab) {
          case TAB_ATTRIBUTES:
            currentTab = (
              <Attributes
                entityType={entityType}
                entity={entity}
                model={isModelRevision || isModelUsage ? model : null}
                revision={isModelUsage ? revision : null}
                entityTypeId={entity_type}
                authoritiesRequired={[
                  getEntityRoleEdit(entity) /*ROLE_ATTRIBUTE_EDIT*/
                ]}
              />
            );
            break;
          case TAB_ATTACHMENTS:
            currentTab = (
              <EntityDirectory
                withDirectory
                onSubfolderChange={this.onSubfolderChange}
                currentSubfolder={currentSubfolder}
                droppedFiles={droppedFiles}
                entity={isModelRevision ? model : entity}
              />
            );
            break;
          case TAB_DOCUMENTS:
            currentTab = (
              <EntityDocuments
                entity={isModelRevision ? model : entity}
                droppedFiles={droppedFiles}
              />
            );
            break;
          case TAB_VALIDATIONS:
            if (isModelRevision) {
              if (model) {
                currentTab = (
                  <Validations
                    entity={entity}
                    validations={model.validations}
                  />
                );
              }
            } else {
              currentTab = (
                <Validations entity={entity} validations={entity.validations} />
              );
            }
            break;
          case TAB_WORKFLOWS:
            currentTab = <ListWorkflows entity={getEntityOverviewForWorkflow(entity)} />;
            break;
          case TAB_WORKFLOWS_AUTOMATION:
            currentTab = (
              <ListWorkflows
                classification={WORKFLOW_AUTOMATION}
                entity={getEntityOverviewForWorkflow(entity)}
              />
            );
            break;
          case TAB_LINKS:
            if (isModelRevision || entityType === VALIDATION_TYPE) {
              currentTab = <Links entity={entity} />;
            } else {
              currentTab = <div />;
            }
            break;
          case TAB_PARTICIPANTS:
            currentTab = <Participants entity={entity} />;
            break;
          case TAB_MODELS:
            currentTab = <ValidationModels entity={entity} />;
            break;
          case TAB_AUDIT_TRAILS:
            currentTab = <AuditTrails entity={entity} />;
            break;
        }
      }
    }

    return (
      <DrawerContent>
        {currentTab}
        <AssignEntityDialog
          title="Assign models"
          entityType={MODEL_REVISION_TYPE}
          selectable
          excludeIds={entity && entity.modelRevisions && entity.modelRevisions.map(revision => revision.id)}
          open={openAssignModels}
          assigning={assigning}
          error={errorAssignValidation}
          getButtonSentence={this.getButtonAssignSentence}
          authoritiesRequired={[ROLE_VALIDATION_MANAGE_ASSIGNMENT]}
          handleAssign={this.handleAssign}
          handleClose={this.closeAssignModels}
        />
      </DrawerContent>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const entityType = getEntityTypeFromPath(location.pathname);
  const entityReducerAttribute = getEntityReducerAttribute(entityType);
  let entity = {},
    entity_type;

  if (state.entity[entityType]) {
    if (entityType === MODEL_REVISION_TYPE) {
      entity_type = state.models.current;
    } else {
      entity_type = state.entity[entityType].entity_type;
    }
    entity = {
      creation_mode: state.entity[entityType].creation_mode,
      edition_mode: state.entity[entityType].edition_mode,
      entity_type: entity_type,
      loading: state.entity[entityType].loading,
      error: state.entity[entityType].error
    };
  }
  return {
    ...entity,
    entityType,
    model: state.models.current,
    revision: state.modelRevisions.current,
    entity: state[entityReducerAttribute].current,
    openAssignModels: state.validations.open_assign_models,
    assigning: state.validations.assigning_validation,
    errorAssignValidation: state.validations.error_assign_validation,
    workflowSearchNameInput: state.workflow.searchNameInput,
    workflowSearchType: state.workflow.searchType
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  const { location } = ownProps;
  let entityType = getEntityTypeFromPath(location.pathname);
  return bindActionCreators(
    {
      getEntity: getEntity(entityType),
      getAttachments: getAttachmentsRedux,
      getDocuments,
      getAuditTrails,
      getValidationsForModel: getValidationsForModel,
      getValidationsForModelRevision,
      closeAssignModels,
      assignModelRevisions
    },
    dispatch
  );
};

export default withErrorBoundary(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EntityOverview)
  )
);
