import {
  LOADING_TOKENS,
  END_LOADING_TOKENS,
  ERROR_TOKENS,
  GET_TOKENS,
  CREATING_TOKEN,
  END_CREATING_TOKEN,
  ERROR_CREATING_TOKEN,
  CREATE_TOKEN,
  REVOKING_TOKEN,
  END_REVOKING_TOKEN,
  ERROR_REVOKING_TOKEN,
  REVOKE_TOKEN
} from "../../constants/ActionTypes";
import { goThroughList } from "../../api/List";

export default function(state = {}, action) {
  let result;

  switch (action.type) {
    case LOADING_TOKENS:
      return { ...state, loading: true, error: undefined };
    case END_LOADING_TOKENS:
      return { ...state, loading: false };
    case ERROR_TOKENS:
      return { ...state, error: action.error };
    case GET_TOKENS:
      return { ...state, list: action.tokens };

    case CREATING_TOKEN:
      return { ...state, creating: true, error_creating: undefined };
    case END_CREATING_TOKEN:
      return { ...state, creating: false };
    case ERROR_CREATING_TOKEN:
      return { ...state, error_creating: action.error };
    case CREATE_TOKEN:
      if (!state.list) {
        state.list = [];
      }
      return {
        ...state,
        token_created: action.token,
        list: [...state.list, action.token]
      };

    case REVOKING_TOKEN:
      result = goThroughList(state.list, null, action.token, token => {
        return {
          ...token,
          revoking: true,
          error: undefined
        };
      });
      return {
        ...state,
        list: result.list
      };
    case END_REVOKING_TOKEN:
      result = goThroughList(state.list, null, action.token, token => {
        return {
          ...token,
          revoking: false
        };
      });
      return {
        ...state,
        list: result.list
      };
    case ERROR_REVOKING_TOKEN:
      result = goThroughList(state.list, null, action.token, token => {
        return {
          ...token,
          error: action.error
        };
      });
      return {
        ...state,
        list: result.list
      };
    case REVOKE_TOKEN:
      result = goThroughList(state.list, null, action.token, token => {
        return null;
      });
      return {
        ...state,
        list: result.list
      };
    default:
      return state;
  }
}
