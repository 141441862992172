import React, { Component } from 'react';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config'

class Entities extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { route } = this.props;
        return (
            renderRoutes(route.routes, { })
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        route: ownProps.route
    };
};

export default connect(
    mapStateToProps, { }
)(Entities);
