import React, { useState } from "react";
import {
  removeODataAccessCode,
  removeODataAccessCodeAdmin
} from "actions/OData";
import { CircularProgress, Grid, List } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TabCentered from "components/TabContainer/TabCentered";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import { FileCopy } from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import { connect } from "react-redux";
import { pushNotification } from "actions/notifications";
import DeleteODataAccessCodeButton from "components/OData/DeleteODataAccessCodeButton";
import CreateODataAccessCodeButton from "components/OData/CreateODataAccessCodeButton";
import { useApiFetchDatas } from "actions/index";
import DisableODataAccessCodeButton from "components/OData/DisableODataAccessCodeButton";

const ODataAccessCode = ({ pushNotification, isAdmin }) => {
  const [accesses, setAccesses] = useState([]);
  const [loading, error] = useApiFetchDatas(
    isAdmin
      ? "/api/user-odata-access-codes"
      : "/api/user-odata-access-codes/own",
    datas => (isAdmin ? setAccesses(datas) : setAccesses([datas]))
  );
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const createAccessCode = _access => {
    if (isAdmin) {
      setAccesses([...accesses, _access]);
    } else {
      setAccesses([_access]);
    }
  };
  const deleteAccessCode = async ({ id }) => {
    if (isAdmin) {
      await removeODataAccessCodeAdmin(id);
      setAccesses(accesses.filter(access => access.id !== id));
    } else {
      await removeODataAccessCode();
      setAccesses(null);
    }
  };

  const handleCopyCode = access => event => {
    event.stopPropagation();
    event.preventDefault();
    navigator.clipboard.writeText(access.accessCode).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
        pushNotification(
          "check",
          "success",
          "OData access link copy to clipboard."
        );
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const buttons = (
    <>
      {isAdmin && <DisableODataAccessCodeButton />}
      <CreateODataAccessCodeButton
        isAdmin={isAdmin}
        onCreate={createAccessCode}
        openDialog={openCreateDialog}
        setOpenDialog={setOpenCreateDialog}
      />
    </>
  );

  const renderContent = () => {
    if (!accesses || accesses.length === 0) {
      if (loading) {
        return (
          <TabCentered>
            <div style={{ lineHeight: "36px", textAlign: "center" }}>
              <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
              <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
                Loading the OData access link
              </span>
            </div>
          </TabCentered>
        );
      } else {
        return (
          <TabCentered>
            <div className="emptyMessage">No OData access link created yet</div>
            <div className="error">{error}</div>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ padding: "0 10px 10px" }}
              wrap="nowrap"
            >
              {buttons}
            </Grid>
          </TabCentered>
        );
      }
    }

    return (
      <>
        {isAdmin && (
          <Grid
            container
            justify="flex-end"
            alignItems="center"
            style={{ padding: "0 10px 10px" }}
            wrap="nowrap"
          >
            {buttons}
          </Grid>
        )}
        <List className={"scrollable-content"}>
          {accesses &&
            accesses.map(access => (
              <ListItem
                key={access.id}
                button
                component="a"
                target="_blank"
                href={access.fullLink}
              >
                <ListItemText
                  primary={access.fullLink}
                  secondary={`${access.userDisplayName} - ${access.accessCode}`}
                />
                <ListItemSecondaryAction>
                  <IconButtonLoading
                    tooltipTitle="Copy OData access link"
                    onClick={handleCopyCode(access)}
                    color="primary"
                  >
                    <FileCopy />
                  </IconButtonLoading>
                  <DeleteODataAccessCodeButton
                    isAdmin={isAdmin}
                    onDelete={() => deleteAccessCode(access)}
                    access={access}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </>
    );
  };

  return (
    <>
      <div style={{ padding: "0 10px 10px" }}>
        <Alert severity="warning">
          This section allows a user to generate their very own OData access
          link. Every user of the MGS reporting system has a different one. If
          this link is used, then no password needs to be provided in reporting
          tools (e.g., Excel, PowerBI, Tableau, ...). Based on the above, the
          link replaces the password, so please keep it secret. If there is any
          possibility the link was shared with other users or was exposed
          anyhow, please delete it and generate a new one.
        </Alert>
      </div>
      {renderContent()}
    </>
  );
};

export default connect(
  () => ({}),
  { pushNotification }
)(ODataAccessCode);
