import React, { Component } from "react";
import Button from "../Buttons/Button";
import { Field, reduxForm } from "redux-form";
import { RequiredValidator } from "../Form/Fields/FieldValidators";
import FileInputField from "../Form/Fields/FileInputField";
import SelectField from "../Form/Fields/SelectField";
import {
  DialogContent,
  Dialog,
  MenuItem,
  DialogActions
} from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "api/Error";
import {getSortingByLabel} from "api/Sort";

class UploadForm extends Component {
  render() {
    const {
      handleSubmit,
      handleClose,
      open,
      typesAttachment,
      withoutTypes,
      attachmentLabel
    } = this.props;

    return (
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleSubmit} style={{ padding: "0 20px" }}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleClose}
          >
            Upload new {attachmentLabel}
          </CloseableDialogTitle>
          <DialogContent>
            <div style={{ width: "400px" }}>
              <Field
                label={"Select a file"}
                name="files"
                multiple
                component={FileInputField}
                validate={[RequiredValidator]}
                ref="files"
              />
            </div>
            {!withoutTypes && (
              <div style={{ margin: "10px auto", width: "300px" }}>
                <Field
                  label={"Type of the selected " + attachmentLabel}
                  name="type"
                  component={SelectField}
                  validate={[RequiredValidator]}
                  ref="type"
                >
                  {typesAttachment &&
                    typesAttachment.sort(getSortingByLabel).map(function(typeSelectedAttachment, idx) {
                      return (
                        <MenuItem key={idx} value={typeSelectedAttachment.id}>
                          {typeSelectedAttachment.label}
                        </MenuItem>
                      );
                    })}
                </Field>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {"Upload the " + attachmentLabel}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
export default reduxForm({
  form: "uploadform",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  onSubmitFail: scrollToFirstError
})(UploadForm);
