import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ExecuteDelegateDialog from "components/Tasks/TaskDialog/ExecuteDelegateDialog";
import {
  executeAction,
  closeTask,
} from "actions/Tasks";
import {TASK_ACTION_DELEGATE} from "constants/Task";
import ResolveTaskForm from "components/Tasks/TaskDialog/ResolveTaskForm";

const ResolveTask = () => {
  const dispatch = useDispatch();
  const me = useSelector(state => state.auth.me);
  const task = useSelector(state => state.tasks.current);
  const [openDelegateDialog, setOpenDelegateDialog] = useState(false);

  const handleClose = () => {
    closeTask()(dispatch);
  };

  const executeActionDelegate = values => {
    executeAction(task, {}, TASK_ACTION_DELEGATE, me.username, values.targetId)(dispatch);
    setOpenDelegateDialog(false);
  };

  const handleExecuteAction = (action, values) => {
    if (action === TASK_ACTION_DELEGATE) {
      setOpenDelegateDialog(true);
      return;
    }
    executeAction(task, values, action, me.username)(dispatch);
  };

  return (
    <div>
      <ResolveTaskForm
        onExecuteAction={handleExecuteAction}
        onClose={handleClose}
      />
      <ExecuteDelegateDialog
        open={openDelegateDialog}
        onSubmit={executeActionDelegate}
        handleClose={() => setOpenDelegateDialog(false)}
      />
    </div>
  );

};
export default ResolveTask;
