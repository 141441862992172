import {
  LOADING_USERS,
  END_LOADING_USERS,
  USERS_ERROR,
  GET_USERS,
  LOADING_USER_ROLES,
  END_LOADING_USER_ROLES,
  ERROR_LOADING_USER_ROLES,
  GET_USER_ROLES
} from "constants/ActionTypes";

export default function(state = {}, action) {

  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        list: action.users,
        error: undefined
      };
    case LOADING_USERS:
      return { ...state, loading: true, error: undefined };
    case END_LOADING_USERS:
      return { ...state, loading: false };
    case USERS_ERROR:
      return { ...state, error: action.error };

    case GET_USER_ROLES:
      return {
        ...state,
        roles: action.roles,
        error_roles: undefined
      };
    case LOADING_USER_ROLES:
      return { ...state, loading_roles: true, error_roles: undefined };
    case END_LOADING_USER_ROLES:
      return { ...state, loading_roles: false };
    case ERROR_LOADING_USER_ROLES:
      return { ...state, error_roles: action.error };

    default:
      return state;
  }
}
