import React, {useEffect, useState} from 'react';
import QueueAnim from 'rc-queue-anim';
import Header from "components/Header";
import Button from "components/Buttons/Button";
import {useKeycloak} from "@react-keycloak/web";
import {getCurrentURLParams} from "api/GUI";

const Error403 = () => {
  const { keycloak } = useKeycloak();
  const [message, setMessage] = useState(null);
  const handleLogout = () =>{
    keycloak.logout({redirectUri:location.origin});
  }

  useEffect(() => {
    if(location) {
      const params = getCurrentURLParams(location.search)
      if (params && params.message) {
        setMessage(params.message)
      }
    }
  }, [location])

  return (
    <div>
      <Header />
      <div className="err-container text-center">
        <div className="err">
          {message ?
            <h2>{message}</h2>
            :
            (<>
              <h1>403</h1>
              <h2>Forbidden</h2>
            </>)
          }
        </div>

        <div className="err-body">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleLogout}
          >
            Log Out
          </Button>
        </div>
      </div>
    </div>
  );
}

const Page = () => (
  <div className="page-err">
    <QueueAnim type="bottom" className="ui-animate">
      <div key="1">
        <Error403 />
      </div>
    </QueueAnim>
  </div>
);

export default Page;
