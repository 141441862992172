import {Typography} from "@material-ui/core";
import Button from "components/Buttons/Button";
import {Link} from "react-router-dom";
import {getLink} from "api/Tasks";
import EntityTag, {ENTITY_TAG_SIZE} from "components/Entity/Tag";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {closeTask} from "actions/Tasks";
import {useDispatch} from "react-redux";

const EntityLinkButton = ({task}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  if (!task.owningEntity) {
    return null;
  }

  return (
      <Button
        onClick={(e) => {e.stopPropagation(); closeTask()(dispatch);}}
        className={classes.entityButton}
        variant="outlined"
        color="primary"
        component={Link}
        to={getLink(task)}
      >
          <EntityTag entityType={task.owningEntity.category} />
          <div style={{width: `calc(100% - ${ENTITY_TAG_SIZE})`}}>
            <div style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}>
              {task.owningEntity.label}
            </div>
            <Typography
              variant="caption"
              display="block"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textTransform: "none",
                textOverflow: "ellipsis",
                letterSpacing: 0
              }}
            >
              {task.owningEntity.typeLabel}
            </Typography>
          </div>
      </Button>
  );
};

const useStyles = makeStyles({
  entityButton: {
    marginRight: 10,
    justifyContent: "flex-start",
    width: "100%",
    maxWidth:"350px"
  }
});

export default EntityLinkButton;