import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

class RevisionMenuItem extends Component {
    render() {
        const { revision } = this.props;
        if (!revision) {
            return null;
        }
        return (
            revision.label
        );
    }
}

const styles = theme => ({
});

export default withStyles(styles)(RevisionMenuItem);
