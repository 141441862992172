import React from 'react';

import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';

import Button from 'components/Buttons/Button';

const ApproveDialog = ({
  isOpen,
  title,
  onApprove,
  onCancel,
}) => {

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="approve-dialog-"
    >
      <DialogTitle
        id="simple-dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogActions>
        <Button
          autoFocus
          color="primary"
          onClick={onCancel}
        >
          No
        </Button>
        <Button
          color="primary"
          onClick={onApprove}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveDialog;
