import React, { useEffect, useRef, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  List,
  Switch,
  Tab,
  Tabs
} from '@material-ui/core';
import { connect } from 'react-redux';

import { getEntityRoleEdit, getEntityType, MODEL_REVISION_TYPE } from 'api/Entity';
import { getApiError } from 'api/Error';
import { pushNotification } from 'actions/notifications';
import { ROLE_ENTITYLINK_CREATE } from 'constants/Authorities';
import APPCONFIG from '../../constants/Config';
import Button from '../Buttons/Button';
import CreateLinkForm, { SEPARATOR } from './CreateLinkForm';
import TabCentered from '../TabContainer/TabCentered';
import {
  createLink,
  deleteLink,
  getLinkDefinitions,
  getLinks
} from 'actions/Links';
import Link from 'components/Links/Link';
import LinksGraph from 'components/Links/Graph/LinksGraph';

const Links = ({ entity, classes, pushNotification }) => {
  const refreshTimeout = useRef(null);
  const [openCreateLink, setOpenCreateLink] = useState(false);
  const [tabSelected, setTabSelected] = useState(null);
  const [linkTypes, setLinkTypes] = useState([]);
  const [links, setLinks] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [creating, setCreating] = useState(false);
  const [errorCreating, setErrorCreating] = useState(null);
  const [linksSelected, setLinksSelected] = useState([]);
  const [transitive, setTransitive] = useState(false);

  useEffect(() => {
    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    refreshTab();
  }, [entity, transitive]);

  const refreshTabTimer = () => {
    refreshTab();
  };

  const refreshTab = () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout.current);
    }

    refreshTimeout.current = setTimeout(() => {
      refreshTabTimer();
    }, APPCONFIG.REFRESH_TIMEOUT);

    refreshLinkTypes();
    refreshLinks();
  };

  const refreshLinkTypes = async () => {
    try {
      const _linkTypes = (await getLinkDefinitions(entity)).data;
      setLinkTypes(_linkTypes);
    } catch (e) {
      getApiError(e);
    }
  };

  const refreshLinks = async () => {
    setLoading(true);
    setError(null);
    try {
      const _links = (await getLinks(entity.id, transitive)).data;
      setLinks(_links);
    } catch (e) {
      setError(getApiError(e));
    }
    setLoading(false);
  };

  const getLinksSelected = linkTab => {
    if (!links || !linkTab) {
      return [];
    }
    if (linkTab.endsWith("target")) {
      if (!links.targetLinks) {
        return [];
      }
      return links.targetLinks.filter(({ definition: { id } }) =>
        linkTab.startsWith(id)
      );
    } else if (linkTab.endsWith("sibling")) {
      if (!links.siblingLinks && !links.sourceLinks) {
        return [];
      }
      const siblingLinks = links.siblingLinks
        ? links.siblingLinks.filter(({ definition: { id } }) =>
            linkTab.startsWith(id)
          )
        : [];
      const sourceLinks = links.sourceLinks
        ? links.sourceLinks.filter(({ definition: { id } }) =>
            linkTab.startsWith(id)
          )
        : [];
      return [...siblingLinks, ...sourceLinks];
    } else {
      if (!links.sourceLinks) {
        return [];
      }
      return links.sourceLinks.filter(({ definition: { id } }) =>
        linkTab.startsWith(id)
      );
    }
  };

  const getTabSelected = () => {
    const selectedLinks = getLinksSelected(tabSelected);
    if (tabSelected && linkTypes && selectedLinks && selectedLinks.length > 0) {
      for (const linkType of linkTypes) {
        if (tabSelected.startsWith(linkType.id)) return;
      }
    }
    if (linkTypes && linkTypes.length > 0) {
      if(links.sourceLinks && links.sourceLinks.length > 0){
        setTabSelected(`${linkTypes[0].id}${SEPARATOR}source`);
      } else if(links.siblingLinks && links.siblingLinks.length > 0){
        setTabSelected(`${linkTypes[0].id}${SEPARATOR}sibling`);
      } else if(links.targetLinks && links.targetLinks.length > 0) {
        setTabSelected(`${linkTypes[0].id}${SEPARATOR}target`);
      }
    }
  };
  useEffect(getTabSelected, []);
  useEffect(() => {
    getTabSelected();
    setLinksSelected(getLinksSelected(tabSelected));
  }, [tabSelected, links]);
  useEffect(getTabSelected, [linkTypes]);

  const handleSubmitLinks = async form => {
    setCreating(true);
    setErrorCreating(null);
    try {
      let _links = [];
      const [definitionId, switched] = form.definitionId.split(SEPARATOR);

      if (!form.otherEntities) {
        let errorMessage  = "No entities selected.";
        setErrorCreating(errorMessage);
        if (errorMessage) {
          errorMessage = ": " + errorMessage;
        }
        pushNotification("error", "error", "Creating link failed" + errorMessage);
        return;
      }

      for (const addEntity of form.otherEntities) {
        const link = (await createLink(
          entity,
          definitionId,
          addEntity,
          switched === "target"
        )).data;
        _links.push(link);
      }
      if (switched === "target") {
        setLinks({
          ...links,
          targetLinks: [...links.targetLinks, ..._links]
        });
      } else if (switched === "source") {
        setLinks({
          ...links,
          sourceLinks: [...links.sourceLinks, ..._links]
        });
      } else if (switched === "sibling") {
        setLinks({
          ...links,
          siblingLinks: [...links.siblingLinks, ..._links]
        });
      }
      setOpenCreateLink(false);
    } catch (e) {
      let message = getApiError(e);
      setErrorCreating(message);
      if (message) {
        message = ": " + message;
      }
      pushNotification("error", "error", "Creating link failed" + message);
    }
    setCreating(false);
    setTabSelected(form.definitionId);
  };

  const handleDeleteLink = async link => {
    const {
      id,
      sourceEntityId,
      definition: { asymmetrical }
    } = link;
    await deleteLink(id);
    if (asymmetrical) {
      if (sourceEntityId === entity.id) {
        setLinks({
          ...links,
          sourceLinks: links.sourceLinks.filter(({ id }) => id !== link.id)
        });
      } else {
        setLinks({
          ...links,
          targetLinks: links.targetLinks.filter(({ id }) => id !== link.id)
        });
      }
    } else {
      setLinks({
        ...links,
        siblingLinks: links.siblingLinks.filter(({ id }) => id !== link.id)
      });
    }
  };

  const tabs = [];
  let hasToChangeTab = false;
  const addTab = (id, name) => {
    if (getLinksSelected(id).length > 0) {
      tabs.push({
        id,
        name
      });
      if (hasToChangeTab) {
        setTabSelected(id);
        hasToChangeTab = false;
      }
    } else if (id === tabSelected) {
      hasToChangeTab = true;
    }
  };
  linkTypes.map(type => {
    const { id, sourceName, targetName, asymmetrical } = type;
    if (asymmetrical) {
      addTab(`${id}${SEPARATOR}source`, sourceName);
      if (targetName) {
        addTab(`${id}${SEPARATOR}target`, targetName);
      }
    } else {
      addTab(`${id}${SEPARATOR}sibling`, sourceName);
    }
  });

  const hasLinks = linksSelected && linksSelected.length > 0;
  let list;

  const button = (
    <Button
      authoritiesRequired={[getEntityRoleEdit(entity), ROLE_ENTITYLINK_CREATE]}
      onClick={() => setOpenCreateLink(true)}
      variant="outlined"
      testid="addlink"
      color="primary"
    >
      Add a link
    </Button>
  );

  if (hasLinks) {
    list = (
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={11}>
          <List component="nav" style={{ width: "100%" }}>
            {linksSelected.map((link) =>
              transitive || !link.depth || link.depth <= 1 ? (
                <Link
                  key={link.id}
                  link={link}
                  entity={entity}
                  onDelete={() => handleDeleteLink(link)}
                />
              ) : null
            )}
          </List>
        </Grid>
      </Grid>
    );
  } else if (loading) {
    list = (
      <TabCentered style={{minHeight:"300px"}}>
        <div style={{ lineHeight: "36px", textAlign: "center" }}>
          <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
          <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
            Loading the links
          </span>
        </div>
      </TabCentered>
    );
  } else {
    list = (
      <TabCentered style={{minHeight:"300px"}}>
        <div className="emptyMessage">No link created yet</div>
        {button}
      </TabCentered>
    );
  }

  const sourceEntityType = getEntityType(entity);
  return (
    <>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        style={{ padding: "10px" }}
      >
        {sourceEntityType === MODEL_REVISION_TYPE &&
        <Grid>
          <FormControlLabel
            control={
              <Switch
                checked={transitive}
                onChange={event => setTransitive(event.target.checked)}
                value={"transitive"}
                color="primary"
              />
            }
            label="Show also transitive links"
          />
        </Grid>
        }
        <Grid item style={{ marginRight: 5 }}>
          <LinksGraph entityId={entity.id} links={links} />
        </Grid>
        <Grid item>{button}</Grid>
      </Grid>
      <Tabs
        variant={"scrollable"}
        value={tabSelected}
        onChange={(event, value) => setTabSelected(value)}
        testid="linkTypes"
      >
        {tabs.map(({ id, name }) => (
          <Tab key={id} label={name} testid={name} value={id} />
        ))}
      </Tabs>
      {list}
      {openCreateLink &&
        <CreateLinkForm
          creating={creating}
          sourceEntityId={entity.id}
          sourceEntityType={sourceEntityType}
          errorCreating={errorCreating}
          choosedEntityLinkDefinition={tabSelected}
          handleClose={() => setOpenCreateLink(false)}
          open={openCreateLink}
          initialValues={{definitionId: tabSelected}}
          typesLink={linkTypes}
          onSubmit={handleSubmitLinks}
        />
      }
    </>
  );
};

const styles = theme => ({
  root: {
    padding: "24px 0",
    overflow: "auto"
  },
  heading: {
    flexBasis: "50%",
    flexShrink: 0
  },
  gridList: {
    width: "100%"
  },
  gridListTile: {},
  card: {
    display: "flex",
    cursor: "pointer"
  },
  details: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "hidden"
  },
  content: {
    flex: "1 0 auto",
    paddingBottom: 0
  },
  cover: {
    display: "flex",
    alignItems: "center",
    margin: "0 5px",
    minWidth: 80
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  uploadingContainer: {
    flex: "1 1 auto",
    padding: "0 16px",
    minWidth: 0
  },
  buttonRefreshContainer: {
    textAlign: "right"
  }
});

export default withStyles(styles)(
  connect(
    () => ({}),
    { pushNotification }
  )(Links)
);
