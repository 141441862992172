import {
  GET_DROOLS_RULES,
  LOADING_DROOLS_RULES,
  END_LOADING_DROOLS_RULES,
  ERROR_LOADING_DROOLS_RULES,
  GET_DROOLS_RULE_LOGS,
  LOADING_DROOLS_RULE_LOGS,
  END_LOADING_DROOLS_RULE_LOGS,
  ERROR_LOADING_DROOLS_RULE_LOGS,
  GET_DROOLS_RULE,
  RENAMING_DROOLS_RULE,
  END_RENAMING_DROOLS_RULE,
  UPDATING_DROOLS_RULE,
  END_UPDATING_DROOLS_RULE,
  ERROR_UPDATING_DROOLS_RULE,
  CREATE_DROOLS_RULE,
  CREATING_DROOLS_RULE,
  END_CREATING_DROOLS_RULE,
  ERROR_CREATING_DROOLS_RULE,
  DELETE_DROOLS_RULE,
  DELETING_DROOLS_RULE,
  END_DELETING_DROOLS_RULE,
  ERROR_DELETING_DROOLS_RULE
} from "../../constants/ActionTypes";
import { goThroughList } from "../../api/List";

export default function(state = {}, action) {
  const list = state.list ? state.list : [];
  let result;

  switch (action.type) {
    case GET_DROOLS_RULES:
      for (let i = 0; action.rules && i < action.rules.length; i++) {
        const ruleAction = action.rules[i];
        for (let j = 0; ruleAction && list && j < list.length; j++) {
          const ruleState = list[j];
          if (
            ruleState &&
            ruleState.id.toString() === ruleAction.id.toString()
          ) {
            ruleAction.logs = ruleState.logs;
          }
        }
      }
      return { ...state, list: action.rules };
    case LOADING_DROOLS_RULES:
      return { ...state, loading: true, error: undefined };
    case END_LOADING_DROOLS_RULES:
      return { ...state, loading: false };
    case ERROR_LOADING_DROOLS_RULES:
      return { ...state, error: action.error };

    case CREATE_DROOLS_RULE:
      return {
        ...state,
        list: [...list, action.rule]
      };
    case CREATING_DROOLS_RULE:
      return { ...state, creating: true, error_creating: undefined };
    case END_CREATING_DROOLS_RULE:
      return { ...state, creating: false };
    case ERROR_CREATING_DROOLS_RULE:
      return {
        ...state,
        error_creating: action.error,
        compilation_errors: action.compilationErrors
      };

    case GET_DROOLS_RULE_LOGS:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          logs: action.logs
        };
      });
      return {
        ...state,
        list: result.list
      };
    case LOADING_DROOLS_RULE_LOGS:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          loading_logs: true,
          error_logs: undefined
        };
      });
      return {
        ...state,
        list: result.list
      };
    case END_LOADING_DROOLS_RULE_LOGS:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          loading_logs: false
        };
      });
      return {
        ...state,
        list: result.list
      };
    case ERROR_LOADING_DROOLS_RULE_LOGS:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          error_logs: action.error
        };
      });
      return {
        ...state,
        list: result.list
      };

    case GET_DROOLS_RULE:
      result = goThroughList(list, null, action.id, rule => {
        return action.rule;
      });
      return {
        ...state,
        list: result.list
      };
    case RENAMING_DROOLS_RULE:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          renaming: true,
          error_updating: undefined
        };
      });
      return {
        ...state,
        list: result.list
      };
    case END_RENAMING_DROOLS_RULE:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          renaming: false
        };
      });
      return {
        ...state,
        list: result.list
      };
    case UPDATING_DROOLS_RULE:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          updating: true,
          error_updating: undefined
        };
      });
      return {
        ...state,
        list: result.list
      };
    case END_UPDATING_DROOLS_RULE:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          updating: false
        };
      });
      return {
        ...state,
        list: result.list
      };
    case ERROR_UPDATING_DROOLS_RULE:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          error_updating: action.error,
          compilation_errors: action.compilationErrors
        };
      });
      return {
        ...state,
        list: result.list
      };

    case DELETE_DROOLS_RULE:
      result = goThroughList(list, null, action.id, rule => {
        return null;
      });
      return {
        ...state,
        list: result.list
      };
    case DELETING_DROOLS_RULE:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          deleting: true,
          error_deleting: undefined
        };
      });
      return {
        ...state,
        list: result.list
      };
    case END_DELETING_DROOLS_RULE:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          deleting: false
        };
      });
      return {
        ...state,
        list: result.list
      };
    case ERROR_DELETING_DROOLS_RULE:
      result = goThroughList(list, null, action.id, rule => {
        return {
          ...rule,
          error_deleting: action.error
        };
      });
      return {
        ...state,
        list: result.list
      };

    default:
      return state;
  }
}
