import {
  ADD_FILE_GROUP,
  DELETE_FILE_GROUPS,
} from 'constants/ActionTypes';

export const initialValue = {
  groups: [],
};

const uploadReducer = (state = initialValue, action) => {
  switch (action.type) {
    case ADD_FILE_GROUP:
      return { ...state, groups: [...state.groups, action.group] };
    case DELETE_FILE_GROUPS:
      return { ...state, groups: state.groups.filter((group) => action.groupIds.indexOf(group.groupId) === -1) };
    default:
      return state;
  }
};

export default uploadReducer;
