import React, {useState} from "react";
import ButtonLoading from "../Buttons/ButtonLoading";
import {Info} from "@material-ui/icons";
import {
    DialogContent,
    Dialog,
    DialogActions
} from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import {getEntityType, getShortcutHref, MODEL_USAGE_TYPE} from "api/Entity";
import InfoDialogItem from "components/Entity/InfoDialogItem";

const InfoDialog = ({entity, revision}) => {
    const [open, setOpen] = useState(false);
    const entityType = getEntityType(entity);

    if (!entity || !entity.id) {
        return null;
    }
    
    let rowsToShow;
    if (entityType === MODEL_USAGE_TYPE && entity.modelRevision && entity.modelRevision.model) {
        rowsToShow = [
            { title: "Base model ID:", id: entity.modelRevision.model.id, href: getShortcutHref(entity.modelRevision.model)},
            { title: "Revision ID:", id: entity.modelRevision.id, href: getShortcutHref(entity.modelRevision)},
            { title: "Usage ID:", id: entity.id, href: getShortcutHref(entity)}
        ];
    } else if (revision && revision.id) {
        rowsToShow = [
            { title: "Base model ID:", id: entity.id, href: getShortcutHref(entity)},
            { title: "Revision ID:", id: revision.id, href: getShortcutHref(revision)}
        ]
    } else {
        rowsToShow = [
            { title: "ID:", id: entity.id, href: getShortcutHref(entity)}
        ];
    }

    return (
        <React.Fragment>
            <ButtonLoading
                tooltipPlacement="left"
                tooltipTitle="Additional information"
                style={{
                    width: "100%",
                    justifyContent: "flex-start"
                }}
                onClick={() => setOpen(true)}
                color="primary"
            >
                <Info style={{marginRight: 15}}/>
                Additional information
            </ButtonLoading>
            <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth="sm">
                <CloseableDialogTitle
                    id="simple-dialog-title"
                    handleClose={() => setOpen(false)}
                >
                    {entity.label}
                </CloseableDialogTitle>
                <DialogContent>
                    {rowsToShow && rowsToShow.map((rowToShow, idx) =>
                        <InfoDialogItem key={idx} rowToShow={rowToShow}/>
                    )}
                </DialogContent>
                <DialogActions/>
            </Dialog>
        </React.Fragment>
    );
};

export default InfoDialog;
