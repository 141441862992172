/* authentification const */
export const AUTH_USER = "auth_user";
export const AUTH_ERROR = "auth_error";
export const UNAUTH_USER = "unauth_user";
export const AUTH_CLEAR_ERROR = "auth_clear_error";
export const LOGINING = "LOGINING";
export const END_LOGINING = "END_LOGINING";
export const GETTING_USER = "GETTING_USER";
export const END_GETTING_USER = "END_GETTING_USER";

/* dynamic properties */
export const GET_DYNAMIC_PROPERTIES = "GET_DYNAMIC_PROPERTIES";
export const SET_DYNAMIC_PROPERTIES = "SET_DYNAMIC_PROPERTIES";

/* model type const */
export const GET_MODELS_OF_MODEL_TYPE = "GET_MODELS_OF_MODEL_TYPE";
export const LOADING_MODELS_OF_MODEL_TYPE = "LOADING_MODELS_OF_MODEL_TYPE";
export const END_LOADING_MODELS_OF_MODEL_TYPE =
  "END_LOADING_MODELS_OF_MODEL_TYPE";
export const MODELS_OF_MODEL_TYPE_ERROR = "MODELS_OF_MODEL_TYPE_ERROR";

/* model const */
export const OPENING_ANALYTICAL_TOOL = "OPENING_ANALYTICAL_TOOL";
export const END_OPENING_ANALYTICAL_TOOL = "END_OPENING_ANALYTICAL_TOOL";
export const ERROR_OPENING_ANALYTICAL_TOOL = "ERROR_OPENING_ANALYTICAL_TOOL";

/* filters */
export const GET_OWN_FILTERS = "GET_OWN_FILTERS";
export const LOADING_OWN_FILTERS = "LOADING_OWN_FILTERS";
export const END_LOADING_OWN_FILTERS = "END_LOADING_OWN_FILTERS";
export const ERROR_OWN_FILTERS = "ERROR_OWN_FILTERS";

export const GET_SHARED_FILTERS = "GET_SHARED_FILTERS";
export const LOADING_SHARED_FILTERS = "LOADING_SHARED_FILTERS";
export const END_LOADING_SHARED_FILTERS = "END_LOADING_SHARED_FILTERS";
export const ERROR_SHARED_FILTERS = "ERROR_SHARED_FILTERS";

export const SAVE_FILTERS = "SAVE_FILTERS";
export const SAVING_FILTERS = "SAVING_FILTERS";
export const END_SAVING_FILTERS = "END_SAVING_FILTERS";
export const ERROR_SAVING_FILTERS = "ERROR_SAVING_FILTERS";

export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATING_FILTERS = "UPDATING_FILTERS";
export const END_UPDATING_FILTERS = "END_UPDATING_FILTERS";
export const ERROR_UPDATING_FILTERS = "ERROR_UPDATING_FILTERS";

export const REMOVE_FILTERS = "REMOVE_FILTERS";
export const REMOVING_FILTERS = "REMOVING_FILTERS";
export const END_REMOVING_FILTERS = "END_REMOVING_FILTERS";
export const ERROR_REMOVING_FILTERS = "ERROR_REMOVING_FILTERS";

export const RUNNING_SEARCH = "RUNNING_SEARCH";
export const RUNNING_SEARCH_END = "RUNNING_SEARCH_END";
export const SEARCH_RESULT = "SEARCH_RESULT";
export const SEARCH_ERROR = "SEARCH_ERROR";

export const LOAD_FILTERS_CONFIG = "LOAD_FILTERS_CONFIG";
export const LOADING_FILTERS_CONFIG = "LOADING_FILTERS_CONFIG";
export const LOADING_FILTERS_CONFIG_END = "LOADING_FILTERS_CONFIG_END";
export const FILTERS_CONFIG_ERROR = "FILTERS_CONFIG_ERROR";

export const LOAD_PROCESS_VARIABLES_CONFIG = "LOAD_PROCESS_VARIABLES_CONFIG";

/* validations */
export const LOADING_VALIDATIONS_MODEL = "LOADING_VALIDATIONS_MODEL";
export const END_LOADING_VALIDATIONS_MODEL = "END_LOADING_VALIDATIONS_MODEL";
export const GET_VALIDATIONS_MODEL = "GET_VALIDATIONS_MODEL";
export const VALIDATIONS_MODEL_ERROR = "VALIDATIONS_MODEL_ERROR";

export const SETTING_COMMITTEE = "SETTING_COMMITTEE";
export const END_SETTING_COMMITTEE = "END_SETTING_COMMITTEE";
export const SETTING_COMMITTEE_ERROR = "SETTING_COMMITTEE_ERROR";

export const UNASSIGN_VALIDATION_MODEL = "UNASSIGN_VALIDATION_MODEL";
export const ASSIGNING_VALIDATION_MODEL = "ASSIGNING_VALIDATION_MODEL";
export const UNASSIGNING_VALIDATION_MODEL = "UNASSIGNING_VALIDATION_MODEL";
export const END_ASSIGNING_VALIDATION_MODEL = "END_ASSIGNING_VALIDATION_MODEL";
export const END_UNASSIGNING_VALIDATION_MODEL =
  "END_UNASSIGNING_VALIDATION_MODEL";
export const ASSIGNING_VALIDATION_MODEL_ERROR =
  "ASSIGNING_VALIDATION_MODEL_ERROR";
export const UNASSIGNING_VALIDATION_MODEL_ERROR =
  "UNASSIGNING_VALIDATION_MODEL_ERROR";

export const ASSIGNING_VALIDATION_MODEL_REVISIONS =
  "ASSIGNING_VALIDATION_MODEL_REVISIONS";
export const ASSIGNING_VALIDATION_MODEL_REVISIONS_END =
  "ASSIGNING_VALIDATION_MODEL_REVISIONS_END";
export const ASSIGNING_VALIDATION_MODEL_REVISIONS_ERROR =
  "ASSIGNING_VALIDATION_MODEL_REVISIONS_ERROR";

export const OPEN_ASSIGN_MODELS = "OPEN_ASSIGN_MODELS";
export const CLOSE_ASSIGN_MODELS = "CLOSE_ASSIGN_MODELS";

/* validation committee */
export const ASSIGN_VALIDATION_TO_COMMITTEE = "ASSIGN_VALIDATION_TO_COMMITTEE";
export const UNASSIGN_VALIDATION_FROM_COMMITTEE =
  "UNASSIGN_VALIDATION_FROM_COMMITTEE";

/* participant */
export const ADD_PARTICIPATION = "ADD_PARTICIPATION";
export const ADDING_PARTICIPANT = "ADDING_PARTICIPANT";
export const END_ADDING_PARTICIPANT = "END_ADDING_PARTICIPANT";
export const ADDING_PARTICIPANT_ERROR = "ADDING_PARTICIPANT_ERROR";

export const REMOVING_PARTICIPANT = "REMOVING_PARTICIPANT";
export const REMOVE_PARTICIPATION = "REMOVE_PARTICIPATION";
export const END_REMOVING_PARTICIPANT = "END_REMOVING_PARTICIPANT";
export const REMOVING_PARTICIPANT_ERROR = "REMOVING_PARTICIPANT_ERROR";

export const GET_PARTICIPATION_ROLES = "GET_PARTICIPATION_ROLES";
export const LOADING_PARTICIPATION_ROLES = "LOADING_PARTICIPATION_ROLES";
export const END_LOADING_PARTICIPATION_ROLES =
  "END_LOADING_PARTICIPATION_ROLES";
export const PARTICIPATION_ROLES_ERROR = "PARTICIPATION_ROLES_ERROR";

/* notifications */
export const PUSH_NOTIFICATION = "PUSH_NOTIFICATION";
export const NOTIFICATIONS_READ = "NOTIFICATIONS_READ";
export const NOTIFICATION_READ = "NOTIFICATION_READ";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const LOADING_NOTIFICATIONS = "LOADING_NOTIFICATIONS";
export const END_LOADING_NOTIFICATIONS = "END_LOADING_NOTIFICATIONS";
export const ERROR_NOTIFICATIONS = "ERROR_NOTIFICATIONS";

/* drools */
export const GET_DROOLS_RULES = "GET_DROOLS_RULES";
export const LOADING_DROOLS_RULES = "LOADING_DROOLS_RULES";
export const END_LOADING_DROOLS_RULES = "END_LOADING_DROOLS_RULES";
export const ERROR_LOADING_DROOLS_RULES = "ERROR_LOADING_DROOLS_RULES";

export const GET_DROOLS_RULE_LOGS = "GET_DROOLS_RULE_LOGS";
export const LOADING_DROOLS_RULE_LOGS = "LOADING_DROOLS_RULE_LOGS";
export const END_LOADING_DROOLS_RULE_LOGS = "END_LOADING_DROOLS_RULE_LOGS";
export const ERROR_LOADING_DROOLS_RULE_LOGS = "ERROR_LOADING_DROOLS_RULE_LOGS";

export const CREATE_DROOLS_RULE = "CREATE_DROOLS_RULE";
export const CREATING_DROOLS_RULE = "CREATING_DROOLS_RULE";
export const END_CREATING_DROOLS_RULE = "END_CREATING_DROOLS_RULE";
export const ERROR_CREATING_DROOLS_RULE = "ERROR_CREATING_DROOLS_RULE";

export const GET_DROOLS_RULE = "GET_DROOLS_RULE";
export const RENAMING_DROOLS_RULE = "RENAMING_DROOLS_RULE";
export const END_RENAMING_DROOLS_RULE = "END_RENAMING_DROOLS_RULE";
export const UPDATING_DROOLS_RULE = "UPDATING_DROOLS_RULE";
export const END_UPDATING_DROOLS_RULE = "END_UPDATING_DROOLS_RULE";
export const ERROR_UPDATING_DROOLS_RULE = "ERROR_UPDATING_DROOLS_RULE";

export const DELETE_DROOLS_RULE = "DELETE_DROOLS_RULE";
export const DELETING_DROOLS_RULE = "DELETING_DROOLS_RULE";
export const END_DELETING_DROOLS_RULE = "END_DELETING_DROOLS_RULE";
export const ERROR_DELETING_DROOLS_RULE = "ERROR_DELETING_DROOLS_RULE";

/* workflow */
export const CREATE_WORKFLOW = "CREATE_WORKFLOW";
export const SET_SEARCH_WORKFLOW_NAME_INPUT = "SET_SEARCH_WORKFLOW_NAME_INPUT";
export const SET_SEARCH_WORKFLOW_TYPE = "SET_SEARCH_WORKFLOW_TYPE";

export const CREATING_WORKFLOW = "CREATING_WORKFLOW";
export const END_CREATING_WORKFLOW = "END_CREATING_WORKFLOW";
export const CREATING_WORKFLOW_ERROR = "CREATING_WORKFLOW_ERROR";

export const LOADING_WORKFLOWS = "LOADING_WORKFLOWS";
export const END_LOADING_WORKFLOWS = "END_LOADING_WORKFLOWS";
export const WORKFLOWS_ERROR = "WORKFLOWS_ERROR";
export const GET_WORKFLOWS = "GET_WORKFLOWS";
export const ADD_WORKFLOWS = "ADD_WORKFLOWS";

export const GET_WORKFLOW = "GET_WORKFLOW";
export const LOADING_WORKFLOW = "LOADING_WORKFLOW";
export const END_LOADING_WORKFLOW = "END_LOADING_WORKFLOW";
export const WORKFLOW_ERROR = "WORKFLOW_ERROR";

export const DELETE_WORKFLOW = "DELETE_WORKFLOW";
export const DELETING_WORKFLOW = "DELETING_WORKFLOW";
export const END_DELETING_WORKFLOW = "END_DELETING_WORKFLOW";
export const DELETE_WORKFLOW_ERROR = "DELETE_WORKFLOW_ERROR";

export const RENAMING_WORKFLOW = "RENAMING_WORKFLOW";
export const END_RENAMING_WORKFLOW = "END_RENAMING_WORKFLOW";
export const RENAMING_WORKFLOW_ERROR = "RENAMING_WORKFLOW_ERROR";

export const UPDATING_WORKFLOW = "UPDATING_WORKFLOW";
export const END_UPDATING_WORKFLOW = "END_UPDATING_WORKFLOW";
export const UPDATE_WORKFLOW_ERROR = "UPDATE_WORKFLOW_ERROR";

export const GET_WORKFLOW_DIAGRAM = "GET_WORKFLOW_DIAGRAM";
export const LOADING_WORKFLOW_DIAGRAM = "LOADING_WORKFLOW_DIAGRAM";
export const END_LOADING_WORKFLOW_DIAGRAM = "END_LOADING_WORKFLOW_DIAGRAM";
export const WORKFLOW_DIAGRAM_ERROR = "WORKFLOW_DIAGRAM_ERROR";

export const GET_WORKFLOW_AUDIT_TRAILS = "GET_WORKFLOW_AUDIT_TRAILS";
export const LOADING_WORKFLOW_AUDIT_TRAILS = "LOADING_WORKFLOW_AUDIT_TRAILS";
export const END_LOADING_WORKFLOW_AUDIT_TRAILS =
  "END_LOADING_WORKFLOW_AUDIT_TRAILS";
export const WORKFLOW_AUDIT_TRAILS_ERROR = "WORKFLOW_AUDIT_TRAILS_ERROR";

/* workflow types */
export const GET_WORKFLOW_TYPES = "GET_WORKFLOW_TYPES";
export const LOADING_WORKFLOW_TYPES = "LOADING_WORKFLOW_TYPES";
export const END_LOADING_WORKFLOW_TYPES = "END_LOADING_WORKFLOW_TYPES";
export const WORKFLOW_TYPES_ERROR = "WORKFLOW_TYPES_ERROR";

export const GET_WORKFLOW_CLASSIFICATIONS = "GET_WORKFLOW_CLASSIFICATIONS";
export const LOADING_WORKFLOW_CLASSIFICATIONS =
  "LOADING_WORKFLOW_CLASSIFICATIONS";
export const END_LOADING_WORKFLOW_CLASSIFICATIONS =
  "END_LOADING_WORKFLOW_CLASSIFICATIONS";
export const WORKFLOW_CLASSIFICATIONS_ERROR = "WORKFLOW_CLASSIFICATIONS_ERROR";

export const GET_WORKFLOW_PROCESSES = "GET_WORKFLOW_PROCESSES";
export const LOADING_WORKFLOW_PROCESSES = "LOADING_WORKFLOW_PROCESSES";
export const END_LOADING_WORKFLOW_PROCESSES = "END_LOADING_WORKFLOW_PROCESSES";
export const WORKFLOW_PROCESSES_ERROR = "WORKFLOW_PROCESSES_ERROR";

export const GET_WORKFLOW_TYPES_BY_ENTITY = "GET_WORKFLOW_TYPES_BY_ENTITY";
export const LOADING_WORKFLOW_TYPES_BY_ENTITY =
  "LOADING_WORKFLOW_TYPES_BY_ENTITY";
export const END_LOADING_WORKFLOW_TYPES_BY_ENTITY =
  "END_LOADING_WORKFLOW_TYPES_BY_ENTITY";
export const WORKFLOW_TYPES_BY_ENTITY_ERROR = "WORKFLOW_TYPES_BY_ENTITY_ERROR";

export const CREATE_WORKFLOW_TYPE = "CREATE_WORKFLOW_TYPE";
export const CREATING_WORKFLOW_TYPE = "CREATING_WORKFLOW_TYPE";
export const END_CREATING_WORKFLOW_TYPE = "END_CREATING_WORKFLOW_TYPE";
export const CREATE_WORKFLOW_TYPE_ERROR = "CREATE_WORKFLOW_TYPE_ERROR";

export const SET_CURRENT_WORKFLOW_TYPE = "SET_CURRENT_WORKFLOW_TYPE";

export const UPDATE_WORKFLOW_TYPE = "UPDATE_WORKFLOW_TYPE";
export const UPDATING_WORKFLOW_TYPE = "UPDATING_WORKFLOW_TYPE";
export const END_UPDATING_WORKFLOW_TYPE = "END_UPDATING_WORKFLOW_TYPE";
export const UPDATE_WORKFLOW_TYPE_ERROR = "UPDATE_WORKFLOW_TYPE_ERROR";

export const DELETE_WORKFLOW_TYPE = "DELETE_WORKFLOW_TYPE";
export const DELETING_WORKFLOW_TYPE = "DELETING_WORKFLOW_TYPE";
export const END_DELETING_WORKFLOW_TYPE = "END_DELETING_WORKFLOW_TYPE";
export const DELETE_WORKFLOW_TYPE_ERROR = "DELETE_WORKFLOW_TYPE_ERROR";

/* attributes */
export const LOADING_ATTRIBUTES = "LOADING_ATTRIBUTES";
export const GET_ATTRIBUTES = "GET_ATTRIBUTES";
export const END_LOADING_ATTRIBUTES = "END_LOADING_ATTRIBUTES";
export const ATTRIBUTES_ERROR = "ATTRIBUTES_ERROR";
export const SHOW_ATTRIBUTE = "SHOW_ATTRIBUTE";
export const HIDE_ATTRIBUTE = "HIDE_ATTRIBUTE";

/* enum entries */
export const GET_ENUM_ENTRIES = "GET_ENUM_ENTRIES";
export const LOADING_ENUM_ENTRIES = "LOADING_ENUM_ENTRIES";
export const END_LOADING_ENUM_ENTRIES = "END_LOADING_ENUM_ENTRIES";
export const ENUM_ENTRIES_ERROR = "ENUM_ENTRIES_ERROR";

/* users */
export const GET_USERS = "GET_USERS";
export const LOADING_USERS = "LOADING_USERS";
export const END_LOADING_USERS = "END_LOADING_USERS";
export const USERS_ERROR = "USERS_ERROR";

export const GET_USER_PREFERENCES = "GET_USER_PREFERENCES";
export const UPDATING_USER_PREFERENCES = "UPDATING_USER_PREFERENCES";
export const END_UPDATING_USER_PREFERENCES = "END_UPDATING_USER_PREFERENCES";
export const ERROR_UPDATING_USER_PREFERENCES =
  "ERROR_UPDATING_USER_PREFERENCES";

export const LOADING_USER_TOKENS = "LOADING_USER_TOKENS";
export const END_LOADING_USER_TOKENS = "END_LOADING_USER_TOKENS";
export const ERROR_USER_TOKENS = "ERROR_USER_TOKENS";
export const GET_USER_TOKENS = "GET_USER_TOKENS";

export const CREATING_USER_TOKEN = "CREATING_USER_TOKEN";
export const END_CREATING_USER_TOKEN = "END_CREATING_USER_TOKEN";
export const ERROR_CREATING_USER_TOKEN = "ERROR_CREATING_USER_TOKEN";
export const CREATE_USER_TOKEN = "CREATE_USER_TOKEN";

export const REVOKING_USER_TOKEN = "REVOKING_USER_TOKEN";
export const END_REVOKING_USER_TOKEN = "END_REVOKING_USER_TOKEN";
export const ERROR_REVOKING_USER_TOKEN = "ERROR_REVOKING_USER_TOKEN";
export const REVOKE_USER_TOKEN = "REVOKE_USER_TOKEN";

export const LOADING_USER_ROLES = "LOADING_USER_ROLES";
export const END_LOADING_USER_ROLES = "END_LOADING_USER_ROLES";
export const ERROR_LOADING_USER_ROLES = "ERROR_LOADING_USER_ROLES";
export const GET_USER_ROLES = "GET_USER_ROLES";

/* uploads */
export const ADD_FILE_GROUP = "ADD_FILE_GROUP";
export const DELETE_FILE_GROUPS = "DELETE_FILE_GROUPS";

/* attachment */
export const GET_ATTACHMENTS = "GET_ATTACHMENTS";
export const LOADING_ATTACHMENTS = "LOADING_ATTACHMENTS";
export const END_LOADING_ATTACHMENTS = "END_LOADING_ATTACHMENTS";
export const ATTACHMENTS_ERROR = "ATTACHMENTS_ERROR";

export const GET_ATTACHMENTS_SEARCH = "GET_ATTACHMENTS_SEARCH";
export const LOADING_ATTACHMENTS_SEARCH = "LOADING_ATTACHMENTS_SEARCH";
export const END_LOADING_ATTACHMENTS_SEARCH = "END_LOADING_ATTACHMENTS_SEARCH";
export const ERROR_ATTACHMENTS_SEARCH = "ERROR_ATTACHMENTS_SEARCH";

export const DRAG_ATTACHMENTS = "DRAG_ATTACHMENTS";
export const END_DRAGING_ATTACHMENTS = "END_DRAGING_ATTACHMENTS";

export const GET_ATTACHMENTS_TYPES = "GET_ATTACHMENTS_TYPES";
export const LOADING_ATTACHMENTS_TYPES = "LOADING_ATTACHMENTS_TYPES";
export const END_LOADING_ATTACHMENTS_TYPES = "END_LOADING_ATTACHMENTS_TYPES";
export const ATTACHMENTS_TYPES_ERROR = "ATTACHMENTS_TYPES_ERROR";

export const ATTACHMENT_IMPORTED = "ATTACHMENT_IMPORTED";
export const ATTACHMENT_CONFLICT_ERROR = "ATTACHMENT_CONFLICT_ERROR";
export const ATTACHMENT_CONFLICT_MANAGED = "ATTACHMENT_CONFLICT_MANAGED";

export const ATTACHMENT_RENAMING = "ATTACHMENT_RENAMING";
export const ATTACHMENT_RENAMED_ERROR = "ATTACHMENT_RENAMED_ERROR";
export const ATTACHMENT_UPDATING = "ATTACHMENT_UPDATING";
export const END_ATTACHMENT_UPDATING = "END_ATTACHMENT_UPDATING";
export const ATTACHMENT_UPDATED = "ATTACHMENT_UPDATED";
export const ATTACHMENT_UPDATED_ERROR = "ATTACHMENT_UPDATED_ERROR";

export const ATTACHMENT_BULK_DELETING = "ATTACHMENT_BULK_DELETING";
export const ATTACHMENT_DELETING = "ATTACHMENT_DELETING";
export const ATTACHMENT_DELETED = "ATTACHMENT_DELETED";
export const ATTACHMENT_DELETED_ERROR = "ATTACHMENT_DELETED_ERROR";

export const MOVING_ATTACHMENT = "MOVING_ATTACHMENT";
export const END_MOVING_ATTACHMENT = "END_MOVING_ATTACHMENT";
export const ERROR_MOVING_ATTACHMENT = "ERROR_MOVING_ATTACHMENT";
export const MOVE_ATTACHMENT = "MOVE_ATTACHMENT";

export const CREATING_FOLDER_ATTACHMENT = "CREATING_FOLDER_ATTACHMENT";
export const END_CREATING_FOLDER_ATTACHMENT = "END_CREATING_FOLDER_ATTACHMENT";
export const ERROR_CREATING_FOLDER_ATTACHMENT =
  "ERROR_CREATING_FOLDER_ATTACHMENT";
export const CREATE_FOLDER_ATTACHMENT = "CREATE_FOLDER_ATTACHMENT";

export const DELETING_FOLDER_ATTACHMENT = "DELETING_FOLDER_ATTACHMENT";
export const END_DELETING_FOLDER_ATTACHMENT = "END_DELETING_FOLDER_ATTACHMENT";
export const ERROR_DELETING_FOLDER_ATTACHMENT =
  "ERROR_DELETING_FOLDER_ATTACHMENT";
export const DELETE_FOLDER_ATTACHMENT = "DELETE_FOLDER_ATTACHMENT";

export const GET_ATTACHMENTS_LOGS = "GET_ATTACHMENTS_LOGS";
export const LOADING_ATTACHMENTS_LOGS = "LOADING_ATTACHMENTS_LOGS";
export const END_LOADING_ATTACHMENTS_LOGS = "END_LOADING_ATTACHMENTS_LOGS";
export const ATTACHMENTS_LOGS_ERROR = "ATTACHMENTS_LOGS_ERROR";

/* document */
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const LOADING_DOCUMENTS = "LOADING_DOCUMENTS";
export const END_LOADING_DOCUMENTS = "END_LOADING_DOCUMENTS";
export const DOCUMENTS_ERROR = "DOCUMENTS_ERROR";

export const DOCUMENT_IMPORTED = "DOCUMENT_IMPORTED";

export const DOCUMENT_RENAMING = "DOCUMENT_RENAMING";
export const DOCUMENT_RENAMED_ERROR = "DOCUMENT_RENAMED_ERROR";
export const DOCUMENT_UPDATING = "DOCUMENT_UPDATING";
export const END_DOCUMENT_UPDATING = "END_DOCUMENT_UPDATING";
export const DOCUMENT_UPDATED = "DOCUMENT_UPDATED";
export const DOCUMENT_UPDATED_ERROR = "DOCUMENT_UPDATED_ERROR";

export const DOCUMENT_DELETING = "DOCUMENT_DELETING";
export const DOCUMENT_DELETED = "DOCUMENT_DELETED";
export const DOCUMENT_DELETED_ERROR = "DOCUMENT_DELETED_ERROR";

export const LOADING_DOCUMENT_HISTORY = "LOADING_DOCUMENT_HISTORY";
export const END_LOADING_DOCUMENT_HISTORY = "END_LOADING_DOCUMENT_HISTORY";
export const ERROR_GET_DOCUMENT_HISTORY = "ERROR_GET_DOCUMENT_HISTORY";
export const GET_DOCUMENT_HISTORY = "GET_DOCUMENT_HISTORY";

export const LOADING_DOCUMENTS_LOGS = "LOADING_DOCUMENTS_LOGS";
export const END_LOADING_DOCUMENTS_LOGS = "END_LOADING_DOCUMENTS_LOGS";
export const ERROR_GET_DOCUMENTS_LOGS = "ERROR_GET_DOCUMENTS_LOGS";
export const GET_DOCUMENTS_LOGS = "GET_DOCUMENTS_LOGS";

/* tasks */
export const GET_TASKS = "GET_TASKS";
export const ADD_TASKS = "ADD_TASKS";
export const LOADING_TASKS = "LOADING_TASKS";
export const END_LOADING_TASKS = "END_LOADING_TASKS";
export const TASKS_ERROR = "TASKS_ERROR";

export const LOADING_CLAIMABLE_TASKS = "LOADING_CLAIMABLE_TASKS";
export const GET_CLAIMABLE_TASKS = "GET_CLAIMABLE_TASKS";
export const ADD_CLAIMABLE_TASKS = "ADD_CLAIMABLE_TASKS";
export const END_LOADING_CLAIMABLE_TASKS = "END_LOADING_CLAIMABLE_TASKS";
export const CLAIMABLE_TASKS_ERROR = "CLAIMABLE_TASKS_ERROR";

export const GET_TASK = "GET_TASK";
export const LOADING_TASK = "LOADING_TASK";
export const END_LOADING_TASK = "END_LOADING_TASK";
export const ERROR_LOADING_TASK = "ERROR_LOADING_TASK";

export const EXECUTING_ACTION = "EXECUTING_ACTION";
export const END_EXECUTING_ACTION = "END_EXECUTING_ACTION";
export const EXECUTING_ACTION_ERROR = "EXECUTING_ACTION_ERROR";

export const SETTING_BUSINESS_OWNERS_TASK = "SETTING_BUSINESS_OWNERS_TASK";
export const END_SETTING_BUSINESS_OWNERS_TASK =
  "END_SETTING_BUSINESS_OWNERS_TASK";
export const ERROR_SETTING_BUSINESS_OWNERS_TASK =
  "ERROR_SETTING_BUSINESS_OWNERS_TASK";

export const LOADING_TASK_AUDIT_TRAILS = "LOADING_TASK_AUDIT_TRAILS";
export const END_LOADING_TASK_AUDIT_TRAILS = "END_LOADING_TASK_AUDIT_TRAILS";
export const ERROR_LOADING_TASK_AUDIT_TRAILS =
  "ERROR_LOADING_TASK_AUDIT_TRAILS";
export const GET_TASK_AUDIT_TRAILS = "GET_TASK_AUDIT_TRAILS";

export const OPEN_TASK = "OPEN_TASK";
export const CLOSE_TASK = "CLOSE_TASK";

/* admin */
export const GET_REST = "GET_REST";
export const EXECUTING_REST = "EXECUTING_REST";
export const END_EXECUTING_REST = "END_EXECUTING_REST";
export const ERROR_EXECUTING_REST = "ERROR_EXECUTING_REST";

export const GET_SQL = "GET_SQL";
export const EXECUTING_SQL = "EXECUTING_SQL";
export const END_EXECUTING_SQL = "END_EXECUTING_SQL";
export const ERROR_EXECUTING_SQL = "ERROR_EXECUTING_SQL";

/* security level */
export const GET_SECURITY_LEVELS = "GET_SECURITY_LEVELS";
export const LOADING_SECURITY_LEVELS = "LOADING_SECURITY_LEVELS";
export const END_LOADING_SECURITY_LEVELS = "END_LOADING_SECURITY_LEVELS";
export const SECURITY_LEVELS_ERROR = "SECURITY_LEVELS_ERROR";

export const UPDATE_SECURITY_LEVEL = "UPDATE_SECURITY_LEVEL";
export const UPDATING_SECURITY_LEVEL = "UPDATING_SECURITY_LEVEL";
export const END_UPDATING_SECURITY_LEVEL = "END_UPDATING_SECURITY_LEVEL";
export const ERROR_UPDATING_SECURITY_LEVEL = "ERROR_UPDATING_SECURITY_LEVEL";

/* entity */
export const DELETE_ENTITY = "DELETE_ENTITY";
export const DELETING_ENTITY = "DELETING_ENTITY";
export const END_DELETING_ENTITY = "END_DELETING_ENTITY";
export const ERROR_DELETING_ENTITY = "ERROR_DELETING_ENTITY";

export const LOADING_ENTITY = "LOADING_ENTITY";
export const END_LOADING_ENTITY = "END_LOADING_ENTITY";
export const GET_ENTITY = "GET_ENTITY";
export const ERROR_ENTITY = "ERROR_ENTITY";

export const LOADING_ENTITY_TYPES = "LOADING_ENTITY_TYPES";
export const GET_ENTITY_TYPES = "GET_ENTITY_TYPES";
export const END_LOADING_ENTITY_TYPES = "END_LOADING_ENTITY_TYPES";
export const ERROR_ENTITY_TYPES = "ERROR_ENTITY_TYPES";

export const LOADING_ENTITY_TYPE = "LOADING_ENTITY_TYPE";
export const GET_ENTITY_TYPE = "GET_ENTITY_TYPE";
export const END_LOADING_ENTITY_TYPE = "END_LOADING_ENTITY_TYPE";
export const ERROR_ENTITY_TYPE = "ERROR_ENTITY_TYPE";

export const CREATE_ENTITY = "CREATE_ENTITY";
export const END_CREATE_ENTITY = "END_CREATE_ENTITY";

export const EDIT_ENTITY = "EDIT_ENTITY";
export const END_EDIT_ENTITY = "END_EDIT_ENTITY";

export const SAVING_ENTITY = "SAVING_ENTITY";
export const END_SAVING_ENTITY = "END_SAVING_ENTITY";
export const SAVE_ENTITY = "SAVE_ENTITY";
export const ERROR_SAVING_ENTITY = "ERROR_SAVING_ENTITY";

/* containers */
export const GET_CONTAINERS = "GET_CONTAINERS";
export const LOADING_CONTAINERS = "LOADING_CONTAINERS";
export const END_LOADING_CONTAINERS = "END_LOADING_CONTAINERS";
export const CONTAINERS_ERROR = "CONTAINERS_ERROR";

export const GET_DOKER_HOSTS = "GET_DOKER_HOSTS";
export const LOADING_DOKER_HOSTS = "LOADING_DOKER_HOSTS";
export const END_LOADING_DOKER_HOSTS = "END_LOADING_DOKER_HOSTS";
export const DOKER_HOSTS_ERROR = "DOKER_HOSTS_ERROR";

export const GET_CONTAINERS_USER = "GET_CONTAINERS_USER";
export const LOADING_CONTAINERS_USER = "LOADING_CONTAINERS_USER";
export const END_LOADING_CONTAINERS_USER = "END_LOADING_CONTAINERS_USER";
export const CONTAINERS_USER_ERROR = "CONTAINERS_USER_ERROR";

export const KILL_CONTAINER = "KILL_CONTAINER";
export const KILLING_CONTAINER = "KILLING_CONTAINER";
export const END_KILLING_CONTAINER = "END_KILLING_CONTAINER";
export const ERROR_KILLING_CONTAINER = "ERROR_KILLING_CONTAINER";

export const SYNCUPING_CONTAINER = "SYNCUPING_CONTAINER";
export const END_SYNCUPING_CONTAINER = "END_SYNCUPING_CONTAINER";
export const ERROR_SYNCUPING_CONTAINER = "ERROR_SYNCUPING_CONTAINER";

export const SYNCDOWNING_CONTAINER = "SYNCDOWNING_CONTAINER";
export const END_SYNCDOWNING_CONTAINER = "END_SYNCDOWNING_CONTAINER";
export const ERROR_SYNCDOWNING_CONTAINER = "ERROR_SYNCDOWNING_CONTAINER";

export const GET_CONTAINER = "GET_CONTAINER";
export const ADD_CONTAINER = "ADD_CONTAINER";
export const PASSWORD_TO_COPY = "PASSWORD_TO_COPY";

/* images */
export const GET_DOCKER_IMAGES = "GET_DOCKER_IMAGES";
export const LOADING_DOCKER_IMAGES = "LOADING_DOCKER_IMAGES";
export const END_LOADING_DOCKER_IMAGES = "END_LOADING_DOCKER_IMAGES";
export const LOADING_DOCKER_IMAGES_ERROR = "LOADING_DOCKER_IMAGES_ERROR";

/* features */
export const GET_FEATURES = "GET_FEATURES";
export const LOADING_FEATURES = "LOADING_FEATURES";
export const END_LOADING_FEATURES = "END_LOADING_FEATURES";
export const FEATURES_ERROR = "FEATURES_ERROR";

/* audit trails */
export const GET_AUDIT_TRAILS = "GET_AUDIT_TRAILS";
export const LOADING_AUDIT_TRAILS = "LOADING_AUDIT_TRAILS";
export const END_LOADING_AUDIT_TRAILS = "END_LOADING_AUDIT_TRAILS";
export const ERROR_LOADING_AUDIT_TRAILS = "ERROR_LOADING_AUDIT_TRAILS";

/* folders */
export const GET_FOLDERS = "GET_FOLDERS";
export const LOADING_FOLDERS = "LOADING_FOLDERS";
export const END_LOADING_FOLDERS = "END_LOADING_FOLDERS";
export const ERROR_FOLDERS = "ERROR_FOLDERS";

export const GET_FOLDER = "GET_FOLDER";
export const LOADING_FOLDER = "LOADING_FOLDER";
export const END_LOADING_FOLDER = "END_LOADING_FOLDER";
export const ERROR_FOLDER = "ERROR_FOLDER";

export const CREATE_FOLDER = "CREATE_FOLDER";
export const CREATING_FOLDER = "CREATING_FOLDER";
export const END_CREATING_FOLDER = "END_CREATING_FOLDER";
export const ERROR_CREATING_FOLDER = "ERROR_CREATING_FOLDER";

export const DELETE_FOLDER = "DELETE_FOLDER";
export const DELETING_FOLDER = "DELETING_FOLDER";
export const END_DELETING_FOLDER = "END_DELETING_FOLDER";
export const ERROR_DELETING_FOLDER = "ERROR_DELETING_FOLDER";

export const UPDATING_FOLDER = "UPDATING_FOLDER";
export const END_UPDATING_FOLDER = "END_UPDATING_FOLDER";
export const ERROR_UPDATING_FOLDER = "ERROR_UPDATING_FOLDER";

/* repository */
export const GET_REPOSITORY = "GET_REPOSITORY";
export const LOADING_REPOSITORY = "LOADING_REPOSITORY";
export const END_LOADING_REPOSITORY = "END_LOADING_REPOSITORY";
export const ERROR_LOADING_REPOSITORY = "ERROR_LOADING_REPOSITORY";

export const CREATE_REPOSITORY = "CREATE_REPOSITORY";
export const CREATING_REPOSITORY = "CREATING_REPOSITORY";
export const END_CREATING_REPOSITORY = "END_CREATING_REPOSITORY";
export const ERROR_CREATING_REPOSITORY = "ERROR_CREATING_REPOSITORY";

/* configuration */
export const LOADING_CONFIGURATION = "LOADING_CONFIGURATION";
export const END_LOADING_CONFIGURATION = "END_LOADING_CONFIGURATION";
export const GET_CONFIGURATION_TIMESTAMP = "GET_CONFIGURATION_TIMESTAMP";
export const ERROR_CONFIGURATION = "ERROR_CONFIGURATION";

export const CHANGE_RESULT_FORMAT = "CHANGE_RESULT_FORMAT";

export const LOCK_UI = "LOCK_UI";
export const UNLOCK_UI = "UNLOCK_UI";

export const CHANGE_ATTACHMENT_VIEW = "CHANGE_ATTACHMENT_VIEW";

export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

export const LONG_REQUEST_PENDING = "LONG_REQUEST_PENDING";
export const END_LONG_REQUEST_PENDING = "END_LONG_REQUEST_PENDING";

export const SET_DIAGRAM_VALUES = "SET_DIAGRAM_VALUES";
export const SET_CHOOSE_ENTITIES = "SET_CHOOSE_ENTITIES";

export const OPEN_TOKEN_DIALOG = "OPEN_TOKEN_DIALOG";
export const CLOSE_TOKEN_DIALOG = "CLOSE_TOKEN_DIALOG";

/* license */
export const SHOW_LICENSE_BANNER = "SHOW_LICENSE_BANNER";
export const HIDE_LICENSE_BANNER = "HIDE_LICENSE_BANNER";

export const GET_LICENSE = "GET_LICENSE";
export const LOADING_LICENSE = "LOADING_LICENSE";
export const END_LOADING_LICENSE = "END_LOADING_LICENSE";
export const ERROR_LOADING_LICENSE = "ERROR_LOADING_LICENSE";

export const SET_LICENSE = "SET_LICENSE";
export const SETTING_LICENSE = "SETTING_LICENSE";
export const END_SETTING_LICENSE = "END_SETTING_LICENSE";
export const ERROR_SETTING_LICENSE = "ERROR_SETTING_LICENSE";

/* tokens */
export const LOADING_TOKENS = "LOADING_TOKENS";
export const END_LOADING_TOKENS = "END_LOADING_TOKENS";
export const ERROR_TOKENS = "ERROR_TOKENS";
export const GET_TOKENS = "GET_TOKENS";

export const CREATING_TOKEN = "CREATING_TOKEN";
export const END_CREATING_TOKEN = "END_CREATING_TOKEN";
export const ERROR_CREATING_TOKEN = "ERROR_CREATING_TOKEN";
export const CREATE_TOKEN = "CREATE_TOKEN";

export const REVOKING_TOKEN = "REVOKING_TOKEN";
export const END_REVOKING_TOKEN = "END_REVOKING_TOKEN";
export const ERROR_REVOKING_TOKEN = "ERROR_REVOKING_TOKEN";
export const REVOKE_TOKEN = "REVOKE_TOKEN";

export const GET_CONSTRAINT_ENTRIES = "GET_CONSTRAINT_ENTRIES";

/* export */
export const GET_DATE_ASSISTANT_OPTIONS = "GET_DATE_ASSISTANT_OPTIONS";
export const LOADING_DATE_ASSISTANT_OPTIONS = "LOADING_DATE_ASSISTANT_OPTIONS";
export const END_GETING_DATE_ASSISTANT_OPTIONS =
  "END_GETING_DATE_ASSISTANT_OPTIONS";
export const ERROR_GETING_DATE_ASSISTANT_OPTIONS =
  "ERROR_GETING_DATE_ASSISTANT_OPTIONS";

export const SET_EXPORT_DATES = "SET_EXPORT_DATES";
export const ADD_EXPORT_DATE = "ADD_EXPORT_DATE";
export const REMOVE_EXPORT_DATE = "REMOVE_EXPORT_DATE";

export const SET_EXPORT_FORMAT = "SET_EXPORT_FORMAT";
