import React, { Component } from "react";
import { connect } from "react-redux";
import IconButton from "../Buttons/IconButton";
import Parser, { domToReact } from "html-react-parser";
import {
  ReactSVGPanZoom,
  TOOL_AUTO,
  TOOL_ZOOM_IN,
  TOOL_ZOOM_OUT,
  TOOL_PAN
} from "react-svg-pan-zoom";
import { AutoSizer } from "react-virtualized";
import { setDiagramValues } from "../../actions/Workflow";
import { ZoomIn, ZoomOut, AspectRatio } from "@material-ui/icons";

import { Typography, CircularProgress } from "@material-ui/core";

class DiagramToolBar extends Component {
  onChangeTool = (event, tool) => {
    event.stopPropagation();
    event.preventDefault();
    const { onChangeTool } = this.props;
    onChangeTool(tool);
  };

  render() {
    return (
      <div
        style={{
          border: "solid 1px #eee",
          background: "rgba(255,255,255,0.8)",
          position: "absolute",
          top: 55,
          right: 10,
          borderRadius: "2px",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <IconButton
          onClick={e => {
            this.onChangeTool(e, TOOL_ZOOM_IN);
          }}
        >
          <ZoomIn style={{ userSelect: "none" }} />
        </IconButton>
        <IconButton
          onClick={e => {
            this.onChangeTool(e, TOOL_ZOOM_OUT);
          }}
        >
          <ZoomOut style={{ userSelect: "none" }} />
        </IconButton>
        <IconButton
          onClick={e => {
            this.onChangeTool(e, TOOL_PAN);
          }}
        >
          <AspectRatio style={{ userSelect: "none" }} />
        </IconButton>
      </div>
    );
  }
}

const HEIGHT_HIGHLIGHTED_DESCRIPTION = 17;

class Diagram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const { open } = nextProps;
    if (open && !this.props.open) {
      this.setState({
        init: false
      });
    }
  }
  componentWillUnmount() {
    if (this.viewer) {
      const { workflow, setDiagramValues } = this.props;
      setDiagramValues(workflow, this.viewer.getValue());
    }
  }

  initDiagram = () => {
    if (!this.state.init && this.viewer) {
      const { workflow } = this.props;
      this.setState({
        init: true
      });
      if (workflow.diagramValues) {
        this.viewer.setValue(workflow.diagramValues);
      } else {
        this.viewer.fitToViewer();
      }
    }
  };

  onChangeTool = tool => {
    if (!this.viewer) {
      return;
    }
    switch (tool) {
      case TOOL_ZOOM_IN:
        this.viewer.zoomOnViewerCenter(1.2);
        break;
      case TOOL_ZOOM_OUT:
        this.viewer.zoomOnViewerCenter(0.8);
        break;
      case TOOL_PAN:
        this.viewer.fitToViewer();
        break;
      default:
        break;
    }
  };

  render() {
    const { workflow } = this.props;
    if (!workflow) {
      return null;
    }
    if (!workflow.diagram && workflow.loading_diagram) {
      return (
        <div
          style={{
            lineHeight: "calc(100vh - 116px)",
            margin: "20px",
            width: "100%",
            textAlign: "center"
          }}
        >
          <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
          <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
            Loading the diagram
          </span>
        </div>
      );
    } else if (!workflow.diagram) {
      return (
        <div
          style={{
            lineHeight: "calc(100vh - 116px)",
            margin: "20px",
            width: "100%",
            textAlign: "center"
          }}
        >
          <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
            No diagram found
          </span>
        </div>
      );
    } else {
      const options = {
        replace: ({ attribs, children }) => {
          if (attribs && attribs.viewBox) {
            const [, , width, height] = attribs.viewBox.split(" ");
            return (
              <svg {...attribs} width={width} height={height}>
                {domToReact(children, options)}
              </svg>
            );
          }
        }
      };
      return (
        <React.Fragment>
          <AutoSizer>
            {({ width, height }) =>
              width === 0 || height === 0 ? null : (
                <ReactSVGPanZoom
                  ref={viewer => {
                    this.viewer = viewer;
                    this.initDiagram();
                  }}
                  miniaturePosition="none"
                  detectAutoPan={false}
                  onChangeTool={this.onChangeTool}
                  width={width}
                  background="white"
                  height={height - HEIGHT_HIGHLIGHTED_DESCRIPTION}
                  customToolbar={DiagramToolBar}
                  tool={TOOL_AUTO}
                >
                  {Parser(workflow.diagram, options)}
                </ReactSVGPanZoom>
              )
            }
          </AutoSizer>
          <Typography
            variant="caption"
            style={{ position: "absolute", bottom: 0, left: 10 }}
          >
            Current step is highlighted in red
          </Typography>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = () => {};

export default connect(
  mapStateToProps,
  { setDiagramValues }
)(Diagram);
