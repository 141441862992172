import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import InputField from "components/Form/Fields/InputField";

class WorkflowSearchBar extends Component {
  render() {
    const { workflowLabel, searchWorkflowName, onChange } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item>
          <InputField
            style={{
              width: 200
            }}
            placeholder={`Filter by ${workflowLabel} name`}
            meta={{}}
            input={{
              name: "name",
              onChange,
              value: searchWorkflowName
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default WorkflowSearchBar;
