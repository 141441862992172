import {
  getEntityGetAttachmentsParam, getEntityType, getEntityTypeObject, MODEL_REVISION_TYPE,
  MODEL_TYPE, VALIDATION_TYPE, VALIDATION_COMMITTEE_TYPE,
  PERSONAL_FOLDER_TYPE,
  PUBLIC_FOLDER_TYPE,
  SHARED_FOLDER_TYPE,
} from './Entity';
import { getCurrentURLParams } from "./GUI";
import qs from "querystring";
import {STORAGE_TYPE_DOCUMENTS} from "components/Attachments/Search";
import { getEntityLabel } from 'api/Entity';

function getApiUrl(entityType, typeOfOwner, ownerId) {
  switch (entityType) {
    case PERSONAL_FOLDER_TYPE:
      return '/api/personal/storage/attachments';
    case PUBLIC_FOLDER_TYPE:
      return '/api/public/storage/attachments';
    default:
      return `/api/${typeOfOwner}/${ownerId}/attachments`;
  }
}

export function getEntityDetail(entity) {
  let ownerId = entity.id;
  const ownerType = getEntityTypeObject(entity);
  const ownerTypeId = ownerType && ownerType.id;
  let entityType = getEntityType(entity);
  const entityTypeLabel = getEntityLabel(entityType, false);
  let typeOfOwner = getEntityGetAttachmentsParam(entityType);
  if (entityType === MODEL_REVISION_TYPE) {
    if (entity.model) {
      ownerId = entity.model.id;
      entityType = MODEL_TYPE;
    }
  }

  return {
    ownerId,
    ownerTypeId,
    entityType,
    entityTypeLabel,
    typeOfOwner
  };
}

export function buildAttachment(fileDetail, uploadDetail, user) {
  return {
    id: uploadDetail.attachmentId,
    objectName: uploadDetail.objectName,
    uploadLink: uploadDetail.uploadLink,
    prefix: uploadDetail.prefix,
    category: uploadDetail.category,
    entity: uploadDetail.entity,
    label: uploadDetail.fileName,
    ownerId: uploadDetail.ownerId,
    fileName: uploadDetail.fileName,
    mimeType: uploadDetail.mimeType,
    fileSize: fileDetail.size,
    uploaderLabel: user.label,
    uploaderId: user.id,
    uploadDate: new Date(),
  };
}

export function areAttachmentsEqual(attachment, otherAttachment) {
  return (
    attachment &&
    otherAttachment &&
    (isFolderTypeAttachment(attachment) ||
      attachment.ownerId === otherAttachment.ownerId) &&
    attachment.objectName === otherAttachment.objectName
  );
}

export function isFolderTypeAttachment(attachment) {
  const attachmentEntity = attachment && attachment.entity;
  return (
    attachmentEntity === PERSONAL_FOLDER_TYPE ||
    attachmentEntity === PUBLIC_FOLDER_TYPE
  );
}

export function generateUploadDetailRequestLink(entityDetail) {
  return getApiUrl(entityDetail.entityType, entityDetail.typeOfOwner, entityDetail.ownerId) + '/upload-details';
}

export function getDownloadLink(file, isDocument, getLatest) {
  const { fileName, entity, ownerId, metadata, prefix, isFolder } = file;

  if (isFolder) {
    const query = getCurrentURLParams(location.search);
    query["folder"] =
      query && query["folder"] ? query["folder"] + fileName : "/" + fileName;
    return location.origin + location.pathname + "?" + qs.stringify(query);
  }

  let params = `?prefix=${prefix}`;
  if (metadata && metadata.revision) {
    params += `&metadata=revision${metadata.revision}`;
  }
  if (getLatest) {
    params += `&latest=true`;
  }

  if (isDocument) {
    return encodeURI(
      `${
        location.origin
      }/api/document-management/${getEntityGetAttachmentsParam(
        entity
      )}/${ownerId}/attachments/downloadlink/${fileName}${params}`
    );
  } else if (file.entity === PERSONAL_FOLDER_TYPE) {
    return encodeURI(
      `${location.origin}/api/personal/storage/attachments/downloadlink/${fileName}${params}`
    );
  } else if (file.entity === PUBLIC_FOLDER_TYPE) {
    return encodeURI(
      `${location.origin}/api/public/storage/attachments/downloadlink/${fileName}${params}`
    );
  }
  return encodeURI(
    `${location.origin}/api/${getEntityGetAttachmentsParam(
      entity
    )}/${ownerId}/attachments/downloadlink/${fileName}${params}`
  );
}

export function generateAttachmentDatalabLinkRequestURL(file) {
  const { fileName, ownerId, prefix } = file;

  let params = `?prefix=${prefix}`;

  if (file.hasOwnProperty('objectName') && !file.objectName.startsWith(STORAGE_TYPE_DOCUMENTS)) {
    switch (file.entity) {
      case MODEL_TYPE:
        return encodeURI(`/api/models/${ownerId}/attachments/datalab-link/${fileName}${params}`);
      case VALIDATION_TYPE:
        return encodeURI(`/api/validations/${ownerId}/attachments/datalab-link/${fileName}${params}`);
      case VALIDATION_COMMITTEE_TYPE:
        return encodeURI(`/api/validation-committees/${ownerId}/attachments/datalab-link/${fileName}${params}`);
      case PUBLIC_FOLDER_TYPE:
        return encodeURI(`/api/public/storage/attachments/datalab-link/${fileName}${params}`);
      case SHARED_FOLDER_TYPE:
        return encodeURI(`/api/shared-folders/${ownerId}/attachments/datalab-link/${fileName}${params}`);
      case PERSONAL_FOLDER_TYPE:
        return encodeURI(`/api/personal/storage/attachments/datalab-link/${fileName}${params}`);
      default:
        return null;
    }
  }

  return null;
}
