import React, { Component } from 'react';

class Image extends Component {
    state = {
        hide: false
    };

    onError = () => {
        this.setState({
            hide: true
        });
    };

    render() {
        const { hide } = this.state;

        if (hide) {
            return null;
        }
        return (
            <img {...this.props} onError={this.onError}/>
        );
    }
}
export default Image;