export const ACCESS_TOKENS = "ACCESS_TOKENS";
export const AUTOMATION_WORKFLOWS = "AUTOMATION_WORKFLOWS";
export const BUSINESS_RULES = "BUSINESS_RULES";
export const DATAHUB_PERSONAL = "DATAHUB_PERSONAL";
export const DATAHUB_PUBLIC = "DATAHUB_PUBLIC";
export const DATAHUB_SHARED_FOLDER = "DATAHUB_SHARED_FOLDER";
export const DATALAB = "DATALAB";
export const DOCUMENT_MANAGEMENT = "DOCUMENT_MANAGEMENT";
export const ENTITY_LINKS = "ENTITY_LINKS";
export const FULLTEXT_SEARCH = "FULLTEXT_SEARCH";
export const GIT = "GIT";
export const HEARTBEAT = "HEARTBEAT";
export const MOCK_HOMEPAGE = "MOCK_HOMEPAGE";
export const MODEL_MONITORING = "MODEL_MONITORING";
export const SAVED_SEARCH = "SAVED_SEARCH";
export const VALUE_HISTORY_RETRIEVE = "VALUE_HISTORY_RETRIEVE";
export const WORKFLOW_AUDIT_TRAIL = "WORKFLOW_AUDIT_TRAIL";
export const PROCESS_VARIABLES_SEARCH = "PROCESS_VARIABLES_SEARCH";
export const ENTITY_OVERVIEW_EXPORT = "ENTITY_OVERVIEW_EXPORT";
export const ODATA = "ODATA";
