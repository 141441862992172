import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

class DrawerContent extends Component {
    render() {
        const { classes, children, onScroll } = this.props;
        return (
            <main className={classes.content} onScroll={onScroll}>
                { children }
            </main>
        );
    }
}

const styles = theme => ({
    content: {
        flexGrow: 1,
        height: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    }
});

export default withStyles(styles, { withTheme: true })(DrawerContent);
