import React, { Component } from "react";
import RestrictedComponent from "../RestrictedComponent";
import { Tab as TabMaterial } from "@material-ui/core";

class Tab extends Component {
  render() {
    return (
      <RestrictedComponent
        testid={this.props.label}
        componentButton={TabMaterial}
        {...this.props}
      />
    );
  }
}

export default Tab;
