import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSorting, getValueLabel } from "../../api/Sort";
import { AutoSizer } from "react-virtualized";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
  Checkbox
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    if (!property.unsortable) {
      this.props.onRequestSort(event, property);
    }
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      columnDatas,
      selectable,
      unsortable
    } = this.props;
    let checkbox;
    if (selectable) {
      checkbox = (
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
      );
    }

    return (
      <TableHead>
        <TableRow>
          {checkbox}
          {columnDatas.map(column => {
            if (column.hide) {
              return;
            }
            let cell;
            const isSortable = !(unsortable || column.unsortable);
            if (!isSortable) {
              cell = column.label;
            } else {
              cell = (
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.name}
                    direction={order}
                    onClick={this.createSortHandler(column)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              );
            }
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={
                  isSortable && column.disablePadding ? "none" : "default"
                }
                sortDirection={orderBy === column.name ? order : false}
              >
                {cell}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired
};

export { EnhancedTableHead };

const styles = theme => ({
  root: {
    maxWidth: 750,
    margin: "auto",
    padding: "0",
    boxShadow: "none"
  },
  table: {
    tableLayout: "auto"
  },
  tableWrapper: {
    overflowX: "auto",
    height:"100%"
  }
});

const THEAD_HEIGHT = 50;

class AttributesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: props.order,
      orderBy: props.orderBy,
      checkAlsoModel: false,
      datas: props.datas,
      page: 0,
      rowsPerPage: props.rowsPerPage || 10
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.datas !== this.props.datas) {
      let { datas } = nextProps;
      this.setState({
        datas: datas
      });
    }
  }

  handleRequestSort = (event, property) => {
    const { onRequestSort } = this.props;
    const orderBy = property.name;
    const checkAlsoModel = property.checkAlsoModel;
    let order = "desc";

    if (this.state.orderBy === property.name && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy, checkAlsoModel });
    if (onRequestSort) {
      onRequestSort(order, orderBy);
    }
  };

  handleSelectAllClick = (event, checked) => {
    const { onSelectionChange, selected, selectAllOverPagination } = this.props;
    if (checked) {
      const { datas } = selectAllOverPagination ? this.props : this.state;
      let newSelected = selected;
      for (let i = 0; datas && i < datas.length; i++) {
        const donnee = datas[i];
        newSelected = this.selectRow(donnee, true, newSelected);
      }
      onSelectionChange(newSelected);
      return;
    }
    onSelectionChange([]);
  };
  handleClickCheckbox = (event, n) => {
    const { onSelectionChange, selected, excludeIds } = this.props;
    const excluded = excludeIds && excludeIds.includes(n.id);
    if(!excluded){
      onSelectionChange(this.selectRow(n, !this.isSelected(n.id), selected));
    }
    event.stopPropagation();
    event.preventDefault();
  };

  selectRow = (n, checked, selected) => {
    const id = n.id;
    const selectedIndex = this.getSelectedIndex(id);

    if (checked) {
      if (selectedIndex === -1) {
        selected.push(n);
      }
    } else {
      selected.splice(selectedIndex, 1);
    }
    return selected;
  };

  handleClick = (event, n) => {
    const { selectableOnRowClick, handleClickRow, excludeIds } = this.props;
    const excluded = excludeIds && excludeIds.includes(n.id);
    if (handleClickRow && !excluded) {
      handleClickRow(n);
    } else if (selectableOnRowClick) {
      this.handleClickCheckbox(event, n);
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  /*
    Comparison function for column objects in the entity overview table.
    If a column is marked with the 'headColumn' flag, it is taken as 'lower' in the comparison (goes to the head of the column list).
    If a column is marked with the 'tailColumn' flag, it is taken as 'higher' in the comparison (goes to the tail of the column list).
    Columns that are not marked by any of these flags are sorted alphabetically.
  */
  compareTableColumns = (c1, c2) => {
    if (c1.headColumn && c2.headColumn && c1.label && c2.label) {
      return c1.label.localeCompare(c2.label);
    }
    if (c1.headColumn) {
      return -1;
    }
    if (c2.headColumn) {
      return 1;
    }
    if (c1.tailColumn && c2.tailColumn && c1.label && c2.label) {
      return c1.label.localeCompare(c2.label);
    }
    if (c2.tailColumn) {
      return -1;
    }
    if (c1.tailColumn) {
      return 1;
    }
    if (c1.label && c2.label) {
      return c1.label.localeCompare(c2.label);
    }
    return 1;
  };

  renderValue = value => {
    if (!value && value !== 0) {
      return;
    }
    return value;
  };

  renderTable = (width, height) => {
    const {
      classes,
      columnDatas,
      fillEmptyRows,
      selectable,
      selected,
      selectAllOverPagination,
      withPagination,
      componentRow,
      propsRow,
      unsortable,
      excludeIds
    } = this.props;
    const {
      datas,
      order,
      orderBy,
      checkAlsoModel,
      rowsPerPage,
      page
    } = this.state;
    const sortedColumnData = columnDatas.sort(this.compareTableColumns);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, datas.length - page * rowsPerPage);
    let numSelected = 0, body;

    if (datas && datas.map) {
      let datas2Show = datas;
      if (withPagination) {
        datas2Show = datas
          .sort(getSorting(order, orderBy, checkAlsoModel))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      }
      body = (
        <TableBody style={height ? { height: height - THEAD_HEIGHT } : null}>
          {datas2Show.map((n, idx) => {
            const excluded = excludeIds && excludeIds.includes(n.id);
            const isSelected = this.isSelected(n.id);
            if (isSelected) {
              numSelected++;
            }
            let checkbox;
            if (selectable) {
              checkbox = (
                <TableCell
                  onClick={event => this.handleClickCheckbox(event, n)}
                  padding="checkbox"
                >
                  <Checkbox checked={isSelected} disabled={excluded} />
                </TableCell>
              );
            }
            let otherProps;
            if (propsRow) {
              otherProps = propsRow(n);
            }
            return (
              <TableRow
                hover={!excluded}
                component={componentRow}
                {...otherProps}
                style={{ cursor: excluded ? "default" : "pointer", height: 25 }}
                onClick={event => this.handleClick(event, n)}
                role="checkbox"
                tabIndex={-1}
                key={idx}
              >
                {checkbox}
                {sortedColumnData.map((column, idx2) => {
                  if (column.hide) {
                    return;
                  }
                  const valueLabel = getValueLabel(column, n);
                  return (
                    <TableCell
                      padding={column.disablePadding ? "none" : "default"}
                      style={{color: excluded? "gray" : "inherit"}}
                      key={idx2}
                    >
                      {this.renderValue(valueLabel)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {fillEmptyRows && emptyRows > 0 && (
            <TableRow style={{ height: 49 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      );
    }

    return (
      <Table className={classes.table} aria-labelledby="tableTitle">
        <EnhancedTableHead
          unsortable={unsortable}
          columnDatas={sortedColumnData}
          numSelected={selectAllOverPagination ? selected.length : numSelected}
          selectable={selectable}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={this.handleSelectAllClick}
          onRequestSort={this.handleRequestSort}
          rowCount={
            selectAllOverPagination ? this.props.datas.length : datas.length
          }
        />
        {body}
      </Table>
    );
  };

  getSelectedIndex = id => {
    const selected = this.props.selected;
    for (let i = 0; selected && i < selected.length; i++) {
      const select = selected[i];
      if (select.id === id) {
        return i;
      }
    }
    return -1;
  };
  isSelected = id => {
    return this.getSelectedIndex(id) !== -1;
  };

  render() {
    const {
      classes,
      style,
      tableWrapperStyle,
      withPagination,
      useAutoSizer
    } = this.props;
    const { datas, rowsPerPage, page } = this.state;

    let pagination;

    if (withPagination) {
      pagination = (
        <TablePagination
          component="div"
          count={datas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      );
    }

    return (
      <Paper className={classes.root} style={style}>
        <div className={classes.tableWrapper} style={tableWrapperStyle}>
          {useAutoSizer ? (
            <AutoSizer>
              {({ width, height }) =>
                width === 0 || height === 0
                  ? null
                  : this.renderTable(width, height)
              }
            </AutoSizer>
          ) : (
            this.renderTable()
          )}
        </div>
        {pagination}
      </Paper>
    );
  }
}

const mapStateToPropsAttributesTable = (state, ownProps) => {
  return {
    selected: ownProps.selected || [],
    onFilter: ownProps.onFilter
  };
};

export default withStyles(styles)(
  connect(
    mapStateToPropsAttributesTable,
    {}
  )(AttributesTable)
);
