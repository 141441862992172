import axios from 'axios';

import { generateUploadDetailRequestLink, getEntityDetail } from 'api/Attachments';
import {
  ADD_FILE_GROUP,
  DELETE_FILE_GROUPS,
} from 'constants/ActionTypes';
import GenerateUUID from 'api/GenerateUUID';
import { ATTACHMENT_PLATFORM } from 'constants/Attachments';
import { generateDocumentUploadDetailRequestLink } from 'api/Documents';

export function addFileGroup(
  files,
  entity,
  categoryId,
  prefix,
  platform = ATTACHMENT_PLATFORM.DATAHUB,
) {

  return function (dispatch) {
    const groupId = GenerateUUID();
    const taggedFiles = files.map((file, idx) => {
      return {
        id: `${idx}_${groupId}`,
        file,
      };
    });

    const entityDetail = getEntityDetail(entity);
    const uploadDetailRequestLink = getUploadDetailRequestLink(entityDetail, platform);

    const group = {
      groupId,
      entityDetail,
      uploadDetailRequestLink,
      prefix,
      categoryId,
      platform,
      taggedFiles,
    };

    dispatch({
      type: ADD_FILE_GROUP,
      group,
    });
  };
}

function getUploadDetailRequestLink(entityDetail, platform) {
  if (platform === ATTACHMENT_PLATFORM.DOCUMENTS) {
    return generateDocumentUploadDetailRequestLink(entityDetail.typeOfOwner, entityDetail.ownerId);
  } else {
    return generateUploadDetailRequestLink(entityDetail);
  }
}

export function deleteFileGroupBy(groupIds) {
  return function (dispatch) {
    dispatch({
      type: DELETE_FILE_GROUPS,
      groupIds,
    });
  };
}

export const getUploadDetail = async (group, file, rewriteDuplicate, cancel) => {
  return (await axios.post(
    group.uploadDetailRequestLink,
    {
      fileName: file.name,
      mimeType: file.type,
      category: group.categoryId,
      prefix: group.prefix,
      rewriteDuplicate,
    },
    {
      withCredentials: true,
      cancelToken: cancel.token,
    },
  )).data;
};

export const createDownloadLink = async (href) => {
  return await axios.post(href);
};

export const uploadFile = async (uploadDetail, file, cancel, onProgressChange) => {
  await axios.put(uploadDetail.uploadLink, file, {
    headers: {
      'Content-Type': uploadDetail.mimeType,
    },
    withCredentials: false,
    onUploadProgress: (progressEvent) => onProgressChange(progressEvent),
    cancelToken: cancel.token,
  });
};
