import React, { Component } from "react";
import QueueAnim from "rc-queue-anim";
import { APP_OVERVIEW_PATH } from "../../../constants/Routes";
import { Link } from "react-router-dom";
import Button from "../../../components/Buttons/Button";
import Header from "../../../components/Header";
import { setTitle } from "../../../api/GUI";

class Error404 extends Component {
  render() {
    setTitle("404 Page not found");
    return (
      <div>
        <Header />
        <div className="err-container text-center">
          <div className="err">
            <h1>404</h1>
            <h2>Sorry, page not found</h2>
          </div>

          <div className="err-body">
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to={APP_OVERVIEW_PATH}
            >
              Go Back to Home Page
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const Page = () => (
  <div className="page-err">
    <QueueAnim type="bottom" className="ui-animate">
      <div key="1">
        <Error404 />
      </div>
    </QueueAnim>
  </div>
);

export default Page;
