import React from "react";
import MonitoringTable from "components/ModelMonitoring/MonitoringTable";
import {ROLE_MODEL_MONITORING_VIEW} from "../../../../constants/Authorities";
import RestrictedComponent from "components/RestrictedComponent";
import {MODEL_MONITORING} from "../../../../constants/Features";

const ModelMonitoringOverview = () => {
    return <div style={{margin: "0 32px", padding: "10px 0px", height:"100%"}}>
        <MonitoringTable />
    </div>
};

export default () => {
    return (
        <RestrictedComponent
            componentButton={ModelMonitoringOverview}
            featuresRequired={[MODEL_MONITORING]}
            authoritiesRequired={[ROLE_MODEL_MONITORING_VIEW]}
        />
    );
};
