import React from "react";
import {reduxForm} from "redux-form";
import FilterModelsForm from "./FilterModelsForm";
import {scrollToFirstError} from "../../api/Error"

const CreateFilterModelsForm = reduxForm({
  form: "filtermodels",
  onSubmitFail: scrollToFirstError
})(({
      handleSubmit,
      onSaveSearch,
      onClearFilter,
      initialValues,
      form,
      entityType
    }) => {
  return (
    <FilterModelsForm
      entityType={entityType}
      isNew
      formName={form}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      onSaveSearch={onSaveSearch}
      onClearFilter={onClearFilter}
    />
  );
});

export default CreateFilterModelsForm;