import React, { Component } from "react";
import { HelpOutlineRounded } from "@material-ui/icons";
import moment from "moment";
import { Field } from "redux-form";
import DateField from "components/Form/Fields/DateField";
import SelectField from "components/Form/Fields/SelectField";
import InputField from "components/Form/Fields/InputField";
import CheckboxField from "components/Form/Fields/CheckboxField";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import {
  OnlyIntegerValidator,
  OnlyNumberValidator,
  RequiredValidator
} from "components/Form/Fields/FieldValidators";
import { DateTimeValidator } from "components/Form/Fields/FieldValidators";
import {
  Tooltip,
  MenuItem,
  Paper,
  withStyles,
  Grid,
  Typography
} from "@material-ui/core";
import IconButton from "components/Buttons/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import RedirectLogo from "components/Account/RedirectLogo";
import {isValidURL} from "api/Links";

export function getValue(field) {
  const { value, standaloneClassName } = field;
  if (!value || value === "null") {
    return undefined;
  }
  switch (standaloneClassName) {
    case "java.lang.Integer":
    case "FLOAT":
      return Number(value);
    case "DECIMAL":
      return String(value);
    case "java.time.LocalDate":
      return moment(value);
    case "java.lang.Boolean":
      return Boolean(value);
    default:
      return String(value);
  }
}

class WorkflowField extends Component {

  constructor(props) {
    super(props);
    const { field } = props;
    this.state = {
      url: isValidURL(getValue(field))? getValue(field): false
    };
  }

  handleOnChange = (e) => {
    const inputValue = e && e.target && e.target.value;
    this.setState({
      url: isValidURL(inputValue) ? inputValue : false
    });
  }

  addUrlAdornment = (inputProps) => {
    const { url } = this.state;
    inputProps.onChange = this.handleOnChange;
    inputProps.endAdornment = (url &&
      <InputAdornment position="end">
        <a href={url} target="_blank">
          <IconButton
            style={{ marginRight: "0"}}
            aria-label="redirect"
            edge="end"
          >
            <RedirectLogo height="0.7em" width="0.7em"/>
          </IconButton>
        </a>
      </InputAdornment>
    );
  }

  renderValue = () => {
    const { field } = this.props;
    const id = field.name;
    let InputComponent,
      withTypography = false,
      InputProps = {},
      validators = [];
    switch (field.code) {
      case "TextBox":
        InputComponent = InputField;
        InputProps.multiline = true;
        this.addUrlAdornment(InputProps);
        break;
      case "DatePicker":
        InputComponent = DateField;
        validators.push(DateTimeValidator);
        break;
      case "CheckBox":
        InputComponent = CheckboxField;
        break;
      case "TextArea":
        InputComponent = InputField;
        InputProps.multiline = true;
        this.addUrlAdornment(InputProps);
        break;
      case "ListBox":
        if (field.options.length === 1 && field.options[0].value === "#User#") {
          return (
            <Field
              label={"Users"}
              name={field.name}
              component={AutoSuggestSelectField}
              validate={[RequiredValidator]}
              apiUrl="/api/users/lookup"
              apiValueFromIdUrl="/api/users/public-info"
              ref="user"
              suggestionFieldName={field.options[0].text}
            />
          );
          break;
        }

        InputComponent = SelectField;
        InputProps.displayEmpty = true;
        InputProps.children = field.options.map(function (option, idx) {
          return (
            <MenuItem key={idx} value={option.value}>
              {option.text}
            </MenuItem>
          );
        });

        if (field.addEmptyOption) {
          InputProps.children.unshift(
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          );
        }
        break;
      case "IntegerBox":
        InputComponent = InputField;
        validators.push(OnlyIntegerValidator);
        break;
      case "DecimalBox":
        InputComponent = InputField;
        validators.push(OnlyNumberValidator);
        break;
    }
    if (!InputComponent) {
      console.log(field);
      return <div>This field can't rendered {field.code}</div>;
    }
    let disabled;
    if (field.required) {
      validators.push(RequiredValidator);
    }
    if (field.readOnly === true || field.readOnly === "true") {
      disabled = true;
    }
    const fieldComponent = (
      <Field
        {...InputProps}
        name={id}
        placeholder={field.placeHolder}
        component={InputComponent}
        validate={validators}
        disabled={disabled}
        withTimeFormatting
        ref={id}
      />
    );

    if (withTypography) {
      return <Typography variant="body1">{fieldComponent}</Typography>;
    }

    return fieldComponent;
  };

  render() {
    const { field, classes } = this.props;

    let valueClasses = classes.value;

    return (
      <Paper className={classes.container}>
        <Grid container>
          <Grid item className={classes.name}>
            <Typography variant="caption" className={classes.label}>
              {field.label}:
            </Typography>
            {field.helpMessage ? (
              <Tooltip title={field.helpMessage}>
                <HelpOutlineRounded className={classes.iconHelp} />
              </Tooltip>
            ) : null}
          </Grid>
          <Grid item className={valueClasses}>
            {this.renderValue()}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const styles = theme => ({
  name: {
    position: "relative",
    textAlign: "right",
    display: "inline-flex",
    paddingRight: "10px",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "150px"
  },
  label: {
    flexShrink: 1,
    flexWrap: "wrap"
  },
  iconHelp: {
    marginLeft: 5
  },
  value: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1
  },
  container: {
    padding: "10px",
    margin: "10px"
  }
});

export default withStyles(styles)(WorkflowField);
