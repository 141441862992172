import reduceAttributes from "../../api/ReduceAttributes";
import { MODEL_USAGE_TYPE } from "../../api/Entity";
import reduceDeleteEntity from "../../api/ReduceDeleteEntity";
import reduceCreateWorkflows from "../../api/ReduceCreateWorkflows";
import reduceWorkflows from "../../api/ReduceWorkflows";

export default function(state = {}, action) {
  return {
    ...state,
    current: reduceCreateWorkflows(
      reduceWorkflows(
        reduceDeleteEntity(
          reduceAttributes(state.current, action, MODEL_USAGE_TYPE),
          action
        ),
        action
      ),
      action
    )
  };
}
