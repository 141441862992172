import React from "react";
import Button from "../Buttons/Button";
import { CircularProgress, Grid } from "@material-ui/core";

class EmptyList extends React.Component {
  render() {
    const {
      loading,
      loadingTitle,
      title,
      buttonTitle,
      onCreate,
      authoritiesRequired
    } = this.props;
    if (loading) {
      return (
        <div
          style={{
            lineHeight: "36px",
            textAlign: "center",
            margin: "20px auto 0"
          }}
        >
          <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
          <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
            {loadingTitle}
          </span>
        </div>
      );
    } else {
      return (
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <div className="emptyMessage">{title}</div>
          </Grid>
          <Grid item>
            {onCreate && (
              <Button
                authoritiesRequired={authoritiesRequired}
                onClick={onCreate}
                variant="outlined"
                color="primary"
              >
                {buttonTitle}
              </Button>
            )}
          </Grid>
        </Grid>
      );
    }
  }
}

export default EmptyList;
