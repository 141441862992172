import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import {getWorkflowClassifications} from "actions/Workflow/types";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import {RequiredValidator} from "components/Form/Fields/FieldValidators";

class InputClassifications extends Component {
    componentDidMount() {
        const { getWorkflowClassifications } = this.props;
        getWorkflowClassifications();
    }

    render() {
        const { classifications } = this.props;

        return (
            <Field
                label="Classification"
                name="classification"
                component={AutoSuggestSelectField}
                validate={[RequiredValidator]}
                withEmptyOpt={true}
                ref="classification"
                options={
                    classifications && classifications.map((classification) => (
                        {
                            id: classification,
                            label: classification
                        })
                    )} />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        classifications: state.workflowTypes.classifications
    };
};
export default connect(
    mapStateToProps, { getWorkflowClassifications }
)(InputClassifications);
