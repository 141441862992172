import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

class Drawer extends Component {
    render() {
        const { classes, children } = this.props;

        return (
            <div className={classes.root}>
                { children }
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    }
});

export default withStyles(styles, { withTheme: true })(Drawer);
