import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../../Buttons/ButtonLoading";
import { reduxForm, Field } from "redux-form";
import InputField from "../../Form/Fields/InputField";
import {
  RequiredValidator,
  NonEmptyStringValidator
} from "../../Form/Fields/FieldValidators";
import InputClassifications from "components/Admin/WorkflowTypes/InputClassifications";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import { scrollToFirstError } from "../../../api/Error";

class CreateWorkflowTypeForm extends Component {
  constructor(props) {
    super(props);
    const { initialValues } = props;
    this.state = {
      containerId:
        initialValues && initialValues.containerId
          ? initialValues.containerId
          : null
    };
  }

  onContainerSelected = value => {
    this.setState({
      containerId: value
    });
  };

  render() {
    const {
      handleSubmit,
      error_creating,
      creating,
      processes,
      initialValues
    } = this.props;
    const { containerId } = this.state;

    const containers = processes && Object.keys(processes);

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Field
          name="name"
          label="Name"
          component={InputField}
          validate={[RequiredValidator, NonEmptyStringValidator]}
          ref="name"
        />
        <InputClassifications />
        <Field
          name="description"
          label="Description"
          component={InputField}
          multiline
          validate={[RequiredValidator, NonEmptyStringValidator]}
          ref="description"
        />
        <Field
          label="Container"
          name="containerId"
          onChangeValue={this.onContainerSelected}
          component={AutoSuggestSelectField}
          validate={[RequiredValidator, NonEmptyStringValidator]}
          withEmptyOpt={true}
          ref="containerId"
          options={
            containers &&
            containers.map(container => ({
              id: container,
              label: container
            }))
          }
        />
        <Field
          label="Process"
          name="processId"
          disabled={!containerId}
          component={AutoSuggestSelectField}
          validate={[RequiredValidator, NonEmptyStringValidator]}
          withEmptyOpt={true}
          ref="processId"
          options={
            processes &&
            processes[containerId] &&
            processes[containerId].map(process => ({
              id: process,
              label: process
            }))
          }
        />
        <ButtonLoading
          loading={initialValues ? initialValues.updating : creating}
          loadingTitle={
            initialValues
              ? "Updating the workflow type"
              : "Creating the workflow type"
          }
          style={{ margin: "20px 0" }}
          className="color-primary"
          type="submit"
          variant="outlined"
          color="primary"
        >
          {initialValues
            ? "Update the workflow type"
            : "Create the workflow type"}
        </ButtonLoading>
        <div className="error">
          {initialValues ? initialValues.error : error_creating}
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    error_creating: state.workflowTypes.error_creating,
    creating: state.workflowTypes.creating,
    processes: state.workflowTypes.processes
  };
};

CreateWorkflowTypeForm = reduxForm({
  form: "createworkflowtypeform",
  onSubmitFail: scrollToFirstError
})(CreateWorkflowTypeForm);

export default connect(
  mapStateToProps,
  {}
)(CreateWorkflowTypeForm);
