import axios from "axios";
import {API_GET_WIDGETS} from "constants/ApiEndpoints";

export function getWidgets() {
  return axios
    .get(API_GET_WIDGETS,{
      withCredentials: true
    });
}
export function createWidget(widget) {
  return axios
    .post("/api/widgets", widget, {
      withCredentials: true
    });
}
export function evaluateWidget(widgetId, variables, parameters) {
  return axios
    .post(
      "/api/widgets/evaluate",
      {
        widgetId: widgetId,
        variables: variables,
        parameters: parameters
      },
      {
        withCredentials: true
      }
    );
}
