import {
  LOADING_ATTACHMENTS_TYPES,
  ATTACHMENTS_TYPES_ERROR,
  END_LOADING_ATTACHMENTS_TYPES,
  GET_ATTACHMENTS_TYPES,
  CREATING_FOLDER_ATTACHMENT,
  END_CREATING_FOLDER_ATTACHMENT,
  LOADING_DOCUMENT_HISTORY,
  END_LOADING_DOCUMENT_HISTORY,
  ERROR_GET_DOCUMENT_HISTORY,
  GET_DOCUMENT_HISTORY,
  LOADING_ATTACHMENTS_SEARCH,
  END_LOADING_ATTACHMENTS_SEARCH,
  GET_ATTACHMENTS_SEARCH,
  ERROR_ATTACHMENTS_SEARCH,
  ERROR_CREATING_FOLDER_ATTACHMENT,
  GET_ATTACHMENTS_LOGS,
  LOADING_ATTACHMENTS_LOGS,
  END_LOADING_ATTACHMENTS_LOGS,
  ATTACHMENTS_LOGS_ERROR,
  ATTACHMENT_CONFLICT_ERROR,
  ATTACHMENT_CONFLICT_MANAGED,
  DRAG_ATTACHMENTS,
  END_DRAGING_ATTACHMENTS,
} from "constants/ActionTypes";
import { areAttachmentsEqual } from "api/Attachments";

export default function(state = {}, action) {
  if (!state.types) {
    state.types = {};
  }
  if (!state.conflicts) {
    state.conflicts = [];
  }

  switch (action.type) {
    case ATTACHMENT_CONFLICT_ERROR:
      return {
        ...state,
        conflicts: [...state.conflicts, action.conflict]
      };
    case ATTACHMENT_CONFLICT_MANAGED:
      return {
        ...state,
        conflicts:
          state.conflicts &&
          state.conflicts.filter(({ id }) => id !== action.id)
      };

    case GET_ATTACHMENTS_TYPES:
      state.types[action.entityType] = {
        ...state.types[action.entityType],
        list: action.types,
        error: undefined
      };
      return {
        ...state,
        types: {
          ...state.types
        }
      };
    case LOADING_ATTACHMENTS_TYPES:
      state.types[action.entityType] = {
        ...state.types[action.entityType],
        loading: true,
        error: undefined
      };
      return {
        ...state,
        types: {
          ...state.types
        }
      };
    case END_LOADING_ATTACHMENTS_TYPES:
      state.types[action.entityType] = {
        ...state.types[action.entityType],
        loading: false
      };
      return {
        ...state,
        types: {
          ...state.types
        }
      };
    case ATTACHMENTS_TYPES_ERROR:
      state.types[action.entityType] = {
        ...state.types[action.entityType],
        error: action.error
      };
      return {
        ...state,
        types: {
          ...state.types
        }
      };

    case CREATING_FOLDER_ATTACHMENT:
      return {
        ...state,
        creating_folder_attachments: true,
        error_folder_attachments: undefined
      };
    case END_CREATING_FOLDER_ATTACHMENT:
      return {
        ...state,
        creating_folder_attachments: false
      };
    case ERROR_CREATING_FOLDER_ATTACHMENT:
      return {
        ...state,
        error_folder_attachments: action.error
      };

    case LOADING_DOCUMENT_HISTORY:
      if (!areAttachmentsEqual(action.document, state.current)) {
        return {
          ...state,
          history: null,
          current: action.document,
          loading_history: true,
          error_history: undefined
        };
      }
      return {
        ...state,
        current: action.document,
        loading_history: true,
        error_history: undefined
      };
    case END_LOADING_DOCUMENT_HISTORY:
      return {
        ...state,
        loading_history: false
      };
    case ERROR_GET_DOCUMENT_HISTORY:
      return {
        ...state,
        error_history: action.error
      };
    case GET_DOCUMENT_HISTORY:
      return {
        ...state,
        history: action.history
      };

    case GET_ATTACHMENTS_LOGS:
      if (action.ownerId) {
        return state;
      }
      return {
        ...state,
        attachments_logs: action.attachments_logs
      };
    case LOADING_ATTACHMENTS_LOGS:
      if (action.ownerId) {
        return state;
      }
      return {
        ...state,
        loading_attachments_logs: true,
        error_attachments_logs: undefined
      };
    case END_LOADING_ATTACHMENTS_LOGS:
      if (action.ownerId) {
        return state;
      }
      return {
        ...state,
        loading_attachments_logs: false
      };
    case ATTACHMENTS_LOGS_ERROR:
      if (action.ownerId) {
        return state;
      }
      return {
        ...state,
        error_attachments_logs: action.error
      };

    case GET_ATTACHMENTS_SEARCH:
      return {
        ...state,
        attachments_search: action.attachments,
        attachments_search_count: action.numberOfElements,
        attachments_search_islast: action.last,
        attachments_search_page: action.page
      };
    case LOADING_ATTACHMENTS_SEARCH:
      return {
        ...state,
        loading_attachments_search: true,
        error_attachments_search: undefined
      };
    case END_LOADING_ATTACHMENTS_SEARCH:
      return {
        ...state,
        loading_attachments_search: false
      };
    case ERROR_ATTACHMENTS_SEARCH:
      return {
        ...state,
        error_attachments_search: action.error
      };

    case DRAG_ATTACHMENTS:
      return {
        ...state,
        draggedAttachments: action.draggedAttachments
      };
    case END_DRAGING_ATTACHMENTS:
      return {
        ...state,
        draggedAttachments: null
      };

    default:
      return state;
  }
}
