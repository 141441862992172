import React from "react";
import Button from "../../Buttons/Button";
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

const PresetChangeConfirmDialog = ({
  handleClose,
  handleConfirmChanging,
  open,
  preset,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <CloseableDialogTitle
        id="confirmation-dialog-title"
        handleClose={handleClose}
      >
        {`Are you sure you want to prefil dates ${
          preset ? `with ${preset.label}?` : ""
        }`}
      </CloseableDialogTitle>
      <DialogContent>
        <DialogContentText>
          Already filled in values will be overwritten.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirmChanging} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PresetChangeConfirmDialog;
