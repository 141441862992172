import React from "react";
import { Typography } from "@material-ui/core";

const Title = ({ content }) => {
  return (
    <Typography variant="h6" style={{ padding: "0px 4px 16px" }}>
      {content}
    </Typography>
  );
};

export default Title;
