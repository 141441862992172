import React, { useState } from 'react';

import PropTypes from 'prop-types';

import ButtonLoading from 'components/Buttons/ButtonLoading';
import LinkGraphDialog from 'components/Links/Graph/LinkGraphDialog';

const LinksGraph = ({ links, entityId, onOpen, onClose }) => {

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenGraph = () => {
    setIsOpen(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleCloseGraph = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  }

  const isEmpty = !links
    || ((!links.sourceLinks || links.sourceLinks.length === 0)
      && (!links.targetLinks || links.targetLinks.length === 0)
      && (!links.siblingLinks || links.siblingLinks.length === 0));

  const isDisabled = entityId && isEmpty;
  const tooltipLabel = isDisabled ? "No link found" : "Link map";

  return (
    <>
      <ButtonLoading
        disabled={isDisabled}
        tooltipTitle={tooltipLabel}
        variant="outlined"
        testid="openlinkgraph"
        color="primary"
        onClick={handleOpenGraph}
      >
        Link map
      </ButtonLoading>
      <LinkGraphDialog
        isOpen={isOpen}
        links={links}
        entityId={entityId}
        onClose={handleCloseGraph}
      />
    </>
  );
};

LinksGraph.propTypes = {
  links: PropTypes.shape({
    sourceLinks:  PropTypes.array,
    targetLinks:  PropTypes.array,
    siblingLinks: PropTypes.array
  }),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  entityId: PropTypes.string,
}

export default LinksGraph;
