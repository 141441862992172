import React from 'react';

import { Divider, withStyles } from '@material-ui/core';

import SecurityLevelLabel from 'components/SecurityLevels/Label';
import {
  MODEL_REVISION_TYPE,
  MODEL_TYPE,
  MODEL_USAGE_TYPE,
  VALIDATION_COMMITTEE_TYPE,
  VALIDATION_TYPE,
} from 'api/Entity';

import DeleteButton from 'components/Entity/DeleteButton';
import {
  ROLE_GIT_REPO_VIEW,
  ROLE_MODEL_DELETE,
  ROLE_MODELREVISION_DELETE, ROLE_SECURITYLEVEL_EDIT,
  ROLE_VALIDATION_DELETE,
  ROLE_VALIDATIONCOMMITTEE_DELETE,
} from 'constants/Authorities';

import RepositoryButton from 'components/Repository/EntityButton';
import { DATALAB, GIT } from 'constants/Features';
import ContainerButton from 'components/Containers/EntityButton';
import ValidationCommitteeButton from 'components/Validations/Committees/EntityButton';
import SecurityLevels from 'components/SecurityLevels/EntityButton';
import InfoDialog from 'components/Entity/InfoDialog';
import RestrictedComponent from 'components/RestrictedComponent';

const RightSideBar = ({ entity, entityType, model, classes }) => {

  const renderAction = (action, idx) => {
    const {
      component,
      allowedEntityTypes,
      ignoreRevisionSystem,
      ...otherProps
    } = action;

    let entityToSend = entity;
    let revision;
    if (allowedEntityTypes && !allowedEntityTypes.includes(entityType)) {
      return null;
    }
    if (ignoreRevisionSystem && entityType === MODEL_REVISION_TYPE) {
      entityToSend = model;
      revision = entity;
    }
    if (component === Divider) {
      return (
        <div key={idx} className={classes.buttonAction}>
          <RestrictedComponent componentButton={component} {...otherProps} />
        </div>
      );
    }
    return (
      <div key={idx} className={classes.buttonAction}>
        <RestrictedComponent
          componentButton={component}
          entity={entityToSend}
          entityType={entityType}
          revision={revision}
          {...otherProps}
        />
      </div>
    );
  };

  return (
    <>
      <div className={classes.actionsTop}>
        <div className={classes.securityLabel}>
          <SecurityLevelLabel
            entity={entityType === MODEL_REVISION_TYPE ? model : entity}
          />
        </div>
        {ACTIONS.map(renderAction)}
      </div>
      <div className={classes.actionsBottom}>
        {ACTIONS_BOTTOM.map(renderAction)}
      </div>
    </>
  )
}

const ACTIONS = [
  {
    component: RepositoryButton,
    ignoreRevisionSystem: true,
    featuresRequired: [GIT],
    authoritiesRequired: [ROLE_GIT_REPO_VIEW],
    allowedEntityTypes: [MODEL_TYPE, MODEL_REVISION_TYPE]
  },
  {
    component: Divider,
    featuresRequired: [GIT],
    authoritiesRequired: [ROLE_GIT_REPO_VIEW],
    allowedEntityTypes: [MODEL_TYPE, MODEL_REVISION_TYPE]
  },
  {
    component: ContainerButton,
    ignoreRevisionSystem: true,
    featuresRequired: [DATALAB],
    allowedEntityTypes: [MODEL_TYPE, MODEL_REVISION_TYPE, VALIDATION_TYPE]
  },
  {
    component: Divider,
    allowedEntityTypes: [MODEL_TYPE, MODEL_REVISION_TYPE, VALIDATION_TYPE]
  },
  {
    component: ValidationCommitteeButton,
    allowedEntityTypes: [VALIDATION_TYPE]
  },
  {
    component: Divider,
    allowedEntityTypes: [VALIDATION_TYPE]
  },
  {
    component: SecurityLevels,
    ignoreRevisionSystem: true,
    authoritiesRequired: [ROLE_SECURITYLEVEL_EDIT],
    allowedEntityTypes: [
      MODEL_TYPE,
      MODEL_REVISION_TYPE,
      VALIDATION_TYPE,
      VALIDATION_COMMITTEE_TYPE
    ]
  },
  {
    component: Divider,
    authoritiesRequired: [ROLE_SECURITYLEVEL_EDIT],
    allowedEntityTypes: [
      MODEL_TYPE,
      MODEL_REVISION_TYPE,
      VALIDATION_TYPE,
      VALIDATION_COMMITTEE_TYPE
    ]
  },
  {
    component: InfoDialog,
    ignoreRevisionSystem: true,
    allowedEntityTypes: [
      MODEL_TYPE,
      MODEL_REVISION_TYPE,
      MODEL_USAGE_TYPE,
      VALIDATION_TYPE,
      VALIDATION_COMMITTEE_TYPE
    ]
  }
];

const ACTIONS_BOTTOM = [
  {
    component: DeleteButton,
    title: "Remove the revision",
    removingTitle: "Removing the revision",
    authoritiesRequired: [ROLE_MODELREVISION_DELETE],
    allowedEntityTypes: [MODEL_REVISION_TYPE]
  },
  {
    component: DeleteButton,
    authoritiesRequired: [ROLE_MODEL_DELETE],
    allowedEntityTypes: [MODEL_USAGE_TYPE]
  },
  {
    component: DeleteButton,
    authoritiesRequired: [ROLE_MODEL_DELETE],
    allowedEntityTypes: [MODEL_TYPE]
  },
  {
    component: DeleteButton,
    authoritiesRequired: [ROLE_VALIDATION_DELETE],
    allowedEntityTypes: [VALIDATION_TYPE]
  },
  {
    component: DeleteButton,
    authoritiesRequired: [ROLE_VALIDATIONCOMMITTEE_DELETE],
    allowedEntityTypes: [VALIDATION_COMMITTEE_TYPE]
  }
];

const drawerWidth = 240;

const styles = () => ({
  actionsTop: {},
  actionsBottom: {},
  buttonAction: {
    width: drawerWidth
  },
  securityLabel: {
    textAlign: "center",
    marginTop: -40,
    width: drawerWidth
  }
});

export default withStyles(styles)(RightSideBar);
