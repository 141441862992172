import React, { useEffect, useState } from "react";

import { Provider } from "react-redux";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import configureStore from "stores";
import moment from "moment";
import MomentUtils from "@date-io/moment";

import { useApiRequest } from "actions";
import {
  createClient,
  isPropertiesValid,
  mapProperties,
} from "constants/Keycloak";
import { getGuiProperties } from "actions/GuiConfiguration";
import App from "containers/App";
import grayTheme from "containers/themes/grayTheme";
import AuthenticationWrapper from "containers/AuthenticationWrapper";

const store = configureStore();

export const InitConfigWrapper = () => {
  const [keycloak, setKeycloak] = useState(null);
  const [loading, error, runEvaluation] = useApiRequest(
    () => getGuiProperties(),
    properties => {
      if (isPropertiesValid(properties)) {
        const guiConfig = mapProperties(properties);
        setKeycloak(createClient(guiConfig));
      }
    }
  );

  useEffect(() => {
    runEvaluation();
  }, []);

  if (loading || !keycloak) {
    return null;
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: "login-required", pkceMethod: "S256" }}
    >
      <Provider store={store}>
        <MuiThemeProvider theme={createMuiTheme(grayTheme)}>
          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <AuthenticationWrapper element={<App keycloak={keycloak} />} />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Provider>
    </ReactKeycloakProvider>
  );
};

export default InitConfigWrapper;
