import React from "react";
import { Paper, Typography } from "@material-ui/core";
import MenuItem from "components/Buttons/MenuItem";
import SelectField from "components/Form/Fields/SelectField";
import { Field, reduxForm } from "redux-form";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { RequiredValidator } from "components/Form/Fields/FieldValidators";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  container: { margin: "16px 0", padding: "0 16px 8px 16px" },
  textMargin: { margin: "10px 0" },
  formStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
}));

let DateSelectionAssistant = ({ handleSubmit }) => {
  const classes = useStyles();
  const options = useSelector(state => state.exportHistory.options || []);

  return (
    <Paper variant="outlined" className={classes.container}>
      <Typography variant="h6" className={classes.textMargin}>
        Date Selection Assistant
      </Typography>
      <Typography variant="subtitle1" className={classes.textMargin}>
        Use one of the presets to pre-fill the common export scenarios to the
        list of exported dates.
      </Typography>
      <form onSubmit={handleSubmit} className={classes.formStyle}>
        <Field
          label={"Select preset"}
          name="presetSelected"
          component={SelectField}
          validate={[RequiredValidator]}
        >
          {options.map(function(preset, idx) {
            return (
              <MenuItem key={idx} value={preset}>
                {preset.label}
              </MenuItem>
            );
          })}
        </Field>
        <ButtonLoading
          loadingTitle="Prefill"
          className="color-primary"
          style={{ height: 32 }}
          type="submit"
          variant="outlined"
          color="primary"
        >
          Prefill
        </ButtonLoading>
      </form>
    </Paper>
  );
};

DateSelectionAssistant = reduxForm({
  form: "presetSelectionForm",
})(DateSelectionAssistant);

export default DateSelectionAssistant;
