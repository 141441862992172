import {Typography, Grid, Tooltip} from "@material-ui/core";
import React from "react";
import TaskStatus from "components/Tasks/TaskStatus";
import EntityLinkButton from "components/Entity/EntityLinkButton";
import TaskInfoWorkflowContainer from "components/Tasks/TaskDialog/TaskInfoWorkflowContainer";
import TaskInfoLastUpdate from "components/Tasks/TaskDialog/TaskInfoLastUpdate";
import TaskInfoBusinessOwner from "components/Tasks/TaskDialog/TaskInfoBusinessOwner";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  row: {
    display: "inline-flex",
    alignItems: "center",
    padding: "5px 0",
    maxHeight: "30px"
  },
  captionRow: {
    display: "inline-flex",
    alignItems: "center"
  },
  caption: {
    color:"rgba(0, 0, 0, 0.54)",
    paddingRight: 5
  }
}));

const TaskInfo = ({task}) => {
  const classes = useStyles();

  if(!task){
    return null;
  }

  return <div style={{ padding: "0 24px 10px" }}>
    <Grid container>
      <Grid item xs={6}>
        <Grid container>
          {task.status && <>
          <Grid item xs={3} lg={2} className={classes.captionRow}>
            <Typography variant="caption" className={classes.caption}>
              Status:
            </Typography>
          </Grid>
          <Grid item xs={9} lg={10} className={classes.row}>
            <Typography variant="body1">
              <TaskStatus taskStatus={task && task.status}/>
            </Typography>
          </Grid>
          </>
          }

          <TaskInfoLastUpdate lastUpdateTime={task.lastUpdateTime} classes={classes}/>

          { task.ownerLabel && <>
          <Grid item xs={3} lg={2} className={classes.captionRow}>
            <Typography variant="caption" className={classes.caption}>
              Owner:
            </Typography>
          </Grid>
          <Grid item xs={9} lg={10} className={classes.row}>
            <Typography variant="body1">
              {task.ownerLabel}
            </Typography>
          </Grid>
          </>}

          <TaskInfoBusinessOwner task={task} classes={classes}/>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          {task.owningEntity &&
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.caption}>
              Entity:
            </Typography>
            <Typography variant="body1">
              <EntityLinkButton task={task}/>
            </Typography>
          </Grid>
          }
          <TaskInfoWorkflowContainer
            taskWorkflowInstanceName={task.workflowInstanceName}
            taskWorkflowTypeLabel={task.workflowTypeLabel}
            taskWorkflowSummary={task.workflowSummary}
          />
        </Grid>
      </Grid>
    </Grid>
    <div className="error">{task.error}</div>
  </div>

};
export default TaskInfo;