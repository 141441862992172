import React, { Component } from "react";
import Button from "./Button";
import { CircularProgress, Tooltip } from "@material-ui/core";

class ButtonLoading extends Component {
  render() {
    const {
      loading,
      loadingTitle,
      tooltipTitle,
      tooltipPlacement,
      loadingSize,
      ...rest
    } = this.props;
    let button;
    if (loading) {
      button = (
        <Button
          {...rest}
          disableFocusRipple
          disableRipple
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <span
            style={{
              verticalAlign: "middle",
              width: loadingSize,
              height: loadingSize
            }}
          >
            <CircularProgress size={loadingSize} />
          </span>
          <span style={{ verticalAlign: "middle", marginLeft: 15 }}>
            {loadingTitle}
          </span>
        </Button>
      );
    } else {
      button = <Button {...rest} />;
    }
    if (tooltipTitle) {
      return (
        <Tooltip
          placement={tooltipPlacement}
          title={loading ? loadingTitle : tooltipTitle}
        >
          <span>{button}</span>
        </Tooltip>
      );
    }
    return button;
  }
}
ButtonLoading.defaultProps = {
  loadingSize: 14
};

export default ButtonLoading;
