import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Grid,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from "@material-ui/core";
import TabCentered from "components/TabContainer/TabCentered";
import moment from "moment";
import { FileCopy } from "@material-ui/icons";
import { pushNotification } from "actions/notifications";
import DeleteTokenButton from "./DeleteTokenButton";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import CreateTokenButton from "./CreateTokenDialog";
import Button from "components/Buttons/Button";
import { ROLE_ACCESSTOKEN_MANAGEOWN } from "constants/Authorities";
import { openTokenDialog } from "actions/index";
import APPCONFIG from "constants/Config";

class AccountTokens extends Component {
  handleCreateToken = form => {
    const { onCreateToken, me } = this.props;
    if (onCreateToken) {
      let expirationDate = moment().add({
        days: form.expirationDays,
        hours: form.expirationHours,
        minutes: form.expirationMinutes
      });
      onCreateToken(
        form.name,
        expirationDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        form.userId
      );
    }
  };

  handleCopyToken = token => {
    const { pushNotification } = this.props;
    navigator.clipboard.writeText(token.token).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
        pushNotification("check", "success", "Token copy to clipboard.");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  open = () => {
    const { openTokenDialog } = this.props;
    openTokenDialog();
  };

  render() {
    const {
      tokens,
      loading,
      classes,
      creating,
      error,
      tokenCreated,
      revokeToken,
      withUserInput
    } = this.props;

    let list,
      has_tokens = tokens && tokens.length > 0;
    const button = (
      <div style={{ textAlign: "right" }}>
        <Button
          loading={creating}
          loadingTitle="Creating the token"
          testid="createtoken"
          authoritiesRequired={[ROLE_ACCESSTOKEN_MANAGEOWN]}
          onClick={this.open}
          variant="outlined"
          color="primary"
        >
          Create a token
        </Button>
      </div>
    );

    if (!has_tokens) {
      if (loading) {
        list = (
          <TabCentered>
            <div
              style={{
                width: "400px",
                lineHeight: "36px",
                textAlign: "center",
                margin: "20px 10px 0"
              }}
            >
              <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
              <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
                Loading the tokens
              </span>
            </div>
          </TabCentered>
        );
      } else {
        list = (
          <TabCentered>
            <div className="emptyMessage">No token created yet</div>
            {button}
          </TabCentered>
        );
      }
    } else {
      list = (
            <List component="nav" className={"scrollable-content"}>
              {tokens.map((token, idx) => {
                if (!token) {
                  return null;
                }
                const { label, createdOn } = token;
                return (
                  <ListItem
                    key={idx}
                    button={Boolean(token.token)}
                    onClick={() => {
                      token.token && this.handleCopyToken(token);
                    }}
                  >
                    <ListItemText
                      primary={label}
                      secondary={
                        "Created at " +
                        moment(createdOn).format(APPCONFIG.dateTimeFormat) +
                        " - Expires at " +
                        moment(token.validTo).format(APPCONFIG.dateTimeFormat)
                      }
                    />
                    <ListItemSecondaryAction>
                      {token.token && (
                        <IconButtonLoading
                          style={{ display: "inline-block", height: 44 }}
                          color="primary"
                          tooltipTitle="Copy the token"
                          aria-label="Copy token"
                          onClick={() => {
                            this.handleCopyToken(token);
                          }}
                        >
                          <FileCopy />
                        </IconButtonLoading>
                      )}
                      <DeleteTokenButton
                        token={token}
                        revokeToken={revokeToken}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
      );
    }

    return (
      <React.Fragment>
        <Grid container justify="flex-end" style={{ padding: "10px" }}>
          {has_tokens && button}
        </Grid>
        {list}
        <CreateTokenButton
          withUserInput={withUserInput}
          creating={creating}
          error={error}
          tokenCreated={tokenCreated}
          handleCreateToken={this.handleCreateToken}
        />
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  root: {}
});

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { pushNotification, openTokenDialog }
  )(AccountTokens)
);
