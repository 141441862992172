import {
  LOADING_DOCKER_IMAGES,
  END_LOADING_DOCKER_IMAGES,
  LOADING_DOCKER_IMAGES_ERROR,
  GET_DOCKER_IMAGES
} from "../../constants/ActionTypes";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_DOCKER_IMAGES:
      return {
        ...state,
        list: action.dockerImages,
        error: undefined
      };
    case LOADING_DOCKER_IMAGES:
      return { ...state, loading: true, error: undefined };
    case END_LOADING_DOCKER_IMAGES:
      return { ...state, loading: false };
    case LOADING_DOCKER_IMAGES_ERROR:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
