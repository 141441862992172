import React, { Component } from 'react';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config'

class Admin extends Component {
    render() {
        const { route } = this.props;
        return (
            renderRoutes(route.routes, { })
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        route: ownProps.route
    };
};

export default connect(
    mapStateToProps, { }
)(Admin);
