import React, { Component } from "react";
import { connect } from "react-redux";
import AssignValidationDialog from "./AssignValidationDialog";
import Button from "components/Buttons/Button";
import { assignModelRevisions } from "actions/Validations";
import { VALIDATION_COMMITTEE_TYPE } from "../../../api/Entity";

class AssignValidationButton extends Component {
  state = {
    openDialogAssignValidation: false
  };

  onAssignValidation = () => {
    this.setState({
      openDialogAssignValidation: true
    });
  };
  handleAssignValidation = validation => {
    const { assignModelRevisions } = this.props;
    assignModelRevisions(validation.id, this.getSelectedIdsForAssign());
  };
  handleCloseAssignValidationDialog = () => {
    this.setState({
      openDialogAssignValidation: false
    });
  };
  getSelectedIdsForAssign = () => {
    const { selectedEntities } = this.props;
    let ids = [];
    for (let i = 0; selectedEntities && i < selectedEntities.length; i++) {
      ids.push(selectedEntities[i].id);
    }
    return ids;
  };

  render() {
    const { openDialogAssignValidation } = this.state;
    const { entityType, excludeIds } = this.props;
    let assignExistingValidationLabel;

    if (entityType === VALIDATION_COMMITTEE_TYPE) {
      assignExistingValidationLabel = "Assign an existing validation";
    } else {
      assignExistingValidationLabel = "Assign to an existing validation";
    }
    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          aria-label={assignExistingValidationLabel}
          onClick={this.onAssignValidation}
        >
          {assignExistingValidationLabel}
        </Button>
        <AssignValidationDialog
          excludeIds={excludeIds}
          open={openDialogAssignValidation}
          onAssign={this.handleAssignValidation}
          handleClose={this.handleCloseAssignValidationDialog}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
export default connect(
  mapStateToProps,
  { assignModelRevisions }
)(AssignValidationButton);
