import {
  DOCUMENT_IMPORTED,
  DOCUMENT_UPDATED,
  DOCUMENT_UPDATED_ERROR,
  DOCUMENT_UPDATING,
  DOCUMENT_DELETED,
  DOCUMENT_DELETED_ERROR,
  DOCUMENT_DELETING,
  LOADING_DOCUMENTS,
  END_LOADING_DOCUMENTS,
  GET_DOCUMENTS,
  DOCUMENTS_ERROR,
  DOCUMENT_RENAMING,
  DOCUMENT_RENAMED_ERROR,
  GET_DOCUMENT_HISTORY,
  LOADING_DOCUMENT_HISTORY,
  END_LOADING_DOCUMENT_HISTORY,
  ERROR_GET_DOCUMENT_HISTORY,
  GET_DOCUMENTS_LOGS,
  LOADING_DOCUMENTS_LOGS,
  END_LOADING_DOCUMENTS_LOGS,
  ERROR_GET_DOCUMENTS_LOGS,
  PUSH_NOTIFICATION,
  END_DOCUMENT_UPDATING
} from 'constants/ActionTypes';
import {
  getEntityGetAttachmentsParam,
} from 'api/Entity';
import axios from "axios";
import { getSortingAttachments } from 'api/Sort';
import { checkConflictError } from "actions/Attachments";
import { getApiError } from 'api/Error';
import { buildAttachment } from 'api/Attachments';

export function getDocuments(ownerId, entityType) {
  return function(dispatch) {
    const entityParam = getEntityGetAttachmentsParam(entityType);

    dispatch({
      type: LOADING_DOCUMENTS,
      ownerId: ownerId
    });
    axios
      .get(
        "/api/document-management/" +
          entityParam +
          "/" +
          ownerId +
          "/documents",
        {
          withCredentials: true
        }
      )
      .then(response => {
        let documents = response.data;
        dispatch({
          type: GET_DOCUMENTS,
          documents: documents && documents.sort(getSortingAttachments),
          ownerId: ownerId
        });
        dispatch({
          type: END_LOADING_DOCUMENTS,
          ownerId: ownerId
        });
      })
      .catch(err => {
        let message = getApiError(err);
        dispatch({
          type: DOCUMENTS_ERROR,
          error: message,
          ownerId: ownerId
        });
        dispatch({
          type: END_LOADING_DOCUMENTS,
          ownerId: ownerId
        });
      });
  };
}

export function getHistory(document) {
  return function(dispatch) {
    let ownerId = document.ownerId;
    const typeOfOwner = getEntityGetAttachmentsParam(document.entity);
    const id = document.id;

    let url = `/api/document-management/${typeOfOwner}/${ownerId}/documents/${id}/versions`;

    dispatch({
      type: LOADING_DOCUMENT_HISTORY,
      ownerId: ownerId,
      document: document
    });

    axios
      .get(url, {
        withCredentials: true
      })
      .then(response => {
        dispatch({
          type: GET_DOCUMENT_HISTORY,
          document: document,
          history: response.data,
          ownerId: ownerId
        });
        dispatch({
          type: END_LOADING_DOCUMENT_HISTORY,
          ownerId: ownerId,
          document: document
        });
      })
      .catch(err => {
        let message = getApiError(err);
        dispatch({
          type: ERROR_GET_DOCUMENT_HISTORY,
          error: message,
          ownerId: ownerId,
          document: document
        });
        dispatch({
          type: END_LOADING_DOCUMENT_HISTORY,
          ownerId: ownerId,
          document: document
        });
      });
  };
}

export function getDocumentsLogs(
  ownerId,
  entityType,
  firstResult,
  maxResultCount,
  document
) {
  return function(dispatch) {
    const entityParam = getEntityGetAttachmentsParam(entityType);

    let url = `/api/document-management/${entityParam}/${ownerId}/documents/logs?firstResult=${firstResult}&maxResultCount=${maxResultCount}`;
    if (document) {
      const id = document.id;
      url += `&documentId=${id}`;
    }

    dispatch({
      type: LOADING_DOCUMENTS_LOGS,
      ownerId: ownerId
    });

    axios
      .get(url, {
        withCredentials: true
      })
      .then(response => {
        let logs = response.data;
        dispatch({
          type: GET_DOCUMENTS_LOGS,
          logs: logs ? logs[0] : null,
          ownerId: ownerId
        });
        dispatch({
          type: END_LOADING_DOCUMENTS_LOGS,
          ownerId: ownerId
        });
      })
      .catch(err => {
        let message = getApiError(err);
        dispatch({
          type: ERROR_GET_DOCUMENTS_LOGS,
          error: message,
          ownerId: ownerId
        });
        dispatch({
          type: END_LOADING_DOCUMENTS_LOGS,
          ownerId: ownerId
        });
      });
  };
}

export function updateDocument(
  oldFile,
  newFile,
  prefix,
  rewriteDuplicate = false
) {
  return async function(dispatch) {
    if (!oldFile) {
      return;
    }
    let ownerId = oldFile.ownerId;
    const typeOfOwner = getEntityGetAttachmentsParam(oldFile.entity);
    const id = oldFile.id;
    const metadata = oldFile.metadata;
    const oldFileName = oldFile.fileName;

    const newFileName = newFile.fileName;
    const newCategory = newFile.category;

    let url = `/api/document-management/${typeOfOwner}/${ownerId}/documents/categories/update`;
    try {
      dispatch({
        type: DOCUMENT_UPDATING,
        ownerId: ownerId,
        oldDocument: oldFile
      });

      const newDocument = (await axios.post(
        url,
        {
          fileName: oldFileName,
          metadata: metadata,
          newCategoryId: newCategory,
          newFileName: newFileName,
          prefix: prefix,
          rewriteDuplicate
        },
        {
          withCredentials: true
        }
      )).data;
      dispatch({
        type: DOCUMENT_UPDATED,
        oldDocument: oldFile,
        newDocument,
        ownerId: ownerId,
        id: id
      });
      dispatch({
        type: PUSH_NOTIFICATION,
        icon: "edit",
        variant: "success",
        message: oldFileName + " updated"
      });
    } catch (err) {
      if (
        !checkConflictError(err, dispatch, id, () =>
          updateDocument(oldFile, newFile, prefix, true)(dispatch)
        )
      ) {
        let message = getApiError(err);
        dispatch({
          type: DOCUMENT_UPDATED_ERROR,
          error: message,
          ownerId: ownerId,
          oldDocument: oldFile
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message:
            "Updating of document " + oldFile.fileName + " failed" + message
        });
      } else {
        dispatch({
          type: END_DOCUMENT_UPDATING,
          ownerId: ownerId,
          oldDocument: oldFile
        });
      }
    }
  };
}
export function renameDocument(
  oldFile,
  newFile,
  prefix,
  rewriteDuplicate = false
) {
  return async function(dispatch) {
    if (!oldFile) {
      return;
    }
    let ownerId = oldFile.ownerId;
    const typeOfOwner = getEntityGetAttachmentsParam(oldFile.entity);
    const id = oldFile.id;
    const metadata = oldFile.metadata;
    const oldFileName = oldFile.fileName;

    const newFileName = newFile.fileName;

    let url = `/api/document-management/${typeOfOwner}/${ownerId}/documents/${id}`;
    try {
      dispatch({
        type: DOCUMENT_RENAMING,
        ownerId: ownerId,
        oldDocument: oldFile
      });

      const newDocument = (await axios.put(
        url,
        {
          metadata: metadata,
          sourceName: oldFileName,
          sourcePrefix: oldFile.prefix,
          targetName: newFileName,
          targetPrefix: prefix,
          rewriteDuplicate
        },
        {
          withCredentials: true
        }
      )).data;
      dispatch({
        type: DOCUMENT_UPDATED,
        oldDocument: oldFile,
        newDocument,
        ownerId: ownerId
      });
      dispatch({
        type: PUSH_NOTIFICATION,
        icon: "edit",
        variant: "success",
        message: oldFileName + " renamed"
      });
    } catch (err) {
      if (
        !checkConflictError(err, dispatch, id, () =>
          renameDocument(oldFile, newFile, prefix, true)(dispatch)
        )
      ) {
        let message = getApiError(err);
        dispatch({
          type: DOCUMENT_RENAMED_ERROR,
          error: message,
          ownerId: ownerId,
          oldDocument: oldFile
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message:
            "Renaming of document " + oldFile.fileName + " failed" + message
        });
      }
    }
  };
}

export function deleteDocument(file, prefix) {
  return function(dispatch) {
    if (!file) {
      return;
    }
    const ownerId = file.ownerId;
    const typeOfOwner = getEntityGetAttachmentsParam(file.entity);
    const fileName = file.fileName;
    const metadata = file.metadata;
    const category = file.category;
    const id = file.id;
    dispatch({
      type: DOCUMENT_DELETING,
      ownerId: ownerId,
      document: file
    });

    let url = `/api/document-management/${typeOfOwner}/${ownerId}/documents`;

    axios
      .delete(url, {
        withCredentials: true,
        data: {
          fileName: fileName,
          metadata: metadata,
          prefix: file.prefix
        }
      })
      .then(response => {
        dispatch({
          type: DOCUMENT_DELETED,
          document: file,
          ownerId: ownerId,
          id: id
        });
      })
      .catch(err => {
        let message = getApiError(err);
        dispatch({
          type: DOCUMENT_DELETED_ERROR,
          error: message,
          ownerId: ownerId,
          document: file
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Deleting of document " + file.fileName + " failed" + message
        });
      });
  };
}

export function importDocument(file, uploadDetail, user) {
  return function (dispatch) {
    const attachment = buildAttachment(file, uploadDetail, user);
    dispatch({
      type: DOCUMENT_IMPORTED,
      document: attachment,
      ownerId: attachment.ownerId,
      id: attachment.objectName
    });
  }
}
