import {
  LOADING_ENTITY,
  GET_ENTITY,
  END_LOADING_ENTITY,
  ERROR_ENTITY,
  LOADING_ENTITY_TYPES,
  GET_ENTITY_TYPES,
  END_LOADING_ENTITY_TYPES,
  ERROR_ENTITY_TYPES,
  LOADING_ENTITY_TYPE,
  GET_ENTITY_TYPE,
  END_LOADING_ENTITY_TYPE,
  ERROR_ENTITY_TYPE,
  CREATE_ENTITY,
  END_CREATE_ENTITY,
  EDIT_ENTITY,
  END_EDIT_ENTITY,
  SAVING_ENTITY,
  END_SAVING_ENTITY,
  SAVE_ENTITY,
  ERROR_SAVING_ENTITY
} from "../../constants/ActionTypes";

export default function(state = {}, action) {
  let entityType = action.entityType;
  if (!state[entityType]) {
    state[entityType] = {};
  }

  switch (action.type) {
    case LOADING_ENTITY:
      state[entityType] = {
        ...state[entityType],
        loading: true,
        error: undefined
      };
      return {
        ...state
      };
    case GET_ENTITY:
      state[entityType] = {
        ...state[entityType],
        creation_mode: false,
        edition_mode: false,
        error: undefined,
        error_saving: undefined,
        saving: false
      };
      return {
        ...state
      };
    case END_LOADING_ENTITY:
      state[entityType] = {
        ...state[entityType],
        loading: false
      };
      return {
        ...state
      };
    case ERROR_ENTITY:
      state[entityType] = {
        ...state[entityType],
        error: action.error
      };
      return {
        ...state
      };

    case GET_ENTITY_TYPES:
      state[entityType] = {
        ...state[entityType],
        types: action.entityTypes,
        error_entity_types: undefined
      };
      return {
        ...state
      };
    case LOADING_ENTITY_TYPES:
      state[entityType] = {
        ...state[entityType],
        loading_entity_type: true,
        error_entity_types: undefined
      };
      return {
        ...state
      };
    case END_LOADING_ENTITY_TYPES:
      state[entityType] = {
        ...state[entityType],
        loading_entity_types: false
      };
      return { ...state };
    case ERROR_ENTITY_TYPES:
      state[entityType] = {
        ...state[entityType],
        error_entity_types: action.error
      };
      return { ...state };

    case GET_ENTITY_TYPE:
      state[entityType] = {
        ...state[entityType],
        entity_type: action.entity_type,
        error_entity_type: undefined
      };
      return {
        ...state
      };
    case LOADING_ENTITY_TYPE:
      state[entityType] = {
        ...state[entityType],
        loading_entity_type: true,
        error_entity_type: undefined
      };
      return {
        ...state
      };
    case END_LOADING_ENTITY_TYPE:
      state[entityType] = {
        ...state[entityType],
        loading_entity_type: false
      };
      return { ...state };
    case ERROR_ENTITY_TYPE:
      state[entityType] = {
        ...state[entityType],
        error_entity_type: action.error
      };
      return { ...state };

    case CREATE_ENTITY:
      state[entityType] = {
        ...state[entityType],
        creation_mode: true,
        edition_mode: false,
        error_saving: undefined
      };
      return { ...state };
    case END_CREATE_ENTITY:
      state[entityType] = {
        ...state[entityType],
        creation_mode: false,
        error_saving: undefined
      };
      return { ...state };
    case EDIT_ENTITY:
      state[entityType] = {
        ...state[entityType],
        edition_mode: true,
        error_saving: undefined
      };
      return { ...state };
    case END_EDIT_ENTITY:
      state[entityType] = {
        ...state[entityType],
        edition_mode: false,
        error_saving: undefined
      };
      return { ...state };
    case SAVING_ENTITY:
      state[entityType] = {
        ...state[entityType],
        saving: true,
        error_saving: undefined
      };
      return { ...state };
    case END_SAVING_ENTITY:
      state[entityType] = {
        ...state[entityType],
        saving: false
      };
      return { ...state };
    case ERROR_SAVING_ENTITY:
      state[entityType] = {
        ...state[entityType],
        error_saving: action.error
      };
      return { ...state };
    case SAVE_ENTITY:
      state[entityType] = {
        ...state[entityType],
        creation_mode: false,
        edition_mode: false,
        error: undefined,
        error_saving: undefined,
        saving: false
      };
      return { ...state };
  }
  return {
    ...state
  };
}
