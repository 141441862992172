import React, { Component } from "react";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { DialogContent, InputAdornment, TextField } from "@material-ui/core";
import IconButton from "components/Buttons/IconButton";
import { FileCopy } from "@material-ui/icons";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { connect } from "react-redux";
import { pushNotification } from "actions/notifications";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

class CredentialsInfoDialog extends Component {
  handleCopy = () => {
    const { pushNotification, credential } = this.props;
    const { notes } = credential;
    navigator.clipboard.writeText(notes).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
        pushNotification("check", "success", "Public key copied to clipboard.");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  render() {
    const { open, onClose, credential } = this.props;
    if (!credential) {
      return null;
    }

    const { notes } = credential;

    return (
      <React.Fragment>
        <Dialog onClose={onClose} open={open} maxWidth={false}>
          <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
            SSH keys
          </CloseableDialogTitle>
          <DialogContent>
            <TextField
              style={{ width: "100%", marginTop: 20, minWidth: 400 }}
              variant="outlined"
              type={"text"}
              label="SSH Public key"
              value={notes}
              onChange={null}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Copy GIT repository link"
                      onClick={this.handleCopy}
                    >
                      <FileCopy />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </DialogContent>
          <DialogActions>
            <ButtonLoading
              testid="submitcredentials"
              className="color-primary"
              variant="outlined"
              onClick={this.handleCopy}
              color="primary"
            >
              {"Copy the SSH Public key"}
            </ButtonLoading>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  { pushNotification }
)(CredentialsInfoDialog);
