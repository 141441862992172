import React, { Component } from 'react';
import classnames from 'classnames';
import Footer from '../Footer';

import { Drawer as DrawerMaterial } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

class DrawerTabs extends Component {
    render() {
        const { classes, children, withoutFooter, testid } = this.props;

        return (
            <DrawerMaterial
                variant="permanent"
                testid={testid}
                classes={{
                    paper: classnames(classes.drawerPaper, (withoutFooter)? classes.drawerPaperWithoutFooter: null),
                }}>
                <div style={{overflow:"auto"}}>
                    { children }
                </div>
                {
                    !withoutFooter && (
                        <div className={classes.footer}>
                            <Footer />
                        </div>
                    )
                }
            </DrawerMaterial>
        );
    }
}

const drawerWidth = 240;

const styles = theme => ({
    footer: {
        position: 'fixed',
        bottom: 0,
        width: drawerWidth-1
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
        zIndex: 0,
        paddingBottom: 60
    },
    drawerPaperWithoutFooter: {
        paddingBottom: 0
    }
});

export default withStyles(styles, { withTheme: true })(DrawerTabs);
