import React, { Component } from "react";
import { connect } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent/index";
import Dialog from "@material-ui/core/Dialog/index";
import CreatWorkflowTypeForm from "./CreateWorkflowTypeForm";
import { updateWorkflowType } from "actions/Workflow/types";
import { withStyles } from "@material-ui/core/styles";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";

class UpdateWorkflowTypeDialog extends Component {
  componentWillReceiveProps(nextProps) {
    const { workflowType } = nextProps;
    if (workflowType) {
      const { updating, error } = workflowType;
      if (
        this.props.workflowType &&
        this.props.workflowType.updating &&
        !updating &&
        !error
      ) {
        this.onClose();
      }
    }
  }
  onClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };
  onCreate = (form) => {
    const { updateWorkflowType } = this.props;
    updateWorkflowType(form);
  };

  render() {
    const { workflowType } = this.props;
    if (!workflowType) {
      return null;
    }
    const { label } = workflowType;
    return (
      <Dialog onClose={this.onClose} open={Boolean(workflowType)}>
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={this.onClose}>
          Update {label}
        </CloseableDialogTitle>
        <DialogContent>
          <CreatWorkflowTypeForm
            initialValues={workflowType}
            onSubmit={this.onCreate}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = () => ({
  root: {},
});

const mapStateToProps = (state, ownProps) => {
  return {
    workflowType: state.workflowTypes.current,
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, { updateWorkflowType })(UpdateWorkflowTypeDialog)
);
