import moment from "moment";
import APPCONFIG from "../constants/Config";
import { getEntityTypeName } from "./Entity";
import { CREATION_TIME_COLUMN_NAME, UPDATE_TIME_COLUMN_NAME } from '../constants/EntityColumnNames';
import {
  TASK_STATUS_COMPLETED,
  TASK_STATUS_IN_PROGRESS,
  TASK_STATUS_READY,
  TASK_STATUS_RESERVED,
  TASK_STATUS_SUSPENDED
} from "../constants/Task";

export function getValue(column, item) {
  return getValueWithLabel(column, item).value;
}

export function getValueLabel(column, item) {
  if (column.isEntityType) {
    return getEntityTypeName(item);
  }

  return getValueWithLabel(column, item).valueLabel;
}

export function getValueWithLabel(column, item) {
  if (column.checkAlsoModel && item && item.model) {
    const result = getValueWithLabel(column, item.model);
    if (result && (result.value || result.value === 0)) {
      return result;
    }
  }
  const id = column.id;
  const ids = id.split(".");
  let value = item,
    valueLabel;
  for (let i = 0; i < ids.length; i++) {
    if (!value && value !== 0) {
      return { value: null, valueLabel: null };
    }
    if (value.valueLabel) {
      valueLabel = value.valueLabel;
    }
    if (value.type === "URL") {
      value = value.value && JSON.parse(value.value);
      valueLabel =
        value &&
        value.url &&
        (value.visibleName ? value.visibleName : value.url);
      return { value, valueLabel };
    }
    value = value[ids[i]];
  }
  if (value && value.value) {
    value = value.value;
  }
  if (!valueLabel) {
    if (value instanceof moment) {
      valueLabel = date2Str(value);
    } else {
      valueLabel = value;
    }
  }
  if (column.id === CREATION_TIME_COLUMN_NAME || column.id === UPDATE_TIME_COLUMN_NAME) {
    valueLabel = moment(valueLabel).format(APPCONFIG.dateTimeFormat);
  }

  return { value: value, valueLabel: valueLabel };
}

export function date2Str(date) {
  if (date === null || !(date instanceof moment)) {
    return "Unknown";
  }
  return date.format(APPCONFIG.dateFormat);
}

export function getSorting(order, orderBy, checkAlsoModel) {
  const coefOrder = order === "desc" ? 1 : -1;
  return (a, b) => {
    let valueA = getValue({ id: orderBy, checkAlsoModel: checkAlsoModel }, a),
      valueB = getValue({ id: orderBy, checkAlsoModel: checkAlsoModel }, b);
    if (!valueA) {
      return coefOrder;
    }
    if (!valueB) {
      return -coefOrder;
    }
    if (valueA instanceof moment) {
      valueA = date2Str(valueA);
    }
    if (valueB instanceof moment) {
      valueB = date2Str(valueB);
    }
    if (!isNaN(valueA) || typeof valueA === "boolean") {
      valueA = String(valueA);
    }
    if (!isNaN(valueB) || typeof valueB === "boolean") {
      valueB = String(valueB);
    }
    if (valueA.label) {
      valueA = String(valueA.label);
    }
    if (valueB.label) {
      valueB = String(valueB.label);
    }
    return (valueB < valueA ? -1 : 1) * coefOrder;
  };
}
export function getSortingByName(typeA, typeB) {
  if (!typeB || !typeB.name) return -1;
  if (!typeA || !typeA.name) return 1;
  return typeA.name.localeCompare(typeB.name);
}

export function getSortingByLabel(typeA, typeB) {
  if (!typeB || !typeB.label) return -1;
  if (!typeA || !typeA.label) return 1;
  return typeA.label.localeCompare(typeB.label);
}

const statusOrder = [
  TASK_STATUS_READY,
  TASK_STATUS_SUSPENDED,
  TASK_STATUS_RESERVED,
  TASK_STATUS_IN_PROGRESS,
  TASK_STATUS_COMPLETED
];
export function getSortingTasks(taskA, taskB) {
  if (!taskB || !taskB.status) return -1;
  if (!taskA || !taskA.status) return 1;
  if (taskA.status === TASK_STATUS_COMPLETED && taskB.status === TASK_STATUS_COMPLETED) {
    return moment(taskA.completionTime).isAfter(taskB.completionTime) ? -1 : 1;
  }
  const orderA = statusOrder.indexOf(taskA.status);
  const orderB = statusOrder.indexOf(taskB.status);
  return orderA - orderB;
}

export function getSortingAttachments(attachmentA, attachmentB) {
  if (attachmentA.isFolder && attachmentB.isFolder) {
    return attachmentA.fileName.localeCompare(attachmentB.fileName);
  } else if (attachmentA.isFolder && !attachmentB.isFolder) {
    return -1;
  } else if (!attachmentA.isFolder && attachmentB.isFolder) {
    return 1;
  } else {
    return moment(attachmentA.uploadDate).isAfter(attachmentB.uploadDate)
      ? -1
      : 1;
  }
}
