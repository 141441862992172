import React, {useEffect} from "react";
import { Field } from "redux-form";
import AssignListField from "components/Form/Fields/AssignListField";
import {initializeForm} from "actions/index";
import {useDispatch} from "react-redux";

const GrantedAuthorities = ({expanded, loading, assignedAuthorities, grantedAuthorities, multiple, form}) => {
  const name = multiple ? "assignedAuthorities" : "assignedAuthority";
  const label = multiple ? "Granted Authorities" : "Granted Authority";

  const dispatch = useDispatch();

  useEffect(()=>{
    initializeForm(form, {assignedAuthorities})(dispatch);
  },[assignedAuthorities])

  return (
    <div style={{width:'100%'}}>
      <Field
        label={label}
        name={name}
        expanded={expanded}
        loading={loading}
        component={AssignListField}
        validate={[]}
        multiple={multiple}
      />
    </div>
  );
}

export default GrantedAuthorities;
