import {
  GET_LICENSE,
  END_LOADING_LICENSE,
  ERROR_LOADING_LICENSE,
  LOADING_LICENSE,
  SET_LICENSE,
  SETTING_LICENSE,
  END_SETTING_LICENSE,
  ERROR_SETTING_LICENSE,
  PUSH_NOTIFICATION,
  SHOW_LICENSE_BANNER,
  HIDE_LICENSE_BANNER
} from "../../constants/ActionTypes";
import axios from "axios/index";
import { getFeatures } from "actions/Features";

export function showLicenseBanner() {
  return function(dispatch) {
    dispatch({
      type: SHOW_LICENSE_BANNER
    });
  };
}
export function hideLicenseBanner() {
  return function(dispatch) {
    dispatch({
      type: HIDE_LICENSE_BANNER
    });
  };
}

export function getLicense() {
  return function(dispatch) {
    dispatch({
      type: LOADING_LICENSE
    });

    axios
      .get("/api/license", {
        withCredentials: true
      })
      .then(response => {
        let license = response.data;
        dispatch({
          type: GET_LICENSE,
          license
        });
        dispatch({
          type: END_LOADING_LICENSE
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_LOADING_LICENSE,
          error: message
        });
        dispatch({
          type: END_LOADING_LICENSE
        });
      });
  };
}
export function setLicense(license) {
  return function(dispatch) {
    dispatch({
      type: SETTING_LICENSE
    });
    axios
      .post("/api/license/set", license, {
        withCredentials: true,
        headers: {
          "Content-Type": "text/plain"
        }
      })
      .then(response => {
        let license = response.data;
        dispatch({
          type: SET_LICENSE,
          license
        });
        dispatch({
          type: END_SETTING_LICENSE
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: "New license uploaded."
        });
        dispatch(getFeatures());
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_SETTING_LICENSE,
          error: message
        });
        dispatch({
          type: END_SETTING_LICENSE
        });
      });
  };
}
