import React from "react";

import { useKeycloak } from "@react-keycloak/web";

const AuthenticationWrapper = ({ element }) => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized && !keycloak.authenticated) {
    return null;
  }

  return element;
};

export default AuthenticationWrapper;
