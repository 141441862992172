import React, { useState } from 'react';

import PropTypes from 'prop-types';

import SecurityLevelItem from 'components/Admin/Users/SecurityLevels/SecurityLevelItem';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UpdateContainer from 'components/Admin/Users/SecurityLevels/Dialog/UpdateContainer';

function compare(a, b) {
  if (a.name.toUpperCase() < b.name.toUpperCase()) {
    return -1;
  }
  if (a.name.toUpperCase() > b.name.toUpperCase()) {
    return 1;
  }
  return 0;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

const SecurityLevelsList = ({ securityLevels, onUpdate }) => {

  const classes = useStyles();

  const [currentItem, setCurrentItem] = useState(null);

  const handleCloseDialog = () => {
    setCurrentItem(null);
  };

  const handleOpenDialog = (securityLevel) => {
    setCurrentItem(securityLevel);
  };

  return (
    <>
      <List className={classes.root}>
        {securityLevels
          .sort(compare)
          .map(sl =>
            <SecurityLevelItem
              key={sl.id}
              securityLevel={sl}
              onClick={handleOpenDialog}
            />,
          )
        }
      </List>
      <UpdateContainer
        onSubmit={onUpdate}
        onClose={handleCloseDialog}
        initValue={currentItem}
      />
    </>
  );
};

SecurityLevelsList.propTypes = ({
  securityLevels: PropTypes.array,
  onUpdate: PropTypes.func.isRequired,
});

export default SecurityLevelsList;
