import {
  GET_AUDIT_TRAILS,
  LOADING_AUDIT_TRAILS,
  END_LOADING_AUDIT_TRAILS,
  ERROR_LOADING_AUDIT_TRAILS
} from "../constants/ActionTypes";

export default function(entity, action) {
  let ownerId = action.ownerId;
  if (!entity || ownerId !== entity.id) {
    return entity;
  }

  switch (action.type) {
    case GET_AUDIT_TRAILS:
      return {
        ...entity,
        auditTrails: action.auditTrails
      };
    case LOADING_AUDIT_TRAILS:
      return {
        ...entity,
        loading_audit_trails: true,
        error_audit_trails: undefined
      };
    case END_LOADING_AUDIT_TRAILS:
      return {
        ...entity,
        loading_audit_trails: false
      };
    case ERROR_LOADING_AUDIT_TRAILS:
      return {
        ...entity,
        error_audit_trails: action.error
      };
  }
  return entity;
}
