import React, { Component } from "react";
import APPCONFIG from "../../../../constants/Config";
import { connect } from "react-redux";
import { Typography, withStyles } from "@material-ui/core";
import {
  TAB_ACCOUNT_INFO,
  TAB_ACCOUNT_TOKEN,
  TAB_ACCOUNT_SSH_KEYS,
  ODATA_TAB,
  TAB_ODATA
} from "../../../../constants/Routes";
import DrawerView from "components/Drawer";
import DrawerTabs from "components/Drawer/DrawerTabs";
import Tabs from "components/TabContainer/Tabs";
import DrawerContent from "components/Drawer/DrawerContent";
import AccountInfo from "components/Account/Info";
import AccountTokens from "components/Account/Tokens";
import { ACCESS_TOKENS } from "../../../../constants/Features";
import { getUserTokens } from "actions/Users";
import Credentials from "components/Credentials";
import ODataAccessCode from "components/OData/ODataAccessCode";
import Divider from "@material-ui/core/Divider";
import RestrictedComponent from "components/RestrictedComponent";

class AccountOverview extends Component {
  state = {
    value: TAB_ACCOUNT_INFO
  };
  refreshTimeout = null;

  componentWillUpdate(nextProps, nextState) {
    const { value } = nextState;
    if (this.state.value !== value) {
      this.refreshTab(nextProps, nextState);
    }
  }

  componentDidMount() {
    this.refreshTab(this.props, this.state);
  }
  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  refreshTab = (props, state) => {
    const { value } = state;
    const { getUserTokens } = props;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTab(this.props, this.state);
    }, APPCONFIG.REFRESH_TIMEOUT);

    switch (value) {
      case TAB_ACCOUNT_TOKEN:
        getUserTokens();
        break;
    }
  };

  handleChange = value => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    let currentTab;

    switch (value) {
      case TAB_ACCOUNT_INFO:
        currentTab = <AccountInfo />;
        break;
      case TAB_ACCOUNT_TOKEN:
        currentTab = <AccountTokens />;
        break;
      case TAB_ACCOUNT_SSH_KEYS:
        currentTab = <Credentials />;
        break;
      case TAB_ODATA:
        currentTab = <ODataAccessCode />;
        break;
    }

    return (
      <DrawerView>
        <DrawerTabs>
          <Typography variant="subtitle2" className={classes.title}>
            General
          </Typography>
          <Tabs tabs={TABS} value={value} onChange={this.handleChange} />
          <Divider />
          <Typography variant="subtitle2" className={classes.title}>
            Integration
          </Typography>
          <Tabs
            tabs={INTEGRATION_TABS}
            value={value}
            onChange={this.handleChange}
          />
          <RestrictedComponent
            featuresRequired={ODATA_TAB.featuresRequired}
            dynamicPropertiesRequired={ODATA_TAB.dynamicPropertiesRequired}
          >
            <Divider />
            <Typography variant="subtitle2" className={classes.title}>
              Reporting
            </Typography>
            <Tabs
              tabs={REPORTING_TABS}
              value={value}
              onChange={this.handleChange}
            />
          </RestrictedComponent>
        </DrawerTabs>
        <DrawerContent>{currentTab}</DrawerContent>
      </DrawerView>
    );
  }
}

const TABS = [
  {
    value: TAB_ACCOUNT_INFO,
    authoritiesRequired: [],
    featuresRequired: [],
    label: "Info"
  }
];

const INTEGRATION_TABS = [
  {
    value: TAB_ACCOUNT_TOKEN,
    authoritiesRequired: [],
    featuresRequired: [ACCESS_TOKENS],
    label: "Tokens"
  },
  {
    value: TAB_ACCOUNT_SSH_KEYS,
    authoritiesRequired: [],
    featuresRequired: [],
    label: "External System Credentials"
  }
];

const REPORTING_TABS = [ODATA_TAB];

const styles = theme => ({
  title: {
    padding: "10px 16px 0"
  }
});

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { getUserTokens }
  )(AccountOverview)
);
