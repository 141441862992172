import React from 'react';

import PropTypes from 'prop-types';

import { useApiRequestV2 } from 'actions';
import { updateSecurityLevelDetail } from 'actions/SecurityLevel';
import GeneralDialog from 'components/Admin/Users/Common/GeneralDialog';
import SecurityLevelDialogForm from 'components/Admin/Users/SecurityLevels/Dialog/SecurityLevelDialogForm';

const UpdateContainer = ({ onSubmit, onClose, initValue }) => {

  const { isLoading, errorObject, reset, run } = useApiRequestV2(
    updateSecurityLevelDetail,
    (response) => {
      onSubmit(response);
      handleClose();
    });

  const handleClose = () => {
    reset();
    onClose();
  };

  const isOpen = initValue !== null;

  return (
    <GeneralDialog
      title="Update security level"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <SecurityLevelDialogForm
        open={isOpen}
        saving={isLoading}
        initialValues={initValue}
        errorSaving={errorObject}
        handleClose={handleClose}
        onSubmit={run}
      />
    </GeneralDialog>
  );
};

UpdateContainer.propTypes = ({
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initValue: PropTypes.object,
});

export default UpdateContainer;
