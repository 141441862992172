import React from 'react';

import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  progress: {
    verticalAlign: 'middle',
  },
  message: {
    verticalAlign: 'middle',
    marginLeft: '10px',
  },
}));

const LoadingBar = ({ message }) => {
  const classes = useStyles();
  return (
    <>
      <CircularProgress size={20} className={classes.progress}/>
      <span className={classes.message}>
        {message}
      </span>
    </>
  );
};

LoadingBar.propTypes = ({
  message: PropTypes.string.isRequired,
});

export default LoadingBar;
