export function clearCreateAttributes(entity) {
    const { attributes, ...rest } = entity;
    return {
        ...rest,
        attributeValues: clearUpdateAttributes(entity)
    };
}
export function clearUpdateAttributes(entity) {
    let values = [];
    let attributes = entity.attributes;
    for (let i = 0; i < attributes.length; i++) {
        const panel = attributes[i].groupNames;
        const keys = Object.keys(panel);
        for (let j = 0; j < keys.length; j++) {
            const key = keys[j];
            const attributesSections = panel[key];
            for (let k = 0; k < attributesSections.length; k++) {
                const attributesSection = attributesSections[k];
                const value = attributesSection.value;
                let attributeToPush = {
                    attributeSpecification: {
                        id: attributesSection.specificationId
                    }
                };
                switch (attributesSection.type) {
                    case "STRING":
                        attributeToPush.stringVal = value;
                        break;
                    case "INTEGER":
                        attributeToPush.integerVal = value;
                        break;
                    case "ENUM":
                        attributeToPush.integerVal = value;
                        break;
                    case "FLOAT":
                        attributeToPush.floatVal = value;
                        break;
                    case "DECIMAL":
                        attributeToPush.decimalVal = value;
                        break;
                    case "DATE":
                        attributeToPush.dateVal = value;
                        break;
                    case "REFERENCE":
                        attributeToPush.stringVal = value;
                        break;
                    case "BOOLEAN":
                        attributeToPush.booleanVal = value;
                        break;
                    case "URL":
                        const url = value && value.url ? value.url : undefined;
                        const visibleName = value && value.visibleName? value.visibleName : undefined;
                        attributeToPush.urlVal = { url, visibleName };
                        break;
                }
                values.push(attributeToPush);
            }
        }
    }
    return values;
}
export function clearAttributes(entity) {
    if (!entity) {
        return null;
    }
    let attributeSections = entity.attributes;
    if (attributeSections) {
        for (let i = 0; i < attributeSections.length; i++) {
            const panel = attributeSections[i].groupNames;
            const keys = Object.keys(panel);
            for (let j = 0; j < keys.length; j++) {
                const key = keys[j];
                const attributes = panel[key];
                for (let k = 0; k < attributes.length; k++) {
                    const attribute = attributes[k];
                    if (attribute.type === "REFERENCE") {
                        for (let l = 0; entity.attributeValues && l < entity.attributeValues.length; l++) {
                            const attributeValue = entity.attributeValues[l];
                            if (attributeValue
                                && attributeValue.attributeSpecification
                                && attributeValue.attributeSpecification.attribute
                                && attributeValue.attributeSpecification.attribute.id === attribute.id) {
                                attribute.value = {
                                    value: attributeValue.valueAsString,
                                    label: attributeValue.label
                                }
                            }
                        }
                    }
                    if (attribute.type === "URL") {
                        for (let l = 0; entity.attributeValues && l < entity.attributeValues.length; l++) {
                            const attributeValue = entity.attributeValues[l];
                            if (attributeValue
                                && attributeValue.attributeSpecification
                                && attributeValue.attributeSpecification.attribute
                                && attributeValue.attributeSpecification.attribute.id === attribute.id) {
                                attribute.value = {
                                    url: attributeValue.urlVal && attributeValue.urlVal.url,
                                    visibleName: attributeValue.urlVal && attributeValue.urlVal.visibleName
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    if (entity.revisions) {
        for (let i = 0; i < entity.revisions.length; i++) {
            entity.revisions[i] = clearAttributes(entity.revisions[i]);
        }
        entity.revisions = entity.revisions.sort(function (a, b) {
            return b.number - a.number
        });
    }
    return entity;
}
export function clearAttributesSearch(model) {
    if (!model) {
        return null;
    }
    model.attributes = model.simpleAttributes;

    return model;
}
export function isAttributeToShow(attributesToShow, attribute) {
    if(attributesToShow) {
        for(let i = 0; i < attributesToShow.length; i++) {
            const att = attributesToShow[i];
            if (att.id === attribute.id) {
                return true;
            }
        }
    }
    return false;
}
