import React, {useEffect, useState} from "react";
import {useApiRequest} from "actions/index";
import {getGuiProperties} from "actions/GuiConfiguration";
import {isPropertiesValid, mapProperties} from "../../constants/Keycloak";
import {CircularProgress} from "@material-ui/core";
import RedirectLogo from "components/Account/RedirectLogo";

const RedirectToAccountManagement = () => {
  const [guiProperties, setGuiProperties] = useState(null);
  const [loading, error, runGetProperties] = useApiRequest(
    () => getGuiProperties(),
    properties => {
      if (isPropertiesValid(properties)) {
        setGuiProperties(mapProperties(properties));
      }
    },
  );

  useEffect(() => {
    runGetProperties()
  },[]);

  if(!guiProperties) {
    return null;
  }

  if(loading){
    return (
      <div style={{ lineHeight: "36px", textAlign: "center" }}>
        <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
        <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
          Loading Account Management link.
        </span>
      </div>
    );
  }

  if(error){
    return <div className="error">{error}</div>;
  }

  return (<a target="_blank" href={`${guiProperties.url}/realms/${guiProperties.realm}/account`} >
    MGS Account Management
    <RedirectLogo />
  </a>);
};

export default RedirectToAccountManagement;