import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../Buttons/ButtonLoading";
import { Field, reduxForm } from "redux-form";
import CheckboxField from "../Form/Fields/CheckboxField";
import InputField from "../Form/Fields/InputField";
import {
  RequiredValidator,
  NonEmptyStringValidator
} from "../Form/Fields/FieldValidators";
import { Grid } from "@material-ui/core";
import { scrollToFirstError } from "../../api/Error";

class RestForm extends Component {
  render() {
    const { handleSubmit, executing, error_sql } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Field
              label={"Query"}
              name="query"
              component={InputField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
              multiline
              ref="query"
            />
          </Grid>
          <Grid item xs={3} style={{ paddingTop: 10 }}>
            <Field
              label={"Include column names"}
              name="includeColumnNames"
              component={CheckboxField}
              validate={[]}
              ref="includeColumnNames"
            />
          </Grid>
        </Grid>
        <ButtonLoading
          loading={executing}
          loadingTitle="Executing the request"
          style={{ width: "300px", margin: "20px 0 0" }}
          className="color-primary"
          type="submit"
          variant="outlined"
          color="primary"
        >
          {"Execute the request"}
        </ButtonLoading>
        <div className="error">{error_sql}</div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    executing: state.admin.executing_sql,
    error_sql: state.admin.error_sql
  };
};
export default reduxForm({
  destroyOnUnmount: false,
  form: "sqlform",
  initialValues: { includeColumnNames: true },
  onSubmitFail: scrollToFirstError
})(connect(mapStateToProps)(RestForm));
