import { CloudUpload, Error, Edit, Check, Add } from "@material-ui/icons";

export function notificationExists(notification, notifications) {
  if (!notification || !notifications) {
    return true;
  }
  for (let i = 0; i < notifications.length; i++) {
    if (notifications[i] && notifications[i].id === notification.id) {
      return true;
    }
  }
  return false;
}

export function getIconComponent(icon) {
  switch (icon) {
    case "cloud_upload":
      return CloudUpload;
    case "error":
      return Error;
    case "edit":
      return Check;
    case "check":
      return Check;
    case "add":
      return Add;
    default:
      return null;
  }
}
