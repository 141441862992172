import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Grid,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from "@material-ui/core";
import TabCentered from "components/TabContainer/TabCentered";
import DeleteCredentialsButton from "./DeleteCredentialsButton";
import AddCredentialsButton from "./AddCredentialsButton";
import Button from "components/Buttons/Button";
import CredentialsInfoDialog from "./CredentialsInfoDialog";
import { VpnKey } from "@material-ui/icons";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import APPCONFIG from "../../constants/Config";
import { getApiError } from "../../api/Error";
import {
  addCredentials,
  deleteCredentials,
  getCredentials,
  getUsersCredentials
} from "actions/Users";

const AccountSSHKeys = ({ classes, admin, me }) => {
  const refreshTimeout = useRef(null);
  const [credentials, setCredentials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCredential, setSelectedCredential] = useState(undefined);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  useEffect(() => {
    refreshTab();
    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout.current);
      }
    };
  }, []);

  const refreshTabTimer = () => {
    refreshTab();
  };

  const refreshTab = async () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout.current);
    }

    refreshTimeout.current = setTimeout(() => {
      refreshTabTimer();
    }, APPCONFIG.REFRESH_TIMEOUT);

    await refreshCredentials();
  };
  const refreshCredentials = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = admin
        ? await getCredentials()
        : await getUsersCredentials(me.id);
      const _credentials = response.data;
      setCredentials(_credentials);
    } catch (e) {
      setError(getApiError(e));
    }
    setLoading(false);
  };
  const createCredential = async (
    userId,
    hostname,
    notes,
    secret,
    isSystemWide
  ) => {
    const _credential = (await addCredentials(
      userId,
      hostname,
      notes,
      secret,
      isSystemWide
    )).data;
    setCredentials([...credentials, _credential]);
    setOpenCreateDialog(false);
  };
  const deleteCredential = async credentialId => {
    await deleteCredentials(credentialId);
    setCredentials(credentials.filter(({ id }) => id !== credentialId));
    setOpenCreateDialog(false);
  };

  const handleOnCredentialClick = selectedCredential => {
    const { notes } = selectedCredential;
    if (notes) {
      setSelectedCredential(selectedCredential);
      setOpenInfoDialog(true);
    }
  };

  const handleCredentialsInfoClose = () => {
    setSelectedCredential(undefined);
    setOpenInfoDialog(false);
  };

  let list,
    has_credentials = credentials && credentials.length > 0;
  const button = (
    <div style={{ textAlign: "right", padding: "10px" }}>
      <Button
        loadingTitle="Adding credentials"
        testid="createcredentials"
        onClick={() => setOpenCreateDialog(true)}
        variant="outlined"
        color="primary"
      >
        Add keys
      </Button>
    </div>
  );

  if (!has_credentials) {
    if (loading) {
      list = (
        <TabCentered>
          <div
            style={{
              width: "400px",
              lineHeight: "36px",
              textAlign: "center",
              margin: "20px 10px 0"
            }}
          >
            <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
              Loading the list of credentials
            </span>
          </div>
        </TabCentered>
      );
    } else {
      list = (
        <TabCentered>
          <div className="emptyMessage">No keys added yet</div>
          {button}
        </TabCentered>
      );
    }
  } else {
    list = (
          <List component="nav" className={"scrollable-content"}>
            {credentials.map((credential, idx) => {
              if (!credential) {
                return null;
              }
              const { hostname, displayName, notes } = credential;
              return (
                <ListItem
                  key={idx}
                  button={Boolean(notes)}
                  onClick={() => handleOnCredentialClick(credential)}
                >
                  <ListItemText
                    primary={hostname}
                    secondary={displayName ? displayName : <i>Unknown owner</i>}
                  />
                  <ListItemSecondaryAction>
                    {notes ? (
                      <IconButtonLoading
                        tooltipTitle="Show SSH Public key"
                        onClick={() => handleOnCredentialClick(credential)}
                        aria-label="Show SSH Public key"
                        color="primary"
                      >
                        <VpnKey />
                      </IconButtonLoading>
                    ) : null}
                    <DeleteCredentialsButton
                      credential={credential}
                      onDelete={deleteCredential}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
    );
  }

  return (
    <React.Fragment>
      {has_credentials && button}
      {list}
      <AddCredentialsButton
        withUserInput={admin}
        open={openCreateDialog}
        onOpen={() => setOpenCreateDialog(true)}
        onClose={() => setOpenCreateDialog(false)}
        onCreate={createCredential}
      />
      <CredentialsInfoDialog
        credential={selectedCredential}
        open={openInfoDialog}
        onClose={handleCredentialsInfoClose}
      />
    </React.Fragment>
  );
};

const styles = theme => ({
  root: {}
});

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(AccountSSHKeys)
);
