import React, { Component } from "react";
import moment from "moment";
import { Field, Fields } from "redux-form";
import DateField from "../Form/Fields/DateField";
import WidgetButton from "../Widget/WidgetButton";
import InputField from "../Form/Fields/InputField";
import SelectField from "../Form/Fields/SelectField";
import SwitchField from "../Form/Fields/SwitchField";
import { connect } from "react-redux";
import AutoSuggestSelectField from "../Form/Fields/AutoSuggestSelectField";
import {
  OnlyIntegerValidator,
  RequiredValidator,
} from "../Form/Fields/FieldValidators";
import {
  DateValidator,
  OnlyNumberValidator,
} from "components/Form/Fields/FieldValidators";
import LinkIcon from "@material-ui/icons/Link";
import URLField from "components/Form/Fields/URLField";

import {
  MenuItem,
  Paper,
  withStyles,
  Grid,
  Typography,
  Button,
  Tooltip,
} from "@material-ui/core";
import Config from "../../constants/Config";
import Hint from "components/Attributes/Hint";

export function getValue(attribute) {
  const { value, type } = attribute;
  if ((!value && value !== 0 && value !== false) || value === "null") {
    return undefined;
  }
  switch (type) {
    case "INTEGER":
    case "FLOAT":
    case "ENUM":
      return Number(value);
    case "DECIMAL":
      return value;
    case "DATE":
      return moment(value).format("YYYY-MM-DD");
    case "STRING":
      return value;
    case "REFERENCE":
      return value.value;
    case "BOOLEAN":
      return JSON.parse(value);
    case "URL":
      return value;
    default:
      return;
  }
}

class Attribute extends Component {
  isMandatory = () => {
    const { constraintEntries } = this.props;
    return constraintEntries && constraintEntries.includes("MANDATORY");
  };
  renderValue = () => {
    const { editMode, editable, attribute, attrEnumEntries } = this.props;
    const id = attribute.id;
    if (editable && editMode) {
      const defaultValidators = [];
      if (this.isMandatory()) {
        defaultValidators.push(RequiredValidator);
      }
      let field;
      if (attribute.availableValues) {
        field = (
          <Field
            name={id}
            component={SelectField}
            validate={defaultValidators}
            ref={id}>
            {attribute.availableValues.map(function (availableValue, idx) {
              return (
                <MenuItem key={idx} value={availableValue.value}>
                  {availableValue.label}
                </MenuItem>
              );
            })}
          </Field>
        );
      } else {
        switch (attribute.type) {
          case "INTEGER":
            field = (
              <Field
                name={id}
                component={InputField}
                validate={[...defaultValidators, OnlyIntegerValidator]}
                ref={id}
                step="any"
              />
            );
            break;
          case "FLOAT":
          case "DECIMAL":
            field = (
              <Field
                name={id}
                component={InputField}
                validate={[...defaultValidators, OnlyNumberValidator]}
                step="any"
                ref={id}
              />
            );
            break;
          case "DATE":
            field = (
              <Field
                name={id}
                component={DateField}
                validate={[...defaultValidators, DateValidator]}
                ref={id}
              />
            );
            break;
          case "ENUM":
            field = (
              <Field
                name={id}
                component={AutoSuggestSelectField}
                validate={defaultValidators}
                ref={id}
                withEmptyOpt={true}
                options={
                  attrEnumEntries &&
                  attrEnumEntries.map((item) => ({
                    id: item.ordinal,
                    label: item.value,
                  }))
                }
              />
            );
            break;
          case "REFERENCE":
            const apiUrl =
              attribute.referencedEntity === "APP_USER"
                ? "/api/users/lookup"
                : null;
            field = (
              <Field
                name={id}
                style={{ maxWidth: "140px" }}
                component={AutoSuggestSelectField}
                validate={defaultValidators}
                valueLabel={attribute.value ? attribute.value.label : ""}
                apiUrl={apiUrl}
                maxResultNumber={10}
                ref={id}
                withEmptyOpt={true}
              />
            );
            break;
          case "BOOLEAN":
            field = (
              <Field
                name={id}
                component={SwitchField}
                validate={defaultValidators}
                ref={id}
              />
            );
            break;
          case "URL":
            field = (
              <Fields
                validate={defaultValidators}
                names={[id + ".visibleName", id + ".url", id]}
                component={URLField}
                props={{ id: id }}
              />
            );
            break;
          default:
            field = (
              <Field
                name={id}
                component={InputField}
                multiline
                validate={defaultValidators}
                ref={id}
              />
            );
        }
      }
      return <div>{field}</div>;
    }

    // not edit mode
    if (attribute.type === "BOOLEAN") {
      return (
        <div>
          <Field
            name={id}
            component={SwitchField}
            validate={[]}
            ref={id}
            disabled={true}
          />
        </div>
      );
    }

    if (attribute.value === undefined || attribute.value === null) {
      return "";
    }

    switch (attribute.type) {
      case "DATE":
        return (
          <Typography variant="body1">
            {moment(attribute.value, "YYYY-MM-DD").format(Config.dateFormat)}
          </Typography>
        );
      case "REFERENCE":
        return <Typography variant="body1">{attribute.value.label}</Typography>;
      case "ENUM":
        if (
          !attribute.value ||
          isNaN(Number(attribute.value)) ||
          !attrEnumEntries ||
          !attrEnumEntries.find(
            (item) => item.ordinal === Number(attribute.value)
          )
        ) {
          return null;
        }
        return (
          <Typography variant="body1">
            {
              attrEnumEntries.find(
                (item) => item.ordinal === Number(attribute.value)
              ).label
            }
          </Typography>
        );
      case "URL":
        const value = attribute.value;
        return (
          value &&
          value.url && (
            <Tooltip title={value.url} placement={"top"}>
              <Button
                component={"a"}
                href={value.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textTransform: "none" }}
                color="primary">
                <LinkIcon style={{ paddingRight: "5px" }} />
                {value.visibleName ? value.visibleName : value.url}
              </Button>
            </Tooltip>
          )
        );
    }
    return (
      <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
        {attribute.value}
      </Typography>
    );
  };

  render() {
    const { attribute, classes, editable, editMode } = this.props;
    const editing = editable && editMode;

    return (
      <Paper className={classes.container} testid="attribute">
        <Grid container>
          <Grid item className={classes.name}>
            <Typography variant="caption">{attribute.label}</Typography>
            <Hint hintText={attribute.hint} />
            {editing && this.isMandatory() && (
              <span className={classes.required}>*</span>
            )}
            {!editing && <Typography variant="caption">:</Typography>}
          </Grid>
          <Grid item className={classes.value}>
            <div style={{ width: "100%" }} testid={attribute.name}>
              {this.renderValue()}
            </div>
          </Grid>
          <Grid item>
            <Grid container>
              {attribute.widgets &&
                attribute.widgets.map((widget, idx) => {
                  return (
                    <Grid key={idx} item>
                      <WidgetButton widget={widget} />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const styles = () => ({
  name: {
    color: "#777",
    textAlign: "right",
    display: "inline-flex",
    paddingRight: "10px",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100px",
  },
  value: {
    display: "inline-flex",
    overflowWrap: "break-word",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  container: {
    padding: "10px",
    margin: "10px",
  },
  required: {
    color: "red",
    marginLeft: 2,
    alignSelf: "flex-start",
  },
});

const mapStateToProps = (state, { attribute }) => {
  return {
    attrEnumEntries: state.enumEntries.list
      ? state.enumEntries.list.filter(
          (item) => item.enumName === attribute.enumName
        )
      : undefined,
    constraintEntries:
      attribute &&
      state.attributes.constraintEntries &&
      state.attributes.constraintEntries[attribute.id],
  };
};

export default withStyles(styles)(connect(mapStateToProps, {})(Attribute));
