import React, { useState } from "react";
import Button from "components/Buttons/Button";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { connect } from "react-redux";
import { pushNotification } from "actions/notifications";
import { ROLE_DYNAMICPROPERTY_EDIT } from "../../constants/Authorities";
import { useApiRequest } from "actions/index";
import { updateDynamicProperty } from "actions/Configuration";
import { ODATA_ACCESS_CODES_ENABLED } from "../../constants/DynamicProperties";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { withRouter } from "react-router";

const DisableODataAccessCodeButton = ({
  pushNotification,
  updateDynamicProperty
}) => {
  const [open, setOpen] = useState(false);
  const [disabling, errorDisabling, runDisable] = useApiRequest(
    () => updateDynamicProperty(ODATA_ACCESS_CODES_ENABLED, false),
    () => {
      setOpen(false);
      pushNotification(
        "check",
        "success",
        "OData access code disabled sucessfully."
      );
    },
    (e, message) => {
      pushNotification(
        "error",
        "error",
        `Disabling OData access code failed${message ? `: ${message}` : ""}`
      );
    }
  );

  const confirm = async () => {
    runDisable();
  };

  return (
    <>
      <ButtonLoading
        style={{ marginRight: 10 }}
        onClick={() => setOpen(true)}
        authoritiesRequired={[ROLE_DYNAMICPROPERTY_EDIT]}
        tooltipTitle="Disable OData access link"
        loading={disabling}
        loadingTitle="Disabling the OData access link"
        aria-label="delete"
        variant="outlined"
        testid="disableodatalink"
        color="primary"
      >
        Disable the access link feature
      </ButtonLoading>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={() => setOpen(false)}
        >
          The OData access codes will be disabled, do you want to continue?
        </CloseableDialogTitle>
        {errorDisabling && (
          <DialogContent>
            <div className="error">{errorDisabling}</div>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => confirm()} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withRouter(
  connect(
    () => ({}),
    { pushNotification, updateDynamicProperty }
  )(DisableODataAccessCodeButton)
);
