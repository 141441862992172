import React, { Component } from "react";
import CredentialInfoDialog from "./CredentialInfoDialog";
import moment from "moment";
import {ROLE_CONTAINER_KILL, ROLE_CONTAINER_SYNC} from "../../constants/Authorities";
import DeleteIcon from "@material-ui/icons/Delete";
import FolderIcon from "@material-ui/icons/Folder";
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
    CircularProgress,
    Grid
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Config from "../../constants/Config";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButtonLoading from "components/Buttons/IconButtonLoading";


class DatalabListItem extends Component {

    render() {
        const {
            classes,
            withoutUser,
            isAdmin,
            container,
            idx,
            s3fsModeEnabled,
            openSynchronizeDialog,
            openRemoveDialog
        } = this.props;
        let backgroundColorStatus;
        const {
            containerOwner,
            datalabImage,
            containerName,
            containerStatus,
            containerCreated,
            containerServiceUrl,
            errorKill,
            containerDatalabHost
        } = container;
        switch (containerStatus) {
            case "RUNNING":
                backgroundColorStatus = Config.color.success;
                break;
            case "PAUSED":
                backgroundColorStatus = Config.color.warning;
                break;
            case "RESTARTING":
                backgroundColorStatus = Config.color.warning;
                break;
            case "REMOVING":
                backgroundColorStatus = Config.color.danger;
                break;
            case "EXITED":
                backgroundColorStatus = Config.color.danger;
                break;
            case "DEAD":
                backgroundColorStatus = Config.color.danger;
                break;
            case "CREATED":
                backgroundColorStatus = Config.color.gray;
                break;
            default:
                backgroundColorStatus = Config.color.danger;
                break;
        }

        // Specifies, whether it is possible to click on the item in order to follow a link into the Datalab environment
        // This is possible for the interactive Datalabs with known URL only
        let isContainerItemClickable = datalabImage && datalabImage.imageType.interactive && containerServiceUrl;

        return (
            <ListItem
                key={idx}
                className={classes.listItem}
                button
                component={isContainerItemClickable ? "a" : "div"}
                target={isContainerItemClickable ? "_blank" : ""}
                href={isContainerItemClickable ? containerServiceUrl : ""}
                disabled={!isContainerItemClickable}
            >
                <ListItemAvatar>
                    <Tooltip title={containerStatus}>
                        <div className={classes.progress}>
                            <CircularProgress
                                variant="determinate"
                                color="inherit"
                                size={30}
                                style={{color: backgroundColorStatus}}
                                value={100}
                                className={classes.progress1}
                            />
                            {!withoutUser && containerOwner && (
                                <Avatar color="primary" className={classes.avatar}>
                                    {containerOwner.label
                                        ? containerOwner.label.substring(0, 1)
                                        : ""}
                                </Avatar>
                            )}
                        </div>
                    </Tooltip>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <React.Fragment>
                            {(datalabImage ? datalabImage.label + " - " : "") + containerName}
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography component="p" variant="caption">
                                {
                                    "Created at " +
                                    moment(containerCreated).format(Config.dateTimeFormat) +
                                    (!withoutUser && containerOwner ? " by user " + containerOwner.label : "")
                                }
                            </Typography>
                            <Typography component="p" variant="caption">
                                {isAdmin && containerDatalabHost ? `Environment hosted on ${containerDatalabHost.label}` : ""}
                            </Typography>
                            <Typography className="error">{errorKill}</Typography>
                        </React.Fragment>
                    }
                />
                <ListItemSecondaryAction>
                    <React.Fragment>
                        <Grid container direction="row">
                            {
                                isContainerItemClickable ? (<CredentialInfoDialog container={container}/>) : null
                            }
                            {
                                // Allow synchronization actions only if not using S3FS and the container is an interactive environment
                                !s3fsModeEnabled && datalabImage && datalabImage.imageType.interactive &&
                                <IconButtonLoading
                                    title="Synchronize files between Datalab and Data hub"
                                    loadingTitle={isAdmin && (container.syncuping || container.syncdowning) ? "Synchronizing files" : ""}
                                    loading={container.syncuping || container.syncdowning}
                                    authoritiesRequired={[ROLE_CONTAINER_SYNC]}
                                    onClick={() => {
                                        openSynchronizeDialog(container);
                                    }}
                                    color="primary"
                                >
                                    <Grid item xs>
                                        <FolderIcon/>
                                    </Grid>
                                </IconButtonLoading>
                            }
                            <IconButtonLoading
                                title="Remove the Datalab"
                                loadingTitle={isAdmin ? "Removing the Datalab" : ""}
                                loading={container.killing}
                                authoritiesRequired={[ROLE_CONTAINER_KILL]}
                                onClick={() => {
                                    openRemoveDialog(container);
                                }}
                                color="primary"
                            >
                                <Grid item xs>
                                    <DeleteIcon/>
                                </Grid>
                            </IconButtonLoading>
                        </Grid>
                    </React.Fragment>
                </ListItemSecondaryAction>
            </ListItem>
        );
    };

}

const styles = {
    inline: {
        display: "inline"
    },
    status: {
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: "#dadada",
        marginRight: 10
    },
    listItem: {
        paddingRight: 50
    },
    progress: {
        position: "relative",
        height: 30
    },
    progress1: {},
    avatar: {
        position: "absolute",
        left: 5,
        top: 5,
        width: 20,
        height: 20,
        fontSize: 10
    }
};


export default withStyles(styles)(DatalabListItem);