import {
  ADD_PARTICIPATION,
  ADDING_PARTICIPANT,
  END_ADDING_PARTICIPANT,
  ADDING_PARTICIPANT_ERROR,
  REMOVING_PARTICIPANT,
  REMOVE_PARTICIPATION,
  END_REMOVING_PARTICIPANT,
  REMOVING_PARTICIPANT_ERROR,
  GET_PARTICIPATION_ROLES,
  LOADING_PARTICIPATION_ROLES,
  END_LOADING_PARTICIPATION_ROLES,
  PARTICIPATION_ROLES_ERROR,
  UNASSIGN_VALIDATION_FROM_COMMITTEE,
  ASSIGN_VALIDATION_TO_COMMITTEE
} from "../../../constants/ActionTypes";
import reduceEntity from "../../../api/ReduceEntity";
import { goThroughList } from "../../../api/List";
import { VALIDATION_COMMITTEE_TYPE } from "../../../api/Entity";

export default function(state = {}, action) {
  let result;
  let list = state.list;
  let current = state.current;
  const committeeId = action.committeeId;
  const participationId = action.participationId;
  const validation = action.validation;

  switch (action.type) {
    case GET_PARTICIPATION_ROLES:
      return { ...state, roles: action.roles, error_roles: undefined };
    case LOADING_PARTICIPATION_ROLES:
      return { ...state, loading_roles: true, error_roles: undefined };
    case END_LOADING_PARTICIPATION_ROLES:
      return { ...state, loading_roles: false };
    case PARTICIPATION_ROLES_ERROR:
      return { ...state, error_roles: action.error };

    case ADD_PARTICIPATION:
      result = goThroughList(
        list,
        current,
        action.participation.validationCommittee.id,
        validationCommittee => {
          return {
            ...validationCommittee,
            participations: [
              ...validationCommittee.participations,
              action.participation
            ]
          };
        }
      );
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list,
        error_participation: undefined
      };
    case ADDING_PARTICIPANT:
      return {
        ...state,
        adding_participation: true,
        error_participation: undefined
      };
    case END_ADDING_PARTICIPANT:
      return { ...state, adding_participation: false };
    case ADDING_PARTICIPANT_ERROR:
      return { ...state, error_participation: action.error };

    case REMOVE_PARTICIPATION:
      if (!current || current.id !== committeeId) {
        return { ...state };
      }
      result = goThroughList(
        current.participations,
        null,
        participationId,
        participation => {
          return null;
        }
      );
      return {
        ...state,
        current: Object.assign(
          {},
          {
            ...current,
            participations: result.list
          }
        )
      };
    case REMOVING_PARTICIPANT:
      if (!current || current.id !== committeeId) {
        return { ...state };
      }
      result = goThroughList(
        current.participations,
        null,
        participationId,
        participation => {
          participation.removing = true;
          participation.error = undefined;
          return participation;
        }
      );
      return {
        ...state,
        current: Object.assign(
          {},
          {
            ...current,
            participations: result.list
          }
        )
      };
    case END_REMOVING_PARTICIPANT:
      if (!current || current.id !== committeeId) {
        return { ...state };
      }
      result = goThroughList(
        current.participations,
        null,
        participationId,
        participation => {
          participation.removing = false;
          return participation;
        }
      );
      return {
        ...state,
        current: Object.assign(
          {},
          {
            ...current,
            participations: result.list
          }
        )
      };
    case REMOVING_PARTICIPANT_ERROR:
      if (!current || current.id !== committeeId) {
        return { ...state };
      }
      result = goThroughList(
        current.participations,
        null,
        participationId,
        participation => {
          participation.error = action.error;
          return participation;
        }
      );
      return {
        ...state,
        current: Object.assign(
          {},
          {
            ...current,
            participations: result.list
          }
        )
      };
    case ASSIGN_VALIDATION_TO_COMMITTEE:
      if (validation && current && current.validations) {
        current.validations.push(validation);
      }
      return {
        ...state,
        ...current
      };
    case UNASSIGN_VALIDATION_FROM_COMMITTEE:
      if (current && validation) {
        result = goThroughList(
          current.validations,
          null,
          validation.id,
          validation => {
            if (
              action.validation.validationCommittee &&
              action.validation.validationCommittee.id === current.id
            ) {
              return action.validation;
            } else {
              return null;
            }
          }
        );
        return {
          ...state,
          current: {
            ...current,
            validations: result.list
          }
        };
      }
  }
  return {
    ...state,
    current: reduceEntity(state.current, action, VALIDATION_COMMITTEE_TYPE)
  };
}
