import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, IconButton, Tooltip, Typography } from '@material-ui/core';

import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { concatUploadStatuses } from 'api/Uploads';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '0px 20px',
    color: theme.palette.alternateTextColor,
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    padding: 0,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  action: {
    color: theme.palette.alternateTextColor,
  },
}));

const UploadHeader = ({
  isUploading,
  uploadStatus,
  isOpen,
  onToggle,
  onClose,
}) => {

  const classes = useStyles();

  const ariaLabel = isOpen ? "collapse uploads" : "expand uploads";
  const arrowTooltip = isOpen ? "Collapse" : "Expand";

  const title = `${isUploading ? 'Uploading' : 'Uploaded'} ${uploadStatus.uploaded} of ${uploadStatus.allFiles} files`;
  const tooltipLabel = concatUploadStatuses(uploadStatus);

  const renderActions = () => {
    return (
      <>
        <IconButton
          className={classes.action}
          aria-label={ariaLabel}
          onClick={onToggle}
        >
          <Tooltip title={arrowTooltip}>
            {isOpen ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
          </Tooltip>
        </IconButton>

        <IconButton
          className={classes.action}
          aria-label="close uploads"
          onClick={onClose}
        >
          <Tooltip title={'Close'}>
            <CloseSharpIcon/>
          </Tooltip>
        </IconButton>
      </>
    );
  };

  return (
    <Container className={classes.container}>
      <Box alignItems="center" display="flex">
        <Box flexGrow={1}>
          <Tooltip title={tooltipLabel}>
            <Typography
              color="primary"
              className={classes.title}
            >
              {title}
            </Typography>
          </Tooltip>
        </Box>
        <Box>
          {renderActions()}
        </Box>
      </Box>
    </Container>
  );
};

export default UploadHeader;
