import React, { Component } from "react";
import { connect } from "react-redux";
import SQLForm from "./SQLForm";
import SQLTable from "../Table/SQLTable";
import { executeSQL } from "../../actions/Admin";
import { Grid } from "@material-ui/core";

class RestPanel extends Component {
  handleSubmit = form => {
    const { executeSQL } = this.props;
    executeSQL(form.query, form.includeColumnNames);
  };

  render() {
    const { data, columns } = this.props;
    return (
      <Grid container justify="center" className={"scrollable-content"}>
        <Grid item xs={11}>
          <SQLForm onSubmit={this.handleSubmit} />
          <SQLTable data={data} columns={columns} />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.admin.result_sql,
    columns: state.admin.columns_sql
  };
};
export default connect(
  mapStateToProps,
  { executeSQL }
)(RestPanel);
