import { lighten } from "@material-ui/core/styles/colorManipulator";
import classNames from "classnames";
import RestrictedComponent from "components/RestrictedComponent";
import { MODEL_REVISION_TYPE, MODEL_TYPE } from "../../api/Entity";
import AttributesFilter from "components/Attributes/filter";
import { Toolbar, Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
    height: 64,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    flexGrow: 1,
  },
  title: {
    flex: "0 0 auto",
  },
  actionsContent: {
    "display": "flex",
    "flexDirection": "row",
    "& button": {
      marginLeft: 10,
    },
  },
});

const EnhancedTableToolbar = ({
  classes,
  title,
  actions,
  selectedEntities,
  entityType,
  withoutAttributeFilters,
  actionProps,
}) => {
  const createActionElement = (
    { component, allowedEntityTypes, ignoreRevisionSystem, ...otherProps },
    idx
  ) => {
    return !allowedEntityTypes || allowedEntityTypes.includes(entityType) ? (
      <RestrictedComponent
        key={idx}
        componentButton={component}
        selectedEntities={selectedEntities}
        entityType={
          entityType === MODEL_TYPE ? MODEL_REVISION_TYPE : entityType
        }
        {...(actionProps ? actionProps : {})}
        {...otherProps}
      />
    ) : null;
  };

  const numSelected = selectedEntities.length;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        )}
      </div>
      <Grid container justify="flex-end" className={classes.actions}>
        {numSelected > 0 ? (
          <div className={classes.actionsContent}>
            {" "}
            {actions && actions.map(createActionElement)}
          </div>
        ) : (
          <Grid container justify="flex-end" className={classes.actions}>
            {withoutAttributeFilters ? null : (
              <AttributesFilter entityType={entityType} />
            )}
          </Grid>
        )}
      </Grid>
    </Toolbar>
  );
};

export default withStyles(toolbarStyles)(connect()(EnhancedTableToolbar));
