import React, { Component } from 'react';
import { connect } from "react-redux";
import Tokens from "components/Tokens";
import {createToken, revokeToken} from "actions/Users";

class AccountTokens extends Component {
    render() {
        const { tokens, loading, createToken, creating, error, token, revokeToken } = this.props;

        return (
            <Tokens
                tokens={tokens}
                loading={loading} creating={creating} error={error} tokenCreated={token}
                onCreateToken={createToken} revokeToken={revokeToken} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        creating: state.auth.creating_token,
        loading: state.auth.loading_tokens,
        error: state.auth.error_create_token,
        tokens: state.auth.tokens,
        token: state.auth.token_created
    };
};

export default connect(
    mapStateToProps, { createToken, revokeToken }
)(AccountTokens);