import {
  GET_ENTITY,
  SETTING_COMMITTEE,
  END_SETTING_COMMITTEE,
  SETTING_COMMITTEE_ERROR,
  ASSIGNING_VALIDATION_MODEL,
  UNASSIGNING_VALIDATION_MODEL,
  END_ASSIGNING_VALIDATION_MODEL,
  END_UNASSIGNING_VALIDATION_MODEL,
  ASSIGNING_VALIDATION_MODEL_ERROR,
  UNASSIGNING_VALIDATION_MODEL_ERROR,
  UNASSIGN_VALIDATION_MODEL,
  LOADING_VALIDATIONS_MODEL,
  END_LOADING_VALIDATIONS_MODEL,
  GET_VALIDATIONS_MODEL,
  VALIDATIONS_MODEL_ERROR,
  ASSIGNING_VALIDATION_MODEL_REVISIONS,
  ASSIGNING_VALIDATION_MODEL_REVISIONS_END,
  ASSIGNING_VALIDATION_MODEL_REVISIONS_ERROR,
  ASSIGN_VALIDATION_TO_COMMITTEE,
  UNASSIGN_VALIDATION_FROM_COMMITTEE,
  OPEN_ASSIGN_MODELS,
  CLOSE_ASSIGN_MODELS,
  PUSH_NOTIFICATION
} from "../../constants/ActionTypes";
import { clearAttributes } from "../../api/Attributes";
import axios from "axios";
import { VALIDATION_TYPE } from "../../api/Entity";

const querystring = require("querystring");

export function getValidationsForModel(modelId) {
  return function(dispatch) {
    dispatch({
      type: LOADING_VALIDATIONS_MODEL,
      modelId: modelId
    });

    axios
      .get(`/api/models/${modelId}/validations`, {
        withCredentials: true
      })
      .then(response => {
        let validations = response.data;
        dispatch({
          type: GET_VALIDATIONS_MODEL,
          validations: validations,
          modelId: modelId
        });
        dispatch({
          type: END_LOADING_VALIDATIONS_MODEL,
          modelId: modelId
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: VALIDATIONS_MODEL_ERROR,
          error: message,
          modelId: modelId
        });
        dispatch({
          type: END_LOADING_VALIDATIONS_MODEL,
          modelId: modelId
        });
      });
  };
}
export function getValidationsForModelRevision(modelId, modelRevisionId) {
  return function(dispatch) {
    dispatch({
      type: LOADING_VALIDATIONS_MODEL,
      modelId: modelId,
      modelRevisionId: modelRevisionId
    });

    axios
      .get(`/api/models/${modelId}/revisions/${modelRevisionId}/validations`, {
        withCredentials: true
      })
      .then(response => {
        let validations = response.data;
        dispatch({
          type: GET_VALIDATIONS_MODEL,
          validations: validations,
          modelId: modelId,
          modelRevisionId: modelRevisionId
        });
        dispatch({
          type: END_LOADING_VALIDATIONS_MODEL,
          modelId: modelId,
          modelRevisionId: modelRevisionId
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: VALIDATIONS_MODEL_ERROR,
          error: message,
          modelId: modelId,
          modelRevisionId: modelRevisionId
        });
        dispatch({
          type: END_LOADING_VALIDATIONS_MODEL,
          modelId: modelId,
          modelRevisionId: modelRevisionId
        });
      });
  };
}

export function setCommittee(validation, committeeId) {
  return function(dispatch) {
    dispatch({
      type: SETTING_COMMITTEE,
      validation: validation
    });

    let committee = committeeId === "none" ? "" : { id: committeeId };
    let url = `/api/validations/${validation.id}`;

    axios
      .patch(url, committee, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        validation = response.data;

        if (committeeId === "none") {
          dispatch({
            type: UNASSIGN_VALIDATION_FROM_COMMITTEE,
            validation: validation
          });
        } else {
          dispatch({
            type: ASSIGN_VALIDATION_TO_COMMITTEE,
            validation: validation
          });
        }

        dispatch({
          type: GET_ENTITY,
          entityType: VALIDATION_TYPE,
          entity: clearAttributes(validation)
        });
        dispatch({
          type: END_SETTING_COMMITTEE,
          validation: validation
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: "Validation committee updated"
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: SETTING_COMMITTEE_ERROR,
          validation: validation,
          error: message
        });
        dispatch({
          type: END_SETTING_COMMITTEE,
          validation: validation
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Validation committee change failed" + message
        });
      });
  };
}

export function unassignModel(validationId, modelRevisionId) {
  return function(dispatch) {
    dispatch({
      type: UNASSIGNING_VALIDATION_MODEL,
      validationId,
      modelRevisionId
    });
    axios
      .patch(
        `/api/validations/${validationId}/model-revisions/${modelRevisionId}`,
        querystring.stringify({ id: modelRevisionId }),
        {
          withCredentials: true
        }
      )
      .then(response => {
        let validation = response.data;
        dispatch({
          type: GET_ENTITY,
          entityType: VALIDATION_TYPE,
          entity: clearAttributes(validation),
          modelRevisionId
        });
        dispatch({
          type: UNASSIGN_VALIDATION_MODEL,
          validationId,
          modelRevisionId
        });
        dispatch({
          type: END_UNASSIGNING_VALIDATION_MODEL,
          validationId,
          modelRevisionId
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: "Model unassigned"
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: UNASSIGNING_VALIDATION_MODEL_ERROR,
          error: message,
          validationId,
          modelRevisionId
        });
        dispatch({
          type: END_UNASSIGNING_VALIDATION_MODEL,
          validationId,
          modelRevisionId
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Validation change failed" + message
        });
      });
  };
}
export function assignModelRevisions(validationId, modelRevisionIds) {
  return function(dispatch) {
    dispatch({
      type: ASSIGNING_VALIDATION_MODEL_REVISIONS
    });

    axios
      .patch(
        "/api/validations/" + validationId + "/model-revisions",
        modelRevisionIds,
        {
          withCredentials: true
        }
      )
      .then(response => {
        let validation = response.data;
        dispatch({
          type: GET_ENTITY,
          entityType: VALIDATION_TYPE,
          entity: clearAttributes(validation),
          modelRevisionIds: modelRevisionIds
        });
        dispatch({
          type: ASSIGNING_VALIDATION_MODEL_REVISIONS_END,
          validationId,
          modelRevisionIds: modelRevisionIds
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: "Validation assigned"
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ASSIGNING_VALIDATION_MODEL_REVISIONS_ERROR,
          error: message,
          validationId,
          modelRevisionIds: modelRevisionIds
        });
        dispatch({
          type: ASSIGNING_VALIDATION_MODEL_REVISIONS_END,
          validationId,
          modelRevisionIds: modelRevisionIds
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Validation change failed" + message
        });
      });
  };
}
export function initAssignError() {
  return function(dispatch) {
    dispatch({
      type: ASSIGNING_VALIDATION_MODEL_REVISIONS_ERROR,
      error: null
    });
  };
}

export function openAssignModels() {
  return function(dispatch) {
    dispatch({
      type: OPEN_ASSIGN_MODELS
    });
  };
}
export function closeAssignModels() {
  return function(dispatch) {
    dispatch({
      type: CLOSE_ASSIGN_MODELS
    });
  };
}
