import React from "react";
import Breadcrumbs from "components/Breadcrumbs/index";
import Header from "components/Header/index";
import ResolveTask from "components/Tasks/TaskDialog/ResolveTask";
import WarningBanner from "components/Header/WarningBanner";
import {closeSnackbar} from "../../../actions";
import {connect} from "react-redux";
import {getParticipationRoles} from "../../../actions/Validations/Committees";
import {refreshTasks} from "../../../actions/Tasks";
import {renderRoutes} from "react-router-config";
import {showLicenseBanner} from "actions/License";
import {Snackbar} from "@material-ui/core";
import AssignEntityDialog from "components/Entity/AssignEntityDialog";
import UploadPanel from 'components/Uploads/UploadPanel';

class MainApp extends React.Component {
  refreshTimeout = null;

  constructor(props) {
    super(props);
    props.getParticipationRoles();
    props.refreshTasks();
  }

  handleRequestClose = () => {
    const {closeSnackbar} = this.props;
    closeSnackbar();
  };

  render() {
    const {route, snackbar, showLicenseBanner, license} = this.props;

    return (
      <div
        onClick={license && license.showsWarningBanner ? showLicenseBanner : undefined}
        className="main-app-container">
        <section id="page-container" className="app-page-container">
          <div className="scrollable-container">
            <div className="scrollable-header">
              <WarningBanner/>
              <Header/>
              <Breadcrumbs/>
            </div>
            <div className="scrollable-content" style={{overflow: "hidden"}}>
              <div className="app-content-wrapper">
                <div className="app-content">
                  <div className="full-height">
                    {renderRoutes(route.routes, {})}
                  </div>
                </div>
                <AssignEntityDialog/>
                <Snackbar
                  open={snackbar.open}
                  message={snackbar.message}
                  bodyStyle={snackbar.bodyStyle}
                  autoHideDuration={snackbar.autoHideDuration}
                  onRequestClose={this.handleRequestClose}
                />
              </div>
            </div>
          </div>
        </section>
        <UploadPanel />
        <ResolveTask/>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    snackbar: state.settings.snackbar,
    license: state.license.license,
    route: ownProps.route
  };
};

const mapDispatchToProps = dispatch => ({
  closeSnackbar: () => dispatch(closeSnackbar())
});

export default connect(
  mapStateToProps,
  {
    mapDispatchToProps,
    getParticipationRoles,
    refreshTasks,
    showLicenseBanner
  }
)(MainApp);
