import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../Buttons/ButtonLoading";
import CreateRepositoryFullDialogForm from "./CreateDialogForm";
import {
  ROLE_GIT_REPO_CREATE,
  ROLE_GIT_REPO_VIEW
} from "../../constants/Authorities";
import { GIT } from "../../constants/Features";
import { createRepository, getRepository } from "actions/Repository";
import {
  Typography,
  Dialog,
  DialogContent,
  TextField,
  InputAdornment
} from "@material-ui/core";
import IconButton from "components/Buttons/IconButton";
import { pushNotification } from "actions/notifications";
import { FileCopy } from "@material-ui/icons";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";

class EntityButton extends Component {
  state = {
    openCreate: false,
    openInfo: null
  };

  componentDidMount() {
    this.refresh(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { entity } = nextProps;
    if (entity && (!this.props.entity || entity.id !== this.props.entity.id)) {
      this.refresh(nextProps);
    }
  }

  refresh = props => {
    const { entity, features, getRepository } = props;
    const isFeatureEnabled = features && features.includes(GIT);
    if (entity && isFeatureEnabled) {
      getRepository(entity);
    }
  };

  handleCreateRepository = () => {
    this.setState({
      openCreate: true
    });
  };
  handleSubmitRepository = form => {
    const { entity, createRepository } = this.props;
    createRepository(entity, form);
  };
  handleCloseCreateRepository = () => {
    this.setState({
      openCreate: false
    });
  };

  openInfo = repository => {
    this.setState({
      openInfo: repository
    });
  };
  closeInfo = () => {
    this.setState({
      openInfo: null
    });
  };
  handleCopyLink = () => {
    const { openInfo } = this.state;
    const { pushNotification } = this.props;
    navigator.clipboard.writeText(openInfo.externalLink).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
        pushNotification("check", "success", "Link copy to clipboard.");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  render() {
    const { entity } = this.props;
    const { openCreate, openInfo } = this.state;
    if (!entity || !entity.id) {
      return null;
    }

    if (!entity.repositories || entity.repositories.length === 0) {
      return (
        <React.Fragment>
          <ButtonLoading
            testid="repositorybutton"
            tooltipPlacement="left"
            tooltipTitle="Create a repository"
            loading={entity.loading_repository || entity.creating_repository}
            loadingTitle={
              entity.creating_repository
                ? "Creating repository"
                : "Loading repositories"
            }
            loadingSize={22}
            style={{
              width: "100%",
              justifyContent: "flex-start",
              textAlign: "left"
            }}
            color="primary"
            authoritiesRequired={[
              entity.loading_repository
                ? ROLE_GIT_REPO_VIEW
                : ROLE_GIT_REPO_CREATE
            ]}
            aria-label="Create a repository"
            onClick={this.handleCreateRepository}
          >
            <img
              className="material-icons"
              style={{ marginRight: 15, height: 18 }}
              src="/assets/images/Git-Icon.png"
            />
            <span
              className="badge"
              style={{
                position: "absolute",
                top: "-3px",
                left: "25px",
                color: "#607c8a",
                fontSize: "15px"
              }}
            >
              +
            </span>
            Create a repository
          </ButtonLoading>
          <CreateRepositoryFullDialogForm
            onSubmit={this.handleSubmitRepository}
            handleClose={this.handleCloseCreateRepository}
            open={openCreate}
            entity={entity}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {entity.repositories.map((repository, idx) => {
          return (
            <ButtonLoading
              key={idx}
              testid="repositorybutton"
              tooltipPlacement="left"
              tooltipTitle="Open the repository"
              onClick={() => {
                this.openInfo(repository);
              }}
              target="_blank"
              style={{
                width: "100%",
                justifyContent: "flex-start",
                textAlign: "left"
              }}
              color="primary"
              authoritiesRequired={[ROLE_GIT_REPO_VIEW]}
              aria-label="Open the repository"
            >
              <img
                className="material-icons"
                style={{ marginRight: 15, height: 18 }}
                src="/assets/images/Git-Icon.png"
              />
              {repository.name}
            </ButtonLoading>
          );
        })}
        {openInfo && (
          <Dialog onClose={this.closeInfo} open={Boolean(openInfo)}>
            <CloseableDialogTitle
              id="simple-dialog-title"
              handleClose={this.closeInfo}
            >
              {openInfo.name}
            </CloseableDialogTitle>
            <DialogContent>
              <Typography>{openInfo.description}</Typography>
              <TextField
                style={{ width: "100%", marginTop: 20, minWidth: 400 }}
                variant="outlined"
                type={"text"}
                label="GIT repository link"
                value={openInfo.externalLink}
                onChange={null}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Copy GIT repository link"
                        onClick={this.handleCopyLink}
                      >
                        <FileCopy />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </DialogContent>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    features: state.features.list
  };
};

export default connect(
  mapStateToProps,
  {
    getRepository,
    createRepository,
    pushNotification
  }
)(EntityButton);
