import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Field, reduxForm } from "redux-form";
import { RequiredValidator } from "../Form/Fields/FieldValidators";
import SecurityLevels from "../SecurityLevels/input";
import InputField from "components/Form/Fields/InputField";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { FOLDER_OVERVIEW_PATH } from "../../constants/Routes";
import { isAuthorized } from "../../api/Authorities";
import { pushNotification } from "actions/notifications";
import { DialogContent, Dialog, DialogActions } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";

class CreateFolderDialogForm extends Component {
  componentWillReceiveProps(nextProps) {
    const {
      handleClose,
      creating,
      error_creating,
      history,
      folder,
      me,
      pushNotification
    } = nextProps;
    if (this.props.creating && !creating && !error_creating) {
      handleClose();
      if (isAuthorized(me, folder.authoritiesRequired)) {
        history.push(FOLDER_OVERVIEW_PATH.replace(":folderId", folder.id));
      } else {
        pushNotification(
          "success",
          "success",
          "Shared folder created but you don't have access to it."
        );
      }
    }
  }

  render() {
    const {
      handleSubmit,
      handleClose,
      open,
      error_creating,
      creating
    } = this.props;

    return (
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleSubmit}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleClose}
          >
            Create folder
          </CloseableDialogTitle>
          <DialogContent>
            <div>
              <Field
                label="Name"
                name="name"
                component={InputField}
                validate={[RequiredValidator]}
                ref="name"
              />
            </div>
            <div>
              <Field
                label="Description"
                name="description"
                component={InputField}
                multiline
                validate={[]}
                ref="description"
              />
            </div>
            <SecurityLevels multiple />
          </DialogContent>
          <DialogActions>
            <ButtonLoading
              loading={creating}
              loadingTitle="Creating the new folder"
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {"Create new folder"}
            </ButtonLoading>
            <div className="error">{error_creating}</div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    creating: state.folders.creating,
    error_creating: state.folders.error_creating,
    folder: state.folders.current,
    me: state.auth.me
  };
};

CreateFolderDialogForm = reduxForm({
  form: "createfolderdialog",
  shouldWarn: () => {
    return true;
  },
  onSubmitFail: scrollToFirstError
})(CreateFolderDialogForm);

export default withRouter(
  connect(
    mapStateToProps,
    { pushNotification }
  )(CreateFolderDialogForm)
);
