import {
  GET_REPOSITORY,
  LOADING_REPOSITORY,
  END_LOADING_REPOSITORY,
  ERROR_LOADING_REPOSITORY,
  CREATE_REPOSITORY,
  CREATING_REPOSITORY,
  END_CREATING_REPOSITORY,
  ERROR_CREATING_REPOSITORY
} from "../../constants/ActionTypes";
import axios from "axios";

export function getRepository(entity) {
  return function(dispatch) {
    const ownerId = entity.id;
    dispatch({
      type: LOADING_REPOSITORY,
      ownerId: ownerId
    });

    axios
      .get("/api/git-repo/find-by-entity-id?id=" + ownerId, {
        withCredentials: true
      })
      .then(response => {
        let repositories = response.data;
        dispatch({
          type: GET_REPOSITORY,
          repositories: repositories,
          ownerId: ownerId
        });
        dispatch({
          type: END_LOADING_REPOSITORY,
          ownerId: ownerId
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_LOADING_REPOSITORY,
          error: message,
          ownerId: ownerId
        });
        dispatch({
          type: END_LOADING_REPOSITORY,
          ownerId: ownerId
        });
      });
  };
}
export function createRepository(entity, form) {
  return function(dispatch) {
    const ownerId = entity.id;
    dispatch({
      type: CREATING_REPOSITORY,
      ownerId: ownerId
    });

    axios
      .post(
        "/api/git-repo/create",
        {
          ...form,
          entityId: ownerId
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        let repository = response.data;
        dispatch({
          type: CREATE_REPOSITORY,
          repository: repository,
          ownerId: ownerId
        });
        dispatch({
          type: END_CREATING_REPOSITORY,
          ownerId: ownerId
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_CREATING_REPOSITORY,
          error: message,
          ownerId: ownerId
        });
        dispatch({
          type: END_CREATING_REPOSITORY,
          ownerId: ownerId
        });
      });
  };
}
