import React from "react";
import { Paper, Typography } from "@material-ui/core";

const AccompanyingText = () => {
  return (
    <Paper variant="outlined" style={{ padding: "10px 20px" }}>
      <Typography variant="subtitle1">
      Export your portfolio data for any selected history dates by choosing the desired dates. 
      If no date is set, then the application will export one currently valid snapshot of the portfolio data.<br/><br/>
      The <strong>Date Selection Assistant</strong> can provide you with a predefined set of dates. 
      An example could be <i>"Last day of every month in previous 12 months"</i>.<br/><br/>
      The export runs in the background, and the processing time can be up to 
      minutes (depending on the number of selected export snapshots). The resulting file is stored in 
      your personal Data Hub's Personal folder named <strong>My Exports</strong>.
      </Typography>
    </Paper>
  );
};

export default AccompanyingText;
