import React, { useEffect, useState } from 'react';

import { useApiRequestV2 } from 'actions';
import { findAll } from 'actions/SecurityLevel';
import SecurityLevelsListContainer from 'components/Admin/Users/SecurityLevels/SecurityLevelsListContainer';
import PanelHeader from 'components/Admin/Users/Common/PanelHeader';
import CreateContainer from 'components/Admin/Users/SecurityLevels/Dialog/CreateContainer';

const SecurityLevelsPanel = () => {

  const [securityLevels, setSecurityLevels] = useState([]);
  const { isLoading, isError, errorObject, run } = useApiRequestV2(findAll, setSecurityLevels);

  useEffect(() => {
    run();
  }, []);

  const addSecurityLevel = (securityLevel) => {
    if (securityLevel) {
      setSecurityLevels((securityLevels) => [securityLevel, ...securityLevels]);
    }
  };

  const updateSecurityLevel = (updated) => {
    setSecurityLevels((securityLevels) =>
      securityLevels.map(old => old.id === updated.id ? updated : old,
      ));
  };

  return (
    <>
      <PanelHeader buttonEl={<CreateContainer onSubmit={addSecurityLevel}/>}/>
      <SecurityLevelsListContainer
        isLoading={isLoading}
        securityLevels={securityLevels}
        isError={isError}
        errorObject={errorObject}
        onUpdate={updateSecurityLevel}
      />
    </>
  );
};

export default SecurityLevelsPanel;
