import reduceSecurityLevel from "./ReduceSecurityLevel";
import reduceCreateWorkflows from "./ReduceCreateWorkflows";
import reduceWorkflows from "./ReduceWorkflows";
import reduceAttributes from "./ReduceAttributes";
import reduceAttachments from "./ReduceAttachments";
import reduceDeleteEntity from "./ReduceDeleteEntity";
import reduceAuditTrails from "./ReduceAuditTrails";
import reduceRepository from "./ReduceRepository";
import reduceDocuments from "./ReduceDocuments";

export default function(entity, action, entityType) {
  if (!entity) {
    return reduceAttributes(null, action, entityType);
  }
  return reduceDocuments(
    reduceRepository(
      reduceAuditTrails(
        reduceAttributes(
          reduceDeleteEntity(
            reduceSecurityLevel(
              reduceCreateWorkflows(
                reduceWorkflows(reduceAttachments(entity, action), action),
                action
              ),
              action
            ),
            action
          ),
          action,
          entityType
        ),
        action
      ),
      action
    ),
    action
  );
}
