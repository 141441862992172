import React, { Component } from "react";
import WorkflowField from "./field";
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

class WorkflowJBPMFields extends Component {
  getField = fieldId => {
    const { form } = this.props;
    for (let i = 0; form && form.fields && i < form.fields.length; i++) {
      const field = form.fields[i];
      if (field.id === fieldId) {
        return field;
      }
    }
    return null;
  };

  renderComponent = component => {
    const { classes } = this.props;
    if (component && component.properties) {
      if (component.properties.field_id) {
        return (
          <WorkflowField field={this.getField(component.properties.field_id)} />
        );
      } else if (component.properties.HTML_CODE) {
        return (
          <div className={classes.jbpmForm}
            dangerouslySetInnerHTML={{ __html: component.properties.HTML_CODE }}
          />
        );
      }
    }
  };
  renderColumn = (column, idx) => {
    const layoutComponents = column.layoutComponents;
    return (
      <Grid
        item
        key={idx}
        style={{ flexBasis: "auto" }}
        xs={Number(column.span)}
      >
        {layoutComponents &&
          layoutComponents.map((layoutComponent, idx) => {
            return <div key={idx}>{this.renderComponent(layoutComponent)}</div>;
          })}
        {this.renderRows(column.rows)}
      </Grid>
    );
  };
  renderRow = row => {
    return (
      <Grid item xs={12}>
        <Grid container direction="column">
          {row.layoutColumns.map((column, idx) => {
            return this.renderColumn(column, idx);
          })}
        </Grid>
      </Grid>
    );
  };

  renderRows = rows => {
    return rows.map((row, idx) => {
      return (
        <Grid key={idx} container direction="row">
          {this.renderRow(row)}
        </Grid>
      );
    });
  };

  render() {
    const { form } = this.props;

    if (!form || !form.layoutTemplate) {
      return null;
    }

    return this.renderRows(form.layoutTemplate.rows);
  }
}

const styles = () => ({
  jbpmForm: {
    '& h1': {
      fontSize: "2rem"
    },
    '& h2': {
      fontSize: "1.75rem"
    },
    '& h3': {
      fontSize: "1.5rem"
    },
    '& h4': {
      fontSize: "1.25r"
    },
    '& h5': {
      fontSize: "1rem"
    },
    '& h6': {
      fontSize: ".75rem"
    }
  }
});

export default withStyles(styles)(WorkflowJBPMFields);
