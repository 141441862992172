import React, {useEffect} from "react";
import {Redirect} from "react-router";
import {ID_REGEX, MODEL_OVERVIEW_PATH} from "constants/Routes";
import {useDispatch, useSelector} from "react-redux";
import {getEntityTypeId} from "api/Entity";
import {getEntity} from "actions/Entity";
import {MODEL_TYPE} from "../../../../../api/Entity";

const BaseModelShortcut = ({match}) => {
    const model = useSelector(state => state.models.current);
    const error = useSelector(state => state.entity[MODEL_TYPE] && state.entity[MODEL_TYPE].error);
    const loading = useSelector(state => state.entity[MODEL_TYPE] && state.entity[MODEL_TYPE].loading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEntity(MODEL_TYPE)(match.params.entityId));
    }, []);

    if(error){
        return <Redirect to={{ pathname: "/404" }} />
    }
    if(loading || !model){
        return <div id="loader-container" />;
    }
    const pathname =
        MODEL_OVERVIEW_PATH
            .replace(":entityType" + ID_REGEX, getEntityTypeId(model))
            .replace(":entityId" + ID_REGEX, model.id);
    return <Redirect to={{ pathname, search: location.search }} />
};

export default BaseModelShortcut;