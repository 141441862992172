import {ODATA} from "./Features";
import {ODATA_ACCESS_CODES_ENABLED} from "./DynamicProperties";

export const ID_REGEX = "(undefined|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})?";

export const APP_PATH = "";
export const APP_OVERVIEW_PATH = APP_PATH + "/overview";

export const MODELS_PATH = APP_PATH + "/models";
export const MODELS_TYPE_PATH = MODELS_PATH + "/:entityType" + ID_REGEX;
export const MODEL_PATH = MODELS_TYPE_PATH + "/:entityId" + ID_REGEX;
export const MODEL_CREATE_PATH = MODELS_TYPE_PATH + "/:securityLevel" + ID_REGEX + "/create";
export const MODEL_REVISION_PATH = MODEL_PATH + "/revision/:revisionId" + ID_REGEX;
export const MODEL_REVISION_CREATE_PATH = MODEL_PATH + "/revision/create";
export const MODEL_USAGES_TYPE_PATH = MODEL_REVISION_PATH + "/usages/:usageType" + ID_REGEX;
export const MODEL_USAGE_PATH = MODEL_USAGES_TYPE_PATH + "/:usageId" + ID_REGEX;
export const MODEL_USAGE_CREATE_PATH = MODEL_REVISION_PATH + "/usages/:usageType" + ID_REGEX + "/create";
export const MODELS_SHORT_PATH = APP_PATH + "/model/:entityId" + ID_REGEX;
export const MODEL_REVISION_SHORT_PATH =  APP_PATH +"/model-revisions/:revisionId" + ID_REGEX;

export const MODELS_OVERVIEW_PATH = MODELS_PATH + "/overview";
export const MODELS_TYPE_OVERVIEW_PATH = MODELS_TYPE_PATH + "/overview";
export const MODEL_OVERVIEW_PATH = MODEL_PATH + "/overview";
export const MODEL_CREATE_OVERVIEW_PATH = MODEL_CREATE_PATH + "/overview";
export const MODEL_REVISION_OVERVIEW_PATH = MODEL_REVISION_PATH + "/overview";
export const MODEL_REVISION_CREATE_OVERVIEW_PATH = MODEL_REVISION_CREATE_PATH + "/overview";
export const MODEL_USAGE_OVERVIEW_PATH = MODEL_USAGE_PATH + "/overview";
export const MODEL_USAGE_CREATE_OVERVIEW_PATH = MODEL_USAGE_CREATE_PATH + "/overview";
export const MODELS_SHORT_OVERVIEW_PATH = MODELS_SHORT_PATH + "/overview";
export const MODEL_REVISION_SHORT_OVERVIEW_PATH = MODEL_REVISION_SHORT_PATH + "/overview";

export const MODEL_MONITORING_PATH = APP_PATH + "/model-monitoring";
export const MODEL_MONITORING_OVERVIEW_PATH = MODEL_MONITORING_PATH + "/overview";
export const MODEL_MONITORING_EXPANDED_PATH = MODEL_MONITORING_PATH + "/:entityId" + ID_REGEX + "/overview";

export const VALIDATIONS_PATH = APP_PATH + "/validations";
export const VALIDATIONS_TYPE_PATH = VALIDATIONS_PATH + "/:entityType" + ID_REGEX;
export const VALIDATION_PATH = VALIDATIONS_TYPE_PATH + "/:entityId" + ID_REGEX;
export const VALIDATION_CREATE_PATH = VALIDATIONS_TYPE_PATH + "/:securityLevel" + ID_REGEX + "/create";

export const VALIDATIONS_OVERVIEW_PATH = VALIDATIONS_PATH + "/overview";
export const VALIDATIONS_TYPE_OVERVIEW_PATH = VALIDATIONS_TYPE_PATH + "/overview";
export const VALIDATION_OVERVIEW_PATH = VALIDATION_PATH + "/overview";
export const VALIDATION_CREATE_OVERVIEW_PATH = VALIDATION_CREATE_PATH + "/overview";

export const VALIDATION_COMMITTEES_PATH = APP_PATH + "/committees";
export const VALIDATION_COMMITTEES_TYPE_PATH = VALIDATION_COMMITTEES_PATH + "/:entityType" + ID_REGEX;
export const VALIDATION_COMMITTEE_PATH = VALIDATION_COMMITTEES_TYPE_PATH + "/:entityId" + ID_REGEX;
export const VALIDATION_COMMITTEE_CREATE_PATH = VALIDATION_COMMITTEES_TYPE_PATH + "/:securityLevel" + ID_REGEX + "/create";

export const VALIDATION_COMMITTEES_OVERVIEW_PATH = VALIDATION_COMMITTEES_PATH + "/overview";
export const VALIDATION_COMMITTEES_TYPE_OVERVIEW_PATH = VALIDATION_COMMITTEES_TYPE_PATH + "/overview";
export const VALIDATION_COMMITTEE_OVERVIEW_PATH = VALIDATION_COMMITTEE_PATH + "/overview";
export const VALIDATION_COMMITTEE_CREATE_OVERVIEW_PATH = VALIDATION_COMMITTEE_CREATE_PATH + "/overview";

export const TASKS_PATH = APP_PATH + "/tasks";
export const TASKS_OVERVIEW_PATH = TASKS_PATH + "/overview";

export const PROCESSES_PATH = APP_PATH + "/processes";
export const PROCESSES_OVERVIEW_PATH = PROCESSES_PATH + "/overview";

export const FOLDERS_PATH = APP_PATH + "/folders";
export const FOLDER_PATH = FOLDERS_PATH + "/:folderId";
export const FOLDER_SHARED_PATH = FOLDER_PATH + "([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})";
export const FOLDER_PERSONAL_PATH = FOLDER_PATH + "(personal)";
export const FOLDER_PUBLIC_PATH = FOLDER_PATH + "(public)";
export const FOLDERS_OVERVIEW_PATH = FOLDERS_PATH + "/overview";
export const FOLDER_OVERVIEW_PATH = FOLDER_PATH + "/overview";

export const FILES_PATH = APP_PATH + "/files";
export const FILE_PATH = FILES_PATH + "/:targetUrl";

export const ADMIN_PATH = APP_PATH + "/admin";
export const ADMIN_OVERVIEW_PATH = ADMIN_PATH + "/overview";

export const DEVELOPER_PATH = APP_PATH + "/developer";
export const DEVELOPER_OVERVIEW_PATH = DEVELOPER_PATH + "/overview";

export const PERSONAL_SECTION_PATH = APP_PATH + "/account";
export const PERSONAL_SECTION_OVERVIEW_PATH = PERSONAL_SECTION_PATH + "/overview";

export const LICENSE_OVERVIEW_PATH = APP_PATH + "/license";

export const TAB_ATTRIBUTES = "attributes";
export const TAB_ATTACHMENTS = "attachments";
export const TAB_DOCUMENTS = "documents";
export const TAB_VALIDATIONS = "validations";
export const TAB_WORKFLOWS = "workflows";
export const TAB_WORKFLOWS_AUTOMATION = "automation";
export const TAB_MODELS = "models";
export const TAB_PARTICIPANTS = "participants";
export const TAB_AUDIT_TRAILS = "audit_trails";
export const TAB_MY_TASKS = "my_tasks";
export const TAB_TASKS_TO_CLAIM = "tasks_to_claim";
export const TAB_TASK_FORM = "task_form";
export const TAB_TASK_AUDIT_TRAIL = "task_audit_trail";
export const TAB_WORKFLOW_DIAGRAM = "workflow_diagram";
export const TAB_ADMIN_REST = "admin_rest";
export const TAB_ADMIN_SQL = "admin_sql";
export const TAB_LINKS = "links";
export const TAB_WORKFLOW_TYPES = "workflow_types";

export const TAB_ADMIN_CONTAINERS = "admin_containers";
export const TAB_ADMIN_DOCKER_HOSTS = "admin_docker_hosts";
export const TAB_ADMIN_DROOLS = "TAB_ADMIN_DROOLS";
export const TAB_ADMIN_TOKENS = "TAB_ADMIN_TOKENS";
export const TAB_ADMIN_SSH_KEYS = "TAB_ADMIN_SSH_KEYS";
export const TAB_ADMIN_ROLES = "admin_roles";
export const TAB_SECURITY_LEVELS = "security_levels";

export const TAB_ODATA = "odata";
export const ODATA_TAB = {
    value: TAB_ODATA,
    authoritiesRequired: [],
    featuresRequired: [ODATA],
    dynamicPropertiesRequired: [
        {
            name: ODATA_ACCESS_CODES_ENABLED,
            value: "true"
        }
    ],
    label: "OData Access Link"
};

export const TAB_ACCOUNT_INFO = "account_info";
export const TAB_ACCOUNT_TOKEN = "account_token";
export const TAB_ACCOUNT_SSH_KEYS = "account_ssh_keys";
export const TAB_LICENSE_INFO = "license_info";
export const TAB_MONITORINBG_LINKS = "monitoring_links";
export const TAB_INPUT_OUTPUT = "input_output";
