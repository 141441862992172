import {
  MODEL_OVERVIEW_PATH,
  MODEL_REVISION_OVERVIEW_PATH,
  VALIDATION_COMMITTEE_OVERVIEW_PATH,
  VALIDATION_OVERVIEW_PATH,
  ID_REGEX
} from "../constants/Routes";
import {
  MODEL_TYPE,
  MODEL_REVISION_TYPE,
  VALIDATION_TYPE,
  VALIDATION_COMMITTEE_TYPE
} from "./Entity";

export function getLink(task) {
  if (!task || !task.owningEntity || !task.owningEntity.category) {
    return "/";
  }
  switch (task.owningEntity.category) {
    case MODEL_TYPE:
      return (
        MODEL_OVERVIEW_PATH.replace(
          ":entityId" + ID_REGEX,
          task.owningEntity.id
        ).replace(":entityType" + ID_REGEX, task.owningEntity.typeId) +
        "?tab=attributes"
      );
    case MODEL_REVISION_TYPE:
      return (
        MODEL_REVISION_OVERVIEW_PATH.replace(
          ":revisionId" + ID_REGEX,
          task.owningEntity.id
        )
          .replace(":entityId" + ID_REGEX, task.owningEntity.parentId)
          .replace(":entityType" + ID_REGEX, task.owningEntity.typeId) +
        "?tab=attributes"
      );
    case VALIDATION_TYPE:
      return (
        VALIDATION_OVERVIEW_PATH.replace(
          ":entityId" + ID_REGEX,
          task.owningEntity.id
        ).replace(":entityType" + ID_REGEX, task.owningEntity.typeId) +
        "?tab=attributes"
      );
    case VALIDATION_COMMITTEE_TYPE:
      return (
        VALIDATION_COMMITTEE_OVERVIEW_PATH.replace(
          ":entityId" + ID_REGEX,
          task.owningEntity.id
        ).replace(":entityType" + ID_REGEX, task.owningEntity.typeId) +
        "?tab=attributes"
      );
    default:
      return "/";
  }
}
export function getTitleLink(task) {
  if (!task || !task.owningEntity || !task.owningEntity.category) {
    return "/";
  }
  switch (task.owningEntity.category) {
    case MODEL_TYPE:
      return "Go to the linked model";
    case MODEL_REVISION_TYPE:
      return "Go to the linked model revision";
    case VALIDATION_TYPE:
      return "Go to the linked validation";
    case VALIDATION_COMMITTEE_TYPE:
      return "Go to the linked validation committee";
    default:
      return "Go to the linked entity";
  }
}
