import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  getEntityLabel, getEntityType,
  MODEL_MONITORING_TYPE,
  MODEL_REVISION_TYPE,
  VALIDATION_TYPE,
} from 'api/Entity';
import { Field } from 'redux-form';
import { RequiredArrayValidator, RequiredValidator } from 'components/Form/Fields/FieldValidators';
import EntityInputField from 'components/Form/Fields/EntityInputField';

const LinkEntitySelection = ({ sourceEntityId, sourceEntityType, definition, classes }) => {

  const getButtonAssignSentence = (selectedEntities) => {
    if (!selectedEntities || selectedEntities.length === 0) {
      return null;
    }
    const isPlural = selectedEntities.length > 1;
    const buttonLabel = getEntityLabel(getEntityType(selectedEntities[0]), isPlural);

    return isPlural
      ? `Link these ${selectedEntities.length} ${buttonLabel}`
      : `Link this ${buttonLabel}`;
  };

  let entityToSearchType;
  let findBy;
  if (sourceEntityType !== definition.sourceEntity) {
    entityToSearchType = definition.sourceEntity;
    findBy = "TARGET";
  } else {
    entityToSearchType = definition.targetEntity;
    findBy = "SOURCE"
  }
  const label = `Link ${getEntityLabel(entityToSearchType)}`
  const dialogTitle = `Link ${getEntityLabel(entityToSearchType)}`;
  const searchUrl = `/api/entity-link-definitions/${definition.id}/search-target-entities/${sourceEntityId}?find-by=${findBy}`;

  return (
    <div className={classes.container}>
      <Field
        label={label}
        entityType={entityToSearchType}
        name="otherEntities"
        dialogTitle={dialogTitle}
        getButtonSentence={getButtonAssignSentence}
        authoritiesRequired={[]}
        selectable
        component={EntityInputField}
        validate={[RequiredValidator, RequiredArrayValidator]}
        url={searchUrl}
      />
    </div>
  );
}

const SUPPORTED_ENTITY_TYPES = [MODEL_REVISION_TYPE, MODEL_MONITORING_TYPE, VALIDATION_TYPE];

LinkEntitySelection.propTypes = {
  sourceEntityId: PropTypes.string.isRequired,
  sourceEntityType: PropTypes.oneOf(SUPPORTED_ENTITY_TYPES).isRequired,
  definition: PropTypes.shape({
    id: PropTypes.string.isRequired,
    sourceEntity: PropTypes.oneOf(SUPPORTED_ENTITY_TYPES).isRequired,
    targetEntity: PropTypes.oneOf(SUPPORTED_ENTITY_TYPES).isRequired
  })
}

const styles = () => ({
  container: {
    margin: "10px 0",
    width: "300px"
  }
});

export default withStyles(styles)(LinkEntitySelection);