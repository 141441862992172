import {Grid, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import moment from "moment";
import APPCONFIG from "constants/Config";

const TaskInfoLastUpdate = ({lastUpdateTime, classes}) => {
  const [lastUpdate, setLastUpdate] = useState(null);

  useEffect(()=>{
    if(lastUpdateTime){
      setLastUpdate(moment(lastUpdateTime).format(APPCONFIG.dateTimeFormat));
    }
  },[lastUpdateTime]);

  if (lastUpdate) {
    return <>
      <Grid item xs={3} lg={2} className={classes.captionRow}>
        <Typography variant="caption" className={classes.caption}>
          Last update:
        </Typography>
      </Grid>
      <Grid item xs={9} lg={10} className={classes.row}>
        <Typography variant="body1" >
          {lastUpdate}
        </Typography>
      </Grid>
      </>
  }
  return null;

};
export default TaskInfoLastUpdate;