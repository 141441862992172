import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { getColorsScale } from "../../constants/Colors";

const getTitles = (labels) => {
    let labelArray = [];
    if (!labels) {
        return labelArray;
    }

    const placement = 100.0 / (labels.length);
    for (let i = 0; i < labels.length; i++) {
        if (i === 0) {
            continue;
        }
        
        const label = labels[i];
        labelArray.push(
            {
                subtext: label,
                left: (placement * i) + "%",
                top: '90%',
                textAlign: 'center',
                subtextStyle: {
                    color: '#000'
                }
            }
        );
    }
    return labelArray;
};

const getSeries = (labels) => {
    let seriesArray = [];
    if (!labels) {
        return seriesArray;
    }

    const placement = 100.0 / (labels.length);
    let commonColumn;

    for (let i = 0; i < labels.length; i++) {
        const label = labels[i];
        if (i === 0) {
            commonColumn = label;
        } else {
            seriesArray.push(
                {
                    name: label,
                    type: 'pie',
                    radius: '55%',
                    center: [(placement * i) + "%", '50%'],
                    encode: {
                        itemName: commonColumn,
                        value: label
                    }
                }
            );
        }
    }
    return seriesArray;
};

const MultiPieChartWidget = ({ widget }) => {
    if (!widget || !widget.results || !widget.results[0]) {
        return null;
    }
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        const { results } = widget;
        const parsedWidgetData = results[0];
        setData(parsedWidgetData.data);
        setLabels(parsedWidgetData.labels);
    }, [widget]);

    return (
        <ReactEcharts
            className="react_for_echarts"
            option={{
                title: getTitles(labels),
                dataset: {
                    source: data
                },
                tooltip: {
                    trigger: "item",
                    formatter: function (params) {
                        return params.name + " : " + params.value[params.seriesIndex + 1] + " (" + params.percent + " %)";
                    }
                },
                color: getColorsScale(data.length - 1),
                legend: {
                    orient: "vertical",
                    right: "20",
                    bottom: "20"
                },
                series: getSeries(labels)
            }}
        />
    );
};

export default MultiPieChartWidget;
