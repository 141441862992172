import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TasksPanel from "components/Tasks/TasksPanel/TasksPanel";
import ButtonLoading from "components/Buttons/ButtonLoading";
import Tabs from "components/TabContainer/Tabs";
import DrawerView from "components/Drawer";
import DrawerTabs from "components/Drawer/DrawerTabs";
import DrawerContent from "components/Drawer/DrawerContent";
import { getTasks, getClaimableTasks } from "actions/Tasks";
import { TAB_MY_TASKS, TAB_TASKS_TO_CLAIM } from "constants/Routes";
import APPCONFIG from "constants/Config";
import { Grid } from "@material-ui/core";
import TaskFilterBar from "components/Tasks/TaskFilterBar";

class Tasks extends Component {
  state = {
        activeTab: TAB_MY_TASKS,
        taskStatusToFilter: [],
        pages: {}
  };
  refreshTimeout = null;

  constructor(props) {
    super(props);
    this.state.pages[TAB_MY_TASKS] = 0;
    this.state.pages[TAB_TASKS_TO_CLAIM] = 0;
  }

  componentWillUpdate(nextProps, nextState) {
    const { activeTab } = nextState;
    if (this.state.activeTab !== activeTab) {
      this.refreshTab(nextProps, nextState);
    }
  }
  componentDidMount() {
    this.refreshTab(this.props, this.state);
  }
  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  refreshTab = (props, state) => {
    const { getTasks, getClaimableTasks } = props;
    const { activeTab, pages, taskStatusToFilter } = state;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTab(this.props, this.state);
    }, APPCONFIG.REFRESH_TIMEOUT);

    switch (activeTab) {
      case TAB_MY_TASKS:
        getTasks(0, (pages[TAB_MY_TASKS] + 1) * APPCONFIG.LIST_SIZE, taskStatusToFilter);
        break;
      case TAB_TASKS_TO_CLAIM:
        getClaimableTasks(
          0,
          (pages[TAB_TASKS_TO_CLAIM] + 1) * APPCONFIG.LIST_SIZE
        );
        break;
    }
  };

  onChangeFilterParams = searchParams => {
    this.setState(searchParams, this.refresh);
  };

  handleChange = activeTab => {
    this.setState({ activeTab, taskStatusToFilter: [] });
  };

  refresh = () => {
    const { getTasks, getClaimableTasks } = this.props;
    const { activeTab, pages, taskStatusToFilter } = this.state;

    switch (activeTab) {
      case TAB_MY_TASKS:
        getTasks(0, (pages[TAB_MY_TASKS] + 1) * APPCONFIG.LIST_SIZE, taskStatusToFilter);
        break;
      case TAB_TASKS_TO_CLAIM:
        getClaimableTasks(0, (pages[TAB_TASKS_TO_CLAIM] + 1) * APPCONFIG.LIST_SIZE);
        break;
    }
  };

  handleScroll = e => {
    const { getTasks, getClaimableTasks, tasks, claimableTasks } = this.props;
    const { activeTab, pages } = this.state;
    let selectedPage = pages[activeTab],
      selectedTasks,
      action;

    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
    if (bottom) {
      switch (activeTab) {
        case TAB_MY_TASKS:
          selectedTasks = tasks;
          action = getTasks;
          break;
        case TAB_TASKS_TO_CLAIM:
          selectedTasks = claimableTasks;
          action = getClaimableTasks;
          break;
      }
      const page = Math.floor(selectedTasks.length / APPCONFIG.LIST_SIZE);
      if (page === selectedPage) {
        return;
      }
      pages[activeTab] = page;
      this.setState({
        pages: pages
      });
      action(page, APPCONFIG.LIST_SIZE);
    }
  };

  render() {
    const {
      tasks,
      claimableTasks,
      claimable_loading,
      my_tasks_loading
    } = this.props;

    const {
        activeTab,
        taskStatusToFilter,
    } = this.state;

    let loading = false,
      selectedTasks;

    switch (activeTab) {
      case TAB_MY_TASKS:
        loading = my_tasks_loading;
        selectedTasks = tasks;
        break;
      case TAB_TASKS_TO_CLAIM:
        loading = claimable_loading;
        selectedTasks = claimableTasks;
        break;
    }

    return (
      <DrawerView>
        <DrawerTabs>
          <Tabs tabs={TABS} value={activeTab} onChange={this.handleChange} />
        </DrawerTabs>
        <DrawerContent onScroll={this.handleScroll}>
          <div className={"scrollable-container"}>
            <Grid container
                  className={"scrollable-header"}
                  justify={activeTab === TAB_MY_TASKS ? "space-between" : "flex-end"}
                  alignItems="center"
                  style={{ padding: "10px" }}
            >

              {activeTab === TAB_MY_TASKS
                ? <Grid item xs>
                    <TaskFilterBar onChange={this.onChangeFilterParams} filterParams={{ taskStatusToFilter }} />
                  </Grid>
                : null
              }
                <Grid item xs>
                  <ButtonLoading
                      style={{float:"right"}}
                      loading={loading}
                      loadingTitle="Refreshing the list"
                      variant="outlined"
                      color="primary"
                      onClick={this.refresh}
                  >
                    Refresh the list
                  </ButtonLoading>
                </Grid>
            </Grid>
            <div className={"scrollable-content"}>
              <TasksPanel tasks={selectedTasks} showEntity withWorkflowContext />
            </div>
          </div>
        </DrawerContent>
      </DrawerView>
    );
  }
}

const TABS = [
  {
    value: TAB_MY_TASKS,
    label: "My tasks"
  },
  {
    value: TAB_TASKS_TO_CLAIM,
    label: "Tasks to claim"
  }
];

const mapStateToProps = (state, ownProps) => {
  return {
    tasks: state.tasks.mine,
    claimableTasks: state.tasks.claimable,
    loading: state.tasks.claimable_loading || state.tasks.loading,
    claimable_loading: state.tasks.claimable_loading,
    my_tasks_loading: state.tasks.loading
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { getTasks, getClaimableTasks }
  )(Tasks)
);
