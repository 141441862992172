import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/Buttons/Button";
import moment from "moment";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import Config from "constants/Config";

const styles = () => ({
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  statusCaption: {
    display: "flex",
    marginRight: 10,
    width: 110,
    justifyContent: "flex-start",
    textAlign: "left"
  },
  statusValue: {
    display: "flex"
  },
  stackTrace: {
    border: "solid 1px #ddd",
    borderRadius: 5,
    padding: "10px 20px",
    background: "#fafafa"
  }
});

const ErrorItem = ({
  activityName,
  errorMessage,
  errorDate,
  acknowledged,
  acknowledgedBy,
  acknowledgedAt,
  onClick
}) => {
  return (
    <ListItem testid="workflowerroritem" button onClick={onClick}>
      <ListItemText
        primary={errorMessage}
        secondary={
          <React.Fragment>
            <div>{`${activityName} - ${moment(errorDate).format(Config.dateTimeFormat)}`}</div>
            {acknowledged &&
              `Acknowledged ${moment(
                acknowledgedAt
              ).format(Config.dateTimeFormat)} by ${acknowledgedBy}`}
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction>
        <Button variant="outlined" color="primary" onClick={onClick}>
          Show detail
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
const ErrorDetail = withStyles(styles)(({ error, onClose, classes }) => {
  if (!error) {
    return null;
  }
  console.log(error);

  const {
    activityName,
    errorDate,
    acknowledged,
    acknowledgedBy,
    acknowledgedAt,
    containerId,
    processInstanceId,
    processId,
    activityId,
    jobId
  } = error;
  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
        {activityName}
      </CloseableDialogTitle>
      <DialogContent>
        <div className={classes.infoContainer}>
          <Typography variant="caption" className={classes.statusCaption}>
            Activity name:
          </Typography>
          <Typography variant="body1" className={classes.statusValue}>
            {activityName}
          </Typography>
        </div>
        <div className={classes.infoContainer}>
          <Typography variant="caption" className={classes.statusCaption}>
            Date:
          </Typography>
          <Typography variant="body1" className={classes.statusValue}>
            {moment(errorDate).format(Config.dateTimeFormat)}
          </Typography>
        </div>
        <div className={classes.infoContainer}>
          <Typography variant="caption" className={classes.statusCaption}>
            Activity ID:
          </Typography>
          <Typography variant="body1" className={classes.statusValue}>
            {activityId}
          </Typography>
        </div>
        <div className={classes.infoContainer}>
          <Typography variant="caption" className={classes.statusCaption}>
            Container ID:
          </Typography>
          <Typography variant="body1" className={classes.statusValue}>
            {containerId}
          </Typography>
        </div>
        <div className={classes.infoContainer}>
          <Typography variant="caption" className={classes.statusCaption}>
            Process instance ID:
          </Typography>
          <Typography variant="body1" className={classes.statusValue}>
            {processInstanceId}
          </Typography>
        </div>
        <div className={classes.infoContainer}>
          <Typography variant="caption" className={classes.statusCaption}>
            Process ID:
          </Typography>
          <Typography variant="body1" className={classes.statusValue}>
            {processId}
          </Typography>
        </div>
        <div className={classes.infoContainer}>
          <Typography variant="caption" className={classes.statusCaption}>
            Job ID:
          </Typography>
          <Typography variant="body1" className={classes.statusValue}>
            {jobId}
          </Typography>
        </div>
        {acknowledged && (
          <>
            <div className={classes.infoContainer}>
              <Typography variant="caption" className={classes.statusCaption}>
                Acknoledged by:
              </Typography>
              <Typography variant="body1" className={classes.statusValue}>
                {acknowledgedBy}
              </Typography>
            </div>
            <div className={classes.infoContainer}>
              <Typography variant="caption" className={classes.statusCaption}>
                Acknoledged at:
              </Typography>
              <Typography variant="body1" className={classes.statusValue}>
                {moment(acknowledgedAt).format(Config.dateTimeFormat)}
              </Typography>
            </div>
          </>
        )}
        <Typography variant="caption" className={classes.statusCaption}>
          Stacktrace:
        </Typography>
        <DialogContentText
          color="error"
          variant="body1"
          id="alert-dialog-description"
          className={classes.stackTrace}
        >
          {error.error}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
});

const ErrorsList = ({ workflow }) => {
  const [selectedError, setSelectedError] = useState(undefined);
  const { executionErrors } = workflow;
  console.log(executionErrors);
  return (
    <>
      {executionErrors && (
        <List component="nav" style={{ width: "100%" }}>
          {executionErrors.map((error, idx) => (
            <ErrorItem
              {...error}
              onClick={() => setSelectedError(error)}
              key={idx}
            />
          ))}
        </List>
      )}
      <ErrorDetail
        error={selectedError}
        onClose={() => setSelectedError(undefined)}
      />
    </>
  );
};

export default ErrorsList;
