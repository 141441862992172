import React from 'react';

import { CircularProgress, Menu } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import IconButton from 'components/Buttons/IconButton';
import MenuItem from 'components/Buttons/MenuItem';
import DataLabLinkGenerator from 'components/Attachments/File/DataLabLinkGenerator';
import {useDispatch} from "react-redux";
import {copyToClipboard} from "api/Links";

const ITEM_HEIGHT = 48;

const FileMenu = ({
  isModifying,
  isSelected,
  isHistory,
  anchorEl,
  dropping,
  href,
  file,
  latestLink,
  actions,
}) => {

  const {
    onSelect,
    onShowHistory,
    onRename,
    onEdit,
    onDelete,
    onOpenDirectory,
    onClose,
    onClick,
    onDownload,
  } = actions;

  const dispatch = useDispatch();

  const handleOpenDirectory = (event) => {
    onOpenDirectory(event);
  };

  const handleClose = (event) => {
    onClose(event);
  };

  const handleOpen = (event) => {
    onClick(event);
  }

  const handleSelect = (event) => {
    handleClose(event);
    onSelect(event);
  }

  const handleShowHistory = (event) => {
    handleClose(event);
    onShowHistory(file);
  }

  const handleRename = (event) => {
    handleClose(event);
    onRename();
  }

  const handleEdit = (event) => {
    handleClose(event);
    onEdit();
  }

  const handleDelete = (event) => {
    handleClose(event);
    onDelete()
  }

  const handleCopyLink = (event, href) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(copyToClipboard(href))
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        aria-label="More"
        color={dropping ? 'secondary' : 'primary'}
        aria-owns={open ? 'long-menu' : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        {isModifying ? (
          <CircularProgress size={20}/>
        ) : (
          <MoreVert/>
        )}
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
            width: 200,
          },
        }}
      >
        {file.isFolder ? (
          <MenuItem onClick={handleOpenDirectory}>
            Open
          </MenuItem>
        ) : href ? (
          <MenuItem onClick={onDownload}>
            Download
          </MenuItem>
        ) : null}
        {onSelect ? (
          <MenuItem onClick={handleSelect}>
            {isSelected ? 'Unselect' : 'Select'}
          </MenuItem>
        ) : null}
        {onShowHistory ? (
          <MenuItem onClick={handleShowHistory}>
            Show history
          </MenuItem>
        ) : null}
        {onRename ? (
          <MenuItem onClick={handleRename}>
            Rename
          </MenuItem>
        ) : null}
        {onEdit ? (
          <MenuItem onClick={handleEdit}>
            Edit
          </MenuItem>
        ) : null}
        {onDelete ? (
          <MenuItem onClick={handleDelete}>
            Delete
          </MenuItem>
        ) : null}
        {href ? (
          <MenuItem onClick={(event) => {
            handleClose(event);
            handleCopyLink(event, href)
          }}>
            Copy link
          </MenuItem>
        ) : null}
        {!latestLink && !isHistory && file.entity !== "VALIDATION_COMMITTEE" ? (
          <DataLabLinkGenerator file={file} onClose={handleClose}/>
        ) : null}
        {latestLink ? (
          <MenuItem onClick={(event) => {
            handleClose(event);
            handleCopyLink(event, latestLink)
          }}>
            Copy latest version link
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};

export default FileMenu;
