import { CREATE_WORKFLOW } from "../constants/ActionTypes";

export default function(entity, action) {
  if (!entity) {
    return entity;
  }

  let ownerId = action.ownerId;
  const newWorkflow = action.workflow;
  const newTask = action.task;

  if (!ownerId) {
    if (
      newWorkflow &&
      newWorkflow.owningEntity &&
      newWorkflow.owningEntity.id
    ) {
      ownerId = newWorkflow.owningEntity.id;
    }
    if (entity && newTask) {
      ownerId = newTask.owningEntity.id;
    }
  }
  if (entity.id !== ownerId) {
    return entity;
  }

  switch (action.type) {
    case CREATE_WORKFLOW:
      let workflows = {};
      if (entity && entity.workflows) {
        workflows = entity.workflows;
      }
      if (!workflows[newWorkflow.workflowType.classification]) {
        workflows[newWorkflow.workflowType.classification] = [];
      }
      workflows[newWorkflow.workflowType.classification].push(newWorkflow);
      return {
        ...entity,
        workflows: workflows
      };
  }
  return entity;
}
