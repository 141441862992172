import React, { Component } from "react";
import Attribute from "./attribute";

import { Grid } from "@material-ui/core";

class AttributesGrid extends Component {
  render() {
    const {
      editable,
      editMode,
      attributes,
      grid: { id, size }
    } = this.props;

    if (!attributes || attributes.length === 0) {
      return <Grid item xs={size} />;
    }

    return (
      <Grid item xs={size}>
        {attributes
          .sort(
            (a, b) =>
              a.positionInGroup - b.positionInGroup ||
              a.label.toLowerCase() > b.label.toLowerCase()
          )
          .map((attribute, idx) => {
            return (
              <Attribute
                key={idx}
                section={id}
                attribute={attribute}
                editMode={editMode}
                editable={editable}
              />
            );
          })}
      </Grid>
    );
  }
}

export default AttributesGrid;
