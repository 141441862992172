import React from "react";
import { Link } from "react-router-dom";
import Button from "../Buttons/Button";
import {
    FOLDERS_OVERVIEW_PATH,
    MODEL_MONITORING_OVERVIEW_PATH,
    MODELS_OVERVIEW_PATH,
    PROCESSES_OVERVIEW_PATH,
    TASKS_OVERVIEW_PATH,
    VALIDATION_COMMITTEES_OVERVIEW_PATH,
    VALIDATIONS_OVERVIEW_PATH
} from "../../constants/Routes";
import {
    ROLE_MODEL_VIEW,
    ROLE_MODEL_MONITORING_VIEW,
    ROLE_VALIDATION_VIEW,
    ROLE_VALIDATIONCOMMITTEE_VIEW
} from "../../constants/Authorities";
import {
    AUTOMATION_WORKFLOWS,
    DATAHUB_PERSONAL,
    DATAHUB_PUBLIC,
    DATAHUB_SHARED_FOLDER,
    MODEL_MONITORING
} from "../../constants/Features";

const STYLE_MENU_BUTTON = { height: "40px", padding: "0 15px" };

class NavLeftList extends React.Component {
  render() {
    return (
      <ul className="list-unstyled list-inline">
        <li className="list-inline-item">
          <Button
            testid="modelsLink"
            component={Link}
            authoritiesRequired={[ROLE_MODEL_VIEW]}
            to={MODELS_OVERVIEW_PATH}
            color="primary"
            style={STYLE_MENU_BUTTON}
          >
            Models
          </Button>
        </li>
        <li className="list-inline-item">
          <Button
            testid="validationsLink"
            component={Link}
            color="primary"
            authoritiesRequired={[ROLE_VALIDATION_VIEW]}
            to={VALIDATIONS_OVERVIEW_PATH}
            style={STYLE_MENU_BUTTON}
          >
            Validations
          </Button>
        </li>
        <li className="list-inline-item">
          <Button
            testid="validationCommitteesLink"
            component={Link}
            color="primary"
            authoritiesRequired={[ROLE_VALIDATIONCOMMITTEE_VIEW]}
            to={VALIDATION_COMMITTEES_OVERVIEW_PATH}
            style={STYLE_MENU_BUTTON}
          >
            Validation committees
          </Button>
        </li>
        <li className="list-inline-item">
          <Button
            testid="tasksLink"
            component={Link}
            to={TASKS_OVERVIEW_PATH + "?tab=my_tasks"}
            color="primary"
            style={STYLE_MENU_BUTTON}
          >
            Tasks
          </Button>
        </li>
        <li className="list-inline-item">
          <Button
            testid="foldersLink"
            atLeastOneFeature={true}
            featuresRequired={[
              DATAHUB_PERSONAL,
              DATAHUB_PUBLIC,
              DATAHUB_SHARED_FOLDER
            ]}
            component={Link}
            to={FOLDERS_OVERVIEW_PATH}
            color="primary"
            style={STYLE_MENU_BUTTON}
          >
            Data hub
          </Button>
        </li>
        <li className="list-inline-item">
          <Button
            testid="processesLink"
            atLeastOneFeature={true}
            featuresRequired={[AUTOMATION_WORKFLOWS]}
            component={Link}
            to={PROCESSES_OVERVIEW_PATH}
            color="primary"
            style={STYLE_MENU_BUTTON}
          >
            Automation
          </Button>
        </li>
          <li className="list-inline-item">
              <Button
                  testid="monitoringLink"
                  atLeastOneFeature={true}
                  authoritiesRequired={[ROLE_MODEL_MONITORING_VIEW]}
                  featuresRequired={[MODEL_MONITORING]}
                  component={Link}
                  to={MODEL_MONITORING_OVERVIEW_PATH}
                  color="primary"
                  style={STYLE_MENU_BUTTON}
              >
                  Model monitoring
              </Button>
          </li>
      </ul>
    );
  }
}

export default NavLeftList;
