import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ButtonLoading from "../../../../Buttons/ButtonLoading";
import { Field, reduxForm } from "redux-form";
import { RequiredValidator } from "../../../../Form/Fields/FieldValidators";
import AutoSuggestSelectField from "../../../../Form/Fields/AutoSuggestSelectField";
import SelectField from "../../../../Form/Fields/SelectField";
import {
  MenuItem,
  DialogContent,
  Dialog,
  CircularProgress,
  DialogActions
} from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../../../../api/Error";

class AddParticipantDialogForm extends Component {
  componentWillReceiveProps(nextProps) {
    const { creating, error_participation, handleClose } = nextProps;
    if (this.props.creating && !creating && !error_participation) {
      handleClose();
    }
  }

  render() {
    const {
      handleSubmit,
      handleClose,
      open,
      roles,
      creating,
      error_participation,
      loadingRoles,
      errorRoles
    } = this.props;

    let rolesDiv;

    if (roles) {
      rolesDiv = (
        <Field
          label={"Role"}
          name="role"
          component={SelectField}
          validate={[RequiredValidator]}
          ref="role"
        >
          {roles.map(function(role, idx) {
            return (
              <MenuItem key={idx} value={role}>
                {role}
              </MenuItem>
            );
          })}
        </Field>
      );
    } else if (loadingRoles) {
      rolesDiv = (
        <div
          style={{
            lineHeight: "36px",
            textAlign: "center",
            margin: "20px auto 0"
          }}
        >
          <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
          <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
            Loading roles
          </span>
        </div>
      );
    } else if (errorRoles) {
      rolesDiv = <div className="error">{errorRoles}</div>;
    } else {
      rolesDiv = <div className="error">No roles found</div>;
    }

    return (
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleSubmit}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleClose}
          >
            Add a participant
          </CloseableDialogTitle>
          <DialogContent>
            <div>
              <Field
                label={"User"}
                name="userId"
                component={AutoSuggestSelectField}
                validate={[RequiredValidator]}
                ref="userId"
                apiUrl="/api/users/lookup"
              />
              {rolesDiv}
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonLoading
              testid="confirmAddParticipant"
              loading={creating}
              loadingTitle="Adding the participant"
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {"Add the participant"}
            </ButtonLoading>
            <div className="error">{error_participation}</div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    validationCommittee: state.committees.current,
    creating: state.committees.adding_participation,
    error_participation: state.committees.error_participation,
    roles: state.committees.roles,
    loadingRoles: state.committees.loading_roles,
    errorRoles: state.committees.error_roles
  };
};
export default withRouter(
  reduxForm({
    form: "addparticipantdialog",
    onSubmitFail: scrollToFirstError
  })(connect(mapStateToProps)(AddParticipantDialogForm))
);
