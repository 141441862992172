import React from "react";

import { DialogContent, Dialog, DialogActions } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { reduxForm } from "redux-form";

import { scrollToFirstError } from 'api/Error';
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import Button from "components/Buttons/Button";
import SecurityLevels from "components/SecurityLevels/input";

const UpdateSecurityLevelDialog = ({ open, isModifiable, handleSubmit, handleClose }) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <form onSubmit={handleSubmit}>
        <CloseableDialogTitle handleClose={handleClose}>
          Update security level
        </CloseableDialogTitle>
        <DialogContent>
          <SecurityLevels />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!isModifiable}
            className="color-primary"
            type="submit"
            variant="outlined"
            color="primary"
          >
            {"Update security level"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const UpdateSecurityLevelDialogForm = reduxForm({
  form: "updatesecurityleveldialog",
  enableReinitialize: true,
  destroyOnUnmount: false,
  shouldWarn: () => {
    return true;
  },
  onSubmitFail: scrollToFirstError
})(UpdateSecurityLevelDialog);

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(UpdateSecurityLevelDialogForm)
);
