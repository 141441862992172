import React, { useState } from "react";
import { Paper, TextField, Typography } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";
import DateField from "components/Form/Fields/DateField";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "components/Buttons/IconButton";
import {
  DateTimeValidator,
  RequiredValidator,
} from "components/Form/Fields/FieldValidators";
import moment from "moment";
import { scrollToFirstError } from "api/Error";

const useStyles = makeStyles(() => ({
  dateInput: {
    display: "inline-flex",
    alignItems: "center",
  },
}));

export default reduxForm({
  form: "addDateToSelectionForm",
  onSubmitFail: scrollToFirstError,
})(({ handleSubmit, clearError }) => {
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} className={classes.dateInput}>
      <Field
        name="dateInput"
        component={DateField}
        maxDate={moment()}
        withTimeFormatting
        onChange={clearError}
        validate={[RequiredValidator, DateTimeValidator]}
      />
      <IconButton
        className="color-primary"
        style={{ height: 32 }}
        type="submit"
        variant="outlined"
        color="primary"
        title="Add date"
      >
        <AddIcon />
      </IconButton>
    </form>
  );
});
