import React, { Component } from "react";
import { connect } from "react-redux";
import WorkflowTypesPanel from "components/Admin/WorkflowTypes/WorkflowTypesPanel";
import ContainersPanel from "components/Admin/Docker/ContainersPanel";
import DockerHostsPanel from "components/Admin/Docker/DockerHostsPanel";
import Tabs from "components/TabContainer/Tabs";
import DrawerView from "components/Drawer";
import DrawerTabs from "components/Drawer/DrawerTabs";
import DrawerContent from "components/Drawer/DrawerContent";
import {
  TAB_WORKFLOW_TYPES,
  TAB_ADMIN_CONTAINERS,
  TAB_ADMIN_DOCKER_HOSTS,
  TAB_ADMIN_ROLES,
  TAB_ADMIN_DROOLS,
  TAB_ADMIN_TOKENS,
  TAB_ADMIN_SSH_KEYS,
  ODATA_TAB,
  TAB_ODATA, TAB_SECURITY_LEVELS,
} from 'constants/Routes';
import { getWorkflowProcesses, getWorkflowTypes } from "actions/Workflow/types";
import { getContainers, getDockerHosts } from "actions/Containers";
import {
  ROLE_ODATAACCESSCODE_VIEW,
  ROLE_SYSTEM_ADMINISTRATION,
  ROLE_WORKFLOWTYPE_VIEW
} from 'constants/Authorities';
import APPCONFIG from "constants/Config";
import {
  ACCESS_TOKENS,
  BUSINESS_RULES,
  DATALAB
} from 'constants/Features';
import DroolsPanel from "components/Admin/Drools/DroolsPanel";
import { getDroolsRules } from "actions/Drools";
import TokensPanel from "components/Admin/Tokens/TokensPanel";
import { getTokens } from "actions/Tokens";
import Credentials from "components/Credentials";
import Divider from "@material-ui/core/Divider";
import ODataAccessCode from "components/OData/ODataAccessCode";
import SecurityLevelsPanel from 'components/Admin/Users/SecurityLevels/SecurityLevelsPanel';
import RolesPanel from "components/Admin/Users/Roles/RolesPanel";
import {isFeaturesAuthorized} from "api/Authorities";

class AdminOverview extends Component {
  refreshTimeout = null;

  constructor(props) {
    super(props);
    const { features } = props;
    const tabs = TABS();
    this.state = {
      tabs,
      value: isFeaturesAuthorized(features, [DATALAB])? TAB_ADMIN_CONTAINERS: TAB_ADMIN_ROLES
    };
  }

  componentWillUpdate(nextProps, nextState) {
    const { value } = nextState;
    if (this.state.value !== value) {
      this.refreshTab(nextProps, nextState);
    }
  }
  componentDidMount() {
    this.refreshTab(this.props, this.state);
  }
  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  refreshTab = (props, state) => {
    const {
      getWorkflowTypes,
      getWorkflowProcesses,
      getContainers,
      getDockerHosts,
      getDroolsRules,
      getTokens
    } = props;
    const { value } = state;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTab(this.props, this.state);
    }, APPCONFIG.REFRESH_TIMEOUT);

    switch (value) {
      case TAB_WORKFLOW_TYPES:
        getWorkflowTypes();
        getWorkflowProcesses();
        break;
      case TAB_ADMIN_CONTAINERS:
        getContainers();
        break;
      case TAB_ADMIN_DOCKER_HOSTS:
        getDockerHosts();
        break;
      case TAB_ADMIN_DROOLS:
        getDroolsRules();
        break;
      case TAB_ADMIN_TOKENS:
        getTokens();
        break;
    }
  };

  handleChange = value => {
    this.setState({ value });
  };

  render() {
    const { value, tabs } = this.state;
    let currentTab;

    switch (value) {
      case TAB_WORKFLOW_TYPES:
        currentTab = <WorkflowTypesPanel />;
        break;
      case TAB_ADMIN_CONTAINERS:
        currentTab = <ContainersPanel />;
        break;
      case TAB_ADMIN_DOCKER_HOSTS:
        currentTab = <DockerHostsPanel />;
        break;
      case TAB_ADMIN_ROLES:
        currentTab = <RolesPanel />;
        break;
      case TAB_SECURITY_LEVELS:
        currentTab = <SecurityLevelsPanel />;
        break;
      case TAB_ADMIN_DROOLS:
        currentTab = <DroolsPanel />;
        break;
      case TAB_ADMIN_TOKENS:
        currentTab = <TokensPanel />;
        break;
      case TAB_ADMIN_SSH_KEYS:
        currentTab = <Credentials admin />;
        break;
      case TAB_ODATA:
        currentTab = <ODataAccessCode isAdmin />;
        break;
    }

    return (
      <DrawerView>
        <DrawerTabs>
          <Tabs tabs={tabs} value={value} onChange={this.handleChange} />
        </DrawerTabs>
        <DrawerContent>{currentTab}</DrawerContent>
      </DrawerView>
    );
  }
}

const TABS = () => [
  {
    headerTitle: "Datalab",
    featuresRequired: [DATALAB],
    footer: <Divider />,
    tabs: DATA_LAB_TABS
  },
  {
    headerTitle: "User management",
    footer: <Divider />,
    tabs: USER_MANAGEMENT_TABS
  },
  {
    headerTitle: "Integration",
    footer: <Divider />,
    tabs: INTEGRATION_TABS
  },
  {
    headerTitle: "Reporting",
    footer: <Divider />,
    tabs: REPORTING_TABS
  },
  {
    headerTitle: "Other",
    footer: <Divider />,
    tabs: OTHER_TABS()
  }
];

const OTHER_TABS = () => [
  {
    value: TAB_WORKFLOW_TYPES,
    authoritiesRequired: [ROLE_WORKFLOWTYPE_VIEW],
    label: "Workflow types"
  },
  {
    value: TAB_ADMIN_DROOLS,
    authoritiesRequired: [ROLE_SYSTEM_ADMINISTRATION],
    featuresRequired: [BUSINESS_RULES],
    label: "Business rules"
  }
];

const DATA_LAB_TABS = [
  {
    value: TAB_ADMIN_CONTAINERS,
    authoritiesRequired: [ROLE_SYSTEM_ADMINISTRATION],
    featuresRequired: [DATALAB],
    label: "Containers"
  },
  {
    value: TAB_ADMIN_DOCKER_HOSTS,
    authoritiesRequired: [ROLE_SYSTEM_ADMINISTRATION],
    featuresRequired: [DATALAB],
    label: "Datalab hosts"
  }
];

const USER_MANAGEMENT_TABS = [
  {
    value: TAB_ADMIN_ROLES,
    authoritiesRequired: [ROLE_SYSTEM_ADMINISTRATION],
    label: "Roles"
  },
  {
    value: TAB_SECURITY_LEVELS,
    authoritiesRequired: [ROLE_SYSTEM_ADMINISTRATION],
    label: "Security levels"
  }
];

const INTEGRATION_TABS = [
  {
    value: TAB_ADMIN_TOKENS,
    authoritiesRequired: [ROLE_SYSTEM_ADMINISTRATION],
    featuresRequired: [ACCESS_TOKENS],
    label: "Tokens"
  },
  {
    value: TAB_ADMIN_SSH_KEYS,
    authoritiesRequired: [ROLE_SYSTEM_ADMINISTRATION],
    featuresRequired: [],
    label: "External System Credentials"
  }
];

const REPORTING_TABS = [
  { ...ODATA_TAB, authoritiesRequired: [ROLE_ODATAACCESSCODE_VIEW] }
];

const mapStateToProps = (state, ownProps) => {
  return {
    features: state.features.list
  };
};

export default connect(
  mapStateToProps,
  {
    getWorkflowTypes,
    getWorkflowProcesses,
    getContainers,
    getDockerHosts,
    getDroolsRules,
    getTokens
  }
)(AdminOverview);
