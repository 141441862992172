import {
  GET_TASKS,
  ADD_TASKS,
  LOADING_TASKS,
  END_LOADING_TASKS,
  TASKS_ERROR,
  GET_TASK,
  LOADING_TASK,
  END_LOADING_TASK,
  ERROR_LOADING_TASK,
  EXECUTING_ACTION,
  END_EXECUTING_ACTION,
  EXECUTING_ACTION_ERROR,
  LOADING_CLAIMABLE_TASKS,
  GET_CLAIMABLE_TASKS,
  ADD_CLAIMABLE_TASKS,
  END_LOADING_CLAIMABLE_TASKS,
  CLAIMABLE_TASKS_ERROR,
  SETTING_BUSINESS_OWNERS_TASK,
  END_SETTING_BUSINESS_OWNERS_TASK,
  ERROR_SETTING_BUSINESS_OWNERS_TASK,
  LOADING_TASK_AUDIT_TRAILS,
  END_LOADING_TASK_AUDIT_TRAILS,
  ERROR_LOADING_TASK_AUDIT_TRAILS,
  GET_TASK_AUDIT_TRAILS,
  OPEN_TASK,
  CLOSE_TASK,
  PUSH_NOTIFICATION
} from "constants/ActionTypes";
import axios from "axios";
import { getEntityApiParam } from "api/Entity";
import { getWorkflowRedux } from "../Workflow";
import { manageForm } from "api/Workflow";
import APPCONFIG from "constants/Config";
import {TASK_STATUS_COMPLETED} from "constants/Task";

export function refreshTasks() {
  return function(dispatch) {
    getTasks()(dispatch);
    getClaimableTasks()(dispatch);
  };
}
export function getTasks(page = 0, pageSize = APPCONFIG.LIST_SIZE, taskStatusFilter = []) {
  return function(dispatch) {
    dispatch({
      type: LOADING_TASKS
    });

    const statusRequestParam = taskStatusFilter && taskStatusFilter.length > 0
      ? '&status=' + taskStatusFilter.map(taskStatus => taskStatus.id).join(",")
      : '';

    axios
      .get(`/api/workflows/tasks?page=${page}&pageSize=${pageSize}${statusRequestParam}`, {
        withCredentials: true
      })
      .then(response => {
        let tasks = response.data;

        dispatch({
          type: page === 0 ? GET_TASKS : ADD_TASKS,
          tasks:
            tasks &&
            tasks.map(task => {
              return manageForm(task);
            })
        });
        dispatch({
          type: END_LOADING_TASKS
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: TASKS_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_TASKS
        });
      });
  };
}
export function getClaimableTasks(page = 0, pageSize = APPCONFIG.LIST_SIZE) {
  return function(dispatch) {
    dispatch({
      type: LOADING_CLAIMABLE_TASKS
    });

    axios
      .get(
        "/api/workflows/tasks/claimable?page=" + page + "&pageSize=" + pageSize,
        {
          withCredentials: true
        }
      )
      .then(response => {
        let tasks = response.data;

        dispatch({
          type: page === 0 ? GET_CLAIMABLE_TASKS : ADD_CLAIMABLE_TASKS,
          tasks:
            tasks &&
            tasks.map(task => {
              return manageForm(task);
            })
        });
        dispatch({
          type: END_LOADING_CLAIMABLE_TASKS
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: CLAIMABLE_TASKS_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_CLAIMABLE_TASKS
        });
      });
  };
}
export function getDeepTask(task) {
  return function(dispatch) {
    const taskId = task.taskId;
    const ownerType = task.owningEntity && task.owningEntity.category;
    const url = ownerType ? "/api/" + getEntityApiParam(ownerType) : "/api";
    dispatch({
      type: LOADING_TASK,
      taskId: taskId
    });
    axios
      .get(
        url + "/workflows/tasks/" + taskId,
        {
          withCredentials: true
        }
      )
      .then(response => {
        let taskResponse = response.data;
        dispatch({
          type: GET_TASK,
          taskId: taskId,
          task: manageForm(taskResponse)
        });
        dispatch({
          type: END_LOADING_TASK,
          taskId: taskId
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: ERROR_LOADING_TASK,
          taskId: taskId,
          error: message
        });
        dispatch({
          type: END_LOADING_TASK,
          taskId: taskId
        });
      });
  };
}

export function executeAction(task, values, action, username, targerId) {
  return function(dispatch) {
    const workflowId = task.workflowId;
    const ownerId = task.owningEntity && task.owningEntity.id;
    const ownerType = task.owningEntity && task.owningEntity.category;
    const taskId = task.taskId;
    const url = ownerType ? "/api/" + getEntityApiParam(ownerType) : "/api";

    dispatch({
      type: EXECUTING_ACTION,
      taskId: taskId,
      workflowId: workflowId,
      ownerId: ownerId,
      action: action
    });

    axios
      .post(
        `${url}/workflows/${workflowId}/tasks/${taskId}/actions`,
        {
          action: action,
          params: values,
          targetId: targerId
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        let taskResponse = response.data;
        dispatch({
          type: GET_TASK,
          taskId: taskId,
          task: manageForm(taskResponse),
          me: username
        });
        if (taskResponse.status === TASK_STATUS_COMPLETED) {
          getWorkflowRedux(workflowId, task.owningEntity)(dispatch);
        }
        if(!taskResponse || taskResponse === {} || taskResponse.status === TASK_STATUS_COMPLETED){
          dispatch({
            type: CLOSE_TASK
          });
        }
        dispatch({
          type: END_EXECUTING_ACTION,
          workflowId: workflowId,
          ownerId: ownerId,
          taskId: taskId
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: action + " action succeeded"
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: EXECUTING_ACTION_ERROR,
          workflowId: workflowId,
          ownerId: ownerId,
          taskId: taskId,
          error: message
        });
        dispatch({
          type: END_EXECUTING_ACTION,
          workflowId: workflowId,
          ownerId: ownerId,
          taskId: taskId
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: action.name + " transition execution failed" + message
        });
      });
  };
}
export function setBusinessOwners(task, administrators) {
  return function(dispatch) {
    const taskId = task.taskId;
    const ownerType = task.owningEntity && task.owningEntity.category;
    const url = ownerType ? "/api/" + getEntityApiParam(ownerType) : "/api";
    dispatch({
      type: SETTING_BUSINESS_OWNERS_TASK,
      taskId: taskId
    });

    axios
      .patch(
        `${url}/workflows/tasks/${taskId}/admins`,
        administrators &&
          administrators.map(administrator => {
            return administrator.id;
          }),
        {
          withCredentials: true
        }
      )
      .then(response => {
        let taskResponse = response.data;
        dispatch({
          type: GET_TASK,
          taskId: taskId,
          task: manageForm(taskResponse)
        });
        dispatch({
          type: END_SETTING_BUSINESS_OWNERS_TASK,
          taskId: taskId
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: ERROR_SETTING_BUSINESS_OWNERS_TASK,
          taskId: taskId,
          error: message
        });
        dispatch({
          type: END_SETTING_BUSINESS_OWNERS_TASK,
          taskId: taskId
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Setting business owners failed" + message
        });
      });
  };
}
export function getAuditTrails(task, firstResult, maxResultCount) {
  return function(dispatch) {
    const taskId = task.taskId;
    const workflowId = task.workflowId;
    const ownerId = task.owningEntity && task.owningEntity.id;
    const ownerType = task.owningEntity && task.owningEntity.category;
    const url = ownerType ? "/api/" + getEntityApiParam(ownerType) : "/api";

    dispatch({
      type: LOADING_TASK_AUDIT_TRAILS,
      workflowId: workflowId,
      ownerId: ownerId,
      taskId: taskId
    });

    axios
      .get(
        url +
          "/workflows/tasks/" +
          taskId +
          "/logs?firstResult=" +
          firstResult +
          "&maxResultCount=" +
          maxResultCount,
        {
          withCredentials: true
        }
      )
      .then(response => {
        let auditTrails = response.data && response.data[0];
        dispatch({
          type: GET_TASK_AUDIT_TRAILS,
          workflowId: workflowId,
          ownerId: ownerId,
          taskId: taskId,
          auditTrails: auditTrails
        });
        dispatch({
          type: END_LOADING_TASK_AUDIT_TRAILS,
          workflowId: workflowId,
          ownerId: ownerId,
          taskId: taskId
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: ERROR_LOADING_TASK_AUDIT_TRAILS,
          workflowId: workflowId,
          ownerId: ownerId,
          taskId: taskId,
          error: message
        });
        dispatch({
          type: END_LOADING_TASK_AUDIT_TRAILS,
          workflowId: workflowId,
          ownerId: ownerId,
          taskId: taskId
        });
      });
  };
}

export function openTask(task) {
  return function(dispatch) {
    dispatch({
      type: OPEN_TASK,
      task: task
    });
  };
}
export function closeTask() {
  return function(dispatch) {
    dispatch({
      type: CLOSE_TASK
    });
  };
}
