import axios from 'axios';

import {
  END_UPDATING_SECURITY_LEVEL,
  ERROR_UPDATING_SECURITY_LEVEL,
  PUSH_NOTIFICATION,
  UPDATE_SECURITY_LEVEL,
  UPDATING_SECURITY_LEVEL,
} from 'constants/ActionTypes';
import { getEntityGetAttachmentsParam, getEntityType } from 'api/Entity';
import { API_SECURITY_LEVEL } from 'constants/ApiEndpoints';


export function updateSecurityLevel(entity, securityLevel) {
  return function (dispatch) {
    const ownerId = entity.id;
    const data = securityLevel ? [{ id: securityLevel.id }] : [];
    dispatch({
      type: UPDATING_SECURITY_LEVEL,
      ownerId,
    });

    axios
      .patch(
        `/api/${getEntityGetAttachmentsParam(getEntityType(entity))}/${
          entity.id
        }/security-levels`,
        data,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Accept-Charset': 'utf-8',
          },
        },
      )
      .then(response => {
        dispatch({
          type: UPDATE_SECURITY_LEVEL,
          securityLevel,
          ownerId,
        });
        dispatch({
          type: END_UPDATING_SECURITY_LEVEL,
          ownerId,
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: 'cloud_upload',
          variant: 'success',
          message: 'Security level updated',
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_UPDATING_SECURITY_LEVEL,
          ownerId,
          error: message,
        });
        dispatch({
          type: END_UPDATING_SECURITY_LEVEL,
          ownerId,
        });
      });
  };
}

export function findAll() {
  return axios.get(API_SECURITY_LEVEL);
}

export function createSecurityLevel(securityLevel) {
  return axios.post(API_SECURITY_LEVEL, securityLevel);
}

export function updateSecurityLevelDetail(securityLevel) {
  return axios.put(`${API_SECURITY_LEVEL}/${securityLevel.id}`, securityLevel);
}
