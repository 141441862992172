import { FILTER_TYPE } from "constants/Filters";

export default function getDefaultSearchFilters() {
  return {
    typeIds: [],
    securityLevelIds: [],
    modelUsageTypeIds: [],
    attributeValueFilter: {
      matchAllPredicates: true,
      searchType: "CONJUNCTION",
      predicates: [],
    },
    workflowVariableValueFilter: {
      matchAllPredicates: true,
      searchType: "CONJUNCTION",
      predicates: [],
    },
    filterType: FILTER_TYPE.DEFAULT,
  };
}
