import React, { Component } from "react";
import Button from "../Buttons/Button";
import { DialogTitle, DialogActions, Dialog } from "@material-ui/core";

class ConfirmRemoveSavedFiltersDialogForm extends Component {
  render() {
    const { handleCancel, handleOk, open, name } = this.props;
    return (
      <Dialog
        onClose={handleCancel}
        open={open}
        testid="deleteSavedFiltersDialog"
      >
        <DialogTitle id="simple-dialog-title">
          Search {name} will be removed. Are you sure?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary" testid="ok">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default ConfirmRemoveSavedFiltersDialogForm;
