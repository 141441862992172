import React, { Component } from "react";
import { reduxForm } from "redux-form";
import FilterModelsForm from "./FilterModelsForm";
import { scrollToFirstError } from "../../api/Error";

class EditFilterModelsForm extends Component {
  render() {
    const {
      handleSubmit,
      onSaveSearch,
      onCloseEditing,
      initialValues,
      form,
      disabled,
      entityType
    } = this.props;
    initialValues.attributeValueFilter.searchType = initialValues
      .attributeValueFilter.matchAllPredicates
      ? "CONJUNCTION"
      : "DISJUNCTION";
    initialValues.workflowVariableValueFilter.searchType = initialValues
      .workflowVariableValueFilter.matchAllPredicates
      ? "CONJUNCTION"
      : "DISJUNCTION";
    return (
      <FilterModelsForm
        entityType={entityType}
        disabled={disabled}
        formName={form}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        onSaveSearch={onSaveSearch}
        onCloseEditing={onCloseEditing}
      />
    );
  }
}
export default reduxForm({
  form: "filtermodelsedition",
  enableReinitialize: true,
  onSubmitFail: scrollToFirstError
})(EditFilterModelsForm);
