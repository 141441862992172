import React from 'react';

import PropTypes from 'prop-types';

import SecurityLevelsList from 'components/Admin/Users/SecurityLevels/SecurityLevelsList';
import TabCentered from 'components/TabContainer/TabCentered';
import LoadingBar from 'components/Admin/Users/Common/LoadingBar';

const SecurityLevelsListContainer = ({
  isLoading,
  isError,
  errorObject,
  securityLevels,
  onUpdate,
}) => {

  if (isLoading) {
    return (
      <TabCentered style={{ flexDirection: 'row' }}>
        <LoadingBar message='Loading security levels'/>
      </TabCentered>
    );
  }

  if (isError) {
    return (
      <TabCentered>
        <div className="emptyMessage">Failed to fetch security levels: {errorObject}</div>
      </TabCentered>
    );
  }

  if (!isLoading && !isError && !securityLevels) {
    return (
      <TabCentered>
        <div className="emptyMessage">No security levels found</div>
      </TabCentered>
    );
  }

  return (
    <SecurityLevelsList
      securityLevels={securityLevels}
      onUpdate={onUpdate}
    />
  );
};

SecurityLevelsListContainer.propTypes = ({
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorObject: PropTypes.object,
  securityLevels: PropTypes.array,
  onUpdate: PropTypes.func.isRequired,
});

export default SecurityLevelsListContainer;
