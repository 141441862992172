import React, {Component} from "react";
import {Field, FieldArray} from "redux-form";
import SelectField from "components/Form/Fields/SelectField";
import {RequiredValidator} from "components/Form/Fields/FieldValidators";
import {MenuItem} from "@material-ui/core";
import AttributesFilter from "components/Search/AttributesFilter";

const searchTypes = [
    {
        id: "CONJUNCTION",
        label: "Match all"
    },
    {
        id: "DISJUNCTION",
        label: "Match at least one"
    }
];

class AttributesFilterContainer extends Component {
    render() {
        const {
            name,
            initialValues,
            availableAttributes,
            formName,
            disabled,
            label
        } = this.props;
        return (
            <div style={{ width: "100%" }}>
                <div style={{ marginBottom: "10px", width: "200px" }}>
                    <Field
                        disabled={disabled}
                        label={""}
                        name={name + ".searchType"}
                        component={SelectField}
                        validate={[RequiredValidator]}
                    >
                        {searchTypes.map(function(searchType, idx) {
                            return (
                                <MenuItem key={idx} value={searchType.id}>
                                    {searchType.label}
                                </MenuItem>
                            );
                        })}
                    </Field>
                </div>
                <FieldArray
                    rerenderOnEveryChange
                    disabled={disabled}
                    availableAttributes={availableAttributes}
                    name={name + ".predicates"}
                    label={label}
                    formName={formName}
                    inputName={name}
                    component={AttributesFilter}
                    initialValues={initialValues[name].predicates}
                />
            </div>
        );
    }
}

AttributesFilterContainer.defaultProps = {
    label: "Attribute"
};

export default AttributesFilterContainer;