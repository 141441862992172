import {
  LOADING_TOKENS,
  END_LOADING_TOKENS,
  ERROR_TOKENS,
  GET_TOKENS,
  CREATING_TOKEN,
  END_CREATING_TOKEN,
  ERROR_CREATING_TOKEN,
  CREATE_TOKEN,
  REVOKING_TOKEN,
  END_REVOKING_TOKEN,
  ERROR_REVOKING_TOKEN,
  REVOKE_TOKEN
} from "../../constants/ActionTypes";
import axios from "axios/index";

export function getTokens() {
  return function(dispatch) {
    dispatch({
      type: LOADING_TOKENS
    });

    axios
      .get("/api/access-tokens", {
        withCredentials: true
      })
      .then(response => {
        let tokens = response.data;
        dispatch({
          type: GET_TOKENS,
          tokens: tokens
        });
        dispatch({
          type: END_LOADING_TOKENS
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_TOKENS,
          error: message
        });
        dispatch({
          type: END_LOADING_TOKENS
        });
      });
  };
}
export function createToken(name, expirationDate, userId) {
  return function(dispatch) {
    dispatch({
      type: CREATING_TOKEN
    });

    axios
      .post(
        "/api/access-tokens",
        {
          name: name,
          validTo: expirationDate,
          userId: userId
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        let token = response.data;
        dispatch({
          type: CREATE_TOKEN,
          token: token
        });
        dispatch({
          type: END_CREATING_TOKEN
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_CREATING_TOKEN,
          error: message
        });
        dispatch({
          type: END_CREATING_TOKEN
        });
      });
  };
}
export function revokeToken(token) {
  return function(dispatch) {
    dispatch({
      type: REVOKING_TOKEN,
      token: token
    });

    axios
      .delete("/api/access-tokens/" + token, {
        withCredentials: true
      })
      .then(response => {
        dispatch({
          type: REVOKE_TOKEN,
          token: token
        });
        dispatch({
          type: END_REVOKING_TOKEN,
          token: token
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_REVOKING_TOKEN,
          token: token,
          error: message
        });
        dispatch({
          type: END_REVOKING_TOKEN,
          token: token
        });
      });
  };
}
