import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { renderRoutes } from "react-router-config";
import NotificationStack from "../components/Notification/NotificationStack";
import {
  init as initAuthentication,
  getMe
} from "../actions/authentification";
import { BUILD_INFO } from 'constants/BuildInfo';

// = styles =
// 3rd
import "styles/bootstrap.scss";
// custom
import "styles/layout.scss";
import "styles/theme.scss";
import "styles/ui.scss";
import "styles/app.scss";

import darkTheme from "./themes/darkTheme";
import grayTheme from "./themes/grayTheme";
import { getFeatures } from "actions/Features";
import { BrowserRouter } from "react-router-dom";
import { getConfiguration } from "actions/Configuration";
import { withErrorBoundary } from 'routes';

function scrollToTop() {
  window.scrollTo(0, 0);
}

class App extends Component {
  state = {};
  constructor(props) {
    super(props);
    console.log("Build info:\n" + JSON.stringify(BUILD_INFO));

    props.initAuthentication(props.keycloak);
    props.getConfiguration();
  }

  componentWillReceiveProps(nextProps) {
    const { loadingConfiguration, me, features } = nextProps;
    const { routes } = this.state;
    if (
      !routes &&
      (!loadingConfiguration || me) &&
      (!loadingConfiguration || features)
    ) {
      this.getRoutes();
    }
  }

  getRoutes = () => {
    const { default: routes } = require("../routes");
    this.setState({
      routes: routes
    });
  };

  render() {
    const {
      layoutBoxed,
      navCollapsed,
      navBehind,
      fixedHeader,
      sidebarWidth,
      theme
    } = this.props;
    const { routes } = this.state;
    let materialUITheme, routesComponents;
    switch (theme) {
      case "gray":
        materialUITheme = grayTheme;
        break;
      case "dark":
        materialUITheme = darkTheme;
        break;
      default:
        materialUITheme = grayTheme;
    }
    if (routes) {
      routesComponents = (
        <BrowserRouter onUpdate={scrollToTop}>
          {renderRoutes(routes)}
        </BrowserRouter>
      );
    }

    return (
      <div id="app-inner">
        <div className="preloaderbar hide">
          <span className="bar" />
        </div>
        <div
          className={classnames("app-main full-height", {
            "fixed-header": fixedHeader,
            "nav-collapsed": navCollapsed,
            "nav-behind": navBehind,
            "layout-boxed": layoutBoxed,
            "theme-gray": theme === "gray",
            "theme-dark": theme === "dark",
            "sidebar-sm": sidebarWidth === "small",
            "sidebar-lg": sidebarWidth === "large"
          })}
        >
          {routesComponents}
        </div>
        <NotificationStack />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  me: state.auth.me,
  loadingConfiguration: state.configuration.loading,
  features: state.features.list,
  layoutBoxed: state.settings.layoutBoxed,
  navCollapsed: state.settings.navCollapsed,
  navBehind: state.settings.navBehind,
  fixedHeader: state.settings.fixedHeader,
  sidebarWidth: state.settings.sidebarWidth,
  theme: state.settings.theme
});

export default withErrorBoundary(
  connect(
    mapStateToProps,
    { initAuthentication, getMe, getFeatures, getConfiguration }
  )(App)
);
