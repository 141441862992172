import React, { Component } from "react";
import { connect } from "react-redux";
import TabCentered from "../TabContainer/TabCentered";
import { getAuditTrails } from "actions/AuditTrail";
import moment from "moment";
import ButtonLoading from "components/Buttons/ButtonLoading";
import AuditTrailsFilter from "components/AuditTrails/AuditTrailsFilter";

import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ListItemAvatar,
  Avatar,
  Tooltip,
  TablePagination
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { updateUserPreferences } from "actions/Configuration";
import APPCONFIG from "constants/Config";

class AuditTrails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchAttribute: undefined,
      searchUserName: undefined,
      page: 0,
      rowsPerPage: 10
    };
  }
  refreshTimeout = null;

  componentWillReceiveProps(nextProps) {
    const { entity, preferences } = nextProps;
    if (this.props.entity !== entity) {
      if (entity && this.props.entity && this.props.entity.id !== entity.id) {
        this.refreshTab(nextProps);
      }
    }
    if (
      preferences &&
      preferences.auditTrailRowsPerTable &&
      this.props.preferences &&
      this.props.preferences.auditTrailRowsPerTable &&
      preferences.auditTrailRowsPerTable !==
        this.props.preferences.auditTrailRowsPerTable
    ) {
      const { auditTrailRowsPerTable } = preferences;
      this.setState({
        rowsPerPage: auditTrailRowsPerTable
      });
    }
  }
  componentDidMount() {
    const { preferences } = this.props;
    this.refreshTab(this.props);
    if (preferences && preferences.auditTrailRowsPerTable) {
      const { auditTrailRowsPerTable } = preferences;
      this.setState({
        rowsPerPage: auditTrailRowsPerTable
      });
    }
  }
  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { rowsPerPage, page, searchAttribute, searchUserName } = this.state;
    if (
      rowsPerPage !== nextState.rowsPerPage ||
      page !== nextState.page ||
      searchAttribute !== nextState.searchAttribute ||
      searchUserName !== nextState.searchUserName
    ) {
      return this.refresh(nextProps, nextState);
    }
  }

  refreshTabTimer = () => {
    this.refreshTab(this.props);
  };

  refreshTab = props => {
    const { entity } = props;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTabTimer();
    }, 30000);

    if (!entity) {
      return;
    }
    this.refresh(props, this.state);
  };

  refresh = (props, state) => {
    const { entity, getAuditTrails } = props;
    const { rowsPerPage, page, searchAttribute, searchUserName } = state;
    getAuditTrails(
      entity,
      searchAttribute,
      searchUserName,
      page * rowsPerPage,
      rowsPerPage
    );
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    const {
      preferences,
      selectedAttributes,
      updateUserPreferences
    } = this.props;
    this.setState({ rowsPerPage: event.target.value, page: 0 });
    updateUserPreferences({
      ...preferences,
      selectedAttributes,
      auditTrailRowsPerTable: event.target.value
    });
  };

  onChangeFilterParams = filterParams => {
    this.setState(filterParams);
  };

  renderAuditTrail = (auditTrail, idx) => {
    const {
      label,
      subLabel,
      changedOn,
      changedByUser,
      attributeName,
      valueAsString
    } = auditTrail;
    const { classes } = this.props;

    return (
      <ListItem key={idx} alignItems="flex-start">
        <ListItemAvatar>
          <Tooltip title={changedByUser}>
            <Avatar color="primary" style={{ width: "36px", height: "36px" }}>
              {changedByUser ? changedByUser.substring(0, 1) : ""}
            </Avatar>
          </Tooltip>
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography variant="body1" className={classes.inline}>
                {changedByUser}
              </Typography>
              <Typography variant="caption" className={classes.inline}>
                {" changed "}
              </Typography>
              <Typography variant="body1" className={classes.inline}>
                {attributeName}
              </Typography>
              <Typography variant="caption" className={classes.inline}>
                {" to "}
              </Typography>
              <Typography variant="body1" className={classes.inline}>
                {valueAsString}
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>{moment(changedOn).format(APPCONFIG.dateTimeFormat)}</React.Fragment>
          }
        />
      </ListItem>
    );
  };

  render() {
    const { entity, classes } = this.props;
    const { page, rowsPerPage, searchAttribute, searchUserName } = this.state;
    const hasAuditTrails =
      entity &&
      entity.auditTrails &&
      entity.auditTrails.result &&
      entity.auditTrails.result.length > 0;
    let list;

    const button = (
      <ButtonLoading
        loading={entity.loading_audit_trails}
        loadingTitle="Refreshing"
        authoritiesRequired={[]}
        onClick={() => {
          this.refresh(this.props, this.state);
        }}
        variant="outlined"
        color="primary"
      >
        Refresh
      </ButtonLoading>
    );

    if (hasAuditTrails) {
      const pagination = (
        <TablePagination
          style={{ margin: "auto" }}
          className={"scrollable-footer"}
          component="div"
          count={entity.auditTrails.totalResultCount}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      );
      list = (
          <>
            <div className={"scrollable-content"}>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <List className={classes.root}>
                    {entity.auditTrails.result.map(this.renderAuditTrail)}
                  </List>
                </Grid>
              </Grid>
            </div>
            {pagination}
          </>
      );
    } else if (entity.loading_audit_trails) {
      list = (
        <TabCentered>
          <div style={{ lineHeight: "36px", textAlign: "center" }}>
            <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
              Loading the history
            </span>
          </div>
        </TabCentered>
      );
    } else {
      list = (
        <TabCentered>
          <div className="emptyMessage">No history available</div>
          {button}
        </TabCentered>
      );
    }

    return (
      <div className={"scrollable-container"}>
        <div className={"scrollable-header"}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: "10px" }}
            wrap="nowrap"
          >
            <AuditTrailsFilter
              onChange={this.onChangeFilterParams}
              searchParams={{
                searchAttribute: searchAttribute,
                searchUserName: searchUserName
              }}
            />
            <Grid item>{hasAuditTrails && button}</Grid>
          </Grid>
        </div>
        {list}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
  },
  inline: {
    display: "inline"
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    preferences: state.auth.preferences,
    selectedAttributes: state.attributes.attributesToShow
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      updateUserPreferences,
      getAuditTrails
    }
  )(AuditTrails)
);
