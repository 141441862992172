import {PUSH_NOTIFICATION} from "../constants/ActionTypes";

export const getApiError = (err) => {
  if (!err || !err.response || !err.response.data) {
    return '';
  }
  let message = err.response.data.message;
  if (!message) {
    message = err.response.statusText;
  }
  return message;
};

export const isConflict = (error) => {
  return error && error.response && error.response.status === 409; // Conflict HTTP status code
};

export const showValidationFailedMessage = (errors, dispatch) => {
  dispatch({
    type: PUSH_NOTIFICATION,
    icon: "error",
    variant: "error",
    message: "Correct all of the invalid fields before resubmitting the form"
  });
};

export const scrollToFirstError = (errors) => {
  const firstError = Object.keys(errors)[0];
  const el = document.getElementById(firstError);
  if (el) {
    el.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
      inline: 'nearest',
    });
  }
};
