import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from './FormControl';
import { change } from "redux-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import IconButton from "components/Buttons/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {useDispatch} from "react-redux";
import InputField from "components/Form/Fields/InputField";
import TabCentered from "components/TabContainer/TabCentered";
import {CircularProgress, TablePagination} from "@material-ui/core";
import {useApiRequest} from "actions/index";
import {searchGrantedAuthorities} from "actions/Roles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    overflow: 'auto',
    height: 350
  },
  title: {
    margin: theme.spacing(0, 0, 1),
  },
}));

const AssignListField = (props) => {
  const { input, label, loading, expanded } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [pageLeft, setPageLeft] = useState(0);
  const [rowsPerPageLeft, setRowsPerPageLeft] = useState(10);
  const [countLeft, setCountLeft] = useState(0);

  const [pageRight, setPageRight] = useState(0);
  const [rowsPerPageRight, setRowsPerPageRight] = useState(10);
  const [countRight, setCountRight] = useState(0);

  const [filteredLeft, setFilteredLeft] = useState([]);
  const [filteredRight, setFilteredRight] = useState([]);

  const [checkedLeft, setCheckedLeft] = useState([]);
  const [checkedRight, setCheckedRight] = useState([]);

  const [searchInputLeft, setSearchInputLeft] = useState(null);
  const [searchInputRight, setSearchInputRight] = useState(null);

  const [loadingSerach, errorSearching, searchAuthorities] = useApiRequest(
    searchGrantedAuthorities,
    (authoritiesResponse) => {
      if(authoritiesResponse && authoritiesResponse.content){
        setFilteredRight(authoritiesResponse.content.map(option=>option.id));
        setCountRight(authoritiesResponse.totalElements)
      }
    });

  useEffect(()=>{
    if(input && input.value) {
      if(searchInputLeft){
        const filteredList = input.value.filter((assigned => assigned && assigned.includes(searchInputLeft.toUpperCase())));
        setFilteredLeft(filteredList)
        setCountLeft(filteredList.length)
      } else {
        setFilteredLeft(input.value)
        setCountLeft(input.value.length)
      }
    }
  }, [input]);

  useEffect(()=>{
    if(expanded) {
      if (pageRight * rowsPerPageRight > countRight) {
        setPageRight(0);
        searchAuthorities(searchInputRight, 0, rowsPerPageRight)
      } else {
        searchAuthorities(searchInputRight, pageRight, rowsPerPageRight)
      }
    }
  }, [pageRight, expanded, rowsPerPageRight]);

  useEffect(()=>{
    if(pageLeft*rowsPerPageLeft > countLeft){
      setPageLeft(0);
    }
  }, [rowsPerPageLeft]);

  useEffect(()=>{
    setCountLeft(filteredLeft.length);
  }, [filteredLeft]);

  const handleToggleLeft = (checkedList, value) => {
    if (checkedList.includes(value)) {
      setCheckedLeft(checkedList.filter(assigned => assigned !== value));
    } else {
      setCheckedLeft([...checkedList, value]);
    }
  };

  const handleToggleRight = (checkedList, value) => {
    if (checkedList.includes(value)) {
      setCheckedRight(checkedList.filter(assigned => assigned !== value));
    } else {
      setCheckedRight([...checkedList, value]);
    }
  };

  const handleRemoveFromAssigned = (value) => {
    const newAssignedList = input && input.value && input.value.filter(assigned => assigned !== value);
    onChange(newAssignedList);
  }

  const handleAddToAssigned = (value, checkedList) => {
    if(input && input.value && !input.value.includes(value)){
      onChange([...input.value, value]);
      setCheckedRight(checkedList.filter(checked => checked !== value));
    }
  }

  const handleRightArrowClick = (checkedValues) => {
    const newAssignedList = input && input.value && input.value.filter(assigned => !checkedValues.includes(assigned));
    onChange(newAssignedList);
    setCheckedLeft([]);
  }

  const handleLeftArrowClick = (checkedValues) => {
    const newValues = input && input.value && checkedValues.filter(checked => !input.value.includes(checked));
    onChange([...input.value, ...newValues]);
    setCheckedRight([]);
  }

  const onChange = value => {
    const {
      input: { name },
      meta: { form },
      onChangeValue
    } = props;
    dispatch(change(form, name, value, true));
    onChangeValue && onChangeValue(value);
  };

  const isIncludedInAssigned = (option) => {
    return input.value.includes(option);
  }

  const onChangeSearchLeft = (e) => {
    const userInput = e.target && e.target.value && e.target.value.toUpperCase();
    const newListToShow = input && input.value && input.value.filter((assigned => assigned && assigned.toUpperCase().includes(userInput)));
    setFilteredLeft(newListToShow);
    setSearchInputLeft(e.target.value);
    setPageLeft(0)
  }

  const onChangeSearchRight = (e) => {
    const userInput = e.target && e.target.value && e.target.value.toUpperCase();
    searchAuthorities(userInput, pageRight, rowsPerPageRight);
    setSearchInputRight(e.target.value);
    setPageRight(0)
  }

  const renderLoading = (
    <TabCentered>
      <div style={{ lineHeight: "36px", textAlign: "center" }}>
        <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
        <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
        Loading
      </span>
      </div>
    </TabCentered>
  );

  const paginationLeft = (
    <TablePagination
      style={{ margin: "auto" }}
      component="div"
      count={countLeft}
      rowsPerPage={rowsPerPageLeft}
      page={pageLeft}
      backIconButtonProps={{
        "aria-label": "Previous Page"
      }}
      nextIconButtonProps={{
        "aria-label": "Next Page"
      }}
      onChangePage={(e, page) => setPageLeft(page)}
      onChangeRowsPerPage={(e) => setRowsPerPageLeft(e.target.value)}
    />
  );

  const paginationRight = (
    <TablePagination
      style={{ margin: "auto" }}
      component="div"
      count={countRight}
      rowsPerPage={rowsPerPageRight}
      page={pageRight}
      backIconButtonProps={{
        "aria-label": "Previous Page"
      }}
      nextIconButtonProps={{
        "aria-label": "Next Page"
      }}
      onChangePage={(e, page) => setPageRight(page)}
      onChangeRowsPerPage={(e) => setRowsPerPageRight(e.target.value)}
    />
  );

  return (
        <div className={classes.root}>
          <Grid container
                justify="space-evenly"
          >
            {/*Header*/}
            <Grid item xs={5}>
              <Typography variant="h6" className={classes.title}>
                Assigned {label}
              </Typography>
              <InputField
                placeholder="Filter assigned granted authorities"
                meta={{}}
                input={{
                  value: searchInputLeft,
                  name: 'assignedList',
                  onChange: onChangeSearchLeft
                }} />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Typography variant="h6" className={classes.title}>
                Available {label}
              </Typography>
              <InputField
                placeholder="Filter available granted authorities"
                meta={{}}
                input={{
                  value: searchInputRight,
                  name: 'availableList',
                  onChange: onChangeSearchRight
                }} />
            </Grid>

            {/*Body*/}
            <Grid item xs={5}>
              <div className={classes.list}>
                  <List dense>
                    {loading ?
                      renderLoading :
                      filteredLeft
                        .sort((a,b) => a.localeCompare(b))
                        .slice(pageLeft * rowsPerPageLeft, pageLeft * rowsPerPageLeft + rowsPerPageLeft)
                        .map( value => {
                      return <ListItem key={value}
                                       dense
                                       button
                                       onClick={() => handleToggleLeft(checkedLeft, value)}
                                       onDoubleClick={(e) => {
                                         e.stopPropagation()
                                         handleRemoveFromAssigned(value)
                                       }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedLeft.includes(value)}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={value}
                          primary={value}
                        />
                      </ListItem>
                    })}
                  </List>
              </div>
            </Grid>
            <Grid item xs={1}>
              <Grid container
                    direction="column"
                    alignItems="center"
                    justify="space-evenly"
                    style={{height: '100%'}}
              >
                <Grid item xs={6} style={{display: 'flex'}}>
                  <div style={{margin:'auto'}}>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      handleRightArrowClick(checkedLeft);
                    }}>
                      <KeyboardArrowRight />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item xs={6} style={{display: 'flex'}}>
                  <div style={{margin:'auto'}}>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      handleLeftArrowClick(checkedRight);
                    }}>
                      <KeyboardArrowLeft />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <div className={classes.list}>
                <List dense>
                  {loadingSerach?
                    renderLoading:
                    filteredRight
                      .sort((a,b) => a.localeCompare(b))
                      .map( option => {
                    return <ListItem key={option}
                                     dense
                                     disabled={isIncludedInAssigned(option)}
                                     button
                                     onClick={() => handleToggleRight(checkedRight, option)}
                                     onDoubleClick={(e) => {
                                       e.stopPropagation()
                                       handleAddToAssigned(option, checkedRight)
                                     }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checkedRight.includes(option)}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={option}
                        primary={option}
                      />
                    </ListItem>
                  })}
                </List>
              </div>
            </Grid>

            {/*Footer*/}
            <Grid item xs={5}>
              {paginationLeft}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              {paginationRight}
            </Grid>
          </Grid>
        </div>
  );
}

export default AssignListField;
