import React from "react";
import { connect } from "react-redux";
import IconButton from "components/Buttons/IconButton";
import TasksPanel from "components/Tasks/TasksPanel/TasksPanel";
import { openTask } from "actions/Tasks";
import { Typography, Tooltip, Menu } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Assignment } from "@material-ui/icons";
import { HeaderIconButtonStyle } from "components/Header/NavRightList";
import {TASK_STATUS_IN_PROGRESS} from "constants/Task";

class NavRightList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasksOpened: null,
      maxItems: 20
    };
  }

  handleClickMenuTasks = event => {
    this.setState({
      tasksOpened: event.currentTarget
    });
  };
  handleCloseMenuTasks = () => {
    this.setState({
      tasksOpened: null
    });
  };

  handleClose = () => {
    this.setState({ notificationOpened: null });
  };

  onScroll = e => {
    const list = e.target;
    if (list) {
      if (list.scrollTop + 100 >= list.scrollHeight - list.offsetHeight) {
        this.setState({
          maxItems: this.state.maxItems + 10
        });
      }
    }
  };
  listDidMount = list => {
    if (list) {
      list.addEventListener("scroll", this.onScroll);
    }
  };

  render() {
    const { tasks, classes } = this.props;
    const { tasksOpened } = this.state;
    const tasksFiltered =
      tasks &&
      tasks.filter(task => {
        return task && task.status === TASK_STATUS_IN_PROGRESS;
      });
    let tasksContainer;
    if (tasksFiltered && tasksFiltered.length > 0) {
      tasksContainer = (
        <React.Fragment>
          <Typography variant="subtitle2" style={{ padding: "0px 24px" }}>
            My tasks in progress
          </Typography>
          <TasksPanel tasks={tasksFiltered} withWorkflowContext/>
        </React.Fragment>
      );
    } else {
      tasksContainer = <div className={classes.empty}>No task in progress found</div>;
    }

    return (
      <li className="list-inline-item">
        <Tooltip title="My tasks in progress">
          <IconButton
            onClick={this.handleClickMenuTasks}
            style={HeaderIconButtonStyle}
            className="md-button header-btn"
          >
            <Assignment />
            {tasksFiltered && tasksFiltered.length > 0 && (
              <span className="badge">{tasksFiltered.length}</span>
            )}
          </IconButton>
        </Tooltip>
        <Menu
          onEntered={this.listDidMount}
          open={Boolean(tasksOpened)}
          onClose={this.handleCloseMenuTasks}
          anchorEl={tasksOpened}
        >
          {tasksContainer}
        </Menu>
      </li>
    );
  }
}

const styles = theme => ({
  empty: {
    padding: "10px 30px"
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    tasks: state.tasks.mine
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { openTask }
  )(NavRightList)
);
