import React, { useState } from "react";
import { connect } from "react-redux";
import { useApiRequest } from "actions/index";
import { pushNotification } from "actions/notifications";
import { runRuleForAllEntities } from "actions/Drools";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const RuleExecution = ({
    pushNotification,
    rule
}) => {
    const classes = useStyles();
    const [executingRule, errorExecutingRule, runExecuteRule] = useApiRequest(
        (ruleName) => runRuleForAllEntities(ruleName),
        () => {
            pushNotification(
                "check",
                "success",
                "Rule was executed sucessfully."
            );
        },
        (e, message) => {
            pushNotification(
                "error",
                "error",
                `Error during rule execution ${message ? `: ${message}` : ""}`
            );
        }
    );

    return (
        <div className={classes.root}>
            <Typography className={classes.description}>
                Execute the rule for all entities (Models, Revisions, Usages, Validations, Committees). If the rule isn't designed to work with entity, it's NOT advised nor safe to run this process. Correct usage can be for example batch recalculation of derived attributes.
            </Typography>
            <ButtonLoading
                loading={executingRule}
                loadingTitle="Reloading"
                style={{ margin: "5px 0" }}
                variant="outlined"
                color="primary"
                onClick={() => runExecuteRule(rule.name)}
            >
                Execute (All entities)
            </ButtonLoading>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        paddingLeft: 20
    },
    description: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        maxWidth: '700px',
        padding: '16px 0'
    }
}));

export default connect(
    () => ({}),
    { pushNotification }
)(RuleExecution);