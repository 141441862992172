import {
  GET_LICENSE,
  END_LOADING_LICENSE,
  ERROR_LOADING_LICENSE,
  LOADING_LICENSE,
  SET_LICENSE,
  END_SETTING_LICENSE,
  ERROR_SETTING_LICENSE,
  SETTING_LICENSE,
  SHOW_LICENSE_BANNER,
  HIDE_LICENSE_BANNER
} from "../../constants/ActionTypes";

function isLicenseOk(license) {
  return license && (!license.messages || license.messages.length === 0);
}

export default function(state = {}, action) {
  let showBanner;

  switch (action.type) {
    case GET_LICENSE:
      showBanner =
        state.showBanner === false ? false : !isLicenseOk(action.license);
      return { ...state, license: action.license, showBanner: showBanner };
    case LOADING_LICENSE:
      return { ...state, loading_license: true, error_license: undefined };
    case END_LOADING_LICENSE:
      return { ...state, loading_license: false };
    case ERROR_LOADING_LICENSE:
      return { ...state, error_license: action.error };

    case SET_LICENSE:
      return {
        ...state,
        license: action.license,
        license_set: true,
        showBanner: !isLicenseOk(action.license)
      };
    case SETTING_LICENSE:
      return {
        ...state,
        setting_license: true,
        license_set: undefined,
        error_setting_license: undefined
      };
    case END_SETTING_LICENSE:
      return { ...state, setting_license: false };
    case ERROR_SETTING_LICENSE:
      return {
        ...state,
        error_setting_license: action.error,
        license_set: false
      };

    case SHOW_LICENSE_BANNER:
      return { ...state, showBanner: true };
    case HIDE_LICENSE_BANNER:
      return { ...state, showBanner: false };

    default:
      return state;
  }
}
