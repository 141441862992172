import React, {Component} from "react";
import {connect} from "react-redux";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import {getAttributes} from "actions/Attributes";
import {getUsers} from "actions/Users";
import { Grid } from '@material-ui/core';

class AuditTrailsFilter extends Component {
    constructor(props) {
        super(props);
        this.props.getAttributes();
        this.props.getUsers();
    }

    onChangeAttribute = (searchAttribute) => {
        const { onChange, searchParams: { searchUserName } } = this.props;
        if (onChange) {
            onChange({
                searchAttribute,
                searchUserName,
            });
        }
    };
    onChangeUser = (searchUserName) => {
        const { onChange, searchParams: { searchAttribute } } = this.props;
        if (onChange) {
            onChange({
                searchAttribute,
                searchUserName
            });
        }
    };

    render() {
        const { attributes, users, searchParams: { searchAttribute, searchUserName } } = this.props;
        return (
            <Grid container spacing={1}>
                <Grid item>
                    <AutoSuggestSelectField
                        options={users && users.map( (user) => (
                            {
                                id: user.username,
                                label: user.label,
                            })
                        )}
                        placeholder="Filter by user"
                        meta={{}}
                        onChangeValue={this.onChangeUser}
                        input={{
                            value: searchUserName,
                            name: 'changedByUser'
                        }}/>
                </Grid>
                <Grid item>
                    <AutoSuggestSelectField
                        options={attributes && attributes.map( (attribute2Filter) => (
                            {
                                id: attribute2Filter.name,
                                label: attribute2Filter.label,
                            })
                        )}
                        placeholder="Filter by attribute name"
                        meta={{}}
                        onChangeValue={this.onChangeAttribute}
                        input={{
                            value: searchAttribute,
                            name: 'attributeName',
                        }} />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    attributes: state.attributes.list,
    users: state.users.list,
});

export default connect(
    mapStateToProps, { getAttributes, getUsers }
)(AuditTrailsFilter);
