import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../Buttons/ButtonLoading";
import { Field, reduxForm } from "redux-form";
import {
  RequiredValidator,
  NonEmptyStringValidator
} from "../Form/Fields/FieldValidators";
import InputField from "../Form/Fields/InputField";
import SelectField from "../Form/Fields/SelectField";
import MenuItem from "../Buttons/MenuItem";
import generatePassword from "../../api/GeneratePassword";
import { getDockerImages } from "actions/Containers/dockerImages";
import { DialogContent, Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";

const SIZES = [
  {
    label: "Small (5Go, 4Go, 1CPU)",
    value: "small"
  },
  {
    label: "Medium (10Go, 8Go, 2CPUs)",
    value: "medium"
  },
  {
    label: "Large (20Go, 16Go, 4CPUs)",
    value: "large"
  }
];

class CreateContainerForm extends Component {
  state = {
    created: false
  };

  componentWillReceiveProps(nextProps) {
    const { open, creating, datalabError } = nextProps;
    if (!open) {
      this.setState({
        created: false
      });
    }
    if (this.props.creating && !creating && !datalabError) {
      this.setState({
        created: true
      });
    }
  }

  copyPassword = () => {
    const { password } = this.props;
    navigator.clipboard.writeText(password).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  render() {
    const {
      handleSubmit,
      handleClose,
      creating,
      datalabError,
      classes,
      dockerImages,
      submitSucceeded,
      lastContainerCreated
    } = this.props;
    const { created } = this.state;

    let submit;
    if (created && !datalabError) {
      submit = (
        <div
          style={{
            margin: "15px 0 10px",
            display: "flex",
            flexDirection: "row"
          }}
        >
          <ButtonLoading
            onClick={this.copyPassword}
            variant="outlined"
            color="primary"
          >
            {"Copy password to clipboard"}
          </ButtonLoading>
          <ButtonLoading
            href={lastContainerCreated.containerServiceUrl}
            target="_blank"
            variant="outlined"
            style={{ marginLeft: 5 }}
            color="primary"
          >
            {"Go to the Datalab"}
          </ButtonLoading>
        </div>
      );
    } else {
      submit = (
        <ButtonLoading
          loading={creating}
          loadingTitle="Creating the Datalab"
          style={{ width: "400px", margin: "15px 0 10px" }}
          className="color-primary"
          type="submit"
          variant="outlined"
          color="primary"
        >
          {"Create a new Datalab"}
        </ButtonLoading>
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={handleClose}
        >
          Create a Datalab
        </CloseableDialogTitle>
        <DialogContent>
          <div className={classes.field}>
            <Field
              label={"Data tool"}
              name="imageAlias"
              component={SelectField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
              ref="imageAlias"
            >
              {dockerImages &&
                dockerImages.map(function(dockerImage, idx) {
                  return (
                    <MenuItem key={idx} value={dockerImage.alias}>
                      {dockerImage.label}
                    </MenuItem>
                  );
                })}
            </Field>
          </div>
          <div className={classes.field}>
            <Field
              label={"Username"}
              name="username"
              component={InputField}
              disabled
              validate={[RequiredValidator]}
              ref="username"
            />
          </div>
          <div className={classes.field}>
            <Field
              label={"Generated password"}
              name="password"
              component={InputField}
              disabled
              validate={[RequiredValidator]}
              ref="password"
            />
          </div>
          {/*<div className={classes.field}>
                            <Field
                                label={ "Size of the instance (disk space, memory, CPUs)" }
                                name="size"
                                component={SelectField}
                                validate={[RequiredValidator, NonEmptyStringValidator]}
                                ref="size">
                                {
                                    SIZES && SIZES.map(function (size, idx) {
                                        return (
                                            <MenuItem key={idx} value={size.value}>
                                                { size.label }
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Field>
                        </div>*/}
          {submit}
          {submitSucceeded ? <div className="error">{datalabError}</div> : null}
        </DialogContent>
      </form>
    );
  }
}

const styles = theme => ({
  field: {
    margin: "10px 0"
  }
});

const mapStateToPropsForm = (state, ownProps) => {
  return {
    creating: state.containers.opening_analytical_tool,
    datalabError: state.containers.error_opening_analytical_tool,
    password: state.containers.password,
    dockerImages: state.dockerImages.list,
    lastContainerCreated: state.containers.last_container_created,
    initialValues: {
      username: state.auth.me.username,
      password: generatePassword()
    }
  };
};

CreateContainerForm = reduxForm({
  form: "createcontainerform",
  onSubmitFail: scrollToFirstError
})(CreateContainerForm);

CreateContainerForm = withStyles(styles)(
  connect(
    mapStateToPropsForm,
    {}
  )(CreateContainerForm)
);

class CreateContainerDialog extends Component {
  componentDidMount() {
    const { getDockerImages } = this.props;
    getDockerImages();
  }

  render() {
    const { onSubmit, handleClose, open } = this.props;

    return (
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
        <CreateContainerForm onSubmit={onSubmit} handleClose={handleClose} />
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  { getDockerImages }
)(CreateContainerDialog);
