import {
  GET_OWN_FILTERS,
  LOADING_OWN_FILTERS,
  END_LOADING_OWN_FILTERS,
  ERROR_OWN_FILTERS,
  GET_SHARED_FILTERS,
  LOADING_SHARED_FILTERS,
  END_LOADING_SHARED_FILTERS,
  ERROR_SHARED_FILTERS,
  SAVE_FILTERS,
  SAVING_FILTERS,
  END_SAVING_FILTERS,
  ERROR_SAVING_FILTERS,
  UPDATING_FILTERS,
  UPDATE_FILTERS,
  END_UPDATING_FILTERS,
  ERROR_UPDATING_FILTERS,
  REMOVE_FILTERS,
  REMOVING_FILTERS,
  END_REMOVING_FILTERS,
  ERROR_REMOVING_FILTERS,
  RUNNING_SEARCH,
  RUNNING_SEARCH_END,
  SEARCH_RESULT,
  SEARCH_ERROR,
  LOAD_FILTERS_CONFIG,
  LOADING_FILTERS_CONFIG,
  LOADING_FILTERS_CONFIG_END,
  FILTERS_CONFIG_ERROR,
  PUSH_NOTIFICATION
} from "../../constants/ActionTypes";
import axios from "axios";
import { change, clearFields } from "redux-form";
import { clearAttributesSearch } from "../../api/Attributes";
import {
  MODEL_VIEW,
  TABLE_VIEW
} from "../../constants/ResultFormats";
import { getEntityGetAttachmentsParam } from "../../api/Entity";
import getDefaultSearchFilters from "../../api/Search";


export function getOwnSavedSearches() {
  return function(dispatch) {
    dispatch({
      type: LOADING_OWN_FILTERS
    });
    axios
      .get("/api/saved-searches/own", {
        withCredentials: true
      })
      .then(response => {
        let filters = response.data;
        dispatch({
          type: GET_OWN_FILTERS,
          filters: filters
        });
        dispatch({
          type: END_LOADING_OWN_FILTERS
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        dispatch({
          type: ERROR_OWN_FILTERS,
          error: message
        });
        dispatch({
          type: END_LOADING_OWN_FILTERS
        });
      });
  };
}

export function getSharedSavedSearches() {
  return function(dispatch) {
    dispatch({
      type: LOADING_SHARED_FILTERS
    });
    axios
      .get("/api/saved-searches/shared", {
        withCredentials: true
      })
      .then(response => {
        let filters = response.data;
        dispatch({
          type: GET_SHARED_FILTERS,
          filters: filters
        });
        dispatch({
          type: END_LOADING_SHARED_FILTERS
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        dispatch({
          type: ERROR_SHARED_FILTERS,
          error: message
        });
        dispatch({
          type: END_LOADING_SHARED_FILTERS
        });
      });
  };
}

export function saveFilters(filters) {
  return function(dispatch) {
    dispatch({
      type: SAVING_FILTERS
    });
    let predicates = [],
      matchAllPredicates = false,
      predicatesProcessVariables = [],
      matchAllPredicatesProcessVariables = false,
      typeIds = [],
      securityLevelIds = [],
      modelUsageTypeIds = [],
      modelActiveFlag;
    if (filters && filters.filters) {
      if (filters.filters.attributeValueFilter) {
        predicates = filters.filters.attributeValueFilter.predicates;
        matchAllPredicates =
          filters.filters.attributeValueFilter.searchType === "CONJUNCTION";
      }
      if (filters.filters.workflowVariableValueFilter) {
        predicatesProcessVariables =
          filters.filters.workflowVariableValueFilter.predicates;
        matchAllPredicatesProcessVariables =
          filters.filters.workflowVariableValueFilter.searchType ===
          "CONJUNCTION";
      }
      if (filters.filters.typeIds) {
        typeIds = filters.filters.typeIds;
      }
      if (filters.filters.securityLevelIds) {
        securityLevelIds = filters.filters.securityLevelIds.map((securityLevel) => securityLevel.id);
      }
      if (filters.filters.modelUsageTypeIds) {
        modelUsageTypeIds = filters.filters.modelUsageTypeIds;
      }
      modelActiveFlag = filters.filters.modelActiveFlag;
    }
    axios
      .post(
        "/api/saved-searches",
        {
          shared: filters.shared,
          label: filters.label,
          searchedEntity: filters.searchedEntity,
          searchDefinition: {
            typeIds: typeIds,
            securityLevelIds: securityLevelIds,
            modelUsageTypeIds: modelUsageTypeIds,
            attributeValueFilter: {
              matchAllPredicates: matchAllPredicates,
              predicates: predicates
            },
            workflowVariableValueFilter: {
              matchAllPredicates: matchAllPredicatesProcessVariables,
              predicates: predicatesProcessVariables
            },
            modelActiveFlag: modelActiveFlag
          }
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        let filters = response.data;
        dispatch({
          type: SAVE_FILTERS,
          filters: filters
        });
        dispatch({
          type: END_SAVING_FILTERS
        });

        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: filters.label + " saved"
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        dispatch({
          type: ERROR_SAVING_FILTERS,
          error: message
        });
        dispatch({
          type: END_SAVING_FILTERS
        });
      });
  };
}
export function editFilters(filters) {
  return function(dispatch) {
    const id = filters.id;
    dispatch({
      type: UPDATING_FILTERS,
      id
    });
    let predicates = [],
      matchAllPredicates = false,
      predicatesProcessVariables = [],
      matchAllPredicatesProcessVariables = false;
    if (filters && filters.filters && filters.filters.attributeValueFilter) {
      predicates = filters.filters.attributeValueFilter.predicates;
      matchAllPredicates =
        filters.filters.attributeValueFilter.searchType === "CONJUNCTION";
    }
    if (filters.filters.workflowVariableValueFilter) {
      predicatesProcessVariables =
        filters.filters.workflowVariableValueFilter.predicates;
      matchAllPredicatesProcessVariables =
        filters.filters.workflowVariableValueFilter.searchType ===
        "CONJUNCTION";
    }
    axios
      .put(
        "/api/saved-searches",
        {
          ...filters,
          filters: null,
          searchDefinition: {
            ...filters.filters,
            securityLevelIds: filters.filters.securityLevelIds.map(securityLevel => securityLevel.id),
            attributeValueFilter: {
              matchAllPredicates: matchAllPredicates,
              predicates: predicates
            },
            workflowVariableValueFilter: {
              matchAllPredicates: matchAllPredicatesProcessVariables,
              predicates: predicatesProcessVariables
            }
          }
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        let filters = response.data;
        dispatch({
          type: UPDATE_FILTERS,
          filters: filters,
          id
        });
        dispatch({
          type: END_UPDATING_FILTERS,
          id
        });

        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: filters.label + " saved"
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        dispatch({
          type: ERROR_UPDATING_FILTERS,
          error: message,
          id
        });
        dispatch({
          type: END_UPDATING_FILTERS,
          id
        });
      });
  };
}
export function removeFilters(filters) {
  return function(dispatch) {
    const id = filters.id;
    dispatch({
      type: REMOVING_FILTERS,
      id
    });
    axios
      .delete(`/api/saved-searches/${filters.id}`, {
        withCredentials: true
      })
      .then(response => {
        dispatch({
          type: REMOVE_FILTERS,
          id
        });

        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: filters.label + " removed"
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        dispatch({
          type: ERROR_REMOVING_FILTERS,
          error: message,
          id
        });
        dispatch({
          type: END_REMOVING_FILTERS,
          id
        });
        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Impossible to remove " + filters.label + message
        });
      });
  };
}

export function clearForm(formName) {
  return function(dispatch) {
    const defaultFilters = getDefaultSearchFilters();
    dispatch(change(formName, "attributeValueFilter", defaultFilters.attributeValueFilter));
    dispatch(change(formName, "workflowVariableValueFilter", defaultFilters.workflowVariableValueFilter));
    dispatch(change(formName, "typeIds", defaultFilters.typeIds));
    dispatch(change(formName, "securityLevelIds", defaultFilters.securityLevelIds));
    dispatch(change(formName, "modelUsageTypeIds", defaultFilters.modelUsageTypeIds));
    dispatch(change(formName, "modelActiveFlag", defaultFilters.modelActiveFlag));
  }
}

export function resetAttributeFilter(formName, attributeFilter, inputName) {
  return function(dispatch) {
    dispatch(
      clearFields(formName, false, false, [
        attributeFilter + ".name",
        attributeFilter + ".operator",
        attributeFilter + "." + inputName
      ])
    );
  };
}

export function cleanSearch(datas, parameters) {
  let tree = {};
  let results = datas.result.results;
  let attributes = datas.result.attributeIds;

  const firstResult = datas.firstResult;
  const maxResultCount = datas.maxResultCount;
  let numberModelAdded = 0,
    modelIdsSelected = [];

  for (let i = 0; i < results.length; i++) {
    const revision = results[i];
    results[i].model = clearAttributesSearch(revision.model);
    results[i] = clearAttributesSearch(results[i]);

    if (
      parameters.resultFormat === MODEL_VIEW &&
      revision.model &&
      revision.model.id
    ) {
      if (!tree[revision.model.id]) {
        tree[revision.model.id] = {
          model: revision.model,
          revisions: []
        };
        numberModelAdded++;
        if (
          numberModelAdded > firstResult &&
          numberModelAdded <= firstResult + maxResultCount
        ) {
          modelIdsSelected.push(revision.model.id);
        }
      }
      tree[revision.model.id].revisions.push(revision);
    }
  }
  let finalResults = {};

  let meta, totalResultCount, resultCount;

  if (parameters.resultFormat === MODEL_VIEW) {
    for (let i = 0; i < modelIdsSelected.length; i++) {
      const modelIdSelected = modelIdsSelected[i];
      finalResults[modelIdSelected] = tree[modelIdSelected];
    }

    totalResultCount = Object.keys(tree).length;
    resultCount = Object.keys(finalResults).length;
  } else {
    finalResults = results;
  }

  if (parameters.resultFormat === MODEL_VIEW) {
    meta = {
      totalResultCount: totalResultCount,
      resultCount: resultCount,
      resultFormat: parameters.resultFormat
    };
  } else {
    meta = {
      totalResultCount: datas.totalResultCount,
      resultCount: datas.resultCount,
      resultFormat: parameters.resultFormat
    };
  }
  return {
    meta: meta,
    results: finalResults,
    attributes: attributes
  };
}

export function runSearch(parameters, entityType, url) {
  // TODO: clean this whole function up once individual result formats for each view are implemented on backend
  return function(dispatch) {
    const source = axios.CancelToken.source();
    parameters.sourceAxios = source;
    dispatch({
      type: RUNNING_SEARCH,
      entityType: entityType,
      filters: parameters
    });
    let predicates = [],
      matchAllPredicates = false,
      predicatesProcessVariables = [],
      matchAllPredicatesProcessVariables = false,
      typeIds = [],
      securityLevelIds = [],
      modelUsageTypeIds = [],
      modelActiveFlag;
    if (parameters && parameters.filters) {
      if (parameters.filters.attributeValueFilter) {
        predicates = parameters.filters.attributeValueFilter.predicates;
        matchAllPredicates =
          parameters.filters.attributeValueFilter.searchType === "CONJUNCTION";
      }
      if (parameters.filters.workflowVariableValueFilter) {
        predicatesProcessVariables =
          parameters.filters.workflowVariableValueFilter.predicates;
        matchAllPredicatesProcessVariables =
          parameters.filters.workflowVariableValueFilter.searchType ===
          "CONJUNCTION";
      }
      if (parameters.filters.typeIds) {
        typeIds = parameters.filters.typeIds;
      }
      if (parameters.filters.securityLevelIds) {
        securityLevelIds = parameters.filters.securityLevelIds.map((securityLevel) => securityLevel.id);
      }
      if (parameters.filters.modelUsageTypeIds) {
        modelUsageTypeIds = parameters.filters.modelUsageTypeIds;
      }
      modelActiveFlag = parameters.filters.modelActiveFlag;
    }
    // TODO: clean this up once individual result formats for each view are implemented on backend
    let searchDefinition = {
      resultFormat: parameters.resultFormat,
      typeIds: typeIds,
      securityLevelIds: securityLevelIds,
      modelActiveFlag: modelActiveFlag,
      modelUsageTypeIds: modelUsageTypeIds,
      firstResult: parameters.page * parameters.rowsPerPage,
      maxResultCount: parameters.rowsPerPage,
      orderByAttribute: parameters.orderBy,
      orderAscending: parameters.order === "asc",
      attributeValueFilter: {
        predicates: predicates,
        matchAllPredicates: matchAllPredicates
      },
      workflowVariableValueFilter: {
        predicates: predicatesProcessVariables,
        matchAllPredicates: matchAllPredicatesProcessVariables
      }
    };
    if (parameters.resultFormat === MODEL_VIEW) {
      searchDefinition = {
        ...searchDefinition,
        firstResult: 0,
        maxResultCount: 1000
      };
    }

    // TODO: remove this and use resultFormat from parameters once individual result formats for each view are implemented on backend
    searchDefinition = {
      ...searchDefinition,
      resultFormat: TABLE_VIEW
    };
    if (!url) {
      url = `/api/${getEntityGetAttachmentsParam(entityType)}/search`;
    }

    axios
      .post(url, searchDefinition, {
        withCredentials: true,
        cancelToken: source.token
      })
      .then(response => {
        const datas = response.data;
        dispatch({
          ...cleanSearch(datas, parameters),
          type: SEARCH_RESULT,
          entityType: entityType
        });
        dispatch({
          type: RUNNING_SEARCH_END,
          entityType: entityType
        });
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          return;
        }
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: SEARCH_ERROR,
          error: message,
          entityType: entityType
        });
        dispatch({
          type: RUNNING_SEARCH_END,
          entityType: entityType
        });
      });
  };
}

export function loadFiltersConfig(entityType) {
  return function(dispatch) {
    dispatch({
      type: LOADING_FILTERS_CONFIG
    });
    axios
      .get(`/api/${getEntityGetAttachmentsParam(entityType)}/search`, {
        withCredentials: true
      })
      .then(response => {
        let config = response.data;
        dispatch({
          type: LOAD_FILTERS_CONFIG,
          config: config
        });
        dispatch({
          type: LOADING_FILTERS_CONFIG_END
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        dispatch({
          type: FILTERS_CONFIG_ERROR,
          error: message
        });
        dispatch({
          type: LOADING_FILTERS_CONFIG_END
        });
      });
  };
}
