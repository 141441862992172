import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../../../Buttons/Button";
import ValidationModelsComponent from "./List";
import {
  unassignModel,
  openAssignModels
} from "../../../../actions/Validations";
import { ROLE_VALIDATION_MANAGE_ASSIGNMENT } from "../../../../constants/Authorities";
import { withErrorBoundary } from "../../../../routes";
import { Grid } from "@material-ui/core";

class ValidationModels extends Component {
  state = {
    open: false
  };

  unassign = modelRevision => {
    const { unassignModel, entity } = this.props;
    unassignModel(entity.id, modelRevision.id);
  };

  assignModels = () => {
    this.props.openAssignModels();
  };

  render() {
    const { entity } = this.props;

    if (!entity) {
      return null;
    }
    const hasModels = entity.modelRevisions && entity.modelRevisions.length > 0;

    return (
      <React.Fragment>
        <Grid container justify="flex-end" style={{ padding: "10px" }}>
          {hasModels && (
            <Button
              testid="assignModel"
              authoritiesRequired={[ROLE_VALIDATION_MANAGE_ASSIGNMENT]}
              onClick={this.assignModels}
              variant="outlined"
              color="primary"
            >
              Assign a model
            </Button>
          )}
        </Grid>
        <ValidationModelsComponent
          onUnassign={this.unassign}
          validation={entity}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withErrorBoundary(
  connect(
    mapStateToProps,
    { unassignModel, openAssignModels }
  )(ValidationModels)
);
