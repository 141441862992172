import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import TaskStatus from "components/Tasks/TaskStatus";
import {useDispatch} from "react-redux";
import {openTask} from "actions/Tasks";
import React from "react";
import TaskListItemEntityLink from "components/Tasks/TasksPanel/TaskListItemEntityLink";

const TaskListItem = ({task, showEntity, withWorkflowContext}) => {
  const dispatch = useDispatch();

  const openTaskDialog = (task) => {
    openTask(task)(dispatch);
  };

  if (!task) {
    return null;
  }
  const taskName = task.name ? task.name: <em>Untitled</em>;

  let workflowText;
  if(withWorkflowContext){
    if (task.workflowInstanceName){
      if(task.workflowTypeLabel) {
        workflowText = task.workflowTypeLabel + " (" + task.workflowInstanceName+")"
      } else {
        workflowText = task.workflowInstanceName
      }
    } else {
      workflowText =
        <em style={{ color: "gray", display: 'inline-flex' }}>
          Untitled {task.workflowSummary}
        </em>
    }
  }

  return <ListItem
      alignItems="center"
      testid="taskitem"
      key={task.id}
      button
      onClick={() => openTaskDialog(task)}
    >
      {task.ownerLabel && (
        <Tooltip title={task.ownerLabel}>
          <ListItemAvatar>
            <Avatar
              color="primary"
              style={{ width: "36px", height: "36px" }}
            >
              {task.ownerLabel ? task.ownerLabel.substring(0, 1) : ""}
            </Avatar>
          </ListItemAvatar>
        </Tooltip>
      )}
      <ListItemIcon>
        <TaskStatus taskStatus={task && task.status}/>
      </ListItemIcon>
      <ListItemText
        style={{marginRight:"10px", flex: "0 0 auto"}}
        primary={taskName &&
        <Typography component={"body2"} style={{color: "gray", marginRight: "5px"}}>Task:</Typography>
        }
        secondary={workflowText && "Workflow:"}
      />
      <ListItemText
        style={{marginRight:"10px", overflowWrap: "break-word"}}
        primary={taskName}
        secondary={workflowText}
      />
      {showEntity && <TaskListItemEntityLink task={task} />}
    </ListItem>
};
export default TaskListItem;