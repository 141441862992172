import React, { Component } from "react";
import { connect } from "react-redux";
import RestForm from "./RESTForm";
import JSONTree from "react-json-view";
import { executeREST } from "../../actions/Admin";
import { Grid } from "@material-ui/core";

class RestPanel extends Component {
  handleSubmit = form => {
    const { executeREST } = this.props;
    executeREST(
      form.method,
      form.endpoint,
      { "content-type": form.contentType, accept: form.acceptType },
      form.body
    );
  };

  render() {
    const { data, responseContentType, error } = this.props;

    let responseView;

    //Exactly false, not undefined
    if (!data && error === false) {
      responseView = "Empty response was retrieved";
    }

    if (
      data &&
      responseContentType &&
      responseContentType.includes("application/json")
    ) {
      responseView = <JSONTree src={data} />;
    }

    if (
      data &&
      responseContentType &&
      responseContentType.includes("text/plain")
    ) {
      responseView = data;
    }

    if (error) {
      responseView = (
        <div className="error">
          {" "}
          {"Error occurred while performing the request"}{" "}
        </div>
      );
    }

    return (
      <Grid container justify="center" className={"scrollable-content"} style={{padding: "10px"}}>
        <Grid item xs={11}>
          <RestForm
            onSubmit={this.handleSubmit}
            initialValues={{
              method: "GET",
              contentType: "application/json",
              acceptType: "application/json"
            }}
          />
          <div style={{ margin: "20px 0", fontFamily: "Roboto" }}>
            {responseView}
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.admin.data_rest,
    responseContentType: state.admin.responseContentType,
    error: state.admin.error_rest
  };
};
export default connect(
  mapStateToProps,
  { executeREST }
)(RestPanel);
