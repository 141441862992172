import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';

import { createStatusLabel } from 'api/Uploads';
import IconButton from 'components/Buttons/IconButton';
import Status from 'components/Uploads/Statuses/Status';

const IconButtonStyle = {
  width: '30px',
  height: '30px',
  color: '#607d8b',
};

const useStyles = makeStyles(theme => ({
  cancel: {
    fontSize: 48,
    color: theme.palette.grey,
  },
  progress: {
    fontSize: 14,
    color: theme.palette.primary,
  },
}));

export const ProgressStatus = ({ progress, onCancel }) => {

  const classes = useStyles();

  const getPrimaryEl = () => {
    return (
      <Typography className={classes.progress} component="div">
        {`${progress}%`}
      </Typography>
    );
  };

  const getSecondaryEl = () => {
    return (
      <Tooltip title={'Cancel'}>
        <span>
          <IconButton onClick={onCancel}
                      style={IconButtonStyle}
                      className="md-button header-btn" aria-label="cancel"
          >
            <CancelIcon className={classes.cancel}/>
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  const label = createStatusLabel(progress, '');
  const primaryEl = getPrimaryEl();
  const secondaryEl = getSecondaryEl();

  return (
    <Status
      progress={progress}
      variant={label.variant}
      primaryEl={primaryEl}
      secondaryEl={secondaryEl}
    />
  );
};
