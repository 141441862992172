import React, { Component } from "react";
import Button from "../Buttons/Button";
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Grid
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import IconButtonLoading from "components/Buttons/IconButtonLoading";

class CredentialInfoDialog extends Component {
  state = {
    open: false
  };

  openInfo = () => {
    this.setState({
      open: true
    });
  };
  closeInfo = () => {
    this.setState({
      open: false
    });
  };

  copyUsername = () => {
    const { container } = this.props;
    navigator.clipboard.writeText(container.containerOwner.username).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };
  copyPassword = () => {
    const { container } = this.props;
    navigator.clipboard.writeText(container.password).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  render() {
    const { container, onClick } = this.props;
    const { open } = this.state;
    if (!container || !container.containerOwner) {
      return null;
    }
    return (
      <React.Fragment>
        <IconButtonLoading
          title="Show credentials"
          onClick={() => {
            onClick ? onClick() : null;
            this.openInfo();
          }}
          color="primary"
        >
          <Grid item xs>
            <VisibilityIcon />
          </Grid>
        </IconButtonLoading>
        <Dialog onClose={this.closeInfo} open={open} fullWidth maxWidth="xs">
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={this.closeInfo}
          >
            {container.label}
          </CloseableDialogTitle>
          <DialogContent>
            <Grid container alignItems="flex-end">
              <Grid item>
                <Typography variant="caption">Username: </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  style={{ fontWeight: "bold", marginLeft: 5 }}
                >
                  {container.containerOwner.username}
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item>
                <Typography variant="caption">Password: </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  style={{ fontWeight: "bold", marginLeft: 5 }}
                >
                  {container.password}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          {navigator && navigator.clipboard && (
            <DialogActions>
              <Button onClick={this.copyUsername} color="primary">
                Copy Username
              </Button>
              <Button onClick={this.copyPassword} color="primary">
                Copy Password
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </React.Fragment>
    );
  }
}
export default CredentialInfoDialog;
