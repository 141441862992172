import React, { Component } from "react";
import RestPanel from "../../../../components/Developer/RESTPanel";
import SQLPanel from "../../../../components/Developer/SQLPanel";
import Tabs from "../../../../components/TabContainer/Tabs";
import DrawerView from "../../../../components/Drawer";
import DrawerTabs from "../../../../components/Drawer/DrawerTabs";
import DrawerContent from "../../../../components/Drawer/DrawerContent";
import {
  TAB_ADMIN_DROOLS,
  TAB_ADMIN_REST,
  TAB_ADMIN_SQL
} from "../../../../constants/Routes";
import APPCONFIG from "../../../../constants/Config";
import DroolsPanel from "components/Admin/Drools/DroolsPanel";
import { getDroolsRules } from "actions/Drools";
import { connect } from "react-redux";

class Admin extends Component {
  state = {
    value: TAB_ADMIN_REST
  };
  refreshTimeout = null;

  componentWillUpdate(nextProps, nextState) {
    const { value } = nextState;
    if (this.state.value !== value) {
      this.refreshTab(nextProps, nextState);
    }
  }
  componentDidMount() {
    this.refreshTab(this.props, this.state);
  }
  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  refreshTab = (props, state) => {
    const { getDroolsRules } = props;
    const { value } = state;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTab(this.props, this.state);
    }, APPCONFIG.REFRESH_TIMEOUT);

    switch (value) {
      case TAB_ADMIN_DROOLS:
        getDroolsRules();
        break;
      default:
        clearTimeout(this.refreshTimeout);
    }
  };

  handleChange = value => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    let currentTab;

    switch (value) {
      case TAB_ADMIN_REST:
        currentTab = <RestPanel />;
        break;
      case TAB_ADMIN_SQL:
        currentTab = <SQLPanel />;
        break;
      case TAB_ADMIN_DROOLS:
        currentTab = <DroolsPanel />;
        break;
    }

    return (
      <DrawerView>
        <DrawerTabs>
          <Tabs tabs={TABS} value={value} onChange={this.handleChange} />
        </DrawerTabs>
        <DrawerContent>{currentTab}</DrawerContent>
      </DrawerView>
    );
  }
}

const TABS = [
  {
    value: TAB_ADMIN_REST,
    label: "REST request"
  },
  {
    value: TAB_ADMIN_SQL,
    label: "SQL request"
  },
  {
    value: TAB_ADMIN_DROOLS,
    label: "Business rules"
  }
];

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  {
    getDroolsRules
  }
)(Admin);
