import React, { Component } from "react";
import {
  getEntityTypeId,
  isAuthorizedToViewEntity,
  MODEL_REVISION_TYPE,
  MODEL_TYPE,
  MODEL_USAGE_TYPE
} from "../../api/Entity";
import { matchPath, withRouter } from "react-router";
import {
  ID_REGEX,
  MODEL_CREATE_OVERVIEW_PATH,
  MODEL_REVISION_CREATE_OVERVIEW_PATH,
  MODEL_REVISION_OVERVIEW_PATH,
  MODEL_REVISION_PATH,
  MODEL_USAGE_OVERVIEW_PATH,
  TAB_ATTRIBUTES
} from "../../constants/Routes";
import { Link } from "react-router-dom";
import classnames from "classnames";
import ListItem from "components/Buttons/ListItem";
import {
  ROLE_MODEL_VIEW,
  ROLE_MODELREVISION_CREATE
} from "../../constants/Authorities";
import Grid from "components/Grid";
import { Add } from "@material-ui/icons";
import IconButtonLoading from "components/Buttons/IconButtonLoading";

import {
  CircularProgress,
  Typography,
  ListItemSecondaryAction,
  List,
  Divider
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LinkEntity from "components/Entity/LinkEntity";
import { connect } from "react-redux";
import { isAuthorized } from "../../api/Authorities";
import qs from "querystring";

class RevisionsList extends Component {
  createNewRevision = () => {
    const { history, model } = this.props;
    let attributes;
    if (model.revisions && model.revisions.length > 0) {
      attributes = model.revisions[0].attributes;
    }
    const new_location = MODEL_REVISION_CREATE_OVERVIEW_PATH.replace(
      ":entityId" + ID_REGEX,
      model.id
    ).replace(":entityType" + ID_REGEX, getEntityTypeId(model));
    history.push({
      pathname: new_location,
      search: "?" + qs.stringify({ tab: TAB_ATTRIBUTES }),
      state: {
        attributes: attributes
      }
    });
  };

  render() {
    const {
      entity,
      entityType,
      loading,
      error,
      location,
      classes,
      model,
      waitingForModelLoading,
      me
    } = this.props;
    let selectedEntity = entity;
    if (entityType === MODEL_REVISION_TYPE) {
      selectedEntity = model;
    } else if (
      entityType !== MODEL_TYPE &&
      entityType !== MODEL_REVISION_TYPE &&
      entityType !== MODEL_USAGE_TYPE
    ) {
      return null;
    }

    const isAbleToCreate =
      model && model.active && isAuthorized(me, [ROLE_MODELREVISION_CREATE]);
    const hasRevisions =
      selectedEntity &&
      selectedEntity.revisions &&
      selectedEntity.revisions.length > 0;
    if (
      !isAbleToCreate &&
      (!isAuthorized(me, [ROLE_MODEL_VIEW]) || !hasRevisions)
    ) {
      //and if the user can't see any usage
      return null;
    }

    let list;
    if (!selectedEntity && loading) {
      list = (
        <div>
          <div style={{ textAlign: "center", lineHeight: "30px" }}>
            <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
              Loading the entity
            </span>
          </div>
        </div>
      );
    } else if (selectedEntity) {
      let creatingModelRevision, revisionSelected, classNameModel, modelItem;
      const currentPath = location.pathname;
      const matchPathUsage = matchPath(currentPath, {
        path: MODEL_USAGE_OVERVIEW_PATH
      });
      const matchPathRevision = matchPath(currentPath, {
        path: MODEL_REVISION_PATH
      });
      const matchPathCreate = matchPath(currentPath, {
        path: MODEL_REVISION_CREATE_OVERVIEW_PATH
      });
      const matchPathCreateModel = matchPath(currentPath, {
        path: MODEL_CREATE_OVERVIEW_PATH
      });
      if (matchPathRevision) {
        revisionSelected = matchPathRevision.params.revisionId;
      } else if (!matchPathCreate) {
        classNameModel = classes.revisionItemSelected;
      }

      if (matchPathCreateModel) {
        const modelCreatePath = MODEL_CREATE_OVERVIEW_PATH.replace(
          ":entityType" + ID_REGEX,
          matchPathCreateModel.params.entityType
        ).replace(
          ":securityLevel" + ID_REGEX,
          matchPathCreateModel.params.securityLevel
        );
        modelItem = (
          <ListItem
            button
            component={Link}
            to={modelCreatePath}
            className={classnames(classes.revisionItemSelected, classes.item)}
          >
            <Typography color={"inherit"} variant="subtitle2">
              Entity in creation
            </Typography>
          </ListItem>
        );
      } else if (selectedEntity.modelType) {
        const isModelSelected =
          !matchPathUsage && !matchPathRevision && !matchPathCreate;
        modelItem = (
          <ListItem
            testid="baseEntity"
            button={isAuthorizedToViewEntity(me, selectedEntity)}
            component={LinkEntity}
            entity={selectedEntity}
            className={classnames(classes.item, classNameModel)}
          >
            <Typography
              color={isModelSelected ? "inherit" : "primary"}
              variant="subtitle2"
            >
              Base entity
            </Typography>
            {isModelSelected && loading && (
              <ListItemSecondaryAction>
                <CircularProgress size={15} />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
        if (matchPathCreate) {
          const modelCreateRevisionPath = MODEL_REVISION_CREATE_OVERVIEW_PATH.replace(
            ":entityId" + ID_REGEX,
            selectedEntity.id
          ).replace(":entityType" + ID_REGEX, getEntityTypeId(selectedEntity));
          creatingModelRevision = (
            <ListItem
              button
              component={Link}
              to={modelCreateRevisionPath}
              className={classnames(
                classes.revisionItemSelected,
                classes.item,
                classes.revisionItem
              )}
            >
              <Typography
                color={"inherit"}
                style={{ fontStyle: "italic" }}
                variant="subtitle2"
              >
                Revision in creation
              </Typography>
              {waitingForModelLoading && (
                <ListItemSecondaryAction>
                  <CircularProgress size={15} />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        }
      }

      list = (
        <div style={{ textAlign: "center" }}>
          <List className={classes.list}>
            {modelItem}
            {creatingModelRevision}
            {selectedEntity.revisions &&
              selectedEntity.revisions.map((revision, idx) => {
                const path =
                  MODEL_REVISION_OVERVIEW_PATH.replace(
                    ":revisionId" + ID_REGEX,
                    revision.id
                  )
                    .replace(":entityId" + ID_REGEX, selectedEntity.id)
                    .replace(
                      ":entityType" + ID_REGEX,
                      getEntityTypeId(selectedEntity)
                    ) + "?tab=attributes";
                const isSelected =
                  revision.id && revisionSelected === revision.id.toString();
                let className;
                if (isSelected) {
                  className = classes.revisionItemSelected;
                }
                return (
                  <ListItem
                    testid="revisionItem"
                    button
                    key={idx}
                    component={Link}
                    to={path}
                    className={classnames(
                      className,
                      classes.item,
                      classes.revisionItem
                    )}
                  >
                    <Typography
                      color={isSelected ? "inherit" : "primary"}
                      variant="subtitle2"
                    >
                      {revision.label}
                    </Typography>
                    <Typography
                      color={isSelected ? "inherit" : "primary"}
                      variant="caption"
                    >
                      {revision.subLabel}
                    </Typography>
                    {isSelected && loading && (
                      <ListItemSecondaryAction>
                        <CircularProgress size={15} />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              })}
          </List>
        </div>
      );
    } else {
      list = (
        <div style={{ textAlign: "center", lineHeight: "30px" }}>
          <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
            No entity found: {error}
          </span>
        </div>
      );
    }
    return (
      <div>
        <Divider />
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ height: 32 }}
        >
          <Grid item>
            <Typography variant="subtitle2" className={classes.title}>
              Revisions
            </Typography>
          </Grid>
          <Grid item>
            {isAbleToCreate ? (
              <IconButtonLoading
                testid="createRevision"
                tooltipTitle="Create a new revision"
                style={{ padding: 6, marginRight: 12 }}
                authoritiesRequired={[ROLE_MODELREVISION_CREATE]}
                onClick={this.createNewRevision}
              >
                <Add color="primary" style={{ fontSize: 20 }} />
              </IconButtonLoading>
            ) : null}
          </Grid>
        </Grid>
        {list}
      </div>
    );
  }
}

const styles = theme => ({
  list: {
    paddingRight: "10px"
  },
  title: {
    padding: "0 16px 0"
  },
  item: {
    paddingTop: "5px",
    paddingBottom: "5px",
    borderBottomRightRadius: "23px",
    borderTopRightRadius: "23px"
  },
  revisionItem: {
    paddingLeft: "40px",
    flexDirection: "column",
    alignItems: "flex-start",
    lineHeight: "18px"
  },
  revisionItemSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: "#594300"
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {}
    )(RevisionsList)
  )
);
