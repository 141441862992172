import { arrayContainsArray } from "../api/List";

export function isAuthorized(me, authoritiesRequired, atLeastOneAuthorization) {
  return !(
    authoritiesRequired &&
    (!me ||
      !me.grantedAuthorities ||
      !arrayContainsArray(
        me.grantedAuthorities,
        authoritiesRequired,
        atLeastOneAuthorization
      ))
  );
}
export function isFeaturesAuthorized(
  features,
  featuresRequired,
  atLeastOneFeature
) {
  return !(
    featuresRequired &&
    (!features ||
      !arrayContainsArray(features, featuresRequired, atLeastOneFeature))
  );
}
export function isDynamicPropertyAuthorized(
  dynamicProperties,
  propertiesRequired,
  atLeastOneProperty
) {
  if (!propertiesRequired || propertiesRequired.length === 0) {
    return true;
  }
  for (
    let i = 0;
    dynamicProperties && propertiesRequired && i < propertiesRequired.length;
    i++
  ) {
    const { name, value } = propertiesRequired[i];
    if (!dynamicProperties[name] || dynamicProperties[name] !== value) {
      if (!atLeastOneProperty) {
        return false;
      }
    } else {
      if (atLeastOneProperty) {
        return true;
      }
    }
  }
  return !atLeastOneProperty;
}
