import {pushNotification} from "actions/notifications";

/**
 * Checks that the source and target entity are set.
 *
 * @param {{}} link - the link to check resolved entities
 * @returns {boolean} True if the source entity and target entity are set
 */
export const isResolvedEntitiesSet = (link) => {
  return link.sourceEntityResolved && link.targetEntityResolved;
}

export const isValidURL = (string) => {
  const pattern = new RegExp('^(https?:\\/\\/)'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+([a-z]{2,})|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(string);
}

export const copyToClipboard = (href) => {
  return function(dispatch) {
    if (href.startsWith(location.origin + "/api/")) {
      href = location.origin + "/files/" + btoa(href)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
    }

    navigator.clipboard.writeText(href).then(
      function () {
        dispatch(pushNotification('check', 'success', 'Link copied to clipboard.'));
      },
    );
  }
}