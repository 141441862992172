import React, { Component } from "react";
import Button from "components/Buttons/Button";
import DialogActions from "@material-ui/core/DialogActions/index";
import Dialog from "@material-ui/core/Dialog/index";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import { Delete } from "@material-ui/icons/index";
import { ROLE_ACCESSTOKEN_MANAGEOWN } from "../../constants/Authorities";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";

class DeleteTokenButton extends Component {
  state = {
    open: false
  };
  open = () => {
    this.setState({
      open: true
    });
  };
  onClose = () => {
    this.setState({
      open: false
    });
  };
  onDelete = () => {
    const { revokeToken, token } = this.props;
    revokeToken(token.id);
    this.onClose();
  };

  render() {
    const { token } = this.props;
    const { open } = this.state;

    if (!token) {
      return null;
    }

    return (
      <div style={{ display: "inline-block" }}>
        <div className="error" style={{ display: "inline-block" }}>
          {token.error}
        </div>
        <IconButtonLoading
          loading={token.revoking}
          loadingTitle="Revoking the token"
          tooltipTitle="Revoke the token"
          authoritiesRequired={[ROLE_ACCESSTOKEN_MANAGEOWN]}
          onClick={this.open}
          aria-label="Revoke the token"
          color="primary"
        >
          <Delete />
        </IconButtonLoading>
        <Dialog onClose={this.onClose} open={open}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={this.onClose}
          >
            This token will be revoked, do you want to continue?
          </CloseableDialogTitle>
          <DialogActions>
            <Button onClick={this.onClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.onDelete} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DeleteTokenButton;
