import {
  GET_OWN_FILTERS,
  LOADING_OWN_FILTERS,
  END_LOADING_OWN_FILTERS,
  ERROR_OWN_FILTERS,
  GET_SHARED_FILTERS,
  LOADING_SHARED_FILTERS,
  END_LOADING_SHARED_FILTERS,
  ERROR_SHARED_FILTERS,
  SAVE_FILTERS,
  SAVING_FILTERS,
  END_SAVING_FILTERS,
  ERROR_SAVING_FILTERS,
  RUNNING_SEARCH,
  RUNNING_SEARCH_END,
  SEARCH_RESULT,
  SEARCH_ERROR,
  LOAD_FILTERS_CONFIG,
  LOADING_FILTERS_CONFIG,
  LOADING_FILTERS_CONFIG_END,
  FILTERS_CONFIG_ERROR,
  UPDATING_FILTERS,
  END_UPDATING_FILTERS,
  ERROR_UPDATING_FILTERS,
  UPDATE_FILTERS,
  REMOVE_FILTERS,
  REMOVING_FILTERS,
  END_REMOVING_FILTERS,
  ERROR_REMOVING_FILTERS,
  LOAD_PROCESS_VARIABLES_CONFIG,
} from "constants/ActionTypes";
import { goThroughList } from "api/List";
import { FILTER_TYPE } from "constants/Filters";

export default function (state = {}, action) {
  let new_filters = [];
  const actionFilters = action.filters;
  let currentOwnFilters = state.own_saved_filters;
  if (!currentOwnFilters) {
    currentOwnFilters = [];
  }
  let last_filters_runned = state.last_filters_runned;
  let last_current_filters_runned = state.last_current_filters_runned;
  let running_filters = state.running_filters;
  let results = state.results;
  let meta = state.meta;
  let attributes = state.attributes;
  let error = state.error;
  if (!last_filters_runned) {
    last_filters_runned = {};
  }
  if (!last_current_filters_runned) {
    last_current_filters_runned = {};
  }
  if (!running_filters) {
    running_filters = {};
  }
  if (!results) {
    results = {};
  }
  if (!meta) {
    meta = {};
  }
  if (!attributes) {
    attributes = {};
  }
  if (!error) {
    error = {};
  }

  switch (action.type) {
    case GET_OWN_FILTERS:
      return { ...state, own_saved_filters: action.filters };
    case LOADING_OWN_FILTERS:
      return {
        ...state,
        loading_own_filters: true,
        error_own_filters: undefined,
      };
    case END_LOADING_OWN_FILTERS:
      return { ...state, loading_own_filters: false };
    case ERROR_OWN_FILTERS:
      return { ...state, error_own_filters: action.error };

    case GET_SHARED_FILTERS:
      return { ...state, shared_saved_filters: action.filters };
    case LOADING_SHARED_FILTERS:
      return {
        ...state,
        loading_shared_filters: true,
        error_shared_filters: undefined,
      };
    case END_LOADING_SHARED_FILTERS:
      return { ...state, loading_shared_filters: false };
    case ERROR_SHARED_FILTERS:
      return { ...state, error_shared_filters: action.error };

    case SAVE_FILTERS:
      new_filters = [...currentOwnFilters, actionFilters];
      return { ...state, own_saved_filters: new_filters };
    case SAVING_FILTERS:
      return {
        ...state,
        saving_filters: true,
        error_saving_filters: undefined,
      };
    case END_SAVING_FILTERS:
      return { ...state, saving_filters: false };
    case ERROR_SAVING_FILTERS:
      return { ...state, error_saving_filters: action.error };

    case UPDATING_FILTERS:
      results = goThroughFiltersLists(
        state.own_saved_filters,
        state.shared_saved_filters,
        state.current_filters,
        action.id,
        (filters) => {
          return {
            ...filters,
            updating: true,
            error_updating: null,
          };
        }
      );
      return {
        ...state,
        own_saved_filters: results.own,
        shared_saved_filters: results.shared,
        current_filters: results.current,
      };
    case END_UPDATING_FILTERS:
      results = goThroughFiltersLists(
        state.own_saved_filters,
        state.shared_saved_filters,
        state.current_filters,
        action.id,
        (filters) => {
          return {
            ...filters,
            updating: false,
          };
        }
      );
      return {
        ...state,
        own_saved_filters: results.own,
        shared_saved_filters: results.shared,
        current_filters: results.current,
      };
    case ERROR_UPDATING_FILTERS:
      results = goThroughFiltersLists(
        state.own_saved_filters,
        state.shared_saved_filters,
        state.current_filters,
        action.id,
        (filters) => {
          return {
            ...filters,
            error_updating: action.error,
          };
        }
      );
      return {
        ...state,
        own_saved_filters: results.own,
        shared_saved_filters: results.shared,
        current_filters: results.current,
      };
    case UPDATE_FILTERS:
      results = goThroughFiltersLists(
        state.own_saved_filters,
        state.shared_saved_filters,
        state.current_filters,
        action.id,
        (filters) => {
          return action.filters;
        }
      );
      return {
        ...state,
        own_saved_filters: results.own,
        shared_saved_filters: results.shared,
        current_filters: results.current,
      };

    case REMOVE_FILTERS:
      results = goThroughFiltersLists(
        state.own_saved_filters,
        state.shared_saved_filters,
        state.current_filters,
        action.id,
        () => {
          return null;
        }
      );
      return {
        ...state,
        own_saved_filters: results.own,
        shared_saved_filters: results.shared,
        current_filters: results.current,
      };
    case REMOVING_FILTERS:
      results = goThroughFiltersLists(
        state.own_saved_filters,
        state.shared_saved_filters,
        state.current_filters,
        action.id,
        (filters) => {
          return {
            ...filters,
            removing: true,
            error_removing: null,
          };
        }
      );
      return {
        ...state,
        own_saved_filters: results.own,
        shared_saved_filters: results.shared,
        current_filters: results.current,
      };
    case END_REMOVING_FILTERS:
      results = goThroughFiltersLists(
        state.own_saved_filters,
        state.shared_saved_filters,
        state.current_filters,
        action.id,
        (filters) => {
          return {
            ...filters,
            removing: false,
          };
        }
      );
      return {
        ...state,
        own_saved_filters: results.own,
        shared_saved_filters: results.shared,
        current_filters: results.current,
      };
    case ERROR_REMOVING_FILTERS:
      results = goThroughFiltersLists(
        state.own_saved_filters,
        state.shared_saved_filters,
        state.current_filters,
        action.id,
        (filters) => {
          return {
            ...filters,
            error_removing: action.error,
          };
        }
      );
      return {
        ...state,
        own_saved_filters: results.own,
        shared_saved_filters: results.shared,
        current_filters: results.current,
      };

    case RUNNING_SEARCH:
      if (actionFilters.filters.filterType === FILTER_TYPE.CUSTOM) {
        last_current_filters_runned[action.entityType] = actionFilters.filters;
      }
      last_filters_runned[action.entityType] = actionFilters.filters;
      running_filters[action.entityType] = actionFilters;
      return {
        ...state,
        running_filters: {
          ...running_filters,
        },
        last_filters_runned: {
          ...last_filters_runned,
        },
        last_current_filters_runned: {
          ...last_current_filters_runned,
        },
      };
    case RUNNING_SEARCH_END:
      running_filters[action.entityType] = undefined;
      return {
        ...state,
        running_filters: {
          ...running_filters,
        },
      };
    case SEARCH_ERROR:
      error[action.entityType] = action.error;
      return {
        ...state,
        error: {
          ...error,
        },
      };
    case SEARCH_RESULT:
      results[action.entityType] = action.results;
      meta[action.entityType] = action.meta;
      attributes[action.entityType] = action.attributes;
      return {
        ...state,
        results: {
          ...results,
        },
        meta: {
          ...meta,
        },
        attributes: {
          ...attributes,
        },
      };

    case LOAD_PROCESS_VARIABLES_CONFIG:
      return { ...state, config_process_variables: action.config };
    case LOAD_FILTERS_CONFIG:
      return { ...state, config: action.config };
    case LOADING_FILTERS_CONFIG:
      return { ...state, loading_config: true, error_filters: undefined };
    case LOADING_FILTERS_CONFIG_END:
      return { ...state, loading_config: false };
    case FILTERS_CONFIG_ERROR:
      return { ...state, error_config: action.error };
    default:
      return state;
  }
}

function goThroughFiltersLists(own, shared, current, filtersId, editFilters) {
  if (
    current &&
    current.id &&
    filtersId &&
    current.id.toString() === filtersId.toString()
  ) {
    current = editFilters(current, false, true);
  }
  let result = goThroughList(own, null, filtersId, (filters) => {
    return editFilters(filters, false, false);
  });
  own = result.list;
  let result2 = goThroughList(shared, null, filtersId, (filters) => {
    return editFilters(filters, true, false);
  });
  shared = result2.list;
  return {
    own: own,
    shared: shared,
    current: current,
  };
}
