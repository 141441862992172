import React, { Component } from "react";
import classnames from "classnames";
import RestrictedComponent from "../RestrictedComponent";
import { getCurrentURLParams } from "../../api/GUI";
import { withRouter } from "react-router";
import qs from "querystring";

import {
  List,
  ListItem,
  Typography,
  ListItemSecondaryAction,
  CircularProgress, Step
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {connect} from "react-redux";
import {initializeForm} from "actions/index";
import {dragAttachments, dragAttachmentsEnd, moveAttachment} from "actions/Attachments";

class Tabs extends Component {
  state = {
    isEmpty: true,
    unauthorizedTabs: [],
    dropping: false
  };

  componentDidMount() {
    const {
      location,
      onChange,
      value,
      tabs,
      urlKey,
      withoutUrlManagement
    } = this.props;
    if (!withoutUrlManagement) {
      const query = getCurrentURLParams(location.search);
      if (query && query[urlKey] && query[urlKey] !== value && onChange) {
        onChange(query[urlKey]);
      }
    }
    this.setState({
      isEmpty: !tabs || tabs.length === 0
    });
  }

  onTabChangeURL = subfolder => {
    const { history, location, urlKey, withoutUrlManagement } = this.props;
    if (!withoutUrlManagement) {
      let query = getCurrentURLParams(location.search);
      query[urlKey] = subfolder;
      const searchString = qs.stringify(query);
      history.push({
        ...location,
        search: searchString
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      location,
      value,
      urlKey,
      onChange,
      withoutUrlManagement,
      tabs
    } = this.props;
    const { unauthorizedTabs } = this.state;

    if (!withoutUrlManagement) {
      const query = getCurrentURLParams(location.search);
      if (!query || !query[urlKey]) {
        if (value) {
          this.onTabChangeURL(value);
        }
      } else if (query[urlKey] !== value) {
        if (value !== prevProps.value) {
          this.onTabChangeURL(value);
        } else {
          onChange(query[urlKey]);
        }
      }
    }
    if (
      tabs !== prevProps.tabs ||
      unauthorizedTabs !== prevState.unauthorizedTabs
    ) {
      this.setState({
        isEmpty: !tabs || tabs.length <= unauthorizedTabs.length
      });
    }
  }

  onDrop = (newOwnerId) => {
    const { droppable, draggedFiles, dragAttachmentsEnd, moveAttachment } = this.props;
    this.setState({
      dropping: false
    });
    if (droppable) {
      for (let i = 0; i < draggedFiles.length; i++) {
        const draggedFile = draggedFiles[i];
        moveAttachment(draggedFile, "/", newOwnerId);
      }
      const element = document.getElementById("flying-files");
      if (element && element.parentNode) {
        element.parentNode.removeChild(element);
      }
      dragAttachmentsEnd()
    }
  };

  onDragEnter = (value) => {
    this.setState({
      dropping: value
    });
  };
  onDragLeave = (value) => {
    this.setState({
      dropping: false
    });
  };
  onDragOver = (value) => {
    const { dropping } = this.state;
    if (!dropping) {
      this.setState({
        dropping: value
      });
    }
  };

  render() {
    const { tabs, header, headerTitle, footer, droppable, ...otherProps } = this.props;
    const {
      classes,
      creation_mode,
      edition_mode,
      entityType,
      onChange,
      loading
    } = otherProps;
    const { isEmpty, unauthorizedTabs, dropping } = this.state;
    return (
      <>
        {!isEmpty && headerTitle && (
          <Typography variant="subtitle2" className={classes.title}>
            {headerTitle}
          </Typography>
        )}
        {!isEmpty && header}
        <List className={classes.list} testid="tabs">
          {tabs &&
            tabs.map((tab, idx) => {
              const {
                value,
                disabled,
                disabledOnEdit,
                allowedEntityTypes,
                ...otherTabProps
              } = tab;
              const isSelected = value && this.props.value === value.toString();
              let className;
              if (
                allowedEntityTypes &&
                !allowedEntityTypes.includes(entityType)
              ) {
                return null;
              }
              if (isSelected) {
                className = classes.tabSelected;
              }
              return (
                <RestrictedComponent
                  key={idx}
                  onAuthorized={() => {
                    if (unauthorizedTabs.includes(value)) {
                      this.setState({
                        unauthorizedTabs: unauthorizedTabs.filter(
                          tab => tab !== value
                        )
                      });
                    }
                  }}
                  onUnauthorized={() => {
                    if (isSelected && onChange) {
                      const allowedTabs = tabs.filter(
                        ({ value }) =>
                          !unauthorizedTabs.includes(value) &&
                          value !== tab.value
                      );
                      if (allowedTabs.length > 0) {
                        onChange(allowedTabs[0].value);
                      } else {
                        this.setState({
                          isEmpty: true
                        });
                        onChange(null);
                      }
                    }
                    if (!unauthorizedTabs.includes(value)) {
                      this.setState({
                        unauthorizedTabs: [...unauthorizedTabs, value]
                      });
                    }
                  }}
                  {...otherTabProps}
                >
                  {tab.label && (
                    <ListItem
                      testid={value}
                      className={classnames(className, classes.tabItem, (dropping === tab.value && classes.tabDropping))}
                      button
                      color="primary"
                      onClick={() => {
                        onChange && onChange(value);
                      }}
                      disabled={
                        disabled ||
                        (disabledOnEdit && (creation_mode || edition_mode))
                      }
                      onDragEnter={() => { droppable && this.onDragEnter(tab.value)}}
                      onDragLeave={droppable && this.onDragLeave}
                      onDragOver={() => { droppable && this.onDragOver(tab.value)}}
                      onDrop={() => this.onDrop(value)}
                      dropping={(dropping === tab.value && droppable)? "true": undefined}
                    >
                      <Typography
                        color={isSelected ? "inherit" : "primary"}
                        variant="subtitle2"
                      >
                        {tab.label}
                      </Typography>
                      {isSelected && loading && (
                        <ListItemSecondaryAction>
                          <CircularProgress size={15} />
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  )}
                  {tab.tabs && (
                    <TabsComponent {...otherTabProps} {...otherProps} />
                  )}
                </RestrictedComponent>
              );
            })}
        </List>
        {!isEmpty && footer}
      </>
    );
  }
}
const styles = theme => ({
  list: {
    paddingRight: "10px !important"
  },
  title: {
    padding: "10px 16px 0"
  },
  tabItem: {
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    flexDirection: "column !important",
    alignItems: "flex-start !important",
    borderBottomRightRadius: "23px !important",
    borderTopRightRadius: "23px !important",
    lineHeight: "18px !important"
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main + " !important",
    color: "#594300 !important"
  },
  tabDropping: {
    borderStyle: "dashed",
    borderWidth: "1px",
    borderLeftWidth: "0",
    borderColor: "#594300 !important"
  }
});

Tabs.defaultProps = {
  urlKey: "tab"
};

const mapStateToProps = (state, ownProps) => {
  return {
    draggedFiles: state.attachments.draggedAttachments
  };
};

const TabsComponent = withRouter(
  connect(
    mapStateToProps,
    {
      dragAttachmentsEnd,
      moveAttachment
    }
  )
  (withStyles(styles)(Tabs))
);
export default TabsComponent;
