import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Route, Redirect } from "react-router-dom";
import U403 from "./containers/pages/403";
import U404 from "./containers/pages/404";
import U500 from "./containers/pages/500";
import Login from "./containers/pages/Login";
import MainApp from "./containers/pages/App";
import AppOverview from "./containers/pages/App/overview";

import Entities from "./containers/pages/App/Entity/Entities";
import EntitiesOverview from "./containers/pages/App/Entity/Entities/overview";

import EntityOverview from "./containers/pages/App/Entity/overview";
import Entity from "./containers/pages/App/Entity";

import EntityTypeOverview from "./containers/pages/App/Entity/EntityType/overview";
import EntityType from "./containers/pages/App/Entity/EntityType";

import ModelMonitoring from "./containers/pages/App/ModelMonitoring";
import ModelMonitoringOverview from "./containers/pages/App/ModelMonitoring/overview";

import Tasks from "./containers/pages/App/Tasks";
import TasksOverview from "./containers/pages/App/Tasks/overview";

import Folders from "./containers/pages/App/Folders";
import FoldersOverview from "./containers/pages/App/Folders/overview";
import Folder from "./containers/pages/App/Folders/Folder";
import FolderOverview from "./containers/pages/App/Folders/Folder/overview";

import FileProvider from "./containers/pages/App/Files/FileProvider";

import Developer from "./containers/pages/App/Developer";
import DeveloperOverview from "./containers/pages/App/Developer/overview";

import Admin from "./containers/pages/App/Admin";
import AdminOverview from "./containers/pages/App/Admin/overview";

import AccountOverview from "./containers/pages/App/Account/overview";
import Account from "./containers/pages/App/Account";

import LicenseOverview from "./containers/pages/App/License/overview";

import ErrorBoundary from "./components/ErrorBoundary";
import RouterRedirect404 from "./api/RouterRedirect404";

import {
  ADMIN_OVERVIEW_PATH,
  ADMIN_PATH,
  APP_OVERVIEW_PATH,
  APP_PATH,
  DEVELOPER_OVERVIEW_PATH,
  DEVELOPER_PATH,
  FOLDER_OVERVIEW_PATH,
  FOLDER_PATH,
  FOLDER_PERSONAL_PATH,
  FOLDER_PUBLIC_PATH,
  FOLDER_SHARED_PATH,
  FOLDERS_OVERVIEW_PATH,
  FOLDERS_PATH,
  LICENSE_OVERVIEW_PATH,
  FILE_PATH,
  MODEL_CREATE_OVERVIEW_PATH,
  MODEL_CREATE_PATH,
  MODEL_MONITORING_EXPANDED_PATH,
  MODEL_MONITORING_OVERVIEW_PATH,
  MODEL_MONITORING_PATH,
  MODEL_OVERVIEW_PATH,
  MODEL_PATH,
  MODEL_REVISION_CREATE_OVERVIEW_PATH,
  MODEL_REVISION_CREATE_PATH,
  MODEL_REVISION_OVERVIEW_PATH,
  MODEL_REVISION_PATH,
  MODEL_REVISION_SHORT_OVERVIEW_PATH,
  MODEL_REVISION_SHORT_PATH,
  MODEL_USAGE_CREATE_OVERVIEW_PATH,
  MODEL_USAGE_CREATE_PATH,
  MODEL_USAGE_OVERVIEW_PATH,
  MODEL_USAGE_PATH,
  MODELS_OVERVIEW_PATH,
  MODELS_PATH,
  MODELS_SHORT_OVERVIEW_PATH,
  MODELS_SHORT_PATH,
  MODELS_TYPE_OVERVIEW_PATH,
  MODELS_TYPE_PATH,
  PERSONAL_SECTION_OVERVIEW_PATH,
  PERSONAL_SECTION_PATH,
  PROCESSES_OVERVIEW_PATH,
  PROCESSES_PATH,
  TASKS_OVERVIEW_PATH,
  TASKS_PATH,
  VALIDATION_COMMITTEE_CREATE_OVERVIEW_PATH,
  VALIDATION_COMMITTEE_CREATE_PATH,
  VALIDATION_COMMITTEE_OVERVIEW_PATH,
  VALIDATION_COMMITTEE_PATH,
  VALIDATION_COMMITTEES_OVERVIEW_PATH,
  VALIDATION_COMMITTEES_PATH,
  VALIDATION_COMMITTEES_TYPE_OVERVIEW_PATH,
  VALIDATION_COMMITTEES_TYPE_PATH,
  VALIDATION_CREATE_OVERVIEW_PATH,
  VALIDATION_CREATE_PATH,
  VALIDATION_OVERVIEW_PATH,
  VALIDATION_PATH,
  VALIDATIONS_OVERVIEW_PATH,
  VALIDATIONS_PATH,
  VALIDATIONS_TYPE_OVERVIEW_PATH,
  VALIDATIONS_TYPE_PATH,
} from "./constants/Routes";
import {
  ROLE_MODEL_CREATE,
  ROLE_MODEL_MONITORING_VIEW,
  ROLE_MODEL_VIEW,
  ROLE_MODELREVISION_CREATE,
  ROLE_MODELTYPE_VIEW,
  ROLE_PERSONAL_STORAGE,
  ROLE_PUBLIC_FOLDER_VIEW,
  ROLE_SHARED_FOLDER_VIEW,
  ROLE_SYSTEM_ADMINISTRATION,
  ROLE_SYSTEM_PERFORM,
  ROLE_VALIDATION_CREATE,
  ROLE_VALIDATION_VIEW,
  ROLE_VALIDATIONCOMMITTEE_CREATE,
  ROLE_VALIDATIONCOMMITTEE_VIEW,
  ROLE_VALIDATIONCOMMITTEETYPE_VIEW,
  ROLE_VALIDATIONTYPE_VIEW,
} from "./constants/Authorities";
import { pushNotification } from "actions/notifications";
import { isAuthorized, isFeaturesAuthorized } from "./api/Authorities";
import {
  AUTOMATION_WORKFLOWS,
  DATAHUB_PERSONAL,
  DATAHUB_PUBLIC,
  DATAHUB_SHARED_FOLDER,
  MODEL_MONITORING,
} from "./constants/Features";
import { renderRoutes } from "react-router-config";
import Processes from "./containers/pages/App/Processes";
import ProcessesOverview from "./containers/pages/App/Processes/overview";
import ModelRevisionShortcut from "./containers/pages/App/Entity/Shortcuts/ModelRevisionShortcut";
import BaseModelShortcut from "./containers/pages/App/Entity/Shortcuts/BaseModelShortcut";

const Root = ({ route }) => {
  return renderRoutes(route.routes, {});
};

const RedirectToRoot = () => {
  return <Redirect to={{ pathname: APP_OVERVIEW_PATH }} />;
};

const restricted = ({
  component: Component,
  authoritiesRequired,
  featuresRequired,
  ...rest
}) => {
  const Restricted = ({ route, tab, droppedFiles }) => {
    const dispatch = useDispatch();
    const me = useSelector((state) => state.auth.me);
    const features = useSelector((state) => state.features.list);
    let render;
    if (
      !isAuthorized(me, authoritiesRequired) ||
      !isFeaturesAuthorized(features, featuresRequired)
    ) {
      pushNotification(
        "error",
        "error",
        "You are not allowed to access this page. You have been redirected to the main page."
      )(dispatch);
      render = () => <RedirectToRoot />;
    } else {
      render = (props) => (
        <Component
          {...props}
          tab={tab}
          droppedFiles={droppedFiles}
          route={route}
        />
      );
    }
    return <Route {...rest} render={render} />;
  };

  return withRouter(Restricted);
};

export function withErrorBoundary(Component) {
  return (props) => {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
}

export default [
  {
    component: Root,
    routes: [
      {
        path: "/",
        exact: true,
        component: withErrorBoundary(RedirectToRoot),
      },
      {
        path: "/login",
        component: withErrorBoundary(Login),
      },
      {
        path: "/403",
        component: withErrorBoundary(U403),
      },
      {
        path: "/404",
        component: withErrorBoundary(U404),
      },
      {
        path: "/500",
        component: withErrorBoundary(U500),
      },
      {
        path: MODELS_SHORT_PATH,
        component: withErrorBoundary(BaseModelShortcut),
        routes: [
          {
            path: MODELS_SHORT_OVERVIEW_PATH,
            exact: true,
            component: withErrorBoundary(
              restricted({
                component: BaseModelShortcut,
                authoritiesRequired: [ROLE_MODEL_VIEW],
              })
            ),
          },
          {
            component: RouterRedirect404,
          },
        ],
      },
      {
        path: MODEL_REVISION_SHORT_PATH,
        component: withErrorBoundary(ModelRevisionShortcut),
        routes: [
          {
            path: MODEL_REVISION_SHORT_OVERVIEW_PATH,
            exact: true,
            component: withErrorBoundary(
              restricted({
                component: ModelRevisionShortcut,
                authoritiesRequired: [ROLE_MODEL_VIEW],
              })
            ),
          },
          {
            component: RouterRedirect404,
          },
        ],
      },
      {
        path: APP_PATH,
        component: withErrorBoundary(restricted({ component: MainApp })),
        routes: [
          {
            path: APP_OVERVIEW_PATH,
            exact: true,
            component: withErrorBoundary(AppOverview),
          },
          {
            path: ADMIN_PATH,
            component: withErrorBoundary(Admin),
            routes: [
              {
                path: ADMIN_OVERVIEW_PATH,
                exact: true,
                component: withErrorBoundary(
                  restricted({
                    component: AdminOverview,
                    authoritiesRequired: [ROLE_SYSTEM_ADMINISTRATION],
                  })
                ),
              },
              {
                component: RouterRedirect404,
              },
            ],
          },
          {
            path: DEVELOPER_PATH,
            component: withErrorBoundary(Developer),
            routes: [
              {
                path: DEVELOPER_OVERVIEW_PATH,
                component: withErrorBoundary(
                  restricted({
                    component: DeveloperOverview,
                    authoritiesRequired: [ROLE_SYSTEM_PERFORM],
                  })
                ),
              },
              {
                component: RouterRedirect404,
              },
            ],
          },
          {
            path: PERSONAL_SECTION_PATH,
            component: withErrorBoundary(Account),
            routes: [
              {
                path: PERSONAL_SECTION_OVERVIEW_PATH,
                component: withErrorBoundary(
                  restricted({
                    component: AccountOverview,
                    authoritiesRequired: [],
                  })
                ),
              },
              {
                component: RouterRedirect404,
              },
            ],
          },
          {
            path: LICENSE_OVERVIEW_PATH,
            component: withErrorBoundary(Account),
            routes: [
              {
                path: LICENSE_OVERVIEW_PATH,
                component: withErrorBoundary(
                  restricted({
                    component: LicenseOverview,
                    authoritiesRequired: [],
                  })
                ),
              },
              {
                component: RouterRedirect404,
              },
            ],
          },
          {
            path: TASKS_PATH,
            component: withErrorBoundary(Tasks),
            routes: [
              {
                path: TASKS_OVERVIEW_PATH,
                exact: true,
                component: withErrorBoundary(TasksOverview),
              },
              {
                component: RouterRedirect404,
              },
            ],
          },
          {
            path: PROCESSES_PATH,
            component: withErrorBoundary(Processes),
            featuresRequired: [AUTOMATION_WORKFLOWS],
            routes: [
              {
                path: PROCESSES_OVERVIEW_PATH,
                exact: true,
                component: withErrorBoundary(ProcessesOverview),
              },
              {
                component: RouterRedirect404,
              },
            ],
          },
          {
            path: MODEL_MONITORING_PATH,
            component: withErrorBoundary(ModelMonitoring),
            featuresRequired: [MODEL_MONITORING],
            authoritiesRequired: [ROLE_MODEL_MONITORING_VIEW],
            routes: [
              {
                path: MODEL_MONITORING_EXPANDED_PATH,
                exact: true,
                component: withErrorBoundary(ModelMonitoringOverview),
              },
              {
                path: MODEL_MONITORING_OVERVIEW_PATH,
                exact: true,
                component: withErrorBoundary(ModelMonitoringOverview),
              },
              {
                component: RouterRedirect404,
              },
            ],
          },
          {
            path: FILE_PATH,
            component: withErrorBoundary(FileProvider),
          },
          {
            path: FOLDERS_PATH,
            component: withErrorBoundary(Folders),
            routes: [
              {
                path: FOLDERS_OVERVIEW_PATH,
                exact: true,
                component: withErrorBoundary(FoldersOverview),
              },
              {
                path: FOLDER_PERSONAL_PATH,
                component: withErrorBoundary(
                  restricted({
                    component: Folder,
                    featuresRequired: [DATAHUB_PERSONAL],
                    authoritiesRequired: [ROLE_PERSONAL_STORAGE],
                  })
                ),
                routes: [
                  {
                    path: FOLDER_OVERVIEW_PATH,
                    exact: true,
                    component: withErrorBoundary(FolderOverview),
                  },
                  {
                    component: RouterRedirect404,
                  },
                ],
              },
              {
                path: FOLDER_PUBLIC_PATH,
                component: withErrorBoundary(
                  restricted({
                    component: Folder,
                    featuresRequired: [DATAHUB_PUBLIC],
                    authoritiesRequired: [ROLE_PUBLIC_FOLDER_VIEW],
                  })
                ),
                routes: [
                  {
                    path: FOLDER_OVERVIEW_PATH,
                    exact: true,
                    component: withErrorBoundary(FolderOverview),
                  },
                  {
                    component: RouterRedirect404,
                  },
                ],
              },
              {
                path: FOLDER_SHARED_PATH,
                component: withErrorBoundary(
                  restricted({
                    component: Folder,
                    featuresRequired: [DATAHUB_SHARED_FOLDER],
                    authoritiesRequired: [ROLE_SHARED_FOLDER_VIEW],
                  })
                ),
                routes: [
                  {
                    path: FOLDER_OVERVIEW_PATH,
                    exact: true,
                    component: withErrorBoundary(FolderOverview),
                  },
                  {
                    component: RouterRedirect404,
                  },
                ],
              },
              {
                component: RouterRedirect404,
              },
            ],
          },
          {
            path: MODELS_PATH,
            component: withErrorBoundary(Entities),
            routes: [
              {
                path: MODELS_OVERVIEW_PATH,
                exact: true,
                component: withErrorBoundary(
                  restricted({
                    component: EntitiesOverview,
                    authoritiesRequired: [ROLE_MODEL_VIEW],
                  })
                ),
              },
              {
                path: MODELS_TYPE_PATH,
                component: withErrorBoundary(EntityType),
                routes: [
                  {
                    path: MODELS_TYPE_OVERVIEW_PATH,
                    exact: true,
                    component: withErrorBoundary(
                      restricted({
                        component: EntityTypeOverview,
                        authoritiesRequired: [ROLE_MODELTYPE_VIEW],
                      })
                    ),
                  },
                  {
                    path: MODEL_USAGE_CREATE_PATH,
                    component: withErrorBoundary(
                      restricted({ component: Entity, authoritiesRequired: [] })
                    ),
                    routes: [
                      {
                        path: MODEL_USAGE_CREATE_OVERVIEW_PATH,
                        exact: true,
                        component: withErrorBoundary(EntityOverview),
                      },
                      {
                        component: RouterRedirect404,
                      },
                    ],
                  },
                  {
                    path: MODEL_USAGE_PATH,
                    component: withErrorBoundary(
                      restricted({
                        component: Entity,
                        authoritiesRequired: [ROLE_MODEL_VIEW],
                      })
                    ),
                    routes: [
                      {
                        path: MODEL_USAGE_OVERVIEW_PATH,
                        exact: true,
                        component: withErrorBoundary(EntityOverview),
                      },
                      {
                        component: RouterRedirect404,
                      },
                    ],
                  },
                  {
                    path: MODEL_REVISION_CREATE_PATH,
                    component: withErrorBoundary(
                      restricted({
                        component: Entity,
                        authoritiesRequired: [ROLE_MODELREVISION_CREATE],
                      })
                    ),
                    routes: [
                      {
                        path: MODEL_REVISION_CREATE_OVERVIEW_PATH,
                        exact: true,
                        component: withErrorBoundary(EntityOverview),
                      },
                      {
                        component: RouterRedirect404,
                      },
                    ],
                  },
                  {
                    path: MODEL_REVISION_PATH,
                    component: withErrorBoundary(
                      restricted({
                        component: Entity,
                        authoritiesRequired: [ROLE_MODEL_VIEW],
                      })
                    ),
                    routes: [
                      {
                        path: MODEL_REVISION_OVERVIEW_PATH,
                        exact: true,
                        component: withErrorBoundary(EntityOverview),
                      },
                      {
                        component: RouterRedirect404,
                      },
                    ],
                  },
                  {
                    path: MODEL_CREATE_PATH,
                    component: withErrorBoundary(
                      restricted({
                        component: Entity,
                        authoritiesRequired: [ROLE_MODEL_CREATE],
                      })
                    ),
                    routes: [
                      {
                        path: MODEL_CREATE_OVERVIEW_PATH,
                        exact: true,
                        component: withErrorBoundary(
                          restricted({
                            component: EntityOverview,
                            authoritiesRequired: [ROLE_MODEL_CREATE],
                          })
                        ),
                      },
                      {
                        component: RouterRedirect404,
                      },
                    ],
                  },
                  {
                    path: MODEL_PATH,
                    component: withErrorBoundary(
                      restricted({
                        component: Entity,
                        authoritiesRequired: [ROLE_MODEL_VIEW],
                      })
                    ),
                    routes: [
                      {
                        path: MODEL_OVERVIEW_PATH,
                        exact: true,
                        component: withErrorBoundary(EntityOverview),
                      },
                      {
                        component: RouterRedirect404,
                      },
                    ],
                  },
                  {
                    component: RouterRedirect404,
                  },
                ],
              },
              {
                component: RouterRedirect404,
              },
            ],
          },
          {
            path: VALIDATION_COMMITTEES_PATH,
            component: withErrorBoundary(Entities),
            routes: [
              {
                path: VALIDATION_COMMITTEES_OVERVIEW_PATH,
                exact: true,
                component: withErrorBoundary(
                  restricted({
                    component: EntitiesOverview,
                    authoritiesRequired: [ROLE_VALIDATIONCOMMITTEE_VIEW],
                  })
                ),
              },
              {
                path: VALIDATION_COMMITTEES_TYPE_PATH,
                component: withErrorBoundary(EntityType),
                routes: [
                  {
                    path: VALIDATION_COMMITTEES_TYPE_OVERVIEW_PATH,
                    exact: true,
                    component: withErrorBoundary(
                      restricted({
                        component: EntityTypeOverview,
                        authoritiesRequired: [
                          ROLE_VALIDATIONCOMMITTEETYPE_VIEW,
                        ],
                      })
                    ),
                  },
                  {
                    path: VALIDATION_COMMITTEE_CREATE_PATH,
                    component: withErrorBoundary(
                      restricted({
                        component: Entity,
                        authoritiesRequired: [ROLE_VALIDATIONCOMMITTEE_CREATE],
                      })
                    ),
                    routes: [
                      {
                        path: VALIDATION_COMMITTEE_CREATE_OVERVIEW_PATH,
                        exact: true,
                        component: withErrorBoundary(
                          restricted({
                            component: EntityOverview,
                            authoritiesRequired: [
                              ROLE_VALIDATIONCOMMITTEE_CREATE,
                            ],
                          })
                        ),
                      },
                      {
                        component: RouterRedirect404,
                      },
                    ],
                  },
                  {
                    path: VALIDATION_COMMITTEE_PATH,
                    component: withErrorBoundary(Entity),
                    routes: [
                      {
                        path: VALIDATION_COMMITTEE_OVERVIEW_PATH,
                        exact: true,
                        component: withErrorBoundary(
                          restricted({
                            component: EntityOverview,
                            authoritiesRequired: [
                              ROLE_VALIDATIONCOMMITTEE_VIEW,
                            ],
                          })
                        ),
                      },
                      {
                        component: RouterRedirect404,
                      },
                    ],
                  },
                  {
                    component: RouterRedirect404,
                  },
                ],
              },
              {
                component: RouterRedirect404,
              },
            ],
          },
          {
            path: VALIDATIONS_PATH,
            component: withErrorBoundary(Entities),
            routes: [
              {
                path: VALIDATIONS_OVERVIEW_PATH,
                exact: true,
                component: withErrorBoundary(
                  restricted({
                    component: EntitiesOverview,
                    authoritiesRequired: [ROLE_VALIDATION_VIEW],
                  })
                ),
              },
              {
                path: VALIDATIONS_TYPE_PATH,
                component: withErrorBoundary(EntityType),
                routes: [
                  {
                    path: VALIDATIONS_TYPE_OVERVIEW_PATH,
                    exact: true,
                    component: withErrorBoundary(
                      restricted({
                        component: EntityTypeOverview,
                        authoritiesRequired: [ROLE_VALIDATIONTYPE_VIEW],
                      })
                    ),
                  },
                  {
                    path: VALIDATION_CREATE_PATH,
                    component: withErrorBoundary(
                      restricted({
                        component: Entity,
                        authoritiesRequired: [ROLE_VALIDATION_CREATE],
                      })
                    ),
                    routes: [
                      {
                        path: VALIDATION_CREATE_OVERVIEW_PATH,
                        exact: true,
                        component: withErrorBoundary(
                          restricted({
                            component: EntityOverview,
                            authoritiesRequired: [ROLE_VALIDATION_CREATE],
                          })
                        ),
                      },
                      {
                        component: RouterRedirect404,
                      },
                    ],
                  },
                  {
                    path: VALIDATION_PATH,
                    component: withErrorBoundary(Entity),
                    routes: [
                      {
                        path: VALIDATION_OVERVIEW_PATH,
                        exact: true,
                        component: withErrorBoundary(
                          restricted({
                            component: EntityOverview,
                            authoritiesRequired: [ROLE_VALIDATION_VIEW],
                          })
                        ),
                      },
                      {
                        component: RouterRedirect404,
                      },
                    ],
                  },
                  {
                    component: RouterRedirect404,
                  },
                ],
              },
              {
                component: RouterRedirect404,
              },
            ],
          },
          {
            component: RouterRedirect404,
          },
        ],
      },
      {
        component: RouterRedirect404,
      },
    ],
  },
];
