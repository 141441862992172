import React, { useEffect, useState } from 'react';

import OptimizedUploadRow from 'components/Uploads/OptimizedUploadRow';

const UploadGroup = ({
  group,
  files,
  isUploading,
  onUploadProcessed,
  onGroupProcessed
}) => {

  const [uploadingIndex, setUploadingIndex] = useState(0);
  const [rewrite, setRewrite] = useState({isRewrite: false, isApplicableForAll: false});

  useEffect(() => {
    if (uploadingIndex >= files.length) {
      onGroupProcessed(group.groupId);
    }
  }, [uploadingIndex]);

  const handleUploadProcessed = (status) => {
    onUploadProcessed(status);
    setUploadingIndex((prevState) => (prevState + 1));
  };

  const handleConflictResolve = (isRewrite, isApplicableForAll) => {
    setRewrite({isRewrite, isApplicableForAll});
  }

  return (
    <>
      {files.map((taggedFile, idx) =>
        <OptimizedUploadRow
          key={idx}
          fileId={taggedFile.id}
          group={group}
          file={taggedFile.file}
          isUploading={isUploading && idx === uploadingIndex}
          rewrite={rewrite}
          onConflictResolve={handleConflictResolve}
          onUploadProcessed={handleUploadProcessed}
        />,
      )
      }
    </>
  );
};

export default UploadGroup;
