import React, { Component } from "react";
import { withRouter } from "react-router";
import Button from "../Buttons/Button";
import { Field, reduxForm } from "redux-form";
import { RequiredValidator } from "../Form/Fields/FieldValidators";
import SelectField from "../Form/Fields/SelectField";
import RevisionMenuItem from "../Model/RevisionMenuItem";
import {
  MenuItem,
  DialogContent,
  Dialog,
  DialogActions
} from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";

class ChooseModelRevisionDialogForm extends Component {
  render() {
    const { handleSubmit, handleClose, open, model } = this.props;
    if (!model || !model.revisions) {
      return null;
    }

    return (
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleSubmit}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleClose}
          >
            Choose a revision to be assigned
          </CloseableDialogTitle>
          <DialogContent>
            <div>
              <Field
                label={"Model revision"}
                name="revisionId"
                component={SelectField}
                validate={[RequiredValidator]}
                ref="revisionId"
              >
                {model.revisions.map(function(revision, idx) {
                  return (
                    <MenuItem key={idx} value={revision.id}>
                      <RevisionMenuItem revision={revision} />
                    </MenuItem>
                  );
                })}
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              testid="chooseRevision"
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {"Choose the revision"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default withRouter(
  reduxForm({
    form: "addvalidationdialog",
    onSubmitFail: scrollToFirstError
  })(ChooseModelRevisionDialogForm)
);
