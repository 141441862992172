import React, { Component } from 'react';
import FormControl from './FormControl';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/ext/language_tools'
import 'brace/mode/drools';
import 'brace/theme/github';

class CodeInputField extends Component {
    onChange = (code) => {
        const { change, meta, input } = this.props;
        change(meta.form, input.name, code);
    };

    render() {
        const { meta, input, placeholder } = this.props;
        return (
            <FormControl { ...this.props }>
                <AceEditor
                    placeholder={placeholder}
                    mode="drools"
                    theme="github"
                    name="droolscode"
                    width="100%"
                    height="400px"
                    style={{
                        fontFamily: 'monospace'
                    }}
                    onChange={this.onChange}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={input.value}
                    showLineNumbers
                    enableSnippets
                    enableBasicAutocompletion
                    enableLiveAutocompletion
                    tabSize={4} />
            </FormControl>
        );
    }
}

function mapStateToProps(state) { return {} }
export default connect(mapStateToProps, { change })(CodeInputField);