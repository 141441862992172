import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import 'whatwg-fetch';
import 'babel-polyfill';
import { render } from 'react-dom';
import Promise from 'promise-polyfill';
import InitConfigWrapper from 'containers/InitConfigWrapper';

if (!window.Promise) {
  window.Promise = Promise;
}

render(
  <InitConfigWrapper />,
  document.getElementById('app-container'),
);
