import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ButtonLoading from "components/Buttons/ButtonLoading";
import {Form, reduxForm, getFormValues} from "redux-form";
import WorkflowFields from "components/Workflow/JBPMForm/fields";
import WorkflowDiagram from "components/Workflow/Diagram";
import AuditTrail from "components/AuditTrails/ListJBPM";
import {
  getDeepTask,
  openTask
} from "actions/Tasks";
import { getWorkflowDiagramRedux } from "actions/Workflow";
import { getAuditTrails } from "actions/Tasks";
import { initializeForm } from "actions";
import {
  TAB_TASK_FORM,
  TAB_TASK_AUDIT_TRAIL,
  TAB_WORKFLOW_DIAGRAM
} from "constants/Routes";
import { WORKFLOW_AUDIT_TRAIL } from "constants/Features";
import { withRouter } from "react-router";
import { getCurrentURLParams } from "api/GUI";
import qs from "querystring";
import {
  DialogContent,
  Dialog,
  Tab,
  Tabs,
} from "@material-ui/core";
import { scrollToFirstError } from "api/Error";
import {
  TASK_ACTION_CLAIM,
  TASK_ACTION_COMPLETE, TASK_ACTION_START,
  TASK_STATUS_IN_PROGRESS
} from "constants/Task";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import TaskInfo from "components/Tasks/TaskDialog/TaskInfo";
import {isFeaturesAuthorized} from "api/Authorities";
import {combineFieldsAndVariables} from "api/Workflow";

let ResolveTaskForm = ({
                              features,
                              formValues,
                              getAuditTrails,
                              getDeepTask,
                              getWorkflowDiagram,
                              handleSubmit,
                              history,
                              initializeForm,
                              location,
                              me,
                              onClose,
                              onExecuteAction,
                              open,
                              openTask,
                              task,
                            }) => {
  const [value, setValue] = useState(TAB_TASK_FORM);
  const query = getCurrentURLParams(location.search);

  useEffect(()=>{
    if (
      query &&
      query["task"] &&
      query["taskCategory"] &&
      (!task || query["task"] !== task.taskId) &&
      !open
    ) {
      openTask({
        taskId: query["task"],
        owningEntity: {
          category: query["taskCategory"]
        }
      });
    }
  },[]);

  useEffect(()=>{
    if(task){
      initializeForm("resolvetask", combineFieldsAndVariables(task));
    }
  },[task && task.variables]);

  useEffect(()=>{
    if(task){
      getDeepTask(task);
    }
  },[task && task.taskId]);

  useEffect(()=>{
    if(task){
      getWorkflowDiagram(task.workflowId, task.owningEntity);
    }
  },[task && task.workflowId]);

  useEffect(()=>{
    setValue(TAB_TASK_FORM);
  },[me]);

  useEffect(()=>{
    query["task"] = (open && task) ? task.taskId : null;
    query["taskCategory"] = (open && task && task.owningEntity) ? task.owningEntity.category : null;
    const searchString = qs.stringify(query);
    history.push({
      ...location,
      search: searchString
    });
    if(open){
      setValue(TAB_TASK_FORM);
    }
  },[open]);

  const handleChange = (event, value) => {
    setValue(value)
  };

  const executeAction = action => {
    onExecuteAction(action, formValues);
  };

  const onRefreshAuditTrails = (firstResult, maxResultCount) => {
    getAuditTrails(task, firstResult, maxResultCount);
  };

  if (!task || task === {}) {
    return null;
  }

  let form;
  if (task.status === TASK_STATUS_IN_PROGRESS && task.form) {
    form = <WorkflowFields form={task.form} />;
  }

  return (
    <Dialog
      testid="resolvetask"
      PaperProps={{
        style: { height: "100%" }
      }}
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      open={open}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
          minHeight: 0
        }}
      >
        <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>{task.name}</CloseableDialogTitle>
        <TaskInfo task={task}/>

        <Tabs value={value} onChange={handleChange}>
          {TABS.map(({ label, value, featuresRequired }, idx) => {
            return (isFeaturesAuthorized(features, featuresRequired) &&
                <Tab
                  key={idx}
                  label={label}
                  value={value}
                />);
          })}
        </Tabs>
        <Form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "auto"
          }}
        >
          <DialogContent style={{ padding: "16px 24px 10px", flexGrow: 1 }}>
            { value === TAB_TASK_FORM &&
              <div className={"scrollable-container"}>
                <div className={"scrollable-content"}>
                  {form}
                </div>
                <div className={"scrollable-footer"} style={{ display:"flex", justifyContent: "flex-end", padding: "10px 0 0"}}>
                  {task.possibleActions &&
                  task.possibleActions.map((possibleAction, idx) => {
                    return (
                      <ButtonLoading
                        variant={[TASK_ACTION_COMPLETE, TASK_ACTION_CLAIM, TASK_ACTION_START].includes(possibleAction)? "contained":"outlined"}
                        // variant="outlined"
                        loading={task.executing_action === possibleAction}
                        loadingTitle={
                          "Resolving the action " + task.executing_action
                        }
                        disabled={
                          task.executing_action &&
                          task.executing_action !== possibleAction
                        }
                        key={idx}
                        onClick={() => {
                          executeAction(possibleAction);
                        }}
                        color="primary"
                        style={{margin:"0 0 10px 5px"}}
                      >
                        {possibleAction}
                      </ButtonLoading>
                    );
                  })}
                </div>
              </div>
            }
            {value === TAB_TASK_AUDIT_TRAIL && (
              <AuditTrail
                entity={task}
                onRefresh={onRefreshAuditTrails}
              />
            )}
            {value === TAB_WORKFLOW_DIAGRAM &&
            <div className={"scrollable-container"}>
              <div className={"scrollable-content"}>
                <WorkflowDiagram open={open} workflow={task} />
              </div>
            </div>
            }
          </DialogContent>
        </Form>
      </div>
    </Dialog>
  );
};

const TABS = [
  {
    value: TAB_TASK_FORM,
    label: "Task form"
  },
  {
    value: TAB_TASK_AUDIT_TRAIL,
    label: "History",
    featuresRequired: [WORKFLOW_AUDIT_TRAIL]
  },
  {
    value: TAB_WORKFLOW_DIAGRAM,
    label: "Workflow diagram"
  }
];

const mapStateToProps = (state, ownProps) => {
  const task = state.tasks.current;
  return {
    task: state.tasks.current,
    open: !!state.tasks.open,
    initialValues: combineFieldsAndVariables(task),
    diagram: task ? task.diagram : null,
    me: state.auth.me,
    features: state.features.list,
    formValues: getFormValues("resolvetask")(state)
  };
};

ResolveTaskForm = withRouter(
  reduxForm({
    form: "resolvetask",
    onSubmitFail: scrollToFirstError
  })(ResolveTaskForm)
);

ResolveTaskForm =
  connect(
    mapStateToProps,
    {
      openTask,
      initializeForm,
      getWorkflowDiagram: getWorkflowDiagramRedux,
      getDeepTask,
      getAuditTrails
    }
  )(ResolveTaskForm);

export default ResolveTaskForm;