import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid/index";
import IconButton from "components/Buttons/IconButton";
import {Close} from "@material-ui/icons/index";
import {DialogTitle} from "@material-ui/core";

class CloseableDialogTitle extends Component {
    render() {
        const { handleClose, children } = this.props;
        return (
            <DialogTitle>
                <Grid container>
                    <Grid item xs={11}>
                        { children }
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton className="closeDialog" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
        );
    }
}

export default CloseableDialogTitle;
