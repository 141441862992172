import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core';
import FolderOpen from '@material-ui/icons/FolderOpen';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';

import IconButton from 'components/Buttons/IconButton';
import Status from 'components/Uploads/Statuses/Status';
import {Link} from "react-router-dom";
import {getLinkToAttachmentPath} from "api/Entity";

const IconButtonStyle = {
  width: '30px',
  height: '30px',
  color: '#607d8b',
};

const useStyles = makeStyles(theme => ({
  success: {
    fontSize: 48,
    color: theme.palette.success.main,
  },
  info: {
    fontSize: 48,
    color: theme.palette.grey,
  },
}));

const EndStatus = ({ group }) => {
  const classes = useStyles();
  const [href, setHref] = useState('');

  useEffect(()=>{
    const entity = group.entityDetail;
    const href = getLinkToAttachmentPath(
      entity.entityType,
      entity.ownerTypeId,
      entity.ownerId,
      group.prefix,
      group.platform
    );
    setHref(href);
  },[group]);

  const getSecondaryEl = () => {
    return (
      <Tooltip title={'Go to location'}>
        <IconButton style={IconButtonStyle}
                    aria-label="go-to-location"
                    component={Link}
                    to={href}
        >
          <FolderOpen className={classes.info}/>
        </IconButton>
      </Tooltip>
    );
  };

  const primaryEl = <CheckCircle className={classes.success}/>;
  const secondaryEl = getSecondaryEl();

  return (
    <Status
      progress={null}
      variant={'static'}
      primaryEl={primaryEl}
      secondaryEl={secondaryEl}
    />
  );
};

export default EndStatus;
