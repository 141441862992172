import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { TAB_LICENSE_INFO } from "../../../../constants/Routes";
import DrawerView from "components/Drawer";
import DrawerTabs from "components/Drawer/DrawerTabs";
import Tabs from "components/TabContainer/Tabs";
import DrawerContent from "components/Drawer/DrawerContent";
import LicenseInfo from "components/License/LicenseInfo";
import { getLicense } from "actions/License";
import APPCONFIG from "../../../../constants/Config";

class LicenseOverview extends Component {
  state = {
    value: TAB_LICENSE_INFO
  };

  refreshTimeout = null;

  componentWillUpdate(nextProps, nextState) {
    const { value } = nextState;
    if (this.state.value !== value) {
      this.refreshTab(nextProps, nextState);
    }
  }

  componentDidMount() {
    this.refreshTab(this.props, this.state);
  }
  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  refreshTab = (props, state) => {
    const { value } = state;
    const { getLicense } = props;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTab(this.props, this.state);
    }, APPCONFIG.REFRESH_TIMEOUT);

    switch (value) {
      case TAB_LICENSE_INFO:
        getLicense();
        break;
    }
  };

  handleChange = value => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    let currentTab;

    switch (value) {
      case TAB_LICENSE_INFO:
        currentTab = <LicenseInfo />;
        break;
    }

    return (
      <DrawerView>
        <DrawerTabs>
          <Tabs tabs={TABS} value={value} onChange={this.handleChange} />
        </DrawerTabs>
        <DrawerContent>{currentTab}</DrawerContent>
      </DrawerView>
    );
  }
}

const TABS = [
  {
    value: TAB_LICENSE_INFO,
    authoritiesRequired: [],
    featuresRequired: [],
    label: "License"
  }
];

const styles = theme => ({});

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { getLicense }
  )(LicenseOverview)
);
