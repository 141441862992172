import React from "react";
import TabCentered from "components/TabContainer/TabCentered";
import CompletedTasksPanel from "components/Tasks/TasksPanel/CompletedTasksPanel";
import ActiveTasksList from "components/Tasks/TasksPanel/ActiveTasksList";

const TasksPanel = ({tasks, showEntity, withWorkflowContext}) => {
    if (!tasks || tasks.length === 0) {
      return (
        <TabCentered>
          <div style={{ padding: "20px" }}>No task found</div>
        </TabCentered>
      );
    }

    return <>
        <ActiveTasksList tasks={tasks} showEntity={showEntity} withWorkflowContext={withWorkflowContext}/>
        <CompletedTasksPanel tasks={tasks} showEntity={showEntity} withWorkflowContext={withWorkflowContext}/>
      </>;
};

export default TasksPanel;
