import reduceAttachments from "../../api/ReduceAttachments";
import reduceAttributes from "../../api/ReduceAttributes";
import reduceAuditTrails from "../../api/ReduceAuditTrails";
import reduceWorkflows from "../../api/ReduceWorkflows";
import reduceCreateWorkflows from "../../api/ReduceCreateWorkflows";
import reduceDeleteEntity from "../../api/ReduceDeleteEntity";
import { MODEL_REVISION_TYPE } from "../../api/Entity";

export default function(state = {}, action) {
  return {
    ...state,
    current: reduceDeleteEntity(
      reduceCreateWorkflows(
        reduceWorkflows(
          reduceAuditTrails(
            reduceAttributes(
              reduceAttachments(state.current, action),
              action,
              MODEL_REVISION_TYPE
            ),
            action
          ),
          action
        ),
        action
      ),
      action
    )
  };
}
