import React, { useState } from "react";
import { connect } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions/index";
import Dialog from "@material-ui/core/Dialog/index";
import { Field, reduxForm } from "redux-form";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { pushNotification } from "actions/notifications";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import {
  NonEmptyStringValidator,
  RequiredValidator
} from "components/Form/Fields/FieldValidators";
import { getApiError, scrollToFirstError } from "../../api/Error";
import { ROLE_ODATAACCESSCODE_CREATE } from "../../constants/Authorities";
import {
  createODataAccessCode,
  createODataAccessCodeAdmin
} from "actions/OData";

const useStyles = makeStyles(theme => ({
  field: {
    marginTop: theme.spacing(2)
  }
}));
const CreateAccessCodeForm = reduxForm({
  form: "createaccesscodeform",
  onSubmitFail: scrollToFirstError
})(({ handleSubmit, error_creating, creating, onClose }) => {
  const classes = useStyles({});
  return (
    <form onSubmit={handleSubmit}>
      <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
        Create an access code
      </CloseableDialogTitle>
      <DialogContent>
        <div className={classes.field}>
          <Field
            label="User"
            name="userId"
            component={AutoSuggestSelectField}
            validate={[RequiredValidator, NonEmptyStringValidator]}
            apiUrl="/api/users/lookup"
            maxResultNumber={10}
            withEmptyOpt={true}
          />
        </div>
        <div className="error">{error_creating}</div>
      </DialogContent>
      <DialogActions>
        <ButtonLoading
          testid="submittoken"
          loading={creating}
          loadingTitle="Creating the token"
          className="color-primary"
          type="submit"
          variant="outlined"
          color="primary"
        >
          {"Create new token"}
        </ButtonLoading>
      </DialogActions>
    </form>
  );
});

const CreateODataAccessCodeButton = ({
  isAdmin,
  onCreate,
  pushNotification,
  openDialog,
  setOpenDialog
}) => {
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);

  const onClick = () => {
    if (isAdmin) {
      setOpenDialog(true);
    } else {
      createAccessCode();
    }
  };

  const handleCreateAccessCode = ({ userId }) => {
    createAccessCode(userId);
  };

  const createAccessCode = async userId => {
    setCreating(true);
    setError(null);
    try {
      onCreate(
        (await (isAdmin
          ? createODataAccessCodeAdmin(userId)
          : createODataAccessCode())).data
      );
      setOpenDialog(false);
    } catch (e) {
      let message = getApiError(e);
      setError(message);
      if (message) {
        message = ": " + message;
      }
      pushNotification(
        "error",
        "error",
        "Creating access link failed" + message
      );
    }
    setCreating(false);
  };

  return (
    <>
      <ButtonLoading
        loading={creating}
        loadingTitle="Creating the access link"
        onClick={onClick}
        authoritiesRequired={isAdmin ? [ROLE_ODATAACCESSCODE_CREATE] : []}
        variant="outlined"
        testid="addlink"
        color="primary"
      >
        Create an access link
      </ButtonLoading>
      <Dialog
        onClose={() => setOpenDialog(false)}
        open={openDialog}
        fullWidth
        maxWidth="sm"
      >
        <CreateAccessCodeForm
          onSubmit={handleCreateAccessCode}
          onClose={() => setOpenDialog(false)}
          error_creating={error}
          creating={creating}
        />
      </Dialog>
    </>
  );
};

export default connect(
  () => ({}),
  { pushNotification }
)(CreateODataAccessCodeButton);
