import {
  UPDATE_SECURITY_LEVEL,
  UPDATING_SECURITY_LEVEL,
  END_UPDATING_SECURITY_LEVEL,
  ERROR_UPDATING_SECURITY_LEVEL
} from "../constants/ActionTypes";

export default function(entity, action) {
  if (!entity) {
    return entity;
  }

  let ownerId = action.ownerId;
  const newSecurityLevel = action.securityLevel;

  if (entity.id !== ownerId) {
    return entity;
  }

  switch (action.type) {
    case UPDATE_SECURITY_LEVEL:
      return {
        ...entity,
        securityLevels: [newSecurityLevel]
      };
    case UPDATING_SECURITY_LEVEL:
      return {
        ...entity,
        updating_security_level: true,
        error_security_level: undefined
      };
    case END_UPDATING_SECURITY_LEVEL:
      return {
        ...entity,
        updating_security_level: false
      };
    case ERROR_UPDATING_SECURITY_LEVEL:
      return {
        ...entity,
        error_security_level: action.error
      };
  }
  return entity;
}
