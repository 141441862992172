import React, { useState } from 'react';

import { connect } from "react-redux";
import { Edit } from "@material-ui/icons";

import ButtonLoading from "../Buttons/ButtonLoading";
import { updateFolder } from "actions/Folders";
import { ROLE_SHARED_FOLDER_MANAGE } from 'constants/Authorities';
import UpdateFolderDialogForm from "components/Folders/UpdateFolderDialogForm";

const UpdateButton = ({ folder, updateFolder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const update = (form) => {
    updateFolder(form);
    closeDialog();
  };

  if (!folder) {
    return null;
  }

  return (
    <>
      <ButtonLoading
        authoritiesRequired={[ROLE_SHARED_FOLDER_MANAGE]}
        tooltipPlacement="left"
        tooltipTitle="Update the folder"
        loading={folder.updating}
        loadingTitle="Updating the folder"
        loadingSize={22}
        style={{
          width: "100%",
          justifyContent: "flex-start"
        }}
        onClick={openDialog}
        color="primary"
      >
        <Edit style={{ marginRight: 15 }} />
        Update the folder
      </ButtonLoading>
      <UpdateFolderDialogForm
        onClose={closeDialog}
        open={isOpen}
        folder={folder}
        onSubmit={update}
      />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  { updateFolder }
)(UpdateButton);
