import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../Buttons/ButtonLoading";
import Grid from "../Grid";
import { Field, Form, formValues } from "redux-form";
import {
  ROLE_SAVEDSEARCH_CREATE,
  ROLE_SAVEDSEARCH_EDIT
} from "../../constants/Authorities";
import ChipsField from "components/Form/Fields/ChipsField";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import {
  PROCESS_VARIABLES_SEARCH,
  SAVED_SEARCH
} from "../../constants/Features";
import { ExpandMore, PlayArrow } from "@material-ui/icons";

import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getEntityLabel, MODEL_USAGE_TYPE } from "../../api/Entity";
import RestrictedComponent from "components/RestrictedComponent";
import AttributesFilterContainer from "components/Search/AttributesFilterContainer";
import {getSortingByLabel, getSortingByName} from "api/Sort";


class FilterModelsForm extends Component {
  state = {
    availableAttributes: []
  };

  constructor(props) {
    super(props);
    this.state.availableAttributes = this.getAvailableAttributes(props);
    this.state.availableProcessVariables = this.getAvailableProcessVariables(
      props
    );
  }

  componentWillReceiveProps(nextProps) {
    const { modelUsageTypeIds, typeIds } = nextProps;
    if (
      modelUsageTypeIds !== this.props.modelUsageTypeIds ||
      typeIds !== this.props.typeIds
    ) {
      this.setState({
        availableAttributes: this.getAvailableAttributes(nextProps)
      });
    }
  }

  getAvailableAttributesFromTypes = (configTypes, selectedTypes) => {
    let availableAttributes = {},
      attributeAlreadyAdded = [];
    for (let i = 0; configTypes && i < configTypes.length; i++) {
      const configType = configTypes[i];
      let addAttributes = false;
      if (!selectedTypes || selectedTypes.length === 0) {
        addAttributes = true;
      } else {
        for (let j = 0; j < selectedTypes.length; j++) {
          const selectedType = selectedTypes[j];
          if (selectedType === configType.id) {
            addAttributes = true;
            break;
          }
        }
      }
      if (addAttributes) {
        for (
          let j = 0;
          configType.attributes && j < configType.attributes.length;
          j++
        ) {
          const attribute = configType.attributes[j];
          if (attributeAlreadyAdded.includes(attribute.id)) {
            continue;
          }
          if (!availableAttributes[attribute.applicableForEntity]) {
            availableAttributes[attribute.applicableForEntity] = [];
          }
          availableAttributes[attribute.applicableForEntity].push(attribute);
          attribute.id && attributeAlreadyAdded.push(attribute.id);
        }
      }
    }
    return availableAttributes;
  };

  getAvailableAttributes = props => {
    const { config, modelUsageTypeIds, typeIds } = props;
    if (!config) {
      return [];
    }
    let sections = [];
    const attributesMapped = this.getAvailableAttributesFromTypes(
      config.entityTypes,
      typeIds
    );
    const attributesMappedKeys = Object.keys(attributesMapped);
    for (let i = 0; i < attributesMappedKeys.length; i++) {
      const entityType = attributesMappedKeys[i];
      sections.push({
        title: getEntityLabel(entityType),
        suggestions: attributesMapped[entityType]
      });
    }
    const modelUsageTypeAttributes = this.getAvailableAttributesFromTypes(
      config.modelUsageTypes,
      modelUsageTypeIds
    );
    if (
      modelUsageTypeAttributes &&
      modelUsageTypeAttributes[MODEL_USAGE_TYPE] &&
      modelUsageTypeAttributes[MODEL_USAGE_TYPE].length > 0
    ) {
      sections.push({
        title: "Model Usage",
        suggestions: modelUsageTypeAttributes[MODEL_USAGE_TYPE]
      });
    }
    return sections;
  };

  getAvailableProcessVariables = props => {
    const { configProcessVariables } = props;
    let sections = [];
    for (let i = 0; i < configProcessVariables.length; i++) {
      const { processId, variables } = configProcessVariables[i];
      if (variables.length > 0) {
        sections.push({
          title: processId,
          suggestions: variables.map(({ name, type }) => ({
            label: name,
            name: `${processId}:${name}`,
            type
          }))
        });
      }
    }
    return sections;
  };

  saveSearch = () => {
    const { onSaveSearch } = this.props;
    const values = this.refs.form.props._reduxForm.getValues();
    onSaveSearch(values);
  };

  render() {
    const {
      handleSubmit,
      initialValues,
      runningSearch,
      formName,
      config,
      errorServer,
      updating,
      isNew,
      disabled,
      onCloseEditing,
      onClearFilter,
      configProcessVariables,
      securityLevels
    } = this.props;
    const { availableAttributes, availableProcessVariables } = this.state;
    return (
      <Form ref={"form"} onSubmit={handleSubmit}>
        <div style={{ margin: "10px 0 30px 0" }}>
          {// Render only if there is more than one type
            securityLevels && securityLevels.length > 1 ? (
              <ExpansionPanel defaultExpanded={initialValues.securityLevelIds && initialValues.securityLevelIds.length > 0}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">Filter by security levels</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div>
                    <Field
                      label={""}
                      name="securityLevelIds"
                      disabled={disabled}
                      multiple
                      component={AutoSuggestSelectField}
                      options={
                        securityLevels
                          .sort(getSortingByLabel)
                          .map(securityLevel => {
                            return {
                              id: securityLevel.id,
                              label: securityLevel.label
                            }
                          })
                      }
                      validate={[]}
                      ref="securityLevelIds"
                    />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ) : null }

          {// Render only if there is more than one type
            config && config.entityTypes && config.entityTypes.length > 1 ? (
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">Filter by types</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                <Field
                  label={""}
                  name="typeIds"
                  disabled={disabled}
                  multiple
                  component={ChipsField}
                  options={
                    config &&
                    config.entityTypes &&
                    config.entityTypes
                      .sort(getSortingByName)
                      .map(entityType => {
                      return {
                        value: entityType.id,
                        label: entityType.name
                      };
                    })
                  }
                  validate={[]}
                  ref="typeIds"
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          ) : null }

          {config && config.modelUsageTypes ? (
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6">
                  Filter by model usage types
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div>
                  <Field
                    label={""}
                    name="modelUsageTypeIds"
                    disabled={disabled}
                    multiple
                    component={ChipsField}
                    options={
                      config &&
                      config.modelUsageTypes &&
                      config.modelUsageTypes
                        .sort(getSortingByName)
                        .map(entityType => {
                        return {
                          value: entityType.id,
                          label: entityType.name
                        };
                      })
                    }
                    validate={[]}
                    ref="modelUsageTypeIds"
                  />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null}
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">Filter by attributes</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <AttributesFilterContainer
                disabled={disabled}
                availableAttributes={availableAttributes}
                formName={formName}
                name="attributeValueFilter"
                title="Filter by model attribute"
                initialValues={initialValues}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {configProcessVariables && configProcessVariables.length > 0 ? (
          <RestrictedComponent featuresRequired={[PROCESS_VARIABLES_SEARCH]}>
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6">
                  Filter by process variables
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <AttributesFilterContainer
                  disabled={disabled}
                  availableAttributes={availableProcessVariables}
                  formName={formName}
                  label="Process variable"
                  name="workflowVariableValueFilter"
                  initialValues={initialValues}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </RestrictedComponent>
          ) : null}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <ButtonLoading
              loading={runningSearch}
              loadingTitle="Search running"
              style={{ width: "100%" }}
              className="color-primary"
              type="submit"
              testid="runSearch"
              variant="outlined"
              color="primary"
            >
              <PlayArrow style={{ verticalAlign: "middle" }} /> Run
            </ButtonLoading>
          </Grid>
          <Grid item xs={4} featuresRequired={[SAVED_SEARCH]}>
            <ButtonLoading
              loading={updating}
              loadingTitle="Updating filters"
              disabled={disabled}
              authoritiesRequired={[
                isNew ? ROLE_SAVEDSEARCH_CREATE : ROLE_SAVEDSEARCH_EDIT
              ]}
              style={{ width: "100%" }}
              variant="outlined"
              color="primary"
              testid="saveSearch"
              onClick={this.saveSearch}
            >
              Save
            </ButtonLoading>
          </Grid>
          <Grid item xs={3}>
            <ButtonLoading
              loadingTitle={isNew ? "Clearing filter" : "Canceling filter editing"}
              style={{ width: "100%" }}
              variant="outlined"
              color="primary"
              onClick={isNew ? (() => onClearFilter(formName)): onCloseEditing}
            >
              {isNew ? "Clear" : "Close tab"}
            </ButtonLoading>
          </Grid>
        </Grid>
        <div className="error">{errorServer}</div>
      </Form>
    );
  }
}

const styles = theme => ({
  selectItem: {},
  selectItemSelected: {
    backgroundColor: "#607d8b !important",
    color: "white"
  }
});

const mapStateToProps = (state, ownProps) => {
  const { entityType } = ownProps;
  return {
    configProcessVariables: state.filters.config_process_variables,
    config: state.filters.config,
    enumEntries: state.enumEntries.list,
    runningSearch: state.filters.running_filters ? state.filters.running_filters[entityType] : undefined,
    updating: state.filters.current_filters? state.filters.current_filters.updating : false,
    securityLevels: state.auth.me.securityLevels
  };
};

export default withStyles(styles)(
  formValues("modelUsageTypeIds", "typeIds", "securityLevelIds")(
    connect(
      mapStateToProps,
      {}
    )(FilterModelsForm)
  )
);
