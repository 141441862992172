import React, { Component } from "react";
import Button from "../Buttons/Button";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { RequiredValidator } from "../Form/Fields/FieldValidators";
import { areAttachmentsEqual } from "../../api/Attachments";
import { initializeForm } from "../../actions";
import InputField from "components/Form/Fields/InputField";
import { withStyles, DialogContent, Dialog } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";
import DialogActions from "@material-ui/core/DialogActions";

class EditName extends Component {
  componentWillReceiveProps(nextProps) {
    const { attachmentToUpdate } = nextProps;
    if (
      (!this.props.attachmentToUpdate && attachmentToUpdate) ||
      (this.props.attachmentToUpdate &&
        attachmentToUpdate &&
        !areAttachmentsEqual(attachmentToUpdate, this.props.attachmentToUpdate))
    ) {
      const initialValues = {
        name: attachmentToUpdate.label
      };
      nextProps.initializeForm("renameattachmentform", initialValues);
    }
  }
  render() {
    const {
      handleSubmit,
      handleClose,
      open,
      classes,
      attachmentToUpdate
    } = this.props;
    const actionTitle =
      attachmentToUpdate && attachmentToUpdate.isFolder
        ? "Rename folder"
        : "Edit attachment";

    return (
      <Dialog onClose={handleClose} open={Boolean(open)}>
        <form onSubmit={handleSubmit} style={{ padding: "0 20px" }}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleClose}
          >
            {actionTitle}
          </CloseableDialogTitle>
          <DialogContent>
            <div className={classes.field}>
              <Field
                label={"New name"}
                name="name"
                component={InputField}
                validate={[RequiredValidator]}
                ref="name"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {actionTitle}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const styles = theme => ({
  field: {
    marginBottom: 10,
    width: "300px"
  }
});

EditName = reduxForm({
  form: "renameattachmentform",
  onSubmitFail: scrollToFirstError
})(EditName);

const mapStateToProps = state => {
  return {};
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { initializeForm }
  )(EditName)
);
