import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import moment from "moment";
import TabCentered from "../TabContainer/TabCentered";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { isAuthorized } from "../../api/Authorities";
import { ROLE_SYSTEM_ADMINISTRATION } from "../../constants/Authorities";
import UploadLicenseForm from "components/License/UploadLicenseForm";
import { getLicense, setLicense } from "actions/License";
import LicenseInfoItem from "components/License/LicenseInfoItem";
import Config from "../../constants/Config";

const LicenseInfo = ({
  license_set,
  license,
  setLicense,
  me,
  loading,
  setting
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  useEffect(() => {
    if (license_set) {
      setDialogOpen(false);
    }
  }, [license_set]);

  const isValid = license && license.status && license.status === "VALID";

  const handleUploadLicense = form => {
    if (form && form.newLicense) {
      setLicense(form.newLicense);
    }
  };

  const renderContent = () => {
    if (license) {
      const {
        organization,
        status,
        licenseTypeName,
        environmentTypeName,
        maintenanceSupportUntil,
        maximumSupportedMajorVersion,
        maximumCodeReleaseDate,
        currentModelCount,
        maxModelCount,
        message
      } = license;
      return (
        <div style={{ display: "inline" }}>
          <Typography variant="h4" gutterBottom>
            MGS license
          </Typography>
          <Grid container>
            <LicenseInfoItem name={"License granted to"} value={organization} />
            <LicenseInfoItem
              name={"License status"}
              value={status}
              valueColor={isValid ? "green" : "red"}
            />
            <LicenseInfoItem
              name={"License type"}
              value={licenseTypeName}
              valueColor={isValid ? "green" : "red"}
            />
            <LicenseInfoItem
              name={"Environment type"}
              value={environmentTypeName}
              valueColor={isValid ? "green" : "red"}
            />
            <LicenseInfoItem
              name={"Maintenance support until"}
              value={
                maintenanceSupportUntil &&
                moment(maintenanceSupportUntil).format(Config.dateFormat)
              }
            />
            {maximumCodeReleaseDate && (
              <LicenseInfoItem
                name={"Supports application release before"}
                value={moment(maximumCodeReleaseDate).format(Config.dateFormat)}
              />
            )}
            {maximumSupportedMajorVersion &&
            maximumSupportedMajorVersion !== 0 ? (
              <LicenseInfoItem
                name={"Highest supported major version"}
                value={maximumSupportedMajorVersion}
              />
            ) : null}
            <LicenseInfoItem
              name={"Current number of active models"}
              value={currentModelCount}
            />
            <LicenseInfoItem
              name={"Number of models limited to"}
              value={maxModelCount}
            />
          </Grid>

          <br />
          <Typography component="p">{message}</Typography>
        </div>
      );
    }
    if (loading) {
      return (
        <TabCentered>
          <div style={{ lineHeight: "36px", textAlign: "center" }}>
            <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
              Loading the license
            </span>
          </div>
        </TabCentered>
      );
    }
  };
  return (
    <div className={"scrollable-content"} style={{ padding: "10px 20px", display: "flex" }}>
      {renderContent()}
      {isAuthorized(me, [ROLE_SYSTEM_ADMINISTRATION]) && (
        <Grid container alignItems="flex-end" direction="column">
          <ButtonLoading
            loading={setting}
            loadingTitle="Updating the license"
            className="color-primary"
            onClick={() => setDialogOpen(true)}
            variant="outlined"
            color="primary"
          >
            {"Update license"}
          </ButtonLoading>
        </Grid>
      )}
      <UploadLicenseForm
        open={dialogOpen}
        handleCancel={() => setDialogOpen(false)}
        onSubmit={handleUploadLicense}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.license.loading_license,
    license: state.license.license,
    setting: state.license.setting_license,
    license_set: state.license.license_set,
    me: state.auth.me
  };
};

export default connect(
  mapStateToProps,
  { getLicense, setLicense }
)(LicenseInfo);
