import APPCONFIG from "constants/Config";
import history from "api/GUI";

const querystring = require("querystring");

import {
  AUTH_USER,
  AUTH_ERROR,
  UNAUTH_USER,
  PUSH_NOTIFICATION,
  LOCK_UI,
  UNLOCK_UI,
  LOGINING,
  END_LOGINING,
  LONG_REQUEST_PENDING,
  END_LONG_REQUEST_PENDING,
  END_GETTING_USER,
  GETTING_USER,
} from "constants/ActionTypes";
import axios from "axios";
import { getConfiguration } from "actions/Configuration";
import momentTimezone from "moment-timezone/builds/moment-timezone-with-data";

let pendingRequests = [];
let longPendingRequests = [];

let hasLoggedIn = false;

function handle401(dispatch, keycloak) {
  if (hasLoggedIn) {
    dispatch({
      type: PUSH_NOTIFICATION,
      icon: "error",
      variant: "error",
      message: "You have been logged out",
    });
  }
  hasLoggedIn = false;
  keycloak.logout({ redirectUri: location.href });
}

let locked521 = false;

function handle521(dispatch) {
  if (!locked521) {
    locked521 = true;
    dispatch({
      type: LOCK_UI,
    });
    setTimeout(function() {
      unlock521();
    }, 1000);
  }
}

function unlock521() {
  locked521 = false;
  testServer();
}

function handle406(dispatch) {
  getConfiguration()(dispatch);
}

function testServer() {
  axios
    .get(`/api/heartbeat`, {
      withCredentials: true,
    })
    .then(response => {
      console.log(response.data);
    })
    .catch(err => {
      console.log(err);
    });
}

function checkRoles(user) {
  if (!user || !user.roles || user.roles.length <= 0) {
    hasLoggedIn = false;
    const message = "User has no roles";
    console.error(message);
    history.push({
      pathname: "/403",
      search: "?" + querystring.stringify({ message }),
    });
  }
}

function checkGrantedAuthorities(user, dispatch) {
  if (
    !user ||
    !user.grantedAuthorities ||
    user.grantedAuthorities.length <= 0
  ) {
    hasLoggedIn = false;
    dispatch({
      type: PUSH_NOTIFICATION,
      icon: "error",
      variant: "error",
      message: "Something went wrong, you are not allowed to access the app",
    });
    dispatch({
      type: UNAUTH_USER,
    });
  }
}

function manageSupplementaryResults(supplementaryResult) {
  if (!supplementaryResult || supplementaryResult.success) {
    return [];
  }

  let attributesError = [];
  for (const resultingObject of supplementaryResult.resultingObjects) {
    const label = resultingObject.label;
    attributesError.push({
      id: resultingObject.id,
      constraint: resultingObject.constraint,
      label: label,
    });
  }
  attributesError = attributesError.concat(
    manageSupplementaryResults(supplementaryResult.supplementaryResults)
  );
  return attributesError;
}

function requestStarted(config, dispatch) {
  if (!config.onUploadProgress) {
    pendingRequests.push(config);
    setTimeout(() => {
      const idx = pendingRequests.indexOf(config);
      if (idx !== -1) {
        longPendingRequests.push(config);
        checkLongRequestPending(dispatch);
      }
    }, APPCONFIG.TIMEOUT_LONG_REQUEST);
  }
}

function requestEnded(response, dispatch) {
  if (!response || !response.config) {
    console.log("didn't catch the end of the request: " + response);
    return;
  }
  let idx = pendingRequests.indexOf(response.config);
  if (idx !== -1) {
    pendingRequests.splice(idx, 1);
  }
  idx = longPendingRequests.indexOf(response.config);
  if (idx !== -1) {
    longPendingRequests.splice(idx, 1);
    checkLongRequestPending(dispatch);
  }
}

function checkLongRequestPending(dispatch) {
  if (longPendingRequests && longPendingRequests.length > 0) {
    dispatch({
      type: LONG_REQUEST_PENDING,
    });
  } else {
    dispatch({
      type: END_LONG_REQUEST_PENDING,
    });
  }
}

const appendAuthorization = (config, keycloak) => {
  if (keycloak && keycloak.token) {
    if (typeof config.headers === "undefined" || config.headers === null) {
      config.headers = {};
    }
    config.headers["Authorization"] = `Bearer ${keycloak.token}`;
  }
  return config;
};

const waitForValidToken = async keycloak => {
  let numOfChecks = 0;
  await checkForTokenExpiration(keycloak, numOfChecks);
};

const checkForTokenExpiration = (keycloak, numOfChecks) => {
  return new Promise(resolve => {
    const maxNumberOfChecks = 5;
    numOfChecks++;
    if (numOfChecks > maxNumberOfChecks) {
      resolve();
      return;
    }
    if (!keycloak.isTokenExpired(APPCONFIG.TOKEN_MIN_EXPIRATION_VALIDITY)) {
      resolve();
      return;
    }
    setTimeout(() => {
      checkForTokenExpiration(keycloak, numOfChecks).finally(resolve);
    }, APPCONFIG.TOKEN_MIN_EXPIRATION_VALIDITY * 1000);
  });
};

export function init(keycloak) {
  return function(dispatch) {
    axios.interceptors.request.use(function(config) {
      requestStarted(config, dispatch);
      return config;
    }, Promise.reject);
    axios.interceptors.request.use(
      async config => {
        keycloak && (await waitForValidToken(keycloak));
        return appendAuthorization(config, keycloak);
      },
      Promise.reject,
      {
        synchronous: true,
        runWhen: config => {
          return config.withCredentials !== false;
        },
      }
    );
    axios.interceptors.response.use(
      response => {
        requestEnded(response, dispatch);
        dispatch({
          type: UNLOCK_UI,
        });
        const data = response && response.data;
        if (
          response &&
          response.status !== 200 &&
          response.status !== 201 &&
          response.status !== 202 &&
          (!response.headers ||
            (response.headers.resultstatus !== "FAILED_ATTRIBUTE_VALIDATION" &&
              response.headers.resultstatus !== "FAILURE"))
        ) {
          console.log("Not good status code");
          console.log(response.status);
          try {
            if (data.message) {
              console.log("Error json");
              console.log(data);
              return Promise.reject({
                response,
              });
            }
            if (data.error) {
              console.log("Error json");
              console.log(data);
              return Promise.reject({
                response: {
                  ...response,
                  data: {
                    ...response.data,
                    message: data.error,
                  },
                },
              });
            }
            if (data.errorMessage) {
              console.log("Error json");
              console.log(data);
              return Promise.reject({
                response: {
                  ...response,
                  data: {
                    ...response.data,
                    message: data.errorMessage,
                  },
                },
              });
            }
          } catch (e) {}
          return response;
        }
        return response;
      },
      function(err) {
        requestEnded(err.response, dispatch);
        if (!err.response && err.message) {
          console.log(err.message);
          //request has been canceled
          err.response = err.message.response;
        }
        if (err.response) {
          const proxyResp = err.response;
          const data = proxyResp.data;
          if (proxyResp.status === 504 || proxyResp.status === 521) {
            handle521(dispatch);
            return Promise.reject(err);
          } else {
            dispatch({
              type: UNLOCK_UI,
            });
          }

          if (proxyResp && proxyResp.status === 401) {
            const originalRequest = err.config;
            if (!originalRequest._retry) {
              originalRequest._retry = true;
              setTimeout(() => {
                axios(originalRequest);
              }, APPCONFIG.WAITING_TIME_IN_MS_BEFORE_RETRY_AFTER_401);
            } else {
              handle401(dispatch, keycloak);
              return Promise.reject(err);
            }
          }

          if (proxyResp && proxyResp.status === 406) {
            handle406(dispatch);
            return Promise.reject(err);
          }

          if (
            proxyResp.headers &&
            proxyResp.headers.resultstatus === "FAILED_ATTRIBUTE_VALIDATION"
          ) {
            return Promise.reject({
              response: {
                ...proxyResp,
                data: {
                  ...data,
                  attributesError: manageSupplementaryResults(
                    data.supplementaryResult
                  ),
                },
              },
            });
          }

          console.log("Error engine");
          console.log(err);
          if (
            data &&
            data.errno &&
            (data.errno === "ECONNREFUSED" ||
              data.errno === "ECONNRESET" ||
              data.code === "ECONNRESET" ||
              data.code === "ECONNREFUSED")
          ) {
            handle521(dispatch);
            return Promise.reject(err);
          }
          if (data.message) {
            console.log("Error json");
            console.log(data);
            return Promise.reject({
              response: proxyResp,
            });
          }
          if (data.error) {
            console.log("Error json");
            console.log(data);
            return Promise.reject({
              response: {
                ...proxyResp,
                data: {
                  ...data,
                  message: data.error,
                },
              },
            });
          }
          if (data.errorMessage) {
            console.log("Error json");
            console.log(data);
            return Promise.reject({
              response: {
                ...proxyResp,
                data: {
                  ...data,
                  message: data.errorMessage,
                },
              },
            });
          }
          return Promise.reject(err);
        }
        return Promise.reject(err);
      }
    );
  };
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
}

export function login(username, password) {
  const user = {
    username: username,
    password: password,
  };
  const formData = querystring.stringify(user);

  return function(dispatch) {
    dispatch({
      type: LOGINING,
    });
    axios
      .post("/api/login-rest", formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(() => {
        getConfiguration()(dispatch);
        dispatch({
          type: END_LOGINING,
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(authError(err.response.data.message));
        dispatch({
          type: END_LOGINING,
        });
      });
  };
}

export function logout() {
  return function(dispatch) {
    dispatch({
      type: UNAUTH_USER,
    });
    axios
      .post(
        "/api/logout-rest",
        {},
        {
          withCredentials: true,
        }
      )
      .catch(err => {
        dispatch(authError(err.response.data.message));
      });
  };
}

export function setMe(me) {
  return function(dispatch) {
    dispatch({
      type: AUTH_USER,
      me: me,
    });
    if (me && me.id) {
      hasLoggedIn = true;
    }
    checkRoles(me);
    checkGrantedAuthorities(me, dispatch);
    momentTimezone.tz.setDefault(me.timezone);
  };
}

export function getMe() {
  return function(dispatch) {
    dispatch({
      type: GETTING_USER,
    });
    axios
      .get("/api/users/whoami", {
        withCredentials: true,
      })
      .then(response => {
        let me = response.data;
        setMe(me)(dispatch);
        dispatch({
          type: END_GETTING_USER,
        });
      })
      .catch(() => {
        dispatch({
          type: UNAUTH_USER,
        });
        dispatch({
          type: END_GETTING_USER,
        });
      });
  };
}
