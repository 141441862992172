import React, {useEffect} from "react";
import { createDownloadLink } from 'actions/Uploads';
import { downloadFile } from 'api/File';
import { useParams } from 'react-router-dom';

const FileProvider = () => {

  const { targetUrl } = useParams();
  const targetUrlDecoded = atob(unescape(targetUrl));

  useEffect(()=>{
    async function openOrDownload() {
      const response = await createDownloadLink(targetUrlDecoded);
      const data = await response.data;
      downloadFile(data);
    }
    openOrDownload();
  },[]);

    return null;
};

const unescape = (baseString) => {
  return (baseString + '==='.slice((baseString.length + 3) % 4))
    .replace(/-/g, '+')
    .replace(/_/g, '/')
};

export default FileProvider;