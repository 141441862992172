import {TablePagination} from "@material-ui/core";
import React from "react";


const Pagination = (props) => {
    const {totalResultCount, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage} = props;
    return (
        <TablePagination
            style={{ margin: "auto", paddingBottom: "10px" }}
            component="div"
            count={totalResultCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
                "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
                "aria-label": "Next Page"
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );

};

export default Pagination;