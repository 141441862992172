import {
  NOTIFICATIONS_READ,
  PUSH_NOTIFICATION,
  GET_NOTIFICATIONS,
  LOADING_NOTIFICATIONS,
  END_LOADING_NOTIFICATIONS,
  ERROR_NOTIFICATIONS
} from "../constants/ActionTypes";
import axios from "axios";

export function getNotifications(maxResultCount = 20, isInit = false) {
  return function(dispatch) {
    dispatch({
      type: LOADING_NOTIFICATIONS
    });
    axios
      .post(
        `/api/notifications/find-own`,
        {
          firstResult: 0,
          maxResultCount: maxResultCount
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        let notifications = response.data;
        dispatch({
          type: GET_NOTIFICATIONS,
          notifications: notifications,
          isInit: isInit
        });
        dispatch({
          type: END_LOADING_NOTIFICATIONS
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_NOTIFICATIONS,
          error: message
        });
        dispatch({
          type: END_LOADING_NOTIFICATIONS
        });
      });
  };
}
export function setAsReadNotifications(notifications) {
  return function(dispatch) {
    if (!notifications || notifications.length <= 0) {
      return null;
    }
    axios
      .post(
        `/api/notifications/set-as-read`,
        notifications.map(notification => {
          return notification.id;
        }),
        {
          withCredentials: true
        }
      )
      .then(response => {
        dispatch({
          type: NOTIFICATIONS_READ,
          notifications
        });
      })
      .catch(err => {});
  };
}
export function createNotification(user, type, message) {
  return function(dispatch) {
    axios
      .post(
        `/api/notifications`,
        {
          appUser: { id: user.id },
          notificationType: type,
          message: message
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        dispatch({
          type: PUSH_NOTIFICATION,
          message: message
        });
      })
      .catch(err => {});
  };
}

export function readNotification(notification) {
  return function(dispatch) {
    setAsReadNotifications([notification])(dispatch);
  };
}

export function pushNotification(icon, variant, message) {
  return function(dispatch) {
    dispatch({
      type: PUSH_NOTIFICATION,
      icon: icon,
      variant: variant,
      message: message
    });
  };
}
