import React, { Component } from "react";
import { connect } from "react-redux";
import { renderRoutes } from "react-router-config";
import { bindActionCreators } from "redux";
import { getEntityType } from "../../../../../actions/Entity";
import { getEntityTypeFromPath } from "../../../../../api/Entity";

class EntityType extends Component {
  constructor(props) {
    super(props);
    this.manageProps(props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.entityType !== this.props.match.params.entityType
    ) {
      this.manageProps(nextProps);
    }
  }

  manageProps = props => {
    const { getEntityType, match } = props;
    const entityType = match.params.entityType;
    if (
      entityType !== "validation-committee-types" &&
      entityType !== "validation-types" &&
      entityType !== "model-types"
    ) {
      getEntityType(match.params.entityType);
    }
  };

  render() {
    const { route } = this.props;
    return renderRoutes(route.routes, {});
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    route: ownProps.route
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  const { location } = ownProps;
  let entityType = getEntityTypeFromPath(location.pathname);

  return bindActionCreators(
    {
      getEntityType: getEntityType(entityType)
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityType);
