import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import SortedTable from "../Table/SortedTable";
import TreeListSearchResult from "../Model/TreeListSearchResult";
import { MODEL_VIEW } from "constants/ResultFormats";
import {
  getEntityGetTypeAttribute,
  getEntityHref,
  getEntityLabel,
  MODEL_REVISION_TYPE,
  MODEL_TYPE,
  VALIDATION_TYPE
} from "api/Entity";
import CreateDataLabButton from "components/Containers/EntitiesButton";
import AssignValidationButton from "components/Validations/Validation/AssignValidationButton";
import AssignNewValidationButton from "components/Validations/Validation/AssignNewValidationButton";
import {
  ROLE_CONTAINER_CREATE,
  ROLE_DATALAB_IMAGE_VIEW
} from "constants/Authorities";
import { DATALAB } from "constants/Features";
import EnhancedTableToolbar from "components/Table/EnhancedTableToolbar";
import LinkEntity from "components/Entity/LinkEntity";
import { isAttributeToShow } from "api/Attributes";
import qs from "querystring";
import { TAB_ATTRIBUTES } from "constants/Routes";
import { CREATION_TIME_COLUMN_NAME, UPDATE_TIME_COLUMN_NAME } from 'constants/EntityColumnNames';

let columnData = [];
let columnDataTree = [];

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: []
    };
    this.initColumnDatas(props);
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.attributes !== this.props.attributes ||
      nextProps.attributesToShow !== this.props.attributesToShow ||
      nextProps.attributesFromResult !== this.props.attributesFromResult
    ) {
      this.initColumnDatas(nextProps);
      this.setState({});
    }
    if (nextProps.results !== this.props.results) {
      let newSelected = [];
      if (nextProps.results) {
        const selected = this.state.selected;
        for (let i = 0; i < selected.length; i++) {
          const select = selected[i];
          for (let j = 0; j < nextProps.results.length; j++) {
            if (nextProps.results[j] && select === nextProps.results[j].id) {
              newSelected.push(select);
              break;
            }
          }
        }
      }
      this.onSelectionChange(newSelected);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.attributes !== this.props.attributes ||
      nextProps.attributesToShow !== this.props.attributesToShow ||
      nextProps.attributesFromResult !== this.props.attributesFromResult ||
      nextProps.runningFilters !== this.props.runningFilters ||
      nextProps.results !== this.props.results
    ) {
      return true;
    }
    if (
      nextState.selected !== this.state.selected ||
      nextState.selected.length !== this.state.selected.length ||
      nextProps.orderBy !== this.props.orderBy ||
      nextProps.order !== this.props.order
    ) {
      return true;
    }
    return false;
  }

  initColumnDatas = props => {
    const {
      attributes,
      attributesToShow,
      attributesFromResult,
      entityType
    } = props;
    if (!attributes) {
      return;
    }
    const entityLabel = getEntityLabel(entityType);
    const prefix = entityType === MODEL_TYPE ? "model." : "";
    columnData = [
      {
        id: "id",
        headColumn: true,
        disablePadding: false,
        label: "ID",
        hide: true,
        unsortable: true
      },
      {
        id: `${prefix}label`,
        headColumn: true,
        editable: false,
        disablePadding: false,
        label: entityLabel,
        unsortable: true
      },
      {
        id: `${prefix}${getEntityGetTypeAttribute(entityType)}.name`,
        headColumn: true,
        disablePadding: false,
        label: `${entityLabel} type`,
        unsortable: true,
        isEntityType: true
      }
    ];
    columnDataTree = [
      {
        id: "id",
        headColumn: true,
        editable: false,
        disablePadding: true,
        label: "ID",
        hide: true
      }
    ];
    if (entityType === MODEL_TYPE) {
      columnData.push({
        id: "number",
        headColumn: true,
        disablePadding: false,
        label: "Revision",
        unsortable: true
      });
      columnDataTree.push({
        id: "number",
        headColumn: true,
        editable: true,
        label: "Revision"
      });
    }

    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      if (
        !isAttributeToShow(attributesToShow, attribute) ||
        (attributesFromResult && !attributesFromResult.includes(attribute.id))
      ) {
        continue;
      }
      const column = {
        id: "attributes." + attribute.id + ".value",
        editable: true,
        disablePadding: true,
        checkAlsoModel: true,
        name: attribute.name,
        label: attribute.label
      };
      columnData.push(column);
      columnDataTree.push({
        ...column
        //checkAlsoModel: false
      });
    }

    columnData.push({
      id: CREATION_TIME_COLUMN_NAME,
      tailColumn: true,
      name: CREATION_TIME_COLUMN_NAME,
      disablePadding: false,
      label: "Creation time",
      unsortable: false
    });
    columnDataTree.push({
      id: CREATION_TIME_COLUMN_NAME,
      tailColumn: true,
      editable: true,
      label: "Creation time"
    });
    columnData.push({
      id: UPDATE_TIME_COLUMN_NAME,
      tailColumn: true,
      name: UPDATE_TIME_COLUMN_NAME,
      disablePadding: false,
      label: "Update time",
      unsortable: false
    });
    columnDataTree.push({
      id: UPDATE_TIME_COLUMN_NAME,
      tailColumn: true,
      editable: true,
      label: "Update time"
    });
  };

  handleClickResult = result => {
    const { history } = this.props;
    history.push(getEntityHref(result.model));
  };
  handleClickModel = model => {
    const { history, location } = this.props;
    const new_location = location.pathname.replace(
      "overview",
      model.modelType.id + "/" + model.id + "/overview"
    );
    history.push({
      pathname: new_location,
      search: "?" + qs.stringify({ tab: TAB_ATTRIBUTES })
    });
  };

  onSelectionChange = selection => {
    const { onSelectionChange } = this.props;
    this.setState({
      selected: [...selection]
    });
    if (onSelectionChange) {
      onSelectionChange(selection);
    }
  };

  render() {
    const { selected } = this.state;
    const {
      results,
      result_format,
      onRequestSort,
      order,
      orderBy,
      title,
      selectable,
      selectableOnRowClick,
      unsortable,
      entityType,
      handleClickRow,
      withoutToolbar,
      style,
      tableWrapperStyle,
      useAutoSizer,
      excludeIds
    } = this.props;

    let resultView;

    if (result_format === MODEL_VIEW) {
      if (results) {
        resultView = (
          <TreeListSearchResult
            tree={results}
            selected={selected}
            componentRow={LinkEntity}
            propsRow={entity => {
              return { entity: entity };
            }}
            onSelectionChange={this.onSelectionChange}
            handleClickResult={this.handleClickResult}
            handleClickModel={this.handleClickModel}
            columnDataTree={columnDataTree}
          />
        );
      }
    } else {
      if (results && results.length > 0) {
        resultView = (
          <SortedTable
            excludeIds={excludeIds}
            selectableOnRowClick={selectableOnRowClick}
            selectable={selectable}
            withToolbar
            unsortable={unsortable}
            handleClickRow={handleClickRow}
            componentRow={handleClickRow ? "tr" : LinkEntity}
            propsRow={entity => {
              return { entity: entity };
            }}
            selected={selected}
            selectAllOverPagination
            onSelectionChange={this.onSelectionChange}
            columnDatas={columnData}
            datas={results || []}
            style={Object.assign({ maxWidth: "none", height:"100%" }, style)}
            useAutoSizer={useAutoSizer}
            tableWrapperStyle={tableWrapperStyle}
            onRequestSort={onRequestSort}
            orderBy={orderBy}
            order={order}
          />
        );
      }
    }

    return (
      <div testid="entities" className={"scrollable-container"}>
        {!withoutToolbar && (
          <EnhancedTableToolbar
            className={"scrollable-header"}
            title={title}
            actions={ACTIONS}
            entityType={entityType}
            selectedEntities={selected}
            entitiesInView={
              results
                ? results.map(entity => {
                    return entity.id;
                  })
                : []
            }
          />
        )}
        <div className={"scrollable-content"}>
          {resultView}
        </div>
      </div>
    );
  }
}

const ACTIONS = [
  {
    component: CreateDataLabButton,
    authoritiesRequired: [ROLE_DATALAB_IMAGE_VIEW, ROLE_CONTAINER_CREATE],
    featuresRequired: [DATALAB],
    allowedEntityTypes: [MODEL_TYPE, MODEL_REVISION_TYPE, VALIDATION_TYPE]
  },
  {
    component: AssignNewValidationButton,
    allowedEntityTypes: [MODEL_TYPE, MODEL_REVISION_TYPE]
  },
  {
    component: AssignValidationButton,
    allowedEntityTypes: [MODEL_TYPE, MODEL_REVISION_TYPE]
  }
];

const mapStateToProps = (state, ownProps) => {
  const { entityType, results, attributesFromResult, result_format } = ownProps;
  return {
    results: results
      ? results
      : state.filters.results
      ? state.filters.results[entityType]
      : undefined,
    attributesFromResult: attributesFromResult
      ? attributesFromResult
      : state.filters.attributes
      ? state.filters.attributes[entityType]
      : undefined,
    attributes: state.attributes.list,
    result_format: result_format ? result_format : state.settings.result_format,
    attributesToShow: state.attributes.attributesToShow
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(ListView)
);
