import {
  GET_FOLDERS,
  LOADING_FOLDERS,
  END_LOADING_FOLDERS,
  ERROR_FOLDERS,
  GET_FOLDER,
  LOADING_FOLDER,
  END_LOADING_FOLDER,
  ERROR_FOLDER,
  CREATE_FOLDER,
  CREATING_FOLDER,
  END_CREATING_FOLDER,
  ERROR_CREATING_FOLDER,
  DELETE_FOLDER,
  DELETING_FOLDER,
  END_DELETING_FOLDER,
  ERROR_DELETING_FOLDER,
  UPDATING_FOLDER,
  END_UPDATING_FOLDER,
  ERROR_UPDATING_FOLDER,
  GET_ATTACHMENTS_LOGS,
  LOADING_ATTACHMENTS_LOGS,
  END_LOADING_ATTACHMENTS_LOGS,
  ATTACHMENTS_LOGS_ERROR
} from "../../constants/ActionTypes";
import reduceEntity from "../../api/ReduceEntity";
import { getEntityType } from "../../api/Entity";
import { goThroughList } from "../../api/List";

export default function(state = {}, action) {
  let list = state.list,
    result;

  switch (action.type) {
    case GET_FOLDERS:
      return {
        ...state,
        list: action.folders
      };
    case LOADING_FOLDERS:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case END_LOADING_FOLDERS:
      return { ...state, loading: false };
    case ERROR_FOLDERS:
      return { ...state, error: action.error };

    case CREATE_FOLDER:
      if (!list) {
        list = [];
      }
      list.push(action.folder);
      return {
        ...state,
        list: list,
        current: action.folder
      };
    case CREATING_FOLDER:
      return {
        ...state,
        creating: true,
        error_creating: undefined
      };
    case END_CREATING_FOLDER:
      return { ...state, creating: false };
    case ERROR_CREATING_FOLDER:
      return { ...state, error_creating: action.error };

    case DELETE_FOLDER:
      result = goThroughList(list, state.current, action.folderId, folder => {
        return null;
      });
      return {
        ...state,
        current: result.current,
        list: result.list
      };
    case DELETING_FOLDER:
      result = goThroughList(list, state.current, action.folderId, folder => {
        return {
          ...folder,
          deleting: true,
          error_deleting: null
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };
    case END_DELETING_FOLDER:
      result = goThroughList(list, state.current, action.folderId, folder => {
        return {
          ...folder,
          deleting: false
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };
    case ERROR_DELETING_FOLDER:
      result = goThroughList(list, state.current, action.folderId, folder => {
        return {
          ...folder,
          error_deleting: action.error
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };

    case UPDATING_FOLDER:
      result = goThroughList(list, state.current, action.folderId, folder => {
        return {
          ...folder,
          updating: true,
          error_updating: null
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };
    case END_UPDATING_FOLDER:
      result = goThroughList(list, state.current, action.folderId, folder => {
        return {
          ...folder,
          updating: false
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };
    case ERROR_UPDATING_FOLDER:
      result = goThroughList(list, state.current, action.folderId, folder => {
        return {
          ...folder,
          error_updating: action.error
        };
      });
      return {
        ...state,
        current: Object.assign({}, result.current),
        list: result.list
      };

    case GET_FOLDER:
      result = goThroughList(list, state.current, action.folderId, folder => {
        return {
          ...folder,
          ...action.folder
        };
      });
      return {
        ...state,
        current: action.folder,
        list: result.list
      };
    case LOADING_FOLDER:
      return {
        ...state,
        loading_folder: true,
        error_folder: undefined
      };
    case END_LOADING_FOLDER:
      return { ...state, loading_folder: false };
    case ERROR_FOLDER:
      return { ...state, error_folder: action.error };

    default:
      return {
        ...state,
        current: reduceEntity(
          state.current,
          action,
          getEntityType(state.current)
        )
      };
  }
}
