import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import IconButton from "../Buttons/IconButton";
import Button from "../Buttons/Button";
import { initAssignError } from "actions/Validations";
import Entities from "components/Entity/Entities";
import { Close, Error } from "@material-ui/icons";
import { getEntityLabel } from 'api/Entity';

import {
  AppBar,
  CircularProgress,
  Toolbar,
  Typography,
  Dialog,
  DialogActions
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  appBar: {},
  title: {
    flex: 1
  },
  tips: {
    marginBottom: 15
  },
  error: {
    color: "red",
    padding: "24px 24px 0 24px"
  }
};

class AssignEntities extends Component {
  state = {
    selected: []
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.assigning && this.props.assigning && !nextProps.error) {
      nextProps.handleClose();
    }
    if (!nextProps.open && this.props.open) {
      nextProps.initAssignError();
    }
  }

  onSelectionChange = selected => {
    this.setState({
      selected: selected
    });
  };

  handleAssign = () => {
    const { handleAssign } = this.props;
    if (handleAssign) {
      handleAssign(this.state.selected);
    }
  };

  render() {
    const {
      assigning,
      authoritiesRequired,
      classes,
      entityType,
      error,
      getButtonSentence,
      handleClose,
      open,
      selectable,
      title,
      url,
      excludeIds
    } = this.props;
    const { selected } = this.state;
    const sentence = getButtonSentence ? getButtonSentence(selected) : "";
    const entityTypeLabel = getEntityLabel(entityType, true);

    let content, errorDiv;
    if (assigning) {
      content = (
        <div
          style={{
            width: "100%",
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress size={100} />
        </div>
      );
    } else {
      content = (
        <div className={"scrollable-container"} style={{ padding: "20px 0", margin: "0 50px" }}>
          <div className={"scrollable-header"}>
          <Typography
            variant="body1"
            color="inherit"
            className={classes.tips}
          >
            Select the {entityTypeLabel} with the checkboxes to assign them.
          </Typography>
          </div>

          <div className={"scrollable-content"}>
            <Entities
              excludeIds={excludeIds}
              url={url}
              withoutToolbar
              entityType={entityType}
              selectableOnRowClick
              selectable={selectable}
              onSelectionChange={this.onSelectionChange}
            />
          </div>

          <DialogActions className={"scrollable-footer"}>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            {sentence && (
              <Button
                authoritiesRequired={authoritiesRequired}
                onClick={this.handleAssign}
                color="primary"
                variant="outlined"
              >
                {sentence}
              </Button>
            )}
          </DialogActions>
        </div>
      );
    }

    if (error) {
      errorDiv = (
        <div className={classes.error}>
          <Error style={{ verticalAlign: "middle", marginRight: 5 }} />
          {error}
        </div>
      );
    }

    return (
      <Dialog
        scroll="body"
        fullScreen
        onClose={handleClose}
        open={open || false}
      >
        <div className={"scrollable-container"}>
        <div className={"scrollable-header"}>
        <AppBar className={classes.appBar} position="sticky">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
            >
              <Close />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>
              {title}
            </Typography>
            <Button onClick={handleClose} color="inherit" variant="outlined">
              Cancel
            </Button>
            {sentence && (
              <Button
                testid="confirmAssignModel"
                style={{ marginLeft: 5, borderColor: "white" }}
                authoritiesRequired={authoritiesRequired}
                onClick={this.handleAssign}
                color="inherit"
                variant="outlined"
              >
                {sentence}
              </Button>
            )}
          </Toolbar>
        </AppBar>
        {errorDiv}
        </div>
        <div className={"scrollable-content"}>
          {content}
        </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const settings = state.settings.choose_entity_settings;
  if (settings) {
    return {
      ...settings,
      ...ownProps
    };
  }
  return {};
};
export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      { initAssignError }
    )(AssignEntities)
  )
);
