import { Chip } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { FILTER_TYPE } from "constants/Filters";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  chip: {
    "margin": "5px",
    "backgroundColor": "rgba(0, 0, 0, 0.04)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const AppliedFilter = ({ entityType, removeAppliedFilter }) => {
  const classes = useStyles();
  const filter = useSelector((state) =>
    state.filters.last_filters_runned
      ? state.filters.last_filters_runned[entityType]
      : undefined
  );

  if (filter && filter.filterType === FILTER_TYPE.SAVED) {
    return (
      <Chip
        className={classes.chip}
        onClick={removeAppliedFilter}
        onDelete={removeAppliedFilter}
        label={filter.name}
      />
    );
  }
  if (filter && filter.filterType === FILTER_TYPE.CUSTOM) {
    return (
      <Chip
        className={classes.chip}
        onClick={removeAppliedFilter}
        onDelete={removeAppliedFilter}
        label={"Custom filter applied"}
      />
    );
  }
  return <Chip className={classes.chip} disabled label={"No filter applied"} />;
};

export default AppliedFilter;
