import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import IconButton from "../../Buttons/IconButton";
import { initAssignError } from "../../../actions/Validations";
import Entities from "components/Entity/Entities";
import { VALIDATION_COMMITTEE_TYPE } from "../../../api/Entity";
import { Close, Error } from "@material-ui/icons";

import {
  AppBar,
  CircularProgress,
  Toolbar,
  Typography,
  Dialog,
  Slide
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  appBar: {
    position: "relative"
  },
  title: {
    marginRight: 20
  },
  error: {
    color: "red",
    padding: "24px 24px 0 24px"
  }
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AssignValidationCommitteeDialog extends Component {
  componentWillReceiveProps(nextProps) {
    if (!nextProps.assigning && this.props.assigning && !nextProps.error) {
      nextProps.handleClose();
    }
    if (!nextProps.open && this.props.open) {
      nextProps.initAssignError();
    }
  }

  render() {
    const {
      handleClose,
      open,
      classes,
      assigning,
      error,
      onAssign
    } = this.props;

    let content, errorDiv;
    if (assigning) {
      content = (
        <div
          style={{
            width: "100%",
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress size={100} />
        </div>
      );
    } else {
      content = (
        <div className={"scrollable-content"} style={{ padding: "10px 0", margin: "0 32px" }}>
          <Entities
            entityType={VALIDATION_COMMITTEE_TYPE}
            handleClickRow={onAssign}
          />
        </div>
      );
    }

    if (error) {
      errorDiv = (
        <div className={classes.error}>
          <Error style={{ verticalAlign: "middle", marginRight: 5 }} />
          {error}
        </div>
      );
    }

    return (
      <Dialog
        fullScreen
        onClose={handleClose}
        open={open}
        TransitionComponent={Transition}
        className={"scrollable-container"}
      >
        <div className={"scrollable-headoer"}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="Close"
              >
                <Close />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.title}>
                Assign to committee
              </Typography>
            </Toolbar>
          </AppBar>
          {errorDiv}
        </div>
        {content}
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    assigning: state.validations.assigning_validation,
    error: state.validations.error_assign_validation
  };
};
export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      { initAssignError }
    )(AssignValidationCommitteeDialog)
  )
);
