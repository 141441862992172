import axios from "axios";
import { getEntityType, MODEL_REVISION_TYPE } from 'api/Entity';

export function getLinkDefinitions(entity) {
  return axios.get(
    `/api/links/definitions/${entity.id}/${getEntityType(entity)}`,
    {
      withCredentials: true
    }
  );
}

export function createLink(sourceEntityResolved, definitionId, targetEntityResolved, switched) {
  if (!sourceEntityResolved || !targetEntityResolved) {
    return null;
  }
  const ownerId = sourceEntityResolved.id;
  const sourceEntityType = getEntityType(sourceEntityResolved);
  const targetEntityType = getEntityType(targetEntityResolved);
  return axios.post(
    "/api/links",
    {
      sourceEntityId: switched ? targetEntityResolved.id : ownerId,
      sourceEntity: switched ? targetEntityType : sourceEntityType,
      targetEntityId: switched ? ownerId : targetEntityResolved.id,
      targetEntity: switched ? sourceEntityType : targetEntityType,
      definition: {
        id: definitionId
      }
    },
    {
      withCredentials: true
    }
  );
}

export function getLinks(ownerId, transitive) {
  if (!ownerId) {
    return axios.get(`/api/links/?shouldFill=true&entity-type=${MODEL_REVISION_TYPE}`, {
      withCredentials: true
    });
  }
  return axios.get(
    "/api/links/" + ownerId + (transitive ? "?mode=TRANSITIVE" : ""),
    {
      withCredentials: true
    }
  );
}
export function deleteLink(linkId) {
  return axios.delete(`/api/links/${linkId}`, {
    withCredentials: true
  });
}
