import React from 'react';
import NotificationMenu from 'components/Notification/MenuHeader';
import Button from 'components/Buttons/Button';
import { Link } from 'react-router-dom';
import { PowerSettingsNew } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  loginButton: {
    marginTop: '10px',
    height: '40px',
    padding: '0 20px',
    color: '#607d8b',
  },
}));

const AnonymousNavRightList = () => {

  const classes = useStyles();

  return (
    <ul className="list-unstyled float-right">
      <NotificationMenu />
      <li className="list-inline-item" style={{ marginRight: '10px' }}>
        <Button
          component={Link}
          to="/login"
          className={classes.loginButton}
        >
          <PowerSettingsNew/>
          Login
        </Button>
      </li>
    </ul>
  );
};

export default AnonymousNavRightList;
