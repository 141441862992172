import React, {useCallback, useEffect, useState} from "react";
import {getGrantedAuthorityForRole} from "actions/Roles";
import {makeStyles} from "@material-ui/core/styles";
import {reduxForm, reset} from "redux-form";
import {scrollToFirstError} from "../../../../api/Error";
import {
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  ListItemText,
  Grid
} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import ButtonLoading from "components/Buttons/ButtonLoading";
import {ROLE_SYSTEM_ADMINISTRATION} from "../../../../constants/Authorities";
import Button from "components/Buttons/Button";
import GrantedAuthorities from "components/GrantedAuthority/input";
import {initializeForm, useApiRequest} from "actions/index";
import {useDispatch} from "react-redux";
import TabCentered from "components/TabContainer/TabCentered";
import IconButton from "components/Buttons/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import Hint from "components/Attributes/Hint";

const useStyles = makeStyles((theme) => ({
  field: {
    margin: '20px 0',
    width: '100%'
  }
}));

const RoleItem = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  shouldWarn: () => {
    return true;
  },
  onSubmitFail: scrollToFirstError
})
(({role, updatingAuthority, errorUpdatingAuthority, handleSubmit, form, onUpdateClick}) => {

  if(!role){
    return null;
  }

  const classes = useStyles();
  const dispatch = useDispatch();
  const [assignedAuthorities, setAssignedAuthorities] = useState([]);
  const [expanded, setExpaned] = useState(false);
  const [loading, error, fetchAssignedGrantedAuthorities] = useApiRequest(
    getGrantedAuthorityForRole,
    (data)=>{
      setAssignedAuthorities(data)
    }
  )

  useEffect(()=>{
    if(expanded){
      fetchAssignedGrantedAuthorities(role.id)
    }
  },[role, expanded]);

  if(error){
    return (
      <TabCentered>
        <div className="emptyMessage">No granted authority found for this role</div>
        <div className="error">{error}</div>
      </TabCentered>
    );
  }

  const handleCancel = (roleId) => {
    dispatch(initializeForm(fetchAssignedGrantedAuthorities(roleId)))
  }

  const handleUpdateClick = useCallback((event) => {
    onUpdateClick(role);
    event.stopPropagation();
  }, [role, onUpdateClick]);

  return <form onSubmit={handleSubmit}>
    <ExpansionPanel onChange={(e, expanded) => setExpaned(expanded)}>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Grid container justify="flex-start" alignItems="center" spacing={3}>
        <Grid item>
          <ListItemText
            primary={role.name}
            secondary={
              <Typography component="span" className={classes.inline} variant="caption">
                {role.key}
              </Typography>
            }
          />
        </Grid>
        { role.hint && (<Grid item><Hint hintText={role.hint}/></Grid>) }
      </Grid>
      <Grid container justify="flex-end" className={classes.actions}>
        <IconButton
          title="Update the role"
          authoritiesRequired={[ROLE_SYSTEM_ADMINISTRATION]}
          color="primary"
          onClick={handleUpdateClick}
        >          
          <EditIcon/>
        </IconButton>
      </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <div className={classes.field}>
          <GrantedAuthorities expanded={expanded} loading={loading} assignedAuthorities={assignedAuthorities} form={form} multiple />
        </div>
      </ExpansionPanelDetails>
      <ExpansionPanelActions>
        <ButtonLoading
          authoritiesRequired={[ROLE_SYSTEM_ADMINISTRATION]}
          testid="submitrole"
          loading={updatingAuthority}
          loadingTitle="Updating the role"
          style={{ width: "300px" }}
          className="color-primary"
          type="submit"
          variant="outlined"
          color="primary"
        >
          {"Save the updated role"}
        </ButtonLoading>
        <Button onClick={() => { handleCancel(role.id) }}>Cancel</Button>
      </ExpansionPanelActions>
      <div className="error">{errorUpdatingAuthority}</div>
    </ExpansionPanel>
  </form>;
});

export default RoleItem;