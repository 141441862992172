import React, { Component } from 'react';
import ReactDOM from "react-dom";
import {AutoSizer} from "react-virtualized";

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const measureElement = element => {
    const DOMNode = ReactDOM.findDOMNode(element);
    return {
        width: DOMNode.clientWidth,
        height: DOMNode.clientHeight,
    };
};

class SimpleTable extends Component {
    state = {
        tableHeaderHeight: 0
    };

    componentDidUpdate() {
        const { tableHeaderHeight } = this.state;
        setTimeout(() => {
            if (this.tablehead) {
                const newHeight = measureElement(this.tablehead).height;
                if (newHeight !== tableHeaderHeight) {
                    this.setState({
                        tableHeaderHeight: newHeight
                    });
                }
            }
        }, 1);
    }

    render() {
        const { data, columns } = this.props;
        const { tableHeaderHeight } = this.state;

        if (!data) {
            return null;
        }
        if (!Array.isArray(data)) {
            return data;
        }
        if (data.length === 0) {
            return null;
        }
        const hasHeader = columns && columns.length > 0;
        return (
            <AutoSizer>
                {
                    ({width, height}) => width === 0 || height === 0 ? null : (
                        <Table>
                            {
                                hasHeader && (
                                    <TableHead ref={r => this.tablehead = r}>
                                        <TableRow>
                                            {
                                                columns.map((th, idx) => {
                                                    return (
                                                        <TableCell key={idx}>{ th }</TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                )
                            }
                            <TableBody style={(height && hasHeader && tableHeaderHeight)? {height: height-tableHeaderHeight-15}: null}>
                                {
                                    data.map((row, idx) => {
                                        return (
                                            <TableRow key={idx} style={{height:25}}>
                                                {
                                                    row && row.map && row.map((td, idx) => {
                                                        return (
                                                            <TableCell
                                                                key={idx}
                                                                style={(hasHeader)? null: {maxWidth: "none"}}>
                                                                { td }
                                                            </TableCell>
                                                        );
                                                    })
                                                }
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    )
                }
            </AutoSizer>
        );
    };
}

export default SimpleTable;
