import React from "react";
import {KeyboardArrowRight} from "@material-ui/icons";
import { Stepper } from "@material-ui/core";
import SubDirectoryStepper from "components/Attachments/SubDirectoryStepper";
import HomeIcon from '@material-ui/icons/Home';

const DirectoryPath = ({ rootPathName, currentSubfolder, onSubfolderChange, onDrop, isDragging }) => {
        const subfolderPaths = currentSubfolder && currentSubfolder !== '' && currentSubfolder.split('/').splice(1);

        return (
            <Stepper
                orientation="horizontal"
                activeStep={20}
                connector={<KeyboardArrowRight />}>
                <SubDirectoryStepper
                    isDragging={isDragging}
                    droppable
                    onDrop={() => {
                        if (onDrop) {
                            onDrop('/');
                        }
                    }}
                    subfolderPath={<div style={{display: "flex", alignItems:"center"}}><HomeIcon />{rootPathName}</div>}
                    onClick={() => { onSubfolderChange && onSubfolderChange('/') }} />
                {
                    subfolderPaths && subfolderPaths.length > 0 && subfolderPaths.map((subfolderPath, idx) => {
                        if (subfolderPath === '') {
                            return null;
                        }
                        const subfolderFullPath = subfolderPaths.slice(0, idx+1).join('/');
                        return (
                            <SubDirectoryStepper
                                key={idx}
                                isDragging={isDragging}
                                droppable
                                onDrop={() => {
                                    if (onDrop) {
                                        onDrop('/' + subfolderFullPath + '/');
                                    }
                                }}
                                subfolderPath={subfolderPath}
                                onClick={() => { onSubfolderChange && onSubfolderChange('/' + subfolderFullPath + '/') }}/>
                        );
                    })
                }
            </Stepper>
        );
};

export default DirectoryPath;