import React, { Component } from "react";
import Button from "../../Buttons/Button";
import FormControl from "./FormControl";
import Dropzone from "react-dropzone";
import { change } from "redux-form";
import { connect } from "react-redux";
import { Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    overflowWrap: "break-word",
    wordBreak: "break-all"
  },
  input: {
    display: "none"
  }
});

class FileInputField extends Component {
  onDrop = files => {
    const { change, meta, input } = this.props;
    change(meta.form, input.name, files);
  };

  render() {
    const { classes, meta, input, multiple, accept, disabled } = this.props;
    let label = this.props.label;
    if (input && input.value && Array.isArray(input.value)) {
      if (input.value.length > 1) {
        label = input.value.length + " files selected";
      } else {
        label = input.value[0].name;
      }
    }
    return (
      <FormControl {...this.props} withoutError>
        <Dropzone
          disabled={disabled}
          style={{
            width: "100%",
            textAlign: "center",
            borderWidth: "2px",
            borderColor: "#333",
            borderStyle: "dashed",
            borderRadius: "5px",
            padding: "5px 10px"
          }}
          inputProps={{
            style: {
              display: "none"
            }
          }}
          multiple={multiple}
          accept={accept}
          onDrop={this.onDrop}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              minHeight: "200px"
            }}
            direction="column"
          >
            <Grid item xs={12} style={{ flexBasis: "auto" }}>
              <Typography variant="body1" style={{ margin: 8 }}>
                Drag and drop your file here
              </Typography>
              <Typography variant="body2">
                Or click to select the file to upload
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ flexBasis: "auto" }}>
              <Button
                disabled={disabled}
                variant="outlined"
                className={classes.button}
              >
                {label}
              </Button>
            </Grid>
            <Grid item xs={12} style={{ flexBasis: "auto" }}>
              {meta.touched && meta.error && (
                <span className="error">{meta.error}</span>
              )}
            </Grid>
          </Grid>
        </Dropzone>
      </FormControl>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
export default withStyles(styles)(
  connect(
    mapStateToProps,
    { change }
  )(FileInputField)
);
