import React, { Component } from 'react';

import { Field, formValues, reduxForm } from 'redux-form';
import { Dialog, DialogActions, DialogContent, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ButtonLoading from '../Buttons/ButtonLoading';
import { RequiredValidator } from '../Form/Fields/FieldValidators';
import SelectField from '../Form/Fields/SelectField';
import CloseableDialogTitle from 'components/Text/CloseableDialogTitle';
import { scrollToFirstError } from 'api/Error';
import LinkEntitySelection from 'components/Links/LinkEntitySelection';

export const SEPARATOR = "|SEPARATOR|";

class CreateLinkForm extends Component {
  state = {
    definitionChoose: null
  };

  componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues && initialValues.definitionId) {
      this.chooseDefinition(initialValues.definitionId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { initialValues } = nextProps;
    const { definitionChoose } = this.state;
    if (
      this.props.initialValues !== initialValues &&
      initialValues &&
      initialValues.definitionId &&
      !definitionChoose
    ) {
      this.chooseDefinition(initialValues.definitionId);
    }
  }

  chooseDefinition = value => {
    const { typesLink } = this.props;
    const [definitionId, switched] = value.split(SEPARATOR);
    for (let i = 0; typesLink && i < typesLink.length; i++) {
      const typeLink = typesLink[i];
      if (typeLink.id === definitionId) {
        this.setState({
          definitionChoose: typeLink
        });
        return;
      }
    }
    this.setState({
      definitionChoose: null
    });
  };

  handleChangeEntityLinkDefinition = (event) => {
    if (event.target && event.target.value) {
      this.chooseDefinition(event.target.value);
    }
  }

  render() {
    const {
      handleSubmit,
      creating,
      errorCreating,
      handleClose,
      open,
      typesLink,
      sourceEntityId,
      sourceEntityType,
      otherEntities
    } = this.props;

    const { definitionChoose } = this.state;

    const typesToDisplay = [];
    for (let i = 0; typesLink && i < typesLink.length; i++) {
      const typeSelectedLink = typesLink[i];
      if (typeSelectedLink.asymmetrical) {
        if (sourceEntityType === typeSelectedLink.sourceEntity) {
          typesToDisplay.push({
            value: typeSelectedLink.id + SEPARATOR + "source",
            label: typeSelectedLink.sourceName
          });
        }
        if (sourceEntityType === typeSelectedLink.targetEntity) {
          typesToDisplay.push({
            value: typeSelectedLink.id + SEPARATOR + "target",
            label: typeSelectedLink.targetName
          });
        }
      } else {
        typesToDisplay.push({
          value: typeSelectedLink.id + SEPARATOR + "sibling",
          label: typeSelectedLink.sourceName
        });
      }
    }

    return (
      <div>
        <Dialog onClose={handleClose} open={open}>
          <form onSubmit={handleSubmit} style={{ padding: "0 20px" }}>
            <CloseableDialogTitle
              id="simple-dialog-title"
              handleClose={handleClose}
            >
              Create a new link
            </CloseableDialogTitle>
            <DialogContent>
              <div style={{ margin: "10px 0", width: "300px" }}>
                <Field
                  label={"This entity"}
                  name="definitionId"
                  onChange={this.handleChangeEntityLinkDefinition}
                  component={SelectField}
                  validate={[RequiredValidator]}
                  ref="definitionId"
                >
                  {typesToDisplay &&
                    typesToDisplay.map((type) =>
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    )}
                </Field>
              </div>
              {definitionChoose &&
                <LinkEntitySelection
                  sourceEntityId={sourceEntityId}
                  sourceEntityType={sourceEntityType}
                  definition={definitionChoose}
                />
              }
            </DialogContent>
            {otherEntities && (
              <DialogActions>
                <ButtonLoading
                  testid="confirmAddLink"
                  loading={creating}
                  loadingTitle="Creating the link"
                  className="color-primary"
                  type="submit"
                  variant="outlined"
                  color="primary"
                >
                  {"Create the link"}
                </ButtonLoading>
                <div className="error">{errorCreating}</div>
              </DialogActions>
            )}
          </form>
        </Dialog>
      </div>
    );
  }
}

const styles = theme => ({
  name: {
    textAlign: "right",
    display: "inline-flex",
    padding: "15px 0",
    paddingRight: "10px",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "150px"
  },
  value: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1
  }
});

CreateLinkForm = reduxForm({
  form: "createlinkform",
  enableReinitialize: true,
  onSubmitFail: scrollToFirstError
})(formValues("otherEntities", "definitionId")(CreateLinkForm));

export default withStyles(styles)(CreateLinkForm);
