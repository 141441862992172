import React from "react";
import { Typography, withStyles, Grid } from "@material-ui/core";

const LicenseInfoItem = ({ name, value, valueColor, classes }) => {
  return (
    <Grid container>
      <Grid item className={classes.name}>
        <Typography variant="body1" className={classes.label} gutterBottom>
          {`${name}: `}
        </Typography>
      </Grid>
      <Grid item className={classes.value}>
        <Typography
          variant="body1"
          style={{
            color: valueColor
          }}
          gutterBottom
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  name: {
    display: "block",
    paddingRight: "10px",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "250px"
  },
  value: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1
  },
  label: {
    color: "grey"
  }
});

export default withStyles(styles)(LicenseInfoItem);
