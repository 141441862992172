import React from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Collapse,
  IconButton,
  TableCell,
  Tab,
  Tabs,
  TableRow
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { TAB_INPUT_OUTPUT, TAB_MONITORINBG_LINKS } from 'constants/Routes';
import APPCONFIG from '../../constants/Config';
import MonitoringRowContent from 'components/ModelMonitoring/MonitoringRowContent';
import Links from 'components/Links';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
  tableCell: {
    whiteSpace: 'inherit',
  },
});

function date2String(date) {
  return moment(date).format(APPCONFIG.dateTimeFormat);
}

const MonitoringRow = ({ monitoring }) => {
  const { entityId } = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(!!entityId);
  const [currentTab, setCurrentTab] = React.useState(TAB_INPUT_OUTPUT);

  const handleChangeTab = (e, value) => {
    setCurrentTab(value);
  };
  const renderCurrentTab = () => {
    switch (currentTab) {
      case TAB_INPUT_OUTPUT:
        return <MonitoringRowContent monitoring={monitoring}/>;
      case TAB_MONITORINBG_LINKS:
        return <Links entity={monitoring}/>;
    }
  };

  return (
    <>
      <TableRow hover
                style={{ cursor: 'pointer' }}
                key={monitoring.id}
                onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
        <TableCell className={classes.tableCell}>{monitoring.label}</TableCell>
        <TableCell className={classes.tableCell}>{monitoring.executorLabel}</TableCell>
        <TableCell className={classes.tableCell}>{monitoring.additionalInformation}</TableCell>
        <TableCell className={classes.tableCell}>{monitoring.dataSource}</TableCell>
        <TableCell className={classes.tableCell}>{date2String(monitoring.creationTime)}</TableCell>
        <TableCell className={classes.tableCell}>{date2String(monitoring.updateTime)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit style={{ maxWidth: '100vw' }}>
            <Tabs value={currentTab} onChange={handleChangeTab}>
              {TABS.map(({ label, value }, idx) => {
                return <Tab
                  key={idx}
                  label={label}
                  value={value}
                />;
              })}
            </Tabs>
            {renderCurrentTab()}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TABS = [{
    value: TAB_INPUT_OUTPUT,
    label: 'I/O',
  }, {
    value: TAB_MONITORINBG_LINKS,
    label: 'Links',
  },
];

export default MonitoringRow;
