import React from "react";
import { connect } from "react-redux";
import SnackBar from "./SnackBar";
import { notificationExists } from "../../api/Notification";

class NotificationStack extends React.Component {
  state = {
    notifications: []
  };

  componentWillReceiveProps(nextProps) {
    const { notifications } = this.state;
    const { lastNotifications } = nextProps;
    for (let i = 0; lastNotifications && i < lastNotifications.length; i++) {
      const notification = lastNotifications[i];
      if (!notificationExists(notification, notifications)) {
        notifications.push({
          ...notification,
          open: true
        });
      }
    }
    this.setState({
      notifications: notifications
    });
  }

  handleClose = notification => {
    notification.open = false;
    this.setState({});
  };

  render() {
    const { notifications } = this.state;
    let count = -1;
    return notifications.map((notification, idx) => {
      if (notification.open) {
        count++;
        return (
          <SnackBar
            key={idx}
            index={count}
            notification={notification}
            onClose={this.handleClose}
          />
        );
      }
    });
  }
}

const mapStateToProps = (state, ownProps) => ({
  lastNotifications: state.notifications.last
});

export default connect(mapStateToProps)(NotificationStack);
