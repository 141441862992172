import {
  DOCUMENT_IMPORTED,
  DOCUMENT_UPDATING,
  DOCUMENT_UPDATED,
  DOCUMENT_UPDATED_ERROR,
  DOCUMENT_DELETING,
  DOCUMENT_DELETED,
  DOCUMENT_DELETED_ERROR,
  GET_DOCUMENTS,
  LOADING_DOCUMENTS,
  END_LOADING_DOCUMENTS,
  DOCUMENTS_ERROR,
  GET_DOCUMENTS_LOGS,
  LOADING_DOCUMENTS_LOGS,
  END_LOADING_DOCUMENTS_LOGS,
  ERROR_GET_DOCUMENTS_LOGS,
  DOCUMENT_RENAMING,
  DOCUMENT_RENAMED_ERROR,
  END_DOCUMENT_UPDATING
} from 'constants/ActionTypes';
import { areAttachmentsEqual } from "./Attachments";

export default function(entity, action) {
  let ownerId = action.ownerId;

  let listDocuments;
  switch (action.type) {
    case GET_DOCUMENTS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        documents: action.documents
      };
    case LOADING_DOCUMENTS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        loading_documents: true,
        error_documents: undefined
      };
    case END_LOADING_DOCUMENTS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        loading_documents: false
      };
    case DOCUMENTS_ERROR:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        error_documents: action.error
      };

    case GET_DOCUMENTS_LOGS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        documents_logs: action.logs
      };
    case LOADING_DOCUMENTS_LOGS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        loading_documents_logs: true,
        error_documents_logs: undefined
      };
    case END_LOADING_DOCUMENTS_LOGS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        loading_documents_logs: false
      };
    case ERROR_GET_DOCUMENTS_LOGS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        error_documents_logs: action.error
      };

    case DOCUMENT_IMPORTED:
      if (!entity || entity.id !== ownerId) {
        return entity;
      }
      if (!action.document) {
        return entity;
      }
      listDocuments = entity.documents;
      if (!listDocuments) {
        listDocuments = [];
      }
      for (let j = 0; listDocuments && j < listDocuments.length; j++) {
        const listDocument = listDocuments[j];
        if (listDocument.fileName === action.document.fileName) {
          listDocuments.splice(j, 1);
          break;
        }
      }
      listDocuments.unshift(action.document);
      return {
        ...entity,
        documents: [...listDocuments]
      };
    case DOCUMENT_UPDATING:
      if (!entity || ownerId !== entity.id || !action.oldDocument) {
        return { ...entity };
      }
      for (let i = 0; entity.documents && i < entity.documents.length; i++) {
        if (areAttachmentsEqual(entity.documents[i], action.oldDocument)) {
          entity.documents[i].updating = true;
          entity.documents[i].error = undefined;
        }
      }
      return {
        ...entity,
        documents: [...(entity.documents ? entity.documents : [])]
      };
    case END_DOCUMENT_UPDATING:
      if (!entity || ownerId !== entity.id || !action.oldDocument) {
        return { ...entity };
      }
      for (let i = 0; entity.documents && i < entity.documents.length; i++) {
        if (areAttachmentsEqual(entity.documents[i], action.oldDocument)) {
          entity.documents[i].updating = false;
        }
      }
      return {
        ...entity,
        documents: [...(entity.documents ? entity.documents : [])]
      };
    case DOCUMENT_UPDATED_ERROR:
      if (!entity || ownerId !== entity.id || !action.oldDocument) {
        return { ...entity };
      }
      for (let i = 0; entity.documents && i < entity.documents.length; i++) {
        if (areAttachmentsEqual(entity.documents[i], action.oldDocument)) {
          entity.documents[i].updating = false;
          entity.documents[i].error = action.error;
        }
      }
      return {
        ...entity,
        documents: [...(entity.documents ? entity.documents : [])]
      };
    case DOCUMENT_UPDATED:
      if (!entity || entity.id !== ownerId) {
        return entity;
      }
      if (!action.newDocument) {
        return entity;
      }
      listDocuments = entity.documents;
      if (!listDocuments) {
        listDocuments = [];
      }
      // remove old document from array of documents of old category
      listDocuments = listDocuments.filter(
        value =>
          !areAttachmentsEqual(value, action.oldDocument) &&
          !areAttachmentsEqual(value, action.newDocument)
      );
      listDocuments.unshift(action.newDocument);

      return {
        ...entity,
        documents: [...listDocuments]
      };

    case DOCUMENT_RENAMING:
      if (!entity || ownerId !== entity.id || !action.oldDocument) {
        return entity;
      }
      for (let i = 0; entity.documents && i < entity.documents.length; i++) {
        if (areAttachmentsEqual(entity.documents[i], action.oldDocument)) {
          entity.documents[i].renaming = true;
          entity.documents[i].renaming_error = undefined;
        }
      }
      return {
        ...entity,
        documents: [...(entity.documents ? entity.documents : [])]
      };
    case DOCUMENT_RENAMED_ERROR:
      if (!entity || ownerId !== entity.id || !action.oldDocument) {
        return entity;
      }
      for (let i = 0; entity.documents && i < entity.documents.length; i++) {
        if (areAttachmentsEqual(entity.documents[i], action.oldDocument)) {
          entity.documents[i].renaming = false;
          entity.documents[i].renaming_error = action.error;
        }
      }
      return {
        ...entity,
        documents: [...(entity.documents ? entity.documents : [])]
      };

    case DOCUMENT_DELETING:
      if (!entity || ownerId !== entity.id || !action.document) {
        return entity;
      }
      for (let i = 0; entity.documents && i < entity.documents.length; i++) {
        if (areAttachmentsEqual(entity.documents[i], action.document)) {
          entity.documents[i].deleting = true;
          entity.documents[i].error = undefined;
        }
      }
      return {
        ...entity,
        documents: [...(entity.documents ? entity.documents : [])]
      };
    case DOCUMENT_DELETED_ERROR:
      if (!entity || ownerId !== entity.id || !action.document) {
        return entity;
      }
      for (let i = 0; entity.documents && i < entity.documents.length; i++) {
        if (areAttachmentsEqual(entity.documents[i], action.document)) {
          entity.documents[i].deleting = false;
          entity.documents[i].deleting = action.error;
        }
      }
      return { ...entity };
    case DOCUMENT_DELETED:
      if (!entity || entity.id !== ownerId) {
        return entity;
      }
      if (!action.document) {
        return entity;
      }
      listDocuments = entity.documents;
      if (!listDocuments) {
        listDocuments = [];
      }
      // remove given document from array of documents of given type
      listDocuments = listDocuments.filter(
        value => !areAttachmentsEqual(value, action.document)
      );
      return {
        ...entity,
        documents: [...listDocuments]
      };
  }
  return entity;
}
