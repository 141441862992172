import React, { Component } from "react";
import { connect } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent/index";
import Dialog from "@material-ui/core/Dialog/index";
import CreatWorkflowTypeForm from "./CreateWorkflowTypeForm";
import { createWorkflowType } from "actions/Workflow/types";
import { withStyles } from "@material-ui/core/styles";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";

class CreateWorkflowTypeButton extends Component {
  componentWillReceiveProps(nextProps) {
    const { creating, error_creating } = nextProps;
    if (this.props.creating && !creating && !error_creating) {
      this.onClose();
    }
  }
  onClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };
  onCreate = (form) => {
    const { createWorkflow } = this.props;
    createWorkflow(form);
  };

  render() {
    const { open } = this.props;
    return (
      <Dialog onClose={this.onClose} open={open}>
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={this.onClose}>
          Create a new workflow type
        </CloseableDialogTitle>
        <DialogContent>
          <CreatWorkflowTypeForm onSubmit={this.onCreate} />
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = () => ({
  root: {},
});

const mapStateToProps = (state, ownProps) => {
  return {
    error_creating: state.workflowTypes.error_creating,
    creating: state.workflowTypes.creating,
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, { createWorkflow: createWorkflowType })(
    CreateWorkflowTypeButton
  )
);
