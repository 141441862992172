import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Empty from "../Table/Empty";
import { TABLE_VIEW } from "constants/ResultFormats";
import { loadFiltersConfig, runSearch } from "actions/Search";
import {
  getEntityRoleCreate,
  getUsersRowPerPageForEntity,
  MODEL_MONITORING_TYPE,
} from "api/Entity";
import { Grid } from "@material-ui/core";
import { updateUserPreferences } from "actions/Configuration";
import Pagination from "components/Search/Pagination";
import TableTopMenu from "components/Search/TableTopMenu";
import DrawerFilter from "components/Search/DrawerFilter";
import getDefaultSearchFilters from "api/Search";
import { getSearchMonitoringMetadata } from "actions/Monitoring";
import DrawerExport from "components/ExportHistory/DrawerExport";

const TableManager = ({
  entityType,
  filters,
  order,
  orderBy,
  url,
  children,
  onCreate,
  enableExportHistory,
  createLabel,
}) => {
  const dispatch = useDispatch();
  const preferences = useSelector(state => state.auth.preferences);
  const selectedAttributes = useSelector(
    state => state.attributes.attributesToShow
  );
  const running_filters = useSelector(state =>
    state.filters.running_filters
      ? state.filters.running_filters[entityType]
      : undefined
  );
  const last_filters_runned = useSelector(state =>
    state.filters.last_filters_runned
      ? state.filters.last_filters_runned[entityType]
      : undefined
  );
  const last_current_filters_runned = useSelector(state =>
    state.filters.last_current_filters_runned
      ? state.filters.last_current_filters_runned[entityType]
      : undefined
  );
  const meta = useSelector(state =>
    state.filters.meta ? state.filters.meta[entityType] : undefined
  );
  const loading_config = useSelector(state => state.filters.loading_config);

  const [lastExecutedWorkingFilter, setLastExecutedWorkingFilter] = useState(
    last_filters_runned || filters || getDefaultSearchFilters()
  );
  const [lastExecutedCurrnetFilter, setLastExecutedCurrentFilter] = useState(
    last_current_filters_runned || filters || getDefaultSearchFilters()
  );
  const [drawerFilterOpened, setDrawerFilterOpened] = useState(false);
  const [drawerExportOpened, setDrawerExportOpened] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (entityType) {
      if (entityType === MODEL_MONITORING_TYPE) {
        getSearchMonitoringMetadata()(dispatch);
      } else {
        loadFiltersConfig(entityType)(dispatch);
      }
    }
  }, [entityType]);

  useEffect(() => {
    if (preferences && preferences[getUsersRowPerPageForEntity(entityType)]) {
      setRowsPerPage(preferences[getUsersRowPerPageForEntity(entityType)]);
    }
  }, [preferences]);

  useEffect(() => {
    refreshTableData();
  }, [rowsPerPage, page, order, orderBy]);

  const refreshTableData = () => {
    if (running_filters && running_filters.sourceAxios) {
      running_filters.sourceAxios.cancel(
        "Operation canceled due to new request."
      );
    }

    runSearch(
      {
        filters: lastExecutedWorkingFilter,
        page: page,
        rowsPerPage: rowsPerPage,
        resultFormat: TABLE_VIEW,
        order: order,
        orderBy: orderBy,
      },
      entityType,
      url
    )(dispatch);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    if (preferences) {
      preferences[getUsersRowPerPageForEntity(entityType)] = event.target.value;
      updateUserPreferences({ ...preferences, selectedAttributes })(dispatch);
    }
  };

  /*
  Execute search based on provided filters and actual settings (pagination etc.)
  */
  const handleSearch = filters => {
    runSearch(
      {
        filters: { ...filters },
        page: page,
        rowsPerPage: rowsPerPage,
        resultFormat: TABLE_VIEW,
        order: order,
        orderBy: orderBy,
      },
      entityType,
      url
    )(dispatch);
    setLastExecutedWorkingFilter(filters);
  };

  const removeAppliedFilter = () => {
    handleSearch(getDefaultSearchFilters());
  };

  const hasResults = meta && meta.totalResultCount > 0;

  return (
    <div className={"scrollable-container"}>
      <div className={"scrollable-header"}>
        <TableTopMenu
          filters={filters}
          onCreate={onCreate}
          createLabel={createLabel}
          enableExportHistory={enableExportHistory}
          entityType={entityType}
          handleOpenDrawerFilter={() => setDrawerFilterOpened(true)}
          onOpenDrawer={() => setDrawerExportOpened(true)}
          removeAppliedFilter={removeAppliedFilter}
        />
      </div>

      <div className={"scrollable-content"}>
        {!running_filters && hasResults && children}

        {(!hasResults || running_filters) && (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Empty
                title="No results"
                buttonTitle={createLabel}
                onCreate={onCreate}
                loadingTitle="Loading the entities"
                loading={running_filters}
                authoritiesRequired={[getEntityRoleCreate(entityType)]}
              />
            </Grid>
          </Grid>
        )}
      </div>

      {meta && !running_filters && hasResults && (
        <div className={"scrollable-footer"}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                totalResultCount={meta.totalResultCount}
                handleChangePage={(event, page) => setPage(page)}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </div>
      )}

      <DrawerFilter
        entityType={entityType}
        loading={loading_config}
        drawerFilterOpened={drawerFilterOpened}
        workingFilter={lastExecutedCurrnetFilter}
        handleCloseDrawerFilter={() => setDrawerFilterOpened(false)}
        handleSearch={handleSearch}
        handleChangeLastExecutedCurrentFilter={setLastExecutedCurrentFilter}
      />
      <DrawerExport
        entityType={entityType}
        isOpened={drawerExportOpened}
        hideDrawer={() => setDrawerExportOpened(false)}
      />
    </div>
  );
};

export default TableManager;
