import {
  GET_REPOSITORY,
  LOADING_REPOSITORY,
  END_LOADING_REPOSITORY,
  ERROR_LOADING_REPOSITORY,
  CREATE_REPOSITORY,
  CREATING_REPOSITORY,
  END_CREATING_REPOSITORY,
  ERROR_CREATING_REPOSITORY
} from "../constants/ActionTypes";

export default function(entity, action) {
  let ownerId = action.ownerId;
  if (!entity || ownerId !== entity.id) {
    return entity;
  }

  switch (action.type) {
    case GET_REPOSITORY:
      return {
        ...entity,
        repositories: action.repositories
      };
    case LOADING_REPOSITORY:
      return {
        ...entity,
        loading_repository: true,
        error_repository: undefined
      };
    case END_LOADING_REPOSITORY:
      return {
        ...entity,
        loading_repository: false
      };
    case ERROR_LOADING_REPOSITORY:
      return {
        ...entity,
        error_repository: action.error
      };

    case CREATE_REPOSITORY:
      let repositories = entity.repositories;
      if (!repositories) {
        repositories = [];
      }
      return {
        ...entity,
        repositories: [...repositories, action.repository]
      };
    case CREATING_REPOSITORY:
      return {
        ...entity,
        creating_repository: true,
        error_creating_repository: undefined
      };
    case END_CREATING_REPOSITORY:
      return {
        ...entity,
        creating_repository: false
      };
    case ERROR_CREATING_REPOSITORY:
      return {
        ...entity,
        error_creating_repository: action.error
      };
  }
  return entity;
}
