import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getOwnSavedSearches } from "actions/Search";
import { Paper, Typography } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import SelectField from "components/Form/Fields/SelectField";
import { RequiredValidator } from "components/Form/Fields/FieldValidators";
import MenuItem from "components/Buttons/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { setFormatSelected } from "../../../actions/ExportHistory";

const availableFormatsList = [
  {
    label: "CSV",
    value: "csv",
  },
];

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "5px 16px",
  },
}));

let FormatSelector = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formatSelected = useSelector(
    state => state.exportHistory.selectedFormat
  );

  useEffect(() => {
    setFormatSelected(availableFormatsList[0].value)(dispatch);
  }, []);

  const handleFormatSelected = (e, newValue) => {
    setFormatSelected(newValue)(dispatch);
  };

  return (
    <Paper variant="outlined" className={classes.container}>
      <Typography variant="subtitle1">Format:</Typography>
      <form>
        <Field
          name="formatSelected"
          component={SelectField}
          onChange={handleFormatSelected}
          value={formatSelected}
          validate={[RequiredValidator]}
        >
          {availableFormatsList.map(function(format) {
            return (
              <MenuItem key={format.value} value={format.value}>
                {format.label}
              </MenuItem>
            );
          })}
        </Field>
      </form>
    </Paper>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me,
  };
};

FormatSelector = reduxForm({
  form: "formatSelectorForm",
  initialValues: {
    formatSelected: availableFormatsList[0].value,
  },
})(FormatSelector);

export default connect(mapStateToProps, { getOwnSavedSearches })(
  FormatSelector
);
