import React, {useState} from "react";
import IconButton from "components/Buttons/IconButton";
import Widget from "./Widget";
import { GridOn, InsertChartOutlined } from "@material-ui/icons";
import { DialogContent, Dialog, Tooltip } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";

const WidgetButton = ({widget}) => {
  const [open, setOpen] = useState(false);

  if (!widget) {
    return null;
  }
  let icon;
  switch (widget.resultFormat) {
    case "TABLE":
      icon = <GridOn />;
      break;
    case "TIMESERIES":
      icon = <InsertChartOutlined />;
      break;
  }
  return (
    <div>
      <Tooltip placement="top" title={widget.name}>
        <IconButton onClick={()=>setOpen(true)} color="primary">
          {icon}
        </IconButton>
      </Tooltip>
      <Dialog maxWidth="md" fullWidth onClose={()=>setOpen(false)} open={open}>
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={()=>setOpen(false)}
        >
          {widget.name}
        </CloseableDialogTitle>
        <DialogContent>
          <Widget widget={widget} hideVariablesButton />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WidgetButton;
