import React, { Component } from "react";
import { Drawer, Tabs, Typography } from "@material-ui/core";
import SaveFiltersDialogForm from "components/Search/SaveFiltersDialogForm";
import ConfirmRemoveSavedFiltersDialogForm from "components/Search/ConfirmRemoveSavedFiltersDialogForm";
import Tab from "components/TabContainer/Tab";
import { SAVED_SEARCH } from "constants/Features";
import {
  ROLE_SAVEDSEARCH_EDIT,
  ROLE_SAVEDSEARCH_VIEW,
} from "../../constants/Authorities";
import SwipeableViews from "react-swipeable-views";
import CreateFilterModelsForm from "components/Search/CreateFilterModelsForm";
import SavedFiltersView from "components/Search/SavedFiltersView";
import EditFilterModelsForm from "components/Search/EditFilterModelsForm";
import { connect } from "react-redux";
import {
  clearForm,
  editFilters,
  getOwnSavedSearches,
  getSharedSavedSearches,
  removeFilters,
  saveFilters,
} from "actions/Search";
import { MODEL_REVISION_TYPE, MODEL_TYPE } from "../../api/Entity";
import APPCONFIG from "../../constants/Config";
import { isFeaturesAuthorized } from "../../api/Authorities";
import { FILTER_TYPE } from "../../constants/Filters";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class DrawerFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterView: 0, // Currently active tab
      editMode: false, // Option for the third tab (read-write)
      showMode: false, // Option for the third tab (read-only)
      openConfirmRemoveSavedFiltersDialogForm: false,
      filtersToRemove: undefined,
      openSaveFiltersDialogForm: false,
      filtersToSave: undefined,
      filterToEdit: undefined, // Filter for the edit/view tab
      workingFilter: props.workingFilter, // Filter for the working (Current) tab
    };
  }

  handleSaveFiltersEdited = (form) => {
    const { editFilters } = this.props;
    const { filterToEdit } = this.state;
    editFilters({
      ...filterToEdit,
      filters: form,
    });
  };

  handleSaveFilters = (values) => {
    this.setState({
      filtersToSave: values,
      openSaveFiltersDialogForm: true,
    });
  };

  handleSubmitSaveFiltersDialog = (form) => {
    const { entityType, saveFilters } = this.props;
    saveFilters({
      searchedEntity:
        entityType === MODEL_TYPE ? MODEL_REVISION_TYPE : entityType,
      label: form.label,
      shared: form.shared,
      filters: this.state.filtersToSave,
    });
    this.setState({
      openSaveFiltersDialogForm: false,
    });
  };

  handleCloseSaveFiltersDialog = () => {
    this.setState({
      openSaveFiltersDialogForm: false,
    });
  };

  onRemoveSavedFilter = (saved_filters) => {
    this.setState({
      filtersToRemove: saved_filters,
      openConfirmRemoveSavedFiltersDialogForm: true,
    });
  };

  handleCancelRemoveSavedFiltersDialog = () => {
    this.setState({
      filtersToRemove: undefined,
      openConfirmRemoveSavedFiltersDialogForm: false,
    });
  };

  handleRemoveSavedFiltersDialog = () => {
    const { filtersToRemove } = this.state;
    const { removeFilters } = this.props;
    removeFilters(filtersToRemove);
    this.setState({
      filtersToRemove: undefined,
      openConfirmRemoveSavedFiltersDialogForm: false,
    });
  };

  handleFilterViewChangeIndex = (index) => {
    this.setState({ filterView: index });
  };

  handleCloseFilterEditing = () => {
    // Close the third edit tab on save and go back to the second tab
    this.setState({
      editMode: false,
      showMode: false,
      filterView: 1,
    });
  };

  onEditSavedFilter = (saved_filters) => {
    const { me } = this.props;
    const secLevelIds = saved_filters.searchDefinition.securityLevelIds;
    this.setState({
      filterView: 2,
      showMode: false,
      editMode: true,
      filterToEdit: {
        ...saved_filters,
        searchDefinition: {
          ...saved_filters.searchDefinition,
          securityLevelIds: me.securityLevels.filter((secLevel) =>
            secLevelIds.includes(secLevel.id)
          ),
        },
      },
    });
  };

  onShowSavedFilter = (saved_filters) => {
    const { me } = this.props;
    const secLevelIds = saved_filters.searchDefinition.securityLevelIds;
    this.setState({
      filterView: 2,
      editMode: false,
      showMode: true,
      filterToEdit: {
        ...saved_filters,
        searchDefinition: {
          ...saved_filters.searchDefinition,
          securityLevelIds: me.securityLevels.filter((secLevel) =>
            secLevelIds.includes(secLevel.id)
          ),
        },
      },
    });
  };

  onRunSavedFilter = (saved_filters) => {
    const { handleSearch, me } = this.props;
    const secLevelIds = saved_filters.searchDefinition.securityLevelIds;
    handleSearch({
      id: saved_filters.id,
      name: saved_filters.label,
      filterType: FILTER_TYPE.SAVED,
      attributeValueFilter: saved_filters.searchDefinition.attributeValueFilter,
      workflowVariableValueFilter:
        saved_filters.searchDefinition.workflowVariableValueFilter,
      typeIds: saved_filters.searchDefinition.typeIds,
      securityLevelIds: me.securityLevels.filter((secLevel) =>
        secLevelIds.includes(secLevel.id)
      ),
      modelUsageTypeIds: saved_filters.searchDefinition.modelUsageTypeIds,
      modelActiveFlag: saved_filters.searchDefinition.modelActiveFlag,
    });
  };

  refreshSavedSearchFilters = (props) => {
    const { me, features, getOwnSavedSearches, getSharedSavedSearches } = props;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }

    this.refreshTimeout = setTimeout(
      () => this.refreshSavedSearchFilters(this.props),
      APPCONFIG.REFRESH_TIMEOUT
    );

    if (isFeaturesAuthorized(features, [SAVED_SEARCH])) {
      if (me && me.id) {
        getOwnSavedSearches();
      }
      getSharedSavedSearches();
    }
  };

  componentWillReceiveProps(nextProps) {
    const { me } = nextProps;
    if (this.props.me !== me) {
      this.refreshSavedSearchFilters(nextProps);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { drawerFilterOpened } = this.props;
    if (nextProps.drawerFilterOpened && !drawerFilterOpened) {
      this.refreshSavedSearchFilters(nextProps);
    } else if (!nextProps.drawerFilterOpened && this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  renderFilterView() {
    const {
      loading,
      error_config,
      config,
      entityType,
      workingFilter,
      handleSearch,
      handleChangeLastExecutedCurrentFilter,
    } = this.props;

    const { filterView, editMode, showMode, filterToEdit } = this.state;

    if (!loading && (!config || !config.entityTypes)) {
      return (
        <div style={{ margin: "0 20px", lineHeight: "30px" }}>
          <div>We couldn't load the filters' configuration</div>
          <div>Error: {error_config}</div>
        </div>
      );
    }

    return (
      <div style={{ maxWidth: "700px", minWidth: "500px" }}>
        <Tabs
          value={filterView}
          onChange={(event, index) => this.handleFilterViewChangeIndex(index)}>
          <Tab label="Custom" />
          <Tab
            label="Saved"
            featuresRequired={[SAVED_SEARCH]}
            authoritiesRequired={[ROLE_SAVEDSEARCH_VIEW]}
          />
          {(showMode || editMode) && filterToEdit && (
            <Tab
              label={filterToEdit.label}
              authoritiesRequired={[ROLE_SAVEDSEARCH_EDIT]}
            />
          )}
        </Tabs>
        <SwipeableViews
          index={filterView}
          onChangeIndex={this.handleFilterViewChangeIndex}>
          <TabContainer>
            <CreateFilterModelsForm
              entityType={entityType}
              onSaveSearch={this.handleSaveFilters}
              onClearFilter={this.props.clearForm}
              onSubmit={(form) => {
                const current = {
                  ...form,
                  filterType: FILTER_TYPE.CUSTOM,
                };
                handleSearch(current);
                handleChangeLastExecutedCurrentFilter(current);
              }}
              initialValues={workingFilter}
            />
          </TabContainer>
          <TabContainer>
            <SavedFiltersView
              searchedEntity={entityType}
              onShow={this.onShowSavedFilter}
              onEdit={this.onEditSavedFilter}
              onRun={this.onRunSavedFilter}
              onRemove={this.onRemoveSavedFilter}
            />
          </TabContainer>
          {(showMode || editMode) && filterToEdit && (
            <TabContainer>
              <EditFilterModelsForm
                entityType={entityType}
                disabled={showMode}
                onSaveSearch={this.handleSaveFiltersEdited}
                onCloseEditing={this.handleCloseFilterEditing}
                onSubmit={(form) => {
                  handleSearch({
                    ...form,
                    name: filterToEdit.label,
                    filterType: FILTER_TYPE.SAVED,
                    id: filterToEdit.id,
                  });
                }}
                initialValues={filterToEdit.searchDefinition}
              />
            </TabContainer>
          )}
        </SwipeableViews>
      </div>
    );
  }

  render() {
    const { handleCloseDrawerFilter, drawerFilterOpened } = this.props;
    const {
      filtersToRemove,
      openConfirmRemoveSavedFiltersDialogForm,
      openSaveFiltersDialogForm,
    } = this.state;

    const currentFiltersTitleToRemoveTitle = filtersToRemove
      ? filtersToRemove.label
      : undefined;
    const filtersView = this.renderFilterView();

    return (
      <React.Fragment>
        <Drawer
          open={drawerFilterOpened}
          onClose={handleCloseDrawerFilter}
          style={{ borderRight: "solid 1px #f2f2f2", padding: "20px 0" }}>
          {filtersView}
        </Drawer>

        <SaveFiltersDialogForm
          open={openSaveFiltersDialogForm}
          onClose={this.handleCloseSaveFiltersDialog}
          onSubmit={this.handleSubmitSaveFiltersDialog}
        />
        <ConfirmRemoveSavedFiltersDialogForm
          name={currentFiltersTitleToRemoveTitle}
          open={openConfirmRemoveSavedFiltersDialogForm}
          handleCancel={this.handleCancelRemoveSavedFiltersDialog}
          handleOk={this.handleRemoveSavedFiltersDialog}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me,
    config: state.filters.config,
    error_config: state.filters.error_config,
    features: state.features.list,
  };
};

export default connect(mapStateToProps, {
  getOwnSavedSearches,
  getSharedSavedSearches,
  clearForm,
  removeFilters,
  saveFilters,
  editFilters,
})(DrawerFilter);
