import React from "react";
import Button from "../Buttons/Button";
import { DialogActions, Dialog } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { connect } from "react-redux";
import { manageFileConflict } from "actions/Attachments";

const ManageFileConflicts = ({ conflicts, manageFileConflict }) => {
  return (
    conflicts &&
    conflicts.map(({ id, message, onAccept }) => (
      <Dialog key={id} onClose={() => manageFileConflict(id)} open>
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={() => manageFileConflict(id)}
        >
          {message}
        </CloseableDialogTitle>
        <DialogActions>
          <Button onClick={() => manageFileConflict(id)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              manageFileConflict(id);
              onAccept();
            }}
            color="primary"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    ))
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    conflicts: state.attachments.conflicts
  };
};

export default connect(
  mapStateToProps,
  { manageFileConflict }
)(ManageFileConflicts);
