import React, { useState } from "react";
import { Dialog, DialogContent, Grid, withStyles } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import InputField from "components/Form/Fields/InputField";
import {
  NonEmptyStringValidator,
  RequiredValidator
} from "components/Form/Fields/FieldValidators";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import CheckboxField from "components/Form/Fields/CheckboxField";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { connect } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import DateField from "components/Form/Fields/DateField";
import { getEntityLabel, getEntityReducerAttribute } from "../../../api/Entity";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {
  STORAGE_TYPE_DATA_HUB,
  STORAGE_TYPE_DOCUMENTS
} from "components/Attachments/Search/index";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Info } from "@material-ui/icons";
import IconButton from "components/Buttons/IconButton";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import { scrollToFirstError } from "api/Error";
import {getSortingByLabel} from "api/Sort";

const SYNTAX_ACTIONS = [
  {
    action: "+",
    description: "AND operation"
  },
  {
    action: "|",
    description: "OR operation"
  },
  {
    action: "-",
    description: "Negates a single token"
  },
  {
    action: '"',
    description: "Wraps a number of tokens to signify a phrase for searching"
  },
  {
    action: "*",
    description: "At the end of a term signifies a prefix query"
  },
  {
    action: "( and )",
    description: "Signify precedence"
  },
  {
    action: "~N",
    description: "After a word signifies edit distance (fuzziness)"
  },
  {
    action: "~N",
    description: "After a phrase signifies slop amount"
  }
];

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650
  },
  field: {
    marginBottom: theme.spacing(3),
    width: "600px"
  }
}));

const DatahubSearchForm = ({
  handleSubmit,
  error_search,
  searching,
  types,
  entity,
  context
}) => {
  const [openInfoSyntaxDialog, setOpenInfoSyntaxDialog] = useState(false);
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          label="Search in Data hub"
          name="text"
          component={InputField}
          type="search"
          margin="dense"
          variant="outlined"
          autoFocus
          onKeyDown={e => {
            if (e.keyCode === 13) {
              handleSubmit();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setOpenInfoSyntaxDialog(true)}>
                  <Info />
                </IconButton>
              </InputAdornment>
            )
          }}
          validate={[RequiredValidator, NonEmptyStringValidator]}
        />
      </div>
      <div className={classes.field}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Field
              label="Filter by author"
              name="author"
              component={AutoSuggestSelectField}
              validate={[]}
              apiUrl="/api/users/lookup"
              maxResultNumber={10}
              withEmptyOpt={true}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Filter by type"
              name="attachmentType"
              multiSection
              options={Object.entries(types).map(([entity, types]) => ({
                title: entity,
                suggestions: types.list.sort(getSortingByLabel).map(type => ({
                  id: type.id,
                  label: type.label
                }))
              }))}
              component={AutoSuggestSelectField}
              validate={[]}
            />
          </Grid>
        </Grid>
      </div>
      {context && entity ? (
        <div className={classes.field}>
          <Field
            label={
              <>
                Search only in the{" "}
                {context.storageTypePlatform === STORAGE_TYPE_DOCUMENTS
                  ? "documents of the "
                  : context.subFolder
                  ? "current subfolder of the "
                  : ""}
                <span style={{ textTransform: "lowercase" }}>
                  {getEntityLabel(context.entityType)}
                </span>
                <Typography variant="caption" display="block">
                  {entity && entity.extendedLabel ? entity.extendedLabel : ""}
                  {context.storageTypePlatform === STORAGE_TYPE_DATA_HUB &&
                  context.subFolder &&
                  context.subFolder !== "/"
                    ? `  ${context.subFolder}`
                    : ""}
                </Typography>
              </>
            }
            name="inCurrentFolder"
            component={CheckboxField}
            validate={[]}
          />
        </div>
      ) : (
        <div className={classes.field}>
          <Field
            label="Search only in the documents"
            name="inDocuments"
            component={CheckboxField}
            validate={[]}
          />
        </div>
      )}
      <div className={classes.field}>
        <InputLabel>Upload date</InputLabel>
        <Grid container spacing={1}>
          <Grid item>
            <Field
              placeholder={"From"}
              name="from"
              maxDate={moment()}
              component={DateField}
              validate={[]}
            />
          </Grid>
          <Grid item>
            <Field
              placeholder={"Till"}
              name="till"
              maxDate={moment()}
              component={DateField}
              validate={[]}
            />
          </Grid>
        </Grid>
      </div>
      <div className="error">{error_search}</div>
      <ButtonLoading
        testid="searchindatahub"
        loading={searching}
        loadingTitle="Searching in files"
        className="color-primary"
        type="submit"
        variant="outlined"
        color="primary"
      >
        Search in Data hub
      </ButtonLoading>
      <Dialog open={openInfoSyntaxDialog} maxWidth="md" fullWidth>
        <CloseableDialogTitle
          id="simple-dialog-title"
          handleClose={() => setOpenInfoSyntaxDialog(false)}
        >
          Query syntax info
        </CloseableDialogTitle>
        <DialogContent>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {SYNTAX_ACTIONS.map(({ action, description }, idx) => (
                <TableRow key={idx}>
                  <TableCell component="th" scope="row">
                    {action}
                  </TableCell>
                  <TableCell>{description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { context } = ownProps;
  if (context && context.entityType && context.entityId) {
    return {
      types: state.attachments.types,
      error_search: state.attachments.error_attachments_search,
      searching: state.attachments.loading_attachments_search,
      entity: state[getEntityReducerAttribute(context.entityType)].current
    };
  }
  return {
    types: state.attachments.types,
    error_search: state.attachments.error_attachments_search,
    searching: state.attachments.loading_attachments_search
  };
};

export default connect(
  mapStateToProps,
  {}
)(
  reduxForm({
    form: "datahubsearchform",
    onSubmitFail: scrollToFirstError
  })(DatahubSearchForm)
);
