import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import {
  Grid,
  Dialog,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from '@material-ui/core';

import Button from '../Buttons/Button';
import EditForm from './EditForm';
import { uppercaseFirstLetter } from 'api/GUI';
import { areAttachmentsEqual } from 'api/Attachments';
import { LIST_MODE } from 'constants/Attachments';
import DeleteButton from 'components/Attachments/DeleteButton';
import EditName from 'components/Attachments/EditName';
import File from 'components/Attachments/File';
import SubDirectory from 'components/Attachments/SubDirectory';
import EnhancedTableToolbar from 'components/Table/EnhancedTableToolbar';
import DeleteAttachmentDialogue from "components/Attachments/DeleteAttachmentDialogue";

const DataStorage = ({
  attachments,
  onSelectionChange,
  onUpdateAttachment,
  onRenameAttachment,
  onDeleteAttachment,
  typesAttachment,
  currentSubfolder,
  withSubfolder,
  onDragStart,
  onDragEnd,
  onDrop,
  isDragged,
  draggedFiles,
  onSubfolderChange,
  onRemoveSubDirectory,
  onRenameSubDirectory,
  onDeleteAttachments,
  onShowHistory,
  selected,
  isSelected,
  getDownloadLink,
  getLatestDownloadLink,
  attachmentsLabel,
  attachmentLabel,
  withoutTypes,
}) => {

  const mode = useSelector((state) => state.settings.attachment_view || LIST_MODE);
  const [attachmentToUpdate, setAttachmentToUpdate] = useState(null);
  const [attachmentToRename, setAttachmentToRename] = useState(null);
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);

  const handleUpdateAttachment = (form) => {
    if (onUpdateAttachment) {
      onUpdateAttachment(attachmentToUpdate, form);
    }
    setAttachmentToUpdate(null);
  };

  const handleRenameAttachment = (form) => {
    if (onRenameAttachment) {
      onRenameAttachment(attachmentToRename, form);
    }
    setAttachmentToRename(null);
  };

  const handleConfirmDeleting = () => {
    if (onDeleteAttachment) {
      onDeleteAttachment(attachmentToDelete);
    }
    setAttachmentToDelete(null);
  };

  const getDrop = (file) => {
    return file.isFolder && onDrop
      ? () => onDrop(currentSubfolder + file.fileName)
      : null
  };

  const getEdit = (file) => {
    return !onUpdateAttachment || file.isFolder
      ? null
      : () => setAttachmentToUpdate(file)
  };

  const getDelete = (file) => {
    return onDeleteAttachment
      ? () => setAttachmentToDelete(file)
      : null
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      onSelectionChange([
        ...(attachments ? attachments : []),
      ]);
      return;
    }
    onSelectionChange([]);
  };

  const onSelect = (file) => () => {
    let newSelected = [],
      found = false;
    for (let i = 0; selected && i < selected.length; i++) {
      const selectedFile = selected[i];
      if (areAttachmentsEqual(selectedFile, file)) {
        found = true;
      } else {
        newSelected.push(selectedFile);
      }
    }
    if (!found) {
      newSelected.push(file);
    }
    onSelectionChange(newSelected);
  };

  const isSelecting = selected && selected.length > 0;
  let listContainer;
  const list = (
    <>
      {attachments &&
      attachments.map((file) => {
        if (withSubfolder && file.prefix !== currentSubfolder) {
          return null;
        }
        if (file.isFolder && mode === 'grid') {
          return null;
        }
        let onRename = undefined;
        if (file.isFolder) {
          if (onRenameSubDirectory) {
            onRename = () => {
              onRenameSubDirectory(file);
            };
          }
        } else if (onRenameAttachment) {
          onRename = () => setAttachmentToRename(file);
        }
        const isDraggedBool = isDragged(file);

        const actions = {
          onSelect: onSelect(file),
          onDragStart: (event) => onDragStart && onDragStart(event, file),
          onDragEnd: (event) => onDragEnd && onDragEnd(event, file),
          onSubfolderChange,
          onShowHistory,
          onDrop: getDrop(file),
          onRename: onRename,
          onEdit: getEdit(file),
          onDelete: getDelete(file),
        };

        const viewStatus = {
          draggable: Boolean(onDrop),
          isDragged: isDraggedBool,
          isDragging: Boolean(draggedFiles),
          droppable: file.isFolder && !isDraggedBool,
          isSelected: isSelected(file) || false,
          isSelecting,
        };

        return (
          <File
            key={file.objectName}
            actions={actions}
            configuration={{ mode, isHistory: false, withoutTypes }}
            file={{ ...file, getDownloadLink, getLatestDownloadLink, currentSubfolder }}
            viewStatus={viewStatus}
            typesAttachment={typesAttachment}
          />
        );
      })}
    </>
  );

  const rowCount = (attachments ? attachments.length : 0);
  const numSelected = selected ? selected.length : 0;

  switch (mode) {
    case 'grid':
      listContainer = (
        <>
          <Grid
            container
            spacing={1}
            style={{ padding: '0 10px', width: '100%', margin: 0 }}
          >
            {attachments &&
            attachments.map((attachment, idx) => {
              if (!attachment.isFolder) {
                return null;
              }
              const isDraggedBool = isDragged(attachment);
              return (
                <Grid item key={idx}>
                  <SubDirectory
                    draggable={Boolean(onDrop)}
                    isDragging={Boolean(draggedFiles)}
                    isSelected={isSelected(attachment)}
                    isSelecting={isSelecting}
                    onDragStart={event => {
                      onDragStart && onDragStart(event, attachment);
                    }}
                    onDragEnd={event => {
                      onDragEnd && onDragEnd(event, attachment);
                    }}
                    droppable={!isDraggedBool}
                    onSelect={onSelect(attachment)}
                    onDrop={getDrop(attachment)}
                    onRename={
                      onRenameSubDirectory
                        ? () => {
                          onRenameSubDirectory(attachment);
                        }
                        : null
                    }
                    onRemove={() => {
                      onRemoveSubDirectory &&
                      onRemoveSubDirectory(attachment);
                    }}
                    onOpenDirectory={() =>
                      onSubfolderChange &&
                      onSubfolderChange(
                        currentSubfolder + attachment.fileName,
                      )
                    }
                    subDirectory={attachment}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            spacing={1}
            style={{ padding: '0 10px', margin: 0, width: '100%' }}
          >
            {list}
          </Grid>
        </>
      );
      break;
    case 'list':
      listContainer = (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell style={{ padding: '0 10px' }}>Icon</TableCell>
              <TableCell>Name</TableCell>
              {!withoutTypes && <TableCell>Type</TableCell>}
              <TableCell>Uploaded on</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Last update</TableCell>
              <TableCell style={{ padding: 0 }}>Size</TableCell>
              <TableCell>Validation</TableCell>
              <TableCell style={{ padding: 0 }}/>
            </TableRow>
          </TableHead>
          <TableBody>{list}</TableBody>
        </Table>
      );
      break;
  }

  return (
    <div className={'scrollable-container'}>
      <EnhancedTableToolbar
        className={'scrollable-header'}
        withoutAttributeFilters
        title={uppercaseFirstLetter(attachmentsLabel)}
        actionProps={{
          onDeleteAttachments: onDeleteAttachments,
          currentSubfolder: currentSubfolder,
          attachmentsLabel: attachmentsLabel,
          attachmentLabel: attachmentLabel,
        }}
        actions={ACTIONS}
        selectedEntities={selected}
      />
      <div className={'scrollable-content'}>
        {listContainer}
      </div>
      <EditForm
        handleClose={() => setAttachmentToUpdate(null)}
        open={attachmentToUpdate}
        attachmentToUpdate={attachmentToUpdate}
        typesAttachment={typesAttachment}
        onSubmit={(form) => handleUpdateAttachment(form)}
      />
      <EditName
        handleClose={() => setAttachmentToRename(null)}
        open={attachmentToRename}
        attachmentToUpdate={attachmentToRename}
        onSubmit={(form) => handleRenameAttachment(form)}
      />
      <DeleteAttachmentDialogue
        handleClose={() => setAttachmentToDelete(null)}
        handleConfirmDeleting={handleConfirmDeleting}
        attachmentToDelete={attachmentToDelete}
        attachmentLabel={attachmentLabel}
        />
    </div>
  );
};

const ACTIONS = [
  {
    component: DeleteButton,
    authoritiesRequired: [],
  },
];

export default DataStorage;
