import React from 'react';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  header: {
    padding: "0 10px",
    margin: "10px 0"
  },
}));

const PanelHeader = ({
  buttonEl
}) => {

  const classes = useStyles();

  return (
    <Grid container justify="flex-end" className={classes.header}>
      {buttonEl}
    </Grid>
  );
};

export default PanelHeader;
