import React, { Component } from "react";
import ButtonAbstract from "../RestrictedComponent";
import { IconButton as IconButtonMaterial } from "@material-ui/core";

class IconButton extends Component {
  render() {
    return (
      <ButtonAbstract
        icon="true"
        componentButton={IconButtonMaterial}
        {...this.props}
      />
    );
  }
}

export default IconButton;
