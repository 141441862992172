import React, { Component } from "react";
import {
  NonEmptyStringValidator,
  OnlyIntegerValidator,
  OnlyNumberValidator,
  RequiredValidator,
} from "components/Form/Fields/FieldValidators";
import { Field } from "redux-form";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import SelectField from "components/Form/Fields/SelectField";
import { MenuItem } from "@material-ui/core";
import IconButton from "components/Buttons/IconButton";
import { Delete } from "@material-ui/icons";
import { connect } from "react-redux";
import { resetAttributeFilter } from "actions/Search";
import { changeField, unregisterFormField } from "actions";
import InputField from "components/Form/Fields/InputField";
import DateField from "components/Form/Fields/DateField";

const types = {
  STRING: InputField,
  DATE: DateField,
  INTEGER: InputField,
  FLOAT: InputField,
  DECIMAL: InputField,
  ENUM: AutoSuggestSelectField,
  REFERENCE: AutoSuggestSelectField,
  URL: InputField,
};

class AttributeFilterRow extends Component {
  state = {
    attributeSelected: undefined,
    operatorSelected: undefined,
    warningAttributeNotAvailable: undefined,
  };

  constructor(props) {
    super(props);
    this.manageInitialValues(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { initialValues, availableAttributes, config, values } = nextProps;
    if (initialValues !== this.props.initialValues) {
      this.manageInitialValues(nextProps);
      this.setState({});
    }
    if (values !== this.props.values) {
      this.state.attributeSelected = this.attributeSelected(
        values.attributeName
      );
      this.setState({
        operatorSelected: this.operatorSelected(values.operator),
      });
    }
    if (availableAttributes !== this.props.availableAttributes) {
      this.reloadFields(nextProps);
    }
    if (config !== this.props.config) {
      this.reloadFields(nextProps);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { attributeSelected, operatorSelected } = this.state;
    const {
      unregisterFormField,
      formName,
      attributeFilter,
      resetAttributeFilter,
    } = this.props;
    const prevOperatorFieldName = // e.g. intValue, stringValue...
      prevState.operatorSelected && prevState.operatorSelected.inputField;
    if (!attributeSelected && prevOperatorFieldName) {
      resetAttributeFilter(formName, attributeFilter, prevOperatorFieldName);
    } else if (!operatorSelected && prevOperatorFieldName) {
      unregisterFormField(formName, attributeFilter + ".operator");
      unregisterFormField(
        formName,
        attributeFilter + "." + prevOperatorFieldName
      );
    } else if (
      prevOperatorFieldName &&
      operatorSelected.inputField !== prevOperatorFieldName
    ) {
      unregisterFormField(
        formName,
        attributeFilter + "." + prevOperatorFieldName
      );
    }
  }

  reloadFields = (props) => {
    const { attributeSelected } = this.state;
    const { availableAttributes } = props;

    if (attributeSelected) {
      let attributeSelectedStillAvailable = false;
      for (let i = 0; i < availableAttributes.length; i++) {
        const section = availableAttributes[i];
        for (let j = 0; j < section.suggestions.length; j++) {
          const availableAttribute = section.suggestions[j];
          if (availableAttribute.name === attributeSelected.name) {
            attributeSelectedStillAvailable = true;
            break;
          }
        }
        if (attributeSelectedStillAvailable) {
          break;
        }
      }
      if (!attributeSelectedStillAvailable) {
        this.resetFields(props);
      }
    }
  };

  resetFields = (props) => {
    const { attributeSelected } = this.state;
    const { attributeFilter, formName, config } = props;
    let inputName = "";
    if (config && config.operators) {
      if (attributeSelected.type && config.operators[attributeSelected.type]) {
        inputName = config.operators[attributeSelected.type].inputName;
      }
    }
    this.props.resetAttributeFilter(formName, attributeFilter, inputName);
    this.setState({
      attributeSelected: undefined,
      operatorSelected: undefined,
      warningAttributeNotAvailable:
        "Attribute '" + attributeSelected.label + "' is not available anymore",
    });
  };

  manageInitialValues = (props) => {
    const { initialValues, values } = props;
    if (initialValues) {
      if (initialValues.attributeName !== undefined) {
        this.state.attributeSelected = this.attributeSelected(
          initialValues.attributeName
        );
      }
      if (initialValues.operator !== undefined) {
        this.state.operatorSelected = this.operatorSelected(
          initialValues.operator
        );
      }
    }
    if (values) {
      this.state.attributeSelected = this.attributeSelected(
        values.attributeName
      );
      this.setState({
        operatorSelected: this.operatorSelected(values.operator),
      });
    }
  };

  getOperators = () => {
    const { attributeSelected } = this.state;
    const { config } = this.props;
    if (config && config.operators && attributeSelected) {
      return config.operators[attributeSelected.type];
    }
    return [];
  };

  attributeSelected = (value) => {
    if (!value) {
      return null;
    }
    const { availableAttributes } = this.props;
    let attributeSelected;
    for (let i = 0; i < availableAttributes.length; i++) {
      const section = availableAttributes[i];
      for (let j = 0; j < section.suggestions.length; j++) {
        const availableAttribute = section.suggestions[j];
        if (availableAttribute.name === value) {
          attributeSelected = availableAttribute;
          break;
        }
      }
      if (attributeSelected) {
        break;
      }
    }
    return attributeSelected;
  };

  operatorSelected = (value) => {
    if (!value) {
      return null;
    }
    const { attributeSelected } = this.state;
    const { config } = this.props;
    if (
      !config ||
      !config.operators ||
      !attributeSelected ||
      !attributeSelected.type ||
      !config.operators[attributeSelected.type]
    ) {
      return undefined;
    }

    const operators = config.operators[attributeSelected.type];
    let operatorSelected;
    for (let i = 0; i < operators.length; i++) {
      const operator = operators[i];
      if (operator.name === value) {
        operatorSelected = operator;
        break;
      }
    }
    if (operatorSelected) {
      operatorSelected.typeName = value;
    }
    return operatorSelected;
  };

  render() {
    const {
      attributeFilter,
      onRemove,
      availableAttributes,
      enumEntries,
      disabled,
      labelAttribute,
    } = this.props;
    const {
      operatorSelected,
      warningAttributeNotAvailable,
      attributeSelected,
    } = this.state;
    const id = attributeFilter;

    const operators = this.getOperators();
    const operatorDisabled = operators.length === 0;

    let valueDisabled = operatorDisabled || !operatorSelected;
    let inputValue = "div",
      inputName,
      attributeOptions = [],
      apiUrl,
      apiValueFromIdUrl,
      operatorValue,
      validatesValue = [RequiredValidator, NonEmptyStringValidator];
    if (
      operatorSelected &&
      attributeSelected &&
      attributeSelected.type &&
      types[attributeSelected.type]
    ) {
      inputValue = types[attributeSelected.type];
      if (attributeSelected.type === "INTEGER") {
        validatesValue.push(OnlyIntegerValidator);
      } else if (["FLOAT", "DECIMAL"].includes(attributeSelected.type)) {
        validatesValue.push(OnlyNumberValidator);
      }
    }
    if (operatorSelected) {
      inputName = operatorSelected.inputField;
      operatorValue = operatorSelected.name;
    }
    if (attributeSelected && attributeSelected.type) {
      switch (attributeSelected.type) {
        case "ENUM":
          if (enumEntries) {
            attributeOptions = enumEntries
              .filter((item) => item.enumName === attributeSelected.enumName)
              .map((item) => ({
                id: item.ordinal,
                label: item.label,
              }));
          }
          break;
        case "REFERENCE":
          if (attributeSelected.referencedEntity === "APP_USER") {
            apiUrl = "/api/users/lookup";
            apiValueFromIdUrl = "/api/users";
          }
          break;
      }
    }

    if (
      operatorSelected &&
      (operatorSelected.typeName.endsWith("IS_NULL") ||
        operatorSelected.typeName.endsWith("IS_NOT_NULL") ||
        operatorSelected.typeName.endsWith("IS_TRUE") ||
        operatorSelected.typeName.endsWith("IS_FALSE"))
    ) {
      inputValue = "div";
      validatesValue = [];
      valueDisabled = true;
    }

    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        key={id}>
        <div style={{ flex: 1, marginRight: 10 }}>
          <Field
            disabled={disabled}
            label={""}
            name={id + ".attributeName"}
            multiSection
            placeholder={labelAttribute}
            component={AutoSuggestSelectField}
            validate={[RequiredValidator, NonEmptyStringValidator]}
            ref={id + ".attributeName"}
            options={availableAttributes.map((section) => ({
              ...section,
              suggestions: section.suggestions.map((attribute2Filter) => ({
                id: attribute2Filter.name,
                label: attribute2Filter.label,
              })),
            }))}
            warning={warningAttributeNotAvailable}
          />
        </div>
        <div style={{ flex: 1, marginRight: 10 }}>
          <Field
            label={""}
            name={id + ".operator"}
            ref={id + ".operator"}
            placeholder="Operator"
            disabled={disabled || operatorDisabled}
            value={operatorValue}
            component={SelectField}
            validate={[RequiredValidator, NonEmptyStringValidator]}>
            {operators.map(function (operator, idx) {
              return (
                <MenuItem key={idx} value={operator.name}>
                  {operator.label}
                </MenuItem>
              );
            })}
          </Field>
        </div>
        <div style={{ flex: 1, marginRight: 10 }}>
          <Field
            label={""}
            placeholder="Value"
            name={id + "." + inputName}
            ref={id + "." + inputName}
            component={inputValue}
            disabled={disabled || valueDisabled}
            options={attributeOptions}
            validate={validatesValue}
            apiValueFromIdUrl={apiValueFromIdUrl}
            apiUrl={apiUrl}
          />
        </div>
        <div style={{ width: "50px" }}>
          <IconButton
            disabled={disabled}
            aria-label="Delete"
            onClick={onRemove}
            testid="removeAttributeSearch">
            <Delete />
          </IconButton>
        </div>
      </div>
    );
  }
}

const mapStateToPropsAttribut = (state, ownProps) => {
  return {
    config: state.filters.config,
    enumEntries: state.enumEntries.list,
  };
};

export default AttributeFilterRow = connect(mapStateToPropsAttribut, {
  resetAttributeFilter,
  changeField,
  unregisterFormField,
})(AttributeFilterRow);
