import {
  DELETE_ENTITY,
  DELETING_ENTITY,
  END_DELETING_ENTITY,
  ERROR_DELETING_ENTITY
} from "../constants/ActionTypes";
import { MODEL_TYPE, getEntityType } from "./Entity";
import { goThroughList } from "./List";
import { is_revision_matching } from "./Model";

export default function(entity, action) {
  if (!entity) {
    return null;
  }

  let ownerId = action.ownerId;
  const entityType = getEntityType(entity);

  if (
    entityType === MODEL_TYPE &&
    !is_revision_matching(entity, ownerId) &&
    entity.id !== ownerId
  ) {
    return entity;
  }
  if (entity.id !== ownerId && entityType !== MODEL_TYPE) {
    return entity;
  }

  let result;

  switch (action.type) {
    case DELETE_ENTITY:
      if (!entity || ownerId !== entity.id) {
        if (entityType === MODEL_TYPE) {
          result = goThroughList(entity.revisions, null, ownerId, revision => {
            return null;
          });
        }
        return {
          ...entity,
          revisions: result.list
        };
      }
      return null;
    case DELETING_ENTITY:
      if (!entity || ownerId !== entity.id) {
        if (entityType === MODEL_TYPE) {
          result = goThroughList(entity.revisions, null, ownerId, revision => {
            return {
              ...revision,
              deleting: true,
              error_deleting: undefined
            };
          });
        }
        return {
          ...entity,
          revisions: result.list
        };
      }
      return {
        ...entity,
        deleting: true,
        error_deleting: undefined
      };
    case END_DELETING_ENTITY:
      if (!entity || ownerId !== entity.id) {
        if (entityType === MODEL_TYPE) {
          result = goThroughList(entity.revisions, null, ownerId, revision => {
            return {
              ...revision,
              deleting: false
            };
          });
        }
        return {
          ...entity,
          revisions: result.list
        };
      }
      return {
        ...entity,
        deleting: false
      };
    case ERROR_DELETING_ENTITY:
      if (!entity || ownerId !== entity.id) {
        if (entityType === MODEL_TYPE) {
          result = goThroughList(entity.revisions, null, ownerId, revision => {
            return {
              ...revision,
              error_deleting: action.error
            };
          });
        }
        return {
          ...entity,
          revisions: result.list
        };
      }
      return {
        ...entity,
        error_deleting: action.error
      };
  }
  return entity;
}
