import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../Buttons/ButtonLoading";
import CreateContainersDialogForm from "./CreateDialogForm";
import { ROLE_CONTAINER_CREATE } from "../../constants/Authorities";
import { createAnalyticalToolContainer } from "../../actions/Containers/index";
import { InsertChartOutlined } from "@material-ui/icons";

class EntityButton extends Component {
  state = {
    open: false
  };

  handleCreateAnalyticalToolContainer = () => {
    this.setState({
      open: true
    });
  };
  handleCloseCreateAnalyticalTool = () => {
    this.setState({
      open: false
    });
  };
  onSubmitAnalyticalTool = form => {
    const { createAnalyticalToolContainer, entity } = this.props;
    createAnalyticalToolContainer(
      form.username,
      form.password,
      form.imageAlias,
      [entity]
    );
  };

  render() {
    const { entity, opening_analytical_tool } = this.props;
    const { open } = this.state;
    if (!entity || !entity.id) {
      return null;
    }

    return (
      <React.Fragment>
        <ButtonLoading
          testid="createdatalab"
          tooltipPlacement="left"
          tooltipTitle="Create a Datalab"
          loading={opening_analytical_tool}
          loadingTitle="Creating the Datalab"
          loadingSize={22}
          style={{
            width: "100%",
            justifyContent: "flex-start",
            textAlign: "left"
          }}
          color="primary"
          authoritiesRequired={[ROLE_CONTAINER_CREATE]}
          aria-label="Create a Datalab"
          onClick={this.handleCreateAnalyticalToolContainer}
        >
          <span
            className="badge"
            style={{
              position: "absolute",
              top: "-3px",
              left: "25px",
              color: "#607c8a",
              fontSize: "15px"
            }}
          >
            +
          </span>
          <InsertChartOutlined style={{ marginRight: 15 }} />
          Create a Datalab
        </ButtonLoading>

        <CreateContainersDialogForm
          open={open}
          handleClose={this.handleCloseCreateAnalyticalTool}
          onSubmit={this.onSubmitAnalyticalTool}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    opening_analytical_tool: state.containers.opening_analytical_tool,
    error_opening_analytical_tool:
      state.containers.error_opening_analytical_tool
  };
};

export default connect(
  mapStateToProps,
  {
    createAnalyticalToolContainer
  }
)(EntityButton);
