import React, { Component } from "react";
import MenuItem from "./MenuItem";
import { CircularProgress, Tooltip } from "@material-ui/core";

class MenuItemLoading extends Component {
  render() {
    const {
      loading,
      loadingTitle,
      tooltipTitle,
      tooltipPlacement,
      loadingSize,
      ...rest
    } = this.props;
    let button;
    if (loading) {
      button = (
        <MenuItem
          {...rest}
          onClick={() => {
            /*click ignored on loading*/
          }}
        >
          <div>
            <span
              style={{
                verticalAlign: "middle",
                width: loadingSize,
                height: loadingSize
              }}
            >
              <CircularProgress size={loadingSize} />
            </span>
            <span style={{ verticalAlign: "middle", marginLeft: 15 }}>
              {loadingTitle}
            </span>
          </div>
        </MenuItem>
      );
    } else {
      button = <MenuItem {...rest} />;
    }
    if (tooltipTitle) {
      return (
        <Tooltip
          placement={tooltipPlacement}
          title={loading ? loadingTitle : tooltipTitle}
        >
          {button}
        </Tooltip>
      );
    }
    return button;
  }
}
MenuItemLoading.defaultProps = {
  loadingSize: 14
};

export default MenuItemLoading;
