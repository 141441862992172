import React, { Component } from "react";
import { connect } from "react-redux";
import IconButton from "../Buttons/IconButton";
import ContainersList from "./List";
import { getContainersUser } from "actions/Containers";
import { ROLE_CONTAINER_VIEW } from "../../constants/Authorities";
import RestrictedComponent from "../RestrictedComponent";
import { Typography, Tooltip, Menu, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import APPCONFIG from "constants/Config";
import { DATALAB } from "constants/Features";
import { InsertChartOutlined } from "@material-ui/icons";
import ContainerCreateButton from "./EntitiesButton";
import { HeaderIconButtonStyle } from "components/Header/NavRightList";
import {isFeaturesAuthorized} from "api/Authorities";

class MenuHeader extends Component {
  state = {
    containersOpened: null
  };
  refreshTimeout = null;

  constructor(props) {
    super(props);
    const { features, getContainersUser } = props;
    if(isFeaturesAuthorized(features, [DATALAB])){
      getContainersUser();
    }
  }

  refreshTabTimer = () => {
    this.refreshDatas(this.props);
  };

  refreshDatas = props => {
    const { getContainersUser } = props;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTabTimer();
    }, APPCONFIG.REFRESH_TIMEOUT);

    getContainersUser();
  };

  componentWillUpdate(nextProps, nextState) {
    const { containersOpened } = this.state;
    if (nextState.containersOpened && !containersOpened) {
      this.refreshDatas(nextProps);
    } else if (!nextState.containersOpened && this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  handleClickMenuContainers = event => {
    this.setState({
      containersOpened: event.currentTarget
    });
  };
  handleCloseMenuContainers = () => {
    this.setState({
      containersOpened: null
    });
  };

  render() {
    const { containers, loading, error, classes } = this.props;

    const { containersOpened } = this.state;
    let menuContainers,
      styleButton = { marginLeft: 20 };
    if (containers && containers.length > 0) {
      menuContainers = (
        <div>
          <Typography variant="subtitle2" style={{ padding: "0px 20px" }}>
            My Datalabs
          </Typography>
          <ContainersList withoutUser containers={containers} />
        </div>
      );
    } else if (loading) {
      menuContainers = (
        <div
          style={{ lineHeight: "36px", textAlign: "center", margin: "0 10px" }}
        >
          <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
          <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
            Loading the Datalabs
          </span>
        </div>
      );
    } else if (error) {
      menuContainers = <div className="error">{error}</div>;
    } else {
      menuContainers = <div className={classes.empty}>No Datalab found</div>;
      styleButton = { textAlign: "center", margin: "5px 0" };
    }

    return (
      <li className="list-inline-item" testid="menuheaderdatalab">
        <Tooltip title="My Datalabs">
          <IconButton
            onClick={this.handleClickMenuContainers}
            style={HeaderIconButtonStyle}
            className="md-button header-btn"
          >
            <InsertChartOutlined />
          </IconButton>
        </Tooltip>
        <Menu
          PaperProps={{
            style: {
              width: 650
            }
          }}
          open={Boolean(containersOpened)}
          onClose={this.handleCloseMenuContainers}
          anchorEl={containersOpened}
        >
          {menuContainers}
          <div style={styleButton}>
            <ContainerCreateButton selectedEntities={null} />
          </div>
        </Menu>
      </li>
    );
  }
}

const styles = theme => ({
  empty: {
    width: "100%",
    textAlign: "center",
    marginTop: 5
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    containers: state.containers.list_user,
    loading: state.containers.loading_user,
    error: state.containers.error_user,
    features: state.features.list
  };
};
MenuHeader = withStyles(styles)(
  connect(
    mapStateToProps,
    { getContainersUser }
  )(MenuHeader)
);

export default () => {
  return (
    <RestrictedComponent
      componentButton={MenuHeader}
      featuresRequired={[DATALAB]}
      authoritiesRequired={[ROLE_CONTAINER_VIEW]}
    />
  );
};
