import React from 'react';

import { DialogActions, DialogContent, withStyles } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';

import { scrollToFirstError } from 'api/Error';
import { RequiredValidator } from 'components/Form/Fields/FieldValidators';
import { ROLE_SYSTEM_ADMINISTRATION } from 'constants/Authorities';
import InputField from 'components/Form/Fields/InputField';
import ButtonLoading from 'components/Buttons/ButtonLoading';

const styles = {
  field: {
    margin: '20px 0',
    '&:first-of-type': {
      marginTop: 0,
    },
  },
};

const SecurityLevelDialogForm = withStyles(styles)(
  reduxForm({
    form: 'securityLevelForm',
    onSubmitFail: scrollToFirstError,
  })(
    ({ handleSubmit, classes, errorSaving, saving, initialValues }) => {
      return (
        <form onSubmit={handleSubmit}>
          <DialogContent style={{ paddingTop: 0 }}>
            <div className={classes.field}>
              <Field
                label="Name"
                name="name"
                required
                component={InputField}
                validate={[RequiredValidator]}
              />
            </div>
            <div className={classes.field}>
              <Field
                label="Key"
                name="key"
                required
                component={InputField}
                validate={[RequiredValidator]}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonLoading
              authoritiesRequired={[ROLE_SYSTEM_ADMINISTRATION]}
              testid="submituser"
              loading={saving}
              loadingTitle="Updating the security level"
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {'Save the security level'}
            </ButtonLoading>
            <div className="error">{errorSaving}</div>
          </DialogActions>
        </form>
      );
    },
  ),
);

export default SecurityLevelDialogForm;
