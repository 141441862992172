import React, {Component} from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers'
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons'
import FormControl from './FormControl';
import moment from 'moment';
import Config from "constants/Config";

class DateField extends Component {

    render() {
        const { disabled, label, input, withTimeFormatting, placeholder, maxDate } = this.props;
        return (
            <FormControl { ...this.props }>
                { (label)? <label>{ label }</label>: "" }
                <KeyboardDatePicker
                    clearable
                    autoOk={true}
                    placeholder={placeholder}
                    leftArrowIcon={<KeyboardArrowLeft/>}
                    rightArrowIcon={<KeyboardArrowRight/>}
                    format={Config.dateFormat}
                    maxDate={maxDate}
                    disabled={disabled}
                    invalidLabel={ "Enter a date" }
                    invalidDateMessage={null}
                    onChange={(date) => {
                        if (!date) {
                            input.onChange(null);
                        } else if(withTimeFormatting) {
                            input.onChange(moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
                        } else {
                            input.onChange(moment(date).format('YYYY-MM-DD'));
                        }
                    }}
                    value={(input.value === '')? null: input.value}
                    color="primary"
                    id={input.name} />
            </FormControl>
        );
    }
}

export default DateField;
