import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {getAttachmentsRedux} from "actions/Attachments";
import APPCONFIG from "constants/Config";
import { FOLDERS_OVERVIEW_PATH } from "constants/Routes";
import EntityDirectory from "components/Attachments/EntityDirectory";
import { getEntityType } from "api/Entity";
import { getCurrentURLParams } from "api/GUI";

class FoldersOverview extends Component {
  refreshTimeout = null;

  constructor(props) {
    super(props);
    const { location } = props;
    const query = getCurrentURLParams(location.search);
    this.state = {
      currentSubfolder: query && query["folder"] ? query["folder"] : "/"
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { folder, history } = this.props;
    if (
      folder &&
      prevProps.folder !== folder &&
      (!prevProps.folder || prevProps.folder.id !== folder.id)
    ) {
      this.refreshTab(this.props);
    }
    if (!folder && prevProps.folder) {
      history.push(FOLDERS_OVERVIEW_PATH);
    }
  }

  componentDidMount() {
    this.refreshTab(this.props);
  }
  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  refreshTab = props => {
    const { getAttachments, folder } = props;
    const { currentSubfolder } = this.state;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTab(this.props);
    }, APPCONFIG.REFRESH_TIMEOUT);

    if (folder && folder.id) {
      getAttachments(folder.id, getEntityType(folder), currentSubfolder);
    }
  };

  onSubfolderChange = subfolder => {
    this.setState(
      {
        currentSubfolder: subfolder
      },
      () => {
        this.refreshTab(this.props);
      }
    );
  };

  render() {
    const { folder, droppedFiles } = this.props;
    const { currentSubfolder } = this.state;

    return (
      <EntityDirectory
        withDirectory
        onSubfolderChange={this.onSubfolderChange}
        currentSubfolder={currentSubfolder}
        droppedFiles={droppedFiles}
        entity={folder}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    folder: state.folders.current
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { getAttachments: getAttachmentsRedux }
  )(FoldersOverview)
);
