import {
  LOADING_VALIDATIONS_MODEL,
  END_LOADING_VALIDATIONS_MODEL,
  GET_VALIDATIONS_MODEL,
  VALIDATIONS_MODEL_ERROR,
  GET_ENTITY,
  UNASSIGN_VALIDATION_MODEL,
  ASSIGNING_VALIDATION_MODEL,
  END_ASSIGNING_VALIDATION_MODEL,
  ASSIGNING_VALIDATION_MODEL_ERROR,
  GET_MODELS_OF_MODEL_TYPE,
  LOADING_MODELS_OF_MODEL_TYPE,
  END_LOADING_MODELS_OF_MODEL_TYPE,
  MODELS_OF_MODEL_TYPE_ERROR,
  OPENING_ANALYTICAL_TOOL,
  END_OPENING_ANALYTICAL_TOOL,
  ERROR_OPENING_ANALYTICAL_TOOL
} from "../../constants/ActionTypes";
import reduceEntity from "../../api/ReduceEntity";
import { is_revision_matching, is_revisions_matching } from "../../api/Model";
import { MODEL_TYPE, VALIDATION_TYPE } from "../../api/Entity";

export default function(state = {}, action) {
  const entity = action.entity;
  const modelRevisionId = action.modelRevisionId;
  const modelRevisionIds = action.modelRevisionIds;
  let model = state.current;
  let found = false;

  switch (action.type) {
    case GET_VALIDATIONS_MODEL:
      if (!model || action.modelId !== model.id) {
        return state;
      }
      return {
        ...state,
        current: {
          ...model,
          validations: action.validations
        }
      };
    case LOADING_VALIDATIONS_MODEL:
      if (!model || action.modelId !== model.id) {
        return state;
      }
      return {
        ...state,
        current: {
          ...model,
          loading_validations: true,
          error_validations: undefined
        }
      };
    case END_LOADING_VALIDATIONS_MODEL:
      if (!model || action.modelId !== model.id) {
        return state;
      }
      return {
        ...state,
        current: {
          ...model,
          loading_validations: false
        }
      };
    case VALIDATIONS_MODEL_ERROR:
      if (!model || action.modelId !== model.id) {
        return state;
      }
      return {
        ...state,
        current: {
          ...model,
          error_validations: action.error
        }
      };

    case UNASSIGN_VALIDATION_MODEL:
      if (is_revision_matching(model, modelRevisionId)) {
        let newValidations = [];
        for (
          let i = 0;
          model && model.validations && i < model.validations.length;
          i++
        ) {
          const validation = model.validations[i];
          if (validation.id !== action.validationId) {
            newValidations.push(validation);
          }
        }
        return {
          ...state,
          current: {
            ...model,
            validations: newValidations
          }
        };
      }
      return { ...state };
    case ASSIGNING_VALIDATION_MODEL:
      if (is_revision_matching(model, modelRevisionId)) {
        return {
          ...state,
          current: {
            ...model,
            assigning_model: true,
            assigning_model_error: undefined
          }
        };
      }
      return { ...state };
    case END_ASSIGNING_VALIDATION_MODEL:
      if (is_revision_matching(model, modelRevisionId)) {
        return {
          ...state,
          current: {
            ...model,
            assigning_model: false
          }
        };
      }
      return { ...state };
    case ASSIGNING_VALIDATION_MODEL_ERROR:
      if (is_revision_matching(model, modelRevisionId)) {
        return {
          ...state,
          current: {
            ...model,
            assigning_model_error: action.error
          }
        };
      }
      return { ...state };
    case GET_ENTITY:
      if (action.entityType === VALIDATION_TYPE) {
        let newValidations = [];
        for (
          let i = 0;
          model && model.validations && i < model.validations.length;
          i++
        ) {
          const validation2 = model.validations[i];
          if (validation2.id === entity.id) {
            //validation already in the model
            newValidations.push(entity);
            found = true;
          } else {
            newValidations.push(validation2);
          }
        }
        if (
          !found &&
          (is_revision_matching(model, modelRevisionId) ||
            is_revisions_matching(model, modelRevisionIds))
        ) {
          newValidations.push(entity);
        }
        return {
          ...state,
          current: {
            ...model,
            validations: newValidations
          }
        };
      }
      break;

    case LOADING_MODELS_OF_MODEL_TYPE:
      return {
        ...state,
        loadingModelsOfModelType: true,
        errorModelsOfModelType: undefined
      };
    case END_LOADING_MODELS_OF_MODEL_TYPE:
      return {
        ...state,
        loadingModelsOfModelType: false
      };
    case MODELS_OF_MODEL_TYPE_ERROR:
      return {
        ...state,
        errorModelsOfModelType: action.error
      };
    case GET_MODELS_OF_MODEL_TYPE:
      return {
        ...state,
        modelsOfModelType: {
          results: action.results,
          meta: action.meta,
          attributes: action.attributes
        }
      };
  }
  return {
    ...state,
    current: reduceEntity(model, action, MODEL_TYPE)
  };
}
