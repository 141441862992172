import React, { Component } from "react";
import {
  VictoryChart,
  VictoryTooltip,
  VictoryLine,
  createContainer,
  VictoryTheme,
  VictoryAxis,
  VictoryLegend
} from "victory";
import moment from "moment";
import * as _ from "lodash";
import { getColorsScale } from "../../constants/Colors";
import Config from "../../constants/Config";
const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

const COLOR_SCALE = [
  "#607d8b",
  "#458c97",
  "#259a95",
  "#2ca682",
  "#58af63",
  "#8ab43d",
  "#c2b10e",
  "#ffa600"
];

function valuesAdaptator(values) {
  let datas = [];
  const valuesX = Object.keys(values);
  for (let i = 0; valuesX && i < valuesX.length; i++) {
    const valueX = valuesX[i];
    datas.push({
      x: new Date(valueX),
      y: Number(values[valueX])
    });
  }
  return datas;
}

class ChartWidget extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.entireDomain = this.getEntireDomain(props);
    if (this.entireDomain) {
      this.state = {
        zoomedXDomain: this.entireDomain.x
      };
    }
  }
  componentWillReceiveProps(nextProps) {
    const { widget } = nextProps;
    if (widget !== this.props.widget) {
      this.entireDomain = this.getEntireDomain(nextProps);
      if (this.entireDomain) {
        this.setState({
          zoomedXDomain: this.entireDomain.x
        });
      }
    }
  }
  onDomainChange = domain => {
    this.setState({
      zoomedXDomain: domain.x
    });
  };
  getData(serie) {
    const { zoomedXDomain } = this.state;
    const { maxPoints } = this.props;
    const data = valuesAdaptator(serie.values);
    if (data.length < maxPoints) {
      return data;
    }
    const filtered = data.filter(
      d =>
        moment(d.x).isSameOrAfter(zoomedXDomain[0]) &&
        moment(d.x).isSameOrBefore(zoomedXDomain[1])
    );

    if (filtered.length > maxPoints) {
      const k = Math.ceil(filtered.length / maxPoints);
      return filtered.filter((d, i) => i % k === 0);
    }
    return filtered;
  }
  getEntireDomain = props => {
    const { widget } = props;
    if (!widget || !widget.results) {
      return null;
    }
    let minY, maxY, minX, maxX;

    for (
      let i = 0;
      widget && widget.results && i < widget.results.length;
      i++
    ) {
      const serie = widget.results[i];
      const valuesX = Object.keys(serie.values);
      const valuesY = Object.values(serie.values);
      const currentMinY = _.minBy(valuesY, o => {
        return Number(o);
      });
      const currentMaxY = _.maxBy(valuesY, o => {
        return Number(o);
      });
      const currentMinX = _.min(valuesX);
      const currentMaxX = _.max(valuesX);
      if (!minX || moment(currentMinX).isBefore(minX)) {
        minX = currentMinX;
      }
      if (!maxX || moment(currentMaxX).isAfter(maxX)) {
        maxX = currentMaxX;
      }
      if (!minY || minY > currentMinY) {
        minY = currentMinY;
      }
      if (!maxY || maxY < currentMaxY) {
        maxY = currentMaxY;
      }
    }
    return {
      y: [minY, maxY],
      x: [new Date(minX), new Date(maxX)]
    };
  };

  render() {
    const { widget } = this.props;
    const { zoomedXDomain } = this.state;
    if (!widget || !widget.results || !this.entireDomain) {
      return null;
    }
    const inTime = moment(zoomedXDomain[1]).diff(zoomedXDomain[0]) <= 86400000;

    return (
      <VictoryChart
        scale={{ x: "time" }}
        theme={VictoryTheme.grayscale}
        domain={this.entireDomain}
        style={{
          labels: { fontFamily: "roboto" },
          parent: {
            overflow: "visible"
          },
          overflow: "visible"
        }}
        containerComponent={
          <VictoryZoomVoronoiContainer
            voronoiDimension="x"
            labels={d => `${d.childName}: ${d.y}`}
            labelComponent={
              <VictoryTooltip
                style={{ fontFamily: "roboto", fontSize: 10 }}
                cornerRadius={0}
                flyoutStyle={{ fill: "white" }}
              />
            }
            zoomDimension="x"
            zoomDomain={zoomedXDomain}
            onZoomDomainChange={this.onDomainChange}
          />
        }
      >
        <VictoryLegend
          x={100}
          title={null}
          colorScale={getColorsScale(
            widget && widget.results && widget.results.length
          )}
          orientation="vertical"
          rowGutter={0}
          padding={{ top: 5, bottom: 5, left: 5, right: 5 }}
          style={{
            border: { stroke: "black", strokeWidth: 1 },
            title: { fontSize: 15, fontFamily: "roboto" },
            labels: { fontSize: 10, fontFamily: "roboto" }
          }}
          data={
            widget &&
            widget.results &&
            widget.results.map(s => {
              return {
                name: s.label
              };
            })
          }
        />
        <VictoryAxis
          style={{
            tickLabels: { fontFamily: "roboto", fontSize: "10px", width: 50 }
          }}
          tickFormat={tick =>
            inTime
              ? moment(tick).format(Config.timeFormat)
              : moment(tick).format(Config.dateFormat)
          }
        />
        <VictoryAxis
          style={{
            tickLabels: { fontFamily: "roboto", fontSize: "10px" }
          }}
          dependentAxis
        />
        {widget &&
          widget.results &&
          widget.results.map((s, idx) => (
            <VictoryLine
              key={idx}
              style={{
                data: { stroke: COLOR_SCALE[idx] }
              }}
              name={s.label}
              data={this.getData(s)}
            />
          ))}
      </VictoryChart>
    );
  }
}

export default ChartWidget;
