import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ROLE_SAVEDSEARCH_DELETE,
  ROLE_SAVEDSEARCH_EDIT
} from "../../constants/Authorities";
import { MODEL_REVISION_TYPE, MODEL_TYPE } from "../../api/Entity";
import IconButtonLoading from "components/Buttons/IconButtonLoading";
import {
  Delete,
  Edit,
  ExpandMore,
  Visibility,
  Group,
  PlayArrow
} from "@material-ui/icons";

import {
  CircularProgress,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from "@material-ui/core";

class SavedFiltersView extends Component {
  renderFilterItem = (savedFilter, index, isOwnFilter) => {
    let runButton, removeButton, showButton, isSharedIcon;
    const {
      onRun,
      onRemove,
      running_filters,
      onCancelRun,
      onEdit,
      onShow
    } = this.props;

    if (
        running_filters &&
        running_filters.filters &&
      savedFilter.id === running_filters.filters.id
    ) {
      runButton = (
        <IconButtonLoading
          testid="cancelSharedSearch"
          tooltipTitle="Cancel the running filters"
          aria-label="Cancel"
          onClick={() => {
            onCancelRun(savedFilter);
          }}
        >
          <CircularProgress size={25} />
        </IconButtonLoading>
      );
    } else {
      runButton = (
        <IconButtonLoading
          testid="runSharedSearch"
          tooltipTitle="Run filters"
          aria-label="Run"
          onClick={() => {
            onRun(savedFilter);
          }}
        >
          <PlayArrow />
        </IconButtonLoading>
      );
    }

    if (isOwnFilter) {
      removeButton = (
        <IconButtonLoading
          testid="deleteSharedSearch"
          tooltipTitle="Remove filters"
          loading={savedFilter.removing}
          loadingTitle="Removing filters"
          authoritiesRequired={[ROLE_SAVEDSEARCH_DELETE]}
          aria-label="Remove filters"
          onClick={() => {
            onRemove(savedFilter);
          }}
        >
          <Delete />
        </IconButtonLoading>
      );
    } else {
      showButton = (
        <IconButtonLoading
          testid="showSharedSearch"
          tooltipTitle="Show filters"
          authoritiesRequired={[]}
          aria-label="Show filters"
          onClick={() => {
            onShow(savedFilter);
          }}
        >
          <Visibility />
        </IconButtonLoading>
      );
    }

    if (isOwnFilter && savedFilter.shared) {
      isSharedIcon = (
        <Tooltip title="Is shared">
          <span>
            <IconButtonLoading
              authoritiesRequired={[ROLE_SAVEDSEARCH_EDIT]}
              tooltipTitle="Is shared"
              aria-label="Is shared"
              disabled={true}
              testid="editSharedSearch"
              /*onClick={() => {doSomething(savedFilter)}} TODO: implement action when this 'icon' changes to real button*/
            >
              <Group />
            </IconButtonLoading>
          </span>
        </Tooltip>
      );
    }

    return (
      <React.Fragment key={index}>
        <ListItem
          style={{ paddingLeft: 0, paddingRight: 0 }}
          key={index}
          role={undefined}
          dense
        >
          <ListItemText
            primary={savedFilter.label}
            secondary={
              isOwnFilter ? "" : `Owned by ${savedFilter.owner.displayName}`
            }
          />
          <ListItemSecondaryAction style={{ right: 0 }}>
            {isSharedIcon}
            {showButton}
            {runButton}
            {isOwnFilter && (
              <IconButtonLoading
                tooltipTitle="Update filters"
                loading={savedFilter.updating}
                loadingTitle="Updating filters"
                authoritiesRequired={[ROLE_SAVEDSEARCH_EDIT]}
                aria-label="Edit"
                onClick={() => {
                  onEdit(savedFilter);
                }}
              >
                <Edit />
              </IconButtonLoading>
            )}
            {removeButton}
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </React.Fragment>
    );
  };

  render() {
    const {
      own_saved_filters,
      shared_saved_filters,
      searchedEntity
    } = this.props;

    if (!own_saved_filters || !shared_saved_filters) {
      return null;
    }

    return (
      <React.Fragment>
        <ExpansionPanel testid="mysearches">
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1">My filters</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List style={{ width: "100%" }}>
              {own_saved_filters
                .filter(
                  savedFilter => savedFilter.searchedEntity === searchedEntity
                )
                .map((savedFilter, index) => {
                  return this.renderFilterItem(savedFilter, index, true);
                })}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel testid="sharedsearches">
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1">Shared filters</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List style={{ width: "100%" }}>
              {shared_saved_filters
                .filter(
                  savedFilter => savedFilter.searchedEntity === searchedEntity
                )
                .map((savedFilter, index) => {
                  return this.renderFilterItem(savedFilter, index, false);
                })}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { searchedEntity } = ownProps;
  return {
    searchedEntity:
      searchedEntity === MODEL_TYPE ? MODEL_REVISION_TYPE : searchedEntity,
    own_saved_filters: state.filters.own_saved_filters,
    shared_saved_filters: state.filters.shared_saved_filters,
    running_filters: state.filters.running_filters ? state.filters.running_filters[searchedEntity] : undefined
  };
};

export default connect(
  mapStateToProps,
  {}
)(SavedFiltersView);
