import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../Buttons/ButtonLoading";
import { Field, reduxForm } from "redux-form";
import {
  RequiredValidator,
  NonEmptyStringValidator
} from "../Form/Fields/FieldValidators";
import SelectField from "../Form/Fields/SelectField";
import InputField from "../Form/Fields/InputField";
import { MenuItem, InputAdornment, Grid } from "@material-ui/core";
import { scrollToFirstError } from "../../api/Error";

const methods = ["GET", "POST", "PUT", "PATCH", "DELETE"];
const contentTypes = [
  {
    name: "JSON",
    type: "application/json"
  },
  {
    name: "Text",
    type: "text/plain"
  },
  {
    name: "form-data",
    type: "multipart/form-data"
  },
  {
    name: "x-www-form-urlencoded",
    type: "application/x-www-form-urlencoded"
  },
  {
    name: "HTML",
    type: "text/html"
  },
  {
    name: "XML",
    type: "text/xml"
  },
  {
    name: "JavaScript",
    type: "application/javascript"
  }
];

const acceptTypes = [
  {
    name: "JSON",
    type: "application/json"
  },
  {
    name: "Text",
    type: "text/plain"
  }
];

class RestForm extends Component {
  render() {
    const { handleSubmit, executing, error_rest } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} style={{ paddingBottom: 5 }}>
              <Field
                label={"Endpoint"}
                name="endpoint"
                component={InputField}
                validate={[RequiredValidator]}
                startAdornment={
                  <InputAdornment
                    position="start"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {window.location.origin + "/api/"}
                  </InputAdornment>
                }
                ref="endpoint"
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 5 }}>
              <Field
                label={"Body"}
                name="body"
                component={InputField}
                validate={[]}
                multiline
                ref="body"
              />
            </Grid>
            <Grid item xs={2} style={{ marginBottom: 5 }}>
              <Field
                label={"Method"}
                name="method"
                component={SelectField}
                validate={[RequiredValidator, NonEmptyStringValidator]}
                ref="method"
              >
                {methods.map(function(method, idx) {
                  return (
                    <MenuItem key={idx} value={method}>
                      {method}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
            <Grid item xs={2} style={{ marginBottom: 5 }}>
              <Field
                label={"Content Type"}
                name="contentType"
                component={SelectField}
                validate={[RequiredValidator, NonEmptyStringValidator]}
                ref="contentType"
              >
                {contentTypes.map(function(contentType, idx) {
                  return (
                    <MenuItem key={idx} value={contentType.type}>
                      {contentType.name}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
            <Grid item xs={2} style={{ marginBottom: 5 }}>
              <Field
                label={"Accept"}
                name="acceptType"
                component={SelectField}
                validate={[RequiredValidator, NonEmptyStringValidator]}
                ref="acceptType"
              >
                {acceptTypes.map(function(acceptType, idx) {
                  return (
                    <MenuItem key={idx} value={acceptType.type}>
                      {acceptType.name}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
          </Grid>
        </div>
        <ButtonLoading
          loading={executing}
          loadingTitle="Executing the request"
          style={{ width: "300px", margin: "20px 0 0" }}
          className="color-primary"
          type="submit"
          variant="outlined"
          color="primary"
        >
          {"Execute the request"}
        </ButtonLoading>
        <div className="error">{error_rest}</div>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.admin.data_rest,
    executing: state.admin.executing_rest
  };
};
export default reduxForm({
  destroyOnUnmount: false,
  form: "restform",
  onSubmitFail: scrollToFirstError
})(connect(mapStateToProps)(RestForm));
