import React, { Component } from 'react';
import { withStyles, Grid } from "@material-ui/core";
import InputField from "components/Form/Fields/InputField";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import {ATTACHMENT_PLATFORM} from "../../constants/Attachments";

class SearchBar extends Component {
    onChangeName = (event) => {
        const { onChange, searchParams: { searchType } } = this.props;
        if (onChange) {
            onChange({
                searchType: searchType,
                searchName: event.nativeEvent.target.value
            });
        }
    };
    onChangeType = (type) => {
        const { onChange, searchParams: { searchName } } = this.props;
        if (onChange) {
            onChange({
                searchType: type,
                searchName: searchName
            });
        }
    };

    render() {
        const { typesAttachment, searchParams: { searchName, searchType }, attachmentPlatform } = this.props;

        return (
            <Grid container spacing={1}>
                <Grid item>
                    <InputField
                        placeholder="Filter by name"
                        meta={{}}
                        input={{
                            value: searchName,
                            name: 'name',
                            onChange: this.onChangeName
                        }} />
                </Grid>
                {attachmentPlatform  === ATTACHMENT_PLATFORM.DATAHUB &&
                <Grid item>
                    <AutoSuggestSelectField
                        options={typesAttachment}
                        placeholder="Filter by type"
                        multiple
                        meta={{}}
                        onChangeValue={this.onChangeType}
                        input={{
                            value: searchType,
                            name: 'type'
                        }}/>
                </Grid>
                }
            </Grid>
        )
    }
}

const styles = theme => ({
});

export default withStyles(styles)(SearchBar);
