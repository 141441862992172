import React from 'react';

import {
  CircularProgress, makeStyles, Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  progressSmall: {
    position: 'relative',
    width: 40,
    height: 40,
  },
  icon: {
    position: 'absolute',
    width: 40,
    height: 40,
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .secondary': {
      display: 'none',
    },
    '&:hover .primary': {
      display: 'none',
    },
    '&:hover .secondary': {
      display: 'block',
    },
  },
}));

const Status = ({
  progress,
  variant,
  primaryEl,
  secondaryEl,
}) => {

  const classes = useStyles();

  return (
    <div
      className={classes.progressSmall}
    >
      <CircularProgress
        variant={variant}
        value={progress}
      />
      <div className={classes.icon}>
        <div className="primary">
          {primaryEl}
        </div>
        <div className="secondary">
          {secondaryEl}
        </div>
      </div>
    </div>
  );
};

export default Status;
