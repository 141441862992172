import React from "react";
import ButtonLoading from "components/Buttons/ButtonLoading";

const ExportAction = ({ onClick }) => {
  return (
    <ButtonLoading
      loadingTitle="Export"
      className="color-primary"
      style={{ height: 32 }}
      variant="outlined"
      color="primary"
      onClick={onClick}
    >
      Export
    </ButtonLoading>
  );
};

export default ExportAction;
