import React, { Component } from "react";
import ButtonAbstract from "../RestrictedComponent";
import { MenuItem as MenuItemMaterial } from "@material-ui/core";

class MenuItem extends Component {
  render() {
    return (
      <ButtonAbstract componentButton={MenuItemMaterial} {...this.props} />
    );
  }
}

export default MenuItem;
