import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../Buttons/Button";
import { Field, reduxForm } from "redux-form";
import InputField from "../Form/Fields/InputField";
import { DialogActions, DialogContent, Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";

class FillWidgetVariablesFormDialog extends Component {
  state = {
    open: false
  };

  openDialog = () => {
    this.setState({
      open: true
    });
  };
  closeDialog = () => {
    this.setState({
      open: false
    });
  };
  handleSubmit = form => {
    const { handleSubmit } = this.props;
    handleSubmit(form);
    this.closeDialog();
  };

  render() {
    const { classes, widget } = this.props;
    const { open } = this.state;
    if (!widget || !widget.variables || widget.variables.length <= 0) {
      return null;
    }

    return (
      <React.Fragment>
        <Button
          className="color-primary"
          onClick={this.openDialog}
          variant="outlined"
          color="primary"
        >
          {"Set variables"}
        </Button>
        <Dialog fullWidth maxWidth="md" onClose={this.closeDialog} open={open}>
          <form onSubmit={this.handleSubmit}>
            <CloseableDialogTitle
              id="simple-dialog-title"
              handleClose={this.closeDialog}
            >
              Set the widget variables
            </CloseableDialogTitle>
            <DialogContent>
              {widget.variables &&
                widget.variables.map((variable, idx) => {
                  return (
                    <div key={idx} className={classes.field}>
                      <Field
                        label={variable.name}
                        name={variable.name}
                        component={InputField}
                        validate={[]}
                        ref={variable.name}
                      />
                    </div>
                  );
                })}
            </DialogContent>
            <DialogActions>
              <Button
                className="color-primary"
                type="submit"
                variant="outlined"
                color="primary"
              >
                {"Save"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  field: {
    margin: 20
  }
});
const mapStateToProps = (state, ownProps) => {
  const { widget } = ownProps;
  return {
    form: "fillwidgetvariablesdialog" + widget.id
  };
};

FillWidgetVariablesFormDialog = reduxForm({
  onSubmitFail: scrollToFirstError
})(FillWidgetVariablesFormDialog);

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(FillWidgetVariablesFormDialog)
);
