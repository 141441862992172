import {Grid, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";

const TaskInfoWorkflowContainer = ({taskWorkflowInstanceName, taskWorkflowTypeLabel, taskWorkflowSummary}) => {
  const [workflowText, setWorkflowText] = useState(null);

  useEffect(()=>{
    if (taskWorkflowInstanceName){
      if(taskWorkflowTypeLabel) {
        setWorkflowText(taskWorkflowTypeLabel + " (" + taskWorkflowInstanceName+")");
      } else {
        setWorkflowText(taskWorkflowInstanceName);
      }
    } else {
      setWorkflowText(
        <em style={{ color: "gray", display: 'inline-flex' }}>
          Untitled {taskWorkflowSummary}
        </em>);
    }
  },[taskWorkflowInstanceName, taskWorkflowTypeLabel, taskWorkflowSummary]);

  if(workflowText) {
    return <Grid item xs={12} style={{padding: "5px 0"}}>
        <Typography variant="caption" style={{color: "rgba(0, 0, 0, 0.54)"}}>
          Workflow:
        </Typography>
        <Typography variant="body1" style={{overflowWrap: "break-word"}}>
          {workflowText}
        </Typography>
      </Grid>;
  }
  return null;

};
export default TaskInfoWorkflowContainer;