import React, { useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Checkbox, DialogTitle,
} from '@material-ui/core';

import Button from 'components/Buttons/Button';

export const ConflictDialog = ({ isOpen, title, onResolve, }) => {

  const [isApplicableToAll, setIsApplicableToAll] = useState(false);

  const handleCheckboxChange = () => {
    setIsApplicableToAll((isRewriteAll) => !isRewriteAll);
  };

  const handleNo = () => {
    onResolve(false, isApplicableToAll);
  };

  const handleYes = () => {
    onResolve(true, isApplicableToAll);
  };

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-file-conflict"
    >
      <DialogTitle
        id="simple-dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              className="switch"
              checked={isApplicableToAll}
              onChange={handleCheckboxChange}
              name="isApplicableToAll"
              color="primary"
            />
          }
          label="Use action for all future conflicts?"
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="primary"
          onClick={handleNo}
        >
          No
        </Button>
        <Button
          color="primary"
          onClick={handleYes}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
