export default {
    state: {
        resturl: '',
        //snackbar
        errorSnackbar: {
            style: {
                backgroundColor: '#e95859',
            },
            bodyStyle: {
                backgroundColor: '#e95859',
                height: 'auto',
                lineHeight: '28px',
                padding: 24,
                whiteSpace: 'pre-line'
            },
            open: true,
        },
        successSnackbar: {
            style: {
                backgroundColor: '#8aac10',
            },
            bodyStyle: {
                backgroundColor: '#8aac10',
                height: 'auto',
                lineHeight: '28px',
                padding: 24,
                whiteSpace: 'pre-line'
            },

            autoHideDuration: 4000,
            open: true,
        },
        defaultSnackbar: {
            autoHideDuration: 4000,
            open: true,
            bodyStyle: {
                height: 'auto',
                lineHeight: '28px',
                padding: 24,
                whiteSpace: 'pre-line'
            },
        },
        snackbar: {
            open: false,
            message: '',
        },
        snackbarMessage: '',
        snackbarOpen: false,
    }
};
