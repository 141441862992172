import React, { Component } from "react";
import Button from "../Buttons/Button";
import { Field, reduxForm } from "redux-form";
import {
  RequiredValidator,
  NonEmptyStringValidator
} from "../Form/Fields/FieldValidators";
import InputField from "../Form/Fields/InputField";
import CheckboxField from "../Form/Fields/CheckboxField";

import { DialogContent, Dialog, DialogActions } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";

class SaveFiltersDialogForm extends Component {
  render() {
    const { handleSubmit, onClose, open } = this.props;
    return (
      <Dialog onClose={onClose} open={open}>
        <form testid="saveSearchForm" onSubmit={handleSubmit}>
          <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
            Save this search
          </CloseableDialogTitle>
          <DialogContent>
            <Field
              label={"Title"}
              name="label"
              component={InputField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
              ref="label"
            />
            <div style={{ marginTop: "20px" }}>
              <Field
                label={"Share with all users"}
                name="shared"
                component={CheckboxField}
                validate={[]}
                ref="shared"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="color-primary"
              testid="save"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {"Save"}
            </Button>
            <div className="error">{this.props.errorServer}</div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
export default reduxForm({
  form: "savefiltersdialog",
  onSubmitFail: scrollToFirstError
})(SaveFiltersDialogForm);
