import React, { Component } from 'react';
import SimpleTable from "components/Table/SimpleTable";
import PaginatedSearchTableWidget from "components/Widget/PaginatedSearchTableWidget";
import TablePaginationWidget from "components/Widget/TablePaginationWidget";

const rowsPerPage = 10;

class PaginatedTableWidget extends Component {
    state = {};

    componentDidMount() {
        this.setState({ page: 0 });
    }

    componentWillReceiveProps(nextProps) {
        const { widget } = nextProps;
        if (widget && (!this.props.widget || widget.id !== this.props.widget.id)) {
            this.setState({ page: 0 });
        }
    }

    componentWillUpdate(nextProps, nextState) {
        const { page } = this.state;
        if (page !== nextState.page) {
            return this.setVariables(nextProps, nextState);
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    setVariables = (nextProps, nextState) => {
        const { onSetParameters } = nextProps;
        const { page } = nextState;
        if (onSetParameters) {
            onSetParameters({
                offset: (page)*rowsPerPage,
                limit: rowsPerPage
            });
        }
    };

    render() {
        const { widget } = this.props;
        const { page } = this.state;
        const { includeColumnNames, queryFormat } = widget;
        let result = widget.results;
        let columns = [], table;
        if (includeColumnNames && result && result.length > 0) {
            columns = result[0];
            result = result.slice(1);
        }

        switch (queryFormat) {
            case "SEARCH":
                table = (
                    <PaginatedSearchTableWidget widget={widget} />
                );
                break;
            case "SQL_QUERY":
                table = (
                    <SimpleTable columns={columns} data={result} />
                );
        }

        return (
            <React.Fragment>
                <div className="paginated">
                    { table }
                </div>
                <TablePaginationWidget
                    rowsPerPageOptions={[]}
                    component="div"
                    count={widget.totalResultCount}
                    rowsPerPage={rowsPerPage}
                    page={page || 0}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                />
            </React.Fragment>
        );
    };
}

export default PaginatedTableWidget;
