import React, {useState} from "react";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "components/Buttons/Button";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import {createDownloadLink} from "actions/Uploads";
import {downloadFile} from "../../api/File";


const styles = () => ({
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  statusCaption: {
    display: "flex",
    marginRight: 20,
    width: 260,
    justifyContent: "flex-start",
    textAlign: "left"
  },
  statusValue: {
    display: "flex"
  },
  logFileContentPreview: {
    border: "solid 1px #ddd",
    borderRadius: 5,
    padding: "10px 20px",
    background: "#fafafa",
    marginTop: 15,
    wordWrap: "break-word",
    whiteSpace: "pre-wrap"
  },
  downloadLogFile: {
    marginTop: 5,
    display: "flex"
  },
  scriptExecutionLogWithErrors: {
    color: "#F24636"
  }
});

const ScriptExecutionLogItem = withStyles(styles)(({executionSuccessful, logFileFileName, onClick, classes}) => {
  return (
    <ListItem button onClick={onClick}>
      <ListItemText
        className={executionSuccessful ? "" : classes.scriptExecutionLogWithErrors}
        primary={logFileFileName}
      />
      <ListItemSecondaryAction>
        <Button variant="outlined" color="primary" onClick={onClick}>
          Show detail
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
});

const ScriptExecutionLogDetail = withStyles(styles)(({scriptExecutionLog, onClose, classes}) => {

  if (!scriptExecutionLog) {
    return null;
  }

  const {
    logFileContent,
    scriptCallExecutionStartedAt,
    scriptCallExecutionFinishedAt,
    executionSuccessful,
    logFileFileName,
    scriptName,
    scriptCallId
  } = scriptExecutionLog;

  const handleLogFileDownload = async () => {
    const href = getScriptExecutionLogFileDownloadLink(scriptCallId);
    const response = await createDownloadLink(href);
    const fileLink = response.data;
    downloadFile(fileLink);
  };


  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
        {logFileFileName}
      </CloseableDialogTitle>
      <DialogContent>
        {getMetadataField("Script source code:", scriptName, classes)}
        {getMetadataField("Execution result (OK / FAILED):", executionSuccessful ? ("OK") : ("FAILED"), classes)}
        {getMetadataField("Script execution started at:", scriptCallExecutionStartedAt, classes)}
        {getMetadataField("Script execution finished at:", scriptCallExecutionFinishedAt, classes)}
        <div className={classes.infoContainer}>
          {getMetadataCaption("Script execution log file:", classes)}
          <Button className={classes.downloadLogFile} variant="outlined" color="primary" component="a" onClick={handleLogFileDownload}>
            Download the log file
          </Button>
        </div>
        {getMetadataCaption("Script execution log preview (might be truncated):", classes)}
        <div>
          <DialogContentText
            variant="body1"
            id="alert-dialog-description"
            className={classes.logFileContentPreview}
          >
            {logFileContent}
          </DialogContentText></div>
      </DialogContent>
    </Dialog>
  );
});

const ScriptExecutionLogList = ({workflow}) => {
  const [selectedLog, setSelectedLog] = useState(undefined);
  const {scriptExecutionLogs} = workflow;
  let scriptExecutionLogList;
  if (!scriptExecutionLogs || scriptExecutionLogs.length <= 0) {
    scriptExecutionLogList =
      <div style={{padding: "10px 30px", textAlign: 'center', marginTop: 5}}>No script execution logs
        found.</div>
  } else {
    scriptExecutionLogList = <List component="nav" style={{width: "100%"}}>
      {scriptExecutionLogs.map((scriptExecutionLog, idx) => (
        <ScriptExecutionLogItem
          {...scriptExecutionLog}
          onClick={() => setSelectedLog(scriptExecutionLog)}
          key={idx}
        />
      ))}
    </List>
  }

  return (
    <>
      {scriptExecutionLogList}
      <ScriptExecutionLogDetail
        scriptExecutionLog={selectedLog}
        onClose={() => setSelectedLog(undefined)}
      />
    </>
  );
};

function getScriptExecutionLogFileDownloadLink(scriptCallId) {
  return encodeURI(
    `${location.origin}/api/script-call/${scriptCallId}/log-file/downloadlink`
  );
}

function getMetadataField(captionText, metadataValue, classes) {
  return <div className={classes.infoContainer}>
    {getMetadataCaption(captionText, classes)}
    <Typography variant="body1" className={classes.statusValue}>
      {metadataValue}
    </Typography>
  </div>
}

function getMetadataCaption(captionText, classes) {
  return <Typography variant="caption" className={classes.statusCaption}>
    {captionText}
  </Typography>
}

export default ScriptExecutionLogList;