import {
  GET_ENUM_ENTRIES,
  LOADING_ENUM_ENTRIES,
  END_LOADING_ENUM_ENTRIES,
  ENUM_ENTRIES_ERROR
} from "../../constants/ActionTypes";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_ENUM_ENTRIES:
      return { ...state, list: action.enumEntries, error: undefined };
    case LOADING_ENUM_ENTRIES:
      return { ...state, loading: true, error: undefined };
    case END_LOADING_ENUM_ENTRIES:
      return { ...state, loading: false };
    case ENUM_ENTRIES_ERROR:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
