import React, { Component } from 'react';
import ListView from "components/Entity/ListView";

const PaginatedSearchTableWidget = ({ widget }) =>{
  if(!widget || !widget.results){
    return null;
  }
  const resultsParsed = widget.results[0];

  return (
      <ListView
          withoutToolbar
          unsortable
          useAutoSizer
          style={{border: "none", height: "100%"}}
          tableWrapperStyle={{overflow: "visible", height: "100%"}}
          results={resultsParsed.result.results && resultsParsed.result.results.map((result) => {
              result.attributes = result.simpleAttributes;
              return result;
          })}
          attributesFromResult={resultsParsed.result.attributeIds}
          result_format={resultsParsed.result_format} />
  );
};

export default PaginatedSearchTableWidget;
