import React, { useState } from 'react';

import { ExpandMore } from '@material-ui/icons';
import {
  Chip,
  Dialog,
  DialogActions,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Paper,
  Typography,
  withStyles
} from '@material-ui/core';
import { connect } from 'react-redux';

import {
  getEntityLabel,
  isAuthorizedToViewEntityType,
  MODEL_MONITORING_TYPE
} from 'api/Entity';
import { ROLE_ENTITYLINK_DELETE } from 'constants/Authorities';
import { getApiError } from 'api/Error';
import { pushNotification } from 'actions/notifications';
import CloseableDialogTitle from 'components/Text/CloseableDialogTitle';
import Button from 'components/Buttons/Button';
import ButtonLoading from 'components/Buttons/ButtonLoading';
import LinkEntity from 'components/Entity/LinkEntity';
import ListWorkflows from 'components/Workflow/ListWorklows';


const Link = ({ link, entity, me, onDelete, classes }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [expand, setExpend] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState(false);
  const {
    sourceEntityId,
    sourceEntity,
    sourceEntityResolved,
    targetEntity,
    targetEntityResolved,
    depth
  } = link;

  let title;
  let otherEntity;
  const sourceEntityName = sourceEntityResolved
    ? sourceEntityResolved.label
    : "";
  const targetEntityName = targetEntityResolved
    ? targetEntityResolved.label
    : "";
  let otherEntityEnum;
  if (sourceEntityId === entity.id) {
    title = targetEntityName;
    otherEntity = targetEntityResolved;
    otherEntityEnum = targetEntity;
  } else {
    title = sourceEntityName;
    otherEntity = sourceEntityResolved;
    otherEntityEnum = sourceEntity;
  }

  const entityOverview = { ...otherEntity, entityType: otherEntityEnum, disabled: !otherEntity.isActive };
  const handleConfirmDeleting = async () => {
    setDeleting(true);
    try {
      await onDelete();
      setOpenDelete(false);
    } catch (e) {
      const message = getApiError(e);
      setErrorDeleting(message);
    }
    setDeleting(false);
  };

  const handleExpandChange = (event, expanded) => {
    setExpend(expanded);
  };

  return (
    <>
      <ExpansionPanel onChange={handleExpandChange} testid="link">
        <ExpansionPanelSummary testid="linkpanel" expandIcon={<ExpandMore />}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row"
            }}
          >
            {depth ? (
              <Chip
                size="small"
                style={{ marginRight: 10 }}
                label={`Level ${depth}`}
                variant="outlined"
              />
            ) : null}
            {title}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0, maxHeight: 400 }}>
          <div
            style={{
              width: "100%",
              flex: 1,
              display: "flex",
              flexDirection: "column"
            }}
          >
            {expand ? (
              <>
                {otherEntity.attributeValues ? (
                  <Grid container>
                    {otherEntity.attributeValues.map(({ label, value }) => (
                      <Grid item xs={4}>
                        <Paper className={classes.container} testid="attribute">
                          <Grid container>
                            <Grid item className={classes.name}>
                              <Typography variant="caption">
                                {label}:
                              </Typography>
                            </Grid>
                            <Grid item className={classes.value}>
                              <div style={{ width: "100%" }}>{value}</div>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                ) : null}
                {entityOverview.entityEnum
                  && entityOverview.entityEnum !== MODEL_MONITORING_TYPE
                  && <ListWorkflows entity={entityOverview} />}
              </>
            ) : null}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {!otherEntity.isAnonymous &&
              isAuthorizedToViewEntityType(me, otherEntity) ? (
                <ButtonLoading component={LinkEntity} entity={otherEntity}>
                  Open the {getEntityLabel(otherEntityEnum)}
                </ButtonLoading>
              ) : null}
              <ButtonLoading
                testid="deleteLink"
                loading={deleting}
                tooltipTitle="Delete the link"
                loadingTitle="Deleting the link"
                authoritiesRequired={[ROLE_ENTITYLINK_DELETE]}
                aria-label={"Delete the link"}
                onClick={() => setOpenDelete(true)}
              >
                Delete the link
              </ButtonLoading>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <CloseableDialogTitle
          id="confirmation-dialog-title"
          handleClose={() => setOpenDelete(false)}
        >
          Are you sure you want to delete this link?
        </CloseableDialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDeleting}
            color="primary"
            testid="confirmDeleteLink"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = theme => ({
  name: {
    textAlign: "right",
    display: "inline-flex",
    paddingRight: "10px",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100px"
  },
  value: {
    display: "inline-flex",
    overflowWrap: "break-word",
    overflowX: "auto",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1
  },
  container: {
    padding: "10px",
    margin: "10px"
  }
});

const mapStateToProps = state => {
  return {
    me: state.auth.me
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { pushNotification }
  )(Link)
);
