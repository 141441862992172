import ReactEcharts from "echarts-for-react";
import React, { useEffect, useState } from "react";
import { getColorsScale } from "../../constants/Colors";

const HeatMapWidget = ({ widget }) => {
    if (!widget || !widget.results || !widget.results[0]) {
        return null;
    }

    const [data, setData] = useState(null);
    const [xAxis, setxAxis] = useState(null);
    const [yAxis, setyAxis] = useState(null);
    const [minVal, setMinVal] = useState(null);
    const [maxVal, setMaxVal] = useState(null);

    useEffect(()=>{
        const { results } = widget;
        const parsedWidgetData = results[0];
        setData(parsedWidgetData.data);
        setxAxis(parsedWidgetData.xaxis);
        setyAxis(parsedWidgetData.yaxis);
        setMinVal(parsedWidgetData.minVal);
        setMaxVal(parsedWidgetData.maxVal);

      },[widget]);

    return (
        <ReactEcharts
            className="react_for_echarts"
            option={{
                tooltip: {
                    position: 'top'
                },
                grid: {
                    height: '50%',
                    top: '10%'
                },
                xAxis: {
                    type: 'category',
                    data: xAxis,
                    splitArea: {
                        show: true
                    }
                },
                yAxis: {
                    type: 'category',
                    data: yAxis,
                    splitArea: {
                        show: true
                    }
                },
                visualMap: {
                    min: minVal,
                    max: maxVal,
                    calculable: true,
                    orient: 'horizontal',
                    left: 'center',
                    bottom: '15%',
                    inRange : {   
                        color: [getColorsScale(3)[0], getColorsScale(3)[1]]
                    }
                },
                series: [
                    {
                        name: widget.name,
                        type: 'heatmap',
                        data: data,
                        label: {
                            show: true,
                            textStyle: {
                                color: '#000000'
                            },
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#F8F8F8',
                            },
                        }
                    }
                ]
            }}
        />
    );
};

export default HeatMapWidget;