import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import IconButtonLoading from "../../../Buttons/IconButtonLoading";
import Button from "../../../Buttons/Button";

import TabCentered from "../../../TabContainer/TabCentered";
import { ROLE_VALIDATION_MANAGE_ASSIGNMENT } from "../../../../constants/Authorities";
import { openAssignModels } from "../../../../actions/Validations";

import EntityTag from "components/Entity/Tag";
import {
  isAuthorizedToViewEntity,
  MODEL_REVISION_TYPE
} from "../../../../api/Entity";
import { Close } from "@material-ui/icons";

import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LinkEntity from "components/Entity/LinkEntity";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

const styles = theme => ({});

class ValidationModels extends Component {
  assignModels = () => {
    this.props.openAssignModels();
  };

  render() {
    const { validation, onUnassign, me } = this.props;

    if (!validation.modelRevisions || validation.modelRevisions.length <= 0) {
      return (
        <TabCentered>
          <div className="emptyMessage">No model linked</div>
          <Button
            testid="assignModel"
            authoritiesRequired={[ROLE_VALIDATION_MANAGE_ASSIGNMENT]}
            onClick={this.assignModels}
            variant="outlined"
            color="primary"
          >
            Assign a model
          </Button>
        </TabCentered>
      );
    }
    return (
      <List component="nav" testid="modelsAssigned" className={"scrollable-content"}>
        {validation.modelRevisions.map((revision, idx) => {
          if (!revision || !revision.model) {
            return null;
          }
          return (
            <ListItem
              key={idx}
              button={isAuthorizedToViewEntity(me, revision)}
              component={LinkEntity}
              entity={revision}
            >
              {/*TODO: Create functions in model objects to return label extended by type/revision*/}
              <ListItemAvatar>
                <EntityTag entityType={MODEL_REVISION_TYPE} />
              </ListItemAvatar>
              <ListItemText
                primary={`${revision.model.label} (${revision.model.modelType.name})`}
                secondary={revision.label}
              />
              <ListItemSecondaryAction>
                <div className="error" style={{ display: "inline-block" }}>
                  {revision.error}
                </div>
                {onUnassign && (
                  <IconButtonLoading
                    testid="unassignModel"
                    loading={revision.setting}
                    loadingTitle="Removing the model from this validation"
                    tooltipTitle="Remove the model from this validation"
                    authoritiesRequired={[ROLE_VALIDATION_MANAGE_ASSIGNMENT]}
                    onClick={() => {
                      onUnassign(revision);
                    }}
                    aria-label="Remove"
                    color="primary"
                  >
                    <Close />
                  </IconButtonLoading>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { openAssignModels }
  )(withRouter(ValidationModels))
);
