import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CircularProgress, Grid, Tab, Tabs, withStyles } from '@material-ui/core';

import { ROLE_SYSTEM_PERFORM } from 'constants/Authorities';
import { AUTOMATION_LABEL, WORKFLOW_REGULAR } from 'constants/Workflows';
import { ADMIN_OVERVIEW_PATH, TAB_ADMIN_DROOLS } from 'constants/Routes';
import { initializeForm } from 'actions/index';
import { deleteWorkflow, getWorkflows } from 'actions/Workflow';
import { getWorkflowTypesByEntity } from 'actions/Workflow/types';
import { isAuthorized } from 'api/Authorities';
import { getApiError } from 'api/Error';
import { manageForm } from 'api/Workflow';
import { getEntityLabel, getEntityWorkflowRoleCreate, MODEL_TYPE } from 'api/Entity';
import APPCONFIG from '../../constants/Config';
import Workflow from './Workflow';
import TabCentered from '../TabContainer/TabCentered';
import CreateWorkflowFullDialogForm from './CreateWorkflowFullDialogForm';
import WorkflowSearchBar from 'components/Workflow/WorkflowSearchBar';
import ButtonLoading from 'components/Buttons/ButtonLoading';
import {getSortingByName} from "api/Sort";
import WorkflowTypeDescription from "components/Workflow/WorkflowTypeDescription";

const ListWorkflows = ({
  initializeForm,
  classes,
  entity,
  classification,
  standalone,
  me
}) => {
  const refreshTimeout = useRef(null);
  const [workflows, setWorkflows] = useState([]);
  const [currentWorkflowPage, setCurrentWorkflowPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [workflowTypes, setWorkflowTypes] = useState([]);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [errorTypes, setErrorTypes] = useState(null);

  const [searchWorkflowName, setSearchWorkflowName] = useState(undefined);
  const [openDialogCreateWorkflow, setOpenDialogCreateWorkflow] = useState(false);
  const [selectedWorkflowType, setSelectedWorkflowType] = useState({});

  const workflowLabel = classification === WORKFLOW_REGULAR ? "workflow" : "process";
  const workflowsLabel = classification === WORKFLOW_REGULAR ? "workflows" : "processes";

  const entityId = entity && entity.id ? entity.id : null;
  const entityType = entity ? entity.entityType : null;
  const disabled = entity ? entity.disabled : false ;

  const entityTypeLabel = standalone ? AUTOMATION_LABEL : getEntityLabel(entityType);
  const tooltipTitle = disabled ? `This ${entityTypeLabel} is inactive` : null;
  let list,
    hasWorkflow = false;

  useEffect(() => {
    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    refreshTab();
  }, [entity]);

  useEffect(() => {
      refreshTab();
  }, [
    selectedWorkflowType.id,
    searchWorkflowName,
    currentWorkflowPage,
    classification
  ]);

  useEffect(() => {
    if (
      workflows &&
      selectedWorkflowType &&
      selectedWorkflowType.id &&
      !selectedWorkflowType.includedInDrools &&
      workflows.length === 0
    ) {
      refreshWorkflowTypes();
    }
  }, [workflows, selectedWorkflowType.id]);

  const refreshTabTimer = () => {
    refreshTab();
  };

  const refreshWorkflows = async () => {
    setLoading(true);
    setError(null);
    try {
      const _workflows = (await getWorkflows(
        entityId,
        entityType,
        0,
        (currentWorkflowPage + 1) * APPCONFIG.LIST_SIZE,
        classification,
        searchWorkflowName,
        selectedWorkflowType.id
      )).data;

      setWorkflows(
        _workflows &&
          _workflows.map(workflow => {
            if (!workflow) {
              return;
            }
            return {
              ...manageForm(workflow),
              tasks:
                workflow.tasks &&
                workflow.tasks.map(task => {
                  return manageForm(task);
                })
            };
          })
      );
    } catch (e) {
      setError(getApiError(e));
    }
    setLoading(false);
  };

  const refreshWorkflowTypes = async () => {
    setLoadingTypes(true);
    setErrorTypes(null);
    try {
      let _workflowsTypes = (await getWorkflowTypesByEntity(
        entityId,
        entityType,
        classification
      )).data;
      if(_workflowsTypes && _workflowsTypes.length > 1){
        _workflowsTypes = _workflowsTypes.sort(getSortingByName)
      }
      let selectedWorkflowTypeExists = false;
      setWorkflowTypes(
        _workflowsTypes &&
          _workflowsTypes.map(type => {
            if (type.id === selectedWorkflowType.id) {
              selectedWorkflowTypeExists = true;
            }
            return manageForm(type);
          })
      );
      if (!selectedWorkflowTypeExists) {
        setSelectedWorkflowType(
          _workflowsTypes && _workflowsTypes.length > 0
            ? _workflowsTypes[0]
            : {}
        );
      }
    } catch (e) {
      setErrorTypes(getApiError(e));
    }
    setLoadingTypes(false);
  };

  const refreshTab = async () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout.current);
    }

    refreshTimeout.current = setTimeout(() => {
      refreshTabTimer();
    }, APPCONFIG.REFRESH_TIMEOUT);

    refreshWorkflows();
    refreshWorkflowTypes();
  };

  const handleCreateWorkflowFullDialogForm = () => {
    setOpenDialogCreateWorkflow(true);
  };

  const handleCloseCreateFullDialogForm = () => {
    setOpenDialogCreateWorkflow(false);
    initializeForm("createworkflowfulldialog", {});
  };

  const handleChangeTab = (event, value) => {
    workflowTypes.map(workflowType => {
      if (workflowType.id === value) {
        setSelectedWorkflowType(workflowType);
      }
    });
  };

  const handleCreateWorkflow = workflow => {
    setWorkflows([workflow, ...workflows]);
  };

  const handleDeleteWorkflow = async workflow => {
    await deleteWorkflow(workflow);
    setWorkflows(workflows.filter(({ id }) => id !== workflow.id));
  };

  const handleScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
    const page = workflows
      ? Math.floor(workflows.length / APPCONFIG.LIST_SIZE)
      : 0;
    setCurrentWorkflowPage(page);
  };

  const button =
    workflowTypes && workflowTypes.length > 0 && entityType !== MODEL_TYPE ? (
      <Grid item style={{ flex: "none" }}>
        <ButtonLoading
          disabled={disabled}
          tooltipTitle={tooltipTitle}
          testid="addworkflow"
          authoritiesRequired={[getEntityWorkflowRoleCreate(entityType)]}
          onClick={handleCreateWorkflowFullDialogForm}
          variant="outlined"
          color="primary"
        >
          Add{" "}
          {selectedWorkflowType.name
            ? selectedWorkflowType.name
            : workflowLabel}
        </ButtonLoading>
      </Grid>
    ) : null;

  if (workflows) {
    list = (
      <Grid
        container
        justify="center"
        className={classes.root}
        onScroll={handleScroll}
      >
        <Grid item xs={11}>
          {workflows.map(workflow => {
            if (
              !workflow ||
              !workflow.workflowType ||
              workflow.workflowType.id !== selectedWorkflowType.id
            ) {
              return null;
            }
            hasWorkflow = true;
            return (
              <Workflow
                entityType={entityType}
                readOnly={disabled}
                workflowLabel={workflowLabel}
                workflowsLabel={workflowsLabel}
                workflow={workflow}
                onDelete={async () => await handleDeleteWorkflow(workflow)}
                key={workflow.id}
              />
            );
          })}
        </Grid>
      </Grid>
    );
  }

  if (!hasWorkflow) {
    if (loadingTypes || loading) {
      list = (
        <TabCentered className={classes.tabCentered}>
          <div
            style={{
              width: "400px",
              lineHeight: "36px",
              textAlign: "center",
              margin: "20px 10px 0"
            }}
          >
            <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
              Loading the {workflowsLabel}
            </span>
          </div>
        </TabCentered>
      );
    } else {
      if (workflowTypes && workflowTypes.length > 0) {
        if (!isAuthorized(me, [getEntityWorkflowRoleCreate(entityType)])) {
          list = (
            <TabCentered className={classes.tabCentered}>
              <div className="emptyMessage">
                Not allowed to add {workflowsLabel}
              </div>
            </TabCentered>
          );
        } else if (searchWorkflowName) {
          list = (
            <TabCentered className={classes.tabCentered}>
              <div className="emptyMessage">
                No {workflowLabel} match defined filter
              </div>
            </TabCentered>
          );
        } else {
          list = (
            <TabCentered className={classes.tabCentered}>
              <div className="emptyMessage">No {workflowsLabel} added yet</div>
              {selectedWorkflowType.includedInDrools ? button : null}
            </TabCentered>
          );
        }
      } else {
        list = (
          <TabCentered className={classes.tabCentered}>
            <div className="emptyMessage">
              No {workflowsLabel} attached to {standalone? entityTypeLabel: "this "+entityTypeLabel}
            </div>
            {entityType !== MODEL_TYPE &&
              isAuthorized(me, [ROLE_SYSTEM_PERFORM]) && (
                <div className="emptyMessage">
                  Define {workflowLabel} type for {standalone? entityTypeLabel : entityTypeLabel+"s"} in{" "}
                  <Link
                    style={{ color: "inherit", textDecoration: "underline" }}
                    to={ADMIN_OVERVIEW_PATH + "?tab=" + TAB_ADMIN_DROOLS}
                  >
                    System administration
                  </Link>{" "}
                  to enable creation of new {workflowsLabel}
                </div>
              )}
          </TabCentered>
        );
      }
    }
  }

  return (
    <div className={"scrollable-container"}>
      <div className={"scrollable-header"}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ padding: "10px" }}
          wrap="nowrap"
        >
          {hasWorkflow ||
          searchWorkflowName ||
          (workflowTypes && workflowTypes.length > 0) ? (
            <WorkflowSearchBar
              onChange={event =>
                setSearchWorkflowName(event.nativeEvent.target.value)
              }
              searchWorkflowName={searchWorkflowName}
              workflowLabel={workflowLabel}
            />
          ) : null}
          {selectedWorkflowType.includedInDrools ? button : null}
        </Grid>
        <Tabs
          variant={"scrollable"}
          value={selectedWorkflowType.id}
          style={{ marginBottom: 0 }}
          onChange={handleChangeTab}
          testid="workflowTypes"
        >
          {workflowTypes
            ? workflowTypes.map(({ name, id }) => {
                return <Tab key={id} label={name} testid={name} value={id} />;
              })
            : null}
        </Tabs>
      </div>
      <div className={"scrollable-content"}>
        <WorkflowTypeDescription description={selectedWorkflowType.description} />
        {list}
      </div>
      <CreateWorkflowFullDialogForm
        workflowLabel={
          selectedWorkflowType.name ? selectedWorkflowType.name : workflowLabel
        }
        onCreate={handleCreateWorkflow}
        workflowTypes={workflowTypes}
        classification={classification}
        workflowTypeId={selectedWorkflowType.id}
        entity={entity}
        open={openDialogCreateWorkflow}
        showOwnerInput
        handleClose={handleCloseCreateFullDialogForm}
      />
    </div>
  );
};

ListWorkflows.defaultProps = {
  classification: WORKFLOW_REGULAR
};

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me
  };
};
const styles = theme => ({
  root: { minHeight: 200, overflow: "auto", paddingTop: 10 },
  tabCentered: { minHeight: 200 }
});

export default  withStyles(styles)(
  connect(
    mapStateToProps,
    {
      initializeForm
    }
  )(ListWorkflows)
);
