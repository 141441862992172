import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { RequiredValidator } from "../Form/Fields/FieldValidators";
import Input from "../Form/Fields/InputField";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { connect } from "react-redux";
import { DialogContent, Dialog } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";
import DialogActions from "@material-ui/core/DialogActions";

class CreateFolderForm extends Component {
  componentWillReceiveProps(nextProps) {
    const { handleClose, creating, error_folder_attachments } = nextProps;
    if (this.props.creating && !creating && !error_folder_attachments) {
      handleClose();
    }
  }

  render() {
    const {
      handleSubmit,
      handleClose,
      open,
      creating,
      error_folder_attachments,
      submitSucceeded
    } = this.props;

    return (
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleSubmit} style={{ padding: "0" }}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleClose}
          >
            Create a new folder
          </CloseableDialogTitle>
          <DialogContent style={{ padding: "0 24px 16px 24px" }}>
            <div style={{ width: "400px" }}>
              <Field
                label={"Name of the folder"}
                name="name"
                multiple
                component={Input}
                validate={[RequiredValidator]}
                ref="name"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonLoading
              loading={creating}
              loadingTitle="Creating the folder"
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {"Create the folder"}
            </ButtonLoading>
            <div className="error">
              {submitSucceeded && error_folder_attachments}
            </div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    creating: state.attachments.creating_folder_attachments,
    error_folder_attachments: state.attachments.error_folder_attachments
  };
};
export default reduxForm({
  form: "createfolderform",
  onSubmitFail: scrollToFirstError
})(connect(mapStateToProps)(CreateFolderForm));
