import React, { Component } from "react";
import AttributesPanel from "./Panel";

class Attributes extends Component {
  render() {
    const { attributes, editable, editMode, creationMode, expanded } = this.props;
    if (!attributes) {
      return null;
    }
    return attributes
      .sort((a, b) => a.position - b.position)
      .map((panel, idx) => {
        return (
          <AttributesPanel
            key={idx}
            panel={panel}
            editable={editable}
            editMode={editMode}
            creationMode={creationMode}
            expanded={expanded}
          />
        );
      });
  }
}

export default Attributes;
