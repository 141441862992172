import React, { Component } from "react";
import { connect } from "react-redux";
import List from "@material-ui/core/List/index";
import Typography from "@material-ui/core/Typography/index";
import Grid from "@material-ui/core/Grid/index";
import ListItem from "@material-ui/core/ListItem/index";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/index";
import ListItemText from "@material-ui/core/ListItemText/index";
import CreateWorkflowTypeButton from "./CreateWorkflowTypeButton";
import DeleteWorkflowTypeButton from "./DeleteWorkflowTypeButton";
import TabCentered from "../../TabContainer/TabCentered";
import { withStyles } from "@material-ui/core/styles";
import UpdateWorkflowTypeDialog from "components/Admin/WorkflowTypes/UpdateWorkflowTypeDialog";
import { setCurrentWorkflowType } from "actions/Workflow/types";

class WorkflowTypesPanel extends Component {
  handleCloseWorkflowTypeDialog = () => {
    const { setCurrentWorkflowType } = this.props;
    setCurrentWorkflowType(null);
  };

  renderWorkflowType = type => {
    const { classes, setCurrentWorkflowType } = this.props;

    if (!type) {
      return null;
    }
    return (
      <ListItem
        key={type.id}
        onClick={() => {
          setCurrentWorkflowType(type);
        }}
        button
      >
        <ListItemText
          primary={type.label}
          secondary={
            <React.Fragment>
              {type.subLabel && (
                <Typography
                  component="span"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {type.subLabel + " — "}
                </Typography>
              )}
              {type.description}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <DeleteWorkflowTypeButton type={type} />
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  render() {
    const { types } = this.props;
    const button = <CreateWorkflowTypeButton />;

    if (!types || types.length <= 0) {
      return (
        <TabCentered>
          <div className="emptyMessage">No workflow types found</div>
          {button}
        </TabCentered>
      );
    }
    return (
      <React.Fragment>
        <Grid container justify="flex-end" style={{ padding: "10px" }}>
          {button}
        </Grid>
        <List component="nav" className={"scrollable-content"}>{types.map(this.renderWorkflowType)}</List>
        <UpdateWorkflowTypeDialog
          onClose={this.handleCloseWorkflowTypeDialog}
        />
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  inline: {
    display: "inline"
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    types: state.workflowTypes.list
  };
};
export default withStyles(styles)(
  connect(
    mapStateToProps,
    { setCurrentWorkflowType }
  )(WorkflowTypesPanel)
);
