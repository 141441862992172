import { Grid } from "@material-ui/core";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { Search } from "@material-ui/icons";
import { getEntityRoleCreate, MODEL_TYPE } from "../../api/Entity";
import AllLinksGraph from "components/Links/Graph/AllLinksGraph";
import React from "react";
import AppliedFilter from "components/Search/AppliedFilter";
import OpenExportDrawerButton from "components/ExportHistory/OpenExportDrawerButton";

const TableTopMenu = ({
  filters,
  entityType,
  createLabel,
  enableExportHistory,
  handleOpenDrawerFilter,
  onOpenDrawer,
  removeAppliedFilter,
  onCreate,
}) => {
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      style={{ marginBottom: "20px" }}
    >
      <Grid item>
        {!filters && (
          <ButtonLoading
            testid="search"
            variant="outlined"
            color="primary"
            style={{ margin: "5px 0 5px 5px" }}
            onClick={handleOpenDrawerFilter}
          >
            <Search style={{ verticalAlign: "middle" }} />
            Filter
          </ButtonLoading>
        )}
        <AppliedFilter
          entityType={entityType}
          removeAppliedFilter={removeAppliedFilter}
        />
      </Grid>
      <Grid item>
        {onCreate ? (
          <ButtonLoading
            style={{ margin: "5px" }}
            variant="outlined"
            color="primary"
            testid="createEntity"
            authoritiesRequired={[getEntityRoleCreate(entityType)]}
            onClick={onCreate}
          >
            {createLabel}
          </ButtonLoading>
        ) : null}
        {entityType === MODEL_TYPE ? <AllLinksGraph /> : null}
        {enableExportHistory ? (
          <OpenExportDrawerButton
            entityType={entityType}
            onOpenDrawer={onOpenDrawer}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default TableTopMenu;
