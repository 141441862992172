import React, { useEffect, useState } from "react";
import {
  DialogContent,
  Dialog,
  DialogActions,
  TablePagination,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import APPCONFIG from "../../../constants/Config";
import { connect } from "react-redux";
import { updateUserPreferences } from "actions/Configuration";
import TabCentered from "components/TabContainer/TabCentered";
import moment from "moment";
import { Link } from "react-router-dom";
import { getEntityDataHubPath } from "../../../api/Entity";

let refreshTimeout = null;
const DialogLogs = ({
  open,
  logs,
  loading,
  error,
  onClose,
  onRefreshLogs,
  preferences,
  selectedAttributes,
  updateUserPreferences,
  withContext
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    preferences && preferences.auditTrailRowsPerTable ? preferences.auditTrailRowsPerTable : 10
  );

  useEffect(() => {
    setRowsPerPage(preferences && preferences.auditTrailRowsPerTable  ? preferences.auditTrailRowsPerTable : 10);
  }, [preferences]);

  const refreshTimer = () => {
    refresh();
  };
  const refresh = () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }
    if (open && onRefreshLogs) {
      refreshTimeout = setTimeout(() => {
        refreshTimer();
      }, APPCONFIG.REFRESH_TIMEOUT);
      onRefreshLogs(page * rowsPerPage, rowsPerPage);
    }
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };
  const handleChangeRowsPerPage = event => {
    const _rowPerPage = event.target.value;
    setRowsPerPage(_rowPerPage);
    setPage(0);
    updateUserPreferences({
      ...preferences,
      selectedAttributes,
      auditTrailRowsPerTable: _rowPerPage
    });
  };

  useEffect(refresh, [open, rowsPerPage, page]);

  useEffect(() => {
    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, []);

  let content;

  const pagination = (
    <TablePagination
      style={{ margin: "auto" }}
      component="div"
      count={logs && logs.totalResultCount ? logs.totalResultCount : 0}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        "aria-label": "Previous Page"
      }}
      nextIconButtonProps={{
        "aria-label": "Next Page"
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  if (!logs && loading) {
    content = (
      <TabCentered>
        <div>
          <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
          <span style={{ verticalAlign: "middle", marginLeft: "5px" }}>
            Loading the history
          </span>
        </div>
      </TabCentered>
    );
  } else if (!logs && error) {
    content = (
      <TabCentered>
        <span style={{ verticalAlign: "middle", color: "red" }}>
          {"Error during the loading of the history: " + error}
        </span>
      </TabCentered>
    );
  } else if (!logs || !logs.result || !logs.result.length) {
    content = (
      <TabCentered>
        <span style={{ verticalAlign: "middle" }}>{"No log found"}</span>
      </TabCentered>
    );
  } else {
    content = (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Event</TableCell>
            {withContext ? <TableCell>Entity type</TableCell> : null}
            {withContext ? <TableCell>Entity label</TableCell> : null}
            <TableCell>Date</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>File</TableCell>
            {withContext ? <TableCell>Link</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.result.map(log => {
            const {
              eventType,
              loggedOn,
              message,
              storageTypePlatform,
              folder,
              id,
              fileName,
              entityType,
              entityLabel,
              entityId,
              entity
            } = log;
            const href = getEntityDataHubPath(
              entityType,
              entityId,
              folder,
              entity,
              storageTypePlatform
            );
            return (
              <TableRow key={id}>
                <TableCell>{eventType}</TableCell>
                {withContext ? <TableCell>{entityType}</TableCell> : null}
                {withContext ? <TableCell>{entityLabel}</TableCell> : null}
                <TableCell>{moment(loggedOn).format(APPCONFIG.dateTimeFormat)}</TableCell>
                <TableCell>{message}</TableCell>
                <TableCell>{folder + fileName}</TableCell>
                {withContext ? (
                  <TableCell>
                    {href ? (
                      <Link to={href} className="list-inline-item">
                        <ArrowForwardIosIcon />
                      </Link>
                    ) : null}
                  </TableCell>
                ) : null}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
        Logs
      </CloseableDialogTitle>
      <DialogContent>
        {content}
        {pagination}
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    preferences: state.auth.preferences,
    selectedAttributes: state.attributes.attributesToShow
  };
};

export default connect(
  mapStateToProps,
  { updateUserPreferences }
)(DialogLogs);
