import React, { Component } from "react";
import { connect } from "react-redux";
import IconButton from "../Buttons/IconButton";
import { Close, Done } from "@material-ui/icons";
import { Input, InputAdornment } from "@material-ui/core";

class EditableDroolsHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changedName: props.rule && props.rule.name
    };
  }

  handleChangeName = event => {
    this.setState({
      changedName: event.target.value
    });
  };

  renderRenamingMode = () => {
    const { changedName } = this.state;
    const { handleCloseRenaming, handleRenameUpdate } = this.props;
    return (
      <form
        onSubmit={() => handleRenameUpdate(changedName)}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Input
          fullWidth
          value={changedName}
          onChange={this.handleChangeName}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                style={{ padding: "4px", marginRight: "8px" }}
                size="small"
                onClick={() => handleRenameUpdate(changedName)}
              >
                <Done style={{ margin: 0 }} size="small" />
              </IconButton>
              <IconButton
                style={{ padding: "4px" }}
                size="small"
                onClick={handleCloseRenaming}
              >
                <Close style={{ margin: 0 }} size="small" />
              </IconButton>
            </InputAdornment>
          }
        />
      </form>
    );
  };

  render() {
    const { rule, openRenamingDialog } = this.props;
    const { name } = rule;
    if (openRenamingDialog) {
      return this.renderRenamingMode();
    } else {
      return name;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(EditableDroolsHeading);
