import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, matchPath } from "react-router";
import { Link } from "react-router-dom";
import {
  APP_PATH,
  MODELS_PATH,
  MODELS_TYPE_PATH,
  MODEL_PATH,
  MODEL_REVISION_PATH,
  MODEL_USAGE_PATH,
  VALIDATION_COMMITTEES_PATH,
  VALIDATION_COMMITTEES_TYPE_PATH,
  VALIDATION_COMMITTEE_PATH,
  VALIDATIONS_PATH,
  VALIDATIONS_TYPE_PATH,
  VALIDATION_PATH,
  TASKS_PATH,
  ADMIN_PATH,
  DEVELOPER_PATH,
  FOLDERS_PATH,
  FOLDER_PATH,
  PERSONAL_SECTION_PATH,
  LICENSE_OVERVIEW_PATH,
  PROCESSES_OVERVIEW_PATH,
  MODEL_MONITORING_OVERVIEW_PATH
} from "constants/Routes";
import {
  MODEL_TYPE,
  VALIDATION_TYPE,
  VALIDATION_COMMITTEE_TYPE,
  MODEL_REVISION_TYPE,
  MODEL_USAGE_TYPE
} from "api/Entity";
import { KeyboardArrowRight } from "@material-ui/icons";
import { setTitle } from "api/GUI";

import { Stepper, Step, StepButton, Typography } from "@material-ui/core";
import { AUTOMATION_LABEL } from "constants/Workflows";

const Connector = () => <KeyboardArrowRight />;

class BreadCrumbsComponent extends Component {
  state = {
    stepIndex: 10
  };

  getModelsLink = params => {
    return MODELS_PATH;
  };
  getModelsTypesLink = params => {
    return this.getModelsLink(params) + "/model-types";
  };
  getModelsTypeLink = params => {
    return this.getModelsLink(params) + "/" + params.entityType;
  };
  getModelLink = params => {
    return this.getModelsTypeLink(params) + "/" + params.entityId;
  };
  getModelRevisionLink = params => {
    return this.getModelLink(params) + "/revision/" + params.revisionId;
  };
  getModelUsageLink = params => {
    return this.getModelRevisionLink(params) + "/usage/" + params.usageId;
  };
  getValidationCommitteesLink = params => {
    return VALIDATION_COMMITTEES_PATH;
  };
  getValidationCommitteesTypeLink = params => {
    return this.getValidationCommitteesLink(params) + "/" + params.entityType;
  };
  getValidationCommitteeLink = params => {
    return this.getValidationCommitteesLink(params) + "/" + params.entityId;
  };
  getValidationsLink = params => {
    return VALIDATIONS_PATH;
  };
  getValidationsTypeLink = params => {
    return this.getValidationsLink(params) + "/" + params.entityType;
  };
  getValidationLink = params => {
    return this.getValidationsLink(params) + "/" + params.entityId;
  };
  getTasksLink = params => {
    return TASKS_PATH;
  };
  getFoldersLink = params => {
    return FOLDERS_PATH;
  };
  getFolderLink = params => {
    return this.getFoldersLink(params) + "/" + params.folderId;
  };
  getAdminLink = params => {
    return ADMIN_PATH;
  };
  getAccountLink = params => {
    return PERSONAL_SECTION_PATH;
  };
  getConsultantLink = params => {
    return DEVELOPER_PATH;
  };
  getLicenseLink = params => {
    return LICENSE_OVERVIEW_PATH + "?tab=license_info";
  };
  getProcessesLink = params => {
    return PROCESSES_OVERVIEW_PATH;
  };
  getMonitoringLink = params => {
    return MODEL_MONITORING_OVERVIEW_PATH;
  };

  getModelsStep = () => {
    const { location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: MODELS_PATH
    });
    if (!match) {
      return null;
    }
    setTitle("Model catalogue");
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getModelsLink(match.params) + "/overview"}
          icon={null}
        >
          Model catalogue
        </StepButton>
      </Step>
    );
  };
  getModelsTypeStep = () => {
    const { location, model_type, model } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: MODELS_TYPE_PATH
    });
    if (
      !match ||
      !match.params.entityType ||
      match.params.entityType === "overview"
    ) {
      return null;
    }
    setTitle("Model types");
    if (match.params.entityType === "model-types") {
      return (
        <Step>
          <StepButton
            component={Link}
            to={this.getModelsTypesLink(match.params) + "/overview"}
            icon={null}
          >
            Model types
          </StepButton>
        </Step>
      );
    }

    let name = "",
      subtitle = "";
    if (model_type) {
      name = model_type.label;
      subtitle = model_type.subtitle;
    } else if (model && model.modelType) {
      name = model.modelType.label;
      subtitle = model.modelType.subtitle;
    }
    setTitle(name);
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getModelsTypeLink(match.params) + "/overview"}
          icon={null}
        >
          <Typography display="block" variant="caption">
            Model type
          </Typography>
          {name}
          <Typography display="block" variant="caption">
            {subtitle}
          </Typography>
        </StepButton>
      </Step>
    );
  };
  getModelStep = () => {
    const { model, location, creation_mode_model } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: MODEL_PATH
    });
    if (
      !match ||
      !match.params.entityId ||
      match.params.entityId === "overview"
    ) {
      return null;
    }

    let name = "",
      caption,
      subtitle = "";
    if (creation_mode_model) {
      name = "Model creation";
    } else if (model) {
      caption = (
        <Typography display="block" variant="caption">
          Model
        </Typography>
      );
      name = model.label;
      subtitle = model.subtitle;
    }
    setTitle(name);
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getModelLink(match.params) + "/overview?tab=attributes"}
          icon={null}
        >
          {caption}
          {name}
          <Typography display="block" variant="caption">
            {subtitle}
          </Typography>
        </StepButton>
      </Step>
    );
  };
  getModelRevisionStep = () => {
    const { modelRevision, location, creation_mode_revision } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: MODEL_REVISION_PATH
    });
    if (!match) {
      return null;
    }

    let name = "",
      caption,
      subtitle = "";
    if (creation_mode_revision) {
      name = "Model revision creation";
    } else if (modelRevision) {
      caption = (
        <Typography display="block" variant="caption">
          Model revision
        </Typography>
      );
      name = modelRevision.label;
      subtitle = modelRevision.subtitle;
    }
    document.title = document.title + " - " + name;
    return (
      <Step>
        <StepButton
          component={Link}
          to={
            this.getModelRevisionLink(match.params) + "/overview?tab=attributes"
          }
          icon={null}
        >
          {caption}
          {name}
          <Typography display="block" variant="caption">
            {subtitle}
          </Typography>
        </StepButton>
      </Step>
    );
  };
  getModelUsageStep = () => {
    const { modelUsage, location, creation_mode_model_usage } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: MODEL_USAGE_PATH
    });
    if (!match) {
      return null;
    }

    let name = "",
      caption,
      subtitle = "";
    if (creation_mode_model_usage) {
      name = "Model usage creation";
    } else if (modelUsage) {
      caption = (
        <Typography display="block" variant="caption">
          Model usage
        </Typography>
      );
      name = modelUsage.label;
      subtitle = modelUsage.subtitle;
    }
    setTitle(name);
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getModelUsageLink(match.params) + "/overview?tab=attributes"}
          icon={null}
        >
          {caption}
          {name}
          <Typography display="block" variant="caption">
            {subtitle}
          </Typography>
        </StepButton>
      </Step>
    );
  };

  getValidationCommitteesStep = () => {
    const { location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: VALIDATION_COMMITTEES_PATH
    });
    if (!match) {
      return null;
    }
    setTitle("Committees");
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getValidationCommitteesLink(match.params) + "/overview"}
          icon={null}
        >
          Committees
        </StepButton>
      </Step>
    );
  };
  getValidationCommitteesTypeStep = () => {
    const { location, validation_committee_type } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: VALIDATION_COMMITTEES_TYPE_PATH
    });
    if (
      !match ||
      !match.params.entityType ||
      match.params.entityType === "overview"
    ) {
      return null;
    }
    if (match.params.entityType === "validation-committees-types") {
      setTitle("Validation committee types");
      return (
        <Step>
          <StepButton icon={null}>Validation committee types</StepButton>
        </Step>
      );
    }

    let name = "",
      subtitle = "";
    if (validation_committee_type) {
      name = validation_committee_type.label;
      subtitle = validation_committee_type.subtitle;
    }
    setTitle(name);
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getValidationCommitteesTypeLink(match.params) + "/overview"}
          icon={null}
        >
          <Typography display="block" variant="caption">
            Validation committee type
          </Typography>
          {name}
          <Typography display="block" variant="caption">
            {subtitle}
          </Typography>
        </StepButton>
      </Step>
    );
  };
  getValidationCommitteeStep = () => {
    const { location, committee } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: VALIDATION_COMMITTEE_PATH
    });
    if (
      !match ||
      !match.params.entityId ||
      match.params.entityId === "overview"
    ) {
      return null;
    }

    let name = "",
      subtitle = "";
    if (committee) {
      name = committee.label;
      subtitle = committee.subtitle;
    }
    setTitle(name);
    return (
      <Step>
        <StepButton
          component={Link}
          to={
            this.getValidationCommitteeLink(match.params) +
            "/overview?tab=attributes"
          }
          icon={null}
        >
          <Typography display="block" variant="caption">
            Committee
          </Typography>
          {name}
          <Typography display="block" variant="caption">
            {subtitle}
          </Typography>
        </StepButton>
      </Step>
    );
  };
  getValidationsStep = () => {
    const { location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: VALIDATIONS_PATH
    });
    if (!match) {
      return null;
    }
    setTitle("Validations");
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getValidationsLink(match.params) + "/overview"}
          icon={null}
        >
          Validations
        </StepButton>
      </Step>
    );
  };
  getValidationTypeStep = () => {
    const { location, validation_type } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: VALIDATIONS_TYPE_PATH
    });
    if (
      !match ||
      !match.params.entityType ||
      match.params.entityType === "committees" ||
      match.params.entityType === "overview"
    ) {
      return null;
    }
    if (match.params.entityType === "validation-types") {
      setTitle("Validation types");
      return (
        <Step>
          <StepButton icon={null}>Validation types</StepButton>
        </Step>
      );
    }

    let name = "",
      subtitle = "";
    if (validation_type) {
      name = validation_type.label;
      subtitle = validation_type.subtitle;
    }
    setTitle(name);
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getValidationsTypeLink(match.params) + "/overview"}
          icon={null}
        >
          <Typography display="block" variant="caption">
            Validation type
          </Typography>
          {name}
          <Typography display="block" variant="caption">
            {subtitle}
          </Typography>
        </StepButton>
      </Step>
    );
  };
  getValidationStep = () => {
    const { validation, location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: VALIDATION_PATH
    });
    if (
      !match ||
      !match.params.entityId ||
      match.params.entityId === "overview" ||
      match.params.entityType === "committees"
    ) {
      return null;
    }
    let name = "",
      subtitle = "";
    if (validation) {
      name = validation.label;
      subtitle = validation.subtitle;
    }
    setTitle(name);
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getValidationLink(match.params) + "/overview?tab=attributes"}
          icon={null}
        >
          <Typography display="block" variant="caption">
            Validation
          </Typography>
          {name}
          <Typography display="block" variant="caption">
            {subtitle}
          </Typography>
        </StepButton>
      </Step>
    );
  };

  getTasksStep = () => {
    const { location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: TASKS_PATH
    });
    if (!match) {
      return null;
    }
    setTitle("Tasks");
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getTasksLink(match.params) + "/overview?tab=my_tasks"}
          icon={null}
        >
          Tasks
        </StepButton>
      </Step>
    );
  };

  getFoldersStep = () => {
    const { location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: FOLDERS_PATH
    });
    if (!match) {
      return null;
    }
    setTitle("Data hub");
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getFoldersLink(match.params) + "/overview"}
          icon={null}
        >
          Data hub
        </StepButton>
      </Step>
    );
  };
  getFolderStep = () => {
    const { folder, location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: FOLDER_PATH
    });
    if (
      !match ||
      !match.params.folderId ||
      match.params.folderId === "overview"
    ) {
      return null;
    }
    let name = "",
      subtitle = "";
    if (folder) {
      name = folder.label;
      subtitle = folder.subtitle;
    }
    setTitle(name);
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getFolderLink(match.params) + "/overview"}
          icon={null}
        >
          <Typography display="block" variant="caption">
            Folder
          </Typography>
          {name}
          <Typography display="block" variant="caption">
            {subtitle}
          </Typography>
        </StepButton>
      </Step>
    );
  };

  getAdminStep = () => {
    const { location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: ADMIN_PATH
    });
    if (!match) {
      return null;
    }
    setTitle("Admin panel");
    return (
      <Step>
        <StepButton
          component={Link}
          to={
            this.getAdminLink(match.params) + "/overview?tab=admin_containers"
          }
          icon={null}
        >
          Admin panel
        </StepButton>
      </Step>
    );
  };

  getAccountStep = () => {
    const { location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: PERSONAL_SECTION_PATH
    });
    if (!match) {
      return null;
    }
    setTitle("My account");
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getAccountLink(match.params) + "/overview?tab=account_info"}
          icon={null}
        >
          My account
        </StepButton>
      </Step>
    );
  };

  getConsultantStep = () => {
    const { location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: DEVELOPER_PATH
    });
    if (!match) {
      return null;
    }
    setTitle("Root");
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getConsultantLink(match.params) + "/overview?tab=admin_rest"}
          icon={null}
        >
          Root
        </StepButton>
      </Step>
    );
  };

  getLicenseStep = () => {
    const { location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: LICENSE_OVERVIEW_PATH
    });
    if (!match) {
      return null;
    }
    setTitle("License");
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getLicenseLink(match.params) + "?tab=license_info"}
          icon={null}
        >
          License
        </StepButton>
      </Step>
    );
  };

  getProcessesStep = () => {
    const { location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: PROCESSES_OVERVIEW_PATH
    });
    if (!match) {
      return null;
    }
    setTitle(AUTOMATION_LABEL);
    return (
      <Step>
        <StepButton
          component={Link}
          to={this.getProcessesLink(match.params)}
          icon={null}
        >
          Automation
        </StepButton>
      </Step>
    );
  };

  getMonitoringStep = () => {
    const { location } = this.props;
    const currentPath = location.pathname;
    const match = matchPath(currentPath, {
      path: MODEL_MONITORING_OVERVIEW_PATH
    });
    if (!match) {
      return null;
    }
    setTitle("Model monitoring");
    return (
        <Step>
          <StepButton
              component={Link}
              to={this.getMonitoringLink(match.params)}
              icon={null}
          >
            Model monitoring
          </StepButton>
        </Step>
    );
  };

  render() {
    const { stepIndex } = this.state;
    const { location } = this.props;
    setTitle("");
    if (location.pathname === "/overview") {
      return null;
    }

    let modelsStep = this.getModelsStep(),
      modelsTypeStep = this.getModelsTypeStep(),
      modelStep = this.getModelStep(),
      modelRevisionStep = this.getModelRevisionStep(),
      modelUsageStep = this.getModelUsageStep(),
      validationCommitteesStep = this.getValidationCommitteesStep(),
      validationCommitteesTypeStep = this.getValidationCommitteesTypeStep(),
      validationCommitteeStep = this.getValidationCommitteeStep(),
      validationsStep = this.getValidationsStep(),
      validationsTypeStep = this.getValidationTypeStep(),
      validationStep = this.getValidationStep(),
      tasksStep = this.getTasksStep(),
      foldersStep = this.getFoldersStep(),
      folderStep = this.getFolderStep(),
      adminStep = this.getAdminStep(),
      accountStep = this.getAccountStep(),
      consultantStep = this.getConsultantStep(),
      licenseStep = this.getLicenseStep(),
      processesStep = this.getProcessesStep(),
      monitoringStep = this.getMonitoringStep()
    ;

    return (
      <Stepper
        style={{ background: "#fafafa" }}
        activeStep={stepIndex}
        connector={<Connector />}
      >
        <Step>
          <StepButton component={Link} to={APP_PATH + "/overview"} icon={null}>
            Home
          </StepButton>
        </Step>
        {modelsStep}
        {modelsTypeStep}
        {modelStep}
        {modelRevisionStep}
        {modelUsageStep}
        {validationsStep}
        {validationsTypeStep}
        {validationStep}
        {validationCommitteesStep}
        {validationCommitteesTypeStep}
        {validationCommitteeStep}
        {tasksStep}
        {foldersStep}
        {folderStep}
        {accountStep}
        {adminStep}
        {consultantStep}
        {licenseStep}
        {processesStep}
        {monitoringStep}
      </Stepper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  route: ownProps.route,
  model: state.models.current,
  modelUsage: state.modelUsages.current,
  folder: state.folders.current,
  committee: state.committees.current,
  validation: state.validations.current,
  modelRevision: state.modelRevisions.current,
  model_type: state.entity && state.entity[MODEL_TYPE]
    ? state.entity[MODEL_TYPE].entity_type
    : null,
  creation_mode_model: state.entity && state.entity[MODEL_TYPE]
    ? state.entity[MODEL_TYPE].creation_mode
    : null,
  creation_mode_model_usage: state.entity && state.entity[MODEL_USAGE_TYPE]
    ? state.entity[MODEL_USAGE_TYPE].creation_mode
    : null,
  creation_mode_revision: state.entity && state.entity[MODEL_REVISION_TYPE]
    ? state.entity[MODEL_REVISION_TYPE].creation_mode
    : null,
  validation_type: state.entity && state.entity[VALIDATION_TYPE]
    ? state.entity[VALIDATION_TYPE].entity_type
    : null,
  validation_committee_type: state.entity && state.entity[VALIDATION_COMMITTEE_TYPE]
    ? state.entity[VALIDATION_COMMITTEE_TYPE].entity_type
    : null
});

export default withRouter(connect(mapStateToProps)(BreadCrumbsComponent));
