import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { renderRoutes } from "react-router-config";
import DrawerView from "components/Drawer";
import DrawerTabs from "components/Drawer/DrawerTabs";
import Tabs from "components/TabContainer/Tabs";
import DrawerContent from "components/Drawer/DrawerContent";
import {
  FOLDER_OVERVIEW_PATH,
  FOLDER_PATH,
  FOLDERS_OVERVIEW_PATH
} from "../../../../constants/Routes";
import { matchPath, withRouter } from "react-router";
import { createFolder, getFolders } from "actions/Folders";
import { Divider, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SecurityLevelLabel from "components/SecurityLevels/Label";
import CreateFolderDialogForm from "components/Folders/CreateFolderDialogForm";
import ButtonLoading from "components/Buttons/ButtonLoading";
import DrawerActions from "components/Drawer/DrawerActions";
import DeleteButton from "components/Folders/DeleteButton";
import {
  getEntityRoleAttachmentUpload,
  getEntityType,
  PERSONAL_FOLDER_TYPE,
  PUBLIC_FOLDER_TYPE
} from "../../../../api/Entity";
import { ROLE_SHARED_FOLDER_MANAGE } from "../../../../constants/Authorities";
import APPCONFIG from "../../../../constants/Config";
import { DATAHUB_SHARED_FOLDER } from "../../../../constants/Features";
import UpdateButton from "components/Folders/UpdateButton";
import Dropzone from "components/Attachments/Dropzone";
import { isAuthorized } from "../../../../api/Authorities";

const Folders = ({
  getFolders,
  features,
  history,
  createFolder,
  route,
  folders,
  location,
  classes,
  folder,
  creating,
  loading,
  me
}) => {
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState(null);
  let refreshTimeout = null;
  useEffect(() => {
    refresh();
    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, []);
  useEffect(() => {
    setDroppedFiles(null);
  }, [location.pathname]);

  const refresh = () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }
    refreshTimeout = setTimeout(() => {
      refresh();
    }, APPCONFIG.REFRESH_TIMEOUT);

    getFolders(features);
  };

  const handleChange = folderId => {
    if (folderId === "overview") {
      history.push(FOLDERS_OVERVIEW_PATH);
      return;
    }
    history.push(FOLDER_OVERVIEW_PATH.replace(":folderId", folderId));
  };

  const createNewFolder = () => {
    setOpenCreateFolderDialog(true);
  };
  const handleCloseCreateNewFolder = () => {
    setOpenCreateFolderDialog(false);
  };
  const handleSubmitCreateNewFolder = folder => {
    createFolder(folder);
  };

  const onDrop = files => {
    setDroppedFiles(files);
  };

  const currentPath = location.pathname;
  const match = matchPath(currentPath, {
    path: FOLDER_PATH
  });
  const matchFolderOverview = matchPath(currentPath, {
    path: FOLDER_OVERVIEW_PATH
  });
  const matchFoldersOverview = matchPath(currentPath, {
    path: FOLDERS_OVERVIEW_PATH
  });
  let tabs = [];
  if (folders && folders.length > 0) {
    tabs = folders.map(folder => {
      return {
        featuresRequired: folder.featuresRequired,
        authoritiesRequired: folder.authoritiesRequired,
        value: folder.id,
        label: folder.name
      };
    });
  }

  const authorityRequiredUpload = getEntityRoleAttachmentUpload(folder);
  const isAuthorizedToUpload = isAuthorized(
    me,
    authorityRequiredUpload ? [authorityRequiredUpload] : null
  );

  const folderType = getEntityType(folder);
  const isSharedFolder =
    matchFolderOverview &&
    folder &&
    folderType !== PUBLIC_FOLDER_TYPE &&
    folderType !== PERSONAL_FOLDER_TYPE;

  return (
    <React.Fragment>
      <Dropzone
        onDrop={onDrop}
        style={{ minHeight: 0, height: "100%" }}
        disabled={!isAuthorizedToUpload}
      >
        <DrawerView>
          {!matchFoldersOverview && (
            <DrawerTabs>
              <div className={classes.attributesContainer}>
                <SecurityLevelLabel entity={folder} />
                <div className={classes.attributeContainer}>
                  <Typography variant="caption">Description</Typography>
                  <Typography variant="body2">
                    {(folder && folder.description) || "No description"}
                  </Typography>
                </div>
              </div>
              <Divider />
              <ButtonLoading
                loading={creating}
                loadingTitle="Creating shared folder"
                style={{ width: "200px", margin: "10px auto 0" }}
                variant="outlined"
                featuresRequired={[DATAHUB_SHARED_FOLDER]}
                authoritiesRequired={[ROLE_SHARED_FOLDER_MANAGE]}
                onClick={createNewFolder}
                color="primary"
              >
                {"Create shared folder"}
              </ButtonLoading>
              <Tabs
                withoutUrlManagement
                droppable
                tabs={tabs}
                loading={loading}
                value={match && match.params.folderId}
                onChange={handleChange}
              />
            </DrawerTabs>
          )}
          <DrawerContent>
            {renderRoutes(route.routes, {
              createNewFolder: createNewFolder,
              droppedFiles
            })}
          </DrawerContent>
          {isSharedFolder && (
            <DrawerActions>
              <div className={classes.buttonAction}>
                <UpdateButton folder={folder} />
              </div>
              <div className={classes.buttonAction}>
                <DeleteButton folder={folder || {}} />
              </div>
            </DrawerActions>
          )}
        </DrawerView>
      </Dropzone>
      <CreateFolderDialogForm
        open={openCreateFolderDialog}
        onSubmit={handleSubmitCreateNewFolder}
        handleClose={handleCloseCreateNewFolder}
      />
    </React.Fragment>
  );
};

const styles = () => ({
  attributesContainer: {
    paddingTop: "10px"
  },
  attributeContainer: {
    padding: "0 24px 10px 24px"
  },
  attributeValue: {
    fontWeight: "bold"
  },

  buttonAction: {
    width: 240
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    route: ownProps.route,
    folders: state.folders.list,
    folder: state.folders.current,
    me: state.auth.me,
    creating: state.folders.creating,
    loading: state.folders.loading_folder,
    features: state.features.list
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      { getFolders, createFolder }
    )(Folders)
  )
);
