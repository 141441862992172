export function arrayContainsArray(a, b, atLeastOne) {
  for (let i = 0; b && i < b.length; i++) {
    const includes = a.includes(b[i]);
    if (!atLeastOne && b[i] && !includes) {
      return false;
    } else if (atLeastOne && includes) {
      return true;
    }
  }
  return !atLeastOne || !b || b.length === 0;
}

export function getIdValue(id, object) {
  const ids = id.split('.');
  for (let i = 0; i < ids.length; i++) {
    if (!object && object !== 0) {
      return null;
    }
    object = object[ids[i]];
  }
  return object;
}

export function goThroughList(list, current, id, edit, idKey = 'id') {
  if (!id) {
    return {
      current: current,
      list: list,
    };
  }

  let idValue = getIdValue(idKey, current);
  if (idValue && idValue.toString() === id.toString()) {
    current = edit(current);
  }

  let new_list = [];
  for (let i = 0; list && i < list.length; i++) {
    idValue = getIdValue(idKey, list[i]);
    if (idValue && idValue.toString() === id.toString()) {
      list[i] = edit(list[i]);
      if (list[i]) {
        new_list.push(list[i]);
      }
    } else {
      new_list.push(list[i]);
    }
  }
  return {
    current: current,
    list: new_list,
  };
}