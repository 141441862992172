import axios from "axios/index";
import {
    FILTERS_CONFIG_ERROR,
    LOAD_FILTERS_CONFIG,
    LOADING_FILTERS_CONFIG,
    LOADING_FILTERS_CONFIG_END
} from "../../constants/ActionTypes";

export function searchMonitoring(searchDefinition) {
  return axios.post(
    "/api/model-monitoring/search",
      searchDefinition,
    {
      withCredentials: true
    }
  );
}

export function getMonitoring(monitoringId) {
  return axios.get(
    "/api/model-monitoring/" + monitoringId,
    {
      withCredentials: true
    }
  );
}

export function getSearchMonitoringMetadata() {
    return function (dispatch) {
        dispatch({type: LOADING_FILTERS_CONFIG});
        axios.get(
            "/api/model-monitoring/search",
            {
                withCredentials: true
            }
        )
            .then(response => {
                    dispatch({type: LOADING_FILTERS_CONFIG_END});
                    dispatch({type: LOAD_FILTERS_CONFIG, config: response.data});
                }
            )
            .catch(err => {
                dispatch({type: LOADING_FILTERS_CONFIG_END});
                dispatch({type: FILTERS_CONFIG_ERROR, error: err});
            });
    };
}

