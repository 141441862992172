import axios from "axios";

export function getODataAccessCodeAdmin() {
  return axios.get("/api/user-odata-access-codes", {
    withCredentials: true
  });
}

export function createODataAccessCodeAdmin(userId) {
  return axios.post(
    `/api/user-odata-access-codes/${userId}`,
    {},
    {
      withCredentials: true
    }
  );
}
export function removeODataAccessCodeAdmin(id) {
  return axios.delete(`/api/user-odata-access-codes/${id}`, {
    withCredentials: true
  });
}

export function getODataAccessCode() {
  return axios.get("/api/user-odata-access-codes/own", {
    withCredentials: true
  });
}

export function createODataAccessCode() {
  return axios.post(
    "/api/user-odata-access-codes/own",
    {},
    {
      withCredentials: true
    }
  );
}
export function removeODataAccessCode() {
  return axios.delete("/api/user-odata-access-codes/own", {
    withCredentials: true
  });
}
