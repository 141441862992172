import {
  CREATING_WORKFLOW,
  END_CREATING_WORKFLOW,
  CREATING_WORKFLOW_ERROR,
  SET_SEARCH_WORKFLOW_NAME_INPUT,
  SET_SEARCH_WORKFLOW_TYPE
} from "../../constants/ActionTypes";

export default function(state = {}, action) {
  const searchNameInput = state.searchNameInput ? state.searchNameInput : {};
  const searchType = state.searchType ? state.searchType : {};
  switch (action.type) {
    case CREATING_WORKFLOW:
      return { ...state, creating: true, error: undefined };
    case END_CREATING_WORKFLOW:
      return { ...state, creating: false };
    case CREATING_WORKFLOW_ERROR:
      return { ...state, creating_error: action.error };
    case SET_SEARCH_WORKFLOW_NAME_INPUT:
      searchNameInput[action.classification] = action.workflowSearchInput;
      return {
        ...state,
        searchNameInput: {
          ...searchNameInput
        }
      };
    case SET_SEARCH_WORKFLOW_TYPE:
      searchType[action.classification] = action.workflowSearchType;
      return {
        ...state,
        searchType: {
          ...searchType
        }
      };

    default:
      return state;
  }
}
