import React, {useState} from 'react';
import PaginatedSearchTableWidget from "components/Widget/PaginatedSearchTableWidget";
import SimpleTable from "components/Table/SimpleTable";
import TablePaginationWidget from "components/Widget/TablePaginationWidget";

const TableWidget = ({widget}) => {
    if(!widget || !widget.results){
        return null;
    }
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const { includeColumnNames, queryFormat, results } = widget;
    if (queryFormat === "SEARCH") {
        return (
            <PaginatedSearchTableWidget widget={widget} />
        );
    }

    let result = results;
    let columns = [];
    if (includeColumnNames && result && result.length > 0) {
        columns = result[0];
        result = result.slice(1);
    }

    if (!result) {
        return null;
    }
    if (!Array.isArray(result)) {
        return result;
    }

    return (
        <React.Fragment>
            <div className="paginated">
                <SimpleTable
                    columns={columns}
                    data={ result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) } />
            </div>
            <TablePaginationWidget
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={result.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onChangePage={(event, page) => setPage(page)}
                onChangeRowsPerPage={(event) => setRowsPerPage(event.target.value)}
            />
        </React.Fragment>
    );
};

export default TableWidget;
