import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../Buttons/ButtonLoading";
import { deleteEntity } from "../../actions/Entity";
import { getEntityLabel } from "../../api/Entity";
import { DialogContent, DialogActions, Dialog } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";

class DeleteButton extends Component {
  state = {
    open: false
  };

  componentWillReceiveProps(nextProps) {
    const { entity } = nextProps;
    if (!entity && this.props.entity) {
      this.closeDialog();
    }
  }

  openDialog = () => {
    this.setState({
      open: true
    });
  };
  closeDialog = () => {
    this.setState({
      open: false
    });
  };
  confirmRemove = () => {
    const { deleteEntity, entity } = this.props;
    deleteEntity(entity);
  };

  render() {
    const { entity, title, removingTitle } = this.props;
    const { open } = this.state;
    if (!entity || !entity.id) {
      return null;
    }

    return (
      <React.Fragment>
        <div style={{ paddingLeft: 50 }}>
          <ButtonLoading
            testid="deleteEntity"
            tooltipPlacement="left"
            tooltipTitle={title}
            loading={entity.deleting}
            loadingTitle={removingTitle}
            loadingSize={22}
            style={{
              width: "100%",
              justifyContent: "flex-start"
            }}
            onClick={this.openDialog}
            color="primary"
          >
            {title}
          </ButtonLoading>
        </div>
        <Dialog onClose={this.closeDialog} open={open}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={this.closeDialog}
          >
            This entity will be deleted, do you want to continue?
          </CloseableDialogTitle>
          <DialogContent>
            <div className="error">{entity.error_deleting}</div>
          </DialogContent>
          <DialogActions>
            <React.Fragment>
              {!entity.deleting && (
                <ButtonLoading
                  onClick={this.closeDialog}
                  color="primary"
                  autoFocus
                >
                  Cancel
                </ButtonLoading>
              )}
              <ButtonLoading
                testid="confirmDeleteEntity"
                loading={entity.deleting}
                loadingTitle={removingTitle}
                onClick={this.confirmRemove}
                color="primary"
              >
                Continue
              </ButtonLoading>
            </React.Fragment>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { title, removingTitle, entityType, entity } = ownProps;
  const entityLabel = getEntityLabel(entityType).toLowerCase();

  return {
    title: title || `Remove the ${entityLabel}`,
    removingTitle: removingTitle || `Removing the ${entityLabel}`
  };
};

export default connect(
  mapStateToProps,
  { deleteEntity }
)(DeleteButton);
