import {
  LOADING_CONFIGURATION,
  END_LOADING_CONFIGURATION,
  GET_CONFIGURATION_TIMESTAMP,
  ERROR_CONFIGURATION,
  GET_FEATURES,
  GET_WORKFLOW_TYPES,
  GET_ENTITY_TYPES,
  GET_SECURITY_LEVELS,
  GET_ATTRIBUTES,
  GET_ENUM_ENTRIES,
  GET_ATTACHMENTS_TYPES,
  LOAD_PROCESS_VARIABLES_CONFIG,
  GET_USER_PREFERENCES,
  UPDATING_USER_PREFERENCES,
  END_UPDATING_USER_PREFERENCES,
  ERROR_UPDATING_USER_PREFERENCES,
  GET_DYNAMIC_PROPERTIES,
  SET_DYNAMIC_PROPERTIES,
  GET_CONSTRAINT_ENTRIES
} from "constants/ActionTypes";
import axios from "axios";
import {
  MODEL_REVISION_TYPE,
  MODEL_TYPE,
  MODEL_USAGE_TYPE,
  PERSONAL_FOLDER_TYPE,
  PUBLIC_FOLDER_TYPE,
  SHARED_FOLDER_TYPE,
  VALIDATION_COMMITTEE_TYPE,
  VALIDATION_TYPE
} from "api/Entity";
import { setMe } from "actions/authentification";
import { getApiError } from "api/Error";

const attachementEntityTypes = [
  MODEL_REVISION_TYPE,
  MODEL_TYPE,
  VALIDATION_COMMITTEE_TYPE,
  VALIDATION_TYPE,
  PERSONAL_FOLDER_TYPE,
  PUBLIC_FOLDER_TYPE,
  SHARED_FOLDER_TYPE
];

export function getConfiguration() {
  return function(dispatch) {
    dispatch({
      type: LOADING_CONFIGURATION
    });
    axios
      .get("/api/context-configuration", {
        withCredentials: true
      })
      .then(response => {
        const config = response.data;
        axios.defaults.headers.common["Context-Timestamp"] = config.timestamp;
        dispatch({
          type: GET_CONFIGURATION_TIMESTAMP,
          timestamp: config.timestamp
        });
        setMe(config.currentUser)(dispatch);
        dispatch({
          type: GET_FEATURES,
          features: config.enabledFeatures
        });
        dispatch({
          type: GET_WORKFLOW_TYPES,
          types: config.workflowTypes
        });
        dispatch({
          type: LOAD_PROCESS_VARIABLES_CONFIG,
          config: config.processVariables
        });
        dispatch({
          type: GET_ENTITY_TYPES,
          entityTypes: config.validationTypes,
          entityType: VALIDATION_TYPE
        });
        dispatch({
          type: GET_ENTITY_TYPES,
          entityTypes: config.validationCommitteeTypes,
          entityType: VALIDATION_COMMITTEE_TYPE
        });
        dispatch({
          type: GET_ENTITY_TYPES,
          entityTypes: config.modelTypes,
          entityType: MODEL_TYPE
        });
        dispatch({
          type: GET_ENTITY_TYPES,
          entityTypes: config.modelUsageTypes,
          entityType: MODEL_USAGE_TYPE
        });
        dispatch({
          type: GET_SECURITY_LEVELS,
          securityLevels: config.securityLevels
        });
        dispatch({
          type: GET_ATTRIBUTES,
          attributes: config.attributes
        });
        dispatch({
          type: GET_ENUM_ENTRIES,
          enumEntries: config.enumEntries
        });
        dispatch({
          type: GET_USER_PREFERENCES,
          preferences: config.userPreferences
        });

        let attachmentCategories = {};
        for (
          let i = 0;
          attachementEntityTypes && i < attachementEntityTypes.length;
          i++
        ) {
          attachmentCategories[attachementEntityTypes[i]] = [];
        }
        for (
          let i = 0;
          config.attachmentCategoryEntries &&
          i < config.attachmentCategoryEntries.length;
          i++
        ) {
          const category = config.attachmentCategoryEntries[i];
          if (category.applicableForEntity) {
            attachmentCategories[category.applicableForEntity].push(category);
          } else {
            for (let j = 0; j < attachementEntityTypes.length; j++) {
              const entityType = attachementEntityTypes[j];
              attachmentCategories[entityType].push(category);
            }
          }
        }
        for (
          let i = 0;
          attachementEntityTypes && i < attachementEntityTypes.length;
          i++
        ) {
          const entityType = attachementEntityTypes[i];
          dispatch({
            type: GET_ATTACHMENTS_TYPES,
            entityType: entityType,
            types: attachmentCategories[entityType]
          });
        }
        const dynamicProperties = {};
        for (let i = 0; i < config.dynamicProperties.length; i++) {
          const { name, value } = config.dynamicProperties[i];
          dynamicProperties[name] = value;
        }
        dispatch({
          type: GET_DYNAMIC_PROPERTIES,
          dynamicProperties
        });
        const constraintEntries = config.constraintEntries.reduce(
          (constraintEntries, { ownerId, constraintName }) => {
            if (!constraintEntries[ownerId]) {
              constraintEntries[ownerId] = [];
            }
            constraintEntries[ownerId].push(constraintName);
            return constraintEntries;
          },
          {}
        );
        dispatch({
          type: GET_CONSTRAINT_ENTRIES,
          constraintEntries
        });
        dispatch({
          type: END_LOADING_CONFIGURATION
        });
      })
      .catch(err => {
        let message = getApiError(err);
        dispatch({
          type: ERROR_CONFIGURATION,
          error: message
        });
        dispatch({
          type: END_LOADING_CONFIGURATION
        });
      });
  };
}

export async function getAppVersion() {
  return axios.get("/api/app-version")
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.err("Failed to fetch app version: " + err)
      });
}

export function updateUserPreferences(preferences) {
  return function(dispatch) {
    dispatch({
      type: UPDATING_USER_PREFERENCES
    });
    axios
      .put("/api/user-preferences", preferences, {
        withCredentials: true
      })
      .then(response => {
        const preferences = response.data;
        dispatch({
          type: GET_USER_PREFERENCES,
          preferences
        });
        dispatch({
          type: END_UPDATING_USER_PREFERENCES
        });
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: ERROR_UPDATING_USER_PREFERENCES,
          error: message
        });
        dispatch({
          type: END_UPDATING_USER_PREFERENCES
        });
      });
  };
}

export function updateDynamicProperty(name, value) {
  return async function(dispatch) {
    await axios.put(`/api/dynamic-properties/${name}`, value, {
      withCredentials: true,
      headers: {
        "Content-Type": "text/plain"
      }
    });
    dispatch({
      type: SET_DYNAMIC_PROPERTIES,
      name,
      value
    });
  };
}
