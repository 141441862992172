import React, {useEffect, useState} from "react";
import {useApiRequest} from "actions/index";
import TabCentered from "components/TabContainer/TabCentered";
import {CircularProgress, Grid} from "@material-ui/core";
import RolesList from "components/Admin/Users/Roles/RolesList";
import PanelHeader from 'components/Admin/Users/Common/PanelHeader';
import CreateRoleContainer from 'components/Admin/Users/Roles/Dialog/CreateRoleContainer';
import {getAllRoles} from "actions/Roles";

const RolesPanel = () => {
  const [roles, setRoles] = useState([]);
  const [loading, error, getRoles] = useApiRequest(
    getAllRoles,
    (rolesResponse) => {
      setRoles(rolesResponse.content);
    });

  useEffect(()=>{
    getRoles()
  },[]);

  const addRole = (newRole) => {
    if (newRole) {
      setRoles((roles) => [newRole, ...roles]);
    }
  };

  const updateRole = (updatedRole) => {
    delete updatedRole.grantedAuthorities;
    setRoles((roles) => roles.map(old => old.id === updatedRole.id ? updatedRole : old));
  };

  if(loading){
    return (
      <TabCentered>
        <div style={{ lineHeight: "36px", textAlign: "center" }}>
          <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
          <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
            Loading roles
          </span>
        </div>
      </TabCentered>
    );
  }
  if(roles.length < 1){
    return (
      <TabCentered>
        <div className="emptyMessage">No roles found</div>
        <div className="error">{error}</div>
      </TabCentered>
    );
  }
  
  return (
    <>
      <PanelHeader buttonEl={<CreateRoleContainer onSubmit={addRole}/>}/>
      <RolesList roles={roles} onRoleUpdate={updateRole}/>
    </>
  );

};

export default RolesPanel
