import {
  GET_WORKFLOWS,
  ADD_WORKFLOWS,
  LOADING_WORKFLOWS,
  END_LOADING_WORKFLOWS,
  WORKFLOWS_ERROR,
  LOADING_WORKFLOW,
  END_LOADING_WORKFLOW,
  WORKFLOW_ERROR,
  GET_WORKFLOW,
  UPDATING_WORKFLOW,
  END_UPDATING_WORKFLOW,
  UPDATE_WORKFLOW_ERROR,
  RENAMING_WORKFLOW,
  END_RENAMING_WORKFLOW,
  RENAMING_WORKFLOW_ERROR,
  GET_WORKFLOW_DIAGRAM,
  LOADING_WORKFLOW_DIAGRAM,
  END_LOADING_WORKFLOW_DIAGRAM,
  WORKFLOW_DIAGRAM_ERROR,
  DELETE_WORKFLOW,
  DELETING_WORKFLOW,
  END_DELETING_WORKFLOW,
  DELETE_WORKFLOW_ERROR,
  GET_TASK,
  SET_DIAGRAM_VALUES,
  EXECUTING_ACTION,
  END_EXECUTING_ACTION,
  EXECUTING_ACTION_ERROR,
  LOADING_WORKFLOW_AUDIT_TRAILS,
  END_LOADING_WORKFLOW_AUDIT_TRAILS,
  WORKFLOW_AUDIT_TRAILS_ERROR,
  GET_WORKFLOW_AUDIT_TRAILS,
  LOADING_TASK_AUDIT_TRAILS,
  END_LOADING_TASK_AUDIT_TRAILS,
  ERROR_LOADING_TASK_AUDIT_TRAILS,
  GET_TASK_AUDIT_TRAILS
} from "../constants/ActionTypes";
import { goThroughList } from "./List";
import { updateEntity, STANDALONE_WORKFLOW_TYPE } from "./Entity";

export default function(entity, action) {
  if (!entity) {
    return entity;
  }

  let ownerId = action.ownerId;
  const newWorkflow = action.workflow;
  const ownerType = newWorkflow && newWorkflow.owningEntityType;
  const newTask = action.task;
  const workflowId = action.workflowId;
  const taskId = action.taskId;
  const classification = action.classification;
  let found = false;
  let result;

  if (!ownerId) {
    if (
      newWorkflow &&
      newWorkflow.owningEntity &&
      newWorkflow.owningEntity.id
    ) {
      ownerId = newWorkflow.owningEntity.id;
    }
    if (entity && newTask) {
      ownerId = newTask.owningEntity.id;
    }
  }
  if (entity.id !== ownerId && !isStandaloneWorkflow(ownerType)) {
    return entity;
  }
  switch (action.type) {
    case GET_WORKFLOW:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        newWorkflow.id,
        workflow => {
          found = true;
          return updateEntity(workflow, newWorkflow);
        }
      );
      if (!found) {
        if (!result.list) {
          result.list = [];
        }
        result.list.push(newWorkflow);
      }
      return {
        ...entity,
        workflows: result.list
      };
    case LOADING_WORKFLOW:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            loading: true,
            error: undefined
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case END_LOADING_WORKFLOW:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            loading: false
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case WORKFLOW_ERROR:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            error: action.error
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };

    case UPDATING_WORKFLOW:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            updating: true,
            update_error: undefined
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case END_UPDATING_WORKFLOW:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            updating: false
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case UPDATE_WORKFLOW_ERROR:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            update_error: action.error
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case RENAMING_WORKFLOW:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            renaming: true,
            renaming_error: undefined
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case END_RENAMING_WORKFLOW:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            renaming: false
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case RENAMING_WORKFLOW_ERROR:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            renaming_error: action.error
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case DELETE_WORKFLOW:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return null;
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case DELETING_WORKFLOW:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            deleting: true,
            error_deleting: undefined
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case END_DELETING_WORKFLOW:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            deleting: false
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case DELETE_WORKFLOW_ERROR:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            error_deleting: action.error
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };

    case GET_TASK:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        newTask.workflowId,
        workflow => {
          const tasks = workflow.tasks;
          let newTasks = [];
          for (let j = 0; tasks && j < tasks.length; j++) {
            const task = tasks[j];
            if (task && task.taskId === newTask.taskId) {
              newTask.diagram = task.diagram;
              newTask.auditTrails = task.auditTrails;
              newTasks.push(newTask);
              found = true;
            } else if (task) {
              newTasks.push(task);
            }
          }
          if (!found) {
            newTasks.push(newTask);
          }
          workflow.tasks = newTasks;
          return Object.assign({}, workflow);
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case EXECUTING_ACTION:
      return {
        ...entity,
        workflows: goThroughWorkflowTasks(
          entity.workflows,
          workflowId,
          taskId,
          task => {
            return {
              ...task,
              executing_action: action.action,
              error: undefined
            };
          }
        )
      };
    case END_EXECUTING_ACTION:
      return {
        ...entity,
        workflows: goThroughWorkflowTasks(
          entity.workflows,
          workflowId,
          taskId,
          task => {
            return {
              ...task,
              executing_action: undefined
            };
          }
        )
      };
    case EXECUTING_ACTION_ERROR:
      return {
        ...entity,
        workflows: goThroughWorkflowTasks(
          entity.workflows,
          workflowId,
          taskId,
          task => {
            return {
              ...task,
              error: action.error
            };
          }
        )
      };

    case LOADING_TASK_AUDIT_TRAILS:
      return {
        ...entity,
        workflows: goThroughWorkflowTasks(
          entity.workflows,
          workflowId,
          taskId,
          task => {
            return {
              ...task,
              loading_audit_trails: true,
              error_audit_trails: undefined
            };
          }
        )
      };
    case END_LOADING_TASK_AUDIT_TRAILS:
      return {
        ...entity,
        workflows: goThroughWorkflowTasks(
          entity.workflows,
          workflowId,
          taskId,
          task => {
            return {
              ...task,
              loading_audit_trails: false
            };
          }
        )
      };
    case ERROR_LOADING_TASK_AUDIT_TRAILS:
      return {
        ...entity,
        workflows: goThroughWorkflowTasks(
          entity.workflows,
          workflowId,
          taskId,
          task => {
            return {
              ...task,
              error_audit_trails: action.error
            };
          }
        )
      };
    case GET_TASK_AUDIT_TRAILS:
      return {
        ...entity,
        workflows: goThroughWorkflowTasks(
          entity.workflows,
          workflowId,
          taskId,
          task => {
            return {
              ...task,
              auditTrails: action.auditTrails
            };
          }
        )
      };

    case GET_WORKFLOWS:
      for (let i = 0; action.workflows && i < action.workflows.length; i++) {
        const workflowAction = action.workflows[i];
        for (
          let j = 0;
          workflowAction &&
          entity.workflows &&
          entity.workflows[classification] &&
          j < entity.workflows[classification].length;
          j++
        ) {
          const workflowState = entity.workflows[classification][j];
          if (
            workflowState &&
            workflowState.id.toString() === workflowAction.id.toString()
          ) {
            workflowAction.diagram = workflowState.diagram;
            workflowAction.auditTrails = workflowState.auditTrails;
            workflowAction.tasks = workflowState.tasks;
            workflowAction.form = workflowState.form;
            workflowAction.variables = workflowState.variables;
          }
        }
      }
      if (!entity.workflows) {
        entity.workflows = {};
      }
      entity.workflows[classification] = action.workflows;
      return {
        ...entity
      };
    case ADD_WORKFLOWS:
      if (!entity.workflows) {
        entity.workflows = {};
      }
      if (!entity.workflows[classification]) {
        entity.workflows[classification] = [];
      }
      entity.workflows[classification] = [
        ...entity.workflows[classification],
        ...action.workflows
      ];
      return {
        ...entity
      };
    case LOADING_WORKFLOWS:
      if (!entity.loading_workflows) {
        entity.loading_workflows = {};
      }
      if (!entity.error_worflows) {
        entity.error_worflows = {};
      }
      entity.loading_workflows[classification] = true;
      entity.error_worflows[classification] = undefined;
      return {
        ...entity
      };
    case END_LOADING_WORKFLOWS:
      if (!entity.loading_workflows) {
        entity.loading_workflows = {};
      }
      entity.loading_workflows[classification] = false;
      return {
        ...entity
      };
    case WORKFLOWS_ERROR:
      if (!entity.error_worflows) {
        entity.error_worflows = {};
      }
      entity.error_worflows[classification] = action.error;
      return {
        ...entity
      };

    case GET_WORKFLOW_DIAGRAM:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            diagram: action.diagram
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case LOADING_WORKFLOW_DIAGRAM:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            loading_diagram: true,
            error_diagram: undefined
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case END_LOADING_WORKFLOW_DIAGRAM:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            loading_diagram: false
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case WORKFLOW_DIAGRAM_ERROR:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            error_diagram: action.error
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };

    case GET_WORKFLOW_AUDIT_TRAILS:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            auditTrails: action.auditTrails
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case LOADING_WORKFLOW_AUDIT_TRAILS:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            loading_audit_trails: true,
            error_audit_trails: undefined
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case END_LOADING_WORKFLOW_AUDIT_TRAILS:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            loading_audit_trails: false
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
    case WORKFLOW_AUDIT_TRAILS_ERROR:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            error_audit_trails: action.error
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };

    case SET_DIAGRAM_VALUES:
      result = goThroughWorkflows(
        entity.workflows,
        null,
        workflowId,
        workflow => {
          return {
            ...workflow,
            diagramValues: action.values
          };
        }
      );
      return {
        ...entity,
        workflows: result.list
      };
  }
  return entity;
}

function goThroughWorkflowTasks(workflows, workflowId, taskId, edit) {
  let newWorkflows = {};
  const classifications = workflows && Object.keys(workflows);
  for (let k = 0; classifications && k < classifications.length; k++) {
    const classification = classifications[k];
    let workflowsClassified = workflows[classification];
    newWorkflows[classification] = [];
    for (
      let i = 0;
      workflowsClassified && i < workflowsClassified.length;
      i++
    ) {
      let workflow = workflowsClassified[i];
      if (workflow && workflow.id === workflowId) {
        const tasks = workflow.tasks;
        let newTasks = [];
        for (let j = 0; tasks && j < tasks.length; j++) {
          const task = tasks[j];
          if (task && task.taskId === taskId) {
            newTasks.push(edit(task));
          } else if (task) {
            newTasks.push(task);
          }
        }
        workflow.tasks = newTasks;
        workflow = Object.assign({}, workflow);
      }
      newWorkflows[classification].push(workflow);
    }
  }
  return newWorkflows;
}

function goThroughWorkflows(workflows, current, workflowId, edit) {
  if (!workflows) {
    return {
      list: null
    };
  }
  const classifications = Object.keys(workflows);
  let newWorkflows = [];
  for (let i = 0; classifications && i < classifications.length; i++) {
    let workflowsClassified = workflows[classifications[i]];
    const result = goThroughList(
      workflowsClassified,
      current,
      workflowId,
      edit
    );
    newWorkflows[classifications[i]] = result.list;
  }
  return {
    list: newWorkflows
  };
}

function isStandaloneWorkflow(ownerType) {
  return ownerType === STANDALONE_WORKFLOW_TYPE;
}
