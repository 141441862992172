import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  listItem: {
    paddingRight: 50,
  },
}));

const SecurityLevelItem = ({ securityLevel, onClick }) => {

  const classes = useStyles();

  const { key, label } = securityLevel;

  const handleClick = useCallback(() => {
    onClick(securityLevel);
  }, [securityLevel, onClick]);

  return (
    <ListItem
      button={true}
      onClick={handleClick}
      className={classes.listItem}
    >
      <ListItemText
        primary={label}
        secondary={
          <Typography component="span" className={classes.inline} variant="caption">
            {key}
          </Typography>
        }
      />
    </ListItem>
  );
};

SecurityLevelItem.propTypes = ({
  securityLevel: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
});

export default SecurityLevelItem;
