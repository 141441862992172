import React, {Component, useState} from "react";
import Attachments from "components/Attachments/List";
import UploadForm from "components/Attachments/UploadForm";
import {withStyles, Grid, CircularProgress, Dialog, DialogActions} from "@material-ui/core";
import TabCentered from "components/TabContainer/TabCentered";
import ManageFileConflicts from "components/Attachments/ManageFileConflicts";
import CreateFolderForm from "components/Attachments/CreateFolderForm";
import EditName from "components/Attachments/EditName";
import { areAttachmentsEqual } from "api/Attachments";
import AttachmentToolbar from "components/Attachments/Toolbar";
import SearchBar from "components/Attachments/SearchBar";
import { initializeForm } from "actions/index";
import { connect } from "react-redux";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { getEntityLabel } from 'api/Entity';
import LogsButton from "components/Attachments/Logs/ButtonLogs";
import DeleteAttachmentDialogue from "components/Attachments/DeleteAttachmentDialogue";
import {dragAttachments, dragAttachmentsEnd} from "actions/Attachments";

class Directory extends Component {
  state = {};

  componentDidMount() {
    const { droppedFiles, initializeForm } = this.props;
    const { attachments, selected } = this.settleAttachments(this.props, {});
    this.setState({
      openAddAttachment: Boolean(droppedFiles),
      attachments: attachments,
      selected: selected,
      openCreateFolder: false,
      searchName: "",
      searchType: null
    });
    initializeForm("uploadform", {
      files: droppedFiles
    });
  }
  componentWillReceiveProps(nextProps) {
    const { droppedFiles, attachments, currentSubfolder } = nextProps;
    if (
      this.props.attachments !== attachments ||
      this.props.currentSubfolder !== currentSubfolder
    ) {
      const { attachments, selected } = this.settleAttachments(
        nextProps,
        this.state
      );
      this.setState({
        attachments: attachments,
        selected: selected
      });
    }
    if (this.props.droppedFiles !== droppedFiles) {
      initializeForm("uploadform", {
        files: droppedFiles
      });
      this.setState({
        openAddAttachment: Boolean(droppedFiles)
      });
    }
  }

  refreshAttachments = () => {
    const { attachments, selected } = this.settleAttachments(
      this.props,
      this.state
    );
    this.setState({
      attachments: attachments,
      selected: selected
    });
  };

  settleAttachments = (props, state) => {
    const { currentSubfolder, attachments, withSubfolder } = props;
    const { searchName, searchType, selected } = state;
    if (!attachments) {
      return {};
    }
    let newAttachments = [],
      newSelected = [];
    for (let i = 0; attachments && i < attachments.length; i++) {
      const attachment = attachments[i];
      if (
        attachment &&
        attachment.fileName.includes(searchName) &&
        (!withSubfolder || attachment.prefix === currentSubfolder)
      ) {
        let found =
          attachment.isFolder || !searchType || searchType.length === 0;
        for (let j = 0; !found && j < searchType.length; j++) {
          const type = searchType[j].id;
          if (type === attachment.category) {
            found = true;
            break;
          }
        }
        if (!found) {
          continue;
        }
        newAttachments.push(attachment);
      }
    }
    for (let i = 0; selected && i < selected.length; i++) {
      const selectedAttachment = selected[i];
      for (let j = 0; attachments && j < attachments.length; j++) {
        const attachment = attachments[j];
        if (
          areAttachmentsEqual(attachment, selectedAttachment) &&
          (!withSubfolder || attachment.prefix === currentSubfolder)
        ) {
          newSelected.push(attachment);
          break;
        }
      }
    }
    return {
      attachments: newAttachments,
      selected: newSelected
    };
  };

  openAddAttachment = () => {
    this.setState({
      openAddAttachment: true
    });
  };
  handleCloseAddAttachment = () => {
    const { initializeForm } = this.props;
    this.setState({
      openAddAttachment: false
    });
    initializeForm("uploadform", {});
  };

  openCreateFolder = () => {
    this.setState({
      openCreateFolder: true
    });
  };
  handleCloseCreateFolder = () => {
    const { initializeForm } = this.props;
    this.setState({
      openCreateFolder: false
    });
    initializeForm("createfolderform", {});
  };
  handleSubmitFolder = form => {
    const { onCreateFolder } = this.props;
    if (onCreateFolder) {
      onCreateFolder(form);
    }
  };

  handleSubmitFiles = form => {
    const { onUpload } = this.props;
    if (onUpload) {
      onUpload(form);
    }
    this.handleCloseAddAttachment();
  };

  onChangeSearchParams = searchParams => {
    this.setState(searchParams, this.refreshAttachments);
  };

  openRenameSubDirectory = subdirectory => {
    this.setState({
      subdirectoryToRename: subdirectory
    });
  };
  handleRenameSubDirectory = form => {
    const { subdirectoryToRename } = this.state;
    const { onRenameSubfolder } = this.props;
    if (onRenameSubfolder) {
      onRenameSubfolder(subdirectoryToRename, form);
    }
    this.setState({
      subdirectoryToRename: null
    });
  };
  handleCloseRenameSubDirectory = () => {
    this.setState({
      subdirectoryToRename: null
    });
  };

  openRemoveSubDirectory = subdirectory => {
    this.setState({
      subdirectoryToRemove: subdirectory
    });
  };

  handleCloseRemoveSubDirectory = () => {
    this.setState({
      subdirectoryToRemove: null
    });
  }

  isSelected = file => {
    const { selected } = this.state;
    for (let i = 0; selected && i < selected.length; i++) {
      const selectedFile = selected[i];
      if (areAttachmentsEqual(selectedFile, file)) {
        return true;
      }
    }
    return false;
  };
  isDragged = file => {
    const { draggedFiles } = this.props;
    for (let i = 0; draggedFiles && i < draggedFiles.length; i++) {
      const draggedFile = draggedFiles[i];
      if (areAttachmentsEqual(draggedFile, file)) {
        return true;
      }
    }
    return false;
  };
  onDragStart = (event, file) => {
    const { selected } = this.state;
    const { dragAttachments } = this.props;
    const draggedFiles = this.isSelected(file) ? selected : [file];
    let div = document.createElement("div");
    div.id = "flying-files";
    if (draggedFiles.length > 1) {
      div.innerHTML = `<svg class="MuiSvgIcon-root-608" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" style="vertical-align: middle; margin-right: 10px; width: 20px;"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6z"></path><path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H8V4h12v12z"></path><path d="M10 9h8v2h-8zM10 12h4v2h-4zM10 6h8v2h-8z"></path></svg><div>${draggedFiles.length} attachments</div>`;
    } else if (file.isFolder) {
      div.innerHTML = `<svg class="MuiSvgIcon-root-608" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" style="vertical-align: middle; margin-right: 10px; width: 20px;"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M9.17 6l2 2H20v10H4V6h5.17M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"></path></svg><div>${file.fileName}</div>`;
    } else {
      div.innerHTML = `<svg class="MuiSvgIcon-root-608" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" style="vertical-align: middle; margin-right: 10px; width: 20px;"><path fill="none" d="M0 0h24v24H0V0z"></path><g><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z"></path></g></svg><div>${file.fileName}</div>`;
    }
    event.dataTransfer.effectAllowed = "copy";
    div.style.cursor = "pointer";
    document.body.appendChild(div);
    if (typeof DataTransfer.prototype.setDragImage === "function") {
      event.dataTransfer.setDragImage(div, 0, 0);
    }
    dragAttachments(draggedFiles)
  };
  onDragEnd = event => {
    const { dragAttachmentsEnd } = this.props;
    const element = document.getElementById("flying-files");
    if (element && element.parentNode) {
      element.parentNode.removeChild(element);
    }
    dragAttachmentsEnd()
  };

  onDrop = subDirectoryPath => {
    const { onMoveAttachment, draggedFiles, dragAttachmentsEnd } = this.props;
    const { openAddAttachment } = this.state;
    if (openAddAttachment) {
      return;
    }
    if (onMoveAttachment) {
      onMoveAttachment(draggedFiles, subDirectoryPath);
    }
    this.onDragEnd()
  };

  onSelectionChange = selected => {
    this.setState({ selected: selected });
  };

  render() {
    const {
      currentSubfolder,
      onSubfolderChange,
      onRenameAttachment,
      onUpdateAttachment,
      onDeleteAttachment,
      onDeleteAttachments,
      onShowHistory,
      onRenameSubfolder,
      onRemoveSubfolder,
      onMoveAttachment,
      getDownloadLink,
      getLatestDownloadLink,
      withSubfolder,
      withoutTypes,
      attachmentPlatform,
      typesAttachment,
      loading,
      authoritiesRequired,
      attachmentsLabel,
      attachmentLabel,
      droppedFiles,
      disabled,
      entityType,
      onRefreshLogs,
      logs,
      errorLogs,
      loadingLogs,
      rootPathName,
      draggedFiles
    } = this.props;
    const {
      openAddAttachment,
      attachments,
      openCreateFolder,
      searchName,
      searchType,
      subdirectoryToRename,
      subdirectoryToRemove,
      selected
    } = this.state;
    let button, list;

    const hasAttachments = attachments && attachments.length > 0;
    const tooltipTitle = disabled
      ? `This ${getEntityLabel(entityType)} is inactive`
      : null;

    const handleConfirmRemoving = () => {
      if (onRemoveSubfolder) {
        onRemoveSubfolder(subdirectoryToRemove);
      }
      this.handleCloseRemoveSubDirectory();
    };

    button = (
      <Grid item style={{ flex: "none" }}>
        <LogsButton
          onRefreshLogs={onRefreshLogs}
          loading={loadingLogs}
          error={errorLogs}
          logs={logs}
        />
        {withSubfolder && (
          <ButtonLoading
            disabled={disabled}
            tooltipTitle={tooltipTitle}
            atLeastOneAuthorization
            authoritiesRequired={authoritiesRequired}
            onClick={this.openCreateFolder}
            style={{ marginRight: 10 }}
            variant="outlined"
            color="primary"
          >
            Create a folder
          </ButtonLoading>
        )}
        <ButtonLoading
          disabled={disabled}
          tooltipTitle={tooltipTitle}
          atLeastOneAuthorization
          authoritiesRequired={authoritiesRequired}
          onClick={this.openAddAttachment}
          variant="outlined"
          color="primary"
        >
          Add {attachmentLabel}
        </ButtonLoading>
      </Grid>
    );

    if (hasAttachments) {
      list = (
        <Attachments
          attachmentsLabel={attachmentsLabel}
          attachmentLabel={attachmentLabel}
          onSubfolderChange={onSubfolderChange}
          onDrop={!disabled && onMoveAttachment ? this.onDrop : null}
          onRenameSubDirectory={
            !disabled && onRenameSubfolder ? this.openRenameSubDirectory : null
          }
          onRemoveSubDirectory = {!disabled && onRemoveSubfolder ? this.openRemoveSubDirectory : null}
          onDragStart={!disabled && onMoveAttachment ? this.onDragStart : null}
          onDragEnd={!disabled && onMoveAttachment ? this.onDragEnd : null}
          attachments={attachments}
          withSubfolder={withSubfolder}
          withoutTypes={withoutTypes}
          currentSubfolder={currentSubfolder}
          typesAttachment={typesAttachment}
          onSelectionChange={this.onSelectionChange}
          selected={selected}
          draggedFiles={draggedFiles}
          isDragged={this.isDragged}
          isSelected={this.isSelected}
          onUpdateAttachment={!disabled ? onUpdateAttachment : null}
          onRenameAttachment={!disabled ? onRenameAttachment : null}
          onDeleteAttachment={!disabled ? onDeleteAttachment : null}
          onDeleteAttachments={!disabled ? onDeleteAttachments : null}
          onShowHistory={onShowHistory}
          getDownloadLink={getDownloadLink}
          getLatestDownloadLink={getLatestDownloadLink}
        />
      );
    } else if (loading) {
      list = (
        <TabCentered>
          <div style={{ lineHeight: "36px", textAlign: "center" }}>
            <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
              Loading the {attachmentsLabel}
            </span>
          </div>
        </TabCentered>
      );
    } else {
      list = (
        <TabCentered>
          <div className="emptyMessage">No {attachmentsLabel} uploaded yet</div>
          {button}
        </TabCentered>
      );
    }

    return (
      <div className={"scrollable-container"}>
        <div className={"scrollable-header"}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: "10px" }}
            wrap="nowrap"
          >
            <SearchBar
              onChange={this.onChangeSearchParams}
              typesAttachment={typesAttachment}
              searchParams={{
                searchName: searchName,
                searchType: searchType
              }}
              attachmentPlatform={attachmentPlatform}
            />
            {button}
          </Grid>
          <AttachmentToolbar
            rootPathName={rootPathName}
            onDrop={this.onDrop}
            currentSubfolder={currentSubfolder}
            onSubfolderChange={onSubfolderChange}
            draggedFiles={draggedFiles}
            withSubfolder={withSubfolder}
          />
        </div>
        <div className={"scrollable-content"}>
          {list}
        </div>
        <UploadForm
          withoutTypes={withoutTypes}
          initialValues={{
            files: droppedFiles
          }}
          attachmentsLabel={attachmentsLabel}
          attachmentLabel={attachmentLabel}
          handleClose={this.handleCloseAddAttachment}
          open={openAddAttachment}
          typesAttachment={typesAttachment}
          onSubmit={this.handleSubmitFiles}
        />
        <CreateFolderForm
          handleClose={this.handleCloseCreateFolder}
          open={openCreateFolder}
          onSubmit={this.handleSubmitFolder}
        />
        <ManageFileConflicts />
        <EditName
          handleClose={this.handleCloseRenameSubDirectory}
          open={subdirectoryToRename}
          attachmentToUpdate={subdirectoryToRename}
          onSubmit={this.handleRenameSubDirectory}
        />
        <DeleteAttachmentDialogue
          handleClose={this.handleCloseRemoveSubDirectory}
          handleConfirmDeleting={handleConfirmRemoving}
          attachmentToDelete={subdirectoryToRemove}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    padding: "24px 0"
  },
  uploadingContainer: {
    flex: "1 1 auto",
    padding: "0 16px",
    minWidth: 0
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    draggedFiles: state.attachments.draggedAttachments
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      initializeForm,
      dragAttachments,
      dragAttachmentsEnd,
    }
  )(Directory)
);
