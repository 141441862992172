import qs from 'querystring';
import createHistory from "history/createBrowserHistory";

export function uppercaseFirstLetter(string)
{
    return string && (string.charAt(0).toUpperCase() + string.slice(1));
}

export function getCurrentURLParams(search) {
    return qs.parse(search && search.substring(1));
}

export function setTitle(title) {
    if (!title || title === '') {
        document.title = "Model Governance Suite";
        return;
    }
    document.title = "Model Governance Suite - " + title;
}

export default createHistory();