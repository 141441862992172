import React, { Component } from 'react';

import { connect } from 'react-redux';

import { pushNotification } from 'actions/notifications';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);
    console.log('Component stack: ', errorInfo.componentStack);
    this.setState({ hasError: true });
    this.props.pushNotification(
      'error',
      'error',
      'Component error: ' + error.toString(),
    );
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h7>Something went wrong.</h7>;
    }
    const { children } = this.props;
    if (children) {
      return children;
    }
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  { pushNotification },
)(ErrorBoundary);
