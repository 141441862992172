import React, {useEffect, useState} from "react";
import {getAppVersion} from "actions/Configuration";
import Typography from "@material-ui/core/Typography";

const VersionInfoFooter = () => {
    const fetchAppVersion = async() => {
        const res = await getAppVersion();
        setAppVersion(res);
    };

    const [appVersion, setAppVersion] = useState(undefined);
    useEffect(() => {
        fetchAppVersion();
    }, []);

    return (
        <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
            {appVersion ? "MGS version " + appVersion : ""}
        </Typography>
    );

};

export default VersionInfoFooter;