import {
  PUSH_NOTIFICATION,
  NOTIFICATIONS_READ,
  NOTIFICATION_READ,
  GET_NOTIFICATIONS,
  LOADING_NOTIFICATIONS,
  END_LOADING_NOTIFICATIONS,
  ERROR_NOTIFICATIONS
} from "../constants/ActionTypes";
import GenerateUUID from "../api/GenerateUUID";
import { notificationExists } from "../api/Notification";
import moment from "moment";

export default function(state = {}, action) {
  const notifications =
    action.notifications &&
    action.notifications.sort(function(left, right) {
      return moment.utc(right.createdAt).diff(moment.utc(left.createdAt));
    });
  const isInit = action.isInit;
  let list = state.list;
  const unread = state.unread;
  if (!list) {
    list = [];
  }

  switch (action.type) {
    case GET_NOTIFICATIONS:
      let unreadCount = 0;
      let unreadNotifications = [];
      for (let i = 0; notifications && i < notifications.length; i++) {
        const notification = notifications[i];
        if (!notification.read) {
          unreadCount++;
        }
        if (!isInit && !notificationExists(notification, list)) {
          unreadNotifications.push(notification);
        }
      }
      return {
        ...state,
        list: notifications,
        last: unreadNotifications,
        unread: unreadCount
      };
    case PUSH_NOTIFICATION:
      const newNotification = {
        id: GenerateUUID(),
        message: action.message,
        action: action.action,
        actionName: action.actionName,
        snackbarDuration: action.snackbarDuration || 6000,
        link: action.link,
        icon: action.icon,
        variant: action.variant,
        createdAt: new Date()
      };
      return { ...state, last: [newNotification] };
    case NOTIFICATIONS_READ:
      return { ...state, unread: Math.max(0, unread - notifications.length) };
    case NOTIFICATION_READ:
      return { ...state, unread: Math.max(0, unread - 1) };
    case LOADING_NOTIFICATIONS:
      return { ...state, loading: true, error: undefined };
    case END_LOADING_NOTIFICATIONS:
      return { ...state, loading: false };
    case ERROR_NOTIFICATIONS:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
