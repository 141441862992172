import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../../Buttons/ButtonLoading";
import CreateWorkflowTypeDialog from "./CreateWorkflowTypeDialog";
import { ROLE_WORKFLOWTYPE_CREATE } from "../../../constants/Authorities";

class CreateWorkflowTypeButton extends Component {
  state = {
    open: false
  };

  open = () => {
    this.setState({
      open: true
    });
  };
  onClose = () => {
    const { onClose } = this.props;
    this.setState({
      open: false
    });
    if (onClose) {
      onClose();
    }
  };

  render() {
    const { open } = this.state;
    const { creating } = this.props;

    return (
      <React.Fragment>
        <ButtonLoading
          loading={creating}
          loadingTitle="Creating the workflow type"
          authoritiesRequired={[ROLE_WORKFLOWTYPE_CREATE]}
          onClick={this.open}
          variant="outlined"
          color="primary"
        >
          Create a workflow type
        </ButtonLoading>
        <CreateWorkflowTypeDialog open={open} onClose={this.onClose} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    creating: state.workflowTypes.creating
  };
};
export default connect(
  mapStateToProps,
  {}
)(CreateWorkflowTypeButton);
