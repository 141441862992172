import {
  GET_USERS,
  LOADING_USERS,
  END_LOADING_USERS,
  USERS_ERROR,
  LOADING_USER_TOKENS,
  END_LOADING_USER_TOKENS,
  ERROR_USER_TOKENS,
  GET_USER_TOKENS,
  CREATING_USER_TOKEN,
  END_CREATING_USER_TOKEN,
  ERROR_CREATING_USER_TOKEN,
  CREATE_USER_TOKEN,
  REVOKING_USER_TOKEN,
  END_REVOKING_USER_TOKEN,
  ERROR_REVOKING_USER_TOKEN,
  REVOKE_USER_TOKEN,
} from "constants/ActionTypes";
import axios from "axios/index";

export function getUsers() {
  return function (dispatch) {
    dispatch({
      type: LOADING_USERS,
    });

    axios
      .get("/api/users", {
        withCredentials: true,
      })
      .then((response) => {
        let users = response.data;
        dispatch({
          type: GET_USERS,
          users: users,
        });
        dispatch({
          type: END_LOADING_USERS,
        });
      })
      .catch((err) => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: USERS_ERROR,
          error: message,
        });
        dispatch({
          type: END_LOADING_USERS,
        });
      });
  };
}

export function getUserTokens() {
  return function (dispatch) {
    dispatch({
      type: LOADING_USER_TOKENS,
    });

    axios
      .get("/api/access-tokens/own", {
        withCredentials: true,
      })
      .then((response) => {
        let tokens = response.data;
        dispatch({
          type: GET_USER_TOKENS,
          tokens: tokens,
        });
        dispatch({
          type: END_LOADING_USER_TOKENS,
        });
      })
      .catch((err) => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: ERROR_USER_TOKENS,
          error: message,
        });
        dispatch({
          type: END_LOADING_USER_TOKENS,
        });
      });
  };
}
export function createToken(name, expirationDate) {
  return function (dispatch) {
    dispatch({
      type: CREATING_USER_TOKEN,
    });

    axios
      .post(
        "/api/access-tokens/own",
        {
          name: name,
          validTo: expirationDate,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        let token = response.data;
        dispatch({
          type: CREATE_USER_TOKEN,
          token: token,
        });
        dispatch({
          type: END_CREATING_USER_TOKEN,
        });
      })
      .catch((err) => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: ERROR_CREATING_USER_TOKEN,
          error: message,
        });
        dispatch({
          type: END_CREATING_USER_TOKEN,
        });
      });
  };
}
export function revokeToken(token) {
  return function (dispatch) {
    dispatch({
      type: REVOKING_USER_TOKEN,
      token: token,
    });

    axios
      .delete("/api/access-tokens/" + token + "/own", {
        withCredentials: true,
      })
      .then((response) => {
        dispatch({
          type: REVOKE_USER_TOKEN,
          token: token,
        });
        dispatch({
          type: END_REVOKING_USER_TOKEN,
          token: token,
        });
      })
      .catch((err) => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: ERROR_REVOKING_USER_TOKEN,
          token: token,
          error: message,
        });
        dispatch({
          type: END_REVOKING_USER_TOKEN,
          token: token,
        });
      });
  };
}

export function getCredentials() {
  return axios.get("/api/user-credentials", {
    withCredentials: true,
  });
}
export function getUsersCredentials(userId) {
  return axios.get("/api/user-credentials/find-by-user-id/" + userId, {
    withCredentials: true,
  });
}

export function addCredentials(userId, hostname, notes, secret, isSystemWide) {
  return axios.put(
    "/api/user-credentials",
    {
      userId: isSystemWide ? null : userId,
      hostname,
      notes,
      secret,
    },
    {
      withCredentials: true,
    }
  );
}
export function deleteCredentials(credentialsId) {
  return axios.delete("/api/user-credentials/" + credentialsId, {
    withCredentials: true,
  });
}
