import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getEntityTypeFromPath } from "../../../../../api/Entity";
import Entities from "components/Entity/Entities";

class ValidationCommitteeTypeOverview extends Component {
  render() {
    const { type, entityType } = this.props;
    if (!type) {
      return null;
    }
    return (
      <div style={{ padding: "10px 0", height: "100%" }}>
        <div style={{ margin: "0 32px", height: "100%" }}>
          <Entities
            enableCreate
            enableExportHistory
            entityType={entityType}
            filters={{
              typeIds: [type.id],
              attributeValueFilter: {
                searchType: "CONJUNCTION",
                predicates: [],
              },
              workflowVariableValueFilter: {
                searchType: "CONJUNCTION",
                predicates: [],
              },
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const entityType = getEntityTypeFromPath(location.pathname);
  return {
    type: state.entity[entityType]
      ? state.entity[entityType].entity_type
      : null,
    entityType: entityType,
  };
};

export default withRouter(
  connect(mapStateToProps, {})(ValidationCommitteeTypeOverview)
);
