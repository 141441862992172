import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { getApiError, isConflict } from 'api/Error';
import { progressConverter } from 'api/Uploads';
import { importAttachment } from 'actions/Attachments';
import { importDocument } from 'actions/Documents';
import { getUploadDetail, uploadFile } from 'actions/Uploads';
import { ATTACHMENT_PLATFORM } from 'constants/Attachments';
import { CANCELLED, DO_NOT_REWRITE, END, ERROR } from 'constants/FileUploadStatus';
import { ConflictDialog } from 'components/Uploads/Dialogs/ConflictDialog';
import { ProgressStatus } from 'components/Uploads/Statuses/ProgressStatus';

const Uploader = ({
  group,
  file,
  rewrite,
  onUploadProcessed,
  onConflictResolve,
}) => {

  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.me);
  const [progress, setProgress] = useState(0);
  const [conflictMessage, setConflictMessage] = useState(null);
  const cancel = axios.CancelToken.source();

  useEffect(() => {
    processFile(rewrite.isRewrite);
    return () => cancel.cancel();
  }, []);

  const processFile = async (rewriteDuplicate) => {
    try {
      const uploadDetail = await getUploadDetail(group, file, rewriteDuplicate, cancel);
      if (uploadDetail) {
        await uploadFile(uploadDetail, file, cancel, handleProgressChange);
        handleUploadProcessed(END, 'Downloaded.', uploadDetail);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        let message = getApiError(error);
        if (isConflict(error)) {
          handleConflict(message);
        } else {
          handleError(message);
        }
      }
    }
  };

  const handleConflict = (message) => {
    if (!rewrite.isApplicableForAll) {
      setConflictMessage(message);
    } else {
      processConflict(rewrite.isRewrite);
    }
  };

  const resolveConflict = (isRewrite, isApplicableForAll) => {
    setConflictMessage(null);
    if (isApplicableForAll) {
      onConflictResolve(isRewrite, isApplicableForAll);
    }
    processConflict(isRewrite);
  };

  const processConflict = (isRewrite) => {
    if (isRewrite) {
      processFile(isRewrite);
    } else {
      handleUploadProcessed(DO_NOT_REWRITE, 'Skipped by user');
    }
  };

  const handleProgressChange = (progressEvent) => {
    setProgress(progressConverter(progressEvent));
  };

  const handleError = (message) => {
    handleUploadProcessed(ERROR, message);
  };

  const handleUploadProcessed = (status, message, uploadDetail) => {
    if (status === END) {
      if (group.platform === ATTACHMENT_PLATFORM.DATAHUB) {
        dispatch(importAttachment(file, uploadDetail, user));
      } else {
        dispatch(importDocument(file, uploadDetail, user));
      }
    }
    onUploadProcessed(status, message);
  };

  const handleUploadCancel = () => {
    cancel.cancel();
    handleUploadProcessed(CANCELLED, 'Cancelled');
  };

  return (
    <>
      <ProgressStatus
        progress={progress}
        onCancel={handleUploadCancel}
      />
      <ConflictDialog
        isOpen={Boolean(conflictMessage)}
        title={conflictMessage}
        onResolve={resolveConflict}
      />
    </>
  );
};

export default Uploader;
