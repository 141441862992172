import {
  ATTACHMENT_IMPORTED,
  ATTACHMENT_UPDATING,
  ATTACHMENT_UPDATED,
  ATTACHMENT_UPDATED_ERROR,
  ATTACHMENT_BULK_DELETING,
  ATTACHMENT_DELETING,
  ATTACHMENT_DELETED,
  ATTACHMENT_DELETED_ERROR,
  CREATE_FOLDER_ATTACHMENT,
  GET_ATTACHMENTS,
  LOADING_ATTACHMENTS,
  END_LOADING_ATTACHMENTS,
  ATTACHMENTS_ERROR,
  DELETING_FOLDER_ATTACHMENT,
  END_DELETING_FOLDER_ATTACHMENT,
  ERROR_DELETING_FOLDER_ATTACHMENT,
  DELETE_FOLDER_ATTACHMENT,
  ATTACHMENT_RENAMING,
  ATTACHMENT_RENAMED_ERROR,
  MOVING_ATTACHMENT,
  END_MOVING_ATTACHMENT,
  ERROR_MOVING_ATTACHMENT,
  MOVE_ATTACHMENT,
  GET_ATTACHMENTS_LOGS,
  LOADING_ATTACHMENTS_LOGS,
  END_LOADING_ATTACHMENTS_LOGS,
  ATTACHMENTS_LOGS_ERROR,
  END_ATTACHMENT_UPDATING
} from 'constants/ActionTypes';
import { goThroughList } from "./List";
import { areAttachmentsEqual, isFolderTypeAttachment } from "./Attachments";
import {PERSONAL_FOLDER_TYPE, PUBLIC_FOLDER_TYPE} from "./Entity";

export default function(entity, action) {
  let ownerId = action.ownerId;

  let result, listAttachments;
  let lastFolderPos = 0;

  switch (action.type) {
    case GET_ATTACHMENTS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        attachments: action.attachments
      };
    case LOADING_ATTACHMENTS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        loading_attachments: true,
        error_attachments: undefined
      };
    case END_LOADING_ATTACHMENTS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        loading_attachments: false
      };
    case ATTACHMENTS_ERROR:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        error_attachments: action.error
      };

    case CREATE_FOLDER_ATTACHMENT:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      listAttachments = entity.attachments;
      if (!listAttachments) {
        listAttachments = [];
      }
      listAttachments.unshift(action.folder);
      return {
        ...entity,
        attachments: [...listAttachments]
      };

    case ATTACHMENT_IMPORTED:
      if (!entity || (entity.id !== ownerId
          && !(entity.id === "public" && action.attachment && action.attachment.entity === PUBLIC_FOLDER_TYPE)
          && !(entity.id === "personal" && action.attachment && action.attachment.entity === PERSONAL_FOLDER_TYPE)
      )) {
        return entity;
      }
      if (!action.attachment || !action.attachment.category) {
        return entity;
      }
      listAttachments = entity.attachments;
      if (!listAttachments) {
        listAttachments = [];
      }
      for (let j = 0; listAttachments && j < listAttachments.length; j++) {
        const listAttachment = listAttachments[j];
        if (listAttachment.isFolder) {
          lastFolderPos = j + 1;
        }
        if (listAttachment.fileName === action.attachment.fileName) {
          listAttachments.splice(j, 1);
          break;
        }
      }
      if (action.attachment.isFolder) {
        listAttachments.unshift(action.attachment);
      } else {
        listAttachments.splice(lastFolderPos, 0, action.attachment);
      }
      return {
        ...entity,
        attachments: [...listAttachments]
      };
    case ATTACHMENT_UPDATING:
      if (!entity || ownerId !== entity.id || !action.oldAttachment) {
        return entity;
      }
      for (
        let i = 0;
        entity.attachments && i < entity.attachments.length;
        i++
      ) {
        if (areAttachmentsEqual(entity.attachments[i], action.oldAttachment)) {
          entity.attachments[i].updating = true;
          entity.attachments[i].error = undefined;
        }
      }
      return {
        ...entity,
        attachments: [...(entity.attachments ? entity.attachments : [])]
      };
    case END_ATTACHMENT_UPDATING:
      if (!entity || ownerId !== entity.id || !action.oldAttachment) {
        return entity;
      }
      for (
        let i = 0;
        entity.attachments && i < entity.attachments.length;
        i++
      ) {
        if (areAttachmentsEqual(entity.attachments[i], action.oldAttachment)) {
          entity.attachments[i].updating = false;
        }
      }
      return {
        ...entity,
        attachments: [...(entity.attachments ? entity.attachments : [])]
      };
    case ATTACHMENT_UPDATED_ERROR:
      if (!entity || ownerId !== entity.id || !action.oldAttachment) {
        return entity;
      }
      for (
        let i = 0;
        entity.attachments && i < entity.attachments.length;
        i++
      ) {
        if (areAttachmentsEqual(entity.attachments[i], action.oldAttachment)) {
          entity.attachments[i].updating = false;
          entity.attachments[i].error = action.error;
        }
      }
      return {
        ...entity,
        attachments: [...(entity.attachments ? entity.attachments : [])]
      };
    case ATTACHMENT_UPDATED:
      if (!entity || entity.id !== ownerId) {
        return entity;
      }
      if (!action.newAttachment) {
        return { ...entity };
      }
      listAttachments = entity.attachments;
      if (!listAttachments) {
        listAttachments = [];
      }
      // remove old attachment from array of attachments of old category
      for (let j = 0; listAttachments && j < listAttachments.length; j++) {
        const listAttachment = listAttachments[j];
        if (listAttachment.isFolder) {
          lastFolderPos = j + 1;
        }
        if (areAttachmentsEqual(listAttachment, action.oldAttachment)) {
          listAttachments.splice(j, 1);
          break;
        }
      }
      listAttachments = listAttachments.filter(
        listAttachment =>
          !areAttachmentsEqual(listAttachment, action.newAttachment)
      );
      listAttachments.splice(lastFolderPos, 0, action.newAttachment);

      return {
        ...entity,
        attachments: [...listAttachments]
      };
    case ATTACHMENT_RENAMING:
      if (!entity || ownerId !== entity.id || !action.oldAttachment) {
        return entity;
      }
      for (
        let i = 0;
        entity.attachments && i < entity.attachments.length;
        i++
      ) {
        if (areAttachmentsEqual(entity.attachments[i], action.oldAttachment)) {
          entity.attachments[i].renaming = true;
          entity.attachments[i].renaming_error = undefined;
        }
      }
      return {
        ...entity,
        attachments: [...(entity.attachments ? entity.attachments : [])]
      };
    case ATTACHMENT_RENAMED_ERROR:
      if (!entity || ownerId !== entity.id || !action.oldAttachment) {
        return entity;
      }
      for (
        let i = 0;
        entity.attachments && i < entity.attachments.length;
        i++
      ) {
        if (areAttachmentsEqual(entity.attachments[i], action.oldAttachment)) {
          entity.attachments[i].renaming = false;
          entity.attachments[i].renaming_error = action.error;
        }
      }
      return {
        ...entity,
        attachments: [...(entity.attachments ? entity.attachments : [])]
      };

    case ATTACHMENT_BULK_DELETING:
        if (!entity || ownerId !== entity.id || !action.attachments) {
          return entity;
        }
        for (
          let i = 0;
          action.attachments && i < action.attachments.length;
          i++
        ) {
          for (
            let j = 0;
            entity.attachments && j < entity.attachments.length;
            j++
          ) {
            if (areAttachmentsEqual(entity.attachments[j], action.attachments[i])) {
              entity.attachments[j].deleting = true;
              entity.attachments[j].error = undefined;
              break;
            }
          }
        }
        return {
          ...entity,
          attachments: [...(entity.attachments ? entity.attachments : [])]
        };

    case ATTACHMENT_DELETING:
      if (!entity || ownerId !== entity.id || !action.attachment) {
        return entity;
      }
      for (
        let i = 0;
        entity.attachments && i < entity.attachments.length;
        i++
      ) {
        if (areAttachmentsEqual(entity.attachments[i], action.attachment)) {
          entity.attachments[i].deleting = true;
          entity.attachments[i].error = undefined;
        }
      }
      return {
        ...entity,
        attachments: [...(entity.attachments ? entity.attachments : [])]
      };
    case ATTACHMENT_DELETED_ERROR:
      if (!entity || ownerId !== entity.id || !action.attachment) {
        return entity;
      }
      for (
        let i = 0;
        entity.attachments && i < entity.attachments.length;
        i++
      ) {
        if (areAttachmentsEqual(entity.attachments[i], action.attachment)) {
          entity.attachments[i].deleting = false;
          entity.attachments[i].deleting = action.error;
        }
      }
      return {
        ...entity,
        attachments: [...(entity.attachments ? entity.attachments : [])]
      };
    case ATTACHMENT_DELETED:
      if (!entity || entity.id !== ownerId) {
        return entity;
      }
      if (!action.attachment) {
        return { ...entity };
      }
      listAttachments = entity.attachments;
      if (!listAttachments) {
        listAttachments = [];
      }
      // remove given attachment from array of attachments of given type
      listAttachments = listAttachments.filter(
        value => !areAttachmentsEqual(value, action.attachment)
      );
      return {
        ...entity,
        attachments: listAttachments
      };

    case DELETING_FOLDER_ATTACHMENT:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      result = goThroughList(
        entity.attachments,
        null,
        action.objectName,
        folder => {
          return {
            ...folder,
            deleting: true,
            error: undefined
          };
        },
        "objectName"
      );
      return {
        ...entity,
        attachments: result.list
      };
    case END_DELETING_FOLDER_ATTACHMENT:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      result = goThroughList(
        entity.attachments,
        null,
        action.objectName,
        folder => {
          return {
            ...folder,
            deleting: false
          };
        },
        "objectName"
      );
      return {
        ...entity,
        attachments: result.list
      };
    case ERROR_DELETING_FOLDER_ATTACHMENT:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      result = goThroughList(
        entity.attachments,
        null,
        action.objectName,
        folder => {
          return {
            ...folder,
            error: action.error
          };
        },
        "objectName"
      );
      return {
        ...entity,
        attachments: result.list
      };
    case DELETE_FOLDER_ATTACHMENT:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      result = goThroughList(
        entity.attachments,
        null,
        action.objectName,
        folder => {
          return null;
        },
        "objectName"
      );
      return {
        ...entity,
        attachments: result.list
      };

    case MOVING_ATTACHMENT:
      if (!entity || ownerId !== entity.id || !action.attachment) {
        return entity;
      }
      for (
        let i = 0;
        entity.attachments && i < entity.attachments.length;
        i++
      ) {
        if (areAttachmentsEqual(entity.attachments[i], action.attachment)) {
          entity.attachments[i].moving = true;
          entity.attachments[i].error_moving = undefined;
        }
      }
      return {
        ...entity,
        attachments: [...(entity.attachments ? entity.attachments : [])]
      };
    case END_MOVING_ATTACHMENT:
      if (!entity || ownerId !== entity.id || !action.attachment) {
        return entity;
      }
      for (
        let i = 0;
        entity.attachments && i < entity.attachments.length;
        i++
      ) {
        if (areAttachmentsEqual(entity.attachments[i], action.attachment)) {
          entity.attachments[i].moving = false;
        }
      }
      return {
        ...entity,
        attachments: [...(entity.attachments ? entity.attachments : [])]
      };
    case ERROR_MOVING_ATTACHMENT:
      if (!entity || ownerId !== entity.id || !action.attachment) {
        return entity;
      }
      for (
        let i = 0;
        entity.attachments && i < entity.attachments.length;
        i++
      ) {
        if (areAttachmentsEqual(entity.attachments[i], action.attachment)) {
          entity.attachments[i].error_moving = action.error;
        }
      }
      return {
        ...entity,
        attachments: [...(entity.attachments ? entity.attachments : [])]
      };
    case MOVE_ATTACHMENT:
      if (
        !entity ||
        !action.attachment ||
        (!isFolderTypeAttachment(action.attachment) && ownerId !== entity.id)
      ) {
        return entity;
      }
      for (
        let i = 0;
        entity.attachments && i < entity.attachments.length;
        i++
      ) {
        if (areAttachmentsEqual(entity.attachments[i], action.attachment)) {
          entity.attachments[i].prefix = action.to;
          if(action.newOwnerId && entity.id !== action.newOwnerId){
            if(entity.attachments.length === 1){
              entity.attachments = [];
            } else {
              entity.attachments = entity.attachments.filter((_, index)=> i !== index);
            }
          }
        }
      }
      return {
        ...entity,
        attachments: [...(entity.attachments ? entity.attachments : [])]
      };

    case GET_ATTACHMENTS_LOGS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        attachments_logs: action.attachments_logs
      };
    case LOADING_ATTACHMENTS_LOGS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        loading_attachments_logs: true,
        error_attachments_logs: undefined
      };
    case END_LOADING_ATTACHMENTS_LOGS:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        loading_attachments_logs: false
      };
    case ATTACHMENTS_LOGS_ERROR:
      if (!entity || ownerId !== entity.id) {
        return entity;
      }
      return {
        ...entity,
        error_attachments_logs: action.error
      };
  }
  return entity;
}
