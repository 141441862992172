import moment from 'moment';
import Config from 'constants/Config';

export const date2Str = (date, withoutOn) => {
  if (date === null || typeof date === 'undefined') {
    return 'Unknown';
  }
  if (!moment(date).isValid()) {
    return date;
  }
  const mDate = moment(date);
  if (mDate.isBefore(moment().subtract(44, 'm'))) {
    return moment(date).format(
      (withoutOn ? '' : 'on ') + Config.dateTimeFormat,
    );
  }
  return moment(date).format(Config.dateTimeFormat);
};

export const nFormatter = (num, digits) => {
  if (!num) {
    return 0;
  }
  let si = [
      { value: 1e18, symbol: ' EB' },
      { value: 1e15, symbol: ' PB' },
      { value: 1e12, symbol: ' TB' },
      { value: 1e9, symbol: ' GB' },
      { value: 1e6, symbol: ' MB' },
      { value: 1e3, symbol: ' kB' },
      { value: 1, symbol: ' B' },
    ],
    rx = /\.0+$|(\.[0-9]*[1-9])0+$/,
    i;
  for (i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
      );
    }
  }
  return num.toFixed(digits).replace(rx, '$1');
};

export const createUpdateOnLabel = (updatedOn, updaterLabel) => {
  return updatedOn
    ? "Updated " + date2Str(updatedOn, true) + (updaterLabel ? " by " + updaterLabel : "")
    : null;
}

export const downloadFile = (href) => {
  href ? window.open(href, "_blank") : null;
};
