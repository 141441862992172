import React, { Component } from "react";
import TabCentered from "../TabContainer/TabCentered";
import moment from "moment";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
  CircularProgress,
  TablePagination,
  Grid
} from "@material-ui/core";
import APPCONFIG from "../../constants/Config";

class LogsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: APPCONFIG.LIST_SIZE
    };
  }
  refreshTimeout = null;

  componentDidMount() {
    this.refreshTab(this.props);
  }
  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { rowsPerPage, page } = this.state;
    if (rowsPerPage !== nextState.rowsPerPage) {
      return this.refresh(nextProps, nextState);
    }
    if (page !== nextState.page) {
      return this.refresh(nextProps, nextState);
    }
  }

  refreshTabTimer = () => {
    this.refreshTab(this.props);
  };

  refreshTab = props => {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTabTimer();
    }, 30000);
    this.refresh(props, this.state);
  };

  refresh = (props, state) => {
    const { onRefresh } = props;
    const { rowsPerPage, page } = state;
    onRefresh(page * rowsPerPage, rowsPerPage);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  renderLog = (log, idx) => {
    const { classes } = this.props;
    const { message, loggedOn } = log;
    return (
      <ListItem key={idx} className={classes.listItem}>
        <ListItemText
          primary={<React.Fragment>{message}</React.Fragment>}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                className={classes.inline}
                variant="caption"
              >
                {loggedOn ? moment(loggedOn).format(APPCONFIG.dateTimeFormat) : ""}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    );
  };

  render() {
    const { logs, classes, loading } = this.props;
    const { page, rowsPerPage } = this.state;

    if (!logs || !logs.result || !logs.result.map || logs.result.length === 0) {
      if (loading) {
        return (
          <TabCentered>
            <div
              style={{
                width: "400px",
                lineHeight: "36px",
                textAlign: "center",
                margin: "20px 10px 0"
              }}
            >
              <CircularProgress size={20} style={{ verticalAlign: "middle" }} />
              <span style={{ verticalAlign: "middle", marginLeft: "10px" }}>
                Loading the logs
              </span>
            </div>
          </TabCentered>
        );
      }
      return (
        <TabCentered style={{ minHeight: 100 }}>
          <div>No log found</div>
        </TabCentered>
      );
    }

    const pagination = (
      <TablePagination
        style={{ margin: "auto" }}
        component="div"
        count={logs.totalResultCount}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page"
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page"
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );

    return (
      <Grid container justify="center" className={classes.root}>
        <List>{logs.result.map(this.renderLog)}</List>
        {pagination}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  listItem: {
    paddingRight: 50
  }
});

export default withStyles(styles)(LogsList);
