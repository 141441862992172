import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../../Buttons/Button";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import DialogActions from "@material-ui/core/DialogActions/index";
import Dialog from "@material-ui/core/Dialog/index";
import IconButtonLoading from "../../Buttons/IconButtonLoading";
import { ROLE_WORKFLOWTYPE_DELETE } from "../../../constants/Authorities";
import { deleteWorkflowType } from "actions/Workflow/types";
import { Close, Delete } from "@material-ui/icons/index";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";

class DeleteWorkflowTypeButton extends Component {
  state = {
    open: false
  };
  open = () => {
    this.setState({
      open: true
    });
  };
  onClose = () => {
    this.setState({
      open: false
    });
  };
  onDelete = () => {
    const { deleteWorkflow, type } = this.props;
    deleteWorkflow(type.id);
    this.onClose();
  };

  render() {
    const { type } = this.props;
    const { open } = this.state;

    return (
      <div>
        <div className="error" style={{ display: "inline-block" }}>
          {type.error}
        </div>
        <IconButtonLoading
          loading={type.deleting}
          loadingTitle="Removing the workflow type"
          tooltipTitle="Remove the workflow type"
          authoritiesRequired={[ROLE_WORKFLOWTYPE_DELETE]}
          onClick={this.open}
          aria-label="Remove"
          color="primary"
        >
          <Delete />
        </IconButtonLoading>
        <Dialog onClose={this.onClose} open={open}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={this.onClose}
          >
            This workflow type will be deleted, do you want to continue?
          </CloseableDialogTitle>
          <DialogActions>
            <Button onClick={this.onClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.onDelete} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
export default connect(
  mapStateToProps,
  { deleteWorkflow: deleteWorkflowType }
)(DeleteWorkflowTypeButton);
