import {
  GET_FEATURES,
  LOADING_FEATURES,
  END_LOADING_FEATURES,
  FEATURES_ERROR
} from "../../constants/ActionTypes";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_FEATURES:
      return {
        ...state,
        list: action.features,
        error: undefined
      };
    case LOADING_FEATURES:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case END_LOADING_FEATURES:
      return { ...state, loading: false };
    case FEATURES_ERROR:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
