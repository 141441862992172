import React from 'react';
import AutoSuggestSelectField from 'components/Form/Fields/AutoSuggestSelectField';
import {TASK_STATUS_OPTIONS} from 'constants/Task';

const TaskFilterBar = ({ filterParams: { taskStatusToFilter }, onChange }) => {

  return (
      <AutoSuggestSelectField
        options={TASK_STATUS_OPTIONS}
        placeholder="Filter by status"
        multiple
        meta={{}}
        onChangeValue={(type) => onChange({searchType: type})}
        input={{
          value: taskStatusToFilter,
          name: 'type'
        }}
      />
  );
};

export default TaskFilterBar;
