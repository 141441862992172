'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var defaultTheme = exports.defaultTheme = {
    container: 'react-autosuggest__container',
    containerOpen: 'react-autosuggest__container--open',
    input: 'react-autosuggest__input',
    inputOpen: 'react-autosuggest__input--open',
    inputFocused: 'react-autosuggest__input--focused',
    suggestionsContainer: 'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList: 'react-autosuggest__suggestions-list',
    suggestion: 'react-autosuggest__suggestion',
    suggestionFirst: 'react-autosuggest__suggestion--first',
    suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
    sectionContainer: 'react-autosuggest__section-container',
    sectionContainerFirst: 'react-autosuggest__section-container--first',
    sectionTitle: 'react-autosuggest__section-title'
};

var mapToAutowhateverTheme = exports.mapToAutowhateverTheme = function mapToAutowhateverTheme(theme) {
    var result = {};

    for (var key in theme) {
        switch (key) {
            case 'suggestionsContainer':
                result['itemsContainer'] = theme[key];
                break;

            case 'suggestionsContainerOpen':
                result['itemsContainerOpen'] = theme[key];
                break;

            case 'suggestion':
                result['item'] = theme[key];
                break;

            case 'suggestionFirst':
                result['itemFirst'] = theme[key];
                break;

            case 'suggestionHighlighted':
                result['itemHighlighted'] = theme[key];
                break;

            case 'suggestionsList':
                result['itemsList'] = theme[key];
                break;

            default:
                result[key] = theme[key];
        }
    }

    return result;
};