import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ValidationCard from "./Validation/card";
import AssignValidationDialog from "./Validation/AssignValidationDialog";
import UnassignValidationConfirmation from "./Validation/UnassignValidationConfirmation";
import Button from "../Buttons/Button";
import TabCentered from "../TabContainer/TabCentered";
import SelectField from "../Form/Fields/SelectField";
import RevisionMenuItem from "../Model/RevisionMenuItem";
import ChooseModelRevisionDialogForm from "../Model/ChooseModelRevisionDialogForm";
import { Field } from "redux-form";
import { RequiredValidator } from "../Form/Fields/FieldValidators";
import {
  VALIDATION_CREATE_OVERVIEW_PATH,
  VALIDATION_OVERVIEW_PATH,
  ID_REGEX,
  TAB_ATTRIBUTES
} from "../../constants/Routes";
import { getSorting } from "../../api/Sort";
import {
  ROLE_VALIDATION_CREATE,
  ROLE_VALIDATION_VIEW,
  ROLE_VALIDATION_MANAGE_ASSIGNMENT
} from "../../constants/Authorities";
import { isAuthorized } from "../../api/Authorities";
import {
  getEntityType,
  MODEL_TYPE,
  MODEL_REVISION_TYPE,
  VALIDATION_COMMITTEE_TYPE,
  VALIDATION_TYPE,
  getEntityHref
} from "../../api/Entity";
import {
  unassignModel,
  assignModelRevisions,
  setCommittee
} from "../../actions/Validations";
import CreateEntityDialogForm from "components/Entity/CreateEntityDialogForm";

import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  Select
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import qs from "querystring";

function includes(inputs, value) {
  return inputs.some(function(v, idx) {
    if (idx > 0 && (!v || v === "")) {
      return false;
    }
    return value.indexOf(v) >= 0;
  });
}

class Validations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      orderBy: "",
      validations: props.validations,
      openDialogCreateValidation: false,
      openDialogAssignValidation: false,
      openDialogChooseRevision: false,
      openDialogConfirmUnassignValidation: false,
      selected: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.validations !== this.props.validations) {
      this.setState({
        validations: nextProps.validations
      });
    }
  }

  handleChangeOrderBy = event => {
    const { text } = this.state;
    const orderBy = event.target.value;
    this.setState({
      orderBy: orderBy,
      validations: this.filter(text, orderBy)
    });
  };
  handleSearch = event => {
    const { orderBy } = this.state;
    const text = event.target.value;
    this.setState({
      text: text,
      validations: this.filter(text, orderBy)
    });
  };
  filter = (text, orderBy) => {
    const { validations } = this.props;
    let newValidations = [];
    if (text && text !== "") {
      const valuesUpperCase = [text.toUpperCase()];

      for (let i = 0; i < validations.length; i++) {
        const validation = validations[i];
        if (
          validation.status &&
          includes(valuesUpperCase, validation.status.toUpperCase())
        ) {
          newValidations.push(validation);
          continue;
        }
        if (
          validation.label &&
          includes(valuesUpperCase, validation.label.toUpperCase())
        ) {
          newValidations.push(validation);
          continue;
        }
        if (
          validation.committee &&
          validation.committee.label &&
          includes(valuesUpperCase, validation.committee.label.toUpperCase())
        ) {
          newValidations.push(validation);
          continue;
        }
        if (validation.committee && validation.committee.participations) {
          const participations = validation.committee.participations;
          let pushed = false;
          for (let j = 0; j < participations.length; j++) {
            const participant = participations[j];
            if (
              participant.label &&
              includes(valuesUpperCase, participant.label.toUpperCase())
            ) {
              newValidations.push(validation);
              pushed = true;
              break;
            }
          }
          if (pushed) {
            continue;
          }
        }
        if (validation.modelRevisions) {
          const modelRevisions = validation.modelRevisions;
          let pushed = false;
          for (let j = 0; j < modelRevisions.length; j++) {
            const modelRevision = modelRevisions[j];
            if (
              modelRevision.model &&
              modelRevision.model.label &&
              includes(valuesUpperCase, modelRevision.model.label.toUpperCase())
            ) {
              newValidations.push(validation);
              pushed = true;
              break;
            }
          }
          if (pushed) {
            continue;
          }
        }
      }
    } else {
      newValidations = validations;
    }
    if (orderBy && orderBy !== "") {
      newValidations = newValidations.sort(getSorting("asc", orderBy));
    }
    return newValidations;
  };

  onAssignValidation = () => {
    const { entity } = this.props;
    const entityType = getEntityType(entity);
    if (entityType === MODEL_TYPE) {
      this.setState({
        openDialogChooseRevision: true
      });
    } else {
      this.setState({
        openDialogAssignValidation: true,
        selected: entity.id
      });
    }
  };
  handleAssignValidation = validation => {
    const { assignModelRevisions, setCommittee, entity } = this.props;
    const { selected } = this.state;
    const entityType = getEntityType(entity);
    if (entityType === VALIDATION_COMMITTEE_TYPE) {
      setCommittee(validation, entity.id);
    } else {
      assignModelRevisions(validation.id, [selected]);
    }
    this.handleCloseAssignValidationDialog();
  };
  handleCloseAssignValidationDialog = () => {
    this.setState({
      openDialogAssignValidation: false
    });
  };

  unassignValidation = validation => {
    this.setState({
      openDialogConfirmUnassignValidation: true,
      validationToUnassign: validation
    });
  };
  handleCloseConfirmUnassignValidation = () => {
    this.setState({
      openDialogConfirmUnassignValidation: false,
      validationToUnassign: null
    });
  };
  handleUnassignValidation = () => {
    const { unassignModel, setCommittee, entity } = this.props;
    const { validationToUnassign } = this.state;
    const entityType = getEntityType(entity);
    if (entityType === MODEL_TYPE) {
      for (
        let i = 0;
        validationToUnassign.modelRevisions &&
        i < validationToUnassign.modelRevisions.length;
        i++
      ) {
        const revisionValidation = validationToUnassign.modelRevisions[i];
        for (let j = 0; entity.revisions && j < entity.revisions.length; j++) {
          const revision = entity.revisions[j];
          if (revisionValidation.id.toString() === revision.id.toString()) {
            unassignModel(validationToUnassign.id, revision.id);
          }
        }
      }
    } else if (entityType === MODEL_REVISION_TYPE) {
      unassignModel(validationToUnassign.id, entity.id);
    } else if (entityType === VALIDATION_COMMITTEE_TYPE) {
      setCommittee(validationToUnassign, "none");
    }
    this.handleCloseConfirmUnassignValidation();
  };

  showMore = validation => {
    const { history } = this.props;
    history.push(getEntityHref(validation));
  };

  onCreateValidation = () => {
    this.setState({
      openDialogCreateValidation: true
    });
  };
  handleSubmitCreateValidationDialogForm = validation => {
    const { history, entity } = this.props;
    const entityType = getEntityType(entity);
    const new_location = VALIDATION_CREATE_OVERVIEW_PATH.replace(
      ":securityLevel" + ID_REGEX,
      validation.securityLevel || "undefined"
    ).replace(":entityType" + ID_REGEX, validation.entityTypeId);
    let state;
    if (entityType === VALIDATION_COMMITTEE_TYPE) {
      state = {
        validationCommittee: entity
      };
    } else if (entityType === MODEL_REVISION_TYPE) {
      state = {
        modelRevisions: [
          {
            id: entity.id
          }
        ]
      };
    } else if (entityType === MODEL_TYPE) {
      state = {
        modelRevisions: [
          {
            id: validation.revisionId
          }
        ]
      };
    }
    history.push({
      pathname: new_location,
      search: "?" + qs.stringify({ tab: TAB_ATTRIBUTES }),
      state: state
    });
    this.setState({
      openDialogCreateValidation: false
    });
  };
  handleCloseCreateValidationDialogForm = () => {
    this.setState({
      openDialogCreateValidation: false
    });
  };

  handleSubmitChooseRevisionDialogForm = form => {
    const revisionId = form.revisionId;
    if (revisionId) {
      this.setState({
        selected: form.revisionId,
        openDialogChooseRevision: false,
        openDialogAssignValidation: true
      });
    } else {
      this.setState({
        selected: null,
        openDialogChooseRevision: false
      });
    }
  };
  handleCloseChooseRevisionDialogForm = () => {
    this.setState({
      openDialogChooseRevision: false
    });
  };

  render() {
    const {
      classes,
      onCreateValidation,
      onAssignValidation,
      onCloseValidation,
      me,
      entity,
      searchedValidations
    } = this.props;
    const validationsProps = this.props.validations;
    const {
      validations,
      text,
      orderBy,
      openDialogCreateValidation,
      openDialogAssignValidation,
      openDialogConfirmUnassignValidation,
      openDialogChooseRevision
    } = this.state;
    let list,
      empty,
      searchForm,
      excludeIds,
      revisionsField,
      assignNewValidationLabel,
      assignExistingValidationLabel;
    const entityType = getEntityType(entity);

    if (entityType === VALIDATION_COMMITTEE_TYPE) {
      assignNewValidationLabel = "Assign a new validation";
      assignExistingValidationLabel = "Assign an existing validation";
      excludeIds = searchedValidations && searchedValidations.filter(validation => validation.validationCommittee !== null).map(validation => validation.id)
    } else {
      assignNewValidationLabel = "Assign to a new validation";
      assignExistingValidationLabel = "Assign to an existing validation";
      excludeIds = validations && validations.map(validation => validation.id)
    }
    const buttons = (
      <React.Fragment>
        <Grid item>
          <Button
            testid="assignNewValidation"
            authoritiesRequired={[
              ROLE_VALIDATION_CREATE,
              ROLE_VALIDATION_MANAGE_ASSIGNMENT
            ]}
            onClick={onCreateValidation || this.onCreateValidation}
            variant="outlined"
            color="primary"
            className={classes.emptyAction}
          >
            {assignNewValidationLabel}
          </Button>
        </Grid>
        <Grid item>
          <Button
            testid="assignExistingValidation"
            authoritiesRequired={[ROLE_VALIDATION_MANAGE_ASSIGNMENT]}
            onClick={onAssignValidation || this.onAssignValidation}
            variant="outlined"
            color="primary"
            className={classes.emptyAction}
          >
            {assignExistingValidationLabel}
          </Button>
        </Grid>
      </React.Fragment>
    );
    searchForm = (
      <div className={classes.inputsContainer}>
        <TextField
          id="search"
          value={text}
          onChange={this.handleSearch}
          label="Filter by name, committee name, status, participant, model name..."
          type="search"
          className={classes.input}
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="orderBy">Order by</InputLabel>
          <Select
            value={orderBy}
            onChange={this.handleChangeOrderBy}
            inputProps={{
              name: "orderBy",
              id: "orderBy"
            }}
          >
            <MenuItem value={"label"}>Name</MenuItem>
            <MenuItem value={"date"}>Date</MenuItem>
          </Select>
        </FormControl>
      </div>
    );

    if (!validationsProps || validationsProps.length <= 0) {
      empty = "Not assigned to any validations yet";
    } else if (!validations || validations.length <= 0) {
      empty = "No result found with those filters";
    }
    if (empty) {
      list = (
        <TabCentered>
          <div className="emptyMessage">{empty}</div>
          <div className={classes.emptyActionsContainer}>{buttons}</div>
        </TabCentered>
      );
    } else {
      list = (
        <Grid
          container
          spacing={2}
          className={classes.validationsContainer}
          testid="linkedValidations"
        >
          {validations.map((validation, idx) => {
            let onClose;
            if (onCloseValidation) {
              onClose = () => {
                onCloseValidation(validation);
              };
            } else if (isAuthorized(me, [ROLE_VALIDATION_MANAGE_ASSIGNMENT])) {
              onClose = () => {
                this.unassignValidation(validation);
              };
            }
            return (
              <Grid item xs={6} md={6} xl={3} lg={4} key={idx}>
                <ValidationCard
                  actionAuthoritiesRequired={[ROLE_VALIDATION_VIEW]}
                  validation={validation}
                  actionName={"Show more"}
                  onClose={onClose}
                  onAction={() => {
                    this.showMore(validation);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    }

    if (entityType === MODEL_TYPE) {
      revisionsField = (
        <div>
          <Field
            label={"Model revision to assign"}
            name="revisionId"
            component={SelectField}
            validate={[RequiredValidator]}
            ref="revisionId"
          >
            {entity.revisions.map(function(revision, idx) {
              return (
                <MenuItem key={idx} value={revision.id}>
                  <RevisionMenuItem revision={revision} />
                </MenuItem>
              );
            })}
          </Field>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Grid container justify="flex-end" style={{ padding: "10px" }}>
          {!empty && buttons}
        </Grid>
        {!empty && searchForm}

        {list}

        <CreateEntityDialogForm
          entityType={VALIDATION_TYPE}
          open={openDialogCreateValidation}
          handleClose={this.handleCloseCreateValidationDialogForm}
          onSubmit={this.handleSubmitCreateValidationDialogForm}
        >
          {revisionsField}
        </CreateEntityDialogForm>
        <AssignValidationDialog
          open={openDialogAssignValidation}
          excludeIds={excludeIds}
          onAssign={this.handleAssignValidation}
          handleClose={this.handleCloseAssignValidationDialog}
        />
        <UnassignValidationConfirmation
          open={openDialogConfirmUnassignValidation}
          handleClose={this.handleCloseConfirmUnassignValidation}
          onUnassignValidation={this.handleUnassignValidation}
        />
        {entityType === MODEL_TYPE && (
          <ChooseModelRevisionDialogForm
            model={entity}
            open={openDialogChooseRevision}
            handleClose={this.handleCloseChooseRevisionDialogForm}
            onSubmit={this.handleSubmitChooseRevisionDialogForm}
          />
        )}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  root: {},
  input: {
    width: 500,
    marginRight: 2 * theme.spacing(1),
    marginTop: -16
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "14px 24px",
    background: "white",
    borderBottom: "solid 1px #eee"
  },
  formControl: {
    width: 200,
    marginTop: -16
  },
  validationsContainer: {
    overflow: "auto",
    padding: 24,
    background: "#fafafa"
  },
  emptyActionsContainer: {
    display: "flex",
    flexDirection: "row"
  },
  emptyAction: {
    margin: "0 5px"
  }
});

const mapStateToProps = (state, ownProps) => {
  let onActionValidation = ownProps.onActionValidation;
  if (!onActionValidation) {
    onActionValidation = validation => {
      const { history } = ownProps;
      const new_location =
        VALIDATION_OVERVIEW_PATH.replace(
          ":entityId" + ID_REGEX,
          validation.id
        ).replace(":entityType" + ID_REGEX, validation.validationType.id) +
        "?tab=attributes";
      history.push(new_location);
    };
  }

  return {
    actionAuthoritiesRequired: ownProps.actionAuthoritiesRequired || [
      ROLE_VALIDATION_VIEW
    ],
    actionName: ownProps.actionName || "Show more",
    onActionValidation: onActionValidation,
    searchedValidations: state.filters && state.filters.results && state.filters.results[VALIDATION_TYPE],
    me: state.auth.me
  };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      { unassignModel, assignModelRevisions, setCommittee }
    )(Validations)
  )
);
