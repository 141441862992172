import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Button from "../Buttons/Button";
import IconButton from "../Buttons/IconButton";
import { readNotification } from "actions/notifications";
import { NOTIFCATION_COLOR } from "../../constants/Notifications";
import { Close } from "@material-ui/icons";
import { getIconComponent } from "../../api/Notification";

import { Snackbar, SnackbarContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  close: {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

function MySnackbarContent(props) {
  const { classes, notification, onClose, onAction, ...other } = props;

  let secondaryAction;
  if (notification.action) {
    secondaryAction = (
      <Button key="undo" color="secondary" size="small" onClick={onAction}>
        {notification.actionName}
      </Button>
    );
  }

  const IconComponent = getIconComponent(notification.icon);

  return (
    <SnackbarContent
      style={{ backgroundColor: NOTIFCATION_COLOR[notification.variant] }}
      message={
        <span className={classes.message}>
          {IconComponent && (
            <IconComponent
              className={classNames(classes.icon, classes.iconVariant)}
            />
          )}
          {notification.message}
        </span>
      }
      action={[
        secondaryAction,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ]}
      {...other}
    />
  );
}
const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

class SnackBar extends React.Component {
  state = {
    open: true
  };

  componentWillReceiveProps(nextProps) {
    const { notification } = nextProps;
    if (
      nextProps.notification &&
      (!this.props.notification ||
        notification.id !== this.props.notification.id)
    ) {
      if (this.state.open) {
        this.setState({ open: false });
        setTimeout(() => {
          this.setState({ open: true });
        }, 100);
      } else {
        this.setState({ open: true });
      }
    }
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  handleCloseButton = (event, reason) => {
    const { onClose, notification } = this.props;
    if (reason === "clickaway") {
      return;
    }
    onClose(notification, true);
  };

  componentDidUpdate(prevProps, prevState) {
    const { onClose, notification } = this.props;
    const { open } = this.state;
    if (prevState.open && !open) {
      setTimeout(() => {
        onClose(notification, false);
      }, 500);
    }
  }

  action = () => {
    const { notification, readNotification } = this.props;
    if (notification.action) {
      notification.action();
    }
    readNotification(notification);
    this.setState({ open: false });
    this.props.onClose();
  };

  render() {
    const { notification, index } = this.props;
    const { open } = this.state;
    if (!notification) {
      return null;
    }
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        style={{ bottom: 70 * index + 24 }}
        open={open}
        autoHideDuration={notification.snackbarDuration || 6000}
        onClose={this.handleClose}
      >
        <MySnackbarContentWrapper
          notification={notification}
          onAction={this.action}
          onClose={this.handleCloseButton}
        />
      </Snackbar>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { readNotification }
  )(SnackBar)
);
