import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import AttributesComponent from "./index";
import AttributesForm from "./form";
import {
  editMode,
  save,
  endEditMode,
  endCreationMode
} from "../../actions/Entity";
import ButtonLoading from "../Buttons/ButtonLoading";
import Grid from "../Grid";
import { withErrorBoundary } from "../../routes";
import {
  getEntityLabel,
  MODEL_REVISION_TYPE,
  MODEL_USAGE_TYPE
} from "../../api/Entity";
import { ExpandMore } from "@material-ui/icons";
import { getEntity } from "actions/Entity";

import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";

class Attributes extends Component {
  state = {
    expanded: true,
    revisionExpanded: true,
    panelsExpanded: false
  };

  constructor(props) {
    super(props);
    this.attributesContainer = React.createRef(); // Create a ref object
  }

  handleSubmit = entity => {
    const {
      entityTypeId,
      save,
      match,
      entityType,
      revision,
      history
    } = this.props;
    if (entityType === MODEL_USAGE_TYPE) {
      if (!entity.modelRevision) {
        entity.modelRevision = {};
      }
      if (!entity.modelRevision.id) {
        entity.modelRevision.id = revision.id;
      }
    }

    save(entity, entityTypeId, match.params.securityLevel, history);
  };

  switchToEditMode = event => {
    event.preventDefault();
    event.stopPropagation();
    const { editMode, entityType } = this.props;
    editMode();
    if (entityType === MODEL_REVISION_TYPE) {
      this.setState({
        expanded: false
      });
    }
    if (entityType === MODEL_USAGE_TYPE) {
      this.setState({
        expanded: false,
        revisionExpanded: false
      });
    }
  };

  endEditMode = () => {
    const {
      endEditMode,
      creation_mode,
      history,
      endCreationMode,
      match,
      getEntity,
      entityType
    } = this.props;
    const isModelRevision = entityType === MODEL_REVISION_TYPE;
    const isModelUsage = entityType === MODEL_USAGE_TYPE;
    const revisionId = match.params.revisionId;
    const id = isModelUsage ? match.params.usageId : match.params.entityId;
    if (creation_mode) {
      endCreationMode();
      history.goBack();
    } else {
      endEditMode();
      if (isModelRevision) {
        getEntity(revisionId, id);
      } else {
        getEntity(id);
      }
    }
  };

  handleChange = (event, expanded) => {
    this.setState({
      expanded: expanded
    });
  };
  handleChangeRevision = (event, expanded) => {
    this.setState({
      revisionExpanded: expanded
    });
  };

  render() {
    const {
      entity,
      creation_mode,
      edition_mode,
      model,
      revision,
      saving,
      authoritiesRequired,
      entityType
    } = this.props;
    const { expanded, revisionExpanded, panelsExpanded } = this.state;

    if (!entity || !entity.attributes) {
      return null;
    }
    let editButtonTitle,
      saveButtonTitle,
      loadingTitle,
      disabled = false,
      tooltipTitle;
    const entityLabel = getEntityLabel(entityType).toLowerCase();

    if (entityType === MODEL_REVISION_TYPE) {
      editButtonTitle = "Edit the revision attributes";
      if (creation_mode) {
        saveButtonTitle = "Create the revision";
        loadingTitle = "Creating the revision";
      } else {
        saveButtonTitle = "Save the revision attributes";
        loadingTitle = "Saving the revision attributes";
      }
      if (!model || model.active === false) {
        disabled = true;
        tooltipTitle = `The model is inactive`;
      }
    } else {
      editButtonTitle = "Edit the attributes";
      if (creation_mode) {
        saveButtonTitle = `Create the ${entityLabel}`;
        loadingTitle = `Creating the ${entityLabel}`;
      } else {
        saveButtonTitle = "Save the attributes";
        loadingTitle = "Saving the attributes";
      }
      if (
        entityType === MODEL_USAGE_TYPE &&
        (!model || model.active === false)
      ) {
        disabled = true;
        tooltipTitle = `The model is inactive`;
      }
      if (entity.active === false) {
        disabled = true;
        tooltipTitle = `The ${entityLabel} is inactive`;
      }
    }
    let buttonSave, modelAttributes, revisionAttributes;
    if (edition_mode || creation_mode || saving) {
      buttonSave = (
        <React.Fragment>
          <Grid item>
            <ButtonLoading
              disabled={disabled}
              tooltipTitle={tooltipTitle}
              loading={saving}
              loadingTitle={loadingTitle}
              testid="saveEntity"
              color="primary"
              type="submit"
              variant="outlined"
              authoritiesRequired={authoritiesRequired}
            >
              {saveButtonTitle}
            </ButtonLoading>
          </Grid>
          {!saving && (
            <Grid item>
              <ButtonLoading color="primary" onClick={this.endEditMode}>
                Cancel
              </ButtonLoading>
            </Grid>
          )}
        </React.Fragment>
      );
    } else {
      buttonSave = (
        <Grid item>
          <ButtonLoading
            testid="editAttributes"
            variant="outlined"
            color="primary"
            disabled={disabled}
            tooltipTitle={tooltipTitle}
            onClick={this.switchToEditMode}
            authoritiesRequired={authoritiesRequired}
          >
            {editButtonTitle}
          </ButtonLoading>
        </Grid>
      );
    }

    if (model) {
      modelAttributes = (
        <div style={{ margin: "20px 10px" }}>
          <ExpansionPanel expanded={expanded} onChange={this.handleChange}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography variant="subtitle1">Base model attributes</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ flexDirection: "column" }}>
              <AttributesComponent attributes={model.attributes} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    }
    if (revision) {
      revisionAttributes = (
        <div style={{ margin: "20px 10px" }}>
          <ExpansionPanel
            expanded={revisionExpanded}
            onChange={this.handleChangeRevision}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography variant="subtitle1">
                Base revision attributes
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ flexDirection: "column" }}>
              <AttributesComponent
                attributes={revision.attributes}
                expanded={revisionExpanded}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    }

    return (
      <AttributesForm owner={entity} onSubmit={this.handleSubmit}>
        <div className={"scrollable-container"}>
          <div className={"scrollable-header"}>
            <Grid container justify="flex-end" style={{ padding: "10px" }}>
              {buttonSave}
            </Grid>
          </div>
          <div className={"scrollable-content"}>
            {modelAttributes}
            {revisionAttributes}
            <div style={{ margin: "20px 10px" }} ref={this.attributesContainer}>
              <AttributesComponent
                editable
                creationMode={creation_mode}
                editMode={edition_mode || creation_mode}
                attributes={entity.attributes}
                expanded={panelsExpanded}
              />
            </div>
            {entity.creationModeRevision && (
              <div style={{ margin: "20px 10px" }}>
                <AttributesComponent
                  editable
                  editMode={edition_mode || creation_mode}
                  attributes={entity.creationModeRevision.attributes}
                />
              </div>
            )}
          </div>
        </div>
      </AttributesForm>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { entityType } = ownProps;
  if (state.entity[entityType]) {
    return {
      creation_mode: state.entity[entityType].creation_mode,
      edition_mode: state.entity[entityType].edition_mode,
      saving: state.entity[entityType].saving,
      error_saving: state.entity[entityType].error_saving
    };
  }
  return {};
};
const mapDispatchToProps = (dispatch, ownProps) => {
  const { entityType } = ownProps;
  return bindActionCreators(
    {
      editMode: editMode(entityType),
      save: save(entityType),
      endEditMode: endEditMode(entityType),
      endCreationMode: endCreationMode(entityType),
      getEntity: getEntity(entityType)
    },
    dispatch
  );
};

export default withErrorBoundary(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Attributes))
);
