import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography} from "@material-ui/core/index";
import {ExpandMore} from "@material-ui/icons/index";
import React, {useEffect, useState} from "react";
import {TASK_STATUS_COMPLETED} from "constants/Task";
import {getSortingTasks} from "api/Sort";
import TasksList from "components/Tasks/TasksPanel/TasksList";

const CompletedTasksPanel = ({tasks, showEntity, withWorkflowContext}) => {
  const [completedTasks, setCompletedTasks] = useState([]);

  useEffect(()=>{
    setCompletedTasks(tasks.filter(
      task => task && task.status === TASK_STATUS_COMPLETED
    ).sort(getSortingTasks));
  },[tasks]);

  if (completedTasks && completedTasks.length === 0) {
    return  null;
  }

  return <ExpansionPanel testid="tasklistcompleted">
    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography variant="subtitle1">Completed tasks</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails style={{padding: 0}}>
      <TasksList tasks={completedTasks} showEntity={showEntity} withWorkflowContext={withWorkflowContext}/>
    </ExpansionPanelDetails>
  </ExpansionPanel>;
};

export default CompletedTasksPanel;