import React, { Component } from 'react';
import ReactDropzone from "react-dropzone";
import TabCentered from "components/TabContainer/TabCentered";

class Dropzone extends Component {
    state = {
        dropping: false
    };

    onDragEnter = () => {
        this.setState({
            dropping: true
        });
    };
    onDragLeave = () => {
        this.setState({
            dropping: false
        });
    };
    onDrop = (files) => {
        this.onDragLeave();
        const { onDrop } = this.props;
        if (onDrop) {
            onDrop(files);
        }
    };

    render() {
        const { dropping } = this.state;
        const { disabled, children, style, testid } = this.props;

        if(disabled){
            return children;
        }
        return (
            <ReactDropzone
                testid={testid}
                disableClick
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                multiple
                style={{
                    width: "100%",
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                    ...((style)? style: {})
                }}
                onDrop={this.onDrop}>
                {
                    dropping && (
                        <div
                            style={{
                                position: 'absolute',
                                zIndex: 999999,
                                top: 0, bottom: 0,
                                left: 0, right: 0,
                                padding: 50,
                                backgroundColor: 'white'
                            }}>
                            <TabCentered
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderWidth: 2,
                                    borderColor: "#333",
                                    borderStyle: "dashed",
                                    borderRadius: 5
                                }}>
                                Drop the file here and upload it to the data hub
                            </TabCentered>
                        </div>
                    )
                }
                { children }
            </ReactDropzone>
        );
    }
}
export default Dropzone;
