import React, { useState } from "react";
import { connect } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions/index";
import Dialog from "@material-ui/core/Dialog/index";
import { Field, reduxForm } from "redux-form";
import ButtonLoading from "components/Buttons/ButtonLoading";
import { withStyles, DialogContent } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import InputField from "components/Form/Fields/InputField";
import AutoSuggestSelectField from "components/Form/Fields/AutoSuggestSelectField";
import {
  NonEmptyStringValidator,
  RequiredValidator
} from "components/Form/Fields/FieldValidators";
import CheckboxField from "components/Form/Fields/CheckboxField";
import { getApiError, scrollToFirstError } from "../../api/Error";

let CreateCredentialsForm = ({
  handleSubmit,
  error_creating,
  creating,
  classes,
  onClose,
  withUserInput,
  isSystemWide,
  onChangeSystemWide
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <CloseableDialogTitle id="simple-dialog-title" handleClose={onClose}>
        Add keys
      </CloseableDialogTitle>
      <DialogContent>
        <div className={classes.field}>
          <Field
            label="Hostname"
            name="hostname"
            component={InputField}
            validate={[RequiredValidator, NonEmptyStringValidator]}
          />
        </div>
        {withUserInput && !isSystemWide ? (
          <div className={classes.field}>
            <Field
              label="User"
              name="userId"
              component={AutoSuggestSelectField}
              validate={[RequiredValidator, NonEmptyStringValidator]}
              apiUrl="/api/users/lookup"
              maxResultNumber={10}
              withEmptyOpt={true}
            />
          </div>
        ) : null}
        <div className={classes.field}>
          <Field
            label="Secret"
            name="secret"
            component={InputField}
            multiline
            validate={[RequiredValidator, NonEmptyStringValidator]}
          />
        </div>
        <div className={classes.field}>
          <Field
            label="Notes"
            name="notes"
            component={InputField}
            multiline
            validate={[]}
          />
        </div>
        {withUserInput ? (
          <div className={classes.checkboxField}>
            <Field
              label={"System-wide keys"}
              name="isSystemWide"
              component={CheckboxField}
              validate={[]}
              checked={isSystemWide}
              onChange={onChangeSystemWide}
            />
          </div>
        ) : null}
        <div className="error">{error_creating}</div>
      </DialogContent>
      <DialogActions>
        <ButtonLoading
          testid="submitcredentials"
          loading={creating}
          loadingTitle="Adding SSH keys"
          className="color-primary"
          type="submit"
          variant="outlined"
          color="primary"
        >
          {"Add keys"}
        </ButtonLoading>
      </DialogActions>
    </form>
  );
};

const styles = theme => ({
  field: {
    marginBottom: theme.spacing(2),
    width: "600px"
  },
  checkboxField: {
    marginTop: theme.spacing(3),
    width: "600px"
  }
});

CreateCredentialsForm = withStyles(styles)(
  reduxForm({
    form: "createcredentialsform",
    onSubmitFail: scrollToFirstError
  })(CreateCredentialsForm)
);

const AddCredentialsButton = ({
  me,
  onCreate,
  withUserInput,
  open,
  onClose
}) => {
  const [isSystemWide, setIsSystemWide] = useState(false);
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);

  const handleCheckSystemWide = event => {
    setIsSystemWide(event.target.checked);
  };

  const handleSubmitCredentials = async credential => {
    const { userId, hostname, notes, secret } = credential;
    setCreating(true);
    setError(null);
    try {
      await onCreate(
        userId ? userId : me.id,
        hostname,
        notes,
        secret,
        isSystemWide
      );
    } catch (e) {
      setError(getApiError(e));
    }
    setCreating(false);
  };

  return (
    <React.Fragment>
      <Dialog onClose={onClose} open={open} maxWidth={false}>
        <CreateCredentialsForm
          withUserInput={withUserInput}
          isSystemWide={isSystemWide}
          onSubmit={handleSubmitCredentials}
          onChangeSystemWide={handleCheckSystemWide}
          onClose={onClose}
          error_creating={error}
          creating={creating}
        />
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me
  };
};

export default connect(mapStateToProps)(AddCredentialsButton);
