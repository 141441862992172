import React, { Component } from "react";
import CreateEntityDialogForm from "../../Entity/CreateEntityDialogForm";
import Button from "components/Buttons/Button";
import {
  ID_REGEX,
  TAB_ATTRIBUTES,
  VALIDATION_CREATE_OVERVIEW_PATH
} from "../../../constants/Routes";
import {
  VALIDATION_COMMITTEE_TYPE,
  VALIDATION_TYPE
} from "../../../api/Entity";
import { withRouter } from "react-router";
import qs from "querystring";

class AssignValidationButton extends Component {
  state = {
    openDialogCreateValidation: false
  };

  onCreateValidation = () => {
    this.setState({
      openDialogCreateValidation: true
    });
  };
  handleSubmitCreateValidationDialogForm = validation => {
    const { history } = this.props;
    const new_location = VALIDATION_CREATE_OVERVIEW_PATH.replace(
      ":entityType" + ID_REGEX,
      validation.entityTypeId
    ).replace(
      ":securityLevel" + ID_REGEX,
      validation.securityLevel || "undefined"
    );
    history.push({
      pathname: new_location,
      search: "?" + qs.stringify({ tab: TAB_ATTRIBUTES }),
      state: { modelRevisions: this.getSelectedIds() }
    });
    this.setState({
      openDialogCreateValidation: false
    });
  };
  handleCloseCreateValidationDialogForm = () => {
    this.setState({
      openDialogCreateValidation: false
    });
  };
  getSelectedIds = () => {
    const { selectedEntities } = this.props;
    let ids = [];
    for (let i = 0; selectedEntities && i < selectedEntities.length; i++) {
      ids.push({
        id: selectedEntities[i].id
      });
    }
    return ids;
  };

  render() {
    const { openDialogCreateValidation } = this.state;
    const { entityType } = this.props;
    let assignNewValidationLabel;

    if (entityType === VALIDATION_COMMITTEE_TYPE) {
      assignNewValidationLabel = "Assign a new validation";
    } else {
      assignNewValidationLabel = "Assign to a new validation";
    }

    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          aria-label={assignNewValidationLabel}
          onClick={this.onCreateValidation}
        >
          {assignNewValidationLabel}
        </Button>
        <CreateEntityDialogForm
          entityType={VALIDATION_TYPE}
          open={openDialogCreateValidation}
          handleClose={this.handleCloseCreateValidationDialogForm}
          onSubmit={this.handleSubmitCreateValidationDialogForm}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(AssignValidationButton);
