import React, { Component } from "react";
import {
  getEntityRoleAttachmentDownload,
  getEntityRoleAttachmentUpload,
  getEntityType,
  MODEL_REVISION_TYPE,
  MODEL_TYPE
} from 'api/Entity';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import Directory from "components/Attachments/Directory";
import { isAuthorized } from 'api/Authorities';
import {
  deleteDocument,
  getDocumentsLogs,
  renameDocument,
  updateDocument,
} from "actions/Documents";
import HistoryDialog from "components/Attachments/HistoryDialog";
import { areAttachmentsEqual, getDownloadLink } from 'api/Attachments';
import { ATTACHMENT_PLATFORM } from 'constants/Attachments'
import { addFileGroup } from 'actions/Uploads';
import {getEntityRoleAttachmentDelete} from "../../api/Entity";

class EntityDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typesAttachment: this.getTypesAttachment(props.entity),
      showHistoryFile: null
    };
  }
  componentWillReceiveProps(nextProps) {
    const { entity, types } = nextProps;
    const { showHistoryDocument } = this.state;
    if (this.props.types !== types || entity !== this.props.entity) {
      this.setState({
        typesAttachment: this.getTypesAttachment(entity)
      });
    }
    if (showHistoryDocument && entity !== this.props.entity) {
      for (let j = 0; entity.documents && j < entity.documents.length; j++) {
        const document = entity.documents[j];
        if (areAttachmentsEqual(document, showHistoryDocument)) {
          this.setState({
            showHistoryDocument: document
          });
        }
      }
    }
  }

  getTypesAttachment = entity => {
    const { types } = this.props;
    let entityType = getEntityType(entity);
    entityType = entityType === MODEL_REVISION_TYPE ? MODEL_TYPE : entityType;
    if (types && types[entityType]) {
      return types[entityType].list;
    }
    return [];
  };

  handleSubmitFiles = form => {
    const { addFileGroup, entity, currentSubfolder } = this.props;
    addFileGroup(form.files, entity, form.type, currentSubfolder, ATTACHMENT_PLATFORM.DOCUMENTS);
  };

  handleUpdateAttachment = (attachmentToUpdate, form) => {
    const updatedAttachment = Object.assign({}, attachmentToUpdate);
    updatedAttachment.category = form.type;

    const { updateDocument, currentSubfolder } = this.props;
    updateDocument(attachmentToUpdate, updatedAttachment, currentSubfolder);
  };

  handleRenameAttachment = (attachmentToRename, form) => {
    const updatedAttachment = Object.assign({}, attachmentToRename);
    updatedAttachment.fileName = form.name;

    const { renameDocument, currentSubfolder } = this.props;
    renameDocument(attachmentToRename, updatedAttachment, currentSubfolder);
  };

  handleDeleteAttachment = attachmentToDelete => {
    const { deleteDocument, currentSubfolder } = this.props;
    deleteDocument(attachmentToDelete, currentSubfolder);
  };

  handleDeleteAttachments = attachments => {
    for (let i = 0; attachments && i < attachments.length; i++) {
      const attachmentToDelete = attachments[i];
      this.handleDeleteAttachment(attachmentToDelete);
    }
  };

  handleShowHistory = file => {
    this.setState({
      showHistoryDocument: file
    });
  };
  handleCloseHistory = () => {
    this.setState({
      showHistoryDocument: null
    });
  };

  onRefreshLogs = (firstResult, maxResultCount) => {
    const { getDocumentsLogs, entity } = this.props;
    getDocumentsLogs(
      entity.id,
      getEntityType(entity),
      firstResult,
      maxResultCount
    );
  };

  render() {
    const { entity, droppedFiles, me } = this.props;
    const { typesAttachment, showHistoryDocument } = this.state;

    if (!entity) {
      return null;
    }

    const authorityRequired = getEntityRoleAttachmentUpload(entity);
    const entityType = getEntityType(entity);
    const authorityRequiredUpload = getEntityRoleAttachmentUpload(entity);
    const authorityRequiredDelete = getEntityRoleAttachmentDelete(entity);
    const authorityRequiredDownload = getEntityRoleAttachmentDownload(entity);

    const disabled =
      entityType === MODEL_REVISION_TYPE
        ? !entity.model || entity.model.active === false
        : entity.active === false;
    const isAuthorizedToUpload = isAuthorized(
      me,
      authorityRequiredUpload ? [authorityRequiredUpload] : null
    );
    const isAuthorizedToDelete = isAuthorized(
      me,
      authorityRequiredDelete ? [authorityRequiredDelete] : null
    );
    const isAuthorizedToDownload = isAuthorized(
      me,
      authorityRequiredDownload ? [authorityRequiredDownload] : null
    );
    return (
      <React.Fragment>
        <Directory
          withoutTypes
          disabled={disabled}
          entityType={entityType}
          attachmentsLabel="documents"
          attachmentLabel="document"
          onCreateFolder={isAuthorizedToUpload ? this.handleSubmitFolder : null}
          onUpload={isAuthorizedToUpload ? this.handleSubmitFiles : null}
          onShowHistory={this.handleShowHistory}
          onRenameAttachment={
            isAuthorizedToUpload ? this.handleRenameAttachment : null
          }
          onDeleteAttachment={
            isAuthorizedToDelete ? this.handleDeleteAttachment : null
          }
          onDeleteAttachments={
            isAuthorizedToDelete ? this.handleDeleteAttachments : null
          }
          getDownloadLink={
            isAuthorizedToDownload ? file => getDownloadLink(file, true) : null
          }
          getLatestDownloadLink={
            isAuthorizedToDownload
              ? file => getDownloadLink(file, true, true)
              : null
          }
          typesAttachment={typesAttachment}
          droppedFiles={droppedFiles}
          authoritiesRequired={authorityRequired && [authorityRequired]}
          loading={entity.loading_documents}
          attachments={entity.documents}
          onRefreshLogs={this.onRefreshLogs}
          loadingLogs={entity && entity.loading_documents_logs}
          errorLogs={entity && entity.error_documents_logs}
          logs={entity && entity.documents_logs}
          attachmentPlatform={ATTACHMENT_PLATFORM.DOCUMENTS}
        />
        <HistoryDialog
          typesAttachment={typesAttachment}
          getDownloadLink={
            isAuthorizedToDownload ? file => getDownloadLink(file, true) : null
          }
          onClose={this.handleCloseHistory}
          document={showHistoryDocument}
        />
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    padding: "24px 0"
  },
  uploadingContainer: {
    flex: "1 1 auto",
    padding: "0 16px",
    minWidth: 0
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    types: state.attachments.types,
    me: state.auth.me
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      renameDocument,
      updateDocument,
      deleteDocument,
      getDocumentsLogs,
      addFileGroup
    }
  )(EntityDocuments)
);
