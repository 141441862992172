export const handleRedirect = ({
  me,
  location: { state },
  history,
  lastLocation
}) => {
  if (me && me.id) {
    //redirect to the previous location only if no users were connected before or if the previous connected user is the same as the currently connected
    //to avoid security problem (cf MGS-691)
    if (
      state &&
      state.from &&
      (!state.fromUserId || state.fromUserId === me.id)
    ) {
      history.push(state.from);
    } else if (lastLocation) {
      if (lastLocation.pathname === "/sign-up") {
        history.goBack();
      } else {
        history.push(lastLocation.pathname);
      }
    } else {
      history.push("/");
    }
  }
};
