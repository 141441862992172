import { isAuthorized } from "./Authorities";
import {
  ROLE_MODEL_ATTACHMENTDELETE,
  ROLE_MODEL_ATTACHMENTDOWNLOAD,
  ROLE_MODEL_ATTACHMENTUPLOAD,
  ROLE_MODEL_CREATE,
  ROLE_MODEL_EDIT,
  ROLE_MODEL_MONITORING_CREATE,
  ROLE_MODEL_MONITORING_EDIT,
  ROLE_MODEL_MONITORING_VIEW,
  ROLE_MODEL_VIEW,
  ROLE_MODEL_WORKFLOW_CREATE,
  ROLE_MODEL_WORKFLOW_DELETE,
  ROLE_MODEL_WORKFLOW_EDIT,
  ROLE_MODEL_WORKFLOW_VIEW,
  ROLE_MODELREVISION_CREATE,
  ROLE_MODELUSAGE_CREATE,
  ROLE_PUBLIC_FOLDER_MANAGE,
  ROLE_SHARED_FOLDER_ATTACHMENTDELETE,
  ROLE_SHARED_FOLDER_ATTACHMENTDOWNLOAD,
  ROLE_SHARED_FOLDER_ATTACHMENTUPLOAD,
  ROLE_SHARED_FOLDER_MANAGE,
  ROLE_STANDALONE_WORKFLOW_CREATE,
  ROLE_STANDALONE_WORKFLOW_DELETE,
  ROLE_STANDALONE_WORKFLOW_EDIT,
  ROLE_STANDALONE_WORKFLOW_VIEW,
  ROLE_VALIDATION_ATTACHMENTDELETE,
  ROLE_VALIDATION_ATTACHMENTDOWNLOAD,
  ROLE_VALIDATION_ATTACHMENTUPLOAD,
  ROLE_VALIDATION_CREATE,
  ROLE_VALIDATION_EDIT,
  ROLE_VALIDATION_VIEW,
  ROLE_VALIDATION_WORKFLOW_CREATE,
  ROLE_VALIDATION_WORKFLOW_DELETE,
  ROLE_VALIDATION_WORKFLOW_EDIT,
  ROLE_VALIDATION_WORKFLOW_VIEW,
  ROLE_VALIDATIONCOMMITTEE_ATTACHMENTDELETE,
  ROLE_VALIDATIONCOMMITTEE_ATTACHMENTDOWNLOAD,
  ROLE_VALIDATIONCOMMITTEE_ATTACHMENTUPLOAD,
  ROLE_VALIDATIONCOMMITTEE_CREATE,
  ROLE_VALIDATIONCOMMITTEE_EDIT,
  ROLE_VALIDATIONCOMMITTEE_VIEW,
  ROLE_VALIDATIONCOMMITTEE_WORKFLOW_CREATE,
  ROLE_VALIDATIONCOMMITTEE_WORKFLOW_DELETE,
  ROLE_VALIDATIONCOMMITTEE_WORKFLOW_EDIT,
  ROLE_VALIDATIONCOMMITTEE_WORKFLOW_VIEW,
} from "constants/Authorities";
import { matchPath } from "react-router";
import {
  APP_OVERVIEW_PATH,
  FOLDER_OVERVIEW_PATH,
  FOLDERS_OVERVIEW_PATH,
  ID_REGEX,
  MODEL_CREATE_OVERVIEW_PATH,
  MODEL_MONITORING_OVERVIEW_PATH,
  MODEL_OVERVIEW_PATH,
  MODEL_PATH,
  MODEL_REVISION_CREATE_OVERVIEW_PATH,
  MODEL_REVISION_OVERVIEW_PATH,
  MODEL_REVISION_PATH,
  MODEL_REVISION_SHORT_OVERVIEW_PATH,
  MODEL_REVISION_SHORT_PATH,
  MODEL_USAGE_CREATE_OVERVIEW_PATH,
  MODEL_USAGE_OVERVIEW_PATH,
  MODEL_USAGES_TYPE_PATH,
  MODELS_OVERVIEW_PATH,
  MODELS_SHORT_PATH,
  TAB_ATTACHMENTS,
  TAB_ATTRIBUTES,
  TAB_DOCUMENTS,
  VALIDATION_COMMITTEE_CREATE_OVERVIEW_PATH,
  VALIDATION_COMMITTEE_OVERVIEW_PATH,
  VALIDATION_COMMITTEES_OVERVIEW_PATH,
  VALIDATION_COMMITTEES_TYPE_PATH,
  VALIDATION_CREATE_OVERVIEW_PATH,
  VALIDATION_OVERVIEW_PATH,
  VALIDATIONS_OVERVIEW_PATH,
  VALIDATIONS_TYPE_PATH,
} from "constants/Routes";
import qs from "querystring";
import { WORKFLOW_AUTOMATION } from "constants/Workflows";
import {
  STORAGE_TYPE_DATA_HUB,
  STORAGE_TYPE_DOCUMENTS,
} from "components/Attachments/Search";
import { ATTACHMENT_PLATFORM } from "constants/Attachments";
import { ROLE_SHARED_FOLDER_VIEW } from "../constants/Authorities";

export const MODEL_TYPE = "MODEL";
export const MODEL_MONITORING_TYPE = "MODEL_MONITORING";
export const MODEL_REVISION_TYPE = "MODEL_REVISION";
export const MODEL_USAGE_TYPE = "MODEL_USAGE";
export const VALIDATION_TYPE = "VALIDATION";
export const VALIDATION_COMMITTEE_TYPE = "VALIDATION_COMMITTEE";
export const SHARED_FOLDER_TYPE = "SHARED_FOLDER";
export const PERSONAL_FOLDER_TYPE = "APP_USER";
export const PUBLIC_FOLDER_TYPE = "PUBLIC";
export const STANDALONE_WORKFLOW_TYPE = "STANDALONE_WORKFLOW";

const LABEL_MODEL = "Model";
const LABEL_MODEL_MONITORING = "Model monitoring";
const LABEL_VALIDATION = "Validation";
const LABEL_VALIDATION_COMMITTEE = "Validation committee";
const LABEL_MODEL_REVISION = "Model Revision";
const LABEL_MODEL_USAGE = "Model Usage";
const LABEL_SHARED_FOLDER = "Shared folder";
const LABEL_PUBLIC_FOLDER = "Public folder";
const LABEL_PERSONAL_FOLDER = "Personal folder";

export function getEntityType(entity) {
  if (!entity) {
    return null;
  }
  if (entity.model) {
    return MODEL_REVISION_TYPE;
  }
  if (entity.modelType) {
    return MODEL_TYPE;
  }
  if (entity.validationType) {
    return VALIDATION_TYPE;
  }
  if (entity.validationCommitteeType) {
    return VALIDATION_COMMITTEE_TYPE;
  }
  if (entity.modelUsageType) {
    return MODEL_USAGE_TYPE;
  }
  if (entity.isSharedFolderEntity) {
    return SHARED_FOLDER_TYPE;
  }
  if (entity.isPersonalFolderEntity) {
    return PERSONAL_FOLDER_TYPE;
  }
  if (entity.isRootSharedFolderEntity) {
    return PUBLIC_FOLDER_TYPE;
  }
  if (entity.entityEnum && entity.entityEnum === MODEL_MONITORING_TYPE) {
    return MODEL_MONITORING_TYPE;
  }
  if (!entity.id && entity.workflows && entity.workflows[WORKFLOW_AUTOMATION]) {
    return STANDALONE_WORKFLOW_TYPE;
  }
  if (entity.entityType) {
    return entity.entityType;
  }
  return null;
}

export function isEntityActive(entity) {
  const entityType = getEntityType(entity);
  if (entityType === MODEL_TYPE) {
    return entity.active ? entity.active === true : false;
  }

  return true;
}

export function getEntityLabel(entityType, isPlural) {
  switch (entityType) {
    case MODEL_TYPE:
      return appendSuffix(LABEL_MODEL, isPlural);
    case MODEL_MONITORING_TYPE:
      return appendSuffix(LABEL_MODEL_MONITORING, isPlural);
    case VALIDATION_TYPE:
      return appendSuffix(LABEL_VALIDATION, isPlural);
    case VALIDATION_COMMITTEE_TYPE:
      return appendSuffix(LABEL_VALIDATION_COMMITTEE, isPlural);
    case MODEL_REVISION_TYPE:
      return appendSuffix(LABEL_MODEL_REVISION, isPlural);
    case MODEL_USAGE_TYPE:
      return appendSuffix(LABEL_MODEL_USAGE, isPlural);
    case SHARED_FOLDER_TYPE:
      return appendSuffix(LABEL_SHARED_FOLDER, isPlural);
    case PUBLIC_FOLDER_TYPE:
      return appendSuffix(LABEL_PUBLIC_FOLDER, isPlural);
    case PERSONAL_FOLDER_TYPE:
      return appendSuffix(LABEL_PERSONAL_FOLDER, isPlural);
  }
  return "";
}

function appendSuffix(label, isPlural, suffix) {
  const suffixToAdd = suffix ? suffix : "s";
  return !isPlural ? label : label + suffixToAdd;
}

export function getEntityTypeFromPath(path) {
  if (matchPath(path, { path: MODEL_USAGES_TYPE_PATH })) {
    return MODEL_USAGE_TYPE;
  }
  if (
    matchPath(path, { path: MODEL_REVISION_PATH }) ||
    matchPath(path, { path: MODEL_REVISION_SHORT_PATH })
  ) {
    return MODEL_REVISION_TYPE;
  }
  if (
    matchPath(path, { path: MODEL_PATH }) ||
    matchPath(path, { path: MODELS_SHORT_PATH })
  ) {
    return MODEL_TYPE;
  }
  if (matchPath(path, { path: VALIDATIONS_TYPE_PATH })) {
    return VALIDATION_TYPE;
  }
  if (matchPath(path, { path: VALIDATION_COMMITTEES_TYPE_PATH })) {
    return VALIDATION_COMMITTEE_TYPE;
  }
  return null;
}
export function getEntityReducerAttribute(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return "models";
    case VALIDATION_TYPE:
      return "validations";
    case VALIDATION_COMMITTEE_TYPE:
      return "committees";
    case MODEL_REVISION_TYPE:
      return "modelRevisions";
    case MODEL_USAGE_TYPE:
      return "modelUsages";
    case MODEL_MONITORING_TYPE:
      return "modelMonitoring";
    case PUBLIC_FOLDER_TYPE:
    case SHARED_FOLDER_TYPE:
    case PERSONAL_FOLDER_TYPE:
      return "folders";
  }
  return null;
}

export function getEntityTypeObject(entity) {
  switch (getEntityType(entity)) {
    case MODEL_TYPE:
      return entity.modelType;
    case MODEL_USAGE_TYPE:
      return entity.modelUsageType;
    case VALIDATION_TYPE:
      return entity.validationType;
    case VALIDATION_COMMITTEE_TYPE:
      return entity.validationCommitteeType;
    case MODEL_REVISION_TYPE:
      if (entity.model && entity.model.modelType) {
        return entity.model.modelType;
      }
  }
  return null;
}
export function getEntityTypeId(entity) {
  const entityTypeObject = getEntityTypeObject(entity);
  if (entityTypeObject) {
    return entityTypeObject.id;
  }
  return null;
}
export function getEntityTypeName(entity) {
  const entityTypeObject = getEntityTypeObject(entity);
  if (entityTypeObject) {
    return entityTypeObject.name;
  }
  return null;
}

export function getEntitiesShortPath(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return MODELS_SHORT_PATH;
    case MODEL_REVISION_TYPE:
      return MODEL_REVISION_SHORT_OVERVIEW_PATH;
    case MODEL_USAGE_TYPE:
      return MODEL_USAGE_OVERVIEW_PATH;
    case VALIDATION_TYPE:
      return VALIDATION_OVERVIEW_PATH;
    case VALIDATION_COMMITTEE_TYPE:
      return VALIDATION_COMMITTEE_OVERVIEW_PATH;
  }
  return APP_OVERVIEW_PATH;
}

export function getEntitiesPath(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return MODELS_OVERVIEW_PATH;
    case MODEL_USAGE_TYPE:
      return MODELS_OVERVIEW_PATH;
    case VALIDATION_TYPE:
      return VALIDATIONS_OVERVIEW_PATH;
    case VALIDATION_COMMITTEE_TYPE:
      return VALIDATION_COMMITTEES_OVERVIEW_PATH;
    case MODEL_REVISION_TYPE:
      return MODELS_OVERVIEW_PATH;
    case SHARED_FOLDER_TYPE:
      return FOLDERS_OVERVIEW_PATH;
    case PUBLIC_FOLDER_TYPE:
      return FOLDER_OVERVIEW_PATH.replace(":folderId", "public");
    case PERSONAL_FOLDER_TYPE:
      return FOLDER_OVERVIEW_PATH.replace(":folderId", "personal");
    case MODEL_MONITORING_TYPE:
      return MODEL_MONITORING_OVERVIEW_PATH;
  }
  return null;
}

export function getEntityRoleView(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return ROLE_MODEL_VIEW;
    case MODEL_MONITORING_TYPE:
      return ROLE_MODEL_MONITORING_VIEW;
    case MODEL_USAGE_TYPE:
      return ROLE_MODEL_VIEW;
    case VALIDATION_TYPE:
      return ROLE_VALIDATION_VIEW;
    case VALIDATION_COMMITTEE_TYPE:
      return ROLE_VALIDATIONCOMMITTEE_VIEW;
    case MODEL_REVISION_TYPE:
      return ROLE_MODEL_VIEW;
    case SHARED_FOLDER_TYPE:
      return ROLE_SHARED_FOLDER_VIEW;
    case PUBLIC_FOLDER_TYPE:
      return ROLE_PUBLIC_FOLDER_MANAGE;
  }
  return null;
}

export function isAuthorizedToViewEntity(me, entity) {
  return isAuthorizedToViewEntityType(me, getEntityType(entity));
}

export function isAuthorizedToViewEntityType(me, entityType) {
  const authorityRequired = getEntityRoleView(getEntityType(entityType));
  return isAuthorized(me, authorityRequired ? [authorityRequired] : null);
}

export function getEntityRoleCreate(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return ROLE_MODEL_CREATE;
    case MODEL_USAGE_TYPE:
      return ROLE_MODELUSAGE_CREATE;
    case MODEL_MONITORING_TYPE:
      return ROLE_MODEL_MONITORING_CREATE;
    case VALIDATION_TYPE:
      return ROLE_VALIDATION_CREATE;
    case VALIDATION_COMMITTEE_TYPE:
      return ROLE_VALIDATIONCOMMITTEE_CREATE;
    case MODEL_REVISION_TYPE:
      return ROLE_MODELREVISION_CREATE;
  }
  return null;
}
export function getEntityRoleEdit(entity) {
  switch (getEntityType(entity)) {
    case MODEL_TYPE:
      return ROLE_MODEL_EDIT;
    case MODEL_MONITORING_TYPE:
      return ROLE_MODEL_MONITORING_EDIT;
    case VALIDATION_TYPE:
      return ROLE_VALIDATION_EDIT;
    case VALIDATION_COMMITTEE_TYPE:
      return ROLE_VALIDATIONCOMMITTEE_EDIT;
    case MODEL_REVISION_TYPE:
      return ROLE_MODEL_EDIT;
    case MODEL_USAGE_TYPE:
      return ROLE_MODEL_EDIT;
    case SHARED_FOLDER_TYPE:
      return ROLE_SHARED_FOLDER_MANAGE;
    case PUBLIC_FOLDER_TYPE:
      return ROLE_PUBLIC_FOLDER_MANAGE;
  }
  return null;
}

export function getEntityWorkflowRoleCreate(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return ROLE_MODEL_WORKFLOW_CREATE;
    case VALIDATION_TYPE:
      return ROLE_VALIDATION_WORKFLOW_CREATE;
    case VALIDATION_COMMITTEE_TYPE:
      return ROLE_VALIDATIONCOMMITTEE_WORKFLOW_CREATE;
    case MODEL_REVISION_TYPE:
      return ROLE_MODEL_WORKFLOW_CREATE;
    case MODEL_USAGE_TYPE:
      return ROLE_MODEL_WORKFLOW_CREATE;
    default:
      return ROLE_STANDALONE_WORKFLOW_CREATE;
  }
}
export function getEntityWorkflowRoleView(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return ROLE_MODEL_WORKFLOW_VIEW;
    case VALIDATION_TYPE:
      return ROLE_VALIDATION_WORKFLOW_VIEW;
    case VALIDATION_COMMITTEE_TYPE:
      return ROLE_VALIDATIONCOMMITTEE_WORKFLOW_VIEW;
    case MODEL_REVISION_TYPE:
      return ROLE_MODEL_WORKFLOW_VIEW;
    case MODEL_USAGE_TYPE:
      return ROLE_MODEL_WORKFLOW_VIEW;
    default:
      return ROLE_STANDALONE_WORKFLOW_VIEW;
  }
}
export function getEntityWorkflowRoleEdit(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return ROLE_MODEL_WORKFLOW_EDIT;
    case VALIDATION_TYPE:
      return ROLE_VALIDATION_WORKFLOW_EDIT;
    case VALIDATION_COMMITTEE_TYPE:
      return ROLE_VALIDATIONCOMMITTEE_WORKFLOW_EDIT;
    case MODEL_REVISION_TYPE:
      return ROLE_MODEL_WORKFLOW_EDIT;
    case MODEL_USAGE_TYPE:
      return ROLE_MODEL_WORKFLOW_EDIT;
    default:
      return ROLE_STANDALONE_WORKFLOW_EDIT;
  }
}
export function getEntityWorkflowRoleDelete(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return ROLE_MODEL_WORKFLOW_DELETE;
    case VALIDATION_TYPE:
      return ROLE_VALIDATION_WORKFLOW_DELETE;
    case VALIDATION_COMMITTEE_TYPE:
      return ROLE_VALIDATIONCOMMITTEE_WORKFLOW_DELETE;
    case MODEL_REVISION_TYPE:
      return ROLE_MODEL_WORKFLOW_DELETE;
    case MODEL_USAGE_TYPE:
      return ROLE_MODEL_WORKFLOW_DELETE;
    default:
      return ROLE_STANDALONE_WORKFLOW_DELETE;
  }
}

export function getEntityRoleAttachmentUpload(entity) {
  switch (getEntityType(entity)) {
    case MODEL_TYPE:
      return ROLE_MODEL_ATTACHMENTUPLOAD;
    case VALIDATION_TYPE:
      return ROLE_VALIDATION_ATTACHMENTUPLOAD;
    case VALIDATION_COMMITTEE_TYPE:
      return ROLE_VALIDATIONCOMMITTEE_ATTACHMENTUPLOAD;
    case MODEL_REVISION_TYPE:
      return ROLE_MODEL_ATTACHMENTUPLOAD;
    case MODEL_USAGE_TYPE:
      return ROLE_MODEL_ATTACHMENTUPLOAD;
    case SHARED_FOLDER_TYPE:
      return ROLE_SHARED_FOLDER_ATTACHMENTUPLOAD;
    case PUBLIC_FOLDER_TYPE:
      return ROLE_PUBLIC_FOLDER_MANAGE;
  }
  return null;
}
export function getEntityRoleAttachmentDownload(entity) {
  switch (getEntityType(entity)) {
    case MODEL_TYPE:
      return ROLE_MODEL_ATTACHMENTDOWNLOAD;
    case VALIDATION_TYPE:
      return ROLE_VALIDATION_ATTACHMENTDOWNLOAD;
    case VALIDATION_COMMITTEE_TYPE:
      return ROLE_VALIDATIONCOMMITTEE_ATTACHMENTDOWNLOAD;
    case MODEL_REVISION_TYPE:
      return ROLE_MODEL_ATTACHMENTDOWNLOAD;
    case MODEL_USAGE_TYPE:
      return ROLE_MODEL_ATTACHMENTDOWNLOAD;
    case SHARED_FOLDER_TYPE:
      return ROLE_SHARED_FOLDER_ATTACHMENTDOWNLOAD;
    case PUBLIC_FOLDER_TYPE:
      return ROLE_PUBLIC_FOLDER_MANAGE;
  }
  return null;
}
export function getEntityRoleAttachmentDelete(entity) {
  switch (getEntityType(entity)) {
    case MODEL_TYPE:
      return ROLE_MODEL_ATTACHMENTDELETE;
    case VALIDATION_TYPE:
      return ROLE_VALIDATION_ATTACHMENTDELETE;
    case VALIDATION_COMMITTEE_TYPE:
      return ROLE_VALIDATIONCOMMITTEE_ATTACHMENTDELETE;
    case MODEL_REVISION_TYPE:
      return ROLE_MODEL_ATTACHMENTDELETE;
    case MODEL_USAGE_TYPE:
      return ROLE_MODEL_ATTACHMENTDELETE;
    case SHARED_FOLDER_TYPE:
      return ROLE_SHARED_FOLDER_ATTACHMENTDELETE;
    case PUBLIC_FOLDER_TYPE:
      return ROLE_PUBLIC_FOLDER_MANAGE;
  }
  return null;
}
export function getEntityCreatePath(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return MODEL_CREATE_OVERVIEW_PATH;
    case VALIDATION_TYPE:
      return VALIDATION_CREATE_OVERVIEW_PATH;
    case VALIDATION_COMMITTEE_TYPE:
      return VALIDATION_COMMITTEE_CREATE_OVERVIEW_PATH;
    case MODEL_REVISION_TYPE:
      return MODEL_REVISION_CREATE_OVERVIEW_PATH;
    case MODEL_USAGE_TYPE:
      return MODEL_USAGE_CREATE_OVERVIEW_PATH;
  }
  return null;
}
export function getEntityOverviewPath(entityType) {
  let url;
  switch (entityType) {
    case MODEL_TYPE:
      url = MODEL_OVERVIEW_PATH;
      break;
    case VALIDATION_TYPE:
      url = VALIDATION_OVERVIEW_PATH;
      break;
    case VALIDATION_COMMITTEE_TYPE:
      url = VALIDATION_COMMITTEE_OVERVIEW_PATH;
      break;
    case MODEL_REVISION_TYPE:
      url = MODEL_REVISION_OVERVIEW_PATH;
      break;
    case MODEL_USAGE_TYPE:
      url = MODEL_USAGE_OVERVIEW_PATH;
      break;
    case MODEL_MONITORING_TYPE:
      url = MODEL_MONITORING_OVERVIEW_PATH;
      break;
    default:
      return null;
  }
  return url;
}

export function getFolderPath(entityType, folderId, folder) {
  switch (entityType) {
    case PUBLIC_FOLDER_TYPE:
      folderId = "public";
      break;
    case PERSONAL_FOLDER_TYPE:
      folderId = "personal";
      break;
  }
  return (
    FOLDER_OVERVIEW_PATH.replace(":folderId", folderId) +
    (folder ? "?" + qs.stringify({ folder }) : "")
  );
}

export function getStorageTypeTab(storageTypePlatform) {
  let tab;
  switch (storageTypePlatform) {
    case STORAGE_TYPE_DATA_HUB:
      tab = TAB_ATTACHMENTS;
      break;
    case ATTACHMENT_PLATFORM.DATAHUB:
      tab = TAB_ATTACHMENTS;
      break;
    case STORAGE_TYPE_DOCUMENTS:
      tab = TAB_DOCUMENTS;
      break;
    case ATTACHMENT_PLATFORM.DOCUMENTS:
      tab = TAB_DOCUMENTS;
      break;
    default:
      tab = TAB_ATTRIBUTES;
  }
  return tab;
}

export function getEntityDataHubPath(
  entityType,
  entityId,
  folder,
  entity,
  storageTypePlatform
) {
  const tab = getStorageTypeTab(storageTypePlatform);
  if (folder && !folder.startsWith("/")) {
    folder = "/" + folder;
  }
  const params = folder ? qs.stringify({ tab, folder }) : qs.stringify({ tab });
  switch (entityType) {
    case MODEL_TYPE:
    case MODEL_REVISION_TYPE:
    case MODEL_USAGE_TYPE:
    case VALIDATION_TYPE:
    case VALIDATION_COMMITTEE_TYPE:
      return getEntityHref(entity, params);
    case SHARED_FOLDER_TYPE:
    case PUBLIC_FOLDER_TYPE:
    case PERSONAL_FOLDER_TYPE:
      return getFolderPath(entityType, entityId, folder);
    default:
      return null;
  }
}

export function getLinkToAttachmentPath(
  entityType,
  entityTypeId,
  entityId,
  folder,
  platform
) {
  const tab = getStorageTypeTab(platform);
  if (folder && !folder.startsWith("/")) {
    folder = "/" + folder;
  }
  const params = folder ? qs.stringify({ tab, folder }) : qs.stringify({ tab });
  switch (entityType) {
    case MODEL_TYPE:
    case MODEL_REVISION_TYPE:
    case MODEL_USAGE_TYPE:
    case VALIDATION_TYPE:
    case VALIDATION_COMMITTEE_TYPE:
      const overviewPath =
        getEntityOverviewPath(entityType) +
        "?" +
        (params ? params : qs.stringify({ tab: TAB_ATTRIBUTES }));
      return overviewPath
        .replace(":entityType" + ID_REGEX, entityTypeId)
        .replace(":entityId" + ID_REGEX, entityId);
    case SHARED_FOLDER_TYPE:
    case PUBLIC_FOLDER_TYPE:
    case PERSONAL_FOLDER_TYPE:
      return getFolderPath(entityType, entityId, folder);
    default:
      return null;
  }
}

export function getShortcutHref(entity) {
  if (!entity) {
    return null;
  }
  const entityType = getEntityType(entity);
  const entityShortPath = getEntitiesShortPath(entityType);
  switch (entityType) {
    case MODEL_TYPE:
      return entityShortPath.replace(":entityId" + ID_REGEX, entity.id);
    case MODEL_REVISION_TYPE:
      return entityShortPath.replace(":revisionId" + ID_REGEX, entity.id);
    case MODEL_USAGE_TYPE:
      return entityShortPath
        .replace(
          ":entityType" + ID_REGEX,
          entity.modelRevision.model.modelType.id
        )
        .replace(":entityId" + ID_REGEX, entity.modelRevision.model.id)
        .replace(":revisionId" + ID_REGEX, entity.modelRevision.id)
        .replace(":usageType" + ID_REGEX, entity.modelUsageType.id)
        .replace(":usageId" + ID_REGEX, entity.id);
    case VALIDATION_TYPE:
      return entityShortPath
        .replace(":entityType" + ID_REGEX, entity.validationType.id)
        .replace(":entityId" + ID_REGEX, entity.id);
    case VALIDATION_COMMITTEE_TYPE:
      return entityShortPath
        .replace(":entityType" + ID_REGEX, entity.validationCommitteeType.id)
        .replace(":entityId" + ID_REGEX, entity.id);
    default:
      return null;
  }
}

export function getEntityHref(entity, params) {
  if (!entity) {
    return "";
  }
  const entityType = getEntityType(entity);
  const overviewPath =
    getEntityOverviewPath(entityType) +
    "?" +
    (params ? params : qs.stringify({ tab: TAB_ATTRIBUTES }));
  const entityTypeObject = getEntityTypeObject(entity);
  if (!entityTypeObject) {
    return "";
  }
  let entityTypeId = entityTypeObject;
  if (entityTypeId.id) {
    entityTypeId = entityTypeObject.id;
  }
  if (entityType === MODEL_REVISION_TYPE) {
    if (!entity.model) {
      return "";
    }
    return overviewPath
      .replace(":entityType" + ID_REGEX, entityTypeId)
      .replace(":entityId" + ID_REGEX, entity.model.id)
      .replace(":revisionId" + ID_REGEX, entity.id);
  }
  if (entityType === MODEL_USAGE_TYPE) {
    if (!entity.model) {
      return "";
    }
    return overviewPath
      .replace(":entityType" + ID_REGEX, entityTypeId)
      .replace(":entityId" + ID_REGEX, entity.model.id)
      .replace(":revisionId" + ID_REGEX, entity.revision.id)
      .replace(":usageId" + ID_REGEX, entity.id);
  }
  return overviewPath
    .replace(":entityType" + ID_REGEX, entityTypeId)
    .replace(":entityId" + ID_REGEX, entity.id);
}

export function getEntityHrefFromPath(path, params) {
  return (
    path +
    APP_OVERVIEW_PATH +
    "?" +
    (params ? params : qs.stringify({ tab: TAB_ATTRIBUTES }))
  );
}

export function getEntityLinkViewFromPath(path) {
  return { to: getEntityHrefFromPath(path) };
}

export function getEntityLink(entity) {
  return {
    to: getEntityHref(entity),
  };
}
export function getEntityGetAttachmentsParam(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return "models";
    case VALIDATION_TYPE:
      return "validations";
    case VALIDATION_COMMITTEE_TYPE:
      return "validation-committees";
    case MODEL_REVISION_TYPE:
      return "models";
    case MODEL_USAGE_TYPE:
      return "models";
    case SHARED_FOLDER_TYPE:
      return "shared-folders";
    case PERSONAL_FOLDER_TYPE:
      return PERSONAL_FOLDER_TYPE;
    case PUBLIC_FOLDER_TYPE:
      return PUBLIC_FOLDER_TYPE;
  }
  return null;
}

export function getEntityGetType(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return "model-types";
    case VALIDATION_TYPE:
      return "validation-types";
    case VALIDATION_COMMITTEE_TYPE:
      return "validation-committee-types";
    case MODEL_REVISION_TYPE:
      return "model-types";
    case MODEL_USAGE_TYPE:
      return "model-usage-types";
  }
  return null;
}
export function getEntityGetTypeAttribute(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return "modelType";
    case VALIDATION_TYPE:
      return "validationType";
    case VALIDATION_COMMITTEE_TYPE:
      return "validationCommitteeType";
    case MODEL_REVISION_TYPE:
      return "modelId";
    case MODEL_USAGE_TYPE:
      return "modelUsageType";
  }
  return null;
}

export function getEntityGetApiParam(entity) {
  return getEntityGetApiParamSimple(
    getEntityType(entity),
    entity.model ? entity.model.id : null
  );
}

export function getEntityGetApiParamSimple(entityType, modelId) {
  switch (entityType) {
    case MODEL_TYPE:
      return "models";
    case MODEL_USAGE_TYPE:
      return "model-usages";
    case VALIDATION_TYPE:
      return "validations";
    case VALIDATION_COMMITTEE_TYPE:
      return "validation-committees";
    case MODEL_REVISION_TYPE:
      if (modelId) {
        return "models/" + modelId + "/revisions";
      } else {
        return "model-revisions";
      }
  }
  return null;
}

export function getEntityApiParam(entity) {
  switch (entity) {
    case MODEL_TYPE:
      return "models";
    case MODEL_USAGE_TYPE:
      return "model-usages";
    case VALIDATION_TYPE:
      return "validations";
    case VALIDATION_COMMITTEE_TYPE:
      return "validation-committees";
    case MODEL_REVISION_TYPE:
      return "models";
  }
  return null;
}

export function getEntityApiExport(entityType) {
  switch (entityType) {
    case MODEL_REVISION_TYPE,MODEL_TYPE:
      return "/api/models/revisions/export";
    case MODEL_USAGE_TYPE:
      return "/api/models/model-usages/export";
    case VALIDATION_TYPE:
      return "/api/validations/export";
    case VALIDATION_COMMITTEE_TYPE:
      return "/api/validation-committees/export";
    default:
      return null;
  }
}

export function getUsersRowPerPageForEntity(entityType) {
  switch (entityType) {
    case MODEL_TYPE:
      return "modelRowsPerTable";
    case MODEL_MONITORING_TYPE:
      return "modelMonitoringRowsPerTable";
    case VALIDATION_TYPE:
      return "validationRowsPerTable";
    case VALIDATION_COMMITTEE_TYPE:
      return "validationCommitteeRowsPerTable";
  }
  return null;
}
export function updateEntity(entity, newEntity) {
  if (
    entity &&
    entity.id &&
    newEntity &&
    newEntity.id &&
    entity.id.toString() === newEntity.id.toString()
  ) {
    return Object.assign({}, Object.assign(entity, newEntity));
  }
  return newEntity;
}
