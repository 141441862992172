import Keycloak from 'keycloak-js';

export const isPropertiesValid = (properties) => {
  return properties != null
    && properties.hasOwnProperty('GUI_KEYCLOAK_REALM')
    && properties.hasOwnProperty('GUI_KEYCLOAK_CLIENT')
    && properties.hasOwnProperty('GUI_KEYCLOAK_URL');
};

export const mapProperties = (properties) => {
  return {
    url: properties.GUI_KEYCLOAK_URL,
    realm: properties.GUI_KEYCLOAK_REALM,
    clientId: properties.GUI_KEYCLOAK_CLIENT,
  };
};

export const createClient = (guiConfig) => {
  return new Keycloak({
    ...guiConfig,
    onLoad: 'login-required',
  });
};
