import React, { Component } from "react";
import ButtonAbstract from "../RestrictedComponent";
import { ListItem as ListItemMaterial } from "@material-ui/core";

class ListItem extends Component {
  render() {
    return (
      <ButtonAbstract componentButton={ListItemMaterial} {...this.props} />
    );
  }
}

export default ListItem;
