import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../../../../Buttons/Button";
import IconButtonLoading from "../../../../Buttons/IconButtonLoading";
import TabCentered from "../../../../TabContainer/TabCentered";
import AddParticipantDialogForm from "./AddParticipantDialogForm";
import {
  addParticipant,
  removeParticipant
} from "../../../../../actions/Validations/Committees";
import {
  ROLE_PARTICIPATION_MANAGE_ASSIGNMENT,
  ROLE_PARTICIPATION_ROLES_VIEW,
  ROLE_PARTICIPATION_CREATE,
  ROLE_PARTICIPATION_DELETE
} from "../../../../../constants/Authorities";
import { isAuthorized } from "../../../../../api/Authorities";
import { withErrorBoundary } from "../../../../../routes";
import { Close } from "@material-ui/icons";
import {
  Grid,
  Avatar,
  DialogActions,
  Dialog,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

class Participants extends Component {
  state = {
    openAddParticipation: false
  };

  handleSubmitParticipation = form => {
    const { entity, addParticipant } = this.props;
    addParticipant(form.userId, entity.id, form.role);
  };

  handleOpenAddParticipation = () => {
    this.setState({
      openAddParticipation: true
    });
  };
  handleCloseAddParticipation = () => {
    this.setState({
      openAddParticipation: false
    });
  };

  handleRemoveParticipation = participation => {
    this.setState({
      participationToRemove: participation
    });
  };

  handleCancelRemove = () => {
    this.setState({
      participationToRemove: null
    });
  };
  handleRemove = () => {
    const { participationToRemove } = this.state;
    const { entity, removeParticipant } = this.props;
    removeParticipant(entity.id, participationToRemove);
    this.setState({
      participationToRemove: null
    });
  };

  render() {
    const { entity, me } = this.props;
    const { openAddParticipation, participationToRemove } = this.state;

    if (!entity) {
      return null;
    }
    const hasParticipants =
      entity.participations && entity.participations.length > 0;
    let list;

    const button = (
      <Button
        testid="addParticipant"
        authoritiesRequired={[
          ROLE_PARTICIPATION_CREATE,
          ROLE_PARTICIPATION_ROLES_VIEW
        ]}
        onClick={this.handleOpenAddParticipation}
        variant="outlined"
        color="primary"
      >
        Add a participant
      </Button>
    );

    if (!hasParticipants) {
      list = (
        <TabCentered>
          <div className="emptyMessage">No participants added yet</div>
          {button}
        </TabCentered>
      );
    } else {
      list = (
        <List
          component="nav"
          style={{ width: "100%", padding: "10px", overflow:"auto" }}
          testid="listParticipants"
        >
          {entity.participations.map((participation, idx) => {
            let role;
            if (isAuthorized(me, [ROLE_PARTICIPATION_ROLES_VIEW])) {
              role = participation.participationRole;
            }

            return (
              <ListItem key={idx} testid="participant">
                <ListItemAvatar>
                  <Avatar>{participation.appUser.label[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={participation.appUser.label}
                  secondary={role}
                />
                <ListItemSecondaryAction>
                  <IconButtonLoading
                    testid="removeParticipant"
                    loading={participation.removing}
                    loadingTitle="Removing the participant"
                    tooltipTitle="Remove the participant"
                    authoritiesRequired={[
                      ROLE_PARTICIPATION_MANAGE_ASSIGNMENT,
                      ROLE_PARTICIPATION_DELETE
                    ]}
                    aria-label={"Remove the participant"}
                    onClick={() => {
                      this.handleRemoveParticipation(participation);
                    }}
                  >
                    <Close />
                  </IconButtonLoading>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      );
    }

    return (
      <React.Fragment>
        <Grid container justify="flex-end" style={{ padding: "10px" }}>
          {hasParticipants && button}
        </Grid>
        {list}
        <AddParticipantDialogForm
          handleClose={this.handleCloseAddParticipation}
          open={openAddParticipation}
          onSubmit={this.handleSubmitParticipation}
        />

        <Dialog
          open={Boolean(participationToRemove)}
          onClose={this.handleCancelRemove}
        >
          <CloseableDialogTitle
            id="confirmation-dialog-title"
            handleClose={this.handleCancelRemove}
          >
            Are you sure to remove the participation of{" "}
            {participationToRemove ? participationToRemove.appUser.label : ""}?
          </CloseableDialogTitle>
          <DialogActions>
            <Button onClick={this.handleCancelRemove} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleRemove}
              color="primary"
              testid="confirmRemoveParticipant"
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    padding: "24px 0"
  },
  heading: {
    flexBasis: "50%",
    flexShrink: 0
  },
  gridList: {
    width: "100%"
  },
  gridListTile: {},
  card: {
    display: "flex",
    cursor: "pointer"
  },
  details: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "hidden"
  },
  fileName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  content: {
    flex: "1 0 auto",
    paddingBottom: 0
  },
  cover: {
    display: "flex",
    alignItems: "center",
    margin: "0 5px",
    minWidth: 80
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    me: state.auth.me
  };
};

export default withStyles(styles)(
  withErrorBoundary(
    connect(
      mapStateToProps,
      { addParticipant, removeParticipant }
    )(Participants)
  )
);
