import {
  LOADING_ATTRIBUTES,
  GET_ATTRIBUTES,
  END_LOADING_ATTRIBUTES,
  ATTRIBUTES_ERROR,
  SHOW_ATTRIBUTE,
  HIDE_ATTRIBUTE
} from "../../constants/ActionTypes";
import axios from "axios";
import {
  setSubmitFailed,
  touch,
  startAsyncValidation,
  stopAsyncValidation
} from "redux-form";

export function manageAttributesErrors(attributesError) {
  return function(dispatch) {
    if (!attributesError) {
      return;
    }
    dispatch(startAsyncValidation("attributesform"));
    let ids = [],
      errors = {};
    for (let i = 0; i < attributesError.length; i++) {
      const attributeError = attributesError[i];
      const id = attributeError.id;
      ids.push(id);
      errors[id] = attributeError.label;
    }
    dispatch(stopAsyncValidation("attributesform", errors));
    dispatch(touch("attributesform", ids));
    dispatch(setSubmitFailed("attributesform", ids));
  };
}

export function getAttributes() {
  return function(dispatch) {
    dispatch({
      type: LOADING_ATTRIBUTES
    });

    axios
      .get("/api/attributes", {
        withCredentials: true
      })
      .then(response => {
        let attributes = response.data;
        dispatch({
          type: GET_ATTRIBUTES,
          attributes: attributes
        });
        dispatch({
          type: END_LOADING_ATTRIBUTES
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ATTRIBUTES_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_ATTRIBUTES
        });
      });
  };
}

export function hideAttribute(attribute) {
  return function(dispatch) {
    dispatch({
      type: HIDE_ATTRIBUTE,
      attribute
    });
  };
}
export function showAttribute(attribute) {
  return function(dispatch) {
    dispatch({
      type: SHOW_ATTRIBUTE,
      attribute
    });
  };
}
