import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { initializeForm } from "../../actions";
import { getValue } from "./attribute";
import { scrollToFirstError, showValidationFailedMessage } from "../../api/Error";

function getInitialValues(revision) {
  let initialValues = {};
  if (revision) {
    let attributeSections = revision.attributes;
    for (let i = 0; attributeSections && i < attributeSections.length; i++) {
      const panel = attributeSections[i].groupNames;
      if (panel) {
        const keys = Object.keys(panel);
        for (let j = 0; j < keys.length; j++) {
          const key = keys[j];
          const attributes = panel[key];
          for (let k = 0; k < attributes.length; k++) {
            const attribute = attributes[k];
            initialValues[attribute.id] = getValue(attribute);
          }
        }
      }
    }
  }
  return initialValues;
}

class AttributesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      owner: props.owner
    };
    let initialValues = getInitialValues(props.owner);
    props.initializeForm("attributesform", initialValues);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.owner !== this.props.owner) {
      let { owner, id } = nextProps;
      let initialValues = getInitialValues(owner);
      nextProps.initializeForm("attributesform", initialValues);
      this.setState({
        owner: nextProps.owner
      });
    }
  }

  render() {
    const { handleSubmit, children } = this.props;

    return <form style={{height:"100%"}} onSubmit={handleSubmit}>{children}</form>;
  }
}

const mapStateToPropsAttributesTable = (state, ownProps) => {
  let { owner, id } = ownProps;
  if (!owner) {
    return {
      initialValues: {}
    };
  }
  let initialValues = getInitialValues(owner);
  return {
    initialValues: initialValues,
    onSubmit: values => {
      let attributes = owner.attributes;
      for (let i = 0; i < attributes.length; i++) {
        const panel = attributes[i].groupNames;
        const keys = Object.keys(panel);
        for (let j = 0; j < keys.length; j++) {
          const key = keys[j];
          const attributesSections = panel[key];
          for (let j = 0; j < attributesSections.length; j++) {
            const attributesSection = attributesSections[j];
            attributesSection.value = values[attributesSection.id];
          }
        }
      }
      if (owner.creationModeRevision) {
        let attributesRevision = owner.creationModeRevision.attributes;
        for (let i = 0; i < attributesRevision.length; i++) {
          const panel = attributesRevision[i].groupNames;
          const keysRevision = Object.keys(panel);
          for (let j = 0; j < keysRevision.length; j++) {
            const key = keysRevision[j];
            const attributesSections = panel[key];
            for (let j = 0; j < attributesSections.length; j++) {
              const attributesSection = attributesSections[j];
              attributesSection.value = values[attributesSection.id];
            }
          }
        }
      }
      if (ownProps.onSubmit) {
        ownProps.onSubmit(owner);
      }
    }
  };
};

export default connect(
    mapStateToPropsAttributesTable,
    {initializeForm}
)
(reduxForm(
    {
      form: "attributesform",
      destroyOnUnmount: false,
      asyncBlurFields: [],
      onSubmitFail: (errors, dispatch) => {
        showValidationFailedMessage(errors, dispatch);
        scrollToFirstError(errors);
      }
    }
)(AttributesForm))