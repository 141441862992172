import React from "react";
import {
    Box,
    Typography
} from "@material-ui/core";
import JSONTree from "react-json-view";
import Grid from "components/Grid";

function renderJSON(json) {
    try {
        return <JSONTree collapseStringsAfterLength={15} src={JSON.parse(json)} />
    } catch(e) {
        return <div>{json}</div>;
    }
}

const MonitoringRowContent = ({monitoring}) => {
    return <React.Fragment>
        <Box margin={1} style={{ padding: "0 16px" }}>
            <Grid container>
                { monitoring.input ?
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                        <Typography variant="h6" gutterBottom component="div">Input</Typography>
                        {renderJSON(monitoring.input)}
                    </Grid>
                    : null
                }
                { monitoring.result ?
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                        <Typography variant="h6" gutterBottom component="div">Result</Typography>
                        {renderJSON(monitoring.result)}
                    </Grid>
                    : null
                }
            </Grid>
        </Box>
    </React.Fragment>;
};

export default MonitoringRowContent;
