import axios from "axios";
import {
  CREATE_FOLDER_ATTACHMENT,
  CREATING_FOLDER_ATTACHMENT,
  END_CREATING_FOLDER_ATTACHMENT,
  ERROR_CREATING_FOLDER_ATTACHMENT,
} from 'constants/ActionTypes';
import {
  getEntityGetAttachmentsParam,
  getEntityType,
  PERSONAL_FOLDER_TYPE,
  PUBLIC_FOLDER_TYPE
} from 'api/Entity';
import { getApiError } from 'api/Error';

export function createFolderAttachment(
  entity,
  prefix,
  name,
  rewriteDuplicate = false
) {
  return async function(dispatch) {
    const ownerId = entity.id;
    const entityType = getEntityType(entity);
    const entityParam = getEntityGetAttachmentsParam(entityType);
    let url = "/api/" + entityParam + "/" + ownerId + "/attachments/folders";
    if (entityType === PERSONAL_FOLDER_TYPE) {
      url = "/api/personal/storage/attachments/folders";
    } else if (entityType === PUBLIC_FOLDER_TYPE) {
      url = "/api/public/storage/attachments/folders";
    }
    try {
      dispatch({
        type: CREATING_FOLDER_ATTACHMENT,
        ownerId: entity.id
      });

      const folder = (await axios.post(
        url,
        {
          prefix: prefix,
          name: name,
          rewriteDuplicate
        },
        {
          withCredentials: true
        }
      )).data;
      dispatch({
        type: CREATE_FOLDER_ATTACHMENT,
        folder: folder,
        ownerId: ownerId
      });
      dispatch({
        type: END_CREATING_FOLDER_ATTACHMENT,
        ownerId: ownerId
      });
    } catch (err) {
      let message = getApiError(err);
      dispatch({
        type: ERROR_CREATING_FOLDER_ATTACHMENT,
        error: message,
        ownerId: ownerId
      });
      dispatch({
        type: END_CREATING_FOLDER_ATTACHMENT,
        ownerId: ownerId
      });
    }
  };
}

export async function getFolderSize(folder) {
  const { entity, prefix, ownerId, fileName } = folder;
  const entityParam = getEntityGetAttachmentsParam(entity);
  let url =
    "/api/" + entityParam + "/" + ownerId + "/attachments/object-size?prefix=";
  if (entity === PERSONAL_FOLDER_TYPE) {
    url = "/api/personal/storage/attachments/object-size?prefix=";
  } else if (entity === PUBLIC_FOLDER_TYPE) {
    url = "/api/public/storage/attachments/object-size?prefix=";
  }

  return await axios.get(url + (prefix + fileName).substr(1), {
    withCredentials: true
  });
}
