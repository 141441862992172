import React from "react";
import IconButton from "./Button";
import { CircularProgress, Tooltip } from "@material-ui/core";

const IconButtonLoading = ({loading, tooltipTitle, tooltipPlacement, loadingTitle, loadingSize, onClick, ...rest}) => {

  // Default onClick function used if the button is in the loading state or no othar function is provided
  const defaultHandleOnClick = (e) => {
      e.stopPropagation();
      e.preventDefault();
  };

  const button = loading ?
      (
          <IconButton {...rest} icon="true" disableFocusRipple disableRipple onClick={defaultHandleOnClick}>
              <span style={{verticalAlign: "middle", width: loadingSize, height: loadingSize}}>
                <CircularProgress color="inherit" size={loadingSize} />
              </span>
          </IconButton>
      ) : <IconButton {...rest} icon="true" onClick={onClick || rest.type === "submit" ? onClick : defaultHandleOnClick} />;

  if ((!loading && tooltipTitle) || (loading && loadingTitle)) {
      return (
          <Tooltip placement={tooltipPlacement} title={loading ? loadingTitle : tooltipTitle}>
              {button}
          </Tooltip>
      );
  }

  return button;
};

IconButtonLoading.defaultProps = {
  loadingSize: 20
};

export default IconButtonLoading;
