import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isAuthorized } from "../../api/Authorities";
import { ROLE_SECURITYLEVEL_VIEW } from "../../constants/Authorities";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

class SecurityLevels extends Component {
  renderSecurityLevel = () => {
    const { entity, match, securityLevels } = this.props;
    let securityLevel;
    if (entity && entity.securityLevels && entity.securityLevels.length > 0) {
      let found = false;
      return entity.securityLevels.map((securityLevel, idx) => {
        if (!securityLevel) {
          return null;
        }
        if (found) {
          return ", " + securityLevel.name;
        }
        found = true;
        return securityLevel.name;
      });
    } else if (match && match.params && match.params.securityLevel) {
      for (let i = 0; securityLevels && i < securityLevels.length; i++) {
        if (securityLevels[i].id === match.params.securityLevel) {
          securityLevel = securityLevels[i];
          break;
        }
      }
    }
    if (!securityLevel) {
      return "No security level";
    } else {
      return securityLevel.name;
    }
  };

  render() {
    const { me, classes } = this.props;
    if (!isAuthorized(me, [ROLE_SECURITYLEVEL_VIEW])) {
      return null;
    }

    return (
      <div className={classes.attributeContainer}>
        <Typography variant="caption" className={classes.attributeLabel}>
          Security level
        </Typography>
        <Typography variant="body2">{this.renderSecurityLevel()}</Typography>
      </div>
    );
  }
}

const styles = theme => ({
  attributeContainer: {
    padding: "0 24px 10px 24px"
  },
  attributeLabel: {}
});

const mapStateToProps = (state, ownProps) => {
  return {
    securityLevels: state.securityLevels.list,
    me: state.auth.me
  };
};
export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {}
    )(SecurityLevels)
  )
);
