import {
  LOADING_DOCKER_IMAGES_ERROR,
  END_LOADING_DOCKER_IMAGES,
  GET_DOCKER_IMAGES,
  LOADING_DOCKER_IMAGES
} from "../../constants/ActionTypes";
import axios from "axios/index";

export function getDockerImages() {
  return function(dispatch) {
    dispatch({
      type: LOADING_DOCKER_IMAGES
    });

    axios
      .get("/api/datalab-images", {
        withCredentials: true,
        params: {
          onlyInteractive: true
        }
      })
      .then(response => {
        dispatch({
          type: GET_DOCKER_IMAGES,
          dockerImages: response.data
        });
        dispatch({
          type: END_LOADING_DOCKER_IMAGES
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: LOADING_DOCKER_IMAGES_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_DOCKER_IMAGES
        });
      });
  };
}
