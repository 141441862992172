import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonLoading from "../Buttons/ButtonLoading";
import { deleteFolder } from "actions/Folders";
import { withRouter } from "react-router";
import { ROLE_SHARED_FOLDER_MANAGE } from "../../constants/Authorities";
import { Delete } from "@material-ui/icons";
import { DialogContent, DialogActions, Dialog } from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";

class DeleteButton extends Component {
  state = {
    open: false
  };

  openDialog = () => {
    this.setState({
      open: true
    });
  };
  closeDialog = () => {
    this.setState({
      open: false
    });
  };
  confirmRemove = () => {
    const { deleteFolder, folder } = this.props;
    deleteFolder(folder.id);
  };

  render() {
    const { folder } = this.props;
    const { open } = this.state;
    if (!folder) {
      return null;
    }

    return (
      <React.Fragment>
        <ButtonLoading
          tooltipPlacement="left"
          tooltipTitle="Remove the folder"
          loading={folder.deleting}
          loadingTitle="Removing the folder"
          loadingSize={22}
          style={{
            width: "100%",
            justifyContent: "flex-start"
          }}
          onClick={this.openDialog}
          color="primary"
        >
          <Delete style={{ marginRight: 15 }} />
          Remove the folder
        </ButtonLoading>
        <Dialog onClose={this.closeDialog} open={open}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={this.closeDialog}
          >
            This folder will be deleted, do you want to continue?
          </CloseableDialogTitle>
          <DialogContent>
            <div className="error">{folder.error_deleting}</div>
          </DialogContent>
          <DialogActions>
            <React.Fragment>
              {!folder.deleting && (
                <ButtonLoading
                  onClick={this.closeDialog}
                  color="primary"
                  autoFocus
                >
                  Cancel
                </ButtonLoading>
              )}
              <ButtonLoading
                loading={folder.deleting}
                loadingTitle="Removing the folder"
                authoritiesRequired={[ROLE_SHARED_FOLDER_MANAGE]}
                onClick={this.confirmRemove}
                color="primary"
              >
                Continue
              </ButtonLoading>
            </React.Fragment>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    folder: ownProps.folder
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { deleteFolder }
  )(DeleteButton)
);
