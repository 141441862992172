import {ListItemSecondaryAction} from "@material-ui/core";
import React from "react";
import EntityLinkButton from "components/Entity/EntityLinkButton";

const TaskListItemEntityLink = ({task}) => {
  if (!task.owningEntity) {
    return null;
  }

  return (<div style={{
      width: "35%",
      flex: "0 0 auto",
      display:"flex",
      justifyContent: "flex-end"
  }}>
      <EntityLinkButton task={task} />
    </div>
  );
};

export default TaskListItemEntityLink;