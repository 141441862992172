import {
  LOADING_CONFIGURATION,
  END_LOADING_CONFIGURATION,
  GET_CONFIGURATION_TIMESTAMP,
  ERROR_CONFIGURATION,
  GET_DYNAMIC_PROPERTIES,
  SET_DYNAMIC_PROPERTIES
} from "../../constants/ActionTypes";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_CONFIGURATION_TIMESTAMP:
      return {
        ...state,
        timestamp: action.timestamp
      };
    case LOADING_CONFIGURATION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case END_LOADING_CONFIGURATION:
      return {
        ...state,
        loading: false
      };
    case ERROR_CONFIGURATION:
      return {
        ...state,
        error: action.error
      };
    case GET_DYNAMIC_PROPERTIES:
      return {
        ...state,
        dynamic_properties: action.dynamicProperties
      };
    case SET_DYNAMIC_PROPERTIES:
      state.dynamic_properties[action.name] = action.value;
      return {
        ...state,
        dynamic_properties: {
          ...state.dynamic_properties
        }
      };
    default:
      return state;
  }
}
