import React, { Component } from "react";

import { VisibilityOutlined } from "@material-ui/icons";

import { nFormatter } from 'api/File';
import { getFolderSize } from "actions/Attachments/folders";
import IconButtonLoading from "components/Buttons/IconButtonLoading";

class FolderSize extends Component {
  state = {
    size: null,
    loading: false
  };
  refresh = async ev => {
    const { folder } = this.props;
    ev.stopPropagation();
    event.preventDefault();
    if (!folder) {
      this.setState({
        size: null
      });
    }
    this.setState({
      loading: true
    });
    try {
      const _size = await getFolderSize(folder);
      this.setState({
        size: _size.data
      });
    } catch (e) {
      this.setState({
        size: null
      });
    }
    this.setState({
      loading: false
    });
  };
  render() {
    const { size, loading } = this.state;

    if (!size && size !== 0) {
      return (
        <IconButtonLoading loading={loading} onClick={this.refresh}>
          <VisibilityOutlined style={{ fontSize: "1.1rem" }} />
        </IconButtonLoading>
      );
    }
    return nFormatter(size, 1);
  }
}
export default FolderSize;
