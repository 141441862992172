import { green, amber } from '@material-ui/core/colors';

export const NOTIFCATION_COLOR = {
    SUCCESS: {
        backgroundColor: green[600],
    },
    ERROR: {
        backgroundColor: "#b71c1c",
    },
    INFO: {
        backgroundColor: "#34515e",
    },
    WARNING: {
        backgroundColor: amber[700],
    }
};
