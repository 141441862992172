import React from "react";
import Button from "../Buttons/Button";
import {Dialog} from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

const DeleteAttachmentDialogue = ({
  handleClose,
  handleConfirmDeleting,
  attachmentToDelete,
  attachmentLabel
}) => {

  const deleteActionConfirmationMessage = "Are you sure you want to delete the" + " " +
    (attachmentToDelete && attachmentToDelete.isFolder
      ? 'folder'
      : attachmentLabel) + ' ' +
    (attachmentToDelete ? attachmentToDelete.label : "") + "?"

  return (
    <Dialog
      open={Boolean(attachmentToDelete)}
      onClose={handleClose}
    >
      <CloseableDialogTitle
        id="confirmation-dialog-title"
        handleClose={handleClose}
      >
        {deleteActionConfirmationMessage}
      </CloseableDialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirmDeleting} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

}

export default DeleteAttachmentDialogue;