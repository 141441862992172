import {
  GET_CONTAINERS,
  LOADING_CONTAINERS,
  END_LOADING_CONTAINERS,
  CONTAINERS_ERROR,
  GET_DOKER_HOSTS,
  LOADING_DOKER_HOSTS,
  END_LOADING_DOKER_HOSTS,
  DOKER_HOSTS_ERROR,
  GET_CONTAINERS_USER,
  LOADING_CONTAINERS_USER,
  END_LOADING_CONTAINERS_USER,
  CONTAINERS_USER_ERROR,
  GET_CONTAINER,
  KILL_CONTAINER,
  KILLING_CONTAINER,
  END_KILLING_CONTAINER,
  ERROR_KILLING_CONTAINER,
  SYNCUPING_CONTAINER,
  END_SYNCUPING_CONTAINER,
  ERROR_SYNCUPING_CONTAINER,
  SYNCDOWNING_CONTAINER,
  END_SYNCDOWNING_CONTAINER,
  ERROR_SYNCDOWNING_CONTAINER,
  OPENING_ANALYTICAL_TOOL,
  END_OPENING_ANALYTICAL_TOOL,
  ERROR_OPENING_ANALYTICAL_TOOL,
  ADD_CONTAINER,
  PASSWORD_TO_COPY,
  PUSH_NOTIFICATION
} from "../../constants/ActionTypes";
import axios from "axios";

export function getContainersUser() {
  return function(dispatch) {
    dispatch({
      type: LOADING_CONTAINERS_USER
    });

    axios
      .get("/api/datalabs", {
        withCredentials: true
      })
      .then(response => {
        let containers = response.data;
        dispatch({
          type: GET_CONTAINERS_USER,
          containers: containers
        });
        dispatch({
          type: END_LOADING_CONTAINERS_USER
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: CONTAINERS_USER_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_CONTAINERS_USER
        });
      });
  };
}
export function getContainers() {
  return function(dispatch) {
    dispatch({
      type: LOADING_CONTAINERS
    });

    axios
      .get("/api/datalabs?admin=true", {
        withCredentials: true
      })
      .then(response => {
        let containers = response.data;
        dispatch({
          type: GET_CONTAINERS,
          containers: containers
        });
        dispatch({
          type: END_LOADING_CONTAINERS
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: CONTAINERS_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_CONTAINERS
        });
      });
  };
}
export function getDockerHosts() {
  return function(dispatch) {
    dispatch({
      type: LOADING_DOKER_HOSTS
    });

    axios
      .get("/api/datalab-nodes", {
        withCredentials: true
      })
      .then(response => {
        let doker_hosts = response.data;
        dispatch({
          type: GET_DOKER_HOSTS,
          doker_hosts: doker_hosts
        });
        dispatch({
          type: END_LOADING_DOKER_HOSTS
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: DOKER_HOSTS_ERROR,
          error: message
        });
        dispatch({
          type: END_LOADING_DOKER_HOSTS
        });
      });
  };
}
export function killContainer(container, isAdmin = false) {
  return function(dispatch) {
    const containerId = container.containerId;
    dispatch({
      type: KILLING_CONTAINER,
      containerId
    });

    axios
      .delete("/api/datalabs/" + containerId + "?admin=" + isAdmin, {
        withCredentials: true
      })
      .then(response => {
        dispatch({
          type: KILL_CONTAINER,
          containerId
        });
        dispatch({
          type: END_KILLING_CONTAINER,
          containerId
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: "Datalab environment removed successfully."
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_KILLING_CONTAINER,
          error: message,
          containerId
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: message
        });
        dispatch({
          type: END_KILLING_CONTAINER,
          containerId
        });
      });
  };
}
export function syncUpContainer(container, removeContainer, isAdmin = false) {
  return function(dispatch) {
    const containerId = container.containerId;
    if (removeContainer) {
      dispatch({
        type: KILLING_CONTAINER,
        containerId
      });
    }
    dispatch({
      type: SYNCUPING_CONTAINER,
      containerId
    });

    axios
      .post(
        "/api/datalabs/" + containerId + "/sync?action=up&admin=" + isAdmin,
        {
          withCredentials: true
        }
      )
      .then(response => {
        const container = response.data;
        dispatch({
          type: GET_CONTAINER,
          container
        });
        dispatch({
          type: END_SYNCUPING_CONTAINER,
          containerId
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: "Updated Data hub successfully."
        });
        if (removeContainer) {
          killContainer(container, isAdmin)(dispatch);
        }
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_SYNCUPING_CONTAINER,
          error: message,
          containerId
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: message
        });
        dispatch({
          type: END_SYNCUPING_CONTAINER,
          containerId
        });
        if (removeContainer) {
          dispatch({
            type: END_KILLING_CONTAINER,
            containerId
          });
        }
      });
  };
}
export function syncDownContainer(container, isAdmin = false) {
  return function(dispatch) {
    const containerId = container.containerId;
    dispatch({
      type: SYNCDOWNING_CONTAINER,
      containerId
    });

    axios
      .post(
        "/api/datalabs/" + containerId + "/sync?action=down&admin=" + isAdmin,
        {
          withCredentials: true
        }
      )
      .then(response => {
        const container = response.data;
        dispatch({
          type: GET_CONTAINER,
          container
        });
        dispatch({
          type: END_SYNCDOWNING_CONTAINER,
          containerId
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "check",
          variant: "success",
          message: "Updated the Datalab environment successfully."
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        console.log(message);
        dispatch({
          type: ERROR_SYNCDOWNING_CONTAINER,
          error: message,
          containerId
        });
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: message
        });
        dispatch({
          type: END_SYNCDOWNING_CONTAINER,
          containerId
        });
      });
  };
}

export function createAnalyticalToolContainer(
  username,
  password,
  imageAlias,
  revisions
) {
  return function(dispatch) {
    dispatch({
      type: OPENING_ANALYTICAL_TOOL
    });

    axios
      .post(
        "/api/datalabs",
        {
          username: username,
          password: password,
          imageAlias: imageAlias,
          mappedEntities: revisions
            ? revisions.map(revision => {
                return revision.id;
              })
            : []
        },
        {
          withCredentials: true
        }
      )
      .then(response => {
        let datas = response.data;
        dispatch({
          type: ADD_CONTAINER,
          container: datas
        });
        dispatch({
          type: PASSWORD_TO_COPY,
          password: password
        });
        dispatch({
          type: END_OPENING_ANALYTICAL_TOOL
        });
        const win = window.open(datas.containerServiceUrl, "_blank");
        if (win) {
          win.focus();
        }
      })
      .catch(err => {
        console.log(err);
        let message = err.response.data.message;
        dispatch({
          type: ERROR_OPENING_ANALYTICAL_TOOL,
          error: message
        });
        dispatch({
          type: END_OPENING_ANALYTICAL_TOOL
        });

        if (message) {
          message = ": " + message;
        }
        dispatch({
          type: PUSH_NOTIFICATION,
          icon: "error",
          variant: "error",
          message: "Failed to create a new Datalab environment" + message
        });
      });
  };
}
