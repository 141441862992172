import React, { useEffect, useState } from 'react';

import FileIcon, { defaultStyles } from 'react-file-icon';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';

import Mime2Ext from 'api/Mime2Ext';
import { END, PENDING, UPLOAD } from 'constants/FileUploadStatus';
import EndStatus from 'components/Uploads/Statuses/EndStatus';
import StaticStatus from 'components/Uploads/Statuses/StaticStatus';
import Uploader from 'components/Uploads/Uploader';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  progress: {
    padding: '0px 0px 0px 20px',
    margin: 0,
  },
}));

export const UploadRow = ({
  fileId,
  group,
  file,
  isUploading,
  rewrite,
  onUploadProcessed,
  onConflictResolve,
}) => {

  const classes = useStyles();
  const [uploadStatus, setUploadStatus] = useState(PENDING);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (isUploading) {
      setUploadStatus(UPLOAD);
    }
  }, [isUploading]);

  const handleUploadProcessed = (status, message) => {
    if (message) {
      setMessage(message.toString());
    }
    setUploadStatus(status);
    onUploadProcessed(status, fileId);
  };

  const renderStatus = () => {
    switch (uploadStatus) {
      case UPLOAD:
        return (
          <Uploader
            file={file}
            group={group}
            rewrite={rewrite}
            onUploadProcessed={handleUploadProcessed}
            onConflictResolve={onConflictResolve}
          />
        );
      case END:
        return (
          <EndStatus
            group={group}
          />
        );
      default:
        return (
          <StaticStatus status={uploadStatus} message={message}/>
        );
    }
  };

  const fileName = file.name;
  const extension = fileName.split('.').pop();
  const extensionFinal = extension || Mime2Ext.data[file.type];
  const prefix = group.prefix ? group.prefix : '';
  const prefixPart = prefix ? `— ${prefix}` : '';

  return (
    <ListItem
      className={classes.root}
      dense
      button
    >
      <ListItemAvatar>
        <FileIcon
          size={40}
          extension={extensionFinal}
          {...defaultStyles[extensionFinal]
          }
        />
      </ListItemAvatar>
      <ListItemText>
        <Tooltip title={fileName}>
          <Typography variant="subtitle1" noWrap>
            {fileName}
          </Typography>
        </Tooltip>
        <Tooltip title={prefix}>
          <Typography variant="body2" noWrap>
            {`${group.entityDetail.entityTypeLabel} ${prefixPart}`}
          </Typography>
        </Tooltip>
      </ListItemText>
      <ListItemIcon className={classes.progress}>
        {renderStatus()}
      </ListItemIcon>
    </ListItem>
  );
};

/**
 * Props are equal until the id or isUploading is changed. Is uploading is a trigger
 * that signal to start uploading (mount Uploader).
 *
 * @param prevProps Previous props
 * @param nextProps Next props
 *
 * @returns {boolean} true if fileId or uploading is changed.
 */
function rowPropsAreEqual(prevProps, nextProps) {
  if (!prevProps || !nextProps) {
    return false;
  }

  if (prevProps.fileId !== nextProps.fileId) {
    return false;
  }

  return prevProps.isUploading === nextProps.isUploading;
}

const OptimizedUploadRow = React.memo(UploadRow, rowPropsAreEqual);

export default OptimizedUploadRow;
