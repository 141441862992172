import React, { Component } from "react";
import { connect } from "react-redux";
import ContainersList from "../../Containers/List";
import { isAuthorized } from "../../../api/Authorities";
import { ROLE_SYSTEM_ADMINISTRATION } from "../../../constants/Authorities";

class ContainersPanel extends Component {
  render() {
    const { containers, me } = this.props;
    return (
      <ContainersList
        containers={containers}
        isAdmin={isAuthorized(me, [ROLE_SYSTEM_ADMINISTRATION])}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    containers: state.containers.list,
    me: state.auth.me
  };
};
export default connect(
  mapStateToProps,
  {}
)(ContainersPanel);
