import {
  GET_WORKFLOW_TYPES,
  LOADING_WORKFLOW_TYPES,
  END_LOADING_WORKFLOW_TYPES,
  WORKFLOW_TYPES_ERROR,
  GET_WORKFLOW_CLASSIFICATIONS,
  LOADING_WORKFLOW_CLASSIFICATIONS,
  END_LOADING_WORKFLOW_CLASSIFICATIONS,
  WORKFLOW_CLASSIFICATIONS_ERROR,
  GET_WORKFLOW_PROCESSES,
  LOADING_WORKFLOW_PROCESSES,
  END_LOADING_WORKFLOW_PROCESSES,
  WORKFLOW_PROCESSES_ERROR,
  GET_WORKFLOW_TYPES_BY_ENTITY,
  LOADING_WORKFLOW_TYPES_BY_ENTITY,
  END_LOADING_WORKFLOW_TYPES_BY_ENTITY,
  WORKFLOW_TYPES_BY_ENTITY_ERROR,
  CREATE_WORKFLOW_TYPE,
  CREATING_WORKFLOW_TYPE,
  END_CREATING_WORKFLOW_TYPE,
  CREATE_WORKFLOW_TYPE_ERROR,
  UPDATE_WORKFLOW_TYPE,
  UPDATING_WORKFLOW_TYPE,
  END_UPDATING_WORKFLOW_TYPE,
  UPDATE_WORKFLOW_TYPE_ERROR,
  DELETE_WORKFLOW_TYPE,
  DELETING_WORKFLOW_TYPE,
  END_DELETING_WORKFLOW_TYPE,
  DELETE_WORKFLOW_TYPE_ERROR,
  SET_CURRENT_WORKFLOW_TYPE
} from "../../constants/ActionTypes";
import { goThroughList } from "../../api/List";

export default function(state = {}, action) {
  const typesId = action.typesId;
  let list = state.list;
  let current = state.current;
  let result;

  switch (action.type) {
    case GET_WORKFLOW_TYPES:
      return { ...state, list: action.types, error: undefined };
    case LOADING_WORKFLOW_TYPES:
      return { ...state, loading: true, error: undefined };
    case END_LOADING_WORKFLOW_TYPES:
      return { ...state, loading: false };
    case WORKFLOW_TYPES_ERROR:
      return { ...state, error: action.error };

    case GET_WORKFLOW_CLASSIFICATIONS:
      return {
        ...state,
        classifications: action.classifications,
        error_classifications: undefined
      };
    case LOADING_WORKFLOW_CLASSIFICATIONS:
      return {
        ...state,
        loading_classifications: true,
        error_classifications: undefined
      };
    case END_LOADING_WORKFLOW_CLASSIFICATIONS:
      return { ...state, loading_classifications: false };
    case WORKFLOW_CLASSIFICATIONS_ERROR:
      return { ...state, error_classifications: action.error };

    case GET_WORKFLOW_PROCESSES:
      return {
        ...state,
        processes: action.processes,
        error_processes: undefined
      };
    case LOADING_WORKFLOW_PROCESSES:
      return { ...state, loading_processes: true, error_processes: undefined };
    case END_LOADING_WORKFLOW_PROCESSES:
      return { ...state, loading_processes: false };
    case WORKFLOW_PROCESSES_ERROR:
      return { ...state, error_processes: action.error };

    case GET_WORKFLOW_TYPES_BY_ENTITY:
      return {
        ...state,
        list_by_entity: action.types,
        error_by_entity: undefined
      };
    case LOADING_WORKFLOW_TYPES_BY_ENTITY:
      return { ...state, loading_by_entity: true, error_by_entity: undefined };
    case END_LOADING_WORKFLOW_TYPES_BY_ENTITY:
      return { ...state, loading_by_entity: false };
    case WORKFLOW_TYPES_BY_ENTITY_ERROR:
      return { ...state, error_by_entity: action.error };

    case CREATE_WORKFLOW_TYPE:
      if (!list) {
        list = [];
      }
      return {
        ...state,
        list: [...list, action.workflowType],
        error_creating: undefined
      };
    case CREATING_WORKFLOW_TYPE:
      return { ...state, creating: true, error_creating: undefined };
    case END_CREATING_WORKFLOW_TYPE:
      return { ...state, creating: false };
    case CREATE_WORKFLOW_TYPE_ERROR:
      return { ...state, error_creating: action.error };

    case SET_CURRENT_WORKFLOW_TYPE:
      return { ...state, current: action.workflowType };

    case UPDATING_WORKFLOW_TYPE:
      if (!typesId) {
        return { ...state };
      }
      result = goThroughList(list, current, typesId, type => {
        return {
          ...type,
          updating: true,
          error: undefined
        };
      });
      return { ...state, list: result.list, current: result.current };
    case END_UPDATING_WORKFLOW_TYPE:
      if (!typesId) {
        return { ...state };
      }
      result = goThroughList(list, current, typesId, type => {
        return {
          ...type,
          updating: false
        };
      });
      return { ...state, list: result.list, current: result.current };
    case UPDATE_WORKFLOW_TYPE_ERROR:
      if (!typesId) {
        return { ...state };
      }
      result = goThroughList(list, current, typesId, type => {
        return {
          ...type,
          error: action.error
        };
      });
      return { ...state, list: result.list, current: result.current };
    case UPDATE_WORKFLOW_TYPE:
      if (!typesId) {
        return { ...state };
      }
      result = goThroughList(list, current, typesId, type => {
        return action.workflowType;
      });
      return { ...state, list: result.list, current: result.current };

    case DELETING_WORKFLOW_TYPE:
      if (!typesId) {
        return { ...state };
      }
      result = goThroughList(list, current, typesId, type => {
        return {
          ...type,
          deleting: true,
          error: undefined
        };
      });
      return { ...state, list: result.list, current: result.current };
    case END_DELETING_WORKFLOW_TYPE:
      if (!typesId) {
        return { ...state };
      }
      result = goThroughList(list, current, typesId, type => {
        return {
          ...type,
          deleting: false
        };
      });
      return { ...state, list: result.list, current: result.current };
    case DELETE_WORKFLOW_TYPE_ERROR:
      if (!typesId) {
        return { ...state };
      }
      result = goThroughList(list, current, typesId, type => {
        return {
          ...type,
          error: action.error
        };
      });
      return { ...state, list: result.list, current: result.current };
    case DELETE_WORKFLOW_TYPE:
      if (!typesId) {
        return { ...state };
      }
      result = goThroughList(list, current, typesId, type => {
        return null;
      });
      return { ...state, list: result.list, current: result.current };

    default:
      return state;
  }
}
