import React, { Component } from "react";
import RestrictedComponent from "../RestrictedComponent";
import { Grid as GridMaterial } from "@material-ui/core";

class Grid extends Component {
  render() {
    return (
      <RestrictedComponent componentButton={GridMaterial} {...this.props} />
    );
  }
}

export default Grid;
