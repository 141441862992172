import {
  cyan,
  grey,
  green
} from '@material-ui/core/colors';
import {fade} from '@material-ui/core/styles/colorManipulator';

// $dark:                  #333C44 !default; // darken Blue 100 > #343E46
// $theme_dark_text_color:       rgba(255,255,255,.7);

export default {
  fontFamily: 'Roboto, sans-serif',
  borderRadius: 2,
  palette: {
    primary1Color: cyan[700],
    primary2Color: cyan[700],
    primary3Color: grey[600],
    accent1Color: green[600],
    accent2Color: green[400],
    accent3Color: green[200],
    textColor: 'rgba(255,255,255,.7)',
    secondaryTextColor: fade('#fff', 0.54),
    alternateTextColor: '#333C44',
    canvasColor: '#333C44',
    borderColor: fade('#fff', 0.15),
    disabledColor: fade('#fff', 0.3),
    pickerHeaderColor: fade('#fff', 0.12),
    clockCircleColor: fade('#fff', 0.12),
  },
};
