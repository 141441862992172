import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import MenuItem from "../Buttons/MenuItem";
import IconButton from "../Buttons/IconButton";
import Button from "../Buttons/Button";
import { setAsReadNotifications } from "actions/notifications";
import { getNotifications } from "actions/notifications";
import APPCONFIG from "constants/Config";
import { NotificationsNone } from "@material-ui/icons";
import { getIconComponent } from "api/Notification";
import {
  Typography,
  Tooltip,
  Menu,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { HeaderIconButtonStyle } from "components/Header/NavRightList";

const listItemStyle = {
  width: "300px"
};

class MenuHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationOpened: null,
      anchorElMenuNotification: null,
      maxItems: 20
    };
  }

  componentDidMount() {
    this.refreshTab(this.props, this.state, true);
  }

  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    const { maxItems } = nextState;
    if (this.state.maxItems !== maxItems) {
      this.refreshTab(nextProps, nextState, true);
    }
  }

  refreshTabTimer = () => {
    this.refreshTab(this.props, this.state);
  };

  refreshTab = (props, state, isInit) => {
    const { maxItems } = state;
    const { getNotifications } = props;

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTabTimer();
    }, APPCONFIG.REFRESH_NOTIFICATION_TIMEOUT);

    getNotifications(maxItems, isInit);
  };

  handleClickMenuNotification = event => {
    const { setAsReadNotifications, notifications } = this.props;
    setAsReadNotifications(notifications);
    this.setState({
      anchorElMenuNotification: event.currentTarget
    });
  };
  handleCloseMenuNotification = () => {
    this.setState({
      anchorElMenuNotification: null
    });
  };

  handleClickOpenNotification = notification => {
    this.setState({ notificationOpened: notification });
  };

  handleClose = () => {
    this.setState({ notificationOpened: null });
  };

  onScroll = e => {
    const list = e.target;
    const { notifications, setAsReadNotifications } = this.props;
    const { maxItems } = this.state;
    if (list) {
      if (list.scrollTop === 0) {
        setAsReadNotifications(notifications);
      }
      if (
        list.scrollTop + 100 >= list.scrollHeight - list.offsetHeight &&
        maxItems <= notifications.length
      ) {
        this.setState({
          maxItems: this.state.maxItems + 10
        });
      }
    }
  };
  listDidMount = list => {
    if (list) {
      list.addEventListener("scroll", this.onScroll);
    }
  };

  render() {
    const { unreadNotifications, notifications, classes } = this.props;
    const { notificationOpened, anchorElMenuNotification } = this.state;
    let menuNotification, badge;

    if (notifications && notifications.length > 0) {
      menuNotification = notifications.map((notification, idx) => {
        let icon, onClick, href, date;
        const IconComponent = getIconComponent(notification.icon);
        if (notification.icon) {
          icon = (
            <ListItemIcon>{IconComponent && <IconComponent />}</ListItemIcon>
          );
        }
        if (!notification.link) {
          onClick = () => {
            this.handleClickOpenNotification(notification);
          };
        } else {
          href = notification.link;
        }

        if (notification.createdAt) {
          date = moment(notification.createdAt).format(APPCONFIG.dateTimeFormat);
        }

        return (
          <MenuItem
            key={idx}
            component="a"
            className="header-icon-dropdown-item"
            style={listItemStyle}
            onClick={onClick}
            href={href}
          >
            {icon}
            <ListItemText
              primaryTypographyProps={{
                className: "truncate",
                style: { display: "block" }
              }}
              secondaryTypographyProps={{
                className: "truncate",
                style: { display: "block" }
              }}
              primary={notification.message}
              secondary={date}
            />
          </MenuItem>
        );
      });
    } else {
      menuNotification = <div className={classes.empty}>No notifications</div>;
    }

    if (unreadNotifications > 0) {
      badge = <span className="badge">{unreadNotifications}</span>;
    }
    return (
      <li className="list-inline-item">
        <Tooltip title="Notifications">
          <IconButton
            onClick={this.handleClickMenuNotification}
            style={HeaderIconButtonStyle}
            className="md-button header-btn"
          >
            <NotificationsNone />
            {badge}
          </IconButton>
        </Tooltip>
        <Menu
          onEntered={this.listDidMount}
          open={Boolean(anchorElMenuNotification)}
          onClose={this.handleCloseMenuNotification}
          anchorEl={anchorElMenuNotification}
        >
          {menuNotification}
        </Menu>
        <Dialog
          open={Boolean(notificationOpened)}
          onClose={this.handleClose}
          scroll="paper"
        >
          <CloseableDialogTitle handleClose={this.handleClose}>
            Notification
          </CloseableDialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {notificationOpened && notificationOpened.message}
            </Typography>
            <Typography variant="caption">
              {notificationOpened &&
                notificationOpened.createdAt &&
                moment(notificationOpened.createdAt).format(APPCONFIG.dateTimeFormat)}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </li>
    );
  }
}

const styles = theme => ({
  empty: {
    padding: "10px 30px"
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: state.notifications.list,
    unreadNotifications: state.notifications.unread
  };
};
export default withStyles(styles)(
  connect(
    mapStateToProps,
    { setAsReadNotifications, getNotifications }
  )(MenuHeader)
);
