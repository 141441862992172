import React, { Component } from "react";
import ButtonLoading from "../Buttons/ButtonLoading";
import { Field, reduxForm } from "redux-form";
import {
  RequiredValidator,
  NonEmptyStringValidator
} from "../Form/Fields/FieldValidators";
import InputField from "../Form/Fields/InputField";
import {
  withStyles,
  DialogContent,
  Dialog,
  DialogActions
} from "@material-ui/core";
import CloseableDialogTitle from "components/Text/CloseableDialogTitle";
import { scrollToFirstError } from "../../api/Error";

class CreateRepositoryFullDialogForm extends Component {
  componentWillReceiveProps(nextProps) {
    const { open, entity, handleClose } = nextProps;
    if (
      open &&
      this.props.entity &&
      this.props.entity.creating_repository &&
      !entity.creating_repository &&
      !entity.error_creating_repository
    ) {
      handleClose();
    }
  }

  render() {
    const { handleSubmit, handleClose, open, entity, classes } = this.props;

    return (
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleSubmit}>
          <CloseableDialogTitle
            id="simple-dialog-title"
            handleClose={handleClose}
          >
            Create a repository
          </CloseableDialogTitle>
          <DialogContent>
            <div className={classes.field}>
              <Field
                label={"Name"}
                name="name"
                component={InputField}
                validate={[RequiredValidator, NonEmptyStringValidator]}
                ref="name"
              />
            </div>
            <div className={classes.field}>
              <Field
                label={"Description"}
                name="description"
                component={InputField}
                multiline
                validate={[RequiredValidator]}
                ref="description"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonLoading
              loading={entity.creating_repository}
              loadingTitle="Creating the repository"
              className="color-primary"
              type="submit"
              variant="outlined"
              color="primary"
            >
              {"Create a repository"}
            </ButtonLoading>
            <div className="error">{entity.error_creating_repository}</div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const styles = theme => ({
  field: {
    margin: "10px 0"
  }
});

CreateRepositoryFullDialogForm = reduxForm({
  form: "createrepositoryfulldialog",
  onSubmitFail: scrollToFirstError
})(CreateRepositoryFullDialogForm);

export default withStyles(styles)(CreateRepositoryFullDialogForm);
