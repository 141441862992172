import axios from 'axios';
import { API_ROLES, API_GRANTED_AUTHORITIES, GRANTED_AUTHORITIES } from "constants/ApiEndpoints";
import qs from "querystring";
import {
  LOADING_USER_ROLES,
  END_LOADING_USER_ROLES,
  ERROR_LOADING_USER_ROLES,
  GET_USER_ROLES
} from "constants/ActionTypes";

export function getAllRoles() {
  return axios.get(API_ROLES, {
        withCredentials: true
      });
}

export function getAllRolesRedux() {
  return function(dispatch) {
    dispatch({
      type: LOADING_USER_ROLES
    });

    axios
      .get(API_ROLES, {
        withCredentials: true
      })
      .then(response => {
        let roles = response.data;
        dispatch({
          type: GET_USER_ROLES,
          roles: roles
        });
        dispatch({
          type: END_LOADING_USER_ROLES
        });
      })
      .catch(err => {
        let message = err.response.data.message;
        if (!message) {
          message = err.response.statusText;
        }
        dispatch({
          type: ERROR_LOADING_USER_ROLES,
          error: message
        });
        dispatch({
          type: END_LOADING_USER_ROLES
        });
      });
  };
}

export function createRole(role) {
  return axios.post(API_ROLES, role, {
    withCredentials: true
  });
}

export function updateRole(role) {
  return axios.put(API_ROLES, role, {
    withCredentials: true
  });
}

export function getGrantedAuthorityForRole(roleId) {
  return axios.get(`${API_ROLES}/${roleId}${GRANTED_AUTHORITIES}`, {
    withCredentials: true
  });
}

export function updateGrantedAuthoritiesForRole(roleId, grantedAuthority) {
  return axios.post(`${API_ROLES}/${roleId}${GRANTED_AUTHORITIES}/update`, grantedAuthority, {
    withCredentials: true
  });
}

export function searchGrantedAuthorities(id, page= 0, size=10) {
  return axios.get(`${API_GRANTED_AUTHORITIES}?${qs.stringify({id, page, size})}`, {
    withCredentials: true
  });
}