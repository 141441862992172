export const createStatusLabel = (progress = 0, title = '') => {
  let variant = progress === 100
    ? 'indeterminate'
    : 'static';

  return {
    progress,
    variant,
    title,
  };
};

export const progressConverter = (progressEvent) => {
  return Math.round((progressEvent.loaded / progressEvent.total) * 100);
};

export const concatUploadStatuses = (uploadStatus) => {
  const statuses = [];
  if (uploadStatus.errors || uploadStatus.cancelled || uploadStatus.skipped) {
    if (uploadStatus.errors) {
      statuses.push(`Failed: ${uploadStatus.errors}`);
    }
    if (uploadStatus.cancelled) {
      statuses.push(`Cancelled: ${uploadStatus.cancelled}`);
    }
    if (uploadStatus.skipped) {
      statuses.push(`Skipped: ${uploadStatus.skipped}`);
    }
  }

  return statuses.length === 0 ? '' : statuses.join(' - ');
}
